import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchSingleDocument(id) {
  return useQuery(
    [
      "getSingleDocument",
    ],
    () => {
      const params = {}
      return apiClient
        .get(`document/${id}`, {
          params,
        })
        .then(({ data }) => {
          console.log(data);
          const creator = {
            id: data[0].creator,
            name: data[0].creator_name,
            profile_picture_ext: data[0].profile_picture_ext
          };

          const usersArray = [creator];

          const newData = {
            ...data[0],
            money_request_name: data[0].parent_name,
            category: data[0].category_name,
            users: usersArray
          }
          return [newData];
        });
    }
  );
}
