const HorizCollArrow = ({ fillColor }) => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 6L14 10L6 14L6 6Z"
          fill={fillColor ? fillColor : "#CBD5E1"}
        />
      </svg>
    </div>
  );
};

export default HorizCollArrow;
