import { useEffect, useState } from "react";
import useFetchUserData from "../../../hooks/reactQuery/useFetchUserData";
import { useHistory } from "react-router-dom";
import { getAttachments } from "../../../utils/getAttachments";
import folders from "../../../constants/static_folders";
import BlueDotSvg from "../../../components/resources/blue dot svg/BlueDotSvg";
import { convertDate } from "../../../utils/convertDate";
import LockSvg from "../../../components/resources/lock svg/LockSvg";
import DocumentExpenseSvg from "../../../components/resources/document expense svg/DocumentExpenseSvg";
import Modal from  "../../../components/common/Modal";
import CloseAndDetails from "../../../components/moneyRequest/CloseAndDetails";

const BalanceDetailsExpandedRow = ({ row }) => {
  const { 
    data: userData, 
    isLoading: isUserDataLoading,
    isError: isUserDataError
  } = useFetchUserData();

  const history = useHistory();

  const [noManagerImg, setNoManagerImg] = useState(false);

  const [showCloseAndDetails, setShowCloseAndDetails] = useState(false);
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => console.log(showCloseAndDetails), [showCloseAndDetails]);

  return (
    <>
    <div className="grid grid-cols-6 gap-4 mr-20">
      <div className="col-span-1 row-start-1">
        <b>موضوع العملية</b>
        <p>{row.name}</p>
      </div>

      {row.type === "CASHOUT_WITHOUT_PERMISSION" ? <div className="col-span-1 row-start-1">
        <b>مدير الخزينة</b>
        <div className="flex justify-start align-center">
          {noManagerImg ? <span className="flex justify-center align-center mx-1" style={{width: "20px", height: "20px", borderRadius: "50%", backgroundColor: "#3B82F6", color: "#fff"}}>
            {row.safe.manager_user.first_name.charAt(0).toUpperCase()}
          </span> : <img src={row.safe.manager_user.profile_picture_url}  style={{width: "15px", height: "15px", borderRadius: "50%"}} onError={() => setNoManagerImg(true)}/>}
          <span>{row.safe.manager_user.first_name} {row.safe.manager_user.last_name}</span>
        </div>
      </div> : null}

      {row.type === "CASHOUT_WITH_PERMISSION" || row.response_date ? <>
      <div className="col-span-1 row-start-1">
        <b>تاريخ الرد</b>
        <p>{row.response_date ? convertDate(row.response_date) : null}</p>
      </div>

      <div className="col-span-1 row-start-1">
        <b>حالة الرد</b>
        <p>مقبول</p>
      </div>
      </> : null}

      <div className="col-span-1 row-start-1">
        <b title="عدد المصروفات و العهد الصادرة">عدد المصروفات</b>
        <div className="flex items-center justify-start">
          <p>{row.num_of_documents ? row.num_of_documents : 0} مصروف</p>
          <div className="mr-2 col-span-1 tool-pin-container relative">
            <button
              onClick={() => {
                history.push({
                  pathname: "/moneyRequestDetails",
                  moneyRequestsActiveTab: 0,
                  type: row.status === 1 
                    ? "closeMoneyRequest"
                    : "moneyRequestDetails",
                  docId: row.id,
                  activeRow: row,
                  showFooter: row.status === 1,
                  inValidteQueryKey: "getBalanceDetails",
                });
              }}
            >
              <BlueDotSvg />
            </button>
            <p className="tool-pin w-max px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-6 -right-20 z-10">
              فتح صفحة تدوينات العهدة
            </p>
          </div>
        </div>
      </div>


      <div className="col-span-1 row-start-1">
        <b>قيمة العهدة الاصلية</b>
        <div className="flex justify-start align-center">
        {new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(row.amount).toString().replace(/EGP/, "") + " "}
        ج.م 
        </div>
      </div>

      <div className="col-span-5 row-start-2">
        <b>ملاحظات</b>
        <p>{row.response_notes ? row.response_notes : "لا توجد ملاحظات"}</p>
      </div>
      <div className="col-span-5 row-start-3">
        {getAttachments({
          title: "مرفقات",
          notFoundMessage: "لا توجد مرفقات",
          attachProperity: row.original_attachments,
          attachUrl: folders.money_requests,
          rowId: row.id,
        })}
      </div>

      {row.status === 1 && row.approved === 1 && (
        <div className="col-span-5 row-start-5 grid grid-cols-5 gap-4">
          <div className="col-span-1 tool-pin-container relative">
            <button
              onClick={() => {
                setShowCloseAndDetails(true);
                setShowFooter(true);
              }}
              disabled={row.has_open_children}
              className={`${
                row.has_open_children === 1 &&
                "cursor-not-allowed disabled bg-opacity-50"
              }`}
            >
              <LockSvg />
            </button>
            <p className="tool-pin w-max px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-8 -right-9 z-10">
              {row.has_open_children
                ? "لا يمكنك تقفيل العهدة إلا بعد تقفيل كل العهد الصادرة منها"
            : " تقفيل السحب"}
            </p>
          </div>

          <div
            className="col-span-1 tool-pin-container relative"
            onClick={() => {
              history.push({
                pathname: "/document",
                state: {
                  label: row.name,
                  value: row.id,
                  remainValue: row.remaining_amount,
                  ...row,
                },
              });
            }}
          >
            <div>
              <DocumentExpenseSvg />
            </div>
            <p className="tool-pin w-max px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-8 -right-9 z-10">
              تدوين مصروف
            </p>
          </div>
        </div>
      )}
    </div>
    <Modal
      showModal={showCloseAndDetails}
      onClose={() => setShowCloseAndDetails(false)}
    >
      <CloseAndDetails
        onClose={() => setShowCloseAndDetails(false)}
        type={showFooter ? "closeMoneyRequest" : "moneyRequestDetails"}
        docId={row.id}
        activeRow={row}
        showFooter={row.status == 1}
        inValidteQueryKey="getBalanceDetails"
      />
    </Modal>
    </>
  );
};

export default BalanceDetailsExpandedRow;
