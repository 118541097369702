import { useMutation } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useMutatePaymentForm(
  userId,
  activeCompanyId,
  setShowAlertConfirmModal,
  history
) {
  return useMutation(
    (data) => {
      const formData = new FormData();

      formData.append("requests", JSON.stringify(data));

      formData.append("user_id", userId);
      formData.append("company_id", activeCompanyId);

      return apiClient.post("spend_request/add", formData);
    },
    {
      onSuccess: () => {
        toast.info("تم الطلب بنجاح.", { autoClose: 3000 });
        setShowAlertConfirmModal(false);
        setTimeout(() => {
          history.push("/");
        }, 1000);
      },
      onError: (error) => {
        setShowAlertConfirmModal(false);
        return error;
      },
    }
  );
}
