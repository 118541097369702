const EditRole = () => {
  return (
    <div>
      <svg
        width={48}
        height={48}
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width={48} height={48} rx={24} fill="#E7F0FF" />
        <path
          d="M22.4899 14.23L17.4999 16.1C16.3499 16.53 15.4099 17.89 15.4099 19.12V26.55C15.4099 27.73 16.1899 29.28 17.1399 29.99L21.4399 33.2C22.8499 34.26 25.1699 34.26 26.5799 33.2L30.8799 29.99C31.8299 29.28 32.6099 27.73 32.6099 26.55V19.12C32.6099 17.89 31.6699 16.53 30.5199 16.1L25.5299 14.23C24.6799 13.92 23.3199 13.92 22.4899 14.23Z"
          stroke="#3B82F6"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.0392 20.573L21.4991 24.1131C21.3591 24.2531 21.2291 24.513 21.1991 24.703L21.0091 26.053C20.9391 26.543 21.2791 26.883 21.7691 26.813L23.1191 26.623C23.3091 26.593 23.5791 26.463 23.7091 26.323L27.2491 22.783C27.8591 22.173 28.1491 21.463 27.2491 20.563C26.3591 19.673 25.6492 19.963 25.0392 20.573Z"
          stroke="#3B82F6"
          strokeWidth="1.5"
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.5293 21.082C24.8293 22.162 25.6693 23.002 26.7493 23.302"
          stroke="#3B82F6"
          strokeWidth="1.5"
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default EditRole;
