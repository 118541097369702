import PropTypes from "prop-types";

function Icon(props) {
  const { className, ...restOfProps } = props;
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...restOfProps}
    >
      <path
        d="M12.0827 7.70898H1.66602C1.32435 7.70898 1.04102 7.42565 1.04102 7.08398C1.04102 6.74232 1.32435 6.45898 1.66602 6.45898H12.0827C12.4243 6.45898 12.7077 6.74232 12.7077 7.08398C12.7077 7.42565 12.4243 7.70898 12.0827 7.70898Z"
        fill="#94A3B8"
      />
      <path
        d="M6.66667 14.375H5C4.65833 14.375 4.375 14.0917 4.375 13.75C4.375 13.4083 4.65833 13.125 5 13.125H6.66667C7.00833 13.125 7.29167 13.4083 7.29167 13.75C7.29167 14.0917 7.00833 14.375 6.66667 14.375Z"
        fill="#94A3B8"
      />
      <path
        d="M12.0833 14.375H8.75C8.40833 14.375 8.125 14.0917 8.125 13.75C8.125 13.4083 8.40833 13.125 8.75 13.125H12.0833C12.425 13.125 12.7083 13.4083 12.7083 13.75C12.7083 14.0917 12.425 14.375 12.0833 14.375Z"
        fill="#94A3B8"
      />
      <path
        d="M14.6327 17.7077H5.36602C2.04935 17.7077 1.04102 16.7077 1.04102 13.4243V6.57435C1.04102 3.29102 2.04935 2.29102 5.36602 2.29102H12.0827C12.4243 2.29102 12.7077 2.57435 12.7077 2.91602C12.7077 3.25768 12.4243 3.54102 12.0827 3.54102H5.36602C2.74935 3.54102 2.29102 3.99102 2.29102 6.57435V13.416C2.29102 15.9993 2.74935 16.4493 5.36602 16.4493H14.6243C17.241 16.4493 17.6993 15.9993 17.6993 13.416V11.6827C17.6993 11.341 17.9827 11.0577 18.3243 11.0577C18.666 11.0577 18.9493 11.341 18.9493 11.6827V13.416C18.9577 16.7077 17.9493 17.7077 14.6327 17.7077Z"
        fill="#94A3B8"
      />
      <path
        d="M16.666 8.54102C16.5827 8.54102 16.5077 8.52435 16.4243 8.49101C16.191 8.39102 16.041 8.16602 16.041 7.91602V2.91602C16.041 2.57435 16.3243 2.29102 16.666 2.29102C17.0077 2.29102 17.291 2.57435 17.291 2.91602V6.40768L17.891 5.80768C18.1327 5.56602 18.5327 5.56602 18.7744 5.80768C19.016 6.04935 19.016 6.44935 18.7744 6.69102L17.1077 8.35768C16.991 8.47435 16.8327 8.54102 16.666 8.54102Z"
        fill="#94A3B8"
      />
      <path
        d="M16.6665 8.54154C16.5082 8.54154 16.3499 8.4832 16.2249 8.3582L14.5582 6.69154C14.3165 6.44987 14.3165 6.04987 14.5582 5.8082C14.7999 5.56654 15.1999 5.56654 15.4415 5.8082L17.1082 7.47487C17.3499 7.71654 17.3499 8.11654 17.1082 8.3582C16.9832 8.4832 16.8249 8.54154 16.6665 8.54154Z"
        fill="#94A3B8"
      />
    </svg>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
};

export default Icon;
