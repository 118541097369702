import React from "react";

import { useForm, Controller } from "react-hook-form";

import TextArea from "../../common/inputs/Textarea";
import SelectMenu from "../../common/inputs/Select";
import Spinner from "../../common/Spinner";
import Close from "../../resources/icons/Close";
import RedirectSvg from "./../../resources/icons/Redirect";
import SelectUser from "./../../resources/icons/SelectUser";

import useFetchUserData from "../../../hooks/reactQuery/useFetchUserData";
import useFetchUsers from "../../../hooks/reactQuery/useFetchUsers";
import useMutateRedirectPaymentLicense from "../../../hooks/reactQuery/useMutateRedirectPaymentLicense";

import { usersFormatOptionLabel } from "../../../utils/markUp";

import "./Respond.scss";

const Redirect = ({ onClose, activeRow }) => {
  const { data: userData } = useFetchUserData();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm({
    mode: "all",
  });

  const {
    data: users,
    isLoading: isUsersLoading,
    isError: isUsersError,
  } = useFetchUsers(userData.id);

  const {
    isLoading: isMutateRedirectLoading,
    mutate: mutateRedirect,
    isError: mutateRedirectError,
  } = useMutateRedirectPaymentLicense(
    onClose,
    userData.id,
    userData.activeCompanyId
  );

  const handlePaymentLicenseRespond = (data) => {
    let modifiedAttachments = data.attachments?.map((attachment) => {
      return Object.assign({
        guid: attachment.guid,
        name: attachment.name,
      });
    });
    data.attachments = modifiedAttachments;

    mutateRedirect({ data, activeRow });
  };

  return (
    <div className="overlay" onClick={!isMutateRedirectLoading && onClose}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <div className="bg-white lg:grid lg:grid-cols-12 lg:gap-x-5 max-w-screen-md payment-license-redirect-container">
          <form
            className="col-span-12"
            onSubmit={handleSubmit(handlePaymentLicenseRespond)}
          >
            <div className="flex justify-between items-center text-center px-6 py-5">
              <div className="flex items-center gap-x-2">
                <div className="border border-onhold p-1 rounded-lg flex justify-center items-center">
                  <RedirectSvg strokeColor="#EB9B54" />
                </div>
                <h3 className="text-base leading-5 font-bold text-body">
                  إعادة توجيه الطلب
                </h3>
              </div>

              <button
                type="button"
                onClick={onClose}
                className={`${
                  isMutateRedirectLoading ? "cursor-not-allowed" : ""
                }`}
                disabled={isMutateRedirectLoading}
              >
                <Close />
              </button>
            </div>
            <hr />
            <div className="flex flex-col gap-y-4 py-5 px-6">
              <div className="grid grid-cols-2">
                <div className="lg:col-span-1 col-span-2">
                  <SelectMenu
                    name="userName"
                    fieldName="اسم الشخص"
                    label="اسم المستخدم"
                    placeholder="اسم المستخدم"
                    hint="اكتب اسم المستخدم الذى تريد تصدير الطلب إليه."
                    Icon={SelectUser}
                    options={users}
                    isLoading={isUsersLoading}
                    isError={isUsersError}
                    Controller={Controller}
                    control={control}
                    required={true}
                    errors={errors?.userName}
                    formatOptionLabel={usersFormatOptionLabel}
                  />
                </div>
              </div>

              <div className="grid grid-cols-1">
                <div className="col-span-1">
                  <TextArea
                    register={register}
                    name="responseNotes"
                    fieldName="الملاحظات"
                    label="ملاحظات"
                    placeholder="ملاحظات"
                    hint="هل هناك بعض الأمور التي تحتاج إلى توضيح؟ يمكنك الاستفاضة في شرحها من هنا."
                    required={false}
                    maxLength="2000"
                    errors={errors?.responseNotes}
                  />
                </div>
              </div>
            </div>

            <div className="grid sm:grid-cols-11 grid-cols-2 gap-x-3 py-6 px-6 text-sm font-medium text-body">
              <button
                type="submit"
                className={`sm:col-span-3 py-2 border rounded-md shadow-sm flex justify-center items-center gap-x-2 ${
                  !isValid || isMutateRedirectLoading
                    ? "cursor-not-allowed bg-side-icons"
                    : "border-separator"
                }`}
                disabled={!isValid || isMutateRedirectLoading}
              >
                <span>إعادة التوجيه</span>

                {isMutateRedirectLoading && (
                  <div className="w-4 h-4">
                    <Spinner />
                  </div>
                )}
              </button>

              <button
                type="button"
                className={`sm:col-span-3 border border-separator rounded-md shadow-sm ${
                  isMutateRedirectLoading ? "cursor-not-allowed" : ""
                }`}
                disabled={isMutateRedirectLoading}
                onClick={onClose}
              >
                إلغاء
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Redirect;
