const Export = () => {
  return (
    <div>
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="table-operation-btn"
      >
        <path
          d="M9.75 5.25H5.25C4.00736 5.25 3 6.25736 3 7.5V18.75C3 19.9926 4.00736 21 5.25 21H16.5C17.7426 21 18.75 19.9926 18.75 18.75V14.25M14.25 3H21M21 3V9.75M21 3L9.75 14.25"
          stroke="#1E293B"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Export;
