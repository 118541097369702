import PropTypes from "prop-types";

function Icon(props) {
  const { className, strokeColor, ...restOfProps } = props;
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...restOfProps}
    >
      <path
        d="M10.4544 3.90945C11.3582 3.90945 12.0908 4.64207 12.0908 5.54581M15.3635 5.54581C15.3635 8.25702 13.1656 10.4549 10.4544 10.4549C9.95845 10.4549 9.47967 10.3814 9.02838 10.2446L7.18168 12.0913H5.54532V13.7276H3.90896V15.364H1.45441C1.00254 15.364 0.63623 14.9977 0.63623 14.5458V12.4302C0.63623 12.2132 0.722431 12.0051 0.87587 11.8516L5.75565 6.97184C5.61887 6.52055 5.54532 6.04177 5.54532 5.54581C5.54532 2.83459 7.7432 0.636719 10.4544 0.636719C13.1656 0.636719 15.3635 2.83459 15.3635 5.54581Z"
        stroke={strokeColor ? strokeColor : "#1E293B"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
};

export default Icon;
