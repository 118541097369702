import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useMutateRedirectPaymentLicense(
  onClose,
  userId,
  activeCompanyId
) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ data, activeRow }) => {
      const formData = new FormData();

      formData.append("spend_request_id", activeRow.id);
      formData.append("id", activeRow.id);
      formData.append("notes", data.responseNotes);

      formData.append("from", userId);
      formData.append("to", data.userName.value);

      formData.append(
        "attachments",
        JSON.stringify(data.attachments ? data.attachments : [])
      );

      formData.append("created_by", activeRow.created_by);
      formData.append("user_id", userId);
      formData.append("company_id", activeCompanyId);

      return apiClient.post("spend_request/forward", formData);
    },
    {
      onSuccess: () => {
        toast.info("تم إعادة توجيه إذن الصرف.", { autoClose: 3000 });
        queryClient.invalidateQueries("getPayments");
        onClose();
      },
      onError: (error) => {
        onClose();
        return error;
      },
    }
  );
}
