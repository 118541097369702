import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useMutateDocumentForm(
  userId,
  activeCompanyId,
  setShowAlertConfirmModal,
  history
) {
  const queryClient = useQueryClient();

  return useMutation(
    (data) => {
      const formData = new FormData();

      formData.append("requests", JSON.stringify(data));

      formData.append("user_id", userId);
      formData.append("company_id", activeCompanyId);

      return apiClient.post("document/add", formData);
    },
    {
      onSuccess: () => {
        toast.info("تم التدوين بنجاح.", { autoClose: 3000 });
        queryClient.invalidateQueries("getLastUpdates");
        queryClient.invalidateQueries("userData");
        queryClient.invalidateQueries("userBalance");
        setShowAlertConfirmModal(false);
        setTimeout(() => {
          history.push("/");
        }, 1000);
      },
      onError: (error) => {
        setShowAlertConfirmModal(false);
        return error;
      },
    }
  );
}
