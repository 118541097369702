import { useEffect } from "react";
import { useWatch, Controller, useFormContext } from "react-hook-form";
import useCollapse from "react-collapsed";
import Input from "../../../common/inputs/Input/index";
import TextArea from "../../../common/inputs/Textarea";
import SelectMenu from "../../../common/inputs/Select";
import UploadInput from "../../../common/inputs/Upload";
import ChevronArrow from "../../../resources/icons/ChevronArrow";
import SubTitle from "../../../resources/icons/Subtitle";
import Cash from "../../../resources/icons/Cash";
import DataBase from "../../../resources/icons/Database";
import Trash from "../../../resources/icons/Trash";
import Safe from "../../../resources/icons/Safe";
import useFetchUserData from "../../../../hooks/reactQuery/useFetchUserData";
import useFetchCategory from "../../../../hooks/reactQuery/useFetchCategory";
import useMutateCategory from "../../../../hooks/reactQuery/useMutateCategory";
import useFetchFinancials from "../../../../hooks/reactQuery/useFetchFinancials";
import useFetchLatestCategories from "../../../../hooks/reactQuery/useFetchLatestCategories";
import { categoriesLatestOptionsMarkup } from "../../../../utils/markUp";
import { useQueryClient } from "react-query";
import apiClient from "../../../../services/apiClient";
import { useTranslation } from "react-i18next";

const Form = ({
  fields,
  index,
  field,
  removeFieldSet,
  trackTotalValue,
  activeFieldArr,
  setActiveFieldArr,
  isExpanded,
  setExpanded,
  safeBalance,
}) => {
  const { 
    data: userData,
    isLoading: isUserDataLoading,
    isError: isUserDataError
  } = useFetchUserData();

  const {t, i18n} = useTranslation();

  const safesOptions = [...userData.safes].filter(safe => safe.company_id === parseInt(localStorage.getItem("activeCompanyId"))).filter(safe => safe.active).map(obj => {
    return {
      ...obj,
      label: obj.name,
      value: obj.id
    }
  });

  const latestSafes = [...userData.safes].filter(safe => safe.company_id === parseInt(localStorage.getItem("activeCompanyId"))).filter(safe => safe.active).splice(0, 5).map(obj => {
    return {
      ...obj,
      label: obj.name,
      value: obj.id
    }
  });

  const {
    register,
    formState: { errors },
    trigger,
    clearErrors,
    getValues,
    setValue,
    control,
  } = useFormContext();

  const {
    data: categories,
    isLoading: iscategoriesLoading,
    isError: isCategoriesError,
  } = useFetchCategory();

  const { data: financialsData } = useFetchFinancials(
    userData.id,
    userData.activeCompanyId
  );

  const newCategoryOptionMutation = useMutateCategory(
    userData.id,
    userData.activeCompanyId
  );

  //
  //

  const watchAmount = useWatch({
    control,
    name: `withdrawForm[${index}].amount`,
  });

  const calcTotalValue = () => {
    return fields
      .map((field, i) => {
        return +getValues(`withdrawForm[${i}].amount`);
      })
      .reduce((accum, current) => {
        return (accum ? +accum : 0) + (current ? +current : 0);
      });
  };

  useEffect(() => {
    trackTotalValue(calcTotalValue());
  }, [watchAmount, fields.length]);

  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded: isExpanded && activeFieldArr === field.id,
    duration: 700,
  });

  const { data: latestCategories } = useFetchLatestCategories();

  const watchAll = useWatch({
    control,
    name: `withdrawForm`,
  });

  const queryClint = useQueryClient();

  useEffect(() => {
    queryClint.setQueryData("getSafeBalance", (safeTotBalance) => ({
      ...safeTotBalance,
      remainValue:
        safeTotBalance?.originalValue -
        watchAll.reduce((acc, { amount }) => acc + +amount, 0),
    }));
  }, [watchAmount]);

  useEffect(() => {
    setValue(`withdrawForm[${index}].amount`, "");
    clearErrors(`withdrawForm[${index}].amount`);
  }, [getValues(`withdrawForm[${index}].safe_id`)]);

  return (
    <div key={field.id} className="flex w-full mt-4">
      <fieldset className="w-full">
        <div className="border border-muted-light rounded">
          <div
            className={`relative flex items-center justify-between gap-x-20 px-6 py-3 transition-all duration-700 ${
              isExpanded && activeFieldArr === field.id
                ? "border-b"
                : "bg-sidebar-background border-transparent"
            }`}
            {...getToggleProps({
              onClick: () => {
                setActiveFieldArr(field.id);
                setExpanded((prevExpanded) => !prevExpanded);
              },
            })}
          >
            <div
              className={`bg-white p-0.5 rounded-full transition-all duration-700 transform ${
                isExpanded && activeFieldArr === field.id
                  ? "rotate-90"
                  : "rotate-180"
              }`}
            >
              <ChevronArrow />
            </div>

            <div
              className={`w-full hidden xl:grid grid-cols-4 gap-x-4 opacity-0 transition-all duration-700 ${
                (!isExpanded || !activeFieldArr === field.id) && "opacity-100"
              }`}
            >
              <div className="invisible"></div>

              <div className="flex flex-wrap justify-center items-center gap-x-2 sm:h-6 overflow-hidden">
                <div>
                  <SubTitle />
                </div>
                <span className="text-right w-32 h-6 overflow-hidden">
                  {getValues(`withdrawForm[${index}].name`)}
                </span>
              </div>

              <div className="flex flex-wrap justify-center items-center gap-x-2 sm:h-6 overflow-hidden">
                <div>
                  <Cash />
                </div>
                <span className="text-right w-32 h-6 overflow-hidden">
                  {getValues(`withdrawForm[${index}].amount`)}
                </span>
              </div>

              <div className="flex flex-wrap justify-center items-center gap-x-2 sm:h-6 overflow-hidden">
                <div>
                  <DataBase />
                </div>
                <span className="text-right w-32 h-6 overflow-hidden">
                  {getValues(`withdrawForm[${index}].category_id`)?.label}
                </span>
              </div>
            </div>

            <button
              type="button"
              className={fields.length === 1 ? "cursor-not-allowed" : ""}
              onClick={() => {
                removeFieldSet(index);
              }}
              disabled={fields.length === 1}
            >
              <Trash fillColor={fields.length === 1 && "gray"} />
            </button>
          </div>

          <div {...getCollapseProps()}>
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-12 py-5 px-6">
            <div className="custom-grid-1 lg:col-span-9">
                <div>
                  <SelectMenu
                    defaultValue={field.safe_id}
                    name={`withdrawForm[${index}].safe_id`}
                    fieldName={t("safe_name")}
                    label={t("safe_name")}
                    hint={t("pick_safe")}
                    Icon={Safe}
                    latestOptions={latestSafes}
                    LatestOptionsMarkup={categoriesLatestOptionsMarkup(
                      latestSafes,
                      getValues,
                      setValue,
                      trigger,
                      `withdrawForm[${index}].safe_id`,
                      "150px"
                    )}
                    options={safesOptions}
                    isLoading={isUserDataLoading}
                    isError={isUserDataError}
                    Controller={Controller}
                    control={control}
                    required={true}
                    errors={
                      errors?.withdrawForm &&
                      errors?.withdrawForm[index]?.category_id
                    }
                  />
                </div>
              </div>
              <div className="lg:col-span-3 row-span-4 mb-6">
                <UploadInput
                  defaultValue={field.attachments}
                  multiple={true}
                  accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf, image/*"
                  name={`withdrawForm[${index}].attachments`}
                  id={`withdrawForm[${index}].attachments`}
                  showUplodedFilesNames={true}
                  getValues={getValues}
                  fileSize="5000000"
                  errors={
                    errors?.withdrawForm &&
                    errors?.withdrawForm[index]?.attachments
                  }
                  Controller={Controller}
                  control={control}
                  setValue={setValue}
                  trigger={trigger}
                />
              </div>
              <div className="custom-grid-1 lg:col-span-9">
                <div>
                  <Input
                    register={register}
                    defaultValue={field?.name}
                    type="text"
                    name={`withdrawForm[${index}].name`}
                    fieldName={t("transaction_name")}
                    label={t("transaction_name")}
                    placeholder={t("transaction_name")}
                    hint={t("money_request_name_hint")}
                    required={true}
                    maxLength="50"
                    validate={{
                      validateRepetition: (value) => {
                        return (
                          financialsData?.find((el) => {
                            return String(el.name) == String(value);
                          }) &&
                          t("money_request_name_exists")
                        );
                      },
                      minLen:(value)=>/[\u0621-\u064Aa-z]/gi.test(value) || t("transaction_name_no_characters")
                    }}
                    errors={
                      errors?.withdrawForm && errors?.withdrawForm[index]?.name
                    }
                  />
                </div>

                <div>
                  <Input
                    register={register}
                    defaultValue={field?.amount}
                    type="number"
                    name={`withdrawForm[${index}].amount`}
                    fieldName={t("amount")}
                    label={t("amount")}
                    placeholder={t("amount")}    
                    hint={!getValues(`withdrawForm[${index}].safe_id`) || getValues(`withdrawForm[${index}].safe_id`) === "" ? t("pick_safe_first") : t("amount_hint")}
                    validate={{                     
                      maxValue: async(value) => {
                        const { data } = await apiClient.get(
                          "safe/balance",
                          { params: { safe_id: getValues(`withdrawForm[${index}].safe_id.id`) } }
                        );
                        return value > data.balance ? t("max_validation") : true 
                      }
                    }}
                    isDisabled={getValues(`withdrawForm[${index}].safe_id`) === "" || !getValues(`withdrawForm[${index}].safe_id`)}
                    required={true}
                    min={1}
                    max={Number.MAX_SAFE_INTEGER}
                    errors={
                      errors?.withdrawForm &&
                      errors?.withdrawForm[index]?.amount
                    }
                  />
                </div>
              </div>

              <div className="lg:col-span-9">
                <div className="relative">
                  <SelectMenu
                    defaultValue={field.category_id}
                    name={`withdrawForm[${index}].category_id`}
                    fieldName={t("category")}
                    label={t("category")}
                    hint={t("category_hint")}
                    Icon={DataBase}
                    latestOptions={latestCategories}
                    LatestOptionsMarkup={categoriesLatestOptionsMarkup(
                      latestCategories,
                      getValues,
                      setValue,
                      trigger,
                      `withdrawForm[${index}].category_id`,
                      "150px"
                    )}
                    options={categories}
                    isLoading={iscategoriesLoading}
                    isError={isCategoriesError}
                    isCreatable={true}
                    Controller={Controller}
                    control={control}
                    required={true}
                    errors={
                      errors?.withdrawForm &&
                      errors?.withdrawForm[index]?.category_id
                    }
                    newOptionMutation={newCategoryOptionMutation}
                  />
                </div>
              </div>

              <div className="lg:col-span-9">
                <TextArea
                  register={register}
                  defaultValue={field.description}
                  name={`withdrawForm[${index}].description`}
                  fieldName={t("notes")}
                  label={t("notes")}
                  hint={t("notes_hint")}
                  required={false}
                  maxLength="2000"
                  errors={
                    errors?.withdrawForm &&
                    errors?.withdrawForm[index]?.description
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default Form;
