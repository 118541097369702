import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchSafes({
  userId,
  activeCompanyId,
  activeSafeId,
  currentPage,
  pageSize,
  columnKey,
  order,
}) {
  return useQuery(
    ["getAllSafes", activeSafeId, currentPage, pageSize, columnKey, order],
    () => {
      const params = {};
      params.user_id = userId;
      params.company_id = activeCompanyId;
      params.pageno = currentPage;
      params.pagesize = pageSize;
      if (order && columnKey) {
        params.isascending =
          order === "ascend" ? 1 : order === "descend" ? 0 : null;
        params.orderbycolumn = columnKey;
      }
      if(activeSafeId) params.safe_id = activeSafeId;

      return apiClient
        .get(`/safe`, {
          params,
        })
        .then(({ data }) => {
          for (let index = 0; index < data[0].length; index++) {
            const element = data[0][index];
            element.users_json_string = JSON.stringify(element.users);
            element.manager_json_string = JSON.stringify([
              {
                id: element.manager_id,
                name: element.manager_name,
                profile_picture_ext: element.profile_picture_ext
              }
            ]);
          }
          return data;
        });
    },
    { keepPreviousData: true.valueOf, retry: false }
  );
}
