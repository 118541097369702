const CurrentSvg = ({}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M24.8078 13.0015C24.8078 19.5332 19.5293 24.8117 12.9976 24.8117C6.46598 24.8117 1.1875 19.5332 1.1875 13.0015H12.9976V1.19141C19.5293 1.19141 24.8078 6.46989 24.8078 13.0015Z"
        fill="#FFE282"
      />
      <path
        d="M12.9976 24.8117C19.5202 24.8117 24.8078 19.5241 24.8078 13.0015C24.8078 6.47898 19.5202 1.19141 12.9976 1.19141C6.47507 1.19141 1.1875 6.47898 1.1875 13.0015C1.1875 19.5241 6.47507 24.8117 12.9976 24.8117Z"
        stroke="#FFC60B"
        stroke-miterlimit="10"
      />
      <path
        d="M12.9983 18.2427C15.8926 18.2427 18.2388 15.8965 18.2388 13.0022C18.2388 10.108 15.8926 7.76172 12.9983 7.76172C10.1041 7.76172 7.75781 10.108 7.75781 13.0022C7.75781 15.8965 10.1041 18.2427 12.9983 18.2427Z"
        fill="#FFC60B"
      />
      <path
        d="M12.9976 1.19141V13.0015H1.1875"
        stroke="#FFC60B"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export default CurrentSvg;
