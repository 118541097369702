const DataBase = ({}) => {
  return (
    <div>
      <svg
        width={17}
        height={18}
        viewBox="0 0 17 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.8184 9C14.8184 10.245 11.8045 11.25 8.03711 11.25C4.26975 11.25 1.25586 10.245 1.25586 9"
          stroke="#94A3B8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.03711 6C11.7823 6 14.8184 4.99264 14.8184 3.75C14.8184 2.50736 11.7823 1.5 8.03711 1.5C4.29193 1.5 1.25586 2.50736 1.25586 3.75C1.25586 4.99264 4.29193 6 8.03711 6Z"
          stroke="#94A3B8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.25586 3.75V14.25C1.25586 15.495 4.26975 16.5 8.03711 16.5C11.8045 16.5 14.8184 15.495 14.8184 14.25V3.75"
          stroke="#94A3B8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default DataBase;
