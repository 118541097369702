import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Input from "../../components/common/inputs/Input";
import "./style.scss";
import { useMutation, useQuery } from "react-query";
import apiClient from "../../services/apiClient";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Personal = ({ history, next, completed, notCompleted, cancel }) => {
  const {t, i18n} = useTranslation();
  const [canSkip, setCanSkip] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [codeSentTime, setCodeSentTime] = useState(30);
  const [codeSent, setCodeSent] = useState(false);

  useEffect(() => {
    if(codeSent) {
      window.codeSentInterval = setInterval(() => {
        setCodeSentTime(sec => sec - 1);
      }, 1000);
    }
  }, [codeSent]);

  useEffect(() => {
    if(codeSentTime === 0 || codeSentTime < 1) {
      clearInterval(window.codeSentInterval);
      setCodeSent(false);
      setCodeSentTime(30);
    }
  }, [codeSentTime]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,
    getValues,
    setError,
    reset
  } = useForm({
    mode: "all",
    defaultValues: {
      name: "",
      phone: "",
      email: "",
      code: "",
      password: "",
      confirmPassword: ""
    },
  });

  const sendCodeMutation = useMutation(
    (body) => apiClient.post("/request_for_access/sendcodetoemail", body),
    {
      onSuccess: (response) => {
        setCodeSent(true);
      },
      onError: (err) => {
        setCodeSent(false);
      }
    }
  );

  const verifyEmailMutation = useMutation(
    (body) => apiClient.post("/request_for_access/verifyemail", body),
    {
      onSuccess: (response) => {
        setEmailVerified(true);
        return true;
      },
      onError: (err) => {
        setEmailVerified(false);
        if(getValues("code").length >= 6) setError('code', { type: 'custom', message: t("wrong_confirmation_code") });
        return false;
      }
    }
  );

  const handleRegister = (data) => {
    //save data in localStorage
    localStorage.setItem("request_access_personal", JSON.stringify(data));
    completed();
    next();
  };

  useEffect(() => {
    if(localStorage.getItem("request_access_personal")) {
      const personalData = JSON.parse(localStorage.getItem("request_access_personal"));
      reset({
        name: personalData.name,
        phone: personalData.phone,
        email: personalData.email,
        code: "",
        password: personalData.password,
        confirmPassword: personalData.confirmPassword
      });
      setCanSkip(true);
      setEmailVerified(true);
    }

    return () => {
      if (isValid && emailVerified) {
        handleSubmit(handleRegister);
      }

      if(localStorage.getItem("request_access_completed")) {
        const completedTabs = JSON.parse(localStorage.getItem("request_access_completed"));
        if(!isDirty && completedTabs.includes(1)) {
          completed();
        }
      }
    }
  }, []);
  

  return (
    <div className="request_for_access_container">
      <form
        className="space-y-4 divide-y divide-gray-200 mx-auto px-5 max-w-screen-lg w-full"
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="space-y-4 divide-y divide-gray-200">
          <div className="pt-4">
            <fieldset className="w-full">
              <div className="mt-3 grid grid-cols-1 sm:grid-cols-4 gap-y-4 gap-x-4">
                <div className="sm:col-span-2">
                  <Input
                    register={register}
                    type="text"
                    name="name"
                    fieldName={t("full_name")}
                    label={t("full_name")}
                    placeholder={t("full_name")}
                    hint={t("full_name_hint")}
                    required={true}
                    maxLength="50"
                    validate={{
                      validate: (value) => {
                        if(value.trim().split(" ").length !== 2)
                          return t("two_names_validation");
                        else return null;
                      },
                    }}
                    errors={errors?.name}
                  />
                </div>

                <div className="sm:col-span-2">
                  <Input
                    register={register}
                    type="text"
                    name="phone"
                    fieldName={t("phone")}
                    label={t("phone")}
                    placeholder={t("phone")}
                    hint={t("phone_hint")}
                    required={true}
                    maxLength="50"
                    pattern={{
                      regex: /^[0-9]*$/,
                      errorMessage: t("no_spaces_just_numbers"),
                    }}
                    errors={errors?.phone}
                  />
                </div>

                <div className="sm:col-span-4">
                  <Input
                    register={register}
                    type="email"
                    name="email"
                    hint={emailVerified ? t("email_verified") : ""}
                    fieldName={t("email")}
                    label={t("email")}
                    placeholder={t("email")}
                    required={true}
                    maxLength="50"
                    isDisabled={emailVerified}
                    pattern={{
                      regex:
                        /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
                      errorMessage: t("invalid_email"),
                    }}
                    validate={{
                      validateRepetition: async (value) => {
                        const { data } = await apiClient.get(
                          "/request_for_access/checkifexists",
                          { params: { email: value } }
                        );
                        return !data.exists || t("email_exists");
                      },
                    }}
                    errors={errors?.email}
                  />
                </div>
                {!errors.email && getValues("email") !== "" && !emailVerified ?
                <div className={`flex ${codeSent ? "items-center" : "items-end"} sm:col-span-4`}>
                  <div style={{width: "50%"}}>
                    <Input
                      register={register}
                      type="text"
                      name="code"
                      hint={codeSent ? "برجاء التحقق من الكود المكون من 6 ارقام تم ارسالة الى بريدك الالكتروني" : ""}
                      fieldName={t("confirmation_code")}
                      label={t("confirmation_code")}
                      placeholder={t("confirmation_code")}
                      maxLength="6"
                      validate={{
                        validate: async (value) => {
                          const verify = verifyEmailMutation.mutate({email: getValues("email"), code: value});
                          console.log(verify);
                          return null; 
                        },
                      }}
                      errors={errors?.code}
                    />
                  </div> 
                  <div className="flex justify-start items-end">
                    <button className={`request_for_access_send_btn ${codeSent ? "btn_disabled" : ""}`} disabled={codeSent}
                    onClick={() => sendCodeMutation.mutate({email: getValues("email")})}
                    >{t("send")}</button> 
                    {codeSent ? <span className="mx-2" style={{
                      fontWeight: 400,
                      fontSize: "12px",
                      color: "#6B7280"
                      }}>{t("resend_code", {seconds: codeSentTime})}</span> : null}
                  </div>
                </div> : null}

                <div className="sm:col-span-2">
                  <Input
                    register={register}
                    type="password"
                    isPassword={true}
                    name="password"
                    fieldName={t("password")}
                    label={t("password")}
                    placeholder={t("password")}
                    required={true}
                    minLength="8"
                    errors={errors?.password}
                  />
                </div>

                <div className="sm:col-span-2">
                  <Input
                    register={register}
                    type="password"
                    isPassword={true}
                    name="confirmPassword"
                    fieldName={t("password_confirmation")}
                    label={t("password_confirmation")}
                    placeholder={t("password_confirmation")}
                    required={true}
                    validate={{
                      validateRepetition: (value) => {
                        return (
                          value === getValues("password") ||
                          t("password_mismatch")
                        );
                      },
                    }}
                    minLength="8"
                    errors={errors?.confirmPassword}
                  />
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </form>
      <div className="request_for_access_footer">
        <button className={`request_for_access_btn`}
          onClick={cancel}
        >{t("cancel")}</button>
        <div className="flex justify-center items-center">
          <button className={`request_for_access_btn ${!isValid || !isDirty && !canSkip || !emailVerified ? "btn_disabled" : ""}`}
          onClick={handleSubmit(handleRegister)}
          disabled={!isValid || !isDirty && !canSkip || !emailVerified}
          >{t("pagination_controls_next")}</button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Personal);
