import React, { Fragment, useEffect, useMemo, useState } from "react";

import Modal from "../../common/Modal";
import Alert from "../../common/Alert";
import CloseSvg from "../../resources/close svg/CloseSvg";

import "./CloseAndDetails.scss";
import Table from "../../common/Table";

import useFetchUserData from "../../../hooks/reactQuery/useFetchUserData";
import useFetchDocs from "../../../hooks/reactQuery/useFetchDocs";
import useFetchMoneyReqs from "../../../hooks/reactQuery/useFetchMoneyReqs";

import {
  columnsHeaderAll,
  columnsHeaderDocs,
  columnsHeaderMoneyReqs,
} from "./tableColumnsHeader";
import useMutateCloseFinancial from "../../../hooks/reactQuery/useMutateCloseFinancial";
import ExpandedRow from "./ExpandedRow";
import { useTranslation } from "react-i18next";

const CloseAndDetails = ({
  onClose,
  type,
  docId: docOrMoneyReqID,
  activeRow,
  showFooter,
  inValidteQueryKey,
}) => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();

  // Confirm Modal
  const [showAlertConfirmModal, setShowAlertConfirmModal] = useState(false);

  const pageSize = process.env.REACT_APP_PAGE_SIZE;
  const [activeTab, setActiveTab] = useState(0);

  const handleTableChange = (pagination, filters, sorter) => {};

  const onPageChange = (page) => {};

  const [expandedRows, setExpandedRows] = useState([]);
  const onTableRowExpand = (expanded, record) => {
    var keys = [];
    if (expanded) {
      keys.push(record.newId);
    }
    setExpandedRows(keys);
  };

  const {
    isLoading: isDocsLoading,
    data: docsData,
    error: docsError,
  } = useFetchDocs(docOrMoneyReqID);
  const {
    isLoading: isMoneyReqsLoading,
    data: moneyReqsData,
    error: moneyReqsError,
  } = useFetchMoneyReqs(docOrMoneyReqID);

  const [allData, setAllData] = useState([]);
  useEffect(() => {
    let data = [];
    if (docsData) data = data.concat(docsData);
    if (moneyReqsData) data = data.concat(moneyReqsData);
    setAllData(data);
  }, [moneyReqsData, docsData]);

  const memoizedTableData = useMemo(
    () =>
      activeTab === 0
        ? docsData && moneyReqsData
          ? allData
          : docsData
          ? docsData
          : moneyReqsData
          ? moneyReqsData
          : []
        : activeTab === 1
        ? docsData
          ? docsData
          : []
        : activeTab === 2
        ? moneyReqsData
          ? moneyReqsData
          : []
        : [],
    [activeTab, docsData, moneyReqsData, allData]
  );

  const {
    isLoading: isCloseFinancialMutateLoading,
    mutate: CloseFinancialMutate,
    isError: CloseFinancialMutateError,
  } = useMutateCloseFinancial(onClose, inValidteQueryKey);
  const handleCloseFinancial = (activeRowId) => {
    CloseFinancialMutate({ user_id: userData.id, id: activeRowId });
  };

  return (
    <div className="overlay" onClick={() => onClose()}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <div className="bg-white lg:grid lg:grid-cols-12 lg:gap-x-5 close-details-container">
          <div className="col-span-12">
            <div className="sm:rounded-md sm:overflow-hidden">
              <div className="relative text-center p-4">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {type === "closeMoneyRequest"
                    ? t("close_money_request")
                    : t("money_request_details")}
                </h3>
                <button
                  type="button"
                  className="absolute top-4 left-4"
                  onClick={() => onClose()}
                >
                  <div>
                    <CloseSvg />
                  </div>
                </button>
              </div>
              <hr />
              <div className="bg-gray-50 py-6 px-4 space-y-6 sm:p-6">
                {type === "closeMoneyRequest" && (
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      {t("close_your_money_request")}
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      {t("close_your_money_request_text")}
                    </p>
                  </div>
                )}

                <div className="grid grid-cols-4 gap-4">
                  <div className="col-span-2 sm:col-span-1 rounded-lg border border-yellow-500 px-4 py-2">
                    <p className="text-center">{t("num_of_documents")}</p>
                    <p className="text-center">{allData.length}</p>
                  </div>
                  <div className="col-span-2 sm:col-span-1 rounded-lg border border-blue-600 px-4 py-2">
                    <p className="text-center">{t("original_amount")}</p>
                    <p className="text-center">{activeRow.amount}</p>
                  </div>
                  <div className="col-span-2 sm:col-span-1 rounded-lg border border-red-500 px-4 py-2">
                    <p className="text-center">{t("spent_amount")}</p>
                    <p className="text-center">
                      {activeRow.amount - activeRow.remaining_amount}
                    </p>
                  </div>
                  <div className="col-span-2 sm:col-span-1 rounded-lg border border-green-400 px-4 py-2">
                    <p className="text-center">{t("current_amount")}</p>
                    <p className="text-center">{activeRow.remaining_amount}</p>
                  </div>
                </div>
              </div>
              <nav
                className="grid grid-cols-5 gap-4 bg-gray-50"
                aria-label="Tabs"
              >
                <button
                  type="button"
                  className={`col-span-7 sm:col-span-1 sm:col-start-2 text-gray-700 px-3 py-2 font-medium text-sm rounded-md ${
                    activeTab === 0 && "bg-gray-150 text-indigo-500"
                  }`}
                  onClick={() => setActiveTab(0)}
                >
                  {t("all_documents")}
                </button>
                <button
                  type="button"
                  className={`col-span-7 sm:col-span-1 sm:col-start-3 text-gray-700 px-3 py-2 font-medium text-sm rounded-md ${
                    activeTab === 1 && "bg-gray-150 text-indigo-500"
                  }`}
                  onClick={() => setActiveTab(1)}
                >
                  {t("documents")}
                </button>
                <button
                  type="button"
                  className={`col-span-7 sm:col-span-1 sm:col-start-4 text-gray-700 px-3 py-2 font-medium text-sm rounded-md ${
                    activeTab === 2 && "bg-gray-150 text-indigo-500"
                  }`}
                  onClick={() => setActiveTab(2)}
                >
                  {t("accepted_money_requests")} 
                </button>
              </nav>
              <div
                className="bg-gray-50"
                style={{ height: "50vh", overflow: "auto" }}
              >
                <Table
                  rowKey="newId"
                  data={memoizedTableData}
                  columns={
                    activeTab === 0
                      ? columnsHeaderAll(t, i18n)
                      : activeTab === 1
                      ? columnsHeaderDocs(t, i18n)
                      : activeTab === 2
                      ? columnsHeaderMoneyReqs(t, i18n)
                      : null
                  }
                  pageSize={pageSize}
                  isLoading={isMoneyReqsLoading || isDocsLoading}
                  error={moneyReqsError || docsError}
                  currentPage={1}
                  total={0}
                  hasFooter={false}
                  isPaginationDisabled={true}
                  onTableRowExpand={onTableRowExpand}
                  expandedRows={expandedRows}
                  setExpandedRows={setExpandedRows}
                  onPageChange={onPageChange}
                  onChange={handleTableChange}
                  expandedRow={(row) => (
                    <ExpandedRow row={row} activeTab={activeTab} />
                  )}
                />
              </div>
              {showFooter && (
                <div className="grid grid-cols-8 gap-5 px-4 py-3 bg-white text-right sm:px-6">
                  <Fragment>
                    <button
                      type="button"
                      className="col-span-8 sm:col-span-2 sm:col-start-3 gap-6 bg-black py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white"
                      onClick={() => setShowAlertConfirmModal(true)}
                    >
                    {t("close_money_request")}
                    </button>
                    <Modal
                      showModal={showAlertConfirmModal}
                      onClose={() => setShowAlertConfirmModal(false)}
                    >
                      <Alert
                        hasFooter={true}
                        alertType="confirm"
                        title={t("confirm_close_money_request")}
                        body={() => (
                          <div>
                            {t("alert_text_confirm_close_money_request", {amount: activeRow.remaining_amount})}
                          </div>
                        )}
                        confirmText={t("confirm_close_money_request")}
                        cancelText={t("cancel")}
                        handleConfirm={() => handleCloseFinancial(activeRow.id)}
                        handleCancel={() =>
                          !isCloseFinancialMutateLoading &&
                          setShowAlertConfirmModal(false)
                        }
                        isSubmitLoading={isCloseFinancialMutateLoading}
                      />
                    </Modal>
                  </Fragment>

                  <Fragment>
                    <button
                      type="button"
                      className="col-span-8 sm:col-span-2 sm:col-start-5 gap-6 bg-white py-2 px-4 border border-red-400 rounded-md shadow-sm text-sm font-medium text-red-500 hover:bg-gray-50"
                      onClick={() => onClose()}
                    >
                    {t("cancel")}
                    </button>
                  </Fragment>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloseAndDetails;
