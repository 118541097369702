const RejectedSvg = ({}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0013 2.16667C7.0213 2.16667 2.16797 7.02001 2.16797 13C2.16797 18.98 7.0213 23.8333 13.0013 23.8333C18.9813 23.8333 23.8346 18.98 23.8346 13C23.8346 7.02001 18.9813 2.16667 13.0013 2.16667Z"
        fill="#FF6161"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4792 9.75677L16.5245 8.80208L12.7396 12.587L8.95469 8.80208L8 9.75677L11.7849 13.5417L8 17.3266L8.95469 18.2812L12.7396 14.4963L16.5245 18.2812L17.4792 17.3266L13.6943 13.5417L17.4792 9.75677Z"
        fill="white"
        stroke="white"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default RejectedSvg;
