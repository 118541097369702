const Cancel = () => {
  return (
    <div>
      <svg
        width={48}
        height={48}
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width={48} height={48} rx={24} fill="#FFD4D4" />
        <path
          d="M18 30L30 18M18 18L30 30"
          stroke="#DC2626"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Cancel;
