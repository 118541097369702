import React, { useState } from "react";
import Alert from "../../common/Alert";
import Modal from "../../common/Modal";
import Respond from "../../paymentLicense/Respond";
import Hand from "../../resources/icons/Hand";
import Accept from "../../resources/icons/Accept";
import Reject from "../../resources/icons/Reject";
import Redirect from "../../resources/icons/Redirect";
import useFetchUserData from "../../../hooks/reactQuery/useFetchUserData";
import useMutatePoke from "../../../hooks/reactQuery/useMutatePoke";
import useMutateAcceptPaymentLicense from "./../../../hooks/reactQuery/useMutateAcceptPaymentLicense";
import useMutateRejectPaymentLicense from "./../../../hooks/reactQuery/useMutateRejectPaymentLicense";
import { getAttachments } from "../../../utils/getAttachments";
import "./ExpandedRow.scss";
import folders from "../../../constants/static_folders";
import { useTranslation } from "react-i18next";


const ExpandedRow = ({ row, activeTab }) => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();

  const [showRedirect, setShowRedirect] = useState(false);
  const [showRespond, setShowRespond] = useState(false);

  const [respondType, setRespondType] = useState("");

  const {
    isLoading: isMutateAcceptLoading,
    mutate: mutateAccept,
    isError: mutateAcceptError,
  } = useMutateAcceptPaymentLicense(
    setShowRespond,
    userData.id,
    userData.activeCompanyId
  );

  const {
    isLoading: isMutateRejectLoading,
    mutate: mutateReject,
    isError: mutateRejectError,
  } = useMutateRejectPaymentLicense(
    setShowRespond,
    userData.id,
    userData.activeCompanyId
  );

  const {
    isLoading: isMutatePokeLoading,
    mutate: mutatePoke,
    isError: mutatePokeError,
  } = useMutatePoke({
    userId: userData.id,
    forward: row.request_chain,
    row: row,
  });

  const alreadyPoked = (request) => {
    if (request.created_by === userData.id) {
      const difference = Math.abs(new Date() - new Date(request.poke_date));
      const dayDifference = Math.floor(difference / (1000 * 60 * 60 * 24));
      return dayDifference < 1;
    } else {
      const forward = row.request_chain?.find(
        (req) => req.spend_request_id === request.id && req.from === userData.id
      );
      const difference = Math.abs(new Date() - new Date(forward?.poke_date));
      const dayDifference = Math.floor(difference / (1000 * 60 * 60 * 24));
      return dayDifference < 1;
    }
  };

  return (
    <div className={`text-body text-xs font-medium grid grid-cols-6 gap-4 ${i18n.resolvedLanguage === "en" ? "text-left ml-10" : "text-right mr-10"}`} style={{maxWidth: "800px"}}>
      <div
        className={`col-span-6 flex ${
          row.approved === 1
            ? "justify-between items-center pl-24"
            : "grid grid-cols-6"
        }`}
      >
        <div>
          <p>{t("transaction_name")}</p>
          <p className="text-hints">{row.name}</p>
        </div>

        {row.approved === 1 && (
          <div>
            <p>{t("send_date")}</p>
            <p className="text-hints">{row.created_at.split("T")[0]}</p>
          </div>
        )}
        <div>
          <p>{t("required_response_time")}</p>
          <p className="text-hints">{row.request_date.split("T")[0]}</p>
        </div>
        {row.approved === null && activeTab === 1 && row.deactivated != 1 && (
          <div className="flex justify-end gap-x-4 col-start-6 row-start-1">
            {userData.permission_tags.includes("ForwardPurchaseRequest") ? 
              <button
                onClick={() => {
                  setShowRedirect(true);
                }}
                className="pay-lic-redirect-btn transition-all duration-300 hover:border-blue-500 border border-body h-3 flex items-center justify-center p-2 rounded-md"
              >
                <span className="absolute left-4 h-4 w-max overflow-hidden">
                  {t("forward")}
                </span>
                <div className="absolute left-px pay-lic-redirect">
                  <Redirect />
                </div>
              </button>
            : null}

            {userData.permission_tags.includes("AcceptOrRejectSpendRequest") && row.deactivated != 1 ? 
            <React.Fragment>
              <button
                onClick={() => {
                  setRespondType("reject");
                  setShowRespond(true);
                }}
                className="pay-lic-reject-btn transition-all duration-300 hover:border-blue-500 border border-body h-3 flex items-center justify-center p-2 rounded-md"
              >
                <span className="absolute left-4">{t("reject")}</span>
                  <div className="absolute -left-1 pay-lic-reject">
                    <Reject />
                  </div>
              </button>
        
              <button
                onClick={() => {
                  setRespondType("accept");
                  setShowRespond(true);
                }}
                className="pay-lic-accept-btn transition-all duration-300 hover:border-blue-500 border border-body h-3 flex items-center justify-center p-2 rounded-md"
              >
                <span className="absolute left-4">{t("accept")}</span>
                <div className="absolute pay-lic-accept">
                  <Accept />
                </div>
              </button>
            </React.Fragment>
            : null }
          </div>
        )}
        {activeTab === 0 && row.approved === null && row.deactivated != 1 && (
          <div className="col-start-6 flex justify-end row-start-1">
            <button
              onClick={() => {
                mutatePoke();
              }}
              disabled={isMutatePokeLoading || alreadyPoked(row)}
              className={`pay-lic-poke-btn transition-all duration-300 hover:border-blue-500 border border-body flex items-center justify-center p-2 rounded-md ${
                (isMutatePokeLoading || alreadyPoked(row)) &&
                "cursor-not-allowed disabled bg-50"
              }`}
            >
              <span className="absolute left-6">{t("send_ticket")}</span>
              <div className="absolute pay-lic-poke p-px">
                <Hand />
              </div>
            </button>
          </div>
        )}
      </div>

      <div className="col-span-6">
        <p>{t("notes")}</p>
        <p className="text-hints">
          {row.notes ? row.notes : t("no_notes")}
        </p>
      </div>
      {row.original_attachments && (
        <div className="col-span-6">
          {getAttachments({
            attachProperity: row.original_attachments,
            attachUrl: folders.spend_requests,
            rowId: row.id,
          })}
        </div>
      )}

      <Modal showModal={showRedirect} onClose={() => setShowRedirect(false)}>
        <Respond onClose={() => setShowRedirect(false)} activeRow={row} />
      </Modal>

      <Modal showModal={showRespond} onClose={() => setShowRespond(false)}>
        <Alert
          hasFooter={true}
          alertType={respondType === "accept" ? "confirm" : "cancel"}
          title={respondType === "accept" ? t("spend_request_accept") : t("spend_request_reject")}
          body={() => (
            <div>
              {respondType === "accept" ? (
                <p>
                  {t("alert_text_accept_spend_request")}
                </p>
              ) : (
                <p>
                  {t("alert_text_reject_spend_request", {sender_name: row.sender_name})}
                </p>
              )}
            </div>
          )}
          confirmText={respondType === "accept" ? t("accept") : t("reject")}
          cancelText="إلغاء"
          handleConfirm={
            respondType === "accept"
              ? () => mutateAccept(row)
              : () => mutateReject(row)
          }
          handleCancel={() => setShowRespond(false)}
          isSubmitLoading={
            respondType === "accept"
              ? isMutateAcceptLoading
              : respondType === "reject"
              ? isMutateRejectLoading
              : false
          }
        />
      </Modal>
    </div>
  );
};

export default ExpandedRow;
