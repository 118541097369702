import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchUsers(
  userId,
  customKey,
  showFiltering,
  staleTime
) {
  return useQuery(
    customKey ? customKey : "users",
    () =>
      apiClient?.get(`user/load`).then(({ data }) => {
        return data
          .filter((user) => user.id != userId)
          .map((user) => ({
            ...user,
            label: user.name,
            value: user.id,
            url:
              user.id !== null
                ? (process.env.REACT_APP_UPLOAD_FILES_BASE_URL
                    ? process.env.REACT_APP_UPLOAD_FILES_BASE_URL
                    : "") +
                  (process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
                    ? process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
                    : "") +
                  `users/${user.id}/profilepicture` +
                  `.${user.profile_picture_ext}`
                : "../img/default-user.svg",
          }));
      }),
    {
      enabled: showFiltering,
      staleTime,
    }
  );
}
