import TableResult from "../../components/common/TableResults"
import Error404 from "../../components/resources/icons/Error404"
import { useHistory } from 'react-router-dom'
const NotFound = () => {
  
  const history = useHistory()
  return (
    <>
      <div className="mt-16"> 
      <TableResult Icon={Error404} title="أوبس" text = "هذه الصحفة يتعذر الوصول إليها الان ، من فضلك عاود المحاولة في وقت لاحق"
            button={true}
            color="#1E293B"
            buttonText='عودة للرئيسية'
            action={() => {
              history.push('/home')
            }}
            />
      </div>
    </>
  );
};

export default NotFound;
