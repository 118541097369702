import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { formatDate } from '../../../../utils/convertDate';
import useFetchUserData from "../../../../hooks/reactQuery/useFetchUserData";
import "../dashboard.scss";

export default function OnHoldRow({data}) {
  const history = useHistory();
  const formattedDate = formatDate(data.creation_date);
  const { data: userData } = useFetchUserData();

  const viewableSafes = userData.safes.filter(s => s.canView);
  const canViewCurrentSafe = viewableSafes.find(safe => safe.id === data.safe_id);

  const [noImg, setNoImg] = useState(false);
  const clickHandler = () => {
    if(data.type == 1)
    history.push({
      pathname: `/safes/${data.safe_id}/cashin/${data.id}`,
      state: { comingFrom: 'العمليات المعلقة', fallback: "/safe", title: "طلب ايداع"}
    });
    else if (data.type == 0) 
      history.push({
        pathname: `/safes/${data.safe_id}/money_request/${data.id}`,
        state: { comingFrom: 'العمليات المعلقة', fallback: "/safe", title: "طلب سحب"}
      });
  }
  return ( canViewCurrentSafe ? 
    <div className='pending-row' onClick={clickHandler}>
      <div className='pending-row-info'>
        {data.type == 1 ? <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.985 4.375H8.515C7.88 4.375 7.5 3.995 7.5 3.36V1.89C7.5 1.255 7.88 0.875 8.515 0.875H9.985C10.62 0.875 11 1.255 11 1.89V3.36C11 3.995 10.62 4.375 9.985 4.375ZM10.095 2.72C10.035 2.66 9.955 2.63 9.875 2.63C9.795 2.63 9.715 2.66 9.655 2.72L9.565 2.81V1.69C9.565 1.515 9.425 1.375 9.25 1.375C9.075 1.375 8.935 1.515 8.935 1.69V2.81L8.845 2.72C8.725 2.6 8.525 2.6 8.405 2.72C8.285 2.84 8.285 3.04 8.405 3.16L9.03 3.785C9.055 3.81 9.09 3.83 9.125 3.845C9.135 3.85 9.145 3.85 9.155 3.855C9.18 3.865 9.205 3.87 9.235 3.87C9.245 3.87 9.255 3.87 9.265 3.87C9.3 3.87 9.33 3.865 9.365 3.85C9.37 3.85 9.37 3.85 9.375 3.85C9.41 3.835 9.44 3.815 9.465 3.79C9.47 3.785 9.47 3.785 9.475 3.785L10.1 3.16C10.22 3.04 10.22 2.84 10.095 2.72Z" fill="#42EBB3"/>
          <path d="M1 5.73V8.23C1 9.375 1.925 10.3 3.07 10.3H8.925C10.07 10.3 11 9.37 11 8.225V5.73C11 5.395 10.73 5.125 10.395 5.125H1.605C1.27 5.125 1 5.395 1 5.73ZM4 8.625H3C2.795 8.625 2.625 8.455 2.625 8.25C2.625 8.045 2.795 7.875 3 7.875H4C4.205 7.875 4.375 8.045 4.375 8.25C4.375 8.455 4.205 8.625 4 8.625ZM7.25 8.625H5.25C5.045 8.625 4.875 8.455 4.875 8.25C4.875 8.045 5.045 7.875 5.25 7.875H7.25C7.455 7.875 7.625 8.045 7.625 8.25C7.625 8.455 7.455 8.625 7.25 8.625Z" fill="#42EBB3"/>
          <path d="M6.75 2.30495V3.76995C6.75 4.10495 6.48 4.37495 6.145 4.37495H1.605C1.265 4.37495 1 4.09495 1 3.75995C1.005 3.19495 1.23 2.67995 1.605 2.30495C1.98 1.92995 2.5 1.69995 3.07 1.69995H6.145C6.48 1.69995 6.75 1.96995 6.75 2.30495Z" fill="#42EBB3"/>
        </svg> : <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.985 0.875H8.515C7.88 0.875 7.5 1.255 7.5 1.89V3.36C7.5 3.995 7.88 4.375 8.515 4.375H9.985C10.62 4.375 11 3.995 11 3.36V1.89C11 1.255 10.62 0.875 9.985 0.875ZM10.095 2.53C10.035 2.59 9.955 2.62 9.875 2.62C9.795 2.62 9.715 2.59 9.655 2.53L9.565 2.44V3.56C9.565 3.735 9.425 3.875 9.25 3.875C9.075 3.875 8.935 3.735 8.935 3.56V2.44L8.845 2.53C8.725 2.65 8.525 2.65 8.405 2.53C8.285 2.41 8.285 2.21 8.405 2.09L9.03 1.465C9.055 1.44 9.09 1.42 9.125 1.405C9.135 1.4 9.145 1.4 9.155 1.395C9.18 1.385 9.205 1.38 9.235 1.38C9.245 1.38 9.255 1.38 9.265 1.38C9.3 1.38 9.33 1.385 9.365 1.4C9.37 1.4 9.37 1.4 9.375 1.4C9.41 1.415 9.44 1.435 9.465 1.46C9.47 1.465 9.47 1.465 9.475 1.465L10.1 2.09C10.22 2.21 10.22 2.41 10.095 2.53Z" fill="#FFCD86"/>
          <path d="M1 5.73V8.23C1 9.375 1.925 10.3 3.07 10.3H8.925C10.07 10.3 11 9.37 11 8.225V5.73C11 5.395 10.73 5.125 10.395 5.125H1.605C1.27 5.125 1 5.395 1 5.73ZM4 8.625H3C2.795 8.625 2.625 8.455 2.625 8.25C2.625 8.045 2.795 7.875 3 7.875H4C4.205 7.875 4.375 8.045 4.375 8.25C4.375 8.455 4.205 8.625 4 8.625ZM7.25 8.625H5.25C5.045 8.625 4.875 8.455 4.875 8.25C4.875 8.045 5.045 7.875 5.25 7.875H7.25C7.455 7.875 7.625 8.045 7.625 8.25C7.625 8.455 7.455 8.625 7.25 8.625Z" fill="#FFCD86"/>
          <path d="M6.75 2.30495V3.76995C6.75 4.10495 6.48 4.37495 6.145 4.37495H1.605C1.265 4.37495 1 4.09495 1 3.75995C1.005 3.19495 1.23 2.67995 1.605 2.30495C1.98 1.92995 2.5 1.69995 3.07 1.69995H6.145C6.48 1.69995 6.75 1.96995 6.75 2.30495Z" fill="#FFCD86"/>
        </svg>}
        {noImg ? 
          <div className='user-circle'>{data.user_name.charAt(0).toUpperCase()}</div>
        : <img className='pending-row-img' src={data.profile_picture_url} onError={({currentTarget}) => {
          currentTarget.onerror = null; 
          setNoImg(true);
        }} /> }
        <span className='pending-row-name'>{data.user_name}</span>
      </div>

      <span className='pending-row-safe-name'>{data.safe_name}</span>

      <span className='pending-row-category'>___</span>
      <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-end", width: "20%"}}>
        <span className='pending-row-amount'>{new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP',  maximumSignificantDigits: 12}).format(data.amount)}</span>
        <span className='pending-row-date'>{formattedDate}</span>
      </div>
    </div> : null
  );
}