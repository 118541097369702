import { useState, useEffect, useReducer, useMemo } from "react";

import { useQueryClient } from "react-query";

import HeaderSection from "../../../components/common/HeaderSection";
import Table from "../../../components/common/Table";
import ExpandedRow from "../../../components/dashboard/ExpandedRow";
import Modal from "../../../components/common/Modal";
import Modify from "../../../components/paymentLicense/Modify";
import Filters from "../../../components/paymentLicense/Filters";

import useFetchUserData from "../../../hooks/reactQuery/useFetchUserData";
import useFetchPayments from "../../../hooks/reactQuery/useFetchPayments";
import useMutateArchivePayLicense from "../../../hooks/reactQuery/useMutateArchivePayLicense";
import useMutatePaymentLicenseUpdateChecked from "../../../hooks/reactQuery/useMutatePaymentLicenseUpdateChecked";

import actionTypes from "../../../context/types";
import { useDataLayerValue } from "../../../context/DataLayer";

import {
  columnsHeaderReceived,
  columnsHeaderOutTable,
} from "./tableColumnsHeader.js";
import { useHistory } from "react-router-dom";
import Alert from "../../../components/common/Alert";
import useMutateHoldSpendRequest from "../../../hooks/reactQuery/useMutateHoldSpendRequest";
import { useTranslation } from "react-i18next";

const pageSize = process.env.REACT_APP_PAGE_SIZE;

const PaymentLicenses = ({}) => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();

  const history = useHistory();
  const [{ activeTab, searchType, searchTerms }, dispatch] = useDataLayerValue();
  
  if(activeTab == 0 && !userData.permission_tags.includes("SendPurchaseRequest")) {
    dispatch({
      type: actionTypes.ACTIVE_TAB,
      payload: 1,
    });
  }

  const [hoverId, setHoverId] = useState(null);

  const [activeDeactiveId, setActiveDeactiveId] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);

  const [showPaymentLicenseModifyModal, setShowPaymentLicenseModifyModal] = useState(false);

  const [activeRow, setActiveRow] = useState({});

  const [columnsHeaderIn, setColumnsHeaderIn] = useState(() => columnsHeaderReceived(
    setActiveDeactiveId, 
    setShowDeactivateModal, 
    userData,
    hoverId,
    t,
    i18n
  ));

  const [columnsHeaderOut, setColumnsHeaderOut] = useState(
    columnsHeaderOutTable(
      setShowPaymentLicenseModifyModal, 
      setActiveRow, 
      setActiveDeactiveId, 
      setShowDeactivateModal, 
      userData,
      hoverId,
      t,
      i18n
    )
  );

  /*useEffect(() => {
    setColumnsHeaderIn(columnsHeaderReceived(
      setActiveDeactiveId, 
      setShowDeactivateModal, 
      userData,
      hoverId,
      t,
      i18n
    ));

    setColumnsHeaderOut(columnsHeaderOutTable(
      setShowPaymentLicenseModifyModal, 
      setActiveRow, 
      setActiveDeactiveId, 
      setShowDeactivateModal, 
      userData,
      hoverId,
      t,
      i18n
    ));
  }, [hoverId]);*/

  const [currentPage, setCurrentPage] = useState(1);
  const [columnKey, setColumnKey] = useState("");
  const [order, setOrder] = useState("");

  
  // handling filters
  const filtersInitState = {
    users: [],
    categories: [],
    approved: [],
    fromAmount: null,
    toAmount: null,
    fromSendDate: null,
    toSendDate: null,
    fromMaxResponseDate: null,
    toMaxResponseDate: null,
    fromResponseDate: null,
    toResponseDate: null,
  };

  function filtersReducer(prevState, { type, payload }) {
    return {
      ...prevState,
      ...payload,
    };
  }

  const [filtersState, dispatchFiltrers] = useReducer(
    filtersReducer,
    filtersInitState
  );

  useEffect(() => {
    dispatchFiltrers({
      // type: "CHANGESTATE",
      payload: {
        users: [],
        categories: [],
        approved: [],
        fromAmount: null,
        toAmount: null,
        fromSendDate: null,
        toSendDate: null,
        fromMaxResponseDate: null,
        toMaxResponseDate: null,
        fromResponseDate: null,
        toResponseDate: null,
      },
    });
    dispatch({
      type: actionTypes.SEARCH_TYPE,
      payload: "",
    });
    dispatch({
      type: actionTypes.SEARCH_TERMS,
      payload: [],
    });
    setOrder("");
    setColumnKey("");
  }, [activeTab]);
  //
  //

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTab, order, columnKey, searchType, searchTerms, filtersState]);

  const {
    isLoading: isTableDataLoading,
    data: tableData,
    error: tableDataError,
    isPreviousData,
  } = useFetchPayments({
    activeTab,
    currentPage,
    pageSize,
    order,
    columnKey,
    searchType,
    searchTerms,
    users: filtersState.users,
    categories: filtersState.categories,
    isapproved: filtersState.approved,
    fromAmount: filtersState.fromAmount,
    toAmount: filtersState.toAmount,
    fromSendDate: filtersState.fromSendDate,
    toSendDate: filtersState.toSendDate,
    fromMaxResponseDate: filtersState.fromMaxResponseDate,
    toMaxResponseDate: filtersState.toMaxResponseDate,
    fromResponseDate: filtersState.fromResponseDate,
    toResponseDate: filtersState.toResponseDate,
  });

  const memoizedTableData = useMemo(
    () => (tableData ? tableData[0] : []),
    [tableData]
  );

  const handleTableChange = (pagination, filters, sorter) => {
    setOrder(sorter?.order);
    setColumnKey(sorter?.columnKey);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const { mutate: mutatePaymentLicenseUpdateChecked } =
    useMutatePaymentLicenseUpdateChecked(userData.id, userData.activeCompanyId);


  const queryClient = useQueryClient();

  const [expandedRows, setExpandedRows] = useState([]);
  const onTableRowExpand = (expanded, record) => {
    var keys = [];
    if (expanded) {
      keys.push(record.id);
      setActiveRow(record);
    }
    setExpandedRows(keys);

    // remove unread effect if the user expanded the row
    if (expanded) {
      if (record.recent != null) {
        if (record.recent !== 0) {
          let expandedRecordData = tableData[0].find((datum) => {
            return datum.id === record.id;
          });
          expandedRecordData.recent = 0;

          queryClient.setQueryData(
            [
              "getPayments",
              activeTab,
              currentPage,
              pageSize,
              order,
              columnKey,
              searchType,
              searchTerms,
            ],
            tableData
          );

          // notify the server that the user expanded the row
          let paymentLicense = expandedRecordData;
          let paymentLicenseType = activeTab;
          mutatePaymentLicenseUpdateChecked({
            paymentLicense,
            paymentLicenseType,
          });
        }
      }
    }
  };

  const [selectedRows, setSelectedRows] = useState([]);
  const mutateArchiving = useMutateArchivePayLicense();

  const { isLoading: isMutateHoldLoading, mutate: mutateHold } =
  useMutateHoldSpendRequest(() => setShowDeactivateModal(false));

  return (
    <div className="bg-white">
      <HeaderSection
        searchDropDownApiUrl={"spend_request/dropdowndata"}
        activeTab={activeTab}
        setColumns={activeTab === 0 ? setColumnsHeaderOut : setColumnsHeaderIn}
        originalColumnsHeader={
          activeTab === 0
            ? columnsHeaderOutTable(
                setShowPaymentLicenseModifyModal,
                setActiveRow,
                setActiveDeactiveId, 
                setShowDeactivateModal,
                userData,
                hoverId,
                t,
                i18n
              )
            : columnsHeaderReceived(
              setActiveDeactiveId, 
              setShowDeactivateModal, 
              userData,
              hoverId,
              t,
              i18n
            )
        }
        selectedRows={selectedRows}
        excelFileName={t("spend_requests")}
        mutateArchiving={mutateArchiving}
        FiltersComponent={Filters}
        dispatchFiltrers={dispatchFiltrers}
        filtersState={filtersState}
      />

      <div className="mx-4 my-3">
        <Table
          rowKey="id"
          data={memoizedTableData}
          columns={(activeTab === 0
            ? columnsHeaderOut
            : columnsHeaderIn
          ).filter((el) => el.selected || el.pinned)}
          setHoverId={setHoverId}
          isLoading={isTableDataLoading}
          error={tableDataError}
          pageSize={pageSize}
          currentPage={currentPage}
          total={tableData && tableData[1] && tableData[1][0]?.overall_count}
          isPaginationDisabled={isPreviousData}
          onPageChange={onPageChange}
          onChange={handleTableChange}
          setSelectedRows={setSelectedRows}
          expandedRows={expandedRows}
          setExpandedRows={setExpandedRows}
          onTableRowExpand={onTableRowExpand}
          buttonEmptyPage={activeTab == 0 ? true : false}
          textEmptyPage={
            activeTab == 0
              ? t("no_sent_spend_requests")
              : t("no_received_spend_requests")
          }
          textButtonEmptyPage={t("send_spend_request")}
          actionEmptyPage={() => {
            history.replace("/payment-license");
          }}
          expandedRow={(row, index, indent, isExpanded) => (
            <ExpandedRow row={row} activeTab={activeTab} />
          )}
        />
      </div>
      <Modal
        showModal={showPaymentLicenseModifyModal}
        onClose={() => setShowPaymentLicenseModifyModal(false)}
      >
        <Modify
          onClose={() => setShowPaymentLicenseModifyModal(false)}
          activeRow={activeRow}
        />
      </Modal>
      <Modal
        showModal={showDeactivateModal}
        onClose={() => setShowDeactivateModal(false)}
      >
        <Alert
          hasFooter={true}
          alertType="cancel"
          title={t("block_transaction")}
          body={() => (
            <div>
            {t("alert_text_block")}
            </div>
          )}
          confirmText={t("block_transaction")}
          cancelText={t("cancel")}
          isSubmitLoading={isMutateHoldLoading}
          handleConfirm={() => mutateHold(activeDeactiveId)}
          handleCancel={() => !isMutateHoldLoading && setShowDeactivateModal(false)}
        />
      </Modal>
    </div>
  );
};

export default PaymentLicenses;
