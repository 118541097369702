import { useState, Fragment } from "react";
import {useHistory} from 'react-router-dom';
import Modal from "../../../../common/Modal";
import Alert from "../../../../common/Alert";
import Accept from "../../../../resources/icons/Accept";
import Reject from "../../../../resources/icons/Reject";

import useFetchUserData from "../../../../../hooks/reactQuery/useFetchUserData";
import useMutateWithdrawAccept from "../../../../../hooks/reactQuery/useMutateWithdrawAccept";
import useMutateWithdrawReject from "../../../../../hooks/reactQuery/useMutateWithdrawReject";

import { convertDate } from "./../../../../../utils/convertDate";
import { getAttachments } from "../../../../../utils/getAttachments";

import folders from "../../../../../constants/static_folders";

import "./ExpandedRowFullControl.scss";
import CloseAndDetails from "../../../../moneyRequest/CloseAndDetails";
import DocumentExpenseSvg from "../../../../resources/document expense svg/DocumentExpenseSvg";
import LockSvg from "../../../../resources/lock svg/LockSvg";
import BlueDotSvg from "../../../../resources/blue dot svg/BlueDotSvg";
import { useTranslation } from "react-i18next";

const ExpandedRowFullControl = ({ row, safeId, isOwner }) => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();

  const history = useHistory();
  const currentSafe = userData.safes.find(safe => safe.id == safeId); 

  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);

  const { isLoading: isAcceptLoading, mutate: mutateAccept } =
    useMutateWithdrawAccept(setShowAcceptModal, safeId);

  const { isLoading: isRejectLoading, mutate: mutateReject } =
    useMutateWithdrawReject(setShowRejectModal, safeId);

  const [showCloseAndDetails, setShowCloseAndDetails] = useState(false);
  const [showFooter, setShowFooter] = useState(false);

  return (
    <div className={`grid grid-cols-6 gap-4 ${i18n.resolvedLanguage === "en" ? "text-left ml-10" : "text-right mr-10"}`}>
      <div className="col-span-1 row-start-1">
        <p className="text-xs text-body font-bold">{t("transaction_name")}</p>
        <p className="text-sm text-hints font-medium mt-1">{row.name}</p>
      </div>

      {row.recipient_id && row.recipient_profile_picture_url ? <div className="col-span-1">
        <b>{t("recipient")}</b>
        <div className="flex justify-center align-center">
          <img src={row.recipient_profile_picture_url}  style={{width: "30px", height: "30px", borderRadius: "50%", margin: "0px 5px;"}}/>
          <p>{row.recipient_name ? row.recipient_name : null}</p>
        </div>
      </div> : null }

      {row.safe_manager_name && row.safe_manager_profile_picture_url && row.approved !== 1 ? <div className="col-span-1">
        <b>{t("safe_manager")}</b>
        <div className="flex justify-center align-center">
          <img src={row.safe_manager_profile_picture_url}  style={{width: "30px", height: "30px", borderRadius: "50%", margin: "0px 5px;"}}/>
          <p>{row.safe_manager_name ? row.safe_manager_name : null}</p>
        </div>
      </div> : null}

      {row.creator_balance ? <div className="col-span-1">
        <p className="text-xs text-body font-bold">{t("user_balance", {user_name: row.creator_name})}</p>
        <p className="text-sm text-hints font-medium mt-1">
          {row.creator_balance} {t("egp")}
        </p>
      </div> : null }

      {row.sender_balance ? <div className="col-span-1">
        <p className="text-xs text-body font-bold">{t("user_balance", {user_name: row.sender_name})}</p>
        <p className="text-sm text-hints font-medium mt-1">
          {row.sender_balance} {t("egp")}
        </p>
      </div> : null }

      {row.approved !== null && (
        <div className="col-span-1">
          <p className="text-xs text-body font-bold">{t("response_date")}</p>
          <p className="text-sm text-hints font-medium mt-1">
            {convertDate(row.response_date)}
          </p>
        </div>
      )}

      {row.deactivated && row.deactivation_date ? (
        <div className="col-span-1">
          <p className="text-xs text-body font-bold">{t("block_date")}</p>
          <p className="text-sm text-hints font-medium mt-1">
            {convertDate(row.deactivation_date)}
          </p>
        </div>
      ) : null}

      {row.approved === 1 && (
        <div className="col-span-1">
          <p className="text-xs text-body font-bold">{t("num_of_documents")}</p>
          <div className="flex items-center">
            <p className="text-sm text-hints font-medium mt-1">
              {row.num_of_documents ? row.num_of_documents : 0} {t("document")}
            </p>
            <div className="mr-2 col-span-1 tool-pin-container relative">
              <button
                onClick={() => {
                  history.push({
                    pathname: "/moneyRequestDetails",
                    moneyRequestsActiveTab: 0,
                    type: row.status === 1
                      ? "closeMoneyRequest"
                      : "moneyRequestDetails",
                    docId: row.id,
                    activeRow: row,
                    showFooter: row.status === 1,
                    inValidteQueryKey: "getUserCashout",
                  });
                }}
              >
                <BlueDotSvg />
              </button>
              <p className="tool-pin w-max px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-6 -right-20 z-10">
                {t("open_money_request_documents")}
              </p>
            </div>
          </div>
        </div>
      )}
      {row.approved === 1 ? (
        row.status === 0 ? (
          <div className="col-span-1">
            <p className="text-xs text-body font-bold">{t("closing_date")}</p>
            <p className="text-sm text-hints font-medium mt-1">
              {convertDate(row.closing_date)}
            </p>
          </div>
        ) : (
          <div className="col-span-1">
            <p className="text-xs text-body font-bold">{t("current_amount")}</p>
            <p className="text-sm text-hints font-medium mt-1">
              {row.remaining_amount} {t("egp")}
            </p>
          </div>
        )
      ) : null}

      {row.approved === 1 && row.status === 0 && (
        <div className="col-span-1">
          <p className="text-xs text-body font-bold">{t("closing_amount")}</p>
          <p className="text-sm text-hints font-medium mt-1">
            {row.remaining_amount} {t("egp")}
          </p>
        </div>
      )}

      {row.approved === null && currentSafe.permissions.includes("CashFullControl") && row.deactivated != 1 && !isOwner ?
        (
          <div className="col-span-1 col-start-6 flex justify-end gap-x-4">
            <Fragment>
              <button
                className="money-req-reject-btn transition-all duration-300 hover:border-secondary border border-body h-3 flex items-center justify-center p-2 rounded-md"
                onClick={() => setShowRejectModal(true)}
              >
                <span className="absolute left-4">{t("reject")}</span>
                <div className="absolute -left-1 money-req-reject">
                  <Reject />
                </div>
              </button>
              <Modal
                showModal={showRejectModal}
                onClose={() => setShowRejectModal(false)}
              >
                <Alert
                  hasFooter={true}
                  alertType="cancel"
                  title={t("reject_withdraw_request")}
                  body={() => (
                    <p>
                      {t("alert_text_reject_withdraw_request", {creator_name: row.creator_name})}
                    </p>
                  )}
                  confirmText={t("reject_withdraw_request")}
                  cancelText={t("cancel")}
                  handleConfirm={() => mutateReject(row)}
                  handleCancel={() => setShowRejectModal(false)}
                  isSubmitLoading={isRejectLoading}
                />
              </Modal>
            </Fragment>
            <Fragment>
              <button
                className="money-req-accept-btn transition-all duration-300 hover:border-secondary border border-body h-3 flex items-center justify-center p-2 rounded-md"
                onClick={() => setShowAcceptModal(true)}
              >
                <span className="absolute left-4">قبول</span>
                <div className="absolute money-req-accept">
                  <Accept />
                </div>
              </button>

              <Modal
                showModal={showAcceptModal}
                onClose={() => setShowAcceptModal(false)}
              >
                <Alert
                  hasFooter={true}
                  alertType="confirm"
                  title={t("accept_withdraw_request")}
                  body={() => (
                    <p>
                    {t("alert_text_accept_withdraw_request", {creator_name: row.creator_name})}
                    </p>
                  )}
                  confirmText={t("accept_withdraw_request")}
                  cancelText={t("cancel")}
                  handleConfirm={() => mutateAccept(row)}
                  handleCancel={() => setShowAcceptModal(false)}
                  isSubmitLoading={isAcceptLoading}
                />
              </Modal>
            </Fragment>
          </div>
        ) : null}

      <div className="col-span-5 row-start-2">
        <p className="text-xs text-body font-bold">
          {t("notes_dynamic", {user_name: row.creator_name})} 
        </p>
        <p className="text-sm text-hints font-medium mt-1">
          {row.description ? row.description : t("no_notes")}
        </p>
      </div>

      {row.original_attachments && (
        <div className="col-span-5 row-start-3">
          {getAttachments({
            title: t("attachments"),
            notFoundMessage: t("no_attachments"),
            attachProperity: row.original_attachments,
            attachUrl: folders.withdrawals,
            rowId: row.id,
          })}
        </div>
      )}

      {row.status === 1 && row.approved === 1 && row.creator == userData.id && row.deactivated != 1 && (
        <div className="col-span-5 row-start-5 grid grid-cols-5 gap-4">
          <div className="col-span-1 tool-pin-container relative">
            <button
              onClick={() => {
                setShowCloseAndDetails(true);
                !showFooter && setShowFooter(true);
              }}
              disabled={row.has_open_children === 1}
              className={`${
                row.approved === 0 &&
                "cursor-not-allowed disabled bg-opacity-50"
              }`}
            >
              <LockSvg />
            </button>
            <p className="tool-pin w-max px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-8 -right-9 z-10">
              {row.has_open_children === 1
                ? t("has_open_children")
            : t("close_money_request")}
            </p>
          </div>

          <div
            className="col-span-1 tool-pin-container relative"
            onClick={() => {
              history.push({
                pathname: "/document",
                state: {
                  label: row.name,
                  value: row.id,
                  remainValue: row.remaining_amount,
                  ...row,
                },
              });
            }}
          >
            <div>
              <DocumentExpenseSvg />
            </div>
            <p className="tool-pin w-max px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-8 -right-9 z-10">
              {t("transaction_type_document")}
            </p>
          </div>
        </div>
      )}

      <Modal
        showModal={showCloseAndDetails}
        onClose={() => setShowCloseAndDetails(false)}
      >
        <CloseAndDetails
          onClose={() => setShowCloseAndDetails(false)}
          type={showFooter ? "closeMoneyRequest" : "moneyRequestDetails"}
          docId={row.id}
          activeRow={row}
          showFooter={showFooter}
          inValidteQueryKey="getMoneyRequestsData"
        />
      </Modal>
    </div>
  );
};

export default ExpandedRowFullControl;
