const Shapes = ({
  shapes,
  setShapes,
  formatShapesOption,
  hasPlusMark = true,
}) => {
  return (
    <div className="flex flex-wrap items-center gap-2">
      {shapes?.map((el) => (
        <button
          key={el.key}
          type="button"
          className={`flex px-2 justify-center items-center select-none transition-all duration-300 rounded-md border border-transparent ${
            el.pinned
              ? "bg-secondary bg-opacity-20 cursor-not-allowed"
              : el.selected
              ? "bg-secondary bg-opacity-30 border-secondary"
              : !el.pinned && !el.selected && "bg-sidebar-background"
          }`}
          disabled={el.pinned}
          onClick={() => {
            setShapes((prevOpts) => {
              return prevOpts.map((option) => {
                if (option.key === el.key) {
                  return {
                    ...option,
                    selected: !option.selected,
                  };
                }
                return option;
              });
            });
          }}
        >
          {hasPlusMark && (
            <div
              className={`w-2 h-px mr-2 ${
                el.selected ? "bg-secondary" : "bg-hints"
              }`}
            >
              <div
                className={`w-2 h-px transition-all duration-300 transform ${
                  el.pinned
                    ? "bg-secondary"
                    : el.selected
                    ? "bg-secondary"
                    : "-rotate-90 bg-hints"
                }`}
              ></div>
            </div>
          )}
          <div
            className={`text-xs font-medium ${
              el.pinned
                ? "text-secondary"
                : el.selected
                ? "text-secondary"
                : "text-hints"
            }`}
          >
            {formatShapesOption(el)}
          </div>
        </button>
      ))}
    </div>
  );
};

export default Shapes;
