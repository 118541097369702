const PeopleIcon = ({ fillColor }) => {
  return (
    <div>
      <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.6089 6.4763C14.5505 6.46797 14.4922 6.46797 14.4339 6.4763C13.1422 6.43464 12.1172 5.3763 12.1172 4.0763C12.1172 2.7513 13.1922 1.66797 14.5255 1.66797C15.8505 1.66797 16.9339 2.74297 16.9339 4.0763C16.9255 5.3763 15.9005 6.43464 14.6089 6.4763Z"
          fill={fillColor ? fillColor : "#3B82F6"}
        />
        <path
          d="M17.3237 12.2503C16.3904 12.8753 15.082 13.1087 13.8737 12.9503C14.1904 12.267 14.357 11.5087 14.3654 10.7087C14.3654 9.87534 14.182 9.08367 13.832 8.392C15.0654 8.22534 16.3737 8.45867 17.3154 9.08367C18.632 9.95034 18.632 11.3753 17.3237 12.2503Z"
          fill={fillColor ? fillColor : "#3B82F6"}
        />
        <path
          d="M5.36745 6.4763C5.42578 6.46797 5.48411 6.46797 5.54245 6.4763C6.83411 6.43464 7.85911 5.3763 7.85911 4.0763C7.85911 2.74297 6.78411 1.66797 5.45078 1.66797C4.12578 1.66797 3.05078 2.74297 3.05078 4.0763C3.05078 5.3763 4.07578 6.43464 5.36745 6.4763Z"
          fill={fillColor ? fillColor : "#3B82F6"}
        />
        <path
          d="M5.4599 10.7088C5.4599 11.5172 5.6349 12.2838 5.95156 12.9755C4.77656 13.1005 3.55156 12.8505 2.65156 12.2588C1.3349 11.3838 1.3349 9.95883 2.65156 9.08383C3.54323 8.48383 4.80156 8.24216 5.9849 8.37549C5.64323 9.07549 5.4599 9.86716 5.4599 10.7088Z"
          fill={fillColor ? fillColor : "#3B82F6"}
        />
        <path
          d="M10.1 13.225C10.0333 13.2167 9.95833 13.2167 9.88333 13.225C8.35 13.175 7.125 11.9167 7.125 10.3667C7.13333 8.78333 8.40833 7.5 10 7.5C11.5833 7.5 12.8667 8.78333 12.8667 10.3667C12.8583 11.9167 11.6417 13.175 10.1 13.225Z"
          fill={fillColor ? fillColor : "#3B82F6"}
        />
        <path
          d="M7.39297 14.9492C6.13464 15.7909 6.13464 17.1742 7.39297 18.0076C8.8263 18.9659 11.1763 18.9659 12.6096 18.0076C13.868 17.1659 13.868 15.7826 12.6096 14.9492C11.1846 13.9909 8.83464 13.9909 7.39297 14.9492Z"
          fill={fillColor ? fillColor : "#3B82F6"}
        />
      </svg>
    </div>
  );
};

export default PeopleIcon;
