const ChevronArrow = ({ fillColor }) => {
  return (
    <div>
      <svg
        width={12}
        height={12}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.37652 8.82426C4.1422 8.58995 4.1422 8.21005 4.37652 7.97574L6.35225 6L4.37652 4.02426C4.1422 3.78995 4.1422 3.41005 4.37652 3.17574C4.61083 2.94142 4.99073 2.94142 5.22505 3.17574L7.62505 5.57574C7.85936 5.81005 7.85936 6.18995 7.62505 6.42426L5.22505 8.82426C4.99073 9.05858 4.61083 9.05858 4.37652 8.82426Z"
          fill={fillColor ? fillColor : "#6B7280"}
        />
      </svg>
    </div>
  );
};

export default ChevronArrow;
