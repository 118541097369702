import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useDeleteMember(setRemoveModal, row) {
  const queryClient = useQueryClient();

  return useMutation(
    () => {
      return apiClient.delete(`user/${row.user_id}/deleteinvitations`);
    },
    {
      onSuccess: () => {
        toast.info("تم إلغاء الدعوة بنجاح");
        queryClient.invalidateQueries("team");
        setRemoveModal(() => false);
      },
      onError: (error) => {
        toast.error(error.response.data.message);
        return error;
      },
    }
  );
}
