import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchFinancials(userId, activeCompanyId) {
  return useQuery("financials", () =>
    apiClient
      .get("money_request/open", {
        params: {
          id: userId,
          company_id: activeCompanyId,
        },
      })
      .then(({ data }) =>
        data.map((financial) => ({
          id: financial.id,
          remainValue: financial.remaining_amount,
          originalValue: financial.remaining_amount,
          label: financial.name,
          value: financial.id,
          name: financial.name,
        }))
      )
      .catch((e) => e)
  );
}
