const DocumentExpenseSvg = ({}) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={32} height={32} fill="white" />
      <path
        d="M28.8898 8.06365C27.4567 6.63555 24.1286 8.92996 22.1181 10.5576V4.88717C22.1181 3.66383 21.1208 2.66626 19.8977 2.66626H13.2572C12.9003 2.66626 12.5433 2.75552 12.2231 2.91828L3.87666 6.58304C3.85566 6.59354 3.83992 6.59879 3.81892 6.60929C3.11026 6.99782 2.66406 7.74863 2.66406 8.56243V27.112C2.66406 28.3354 3.66144 29.3329 4.88454 29.3329H19.8924C21.1155 29.3329 22.1129 28.3354 22.1129 27.112V19.3047C22.6903 18.7954 23.273 18.2546 23.8452 17.6771C26.5696 14.9574 30.6903 9.86453 28.8898 8.06365ZM12.1391 4.57215V7.21309H6.12338L12.1391 4.57215ZM20.6326 27.112C20.6326 27.5215 20.3019 27.8523 19.8924 27.8523H4.88454C4.47509 27.8523 4.14438 27.5215 4.14438 27.112V8.69369H12.8793C13.2887 8.69369 13.6194 8.36292 13.6194 7.95339V4.14162H19.8924C20.3019 4.14162 20.6326 4.47239 20.6326 4.88192V11.8177C20.1549 12.2482 19.6982 12.684 19.2782 13.104C17.1942 15.1937 15.4672 17.4041 14.5433 19.1734C14.4646 19.3257 14.4436 19.499 14.4751 19.6565L12.3753 20.6278C12.1391 20.7381 11.9764 20.9586 11.9449 21.2158L11.5617 24.5918C11.5197 25.0171 11.8294 25.3951 12.2966 25.4161C12.3229 25.4161 15.7507 25.0276 15.7507 25.0276C16.0079 24.9961 16.2336 24.8334 16.3386 24.5971L17.2992 22.4917C17.462 22.5389 17.6772 22.4759 17.7874 22.4234C18.6536 21.9719 19.6247 21.3209 20.6326 20.5385V27.112ZM15.4725 20.8378L16.1286 21.4941L15.168 23.5995L13.1418 23.8305L13.3727 21.7986L15.4725 20.8378ZM22.8005 16.6323C21.0053 18.4331 19.1313 19.94 17.5722 20.8483L16.1181 19.394C17.021 17.8346 18.5328 15.955 20.3281 14.1541C25.9974 8.69369 27.8294 9.10847 27.8347 9.10322C28.1076 9.70702 26.6431 12.789 22.8005 16.6323Z"
        fill="#343C44"
      />
      <path
        d="M6.83006 12.2641H17.4075C17.8169 12.2641 18.1476 11.9333 18.1476 11.5238C18.1476 11.1142 17.8169 10.7834 17.4075 10.7834H6.83006C6.42061 10.7834 6.0899 11.1142 6.0899 11.5238C6.08466 11.9333 6.42061 12.2641 6.83006 12.2641Z"
        fill="#343C44"
      />
      <path
        d="M6.83006 16.8004H13.523C13.9324 16.8004 14.2631 16.4697 14.2631 16.0601C14.2631 15.6506 13.9324 15.3198 13.523 15.3198H6.83006C6.42061 15.3198 6.0899 15.6506 6.0899 16.0601C6.08466 16.4697 6.42061 16.8004 6.83006 16.8004Z"
        fill="#343C44"
      />
      <path
        d="M9.31819 19.8564H6.83C6.42055 19.8564 6.08984 20.1872 6.08984 20.5967C6.08984 21.0063 6.42055 21.3371 6.83 21.3371H9.31819C9.72764 21.3371 10.0583 21.0063 10.0583 20.5967C10.0583 20.1872 9.72764 19.8564 9.31819 19.8564Z"
        fill="#343C44"
      />
      <path
        d="M9.31819 24.3979H6.83C6.42055 24.3979 6.08984 24.7287 6.08984 25.1383C6.08984 25.5478 6.42055 25.8786 6.83 25.8786H9.31819C9.72764 25.8786 10.0583 25.5478 10.0583 25.1383C10.0583 24.7287 9.72764 24.3979 9.31819 24.3979Z"
        fill="#343C44"
      />
    </svg>
  );
};

export default DocumentExpenseSvg;
