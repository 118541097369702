import CurrentSvg from "../../../resources/current svg/CurrentSvg";
import FinishedSvg from "../../../resources/finished svg/FinishedSvg";
import { convertDate } from "../../../../utils/convertDate";
import { getAttachments } from "../../../../utils/getAttachments";
import React, { useEffect } from "react";
import useFetchFinLength from "../../../../hooks/reactQuery/useFetchFinLength";
import useFetchDocsCount from "../../../../hooks/reactQuery/useFetchDocsCount";
import Spinner from "../../../common/Spinner";
import OpenExpensesIcon from "../../../resources/icons/OpenExpensesIcon";
import folders from "../../../../constants/static_folders";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";


const ExpandedRow = ({ row, activeTab }) => {
  const {t, i18n} = useTranslation();

  const {
    isLoading: isFinLengthLoading,
    data: finLengthData,
    error: finLengthError,
  } = useFetchFinLength(row.id);
  const {
    isLoading: isDocsCountLoading,
    data: docsCountData,
    error: docsCountError,
  } = useFetchDocsCount(row.id);

  return (
    <>
      {activeTab === 0 && (
        <div>
          <div className={`grid grid-cols-5 gap-4 ${i18n.resolvedLanguage === "en" ? "text-left ml-10" : "text-right mr-10"}`}>
            <div className="col-span-1">
              <p className="text-xs font-bold">{t("transaction_name")}</p>
              <p className="text-sm font-medium mt-2">{row.name}</p>
            </div>
            <div className="col-span-1">
              <p className="text-xs font-bold">{t("sender")}</p>
              <p className="text-sm font-medium mt-2">
                <div class="flex flex-row">
                  <div className="ml-2">
                    {row.sender_name ? row.sender_name : "---"}
                  </div>
                  {row.sender_name ? (
                    <div>
                      <span className="h-5 w-5 rounded-full bg-hold flex justify-center items-center">
                        {row.sender_name[0]}
                      </span>
                    </div>
                  ) : null}
                </div>
              </p>
            </div>
            <div className="col-span-1">
              <p className="text-xs font-bold">{t("response_date")}</p>
              <p className="text-sm font-medium mt-2">
                {convertDate(row.creation_date)}
              </p>
            </div>
            <div className="col-span-1">
              <p className="text-xs font-bold">{t("num_of_documents")}</p>
              <p className="text-sm font-medium mt-2">
                {isFinLengthLoading || isDocsCountLoading ? (
                  <div className="w-6 h-6">
                    <Spinner />
                  </div>
                ) : (
                  <div className="flex items-center">
                    <span>{+finLengthData + +docsCountData} {t("document")}</span>
                    <div className="mr-2 col-span-1 tool-pin-container relative">
                      <button onClick={() => {}}>
                        <OpenExpensesIcon />
                      </button>
                      <p className="tool-pin w-max px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-6 -right-20 z-10">
                        {t("open_money_request_documents")}
                      </p>
                    </div>
                  </div>
                )}
              </p>
            </div>
            <div className="col-span-1">
              <p className="text-xs font-bold">{t("money_request_status")}</p>
              <p className="text-sm font-medium mt-2">
                {row.status === 1 ? t("running_money_request") : t("closed_money_request")}
              </p>
            </div>
            <div className="col-span-1">
              <p className="text-xs font-bold">{t("closing_date")}</p>
              <p className="text-sm font-medium mt-2">
                {row.closing_date ? convertDate(row.closing_date) : "---"}
              </p>
            </div>
            <div className="col-span-1">
              <p className="text-xs font-bold">{t("closing_amount")}</p>
              <p className="text-sm font-medium mt-2">
                {row.remaining_amount ? row.remaining_amount : "---"}
              </p>
            </div>
            <div className="col-span-5">
              <p className="text-xs font-bold">{t("money_request_purpose")}</p>
              <p className="text-sm font-medium mt-2">{row.description}</p>
            </div>
            <hr className="col-span-5"></hr>
            <div className="col-span-5">
              {getAttachments({
                title: t("attachments"),
                notFoundMessage: t("no_attachments"),
                attachProperity: row.original_attachments,
                attachUrl: folders.money_requests,
                rowId: row.id,
              })}
            </div>
            <div className="col-span-5">
              <p className="text-xs font-bold">{t("your_response")}</p>
              <p className="text-sm font-medium mt-2">
                {row.response_notes ? row.response_notes : t("no_response")}
              </p>
            </div>
            <div className="col-span-5">
              {getAttachments({
                title: t("attachments"),
                notFoundMessage: t("no_attachments"),
                attachProperity: row.response_attachments,
                attachUrl: folders.money_requests,
                rowId: row.id,
              })}
            </div>
          </div>
        </div>
      )}
      {activeTab === 1 && (
        <div className={`grid grid-cols-5 gap-4 ${i18n.resolvedLanguage === "en" ? "text-left ml-10" : "text-right mr-10"}`}>
          <div className="col-span-1">
            <p className="text-xs font-bold">{t("document_type")}</p>
            <p className="text-sm font-medium mt-2">
              {row.periodic === 0 ? t("rare") : t("periodic")}
            </p>
          </div>
          <div className="col-span-1">
            <p className="text-xs font-bold">{t("spend_date")}</p>
            <p className="text-sm font-medium mt-2">
              {row.payment_date ? convertDate(row.payment_date) : "---"}
            </p>
          </div>

          <div className="col-span-6">
            {getAttachments({
              title: t("attachments"),
              notFoundMessage: t("no_attachments"),
              attachProperity: row.attachments,
              attachUrl: folders.documents,
              rowId: row.id,
            })}
          </div>
        </div>
      )}
      {activeTab === 2 && (
        <div className={`grid grid-cols-5 gap-4 ${i18n.resolvedLanguage === "en" ? "text-left ml-10" : "text-right mr-10"}`}>
          <div className="col-span-1">
            <p className="text-xs font-bold">{t("transaction_name")}</p>
            <p className="text-sm font-medium mt-2">{row.name}</p>
          </div>
          <div className="col-span-1">
            <p className="text-xs font-bold">{t("sender")}</p>
            <p className="text-sm font-medium mt-2">
              <div class="flex flex-row">
                <div className="ml-2">
                  {row.sender_name ? row.sender_name : "---"}
                </div>
                {row.sender_name ? (
                  <div>
                    <span className="h-5 w-5 rounded-full bg-hold flex justify-center items-center">
                      {row.sender_name[0]}
                    </span>
                  </div>
                ) : null}
              </div>
            </p>
          </div>
          <div className="col-span-1">
            <p className="text-xs font-bold">{t("response_date")}</p>
            <p className="text-sm font-medium mt-2">
              {convertDate(row.creation_date)}
            </p>
          </div>
          <div className="col-span-1">
            <p className="text-xs font-bold">{t("num_of_documents")}</p>
            <p className="text-sm font-medium mt-2">
              {convertDate(row.creation_date)}
            </p>
          </div>
          <div className="col-span-1">
            <p className="text-xs font-bold">{t("money_request_status")}</p>
            <p className="text-sm font-medium mt-2">
              {row.status === 1 ? t("running_money_request") : t("closed_money_request")}
            </p>
          </div>
          {row.status === 0 ? <div className="col-span-1">
            <p className="text-xs font-bold">{t("closing_date")}</p>
            <p className="text-sm font-medium mt-2">
              {row.closing_date ? convertDate(row.closing_date) : "---"}
            </p>
          </div> : null }
          {row.status === 0 ?<div className="col-span-1">
            <p className="text-xs font-bold">{t("closing_amount")}</p>
            <p className="text-sm font-medium mt-2">
              {row.status === 0 ? row.remaining_amount : "---"}
            </p>
          </div> : null}
          <div className="col-span-5">
            <p className="text-xs font-bold">{t("money_request_purpose")}</p>
            <p className="text-sm font-medium mt-2">{row.description}</p>
          </div>
          <hr className="col-span-5"></hr>
          <div className="col-span-5">
            {getAttachments({
              title: t("attachments"),
              notFoundMessage: t("no_attachments"),
              attachProperity: row.original_attachments,
              attachUrl: folders.money_requests,
              rowId: row.id,
            })}
          </div>
          <div className="col-span-5">
            <p className="text-xs font-bold">{t("your_response")}</p>
            <p className="text-sm font-medium mt-2">
              {row.response_notes ? row.response_notes : t("no_response")}
            </p>
          </div>
          <div className="col-span-5">
            {getAttachments({
              title: t("attachments"),
              notFoundMessage: t("no_attachments"),
              attachProperity: row.response_attachments,
              attachUrl: folders.money_requests,
              rowId: row.id,
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default ExpandedRow;
