import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchLatestInteractions({ currentPage, pageSize }) {
  return useQuery(["getLatestInteractions", currentPage], () =>
    apiClient
      .get("/admin/getLatestInteractions", {
        params: {
          pageno: currentPage,
          pagesize: pageSize,
        },
      })
      .then(({ data }) => {
        return data;
      })
      .catch((e) => e)
  );
}
