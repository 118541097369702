import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Input from "../../components/common/inputs/Input";
import TextArea from "../../components/common/inputs/Textarea";
import SelectMenu from "../../components/common/inputs/Select";
import ReactTagInput from "@pathofdev/react-tag-input";
import "./style.scss";
import { useMutation, useQuery } from "react-query";
import apiClient from "../../services/apiClient";
import { toast } from "react-toastify";
import SuccessIllustration from "./SuccessIllustration";
import { useTranslation } from "react-i18next";

const SuccessScreen = ({ history, firstName }) => {
  const {t, i18n} = useTranslation();
  return (
    <div className="request_access_success_screen">
      <div className="request_access_success_screen_image_container">
        <SuccessIllustration />
      </div>
      <div className="request_access_success_screen_text_container">
        <span style={{fontSize: "24px", fontWeight: "700", color: "#fff"}}>
        {t("request_for_access_success_1", {user_name: firstName})}
        </span>
        <span style={{fontSize: "18px", fontWeight: "500", color: "#fff"}}>
        {t("request_for_access_success_2")}
        </span>
        <span style={{fontSize: "18px", fontWeight: "500", color: "#fff"}}>
        {t("request_for_access_success_3")}
        </span>
        <span style={{fontSize: "24px", fontWeight: "700", color: "#fff"}}>
        {t("request_for_access_success_4")}
        </span>
        <button style={{
          width: "300px",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
          background: "#3B82F6",
          boxShadow: "0px 0px 16px rgba(84, 115, 255, 0.5)",
          borderRadius: "6px",
          fontSize: "20px",
          fontWeight: "700",
          marginTop: "20px"
        }} onClick={() => history.push("/")}>{t("go_to_home")}</button>
      </div>
    </div>
  );
};

export default withRouter(SuccessScreen);
