import React from 'react'
import EmptySpace from '../../resources/icons/EmptySpace'
import TableResult from '.'

const SearchEmpty = () => {
  return (
    <TableResult
      Icon={EmptySpace}
      title='لا يوجد نتائج'
      text='جرب استخدام كلمات مفتاحية اخرى.'
    />
  )
}

export default SearchEmpty
