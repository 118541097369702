const RolesIcon = ({ fillColor }) => {
  return (
    <div>
      <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.4148 3.42396L11.2565 1.86562C10.5648 1.60729 9.43151 1.60729 8.73984 1.86562L4.58151 3.42396C3.62318 3.78229 2.83984 4.91562 2.83984 5.93229V12.124C2.83984 13.1073 3.48984 14.399 4.28151 14.9906L7.86484 17.6656C9.03984 18.549 10.9732 18.549 12.1482 17.6656L15.7315 14.9906C16.5232 14.399 17.1732 13.1073 17.1732 12.124V5.93229C17.1565 4.91562 16.3732 3.78229 15.4148 3.42396ZM9.93984 5.85729C10.9232 5.85729 11.7232 6.65729 11.7232 7.64062C11.7232 8.60729 10.9648 9.38229 10.0065 9.41563H9.98984H9.97318C9.95651 9.41563 9.93984 9.41563 9.92318 9.41563C8.92318 9.38229 8.17318 8.60729 8.17318 7.64062C8.16484 6.65729 8.96484 5.85729 9.93984 5.85729ZM11.8232 13.6323C11.3148 13.9656 10.6565 14.1406 9.99818 14.1406C9.33984 14.1406 8.67318 13.974 8.17318 13.6323C7.69818 13.3156 7.43984 12.8823 7.43151 12.4073C7.43151 11.9406 7.69818 11.4906 8.17318 11.174C9.18151 10.5073 10.8232 10.5073 11.8315 11.174C12.3065 11.4906 12.5732 11.924 12.5732 12.399C12.5648 12.8656 12.2982 13.3156 11.8232 13.6323Z"
          fill={fillColor ? fillColor : "#3B82F6"}
        />
      </svg>
    </div>
  );
};

export default RolesIcon;
