import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchMonthlyCashin(safeId) {
  const params = {};
  if(safeId) params.safe_id = safeId;
  return useQuery(["monthlyCashin", safeId], () => {
    return apiClient.get("/cashin/monthlyaverage", {params}).then((response) => {
      return response.data.total;
    })
  });
}
