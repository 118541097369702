const Redirect = ({ strokeColor }) => {
  return (
    <div>
      <svg
        width={12}
        height={11}
        viewBox="0 0 12 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 9.38265H4.08C2.38 9.38265 1 8.00266 1 6.30266C1 4.60266 2.38 3.22266 4.08 3.22266H10.85"
          stroke={strokeColor ? strokeColor : "#1E293B"}
          strokeWidth="1.5"
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.42993 4.77109L10.9999 3.19109L9.42993 1.62109"
          stroke={strokeColor ? strokeColor : "#1E293B"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Redirect;
