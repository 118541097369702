import { getAttachments } from "../../../utils/getAttachments";
import folders from "../../../constants/static_folders";
import { useTranslation } from "react-i18next";

const ExpandedRow = ({ row }) => {
  const {t, i18n} = useTranslation();

  return (
    <div className={`grid grid-cols-1 gap-y-4  ${i18n.resolvedLanguage === "en" ? "text-left ml-10" : "text-right mr-10"}`}>
      <div className="col-span-1">
        <p className="text-xs text-body font-bold">{t("expense_details")}</p>
        <p className={`text-sm text-hints font-medium mt-1`}>
          {row.description ? row.description : t("none")}
        </p>
      </div>
      {row.attachments && (
        <div className="col-span-1">
          {getAttachments({
            title: t("attachments"),
            notFoundMessage: t("no_attachments"),
            attachProperity: row.attachments,
            attachUrl: folders.documents,
            rowId: row.id,
          })}
        </div>
      )}
    </div>
  );
};

export default ExpandedRow;
