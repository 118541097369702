import PropTypes from "prop-types";

function Icon(props) {
  const { className, strokeColor, ...restOfProps } = props;
  return (
    <svg
      width={16}
      height={14}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...restOfProps}
    >
      <path
        d="M4.25 10V1M4.25 1L1.25 4M4.25 1L7.25 4M11.75 4V13M11.75 13L14.75 10M11.75 13L8.75 10"
        stroke={strokeColor ? strokeColor : "#CBD5E1"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
};

export default Icon;
