import PropTypes from "prop-types";

function Icon(props) {
  const { className, ...restOfProps } = props;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...restOfProps}
    >
      <path
        d="M14.166 17.2923H7.08268C6.74102 17.2923 6.45768 17.009 6.45768 16.6673C6.45768 16.3257 6.74102 16.0423 7.08268 16.0423H14.166C16.5493 16.0423 17.7077 14.884 17.7077 12.5007V7.50065C17.7077 5.11732 16.5493 3.95898 14.166 3.95898H5.83268C3.44935 3.95898 2.29102 5.11732 2.29102 7.50065V12.6673C2.29102 13.009 2.00768 13.2923 1.66602 13.2923C1.32435 13.2923 1.04102 13.009 1.04102 12.6673V7.50065C1.04102 4.45898 2.79102 2.70898 5.83268 2.70898H14.166C17.2077 2.70898 18.9577 4.45898 18.9577 7.50065V12.5007C18.9577 15.5423 17.2077 17.2923 14.166 17.2923Z"
        fill="#94A3B8"
      />
      <path
        d="M9.99935 12.7077C8.50768 12.7077 7.29102 11.491 7.29102 9.99935C7.29102 8.50768 8.50768 7.29102 9.99935 7.29102C11.491 7.29102 12.7077 8.50768 12.7077 9.99935C12.7077 11.491 11.491 12.7077 9.99935 12.7077ZM9.99935 8.54102C9.19935 8.54102 8.54102 9.19935 8.54102 9.99935C8.54102 10.7993 9.19935 11.4577 9.99935 11.4577C10.7993 11.4577 11.4577 10.7993 11.4577 9.99935C11.4577 9.19935 10.7993 8.54102 9.99935 8.54102Z"
        fill="#94A3B8"
      />
      <path
        d="M15.416 12.7077C15.0743 12.7077 14.791 12.4243 14.791 12.0827V7.91602C14.791 7.57435 15.0743 7.29102 15.416 7.29102C15.7577 7.29102 16.041 7.57435 16.041 7.91602V12.0827C16.041 12.4243 15.7577 12.7077 15.416 12.7077Z"
        fill="#94A3B8"
      />
      <path
        d="M4.16732 18.9577C2.78398 18.9577 1.48399 18.2244 0.783986 17.0327C0.408986 16.4327 0.208984 15.7243 0.208984 14.9993C0.208984 12.816 1.98398 11.041 4.16732 11.041C6.35065 11.041 8.12565 12.816 8.12565 14.9993C8.12565 15.7243 7.92565 16.4327 7.55065 17.041C6.85065 18.2244 5.55065 18.9577 4.16732 18.9577ZM4.16732 12.291C2.67565 12.291 1.45898 13.5077 1.45898 14.9993C1.45898 15.491 1.59229 15.9743 1.85063 16.391C2.33396 17.2077 3.22565 17.7077 4.16732 17.7077C5.10898 17.7077 6.00068 17.2077 6.48401 16.3993C6.74234 15.9743 6.87565 15.4993 6.87565 14.9993C6.87565 13.5077 5.65898 12.291 4.16732 12.291Z"
        fill="#94A3B8"
      />
      <path
        d="M1.87489 17.9172C1.71656 17.9172 1.5582 17.8589 1.4332 17.7339C1.19154 17.4922 1.19154 17.0922 1.4332 16.8505L6.01654 12.2672C6.2582 12.0255 6.65825 12.0255 6.89992 12.2672C7.14159 12.5089 7.14159 12.9089 6.89992 13.1506L2.31659 17.7339C2.19159 17.8589 2.03323 17.9172 1.87489 17.9172Z"
        fill="#94A3B8"
      />
    </svg>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
};

export default Icon;
