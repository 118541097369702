export const ForwardSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.4974 18.3334H12.4974C16.6641 18.3334 18.3307 16.6667 18.3307 12.5V7.50002C18.3307 3.33335 16.6641 1.66669 12.4974 1.66669H7.4974C3.33073 1.66669 1.66406 3.33335 1.66406 7.50002V12.5C1.66406 16.6667 3.33073 18.3334 7.4974 18.3334Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5026 12.8166H8.40261C6.98594 12.8166 5.83594 11.6666 5.83594 10.25C5.83594 8.83329 6.98594 7.68329 8.40261 7.68329H14.0443"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.8594 8.97497L14.1677 7.65831L12.8594 6.34998"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
