const Health = () => {
  return (
    <div>
      <svg
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.2275 17.0625H6.72754C2.65504 17.0625 0.915039 15.3225 0.915039 11.25V6.75C0.915039 2.6775 2.65504 0.9375 6.72754 0.9375H11.2275C15.3 0.9375 17.04 2.6775 17.04 6.75V11.25C17.04 15.3225 15.3075 17.0625 11.2275 17.0625ZM6.72754 2.0625C3.27004 2.0625 2.04004 3.2925 2.04004 6.75V11.25C2.04004 14.7075 3.27004 15.9375 6.72754 15.9375H11.2275C14.685 15.9375 15.915 14.7075 15.915 11.25V6.75C15.915 3.2925 14.685 2.0625 11.2275 2.0625H6.72754Z"
          fill="#1E293B"
        />
        <path
          d="M8.58719 13.545C8.40719 13.545 7.97219 13.4775 7.71719 12.825L6.86219 10.665C6.74219 10.3575 6.31469 10.0725 5.99219 10.0725L1.49219 10.0875C1.18469 10.0875 0.929688 9.84004 0.929688 9.52504C0.929688 9.21754 1.17719 8.96254 1.49219 8.96254L5.99219 8.94754H5.99969C6.78719 8.94754 7.62719 9.51754 7.91969 10.2525L8.60219 11.9775L10.1472 8.06254C10.3797 7.47754 10.7922 7.39504 10.9647 7.38004C11.1372 7.37254 11.5572 7.41004 11.8497 7.96504L12.6297 9.44254C12.7647 9.69754 13.1697 9.94504 13.4622 9.94504H16.5072C16.8147 9.94504 17.0697 10.2 17.0697 10.5075C17.0697 10.815 16.8147 11.07 16.5072 11.07H13.4622C12.7497 11.07 11.9697 10.5975 11.6397 9.96754L11.0547 8.85754L9.47969 12.825C9.20219 13.47 8.75969 13.545 8.58719 13.545Z"
          fill="#1E293B"
        />
      </svg>
    </div>
  );
};

export default Health;
