export const CardReceiveSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M12.0807 7.70833H1.66406C1.3224 7.70833 1.03906 7.42499 1.03906 7.08333C1.03906 6.74166 1.3224 6.45833 1.66406 6.45833H12.0807C12.4224 6.45833 12.7057 6.74166 12.7057 7.08333C12.7057 7.42499 12.4224 7.70833 12.0807 7.70833Z"
        fill="white"
      />
      <path
        d="M6.66667 14.375H5C4.65833 14.375 4.375 14.0917 4.375 13.75C4.375 13.4083 4.65833 13.125 5 13.125H6.66667C7.00833 13.125 7.29167 13.4083 7.29167 13.75C7.29167 14.0917 7.00833 14.375 6.66667 14.375Z"
        fill="white"
      />
      <path
        d="M12.0833 14.375H8.75C8.40833 14.375 8.125 14.0917 8.125 13.75C8.125 13.4083 8.40833 13.125 8.75 13.125H12.0833C12.425 13.125 12.7083 13.4083 12.7083 13.75C12.7083 14.0917 12.425 14.375 12.0833 14.375Z"
        fill="white"
      />
      <path
        d="M14.6307 17.7083H5.36406C2.0474 17.7083 1.03906 16.7083 1.03906 13.425V6.57501C1.03906 3.29167 2.0474 2.29167 5.36406 2.29167H12.0807C12.4224 2.29167 12.7057 2.57501 12.7057 2.91667C12.7057 3.25834 12.4224 3.54167 12.0807 3.54167H5.36406C2.7474 3.54167 2.28906 3.99167 2.28906 6.57501V13.4167C2.28906 16 2.7474 16.45 5.36406 16.45H14.6224C17.2391 16.45 17.6974 16 17.6974 13.4167V11.6833C17.6974 11.3417 17.9807 11.0583 18.3224 11.0583C18.6641 11.0583 18.9474 11.3417 18.9474 11.6833V13.4167C18.9557 16.7083 17.9474 17.7083 14.6307 17.7083Z"
        fill="white"
      />
      <path
        d="M16.6641 8.54167C16.5807 8.54167 16.5057 8.525 16.4224 8.49167C16.1891 8.39167 16.0391 8.16667 16.0391 7.91667V2.91667C16.0391 2.57501 16.3224 2.29167 16.6641 2.29167C17.0057 2.29167 17.2891 2.57501 17.2891 2.91667V6.40834L17.8891 5.80834C18.1307 5.56667 18.5307 5.56667 18.7724 5.80834C19.0141 6.05 19.0141 6.45 18.7724 6.69167L17.1057 8.35834C16.9891 8.475 16.8307 8.54167 16.6641 8.54167Z"
        fill="white"
      />
      <path
        d="M16.6646 8.54166C16.5062 8.54166 16.3479 8.48333 16.2229 8.35833L14.5562 6.69166C14.3146 6.44999 14.3146 6.04999 14.5562 5.80833C14.7979 5.56666 15.1979 5.56666 15.4396 5.80833L17.1062 7.47499C17.3479 7.71666 17.3479 8.11666 17.1062 8.35833C16.9812 8.48333 16.8229 8.54166 16.6646 8.54166Z"
        fill="white"
      />
    </svg>
  );
};
