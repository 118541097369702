import { useEffect, useState, useRef } from "react";

import Select, { components } from "react-select";

import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";

import Input from "../../common/inputs/Input";
import Modal from "../../common/Modal";
import Alert from "../../common/Alert";

import Close from "../../resources/icons/Close";
import Trash from "./../../resources/icons/Trash";
import UserAdd from "../../resources/icons/UserAdd";
import Selector from "../../resources/icons/Selector";
import UserRoleSelect from "../../resources/icons/UserRoleSelect";

import useFetchUserData from "../../../hooks/reactQuery/useFetchUserData";
import useMutateAddMembers from "../../../hooks/reactQuery/useMutateAddMembers";
import useFetchCompanyRoles from "./../../../hooks/reactQuery/useFetchCompanyRoles";

import apiClient from "../../../services/apiClient";

import "./AddMembers.scss";
import Check from "../../resources/icons/Check";
import { useTranslation } from "react-i18next";

const AddMembers = ({ onClose }) => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();
  const cancelButtonRef = useRef();

  const [showAlertConfirmModal, setShowAlertConfirmModal] = useState(false);
  const [showAlertCancelModal, setShowAlertCancelModal] = useState(false);

  const { data: compRoles, isLoading: isCompRolesLoading } =
    useFetchCompanyRoles();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,
    trigger,
    reset,
  } = useForm({
    mode: "all",
    defaultValues: {
      addMembersForm: [
        {
          email: "",
          role: "",
        },
        {
          email: "",
          role: "",
        },
        {
          email: "",
          role: "",
        },
      ],
    },
  });

  useEffect(() => {
    reset({
      addMembersForm: [
        {
          email: "",
          role: compRoles?.[0],
        },
        {
          email: "",
          role: compRoles?.[0],
        },
        {
          email: "",
          role: compRoles?.[0],
        },
      ],
    });
  }, [reset, compRoles]);

  const watchAll = useWatch({ control });

  useEffect(() => {
    watchAll.addMembersForm.some((field) =>
      field.email.match(
        /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
      )
    ) && trigger();
  }, [watchAll]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "addMembersForm",
  });

  const addFieldSet = () => {
    append({
      email: "",
      role: compRoles?.[0],
    });
  };

  const removeFieldSet = (fieldIndex) => {
    remove(fieldIndex);
  };

  const { isLoading: isMutateAddMemebersLoading, mutate: mutateAddMembers } =
    useMutateAddMembers(onClose, userData);
  const handleAddMembers = (data) => {
    const formData = data.addMembersForm
      .filter((el) => el.email && el.role)
      .map((el) => ({
        email: el.email,
        role: el.role.value,
      }));
    mutateAddMembers(formData);
  };

  const styles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "unset",
      outline: "none",
      border: "none",
      borderRight: "1px solid #CBD5E1",
      borderRadius: "2px",
      boxShadow: state.menuIsOpen ? "0 0 0 2px #2563eb" : "",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "inherit",
      backgroundColor: (state.isFocused || state.isSelected) && "#F8FAFC",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      maxHeight: "122px",
    }),
    loadingIndicator: (provided) => ({
      ...provided,
      position: "absolute",
      left: "47px",
    }),
  };

  const DropdownIndicator = () => (
    <div className="px-3 py-2 max-h-full">
      <UserRoleSelect />
    </div>
  );

  const SelectMenuControlIcon = ({ children, ...rest }) => (
    <components.Control {...rest}>
      <div className="px-2">
        <Selector />
      </div>

      {children}
    </components.Control>
  );

  const formatOptionLabel = (data, context) => {
    return (
      <div className="flex justify-between items-center">
        <div className="flex justify-center items-center gap-x-3 selection-container">
          <p
            className={`text-sm text-body selection-weight ${
              context.selectValue &&
              context.selectValue[0]?.value === data?.value
                ? "font-bold"
                : "font-medium"
            }`}
          >
            {data.label}
          </p>
        </div>
        {context.selectValue && context.selectValue[0]?.value === data?.value && (
          <div>
            <Check />
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanUp() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);
  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      cancelButtonRef.current.click();
    }
  };

  return (
    <div
      className="overlay"
      onClick={() => (!isDirty ? onClose() : setShowAlertCancelModal(true))}
    >
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <div className="bg-white add-members-container">
          <div className="p-4 flex justify-between items-center">
            <div className="flex items-center gap-x-1.5">
              <div>
                <UserAdd />
              </div>
              <h3 className="text-base leading-5 font-bold text-body">
                {t("add_members")}
              </h3>
            </div>
            <button
              type="button"
              onClick={() =>
                !isDirty ? onClose() : setShowAlertCancelModal(true)
              }
            >
              <div>
                <Close />
              </div>
            </button>
          </div>
          <hr />
          <form noValidate={true}>
            <div
              className="flex flex-col gap-y-4 px-4 pt-5 pb-16 scroll-bar"
              style={{ maxHeight: "308px" }}
            >
              {fields.map((field, index) => {
                return (
                  <div key={field.id} className="grid grid-cols-12 gap-x-3">
                    <div className="col-span-11">
                      <Input
                        register={register}
                        type="email"
                        name={`addMembersForm[${index}].email`}
                        defaultValue={field.email}
                        label={t("email")}
                        fieldName={t("email")}
                        placeholder="someone@example.com"
                        required={
                          watchAll.addMembersForm.some((field) =>
                            field.email.match(
                              /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
                            )
                          )
                            ? false
                            : true
                        }
                        maxLength="50"
                        pattern={{
                          regex:
                            /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
                          errorMessage: t("invalid_email"),
                        }}
                        validate={{
                          validateRepetition: async (value) => {
                            if (value) {
                              const { data } = await apiClient.get(
                                "/user/sendinvitations/checkifexists",
                                { params: { email: value } }
                              );
                              const isUserSharmoot =
                                watchAll.addMembersForm.some(
                                  (field, i) =>
                                    field.email === value && i !== index
                                );
                              return data.exists
                                ? t("email_already_exists")
                                : isUserSharmoot
                                ? t("email_exists")
                                : null;
                            }
                          },
                        }}
                        errors={
                          errors?.addMembersForm &&
                          errors?.addMembersForm[index]?.email
                        }
                      >
                        <div className="w-32">
                          <Controller
                            name={`addMembersForm[${index}].role`}
                            control={control}
                            defaultValue={field.role}
                            render={({
                              field: { onChange, onBlur, value },
                            }) => {
                              return (
                                <Select
                                  placeholder=""
                                  options={compRoles}
                                  isLoading={isCompRolesLoading}
                                  styles={styles}
                                  components={{
                                    Control: SelectMenuControlIcon,
                                    DropdownIndicator,
                                  }}
                                  onChange={(selectedOption) => {
                                    onChange(selectedOption);
                                  }}
                                  //
                                  value={value}
                                  //
                                  onBlur={(e) => onBlur(e)}
                                  formatOptionLabel={formatOptionLabel}
                                />
                              );
                            }}
                          />
                        </div>
                      </Input>
                    </div>

                    <button
                      type="button"
                      className="col-span-1 flex justify-center items-end"
                      onClick={() => removeFieldSet(index)}
                      disabled={fields.length === 1}
                    >
                      <div
                        className={`${
                          fields.length === 1 ? "cursor-not-allowed" : ""
                        }`}
                      >
                        <Trash fillColor={fields.length === 1 ? "gray" : ""} />
                      </div>
                    </button>
                  </div>
                );
              })}
            </div>
            <hr />

            <div className="p-4 grid grid-cols-10 gap-x-3">
              <button
                type="button"
                className={`relative col-span-3 py-2 rounded-md text-base leading-5 font-medium ${
                  isMutateAddMemebersLoading || !isValid
                    ? "cursor-not-allowed bg-outline text-side-icons"
                    : "bg-secondary text-white"
                }`}
                disabled={isMutateAddMemebersLoading || !isValid}
                onClick={() => setShowAlertConfirmModal(true)}
              >
                {t("send_invitations")}
              </button>

              <button
                type="button"
                ref={cancelButtonRef}
                className={`col-span-3 py-2 rounded-md text-base leading-5 font-medium border border-outline hover:border-secondary ${
                  isMutateAddMemebersLoading
                    ? "cursor-not-allowed bg-outline text-side-icons"
                    : "bg-white"
                }`}
                onClick={() =>
                  !isDirty ? onClose() : setShowAlertCancelModal(true)
                }
                disabled={isMutateAddMemebersLoading}
              >
                {t("cancel")}
              </button>

              <div className="col-span-3 col-start-8 flex justify-end items-center">
                <button
                  type="button"
                  className="text-xs left-5 font-medium rounded-md text-secondary bg-secondary border border-transparent hover:border-secondary bg-opacity-20 p-1.5"
                  onClick={addFieldSet}
                >
                  {t("add_new_cell")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <>
        <Modal
          showModal={showAlertConfirmModal}
          onClose={() => setShowAlertConfirmModal(false)}
        >
          <Alert
            hasFooter={true}
            alertType="confirm"
            title={t("add_members")}
            body={() => (
              <div>
                {t("alert_text_confirm_add_members")}
              </div>
            )}
            confirmText={t("confirm_send")}
            cancelText={t("cancel")}
            isSubmitLoading={isMutateAddMemebersLoading}
            handleConfirm={handleSubmit(handleAddMembers)}
            handleCancel={() =>
              !isMutateAddMemebersLoading && setShowAlertConfirmModal(false)
            }
          />
        </Modal>
      </>

      <>
        <Modal
          showModal={showAlertCancelModal}
          onClose={() => setShowAlertCancelModal(false)}
        >
          <Alert
            hasFooter={true}
            alertType="cancel"
            title={t("cancel_add_members")}
            body={() => (
              <div>
                {t("alert_text_cancel")}
              </div>
            )}
            confirmText={t("confirm")}
            cancelText={t("cancel")}
            handleConfirm={onClose}
            handleCancel={() => setShowAlertCancelModal(false)}
          />
        </Modal>
      </>
    </div>
  );
};

export default AddMembers;
