import { useState, useEffect } from "react";

import Modal from "../../common/Modal";

import FilterIcon from "../../resources/icons/Filter";

import useFetchUsers from "../../../hooks/reactQuery/useFetchUsers";
import useFetchCategory from "../../../hooks/reactQuery/useFetchCategory";
import useFetchUserData from "../../../hooks/reactQuery/useFetchUserData";
//
//
//
import { useForm, useWatch } from "react-hook-form";
import Shapes from "../../common/Shapes";
import Amount from "../../common/TableOperations/filter/Amount";
import UsersShapes from "../../common/TableOperations/filter/UsersShapes";
import CategsShapes from "../../common/TableOperations/filter/CategsShapes";
import Input from "../../common/inputs/Input";
import Close from "../../resources/icons/Close";
import SendDate from "./../../resources/icons/SendDate";
import Timer from "../../resources/icons/Timer";
import ReplyDate from "../../resources/icons/ReplyDate";
import { useTranslation } from "react-i18next";


const fromAmountData = {
  originalFromAmount: null,
  finalFromAmount: null,
};
const toAmountData = {
  originalToAmount: null,
  finalToAmount: null,
};

const fromSendDateData = {
  originalFromSendDate: "",
  finalFromSendDate: "",
};
const toSendDateData = {
  originalToSendDate: "",
  finalToSendDate: "",
};

const fromMaxResponseDateData = {
  originalFromMaxResponseDate: "",
  finalFromMaxResponseDate: "",
};
const toMaxResponseDateData = {
  originalToMaxResponseDate: "",
  finalToMaxResponseDate: "",
};

const fromResponseDateData = {
  originalFromResponseDate: "",
  finalFromResponseDate: "",
};
const toResponseDateData = {
  originalToResponseDate: "",
  finalToResponseDate: "",
};

const Filters = ({ activeTab, dispatchFiltrers, filtersState }) => {
  const { data: userData } = useFetchUserData();

  const [showFiltering, setShowFiltering] = useState(false);

  const [fromAmount, setFromAmount] = useState({ ...fromAmountData });
  const [toAmount, setToAmount] = useState({ ...toAmountData });

  const {t, i18n} = useTranslation();

  const responseStatusData = [
    { name: t("accepted"), key: 1, selected: false, finalSelected: false },
    { name: t("transaction_pending"), key: 2, selected: false, finalSelected: false },
    { name: t("rejected"), key: 0, selected: false, finalSelected: false },
  ];

  useEffect(() => {
    setFromAmount({ ...fromAmountData });
    setToAmount({ ...toAmountData });
  }, [activeTab]);

  const { data: usersData, isLoading: isUsersLoading } = useFetchUsers(
    userData.id,
    "customUsers",
    showFiltering,
    Infinity
  );

  const [users, setUsers] = useState([]);
  useEffect(() => {
    setUsers(
      usersData?.map((user) => {
        user.key = user.id;
        user.isShown = true;
        user.selected = false;
        user.finalSelected = false;
        return user;
      })
    );
  }, [usersData, activeTab]);

  const { data: categoriesData, isLoading: iscategoriesLoading } =
    useFetchCategory("customCategory", showFiltering, Infinity);

  const [categories, setCategories] = useState([]);
  useEffect(() => {
    setCategories(
      categoriesData?.map((category) => {
        category.key = category.id;
        category.isShown = true;
        category.selected = false;
        category.finalSelected = false;
        return category;
      })
    );
  }, [categoriesData, activeTab]);

  const [responseStatus, setResponseStatus] = useState([...responseStatusData]);

  useEffect(() => {
    setResponseStatus([...responseStatusData]);
  }, [activeTab]);

  const [fromSendDate, setFromSendDate] = useState({
    ...fromSendDateData,
  });
  const [toSendDate, setToSendDate] = useState({
    ...toSendDateData,
  });

  const [fromMaxResponseDate, setFromMaxResponseDate] = useState({
    ...fromMaxResponseDateData,
  });
  const [toMaxResponseDate, setToMaxResponseDate] = useState({
    ...toMaxResponseDateData,
  });

  const [fromResponseDate, setFromResponseDate] = useState({
    ...fromResponseDateData,
  });
  const [toResponseDate, setToResponseDate] = useState({
    ...toResponseDateData,
  });

  useEffect(() => {
    setFromSendDate({ ...fromSendDateData });
    setToSendDate({ ...toSendDateData });
    setFromMaxResponseDate({ ...fromMaxResponseDateData });
    setToMaxResponseDate({ ...toMaxResponseDateData });
    setFromResponseDate({ ...fromResponseDateData });
    setToResponseDate({ ...toResponseDateData });
  }, [activeTab]);

  let hasFilter =
    filtersState.users.length > 0 ||
    filtersState.categories.length > 0 ||
    filtersState.approved.length > 0 ||
    filtersState.fromAmount ||
    filtersState.toAmount ||
    filtersState.fromSendDate ||
    filtersState.toSendDate ||
    filtersState.fromMaxResponseDate ||
    filtersState.toMaxResponseDate ||
    filtersState.fromResponseDate ||
    filtersState.toResponseDate;

  return (
    <>
      <div className="relative inline-block text-left h-full">
        <button
          className={`h-10 closing-table-btn transition-all duration-300 border border-transparent hover:border-secondary-hover flex items-center justify-center rounded-md ${
            hasFilter ? "border-secondary-hover closing-table-btn-w" : ""
          }`}
          onClick={() => setShowFiltering(true)}
        >
          <span className="absolute left-8 w-max">{t("filteration")}</span>
          <div className="absolute left-1 closing-table">
            <FilterIcon strokeColor={hasFilter ? "#1D64D8" : ""} />
          </div>
        </button>
      </div>

      <Modal showModal={showFiltering} onClose={() => setShowFiltering(false)}>
        <FiltersModal
          onClose={() => setShowFiltering(false)}
          isUsersLoading={isUsersLoading}
          users={users}
          setUsers={setUsers}
          iscategoriesLoading={iscategoriesLoading}
          categories={categories}
          setCategories={setCategories}
          responseStatus={responseStatus}
          setResponseStatus={setResponseStatus}
          fromAmount={fromAmount}
          setFromAmount={setFromAmount}
          toAmount={toAmount}
          setToAmount={setToAmount}
          fromSendDate={fromSendDate}
          setFromSendDate={setFromSendDate}
          toSendDate={toSendDate}
          setToSendDate={setToSendDate}
          fromMaxResponseDate={fromMaxResponseDate}
          setFromMaxResponseDate={setFromMaxResponseDate}
          toMaxResponseDate={toMaxResponseDate}
          setToMaxResponseDate={setToMaxResponseDate}
          fromResponseDate={fromResponseDate}
          setFromResponseDate={setFromResponseDate}
          toResponseDate={toResponseDate}
          setToResponseDate={setToResponseDate}
          dispatchFiltrers={dispatchFiltrers}
        />
      </Modal>
    </>
  );
};

export default Filters;

//
//
//
//
//
//
//

const FiltersModal = ({
  onClose,
  isUsersLoading,
  users,
  setUsers,
  iscategoriesLoading,
  categories,
  setCategories,
  responseStatus,
  setResponseStatus,
  fromAmount,
  setFromAmount,
  toAmount,
  setToAmount,
  fromSendDate,
  setFromSendDate,
  toSendDate,
  setToSendDate,
  fromMaxResponseDate,
  setFromMaxResponseDate,
  toMaxResponseDate,
  setToMaxResponseDate,
  fromResponseDate,
  setFromResponseDate,
  toResponseDate,
  setToResponseDate,
  dispatchFiltrers,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isSaved, setSaved] = useState(false);

  const {t, i18n} = useTranslation();

  const { register, control, getValues } = useForm({
    defaultValues: {
      fromAmount: fromAmount.originalFromAmount,
      toAmount: toAmount.originalToAmount,
      fromSendDate: fromSendDate.originalFromSendDate,
      toSendDate: toSendDate.originalToSendDate,
      fromMaxResponseDate: fromMaxResponseDate.originalFromMaxResponseDate,
      toMaxResponseDate: toMaxResponseDate.originalToMaxResponseDate,
      fromResponseDate: fromResponseDate.originalFromResponseDate,
      toResponseDate: toResponseDate.originalToResponseDate,
    },
  });

  const tabs = [
    {
      name: t("amount"),
      selectedCount:
        (useWatch({ control, name: "fromAmount" }) ? 1 : 0) +
        (useWatch({ control, name: "toAmount" }) ? 1 : 0),
    },
    {
      name: t("user_name"),
      selectedCount: users?.filter((user) => user.selected).length,
    },
    {
      name: t("category_name"),
      selectedCount: categories?.filter((user) => user.selected).length,
    },
    {
      name: t("status"),
      selectedCount: responseStatus?.filter((status) => status.selected).length,
    },
    {
      name: t("date"),
      selectedCount:
        (useWatch({ control, name: "fromSendDate" }) ? 1 : 0) +
        (useWatch({ control, name: "toSendDate" }) ? 1 : 0) +
        (useWatch({ control, name: "fromMaxResponseDate" }) ? 1 : 0) +
        (useWatch({ control, name: "toMaxResponseDate" }) ? 1 : 0) +
        (useWatch({ control, name: "fromResponseDate" }) ? 1 : 0) +
        (useWatch({ control, name: "toResponseDate" }) ? 1 : 0),
    },
  ];

  const dates = [
    {
      name: "fromSendDate",
      defaultValue: fromSendDate.originalFromSendDate,
      fieldName: t("from"),
      label: t("from"),
      hint: t("from_date_hint"),
      rowLabel: t("send_date"),
      RowIcon: SendDate,
    },
    {
      name: "toSendDate",
      defaultValue: toSendDate.originalToSendDate,
      fieldName: t("to"),
      label: t("to"),
      hint: t("to_date_hint"),
    },

    {
      name: "fromMaxResponseDate",
      defaultValue: fromMaxResponseDate.originalFromMaxResponseDate,
      fieldName: t("from"),
      label: t("from"),
      hint: t("from_date_hint"),
      rowLabel: t("max_response_time"),
      RowIcon: Timer,
    },
    {
      name: "toMaxResponseDate",
      defaultValue: toMaxResponseDate.originalToMaxResponseDate,
      fieldName: t("to"),
      label: t("to"),
      hint: t("to_date_hint"),
    },

    {
      name: "fromResponseDate",
      defaultValue: fromResponseDate.originalFromResponseDate,
      fieldName: t("from"),
      label: t("from"),
      hint: t("from_date_hint"),
      rowLabel: t("response_Date"),
      RowIcon: ReplyDate,
    },
    {
      name: "toResponseDate",
      defaultValue: toResponseDate.originalToResponseDate,
      fieldName: t("to"),
      label: t("to"),
      hint: t("to_date_hint"),
    },
  ];

  useEffect(() => {
    if (isSaved) {
      setUsers((prevUsers) =>
        prevUsers?.map((user) => {
          user.finalSelected = user.selected;
          return user;
        })
      );
      setCategories((prevCategs) =>
        prevCategs?.map((category) => {
          category.finalSelected = category.selected;
          return category;
        })
      );
      setResponseStatus((prevResponses) =>
        prevResponses.map((response) => {
          response.finalSelected = response.selected;
          return response;
        })
      );

      setFromAmount({
        originalFromAmount: getValues("fromAmount"),
        finalFromAmount: getValues("fromAmount"),
      });
      setToAmount({
        originalToAmount: getValues("toAmount"),
        finalToAmount: getValues("toAmount"),
      });

      setFromSendDate({
        originalFromSendDate: getValues("fromSendDate"),
        finalFromSendDate: getValues("fromSendDate"),
      });
      setToSendDate({
        originalToSendDate: getValues("toSendDate"),
        finalToSendDate: getValues("toSendDate"),
      });

      setFromMaxResponseDate({
        originalFromMaxResponseDate: getValues("fromMaxResponseDate"),
        finalFromMaxResponseDate: getValues("fromMaxResponseDate"),
      });
      setToMaxResponseDate({
        originalToMaxResponseDate: getValues("toMaxResponseDate"),
        finalToMaxResponseDate: getValues("toMaxResponseDate"),
      });

      setFromResponseDate({
        originalFromResponseDate: getValues("fromResponseDate"),
        finalFromResponseDate: getValues("fromResponseDate"),
      });
      setToResponseDate({
        originalToResponseDate: getValues("toResponseDate"),
        finalToResponseDate: getValues("toResponseDate"),
      });

      dispatchFiltrers({
        // type: "CHANGESTATE",
        payload: {
          users: users?.filter((user) => user.selected).map((user) => user.id),
          categories: categories
            ?.filter((category) => category.selected)
            .map((category) => category.id),
          approved: responseStatus
            ?.filter((status) => status.selected)
            .map((status) => status.key),
          fromAmount: getValues("fromAmount"),
          toAmount: getValues("toAmount"),
          fromSendDate: getValues("fromSendDate"),
          toSendDate: getValues("toSendDate"),
          fromMaxResponseDate: getValues("fromMaxResponseDate"),
          toMaxResponseDate: getValues("toMaxResponseDate"),
          fromResponseDate: getValues("fromResponseDate"),
          toResponseDate: getValues("toResponseDate"),
        },
      });
      onClose();
    } else {
      setUsers((prevUsers) =>
        prevUsers?.map((user) => {
          user.selected = user.finalSelected;
          return user;
        })
      );
      setCategories((prevCategs) =>
        prevCategs?.map((category) => {
          category.selected = category.finalSelected;
          return category;
        })
      );
      setResponseStatus((prevResponses) =>
        prevResponses.map((response) => {
          response.selected = response.finalSelected;
          return response;
        })
      );
      setFromAmount((prevFromAmount) => ({
        ...prevFromAmount,
        originalFromAmount: prevFromAmount.finalFromAmount,
      }));
      setToAmount((prevToAmount) => ({
        ...prevToAmount,
        originalToAmount: prevToAmount.finalToAmount,
      }));

      setFromSendDate((prevDate) => ({
        ...prevDate,
        originalFromSendDate: prevDate.finalFromSendDate,
      }));
      setToSendDate((prevDate) => ({
        ...prevDate,
        originalToSendDate: prevDate.finalToSendDate,
      }));

      setFromMaxResponseDate((prevDate) => ({
        ...prevDate,
        originalFromMaxResponseDate: prevDate.finalFromMaxResponseDate,
      }));
      setToMaxResponseDate((prevDate) => ({
        ...prevDate,
        originalToMaxResponseDate: prevDate.finalToMaxResponseDate,
      }));

      setFromResponseDate((prevDate) => ({
        ...prevDate,
        originalFromResponseDate: prevDate.finalFromResponseDate,
      }));
      setToResponseDate((prevDate) => ({
        ...prevDate,
        originalToResponseDate: prevDate.finalToResponseDate,
      }));
    }
  }, [isSaved]);

  const responseStatusShapesOption = (responseStatus) => (
    <div className="p-2">{responseStatus.name}</div>
  );

  return (
    <div className="overlay overlay--side" onClick={() => onClose()}>
      <div className="modal modal--side" onClick={(e) => e.stopPropagation()}>
        <div className="py-5 px-6 flex justify-between items-center border-b border-separator">
          <button
            className="bg-secondary text-sm font-medium text-white rounded-md py-1 px-8"
            onClick={() => {
              setSaved(true);
            }}
          >
            {t("save")}
          </button>
          <div className="flex items-center gap-x-2">
            <div>
              <FilterIcon />
            </div>
            <div className="text-base leading-5 font-bold text-body">{t("filteration")}</div>
          </div>
          <button onClick={onClose}>
            <Close />
          </button>
        </div>

        <div className="flex justify-center items-center border-b border-separator gap-x-10 text-sm font-medium text-hints" dir={i18n.resolvedLanguage === "en" ? "ltr" : "rtl"}>
          {tabs.map((tab, index) => (
            <button
              className={`py-4 relative top-px flex items-center gap-x-2 border-b-2 border-transparent ${
                activeTab === index ? "border-secondary text-secondary" : ""
              }`}
              onClick={() => setActiveTab(index)}
            >
              <span>{tab.name}</span>
              <span
                className={`text-xs font-medium rounded-xl px-px w-6 flex justify-center items-center transition-all duration-300 ${
                  activeTab === index
                    ? "text-secondary bg-secondary bg-opacity-40"
                    : "text-hints bg-separator"
                }`}
              >
                {tab.selectedCount}
              </span>
            </button>
          ))}
        </div>

        {activeTab === 0 && (
          <Amount
            register={register}
            fromAmount={fromAmount}
            toAmount={toAmount}
          />
        )}

        {activeTab === 1 && (
          <UsersShapes
            isUsersLoading={isUsersLoading}
            users={users}
            setUsers={setUsers}
          />
        )}

        {activeTab === 2 && (
          <CategsShapes
            iscategoriesLoading={iscategoriesLoading}
            categories={categories}
            setCategories={setCategories}
          />
        )}

        {activeTab === 3 && (
          <div className="pt-10 px-6 flex flex-col gap-y-2.5" dir={i18n.resolvedLanguage === "en" ? "ltr" : "rtl"}>
            <div className="text-xs leading-5 font-medium text-body">
              {t("response_status")}
            </div>
            <div>
              <Shapes
                shapes={responseStatus}
                setShapes={setResponseStatus}
                formatShapesOption={responseStatusShapesOption}
              />
            </div>
          </div>
        )}

        <div className={`grid grid-cols-5 gap-x-4 ${i18n.resolvedLanguage === "en" ? "pl-4" : "pt-4"} pb-10`} dir={i18n.resolvedLanguage === "en" ? "ltr" : "rtl"}>
          {activeTab === 4 &&
            dates.map((date, index) => (
              <>
                {index % 2 === 0 && (
                  <div className="col-span-5 px-6 pt-6 flex items-center gap-x-2.5">
                    <div>
                      <date.RowIcon />
                    </div>
                    <span>{date.rowLabel}</span>
                  </div>
                )}
                <div className="col-span-2 pr-6 pt-4 pb-6">
                  <Input
                    register={register}
                    type="date"
                    name={date.name}
                    fieldName={date.fieldName}
                    label={date.label}
                    hint={date.hint}
                    defaultValue={date.defaultValue}
                    required={false}
                  />
                </div>
                {index % 2 !== 0 && index !== dates.length - 1 && (
                  <hr className="col-start-1 col-span-5" />
                )}
              </>
            ))}
        </div>
      </div>
    </div>
  );
};
