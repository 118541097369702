import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchSafePermissions(t, i18n) {
  return useQuery("safePermissions", () =>
    apiClient.get("safe/permissions").then(({ data }) => {
      data?.map((permission) => ({
        ...permission,
        label: i18n.resolvedLanguage === "en" ? permission.en_name : permission.name,
        value: permission.id,
      }));
      return data?.map((permission) => ({
        ...permission,
        label: i18n.resolvedLanguage === "en" ? permission.en_name : permission.name,
        value: permission.id,
      }));
    })
  );
}
