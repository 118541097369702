import { useCallback, useEffect, useReducer } from "react";

const useKeyboardShortcut = (shortcuts) => {
  // Don't listen to keyboard events if user is filling input fields
  const blacklistedTargets = ["input", "textarea"];

  let mergedShortcuts = [];
  shortcuts
    .map((shortcut) => shortcut.keys)
    .forEach((array) => {
      mergedShortcuts = [...mergedShortcuts, ...array];
    });

  const initalKeyMapping = mergedShortcuts.reduce((currentKeys, key) => {
    currentKeys[key] = false;
    return currentKeys;
  }, {});

  // When a key is pressed down, set it's value to true and set it back to false when it's released
  function keysReducer(state, action) {
    let newState;
    if (action.type === "set-key-down")
      newState = { ...state, [action.key]: true };
    else newState = { ...state, [action.key]: false };
    return newState;
  }

  const [keys, setKeys] = useReducer(keysReducer, initalKeyMapping);

  const keydownListener = useCallback(
    (keydownEvent) => {
      const { key, target, repeat } = keydownEvent;

      // Ignore the keypress if the user is filling an input field
      if (blacklistedTargets.includes(target.tagName.toLowerCase())) return;

      // disable default browser shortcuts
      if (keydownEvent.ctrlKey) keydownEvent.preventDefault();

      // Only capture the key once if it's pressed for long
      if (repeat) return;

      // Igonre the keypress if the key is not included in the defined shortcuts
      if (!mergedShortcuts.includes(key)) return;

      if (keys && !keys[key]) setKeys({ type: "set-key-down", key });
    },
    [mergedShortcuts, keys]
  );

  const keyupListener = useCallback(
    (keyupEvent) => {
      const { key, target } = keyupEvent;
      if (blacklistedTargets.includes(target.tagName.toLowerCase())) return;
      if (!mergedShortcuts.includes(key)) return;
      if (keys && keys[key]) setKeys({ type: "set-key-up", key });
    },
    [mergedShortcuts, keys]
  );

  useEffect(() => {
    window.addEventListener("keydown", keydownListener, true);
    return () => window.removeEventListener("keydown", keydownListener, true);
  }, [keydownListener]);

  useEffect(() => {
    window.addEventListener("keyup", keyupListener, true);
    return () => window.removeEventListener("keyup", keyupListener, true);
  }, [keyupListener]);

  useEffect(() => {
    // Invoke the callback function if all the keys inlcuded in the shortcut are pressed
    for (let i = 0; i < shortcuts.length; i++) {
      let shortcutClicked = true;
      // Check if all the keys included in one of the shortcuts have been clicked
      for (let j = 0; j < shortcuts[i].keys.length; j++) {
        if (!keys[shortcuts[i].keys[j]]) {
          shortcutClicked = false;
          break;
        }
      }
      if (shortcutClicked) shortcuts[i].callback(shortcuts[i].keys);
    }
  }, [keys]);
};

export default useKeyboardShortcut;
