import { useEffect, useState } from "react";

import { useMutation } from "react-query";

import Spinner from "../../Spinner";
import CloseSvg from "../../../resources/close svg/CloseSvg";
import FieldErrorSvg from "../../../resources/field error svg/FieldErrorSvg";

import apiClient from "../../../../services/apiClient";
import { v4 as uuidv4 } from "uuid";
import AddImageIcon from "../../../resources/icons/AddImageIcon";

import actionTypes from "../../../../context/types";
import { useDataLayerValue } from "../../../../context/DataLayer";

import "./UploadInput.module.css";

import { bytesToSize, fileTypeIcon } from "../../../../utils/fileUtils";
import useFetchUserData from "../../../../hooks/reactQuery/useFetchUserData";
import { useTranslation } from "react-i18next";

const UploadInput = ({ ...props }) => {
  const {
    name,
    setValue,
    trigger,
    fileSize,
    showUplodedFilesNames,
    getValues,
    Controller,
    control,
    id,
    hint,
    accept,
    multiple = false,
    defaultValue,
    required,
    errors,
    photoPreview,
  } = props;

  const files = getValues(name);

  const {t, i18n} = useTranslation();

  return (
    <div className="flex flex-col justify-center h-full">
      {!required && showUplodedFilesNames && (
        <p className="text-sm font-medium text-hints mb-1 text-left">{t("optional")}</p>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{
          validate: {
            validateRequired: required
              ? (files) => files?.length > 0 || t("required_file")
              : () => {},
            validateSize: (files) => {
              return required
                ? getValues(name)?.every((file) => file.size <= fileSize) ||
                    t("too_large_file")
                : () => {};
            },
          },
        }}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          <>
            <div
              className={`relative border-dashed border-2 rounded-md border-gray-300 items-center h-full`}
              // style={{ maxHeight: 450 }}
              onDrop={(e) => {
                e.preventDefault();

                let files = e.dataTransfer.files;

                let prevFiles = getValues(name) || [];
                if (!multiple) {
                  prevFiles = [];
                }

                for (const file of files) {
                  file.guid = uuidv4();
                }
                onChange([...prevFiles, ...files]);
                photoPreview && photoPreview(e);
              }}
              onDragOver={(e) => {
                e.preventDefault();
              }}
            >
              {showUplodedFilesNames && (
                <>
                  {files && files.length > 0 ? (
                    <div className="px-6 py-2">
                      <div
                        className="overflow-y-auto workspace-options"
                        style={{ maxHeight: 450 }}
                      >
                        {showUplodedFilesNames &&
                          files?.map((file) => (
                            <File
                              key={file.guid}
                              file={file}
                              files={files}
                              name={name}
                              setValue={setValue}
                              fileSize={fileSize}
                              trigger={trigger}
                            />
                          ))}
                        <div className="flex justify-between">
                          <div>
                            {(errors || hint) && (
                              <p
                                className={`mt-1 text-sm font-small ${
                                  errors ? "text-red-600" : "text-gray-500"
                                } ${!hint && "absolute"}`}
                                style={{ position: "sticky" }}
                              >
                                {errors ? errors.message : hint}
                              </p>
                            )}
                          </div>
                          <div>
                            <p
                              className={`mt-1 text-sm font-small "text-gray-200`}
                              style={{ position: "sticky" }}
                            >
                              {bytesToSize(fileSize)} {t("max")}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="pt-2" style={{ position: "sticky" }}>
                        <button
                          type="button"
                          className={`text-white bg-gray-600 text-16 font-normal h-10 rounded-md w-full`}
                          onClick={() => {
                            document.getElementById(id).click();
                          }}
                        >
                        {t("add_attachments")} 
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="justify-center items-center text-center h-full">
                      <div className="p-4 mb-auto mt-auto w-full relative">
                        <AddImageIcon className="m-auto"></AddImageIcon>
                        <p className="text-gray-400 mt-3 text-14 font-bold">
                          <button
                            className="text-blue-700 text-14 font-bold"
                            onClick={(e) => {
                              e.preventDefault();
                              document.getElementById(id).click();
                            }}
                          >
                          
                          </button>{t("upload_attachment")} {t("or")} {t("drag_drop")}
                        </p>
                        <p className="text-gray-400 text-12 font-normal">
                          PNG, JPG, PDF, Excel, word up to{" "}
                          {bytesToSize(fileSize)}
                        </p>
                      </div>
                    </div>
                  )}
                </>
              )}
              <input
                id={id}
                type="file"
                multiple={multiple}
                className="hidden"
                accept={accept}
                onChange={(e) => {
                  let files = e.target.files;

                  let prevFiles = getValues(name) || [];
                  if (!multiple) {
                    prevFiles = [];
                  }

                  for (const file of files) {
                    file.guid = uuidv4();
                  }
                  onChange([...prevFiles, ...files]);
                  photoPreview && photoPreview(e);
                  e.target.value = null;
                }}
              />
            </div>
          </>
        )}
      />
    </div>
  );
};

export default UploadInput;

function File({ key, file, files, name, setValue, trigger, fileSize }) {
  const { data: userData } = useFetchUserData();

  const fullPath =
    (process.env.REACT_APP_UPLOAD_FILES_BASE_URL
      ? process.env.REACT_APP_UPLOAD_FILES_BASE_URL
      : "") +
    (process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
      ? process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
      : "") +
    "";
  const [state, dispatch] = useDataLayerValue();

  const [fileUploadedPercentage, setFileUploadedPercentage] = useState(0);
  const { isLoading, mutate, status } = useMutation("mutateFiles", () => {
    const data = new FormData();
    data.append("file", file);
    data.append("guid", file.guid);
    data.append("user_id", userData.id);
    const config = {
      onUploadProgress: (progressEvent) => {
        let percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setFileUploadedPercentage(percentage);
      },
    };
    return apiClient.post("spend_request/uploadattachment", data, config);
  });

  useEffect(() => {
    if (file.size < fileSize) mutate();
  }, [mutate]);

  useEffect(() => {
    if (isLoading)
      dispatch({
        type: actionTypes.IS_FILE_UPLOADING,
        payload: true,
      });
    else
      dispatch({
        type: actionTypes.IS_FILE_UPLOADING,
        payload: false,
      });
  }, [isLoading]);

  return (
    <a
      href={`${fullPath}/temp/${file.guid}/${file.name}`}
      download
      target="_blank"
      title={file.name}
    >
      <div
        key={key}
        onClick={() => {}}
        className={`mt-1 flex justify-between border-2 px-4 py-2 rounded col-span-6 sm:col-span-2 overflow-auto relative cursor-pointer ${
          status === "error" || file.size > fileSize
            ? "border-red-600"
            : "border-gray-200"
        }`}
      >
        <div
          className={`absolute inset-0 h-full w-full ${
            isLoading ? "visible" : "invisible"
          }`}
          style={{ direction: "ltr" }}
        >
          <div className="overflow-hidden h-full w-full text-12 flex bg-transparent relative">
            <div className="absolute inset-0 py-2">
              <span className="flex justify-center items-center">
                {fileUploadedPercentage}%
              </span>
            </div>
            <div
              style={{
                width: fileUploadedPercentage + "%",
                transition: "width 1s",
              }}
              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-800 opacity-5"
            ></div>
          </div>
        </div>
        <div className={`w-full h-full flex justify-between`}>
          <div className="flex">
            <button
              type="button"
              className="grid grid-cols-2 gap-x-2 z-10"
              onClick={(e) => {
                e.preventDefault();
                setValue(
                  name,
                  files.filter((f) => f.guid !== file.guid)
                );
                trigger(name);
              }}
            >
              <div
                className={`col-span-2 w-6 h-6 flex justify-center items-center`}
              >
                <CloseSvg />
              </div>
            </button>
            <div
              className={`col-span-1 w-6 h-6 flex justify-center items-center mr-4 ${
                isLoading ? "opacity-25" : "opacity-100"
              } ${file.size > fileSize && "text-red-600"}`}
            >
              {bytesToSize(file.size)}
            </div>
          </div>
          <div className="flex">
            <a
              className={`break-all ml-3 text-14 ${
                isLoading ? "opacity-25" : "opacity-100"
              }`}
              style={{ textDecoration: "none" }}
              href={`${fullPath}/temp/${file.guid}/${file.name}`}
              download
              target="_blank"
              title={file.name}
            >
              ...{file.name.substring(0, 7)}
            </a>
            {fileTypeIcon(file.type)}
          </div>
        </div>
      </div>
    </a>
  );
}
