import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchCashinCount() {
  return useQuery("cashinCount", () =>
    apiClient
      ?.get(`cashin/notificationscount`)
      .then(({ data }) => data)
      .catch((e) => e)
  );
}
