const Shield = () => {
  return (
    <div>
      <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.4487 3.47487L10.8654 1.7582C10.3904 1.5832 9.61536 1.5832 9.14036 1.7582L4.55703 3.47487C3.6737 3.8082 2.95703 4.84154 2.95703 5.7832V12.5332C2.95703 13.2082 3.3987 14.0999 3.94036 14.4999L8.5237 17.9249C9.33203 18.5332 10.657 18.5332 11.4654 17.9249L16.0487 14.4999C16.5904 14.0915 17.032 13.2082 17.032 12.5332V5.7832C17.0404 4.84154 16.3237 3.8082 15.4487 3.47487ZM10.6237 10.7249V12.9165C10.6237 13.2582 10.3404 13.5415 9.9987 13.5415C9.65703 13.5415 9.3737 13.2582 9.3737 12.9165V10.7249C8.53203 10.4582 7.91536 9.67487 7.91536 8.74987C7.91536 7.59987 8.8487 6.66654 9.9987 6.66654C11.1487 6.66654 12.082 7.59987 12.082 8.74987C12.082 9.6832 11.4654 10.4582 10.6237 10.7249Z"
          fill="#94A3B8"
        />
      </svg>
    </div>
  );
};

export default Shield;
