const Forward = ({}) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M4 8L-3.49691e-07 8L8 -3.49691e-07L16 8L12 8L12 16L4 16L4 8Z"
          fill="#1697FF"
        />
      </svg>
    </div>
  );
};

export default Forward;
