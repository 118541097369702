const DocumentDate = () => {
  return (
    <div>
      <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.78361 18.2846C8.59194 18.2846 8.4086 18.2013 8.2836 18.0346L7.44193 16.9096C7.26693 16.6763 7.03362 16.543 6.78362 16.5263C6.52528 16.5096 6.28359 16.6263 6.08359 16.8346C4.87526 18.1263 3.9586 18.018 3.51694 17.8513C3.06694 17.6763 2.30859 17.1013 2.30859 15.2513V5.86798C2.30859 2.16798 3.37526 1.04297 6.86693 1.04297H13.1586C16.6503 1.04297 17.7169 2.16798 17.7169 5.86798V9.41796C17.7169 9.75963 17.4336 10.043 17.0919 10.043C16.7503 10.043 16.4669 9.75963 16.4669 9.41796V5.86798C16.4669 2.85964 15.9419 2.29297 13.1586 2.29297H6.86693C4.08359 2.29297 3.55859 2.85964 3.55859 5.86798V15.2513C3.55859 16.1263 3.77526 16.6096 3.97526 16.6846C4.12526 16.743 4.53361 16.6596 5.16695 15.9846C5.62528 15.5013 6.22527 15.243 6.85027 15.2763C7.46694 15.3096 8.05027 15.6346 8.44194 16.1596L9.29193 17.2846C9.50026 17.5596 9.44194 17.9513 9.16694 18.1596C9.04194 18.2513 8.90861 18.2846 8.78361 18.2846Z"
          fill="#94A3B8"
        />
        <path
          d="M13.3337 6.45703H6.66699C6.32533 6.45703 6.04199 6.1737 6.04199 5.83203C6.04199 5.49036 6.32533 5.20703 6.66699 5.20703H13.3337C13.6753 5.20703 13.9587 5.49036 13.9587 5.83203C13.9587 6.1737 13.6753 6.45703 13.3337 6.45703Z"
          fill="#94A3B8"
        />
        <path
          d="M12.5 9.79297H7.5C7.15833 9.79297 6.875 9.50964 6.875 9.16797C6.875 8.8263 7.15833 8.54297 7.5 8.54297H12.5C12.8417 8.54297 13.125 8.8263 13.125 9.16797C13.125 9.50964 12.8417 9.79297 12.5 9.79297Z"
          fill="#94A3B8"
        />
        <path
          d="M12.3503 18.1513C12.0336 18.1513 11.7336 18.0346 11.517 17.818C11.2586 17.5596 11.142 17.1846 11.2003 16.793L11.3586 15.668C11.4003 15.3763 11.5753 15.0263 11.7836 14.818L14.7336 11.868C15.1336 11.468 15.5253 11.2596 15.9503 11.218C16.467 11.168 16.9836 11.3846 17.4669 11.868C17.9503 12.3513 18.1669 12.8596 18.1169 13.3846C18.0753 13.8013 17.8586 14.2013 17.4669 14.6013L14.517 17.5513C14.3086 17.7596 13.9586 17.9346 13.6669 17.9763L12.5419 18.1347C12.4753 18.143 12.417 18.1513 12.3503 18.1513ZM16.092 12.4597C16.0836 12.4597 16.0753 12.4597 16.067 12.4597C15.9503 12.468 15.7919 12.5763 15.6169 12.7513L12.667 15.7013C12.642 15.7263 12.6003 15.8097 12.6003 15.843L12.4503 16.8847L13.492 16.7346C13.5253 16.7263 13.6086 16.6846 13.6336 16.6596L16.5836 13.7097C16.7586 13.5263 16.867 13.3763 16.8753 13.2596C16.892 13.093 16.7253 12.893 16.5836 12.7513C16.4503 12.618 16.2586 12.4597 16.092 12.4597Z"
          fill="#94A3B8"
        />
        <path
          d="M16.5998 15.2078C16.5415 15.2078 16.4832 15.1995 16.4332 15.1828C15.3332 14.8745 14.4582 13.9995 14.1498 12.8995C14.0582 12.5661 14.2498 12.2244 14.5832 12.1244C14.9165 12.0328 15.2582 12.2245 15.3498 12.5578C15.5415 13.2411 16.0832 13.7828 16.7665 13.9744C17.0998 14.0661 17.2915 14.4161 17.1998 14.7495C17.1248 15.0245 16.8748 15.2078 16.5998 15.2078Z"
          fill="#94A3B8"
        />
      </svg>
    </div>
  );
};

export default DocumentDate;
