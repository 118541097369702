import socketio from "socket.io-client";
import { useEffect } from "react";
import "dotenv";

const useSubscribtion = (userId, callback) => {
  useEffect(() => {
    const socket = socketio(process.env.REACT_APP_API_URL, {
      transports: ["websocket"],
    });
    socket.on(`notification_${userId}`, (res)=>{callback(res)});

    return () => {
      socket.off(`notification_${userId}`, callback);
      socket.disconnect();
    };
  }, []);
};

export default useSubscribtion;
