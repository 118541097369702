const BlueBellSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="28"
      viewBox="0 0 26 28"
      fill="none"
      style={{ display: "inline-block" }}
    >
      <path
        d="M17.2474 21.0833H24.3307L22.3404 19.093C21.8006 18.5532 21.4974 17.8212 21.4974 17.0578V12.5833C21.4974 8.88239 19.1321 5.73387 15.8307 4.56701V4.08333C15.8307 2.51853 14.5622 1.25 12.9974 1.25C11.4326 1.25 10.1641 2.51853 10.1641 4.08333V4.56701C6.86268 5.73387 4.4974 8.88239 4.4974 12.5833V17.0578C4.4974 17.8212 4.19416 18.5532 3.65438 19.093L1.66406 21.0833H8.7474M17.2474 21.0833V22.5C17.2474 24.8472 15.3446 26.75 12.9974 26.75C10.6502 26.75 8.7474 24.8472 8.7474 22.5V21.0833M17.2474 21.0833H8.7474"
        stroke="#3B82F6"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default BlueBellSvg;
