import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchDocs(expenseId) {
  return useQuery(["docs", expenseId], () => {
    return apiClient
      ?.get(`document/fin`, {
        params: { id: expenseId },
      })
      .then((res) => {
        res?.data[0]?.forEach((element) => {
          element.newId = `${element.id}D`;
        });
        return res?.data[0];
      })
      .catch((e) => e);
  });
}
