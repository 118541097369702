import "../charts.scss";

export default function CustomTooltip(props) {
    if (props.active) {
        return (
            <div className="custom-tooltip">
                <p className="custom-tooltip-label">{props.label}</p>
                <ul className="custom-tooltip-item-list">
                {props.payload ? props.payload.map(p => <div className="custom-tooltip-item">
                    <div style={{display: "flex", marginLeft: "10px", justifyContent: "flex-start", alignItems: "center"}}>
                        <span className="custom-tooltip-item-color-id" style={{backgroundColor: p.payload.colors[p.dataKey]}}></span>
                        <p className="custom-tooltip-item-name">{p.dataKey}</p>
                    </div>
                    <p className="custom-tooltip-item-value">{new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(p.payload[p.dataKey])}</p>
                </div>) : null}
                </ul>

            </div>
        );
    }

    return null;
};