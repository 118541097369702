import "../charts.scss";
import { useTranslation } from "react-i18next";

export default function CustomizedXAxisTick(props) {
    const {x, y, payload} = props;
    const {t, i18n} = useTranslation();

    return (
        <g transform={`translate(${x},${y})`} fill={"#ff8080"}> 
            <text className="xTick" x={i18n.resolvedLanguage === "en" ? -25 : 25} y={10} textAnchor="start" fill="#666">{payload.value}</text>
        </g>
    )
};