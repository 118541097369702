import { useMutation, useQueryClient } from "react-query";

import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useMutateArchivePayLicense() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ selectedRows, userId, activeTab, setShowArchive }) => {
      const formData = new FormData();

      const ids = selectedRows?.map((el) => {
        if (activeTab === 0) {
          if (el.created_by === userId) {
            return el.id;
          }
        } else if (activeTab === 1) {
          if (el.recipient_id === userId) {
            return el.id;
          }
        }
      });
      formData.append("ids", ids ? ids : []);

      const forwardIds = selectedRows?.map((el) => {
        if (activeTab === 0) {
          if (el.created_by !== userId) {
            return el.sent_forward_id;
          }
        } else if (activeTab === 1) {
          if (el.recipient_id !== userId) {
            return el.received_forward_id;
          }
        }
      });
      formData.append("forward_ids", forwardIds ? forwardIds : []);

      return apiClient.post("spend_request/archivemultiple", formData);
    },
    {
      onSuccess: (responseData, mutateData) => {
        mutateData.setShowArchive();
        queryClient.invalidateQueries("getPayments");
        toast.info("تم الأرشفة بنجاح.", { autoClose: 3000 });
      },
      onError: (error) => {
        return error;
      },
    }
  );
}
