import { useEffect, useState } from "react";

import Pagination from "antd/es/pagination";
import "antd/es/pagination/style/css";
import { default as AntdTable } from "antd/es/table";
import "antd/es/table/style/css";

import Spinner from "../Spinner";
import FieldError from "../../resources/icons/FieldError";
import EmptySpace from "../../resources/icons/EmptySpace";
import Arrow from "../../resources/icons/Arrow";
import ThreeDots from "../../resources/icons/ThreeDots";

import { useDataLayerValue } from "../../../context/DataLayer";
import actionTypes from "../../../context/types";

import "./Table.scss";
import UnderDevelopment from "../../resources/icons/UnderDevelopment";
import SearchEmpty from "../TableResults/SearchEmpty";
import EmptyData from "../TableResults/EmptyData";
import DevelopmentSection from "../TableResults/DevelopmentSection";
import TimeOut from "../TableResults/TimeOut";
import { contextType } from "react-grid-layout";

const Table = ({
  rowKey,
  columns,
  data,
  error,
  development,
  isLoading,
  total,
  pageSize,
  currentPage,
  isPaginationDisabled,
  onChange,
  onPageChange,
  hasSelection = true,
  setSelectedRows,
  onTableRowExpand,
  setExpandedRows,
  expandedRows,
  expandedRow,
  textEmptyPage,
  buttonEmptyPage,
  textButtonEmptyPage,
  actionEmptyPage,
  hasFooter = true,
  scrollable = true,
  isFullHeight = false,
  setHoverId = (id) => {},
  maxHeight = null,
  onScroll= () => {}
}) => {
  const [{}, dispatch] = useDataLayerValue();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  useEffect(() => {
    setSelectedRowKeys([]);
    //setExpandedRows([]);
    dispatch({
      type: actionTypes.SELECTED_ROWS,
      payload: {
        length: 0,
        amount: 0,
      },
    });
  }, [data]);

  const rowSelection = {
    type: "checkbox",
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      //
      // related to the component that consume Table not the table itself
      setSelectedRows && setSelectedRows(selectedRows);
      //
      dispatch({
        type: actionTypes.SELECTED_ROWS,
        payload: {
          length: selectedRows.length > 0 ? selectedRows.length : 0,
          amount:
            selectedRows.length > 0
              ? selectedRows
                  .map((el) => +el.amount)
                  ?.reduce((cur, acc) => cur + acc)
              : 0,
        },
      });
    },
  };

  useEffect(() => {
    /*const rows = document.querySelectorAll(".ant-table-container .ant-table-content .ant-table-row");
    const rowsArray = Array.from(rows);
    for(let row of rowsArray) {
      row.addEventListener("mouseenter", (e) => {
        setHoverId(row.getAttribute('data-row-key'));
      });
      row.addEventListener("mouseleave", (e) => {
        setHoverId(null);
      });
    } */
    document.getElementsByClassName("ant-table-content")[0].scrollTo({
      left: 0,
      top: 0,
      behavior: "smooth",
    });
    if(maxHeight) document.querySelector('.max-height-table .ant-table-content').addEventListener("scroll", onScroll);
  }, [data]);

  useEffect(() => {
    if(maxHeight) {
      document.querySelector('.max-height-table .ant-table-content').style.minHeight = maxHeight;
      document.querySelector('.max-height-table .ant-table-content').style.maxHeight = maxHeight;
      document.querySelector('.max-height-table .ant-table-content').style.overflowY = "auto";
    }
  }, [maxHeight]);

  useEffect(() => {
    if(!scrollable) {
      let height = 69;
      const tableRows = document.querySelectorAll('.not-scrollable-table .ant-table-row-level-0');
      if(tableRows && tableRows.length > 0) height = tableRows[0].offsetHeight;
      const tableHeadHeight = document.querySelectorAll('.not-scrollable-table .ant-table-thead')[0].offsetHeight;
      const totalHeight = (total * height) + tableHeadHeight;
      document.querySelector('.not-scrollable-table .ant-table-content').style.minHeight = "auto";
      document.querySelector('.not-scrollable-table .ant-table-content').style.maxHeight = totalHeight + "px";
      document.querySelector('.not-scrollable-table .ant-table-content').style.height = totalHeight + "px";
      document.querySelector('.not-scrollable-table .ant-table-content').style.overflowY = "hidden";
    }
    if(isFullHeight){
      document.querySelector('.ant-table-content').style.maxHeight = "100%";
      document.querySelector('.ant-table-content').style.overflowY = "hidden";
    }
  }, [data, total]);

  return (
    <AntdTable
      tableLayout="fixed"
      rowSelection={
        hasSelection && {
          ...rowSelection,
        }
      }
      expandRowByClick
      onExpand={onTableRowExpand}
      expandedRowKeys={expandedRows}
      expandIconColumnIndex={-1}
      expandable={{
        expandedRowRender: expandedRow,
        rowExpandable: (record) => record.name !== "Not Expandable",
      }}
      onChange={onChange}
      showSorterTooltip={false}
      pagination={false}
      className={!scrollable ? "not-scrollable-table" : maxHeight ? "max-height-table" : ""}
      rowClassName={(record, index) => {
        let highlightClassName = "";
        if (record.recent != null) {
          if (record.recent === 1) {
            highlightClassName = "font-bold";
          } else {
            highlightClassName = "font-medium";
          }
        }
        return highlightClassName;
      }}
      loading={{
        indicator: (
          <div className="w-8 h-8">
            <Spinner />
          </div>
        ),
        spinning: isLoading,
      }}
      locale={{
        emptyText: isLoading ? (
          <div className="p-px mt-px w-full flex justify-center items-center table-states">
            ...Loading
          </div>
        ) : development ? (
          <div className="w-full flex flex-col justify-center items-center table-states">
            <div>
              <DevelopmentSection />
            </div>
          </div>
        ) : error ? (
          <div className="w-full flex flex-col justify-center items-center table-states">
            <div>
              {error.response?.data ==
              "You don't have the required permissions" ? (
                <TimeOut text="لا تمتلك صلاحية الاطلاع علي هذه البيانات" />
              ) : (
                <TimeOut />
              )}
            </div>
          </div>
        ) : //  data empty without search condation
        document.getElementById("search-term")?.value?.trim() == "" ||
          !document.getElementById("search-term") ? (
          <div className="w-full flex flex-col justify-center items-center table-states">
            <EmptyData
              button={buttonEmptyPage}
              text={textEmptyPage}
              buutonText={textButtonEmptyPage}
              action={actionEmptyPage}
            />
          </div>
        ) : (
          <div className="w-full flex flex-col justify-center items-center table-states">
            <SearchEmpty />
          </div>
        ),
      }}
      footer={() =>
        hasFooter && (
          <div className="w-full border-t border-separate mt-1 pl-10 pr-5">
            <Pagination
              current={currentPage}
              onChange={onPageChange}
              total={total}
              pageSize={pageSize}
              showSizeChanger={false}
              hideOnSinglePage={false}
              disabled={isPaginationDisabled}
              itemRender={(page, type, originalElement) => (
                <>
                  {type !== "prev" && type !== "next" && type !== "page" && (
                    <div>
                      <ThreeDots />
                    </div>
                  )}
                  {type === "page" && <span>{page}</span>}
                  {type === "prev" && (
                    <div className="flex items-center">
                      <span className="ml-3 text-dark">Previous</span>
                      <div className="transform rotate-180">
                        <Arrow />
                      </div>
                    </div>
                  )}
                  {type === "next" && (
                    <div className="flex items-center">
                      <div>
                        <Arrow />
                      </div>
                      <span className="mr-3 text-dark">Next</span>
                    </div>
                  )}
                </>
              )}
            />
          </div>
        )
      }
      columns={columns}
      dataSource={data}
      rowKey={rowKey}
    />
  );
};

export default Table;
