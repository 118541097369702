import React from "react";

import { useForm } from "react-hook-form";

import Input from "../../common/inputs/Input";

import apiClient from "../../../services/apiClient";

import "./ForgetPassword.scss";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const ForgetPassword = ({ onClose, url }) => {
  const {t, i18n} = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
  });

  const forgetPasswordMutation = useMutation(
    (body) => apiClient.post("/user/forgetpassword", body),
    {
      onSuccess: () => {
        onClose();
        toast.info(t("link_sent_mail"), {
          autoClose: 3000,
        });
      },
    }
  );

  const handleForgetPassword = (data) => {
    let body = new FormData();
    body.append("email", data.email);
    forgetPasswordMutation.mutate(body);
  };

  return (
    <div className="overlay" onClick={() => onClose()} dir={i18n.resolvedLanguage === "en" ? "ltr" : "rtl"}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <form
          class="bg-gray-50 flex flex-col justify-center py-6 rounded-lg forget-container"
          onSubmit={handleSubmit(handleForgetPassword)}
        >
          <div>
            <div className="sm:mx-auto sm:w-full">
              <h2 className="mt-2 text-center text-lg font-extrabold text-gray-900">
                {t("enter_email")}
              </h2>
            </div>
            <div className="mt-8 sm:mx-auto sm:w-full">
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <div>
                  <Input
                    register={register}
                    type="email"
                    name="email"
                    fieldName={t("email")}
                    label={t("email")}
                    placeholder={t("email")}
                    hint={t("enter_email")}
                    required={true}
                    maxLength="50"
                    pattern={{
                      regex:
                        /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
                      errorMessage: t("invalid_email"),
                    }}
                    validate={{
                      validateRepetition: async (value) => {
                        const { data } = await apiClient.get(
                          "/user/checkifexists",
                          { params: { email: value } }
                        );
                        return data.exists || t("email_not_found");
                      },
                    }}
                    errors={errors?.email}
                  />
                </div>

                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 mt-6 bg-black border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white"
                  >
                    {t("reset_password")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgetPassword;
