import React, { Fragment, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Spinner from "../../common/Spinner";
import ForgetPassword from "../../auth/ForgetPassword";
import Input from "../../common/inputs/Input";
import UploadInput from "../../common/inputs/Upload";
import Modal from "../../common/Modal";
import useMutatePerson from "../../../hooks/reactQuery/useMutatePerson";
import useFetchUserData from "../../../hooks/reactQuery/useFetchUserData";
import { photoPreview } from "../../../utils/photoPreview";
import { useTranslation } from "react-i18next";

const UserSettings = ({}) => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();

  const [file, setFile] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState(
    "img/upload-profile.png"
  );

  const [showForgetPasswordModal, setShowForgetPasswordModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
    setError,
    clearErrors,
    control,
    trigger,
    reset,
  } = useForm({
    mode: "all",
  });

  // useEffect(() => {
  //   if (userData) {
  //     reset({
  //       userName: userData.user_name,
  //       firstName: userData?.first_name,
  //       lastName: userData?.last_name,
  //     });
  //   }
  // }, [reset, userData]);

  useEffect(() => {
    const userImageUrl =
      userData?.id !== null
        ? (process.env.REACT_APP_UPLOAD_FILES_BASE_URL
            ? process.env.REACT_APP_UPLOAD_FILES_BASE_URL
            : "") +
          (process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
            ? process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
            : "images/tarbeetapaymentrequests/") +
          `users/${userData?.id}/profilepicture` +
          `.${userData?.profile_picture_ext}`
        : "../img/default-user.svg";
    setImagePreviewUrl(userImageUrl);
  }, [userData]);

  const {
    isLoading: isPersonMutationLoading,
    mutate: personMutation,
    status: personMutationStatus,
  } = useMutatePerson(userData.id, setFile);
  const handleChangeAccountSettings = (data) => {
    data.file = file;
    personMutation(data);
  };

  const isFormValid =
    getValues("userName") === userData?.user_name &&
    getValues("firstName") === userData?.first_name &&
    getValues("lastName") === userData?.last_name &&
    !file;

  return (
    <div className="bg-gray-50 lg:grid lg:grid-cols-12 lg:gap-x-5 w-full">
      <form
        className="col-span-12"
        onSubmit={handleSubmit(handleChangeAccountSettings)}
      >
        <div className="sm:rounded-md sm:overflow-hidden flex flex-col justify-between">
          <div className="py-6 px-4  space-y-6">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
              {t("account_info")}
              </h3>
            </div>
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6">
                <div className="flex flex-col justify-center items-center select-none">
                  <div>
                    <label
                      className="flex flex-col justify-center items-center relative cursor-pointer"
                      htmlFor="accountImage"
                    >
                      <div className="w-16 h-16">
                        <img
                          className="w-full h-full object-cover rounded-lg"
                          htmlFor="photo-upload"
                          alt="user image"
                          src={imagePreviewUrl}
                        />
                      </div>
                    </label>
                  </div>
                  <UploadInput
                    multiple={false}
                    accept="image/*"
                    name="accountImage"
                    id="accountImage"
                    hint={t("pick_profile_pic")}
                    showUplodedFilesNames={false}
                    getValues={getValues}
                    setError={setError}
                    clearErrors={clearErrors}
                    fileSize="5000000"
                    errors={errors.accountImage}
                    Controller={Controller}
                    control={control}
                    setValue={setValue}
                    trigger={trigger}
                    photoPreview={(e) =>
                      photoPreview(e, setFile, setImagePreviewUrl)
                    }
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <Input
                  register={register}
                  type="text"
                  name="userName"
                  fieldName={t("user_name")}
                  placeholder={t("user_name")}
                  defaultValue={userData?.user_name}
                  required={true}
                  maxLength="50"
                  pattern={{
                    regex: /^(?!.*\s)(?=.+)/,
                    errorMessage:
                      t("no_spaces_allowed")
                  }}
                  errors={errors.userName}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  register={register}
                  type="text"
                  name="email"
                  fieldName={t("email")}
                  placeholder={t("email")}
                  defaultValue={userData?.email}
                  required={false}
                  isDisabled={true}
                  errors={errors.email}
                />
              </div>
            </div>

            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <Input
                  register={register}
                  type="text"
                  name="firstName"
                  fieldName={t("first_name")}
                  placeholder={t("first_name")}
                  defaultValue={userData?.first_name}
                  required={true}
                  maxLength="50"
                  errors={errors.firstName}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  register={register}
                  type="text"
                  name="lastName"
                  fieldName={t("last_name")}
                  placeholder={t("last_name")}
                  defaultValue={userData?.last_name}
                  required={true}
                  maxLength="50"
                  errors={errors.lastName}
                />
              </div>
            </div>

            <Fragment>
              <button
                type="button"
                className="text-blue-600"
                onClick={() => setShowForgetPasswordModal(true)}
              >
                {t("change_password")}
              </button>
              <Modal
                showModal={showForgetPasswordModal}
                onClose={() => setShowForgetPasswordModal(false)}
              >
                <ForgetPassword
                  onClose={() => setShowForgetPasswordModal(false)}
                  url="api/url"
                />
              </Modal>
            </Fragment>
          </div>

          <div className="px-4 py-3 bg-white text-right sm:px-6">
            <button
              type="submit"
              className={`flex items-center bg-black py-2 px-10 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white w-full sm:w-auto ${
                (isFormValid || isPersonMutationLoading) &&
                "cursor-not-allowed disabled bg-opacity-50"
              }`}
              disabled={isFormValid || isPersonMutationLoading}
            >
              {t("confirm")}
              {isPersonMutationLoading && (
                <div className="w-5 h-5 mr-4">
                  <Spinner />
                </div>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UserSettings;
