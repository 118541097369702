const actionTypes = {
  SWITCH_TAB: "SWITCH_TAB",
  DOCUMENT_FORM: "DOCUMENT_FORM",
  PAYMENT_LICENSE_FORM: "PAYMENT_LICENSE_FORM",
  SEARCH_TYPE: "SEARCH_TYPE",
  SEARCH_TERMS: "SEARCH_TERMS",
  RESET_STATE: "RESET_STATE",
  ACTIVE_TAB: "ACTIVE_TAB",
  IS_FILE_UPLOADING: "IS_FILE_UPLOADING",
  SELECTED_ROWS: "SELECTED_ROWS",
  IS_SIDEBAR_EXPANDED: "IS_SIDEBAR_EXPANDED"
};

export default actionTypes;
