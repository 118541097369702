import { useEffect, useState, useRef } from 'react';
import {useHistory} from 'react-router-dom';
import "../dashboard.scss";
import Selector from '../../../resources/icons/Selector';
import {
  startOfWeek,
  endOfWeek
} from "date-fns";
import Total from '../Total';
import DateRangePickerComponent from '../../../common/DateRangePicker/DateRangePicker';
import CashinBox from '../../../resources/icons/CashinBox';
import WithdrawalsBox from '../../../resources/icons/WithdrawalsBox';
import DocumentsBox from '../../../resources/icons/DocumentsBox';
import useFetchCashinDashboard from '../../../../hooks/reactQuery/useFetchCashinDashboard';
import useFetchWithdrawalsDashboard from '../../../../hooks/reactQuery/useFetchWithdrawalsDashboard';
import useFetchDocumentsDashboard from '../../../../hooks/reactQuery/useFetchDocumentsDashboard';
import { useTranslation } from "react-i18next";

export default function TotalSection({safeId}) {
  const {t, i18n} = useTranslation();
  const history = useHistory();

  const [showDatePicker, setShowDatePicker] = useState(false);
  const filterBtnRef = useRef(null);
  const datePickerRef = useRef(null);

  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: startOfWeek(new Date()),
      endDate: endOfWeek(new Date()),
      label: t("current_week"),
      key: "selection"
    }
  ]);

  const [depositsSelectionRange, setDepositsSelectionRange] = useState([
    {
      startDate: startOfWeek(new Date()),
      endDate: endOfWeek(new Date()),
      label: t("current_week"),
      key: "selection"
    }
  ]);

  const [withdrawalsSelectionRange, setWithdrawalsSelectionRange] = useState([
    {
      startDate: startOfWeek(new Date()),
      endDate: endOfWeek(new Date()),
      label: t("current_week"),
      key: "selection"
    }
  ]);

  const [documentsSelectionRange, setDocumentsSelectionRange] = useState([
      {
        startDate: startOfWeek(new Date()),
        endDate: endOfWeek(new Date()),
        label: t("current_week"),
        key: "selection"
      }
  ]);

  useEffect(() => {
      setDepositsSelectionRange(selectionRange);
      setWithdrawalsSelectionRange(selectionRange);
      setDocumentsSelectionRange(selectionRange);
  }, [selectionRange]);

  useEffect(() => {
    document.addEventListener("click", e => {
      if(datePickerRef && datePickerRef.current && filterBtnRef && filterBtnRef.current && showDatePicker) {
        if(!datePickerRef.current.contains(e.target) && !filterBtnRef.current.contains(e.target)) setShowDatePicker(false);
      }
    });
    
    return () => {
      document.removeEventListener("click", e => {
        if(datePickerRef && datePickerRef.current && filterBtnRef && filterBtnRef.current && showDatePicker) {
          if(!datePickerRef.current.contains(e.target) && !filterBtnRef.current.contains(e.target)) setShowDatePicker(false);
        }
      });
    }
  }, [showDatePicker]);

  //Cashin Data
  const [cashinState, setCashinState] = useState([]);
  const [cashinLastPage, setCashinLastPage] = useState(1);
  const [currentCashinPage, setCurrentCashinPage] = useState(1);

  const {
    isLoading: isCashinDashboardLoading,
    data: cashinDashboard,
    error: cashinDashboardError,
  } = useFetchCashinDashboard(safeId, currentCashinPage, 10, depositsSelectionRange);


  const [cashinPieChartData, setCashinPieChartData] = useState([]);

  useEffect(() => {
    if(cashinDashboard) {
      let categories = [...cashinDashboard[2]].sort((a,b) => a.count - b.count).reverse();
      categories = categories.map((cat, index) => {
        let fill = index === 2 ? "#8EFFD3" : index === 1 ? "#42EBB3" : index === 0 ? "#508970" : "";
        return {...cat, name: cat.name, value: cat.count, fill};
      });
      setCashinPieChartData(categories);
      setCashinLastPage(Math.ceil(cashinDashboard[1][0].count /10));
      if(currentCashinPage === 1) setCashinState(cashinDashboard[0]);
      else setCashinState(currentCashin => [...currentCashin, ...cashinDashboard[0]]);
    }
  }, [cashinDashboard]);


  //Withdrawals Data
  const [withdrawalsState, setWithdrawalsState] = useState([]);
  const [withdrawalsLastPage, setWithdrawalsLastPage] = useState(1);
  const [currentWithdrawalsPage, setCurrentWithdrawalsPage] = useState(1);

  const {
    isLoading: isWithdrawalsDashboardLoading,
    data: withdrawalsDashboard,
    error: withdrawalsDashboardError,
  } = useFetchWithdrawalsDashboard(safeId, currentWithdrawalsPage, 10, withdrawalsSelectionRange);


  const [withdrawalsPieChartData, setWithdrawalsPieChartData] = useState([]);

  useEffect(() => {
    if(withdrawalsDashboard) {
      let categories = [...withdrawalsDashboard[2]].sort((a,b) => a.count - b.count).reverse();
      categories = categories.map((cat, index) => {
        let fill = index === 2 ? "#FFEF98" : index === 1 ? "#FFCD86" : index === 0 ? "#93825A" : "";
        return {...cat, name: cat.name, value: cat.count, fill};
      });
      setWithdrawalsPieChartData(categories);
      setWithdrawalsLastPage(Math.ceil(withdrawalsDashboard[1][0].count /10));
      if(currentWithdrawalsPage === 1) setWithdrawalsState(withdrawalsDashboard[0]);
      else setWithdrawalsState(currentWithdrawals => [...currentWithdrawals, ...withdrawalsDashboard[0]]);
    }
  }, [withdrawalsDashboard]);


  //Documents Data 
  const [documentsState, setDocumentsState] = useState([]);
  const [documentsLastPage, setDocumentsLasPage] = useState(1);
  const [currentDocumentsPage, setCurrentDocumentsPage] = useState(1);

  const {
    isLoading: isDocumentsDashboardLoading,
    data: documentsDashboard,
    error: documentsDashboardError,
  } = useFetchDocumentsDashboard(safeId, currentDocumentsPage, 10, documentsSelectionRange);


  const [documentsPieChartData, setDocumentsPieChartData] = useState([]);

  useEffect(() => {
    if(documentsDashboard) {
      let categories = [...documentsDashboard[2]].sort((a,b) => a.count - b.count).reverse();
      categories = categories.map((cat, index) => {
        let fill = index === 2 ? "#FFAF98" : index === 1 ? " #FF805C" : index === 0 ? "#93655A" : "";
        return {...cat, name: cat.name, value: cat.count, fill};
      });
      setDocumentsPieChartData(categories);
      setDocumentsLasPage(Math.ceil(documentsDashboard[1][0].count /10));
      if(currentDocumentsPage === 1) setDocumentsState(documentsDashboard[0]);
      else setDocumentsState(currentDocuments => [...currentDocuments, ...documentsDashboard[0]]);
    }
  }, [documentsDashboard]);
  
  useEffect(() => {
    setCurrentWithdrawalsPage(1);
  }, [withdrawalsSelectionRange]);

  useEffect(() => {
    setCurrentCashinPage(1);
  }, [depositsSelectionRange]);

  useEffect(() => {
    setCurrentDocumentsPage(1);
  }, [documentsSelectionRange]);

  return (
      <div className='mx-4 my-3 total-section'>
          <div className='total-section-header'>
              <button className='total-section-filter-btn' onClick={() => setShowDatePicker(!showDatePicker)} ref={filterBtnRef}>
                <Selector fill="#3B82F6"/>{selectionRange[0].label}
              </button>
              {showDatePicker ? <div className='total-section-date-picker' style={{direction: "ltr"}} ref ={datePickerRef}>
              <DateRangePickerComponent selectionRange={selectionRange} setSelectionRange={setSelectionRange} />
            </div> : null}           
          </div>
          <div className='totals-container'>
              <Total 
                  title={t("total_deposits")}
                  transactionsTitle={t("deposit_transactions")}
                  count={cashinDashboard && cashinDashboard[1][0].count}
                  icon={<CashinBox />}
                  total={new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(cashinDashboard && cashinDashboard[1][0].total)}
                  selectionRange={depositsSelectionRange} 
                  setSelectionRange={setDepositsSelectionRange} 
                  pieChartData={cashinPieChartData}
                  currentPage={currentCashinPage} 
                  lastPage={cashinLastPage} 
                  setCurrentPage={setCurrentCashinPage} 
                  isLoading={isCashinDashboardLoading}
                  transactions={cashinState}
                  compare={cashinDashboard && cashinDashboard[3][0]}
                  onClick={(safeId, id) => history.push({
                    pathname: `/safes/${safeId}/cashin/${id}`,
                    state: { comingFrom: t("total_deposits"), fallback: "/safe", title: t("deposit")}
                  })}
                  downColor="#FF805C"
                  upColor="#59A0FF"
              />
              <Total
                  title={t("total_withdrawals")}
                  transactionsTitle={t("withdraw_transactions")}
                  icon={<WithdrawalsBox />}
                  count={withdrawalsDashboard && withdrawalsDashboard[1][0].count}
                  total={new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(withdrawalsDashboard && withdrawalsDashboard[1][0].total)}
                  selectionRange={withdrawalsSelectionRange} 
                  setSelectionRange={setWithdrawalsSelectionRange} 
                  pieChartData={withdrawalsPieChartData}
                  currentPage={currentWithdrawalsPage} 
                  lastPage={withdrawalsLastPage} 
                  setCurrentPage={setCurrentWithdrawalsPage} 
                  isLoading={isWithdrawalsDashboardLoading}
                  transactions={withdrawalsState}
                  compare={withdrawalsDashboard && withdrawalsDashboard[3][0]}
                  onClick={(safeId, id) => history.push({
                    pathname: `/safes/${safeId}/money_request/${id}`,
                    state: { comingFrom: t("total_withdrawals"), fallback: "/safe", title: t("withdraw")}
                  })}
                  downColor="#FF805C"
                  upColor="#59A0FF"
              />
              <Total 
                  title={t("total_documents")}
                  transactionsTitle={t("document_transactions")}
                  icon={<DocumentsBox />}
                  count={documentsDashboard && documentsDashboard[1][0].count}
                  total={new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(documentsDashboard && documentsDashboard[1][0].total)}
                  selectionRange={documentsSelectionRange} 
                  setSelectionRange={setDocumentsSelectionRange} 
                  alignDatePickerLeft={true}
                  pieChartData={documentsPieChartData}
                  currentPage={currentDocumentsPage} 
                  lastPage={documentsLastPage} 
                  setCurrentPage={setCurrentDocumentsPage} 
                  isLoading={isDocumentsDashboardLoading}
                  transactions={documentsState}
                  compare={documentsDashboard && documentsDashboard[3][0]}
                  onClick={(safeId, id) => history.push({
                    pathname: `/safes/${safeId}/document/${id}`,
                    state: { comingFrom: t("total_documents"), fallback: "/safe", title: t("document")}
                  })}
                  downColor="#59A0FF"
                  upColor="#FF805C"
              />
          </div>
      </div>
  );
}