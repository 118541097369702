const AttachmentSvg = ({}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={19}
      height={17}
      viewBox="0 0 19 17"
      fill="none"
    >
      <path
        d="M17.3333 1.40885C16.3889 0.496422 15.2431 0.0402069 13.8958 0.0402069C12.5486 0.0402069 11.3889 0.496422 10.4167 1.40885L1.33333 10.2245C0.611111 10.9223 0.25 11.7408 0.25 12.68C0.25 13.6193 0.611111 14.4378 1.33333 15.1355C2.05556 15.8333 2.88889 16.1822 3.83333 16.1822C4.75 16.1822 5.56944 15.8333 6.29167 15.1355L15.4583 6.27961C15.875 5.90391 16.0833 5.39402 16.0833 4.74995C16.0833 4.18639 15.875 3.66309 15.4583 3.18004C15.0139 2.75066 14.4861 2.53597 13.875 2.53597C13.2639 2.53597 12.7639 2.75066 12.375 3.18004L3.91667 11.3516C3.5 11.7542 3.5 12.1567 3.91667 12.5593C4.30556 12.9887 4.72222 12.9887 5.16667 12.5593L13.5 4.38767C13.7222 4.19981 13.9306 4.19981 14.125 4.38767C14.3194 4.60235 14.3194 4.80363 14.125 4.99148L5.08333 13.8474C4.72222 14.1963 4.30556 14.3707 3.83333 14.3707C3.36111 14.3707 2.94444 14.1963 2.58333 13.8474C2.22222 13.4985 2.04167 13.096 2.04167 12.6398C2.04167 12.1836 2.22222 11.781 2.58333 11.4322L11.625 2.61648C12.2639 1.99925 13.0139 1.69063 13.875 1.69063C14.7361 1.69063 15.4722 1.99925 16.0833 2.61648C16.6944 3.20687 17 3.91132 17 4.72983C17 5.54833 16.6944 6.2662 16.0833 6.88343L8.25 14.4512C7.86111 14.8269 7.86111 15.2295 8.25 15.6589C8.69444 16.0346 9.11111 16.0346 9.5 15.6589L17.3333 8.09106C18.2778 7.15179 18.75 6.03809 18.75 4.74995C18.75 3.46182 18.2778 2.34812 17.3333 1.40885Z"
        fill="#5473FF"
      />
    </svg>
  );
};

export default AttachmentSvg;
