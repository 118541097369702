const UserRoleSelect = () => {
  return (
    <div>
      <svg
        width={20}
        height={20}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.9999 22.76C10.9099 22.76 9.82992 22.44 8.97992 21.81L4.6799 18.6C3.5399 17.75 2.6499 15.98 2.6499 14.56V7.13001C2.6499 5.59001 3.77992 3.95001 5.22992 3.41001L10.2199 1.54001C11.2099 1.17001 12.7699 1.17001 13.7599 1.54001L18.7599 3.41001C20.2099 3.95001 21.3399 5.59001 21.3399 7.13001V14.56C21.3399 15.98 20.4499 17.75 19.3099 18.6L15.0099 21.81C14.1699 22.44 13.0899 22.76 11.9999 22.76ZM10.7499 2.94001L5.75992 4.81C4.89992 5.13 4.1499 6.21001 4.1499 7.13001V14.56C4.1499 15.51 4.81992 16.84 5.56992 17.4L9.8699 20.61C11.0199 21.47 12.9699 21.47 14.1199 20.61L18.4199 17.4C19.1799 16.83 19.8399 15.5 19.8399 14.56V7.13001C19.8399 6.22001 19.0899 5.14 18.2299 4.81L13.2399 2.94001C12.5799 2.69001 11.4199 2.69001 10.7499 2.94001Z"
          fill="#94A3B8"
        />
        <path
          d="M11.9999 11.67C11.9799 11.67 11.9599 11.67 11.9299 11.67C10.4799 11.63 9.41992 10.52 9.41992 9.17004C9.41992 7.79004 10.5499 6.66003 11.9299 6.66003C13.3099 6.66003 14.4399 7.79004 14.4399 9.17004C14.4299 10.53 13.3699 11.63 12.0199 11.68C12.0099 11.67 12.0099 11.67 11.9999 11.67ZM11.9299 8.16003C11.3699 8.16003 10.9199 8.61004 10.9199 9.17004C10.9199 9.72004 11.3499 10.16 11.8899 10.18C11.8899 10.18 11.9399 10.18 11.9999 10.18C12.5299 10.15 12.9399 9.71004 12.9399 9.17004C12.9499 8.61004 12.4899 8.16003 11.9299 8.16003Z"
          fill="#94A3B8"
        />
        <path
          d="M11.9998 17.35C11.1398 17.35 10.2698 17.12 9.59982 16.67C8.92982 16.23 8.5498 15.58 8.5498 14.89C8.5498 14.2 8.92982 13.55 9.59982 13.1C10.9498 12.2 13.0598 12.21 14.3998 13.1C15.0698 13.54 15.4498 14.19 15.4498 14.88C15.4498 15.57 15.0698 16.22 14.3998 16.67C13.7298 17.12 12.8598 17.35 11.9998 17.35ZM10.4298 14.34C10.1798 14.5 10.0398 14.7 10.0498 14.88C10.0498 15.06 10.1898 15.26 10.4298 15.42C11.2698 15.98 12.7298 15.98 13.5698 15.42C13.8198 15.26 13.9598 15.06 13.9598 14.88C13.9598 14.7 13.8198 14.5 13.5798 14.34C12.7398 13.79 11.2698 13.79 10.4298 14.34Z"
          fill="#94A3B8"
        />
      </svg>
    </div>
  );
};

export default UserRoleSelect;
