import { useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useActivatMember(setActivatModal, row) {
  const queryClint = useQueryClient();

  return useMutation(
    () => {
      const formData = new FormData();
      formData.append("activationUserId", row.id);
      return apiClient.post("user/activation", formData);
    },
    {
      onSuccess: () => {
        toast.info("تم تعديل نشاط هذا العضو بنجاح.");
        queryClint.invalidateQueries("team");
        setActivatModal(false);
      },
      onError: (error) => {
        toast.error(error.response.data.message);
        setActivatModal(false);
        return error;
      },
    }
  );
}
