const InputDate = ({}) => {
  return (
    <div>
      <svg
        width={20}
        height={18}
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.94596 4.3125C6.63704 4.3125 6.38086 4.0575 6.38086 3.75V1.5C6.38086 1.1925 6.63704 0.9375 6.94596 0.9375C7.25489 0.9375 7.51107 1.1925 7.51107 1.5V3.75C7.51107 4.0575 7.25489 4.3125 6.94596 4.3125Z"
          fill="#94A3B8"
        />
        <path
          d="M12.9733 4.3125C12.6644 4.3125 12.4082 4.0575 12.4082 3.75V1.5C12.4082 1.1925 12.6644 0.9375 12.9733 0.9375C13.2822 0.9375 13.5384 1.1925 13.5384 1.5V3.75C13.5384 4.0575 13.2822 4.3125 12.9733 4.3125Z"
          fill="#94A3B8"
        />
        <path
          d="M7.32183 10.8747C7.22388 10.8747 7.12593 10.8523 7.03551 10.8148C6.93756 10.7773 6.86221 10.7247 6.78687 10.6572C6.65124 10.5147 6.56836 10.3272 6.56836 10.1247C6.56836 10.0272 6.59096 9.92974 6.62864 9.83974C6.66631 9.74974 6.71905 9.66725 6.78687 9.59225C6.86221 9.52475 6.93756 9.47224 7.03551 9.43474C7.30676 9.32224 7.64582 9.38225 7.8568 9.59225C7.99242 9.73475 8.0753 9.92975 8.0753 10.1247C8.0753 10.1697 8.06777 10.2223 8.06023 10.2748C8.0527 10.3198 8.03763 10.3647 8.01502 10.4097C7.99996 10.4547 7.97735 10.4997 7.94722 10.5447C7.92461 10.5822 7.88694 10.6197 7.8568 10.6572C7.71364 10.7922 7.51773 10.8747 7.32183 10.8747Z"
          fill="#94A3B8"
        />
        <path
          d="M9.9605 10.8742C9.86255 10.8742 9.7646 10.8517 9.67418 10.8142C9.57623 10.7767 9.50089 10.7242 9.42554 10.6567C9.28991 10.5142 9.20703 10.3267 9.20703 10.1242C9.20703 10.0267 9.22964 9.92917 9.26731 9.83917C9.30498 9.74917 9.35773 9.66668 9.42554 9.59168C9.50089 9.52418 9.57623 9.47166 9.67418 9.43416C9.94543 9.31416 10.2845 9.38168 10.4955 9.59168C10.6311 9.73418 10.714 9.92917 10.714 10.1242C10.714 10.1692 10.7064 10.2217 10.6989 10.2742C10.6914 10.3192 10.6763 10.3642 10.6537 10.4092C10.6386 10.4542 10.616 10.4992 10.5859 10.5442C10.5633 10.5817 10.5256 10.6192 10.4955 10.6567C10.3523 10.7917 10.1564 10.8742 9.9605 10.8742Z"
          fill="#94A3B8"
        />
        <path
          d="M12.5972 10.8742C12.4993 10.8742 12.4013 10.8517 12.3109 10.8142C12.213 10.7767 12.1376 10.7242 12.0623 10.6567C12.0321 10.6192 12.002 10.5817 11.9718 10.5442C11.9417 10.4992 11.9191 10.4542 11.904 10.4092C11.8814 10.3642 11.8664 10.3192 11.8588 10.2742C11.8513 10.2217 11.8438 10.1692 11.8438 10.1242C11.8438 9.92917 11.9266 9.73418 12.0623 9.59168C12.1376 9.52418 12.213 9.47166 12.3109 9.43416C12.5897 9.31416 12.9212 9.38168 13.1322 9.59168C13.2678 9.73418 13.3507 9.92917 13.3507 10.1242C13.3507 10.1692 13.3432 10.2217 13.3356 10.2742C13.3281 10.3192 13.313 10.3642 13.2904 10.4092C13.2753 10.4542 13.2527 10.4992 13.2226 10.5442C13.2 10.5817 13.1623 10.6192 13.1322 10.6567C12.989 10.7917 12.7931 10.8742 12.5972 10.8742Z"
          fill="#94A3B8"
        />
        <path
          d="M7.32183 13.4984C7.22388 13.4984 7.12593 13.4759 7.03551 13.4384C6.94509 13.4009 6.86221 13.3484 6.78687 13.2809C6.65124 13.1384 6.56836 12.9434 6.56836 12.7484C6.56836 12.6509 6.59096 12.5534 6.62864 12.4634C6.66631 12.3659 6.71905 12.2834 6.78687 12.2159C7.06565 11.9384 7.57801 11.9384 7.8568 12.2159C7.99242 12.3584 8.0753 12.5534 8.0753 12.7484C8.0753 12.9434 7.99242 13.1384 7.8568 13.2809C7.71364 13.4159 7.51773 13.4984 7.32183 13.4984Z"
          fill="#94A3B8"
        />
        <path
          d="M9.9605 13.4984C9.7646 13.4984 9.5687 13.4159 9.42554 13.2809C9.28991 13.1384 9.20703 12.9434 9.20703 12.7484C9.20703 12.6509 9.22964 12.5534 9.26731 12.4634C9.30498 12.3659 9.35773 12.2834 9.42554 12.2159C9.70432 11.9384 10.2167 11.9384 10.4955 12.2159C10.5633 12.2834 10.616 12.3659 10.6537 12.4634C10.6914 12.5534 10.714 12.6509 10.714 12.7484C10.714 12.9434 10.6311 13.1384 10.4955 13.2809C10.3523 13.4159 10.1564 13.4984 9.9605 13.4984Z"
          fill="#94A3B8"
        />
        <path
          d="M12.5972 13.5007C12.4013 13.5007 12.2054 13.4182 12.0623 13.2832C11.9944 13.2157 11.9417 13.1332 11.904 13.0357C11.8664 12.9457 11.8438 12.8482 11.8438 12.7507C11.8438 12.6532 11.8664 12.5557 11.904 12.4657C11.9417 12.3682 11.9944 12.2857 12.0623 12.2182C12.2356 12.0457 12.4993 11.9632 12.7404 12.0157C12.7931 12.0232 12.8383 12.0382 12.8835 12.0607C12.9288 12.0757 12.974 12.0982 13.0192 12.1282C13.0568 12.1507 13.0945 12.1882 13.1322 12.2182C13.2678 12.3607 13.3507 12.5557 13.3507 12.7507C13.3507 12.9457 13.2678 13.1407 13.1322 13.2832C12.989 13.4182 12.7931 13.5007 12.5972 13.5007Z"
          fill="#94A3B8"
        />
        <path
          d="M16.3644 7.37891H3.55534C3.24641 7.37891 2.99023 7.12391 2.99023 6.81641C2.99023 6.50891 3.24641 6.25391 3.55534 6.25391H16.3644C16.6733 6.25391 16.9295 6.50891 16.9295 6.81641C16.9295 7.12391 16.6733 7.37891 16.3644 7.37891Z"
          fill="#94A3B8"
        />
        <path
          d="M12.9735 17.0625H6.94575C4.19557 17.0625 2.61328 15.4875 2.61328 12.75V6.375C2.61328 3.6375 4.19557 2.0625 6.94575 2.0625H12.9735C15.7237 2.0625 17.306 3.6375 17.306 6.375V12.75C17.306 15.4875 15.7237 17.0625 12.9735 17.0625ZM6.94575 3.1875C4.79082 3.1875 3.74349 4.23 3.74349 6.375V12.75C3.74349 14.895 4.79082 15.9375 6.94575 15.9375H12.9735C15.1285 15.9375 16.1758 14.895 16.1758 12.75V6.375C16.1758 4.23 15.1285 3.1875 12.9735 3.1875H6.94575Z"
          fill="#94A3B8"
        />
      </svg>
    </div>
  );
};

export default InputDate;
