import { useQuery } from "react-query";
import apiClient from "../../services/apiClient";

// get total balance , capital and revenue balance to company by id
export default function useFetchSafeBalance() {
  return useQuery("getSafeBalance", () => {
    return apiClient
      .get("safe/balance")
      .then(({ data }) => ({
        ...data,
        originalValue: data.balance,
        remainValue: data.balance,
      }));
  });
}
