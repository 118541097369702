import { useState, Fragment } from "react";
import Modal from "./../../../../../components/common/Modal/index";
import Alert from "./../../../../../components/common/Alert/index";
import UsersAvatars from "../../../../../components/common/UsersAvatars.jsx";
import TableSortIcon from "../../../../../components/resources/icons/TableSortIcon";
import KeyIcon from "../../../../../components/resources/icons/KeyIcon";
import CashAmountIcon from "../../../../../components/resources/icons/CashAmountIcon";
import UserAlt from "../../../../../components/resources/icons/UserAlt";
import CategoryIcon from ".././../../../../components/resources/icons/CategoryIcon";
import InputDate from "../../../../../components/resources/icons/InputDate";
import Health from "../../../../../components/resources/icons/Health";
import useMutateDepositAccept from "./../../../../../hooks/reactQuery/useMutateDepositAccept";
import useMutateDepositReject from "./../../../../../hooks/reactQuery/useMutateDepositReject";
import { toolPin } from "../../../../../utils/toolPin";
import SafesIcon from "../../../../../components/resources/icons/SafesIcon";

export const columnsHeader = (t, i18n) => [
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <KeyIcon />
        </div>
        <div className="col-title">{t("transaction_code")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "id",
    key: "id",
    sorter: true,
    render: (text) => <div className="col-desc flex justify-center">{toolPin(String(text))}</div>,
    width: 100,
    customizeKey: t("transaction_code"),
    selected: true
  },
  {
    subject: "اسم الخزينة",
    title: () => (
      <div class="flex items-center justify-center">
        <SafesIcon fillColor={"#292D32"}/>
        <div style={{fontSize: "12px", fontWeight: "500", margin: "0px 3px"}}>{t("safe_name")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "safe_name",
    key: "safename",
    sorter: true,
    render: (text, row) => (
      <div className="flex items-center justify-center">
        <span style={{color: "#94A3B8", fontSize: "12px", fontWeight: "500"}}>
          {text}
        </span>
      </div>
    ),
    width: 150,
    selected: true
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <CashAmountIcon />
        </div>
        <div className="col-title">{t("amount")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "amount",
    key: "amount",
    sorter: true,
    render: (text) => <div className="col-desc flex justify-center">     
    {new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(parseInt(text)).toString().replace(/EGP/, "") + " "}
    {t("egp")}</div>,
    width: 100,
    customizeKey: t("amount"),
    selected: true
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <UserAlt />
        </div>
        <div className="col-title">{t("transaction_users")}</div>
      </div>
    ),
    dataIndex: "users",
    key: "users",
    render: (text) => (
      <div className="col-desc flex justify-center">
        <UsersAvatars usersArr={text} />
      </div>
    ),
    width: 100,
    customizeKey: t("transaction_users"),
    selected: true
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <CategoryIcon />
        </div>
        <div className="col-title">{t("category")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "category_name",
    key: "category_name",
    sorter: true,
    render: (text) => (
      <div className="text-sm text-body font-medium bg-sidebar-background p-2 rounded-xl flex justify-center items-center border border-transparent hover:border-secondary">
        {toolPin(text)}
      </div>
    ),
    width: 150,
    customizeKey: t("category"),
    selected: true
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <InputDate />
        </div>
        <div className="col-title">{t("creation_date")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "creation_date",
    key: "creation_date",
    sorter: true,
    render: (text) => (
      <div className="col-desc">{toolPin(text.split("T")[0])}</div>
    ),
    width: 100,
    customizeKey: t("creation_date"),
    selected: true
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <Health />
        </div>
        <div className="col-title">{t("response_status")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "approved",
    key: "approved",
    sorter: true,
    render: (text, row) => (
      <div className="flex justify-start items-center mx-0">
        {text === 1 ? (
          <span className="text-xs font-medium text-confirmation py-1.5 px-2.5 bg-confirmation bg-opacity-20 rounded-xl">
            {t("accepted")}
          </span>
        ) : text === 0 ? (
          <span className="text-xs font-medium text-danger py-1.5 px-2.5 bg-danger bg-opacity-20 rounded-xl">
            {t("rejected")}
          </span>
        ) : (
          <div className="tool-pin-container relative">
            <span className="text-xs font-medium text-onhold py-1.5 px-2.5 bg-onhold bg-opacity-20 rounded-xl">
              {t("transaction_pending")}
            </span>
            <div
              style={{ width: "170px" }}
              className="tool-pin text-sm text-body font-normal rounded-lg bg-white hidden absolute top-6 left-20 z-10"
            >
              <DropDown row={row} t={t} />
            </div>
          </div>
        )}
        {row.needPermission == 0 ? 
          <span className="doesNotNeedPermission">{t("no_permission_needed")}</span> : null
        }
      </div>
    ),
    width: 150,
    customizeKey: t("response_status"),
    selected: true
  },
];

function DropDown({ row, t }) {
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);

  const { isLoading: isAcceptLoading, mutate: mutateAccept } =
    useMutateDepositAccept(setShowAcceptModal);

  const { isLoading: isRejectLoading, mutate: mutateReject } =
    useMutateDepositReject(setShowRejectModal);

  return (
    <>
      <Fragment>
        <button
          type="button"
          className="w-full text-right py-2 px-4 hover:bg-secondary-hover hover:bg-opacity-5"
          onClick={(e) => {
            e.stopPropagation();
            setShowAcceptModal(true);
          }}
        >
          {t("accept")}
        </button>

        <Modal
          showModal={showAcceptModal}
          onClose={() => setShowAcceptModal(false)}
        >
          <Alert
            hasFooter={true}
            alertType="confirm"
            title={t("accept_deposit_request")}
            body={() => (
              <p>
                {t("alert_text_accept_deposit_request", {creator_name: row.creator_name})}
              </p>
            )}
            confirmText={t("accept_deposit_request")}
            cancelText={t("cancel")}
            handleConfirm={() => mutateAccept(row)}
            handleCancel={() => setShowAcceptModal(false)}
            isSubmitLoading={isAcceptLoading}
          />
        </Modal>
      </Fragment>
      <hr />

      <Fragment>
        <button
          type="button"
          className="w-full text-right py-2 px-4 hover:bg-secondary-hover hover:bg-opacity-5"
          onClick={(e) => {
            e.stopPropagation();
            setShowRejectModal(true);
          }}
        >
          رفض
        </button>
        <Modal
          showModal={showRejectModal}
          onClose={() => setShowRejectModal(false)}
        >
          <Alert
            hasFooter={true}
            alertType="cancel"
            title={t("reject_deposit_request")}
            body={() => (
              <p>
                {t("alert_text_reject_deposit_request", {creator_name: row.creator_name})}
              </p>
            )}
            confirmText={t("reject_deposit_request")}
            cancelText={t("cancel")}
            handleConfirm={() => mutateReject(row)}
            handleCancel={() => setShowRejectModal(false)}
            isSubmitLoading={isRejectLoading}
          />
        </Modal>
      </Fragment>
    </>
  );
}
