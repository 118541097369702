const CustomizeTable = ({ strokeColor }) => {
  return (
    <div>
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="table-operation-btn"
      >
        <path
          d="M22 11V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H10"
          stroke={strokeColor ? strokeColor : "#1E293B"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.03003 8.5H22"
          stroke={strokeColor ? strokeColor : "#1E293B"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.03003 15.5H12"
          stroke={strokeColor ? strokeColor : "#1E293B"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.51001 21.9917V2.01172"
          stroke={strokeColor ? strokeColor : "#1E293B"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.51 11.9917V2.01172"
          stroke={strokeColor ? strokeColor : "#1E293B"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.73 14.6711L14.58 18.821C14.42 18.981 14.27 19.291 14.23 19.511L14 21.101C13.92 21.671 14.32 22.081 14.89 21.991L16.48 21.761C16.7 21.731 17.01 21.571 17.17 21.411L21.32 17.261C22.03 16.551 22.37 15.711 21.32 14.661C20.28 13.621 19.45 13.9511 18.73 14.6711Z"
          stroke={strokeColor ? strokeColor : "#1E293B"}
          strokeWidth="1.5"
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.14 15.2617C18.49 16.5217 19.48 17.5017 20.74 17.8617"
          stroke={strokeColor ? strokeColor : "#1E293B"}
          strokeWidth="1.5"
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default CustomizeTable;
