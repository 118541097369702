const Safe = ({ fillColor }) => {
  return(
    <div>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.4993 18.4584H7.49935C5.29424 18.4584 3.83969 17.9853 2.92686 17.0725C2.01403 16.1597 1.54102 14.7051 1.54102 12.5V7.50002C1.54102 5.29492 2.01403 3.84036 2.92686 2.92753C3.83969 2.0147 5.29424 1.54169 7.49935 1.54169H12.4993C14.7045 1.54169 16.159 2.0147 17.0718 2.92753C17.9847 3.84036 18.4577 5.29492 18.4577 7.50002V12.5C18.4577 14.7051 17.9847 16.1597 17.0718 17.0725C16.159 17.9853 14.7045 18.4584 12.4993 18.4584ZM7.49935 1.79169C5.54324 1.79169 4.06825 2.13442 3.101 3.10168C2.13375 4.06893 1.79102 5.54391 1.79102 7.50002V12.5C1.79102 14.4561 2.13375 15.9311 3.101 16.8984C4.06825 17.8656 5.54324 18.2084 7.49935 18.2084H12.4993C14.4555 18.2084 15.9304 17.8656 16.8977 16.8984C17.8649 15.9311 18.2077 14.4561 18.2077 12.5V7.50002C18.2077 5.54391 17.8649 4.06893 16.8977 3.10168C15.9304 2.13442 14.4555 1.79169 12.4993 1.79169H7.49935Z" fill="#94A3B8" stroke="#94A3B8"/>
        <path d="M8.83425 10.8V10.5653L8.6537 10.4153C8.25335 10.0828 7.98788 9.61976 7.92175 9.11056L7.9206 9.10164L7.91912 9.09276C7.90532 9.00996 7.89258 8.89722 7.89258 8.79996C7.89258 8.14697 8.18706 7.54336 8.7141 7.13794C9.23683 6.73584 9.90944 6.60481 10.5651 6.77512C11.2695 6.96352 11.8546 7.54611 12.0333 8.24115L12.0333 8.24116L12.0347 8.2462C12.1095 8.52507 12.1285 8.81175 12.0824 9.09462L12.0823 9.09461L12.0811 9.10334C12.0073 9.61293 11.7456 10.0769 11.3481 10.407L11.1676 10.5569V10.7916V12.125C11.1676 12.7754 10.6398 13.3 10.0009 13.3C9.36039 13.3 8.83425 12.7738 8.83425 12.1333V10.8ZM10.9753 10.3733L10.9753 10.3733L10.977 10.3722C11.4316 10.084 11.7595 9.61704 11.8302 9.06328L11.8343 9.03175V9.02461C11.8708 8.786 11.8575 8.54435 11.792 8.3113C11.6275 7.67616 11.1142 7.17766 10.4948 7.0164C10.3297 6.97254 10.162 6.94996 9.99257 6.94996C9.58709 6.94996 9.19358 7.07533 8.85908 7.3398C8.40551 7.6924 8.14258 8.22886 8.14258 8.79996C8.14258 8.80235 8.14255 8.8054 8.14251 8.80908C8.14214 8.84906 8.14107 8.96206 8.169 9.07736C8.25515 9.62591 8.56163 10.0849 9.02784 10.3741L9.08425 10.4096V12.1333C9.08425 12.6428 9.49144 13.05 10.0009 13.05C10.5104 13.05 10.9176 12.6428 10.9176 12.1333V10.4096L10.9753 10.3733Z" fill="#94A3B8" stroke="#94A3B8"/>
        <path d="M12.459 9.21667V8.96667H17.834V9.21667H12.459Z" fill="#94A3B8" stroke="#94A3B8"/>
        <path d="M8.04101 8.46667H1.66602V9.71667H8.04101V8.46667Z" fill="#94A3B8"/>
      </svg>
    </div>
  );
};

export default Safe;
