const Building = () => {
  return (
    <div>
      <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1667 0.666504H3.83333C1.33333 0.666504 0.5 2.15817 0.5 3.99984V17.3332H15.5V3.99984C15.5 2.15817 14.6667 0.666504 12.1667 0.666504ZM6.33333 13.3748H3.83333C3.49167 13.3748 3.20833 13.0915 3.20833 12.7498C3.20833 12.4082 3.49167 12.1248 3.83333 12.1248H6.33333C6.675 12.1248 6.95833 12.4082 6.95833 12.7498C6.95833 13.0915 6.675 13.3748 6.33333 13.3748ZM6.33333 9.62484H3.83333C3.49167 9.62484 3.20833 9.3415 3.20833 8.99984C3.20833 8.65817 3.49167 8.37484 3.83333 8.37484H6.33333C6.675 8.37484 6.95833 8.65817 6.95833 8.99984C6.95833 9.3415 6.675 9.62484 6.33333 9.62484ZM6.33333 5.87484H3.83333C3.49167 5.87484 3.20833 5.5915 3.20833 5.24984C3.20833 4.90817 3.49167 4.62484 3.83333 4.62484H6.33333C6.675 4.62484 6.95833 4.90817 6.95833 5.24984C6.95833 5.5915 6.675 5.87484 6.33333 5.87484ZM12.1667 13.3748H9.66667C9.325 13.3748 9.04167 13.0915 9.04167 12.7498C9.04167 12.4082 9.325 12.1248 9.66667 12.1248H12.1667C12.5083 12.1248 12.7917 12.4082 12.7917 12.7498C12.7917 13.0915 12.5083 13.3748 12.1667 13.3748ZM12.1667 9.62484H9.66667C9.325 9.62484 9.04167 9.3415 9.04167 8.99984C9.04167 8.65817 9.325 8.37484 9.66667 8.37484H12.1667C12.5083 8.37484 12.7917 8.65817 12.7917 8.99984C12.7917 9.3415 12.5083 9.62484 12.1667 9.62484ZM12.1667 5.87484H9.66667C9.325 5.87484 9.04167 5.5915 9.04167 5.24984C9.04167 4.90817 9.325 4.62484 9.66667 4.62484H12.1667C12.5083 4.62484 12.7917 4.90817 12.7917 5.24984C12.7917 5.5915 12.5083 5.87484 12.1667 5.87484Z" fill="#94A3B8"/>
      </svg>
    </div>
  );
};

export default Building;
