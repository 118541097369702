import { useState } from "react";
import { convertDate } from "./../../../../../utils/convertDate";
import { getAttachments } from "../../../../../utils/getAttachments";
import {useHistory} from 'react-router-dom';
import Modal from "../../../../common/Modal";
import folders from "../../../../../constants/static_folders";
import LockSvg from "../../../../resources/lock svg/LockSvg";
import CloseAndDetails from "../../../../moneyRequest/CloseAndDetails";
import DocumentExpenseSvg from "../../../../resources/document expense svg/DocumentExpenseSvg";
import useFetchUserData from "../../../../../hooks/reactQuery/useFetchUserData";
import { useTranslation } from "react-i18next";

const ExpandedRowWithoutPerm = ({ row }) => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();

  const history = useHistory();
  const [showCloseAndDetails, setShowCloseAndDetails] = useState(false);
  const [showFooter, setShowFooter] = useState(false);

  return (
    <div className={`grid grid-cols-6 gap-4 ${i18n.resolvedLanguage === "en" ? "text-left ml-10" : "text-right mr-10"}`}>
      <div className="col-span-1">
        <p className="text-xs text-body font-bold">{t("transaction_name")}</p>
        <p className="text-sm text-hints font-medium mt-1">{row.name}</p>
      </div>

      <div className="col-span-1">
        <p className="text-xs text-body font-bold">{t("user_balance", {user_name: row.creator_name})}</p>
        <p className="text-sm text-hints font-medium mt-1">
          {row.creator_balance} ج.م
        </p>
      </div>

      {row.approved !== null && (
        <div className="col-span-1">
          <p className="text-xs text-body font-bold">{t("response_date")}</p>
          <p className="text-sm text-hints font-medium mt-1">
            {convertDate(row.response_date)}
          </p>
        </div>
      )}

      {row.deactivated && row.deactivation_date ? (
        <div className="col-span-1">
          <p className="text-xs text-body font-bold">{t("block_date")}</p>
          <p className="text-sm text-hints font-medium mt-1">
            {convertDate(row.deactivation_date)}
          </p>
        </div>
      ) : null}


      {row.approved === 1 && (
        <div className="col-span-1">
          <p className="text-xs text-body font-bold">{t("num_of_documents")}</p>
          <p className="text-sm text-hints font-medium mt-1">
            {t("num_of_documents_dynamic", {number: row.num_of_documents})}
          </p>
        </div>
      )}
      {row.approved === 1 ? (
        row.status === 1 ? (
          <div className="col-span-1">
            <p className="text-xs text-body font-bold">{t("closing_date")}</p>
            <p className="text-sm text-hints font-medium mt-1">
              {convertDate(row.closing_date)}
            </p>
          </div>
        ) : (
          <div className="col-span-1">
            <p className="text-xs text-body font-bold">{t("current_amount")}</p>
            <p className="text-sm text-hints font-medium mt-1">
              {row.remaining_amount} {t("egp")}
            </p>
          </div>
        )
      ) : null}

      {row.approved === 1 && row.status === 1 && (
        <div className="col-span-1">
          <p className="text-xs text-body font-bold">{t("closing_amount")}</p>
          <p className="text-sm text-hints font-medium mt-1">
            {row.remaining_amount} {t("egp")}
          </p>
        </div>
      )}

      <div className="col-span-5 row-start-2">
        <p className="text-xs text-body font-bold">
          {t("notes_dynamic", {user_name: row.creator_name})}
        </p>
        <p className="text-sm text-hints font-medium mt-1">
          {row.description ? row.description : t("no_notes")}
        </p>
      </div>

      {row.attachments && (
        <div className="col-span-5 row-start-3">
          {getAttachments({
            title: t("attachments"),
            notFoundMessage: t("no_attachments"),
            attachProperity: row.attachments,
            attachUrl: folders.withdrawals,
            rowId: row.id,
          })}
        </div>
      )}

      { row.status === 1 && row.approved === 1 && row.creator == userData.id && row.deactivated != 1 && (
        <div className="col-span-5 row-start-5 grid grid-cols-5 gap-4">
          <div className="col-span-1 tool-pin-container relative">
            <button
              onClick={() => {
                setShowCloseAndDetails(true);
                !showFooter && setShowFooter(true);
              }}
              disabled={row.has_open_children === 1}
              className={`${
                row.approved === 0 &&
                "cursor-not-allowed disabled bg-opacity-50"
              }`}
            >
              <LockSvg />
            </button>
            <p className="tool-pin w-max px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-8 -right-9 z-10">
              {row.has_open_children === 1
                ? t("has_open_children")
            : t("close_money_request")}
            </p>
          </div>

          <div
            className="col-span-1 tool-pin-container relative"
            onClick={() => {
              history.push({
                pathname: "/document",
                state: {
                  label: row.name,
                  value: row.id,
                  ...row,
                },
              });
            }}
          >
            <div>
              <DocumentExpenseSvg />
            </div>
            <p className="tool-pin w-max px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-8 -right-9 z-10">
              {t("transaction_type_document")}
            </p>
          </div>
        </div>
      )}

      <Modal
        showModal={showCloseAndDetails}
        onClose={() => setShowCloseAndDetails(false)}
      >
        <CloseAndDetails
          onClose={() => setShowCloseAndDetails(false)}
          type={showFooter ? "closeMoneyRequest" : "moneyRequestDetails"}
          docId={row.id}
          activeRow={row}
          showFooter={showFooter}
          inValidteQueryKey="getMoneyRequestsData"
        />
      </Modal>

    </div>
  );
};

export default ExpandedRowWithoutPerm;
