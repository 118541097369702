const EmptyView = () => {
  return (
    <div>
      <svg
        width={248}
        height={242}
        viewBox='0 0 248 242'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_4665_99013)'>
          <path
            d='M156.992 54.2878H90.3573C88.8388 54.2897 87.383 54.8937 86.3093 55.9674C85.2355 57.0412 84.6315 58.497 84.6296 60.0154V208.113L83.866 208.345L67.5193 213.351C66.7446 213.588 65.9078 213.507 65.1927 213.127C64.4775 212.747 63.9424 212.098 63.7048 211.324L15.081 52.497C14.8444 51.7223 14.9251 50.8853 15.3053 50.17C15.6855 49.4547 16.3341 48.9197 17.1086 48.6824L42.2988 40.9692L115.326 18.6162L140.516 10.903C140.899 10.785 141.302 10.7438 141.701 10.7817C142.101 10.8196 142.489 10.9358 142.843 11.1238C143.197 11.3118 143.511 11.5678 143.766 11.8772C144.022 12.1865 144.213 12.5432 144.331 12.9268L156.759 53.5241L156.992 54.2878Z'
            fill='#F8FAFC'
          />
          <path
            d='M171.533 53.5241L156.554 4.59496C156.304 3.77971 155.897 3.02155 155.355 2.36382C154.812 1.70609 154.146 1.16168 153.393 0.76168C152.64 0.361686 151.816 0.113949 150.967 0.0326361C150.119 -0.0486765 149.262 0.0380284 148.447 0.287787L113.031 11.1283L40.0082 33.4851L4.59241 44.3294C2.94718 44.8345 1.56961 45.9719 0.76207 47.4917C-0.0454707 49.0115 -0.217004 50.7897 0.28513 52.4359L51.4824 219.656C51.8903 220.984 52.7134 222.147 53.8308 222.974C54.9483 223.801 56.3013 224.248 57.6913 224.249C58.3346 224.249 58.9743 224.153 59.5889 223.963L83.8664 216.532L84.6301 216.295V215.497L83.8664 215.73L59.3637 223.233C57.9115 223.676 56.3429 223.525 55.0022 222.812C53.6615 222.1 52.6579 220.885 52.2118 219.434L1.01842 52.2106C0.79737 51.4915 0.720327 50.7359 0.791703 49.9871C0.863079 49.2382 1.08147 48.5108 1.43437 47.8465C1.78728 47.1821 2.26777 46.594 2.84832 46.1156C3.42887 45.6372 4.09808 45.278 4.81763 45.0587L40.2334 34.2144L113.257 11.8614L148.673 1.01711C149.218 0.850552 149.786 0.765626 150.356 0.765095C151.581 0.767844 152.773 1.16265 153.757 1.89169C154.74 2.62072 155.465 3.64568 155.824 4.81643L170.735 53.5241L170.972 54.2878H171.766L171.533 53.5241Z'
            fill='#1E293B'
            fillOpacity='0.8'
          />
          <path
            d='M46.8448 48.8072C46.1089 48.8067 45.3925 48.5703 44.8007 48.1328C44.2089 47.6953 43.7729 47.0796 43.5567 46.3761L38.6384 30.3114C38.5063 29.8799 38.4604 29.4266 38.5035 28.9773C38.5465 28.5281 38.6777 28.0917 38.8894 27.6931C39.1011 27.2946 39.3893 26.9416 39.7375 26.6544C40.0856 26.3673 40.4869 26.1515 40.9185 26.0194L108.099 5.45202C108.971 5.18608 109.912 5.27678 110.717 5.70422C111.522 6.13165 112.124 6.86089 112.391 7.73189L117.31 23.7968C117.575 24.6683 117.485 25.6097 117.057 26.4143C116.63 27.219 115.901 27.8212 115.03 28.089L47.8489 48.6564C47.5235 48.7562 47.1852 48.8071 46.8448 48.8072Z'
            fill='#3B82F6'
          />
          <path
            d='M72.6085 17.1655C76.8262 17.1655 80.2453 13.7464 80.2453 9.52867C80.2453 5.31097 76.8262 1.89185 72.6085 1.89185C68.3908 1.89185 64.9717 5.31097 64.9717 9.52867C64.9717 13.7464 68.3908 17.1655 72.6085 17.1655Z'
            fill='#3B82F6'
          />
          <path
            d='M72.6083 14.3646C75.2791 14.3646 77.4442 12.1995 77.4442 9.52875C77.4442 6.85797 75.2791 4.69287 72.6083 4.69287C69.9376 4.69287 67.7725 6.85797 67.7725 9.52875C67.7725 12.1995 69.9376 14.3646 72.6083 14.3646Z'
            fill='white'
          />
          <path
            d='M230.111 222.298H101.049C100.188 222.297 99.3634 221.955 98.7549 221.346C98.1465 220.738 97.8042 219.913 97.8032 219.052V64.4065C97.8042 63.546 98.1464 62.721 98.7549 62.1126C99.3634 61.5041 100.188 61.1618 101.049 61.1609H230.111C230.972 61.1619 231.797 61.5042 232.405 62.1126C233.014 62.7211 233.356 63.546 233.357 64.4065V219.052C233.356 219.913 233.014 220.738 232.405 221.346C231.797 221.955 230.972 222.297 230.111 222.298Z'
            fill='#F8FAFC'
          />
          <path
            d='M170.735 53.5242H90.3575C88.6367 53.5266 86.987 54.2113 85.7702 55.4281C84.5533 56.6449 83.8686 58.2946 83.8662 60.0155V215.73L84.6299 215.497V60.0155C84.6317 58.497 85.2358 57.0412 86.3095 55.9675C87.3833 54.8937 88.839 54.2897 90.3575 54.2879H170.972L170.735 53.5242ZM240.803 53.5242H90.3575C88.6367 53.5266 86.987 54.2113 85.7702 55.4281C84.5533 56.6449 83.8686 58.2946 83.8662 60.0155V234.899C83.8686 236.62 84.5533 238.269 85.7702 239.486C86.987 240.703 88.6367 241.388 90.3575 241.39H240.803C242.524 241.388 244.173 240.703 245.39 239.486C246.607 238.269 247.292 236.62 247.294 234.899V60.0155C247.292 58.2946 246.607 56.6449 245.39 55.4281C244.173 54.2113 242.524 53.5266 240.803 53.5242ZM246.531 234.899C246.529 236.417 245.925 237.873 244.851 238.947C243.777 240.02 242.321 240.624 240.803 240.626H90.3575C88.839 240.624 87.3833 240.02 86.3095 238.947C85.2358 237.873 84.6317 236.417 84.6299 234.899V60.0155C84.6317 58.497 85.2358 57.0412 86.3095 55.9675C87.3833 54.8937 88.839 54.2897 90.3575 54.2879H240.803C242.321 54.2897 243.777 54.8937 244.851 55.9675C245.925 57.0412 246.529 58.497 246.531 60.0155V234.899Z'
            fill='#1E293B'
            fillOpacity='0.8'
          />
          <path
            d='M200.71 70.3251H130.451C129.54 70.3241 128.666 69.9617 128.022 69.3175C127.378 68.6732 127.015 67.7997 127.014 66.8886V50.0876C127.015 49.1765 127.378 48.3029 128.022 47.6587C128.666 47.0144 129.54 46.652 130.451 46.651H200.71C201.621 46.652 202.494 47.0144 203.138 47.6587C203.783 48.3029 204.145 49.1765 204.146 50.0876V66.8886C204.145 67.7997 203.783 68.6732 203.138 69.3175C202.494 69.9617 201.621 70.3241 200.71 70.3251Z'
            fill='#3B82F6'
          />
          <path
            d='M165.58 47.7965C169.798 47.7965 173.217 44.3774 173.217 40.1597C173.217 35.942 169.798 32.5228 165.58 32.5228C161.362 32.5228 157.943 35.942 157.943 40.1597C157.943 44.3774 161.362 47.7965 165.58 47.7965Z'
            fill='#3B82F6'
          />
          <path
            d='M165.58 44.8113C168.149 44.8113 170.232 42.7287 170.232 40.1597C170.232 37.5907 168.149 35.5082 165.58 35.5082C163.011 35.5082 160.929 37.5907 160.929 40.1597C160.929 42.7287 163.011 44.8113 165.58 44.8113Z'
            fill='white'
          />
        </g>
        <defs>
          <clipPath id='clip0_4665_99013'>
            <rect width='247.294' height='241.39' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export default EmptyView
