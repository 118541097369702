import Select from "react-select";

import { convertDate } from "../../../utils/convertDate";

export const tableColumnsHeader = (setUserModifiedRoles, userRoleOptions) => [
  {
    title: "الاسم",
    dataIndex: "name",
    key: "name",
    width: 150,
  },
  {
    title: "اسم المستخدم",
    dataIndex: "user_name",
    key: "user_name",
    width: 150,
  },
  {
    title: "البريد الإلكتروني",
    dataIndex: "email",
    key: "email",
    width: 200,
  },
  {
    title: "تاريخ الانضمام",
    dataIndex: "created_at",
    key: "created_at",
    width: 250,
    render: (text, row, index) => {
      return <div>{convertDate(text)}</div>;
    },
  },
  {
    title: "اخر تفاعل",
    dataIndex: "latest_interaction",
    key: "latest_interaction",
    width: 250,
    render: (text, row, index) => {
      return <div>{convertDate(text)}</div>;
    },
  },
  {
    title: "رصيد الحساب الحالي",
    dataIndex: "balance",
    key: "balance",
    width: 150,
  },
  {
    title: "الدور",
    dataIndex: "role",
    key: "role",
    width: 150,
    render: (text, row, index) => {
      return (
        <Select
          isSearchable={false}
          defaultValue={userRoleOptions.find((el) => el.value === text)}
          options={userRoleOptions}
          onChange={(selectedRole) => {
            setUserModifiedRoles((prevRoles) => ({
              ...prevRoles,
              [row?.id]: selectedRole.value,
            }));
          }}
        />
      );
    },
    width: 125,
  },
];
