import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchSearchMenu({
  apiUrl,
  searchType,
  searchTerm,
  sentOrReceived,
  activeCompanyId,
}) {
  const params = {
    search_type: searchType,
    search_term: searchTerm,
    sentorreceived: sentOrReceived,
    company_id: activeCompanyId,
  };

  return useQuery(
    ["searchMenu", apiUrl, searchType, searchTerm],
    () =>
      apiClient
        ?.get(apiUrl, { params })
        .then(({ data }) =>
          data.map((option) => ({
            ...option,
            label: option.name ? option.name : option.id,
            value: option.id ? option.id : option.name,
          }))
        )
        .catch((e) => e),
    {
      enabled: searchType !== "" && searchTerm !== "",
    }
  );
}
