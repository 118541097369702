import { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { useDataLayerValue } from "../context/DataLayer";
import actionTypes from "../context/types";

export default function useResetState() {
  const { pathname } = useLocation();
  const [state, dispatch] = useDataLayerValue();

  const resetState = () => {
    dispatch({
      type: actionTypes.RESET_STATE,
    });
  };

  useEffect(() => {
    Object.values(state).some((el) => el) && resetState();
  }, [pathname]);
}
