const ContactUs = () => {
  return (
    <>
      <div>Contact us</div>
      <div>Waiting for Ui to Complete this page</div>
    </>
  );
};

export default ContactUs;
