import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

import useFetchUserData from "./useFetchUserData";

export default function useMutateCashWithDrawal(
  setShowAlertConfirmlModal,
  history
) {
  const { data: userData } = useFetchUserData();
  const queryClient = useQueryClient();

  return useMutation(
    (data) => {
      const formData = new FormData();
      formData.append("requests", JSON.stringify(data));

      return apiClient.post(`money_request/cashout?safe_id=${data[0].safe_id}`, formData);
    },
    {
      onSuccess: () => {
        toast.info("تم انشاء عملية السحب. و سيتم تحديثك بأي مستجدات");
        setShowAlertConfirmlModal(false);
        queryClient.invalidateQueries("userData");
        queryClient.invalidateQueries("userBalance");
        history.push("/safe");
      },
      onError: (error) => {
        setShowAlertConfirmlModal(false);
        return error;
      },
    }
  );
}
