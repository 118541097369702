import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchDocumentations({
  currentPage,
  pageSize,
  order,
  columnKey,
  searchType,
  searchTerms,
  categories,
  periodic,
  fromAmount,
  toAmount,
  fromdate,
  todate,
  frompaymentdate,
  topaymentdate,
}) {
  return useQuery(
    [
      "getDocumentations",
      currentPage,
      pageSize,
      order,
      columnKey,
      searchType,
      searchTerms,
      categories,
      periodic,
      fromAmount,
      toAmount,
      fromdate,
      todate,
      frompaymentdate,
      topaymentdate,
    ],
    () => {
      const params = {};
      params.pageno = currentPage;
      params.pagesize = pageSize;

      if (searchType !== "" && searchTerms.length > 0) {
        params.search_type = searchType;
        params.search_terms = JSON.stringify(searchTerms);
      }
      if (order && columnKey) {
        // to match backend sorting
        switch (columnKey) {
          case "category":
            params.orderbycolumn = "category_id";
            break;

          case "money_request_name":
            params.orderbycolumn = "money_request_id";
            break;

          default:
            params.orderbycolumn = columnKey;
            break;
        }
        //
        params.isascending =
          order === "ascend" ? 1 : order === "descend" ? 0 : null;
      }

      if (categories.length > 0) {
        params.categories = JSON.stringify(categories);
      }

      if (2 > periodic.length > 0) {
        params.periodic = periodic[0];
      }

      if (fromAmount) {
        params.fromAmount = fromAmount;
      }
      if (toAmount) {
        params.toAmount = toAmount;
      }

      if (fromdate) {
        params.fromdate = fromdate;
      }
      if (todate) {
        params.todate = todate;
      }
      if (frompaymentdate) {
        params.frompaymentdate = frompaymentdate;
      }
      if (topaymentdate) {
        params.topaymentdate = topaymentdate;
      }

      return apiClient
        .get("document/all", {
          params,
        })
        .then(({ data }) => data);
    }
    // { keepPreviousData: true }
  );
}
