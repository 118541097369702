import { useEffect, useState, useRef } from "react";

import Select, { components } from "react-select";

import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";

import Input from "../../common/inputs/Input";
import SelectMenu from "../../common/inputs/Select";
import Modal from "../../common/Modal";
import Alert from "../../common/Alert";

import Close from "../../resources/icons/Close";
import Email from "../../resources/icons/Email";
import Trash from "../../resources/icons/Trash";
import UserIcon from "../../resources/icons/UserIcon";
import Selector from "../../resources/icons/Selector";
import K from "../../resources/icons/K";
import UserRoleSelect from "../../resources/icons/UserRoleSelect";

import useFetchUserData from "../../../hooks/reactQuery/useFetchUserData";
import useFetchSafePermissions from "../../../hooks/reactQuery/useFetchSafePermissions";
import useFetchUsers from "../../../hooks/reactQuery/useFetchUsers";

import apiClient from "../../../services/apiClient";

import "./AddSafe.scss";
import Check from "../../resources/icons/Check";
import { fileExtByFileName } from "../../../utils/fileUtils";
import useMutateAddSafe from "../../../hooks/reactQuery/useMutateAddSafe";
import Breadcrumb from "../../common/Breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";

const AddSafe = ({ onClose }) => {
  const {t, i18n} = useTranslation();
  const { data: userData } = useFetchUserData();
  const { data: permissions, isLoading: isPermissionsLoading } = useFetchSafePermissions(t, i18n);

  const usersContainerRef = useRef(null);

  const scrollToIndex = (e, index) => {
    if(usersContainerRef && usersContainerRef.current) 
      usersContainerRef.current.scrollTop = usersContainerRef.current.children[index].offsetTop - usersContainerRef.current.offsetTop;
  }

  const {
    data: users,
    isLoading: isUsersLoading,
    isError: isUsersError,
  } = useFetchUsers(userData.id);

  const safeMembers = users?.map(obj => {
    return {
      ...obj, 
      label: obj.email
    }
  });

  const [usersOptions, setUsersOptions] = useState([]);

  useEffect(() => {
    setUsersOptions(safeMembers);
  }, [users]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,
    getValues,
    trigger,
    reset,
  } = useForm({
    mode: "all",
    defaultValues: {
      addMembersForm: [
        {
          email: "",
          permissions: "",
        },
        {
          email: "",
          permissions: "",
        },
        {
          email: "",
          permissions: "",
        },
        {
          email: "",
          permissions: "",
        },
      ],
    },
  });

  useEffect(() => {
    reset({
      addMembersForm: [
        {
          email: "",
          permissions: [permissions?.[0]]
        },
        {
          email: "",
          permissions: [permissions?.[0]]
        },
        {
          email: "",
          permissions:  [permissions?.[0]]  
        },
        {
          email: "",
          permissions:  [permissions?.[0]]  
        },
      ],
    });
  }, [reset, permissions]);

  const watchAll = useWatch({ control });

  const { fields, append, remove, update, prepend } = useFieldArray({
    control,
    name: "addMembersForm",
  });

  const addFieldSet = () => {
    append({
      email: "",
      permissions: permissions?.[0],
    });
    scrollToIndex(null, fields.length - 1);
  };

  const removeFieldSet = (fieldIndex) => {
    remove(fieldIndex);
  };

  const onManagerChange = (option) => {
    const currentFields = (getValues("addMembersForm"));

    currentFields.forEach((value, index) => {
      if(value.email.id == option.id) update(index, {...value, manager: true});
      else update(index, {...value, manager: false});
    });

    setUsersOptions(safeMembers.filter(obj => obj.id != option.id));
  }

  const { isLoading: isMutateAddMemebersLoading, mutate: mutateAddSafe } =
    useMutateAddSafe(onClose, userData);
  const handleAddSafe = (data) => {
    const fullControlPermissions = permissions?.filter(obj => obj.tag !== "CashInWithPermission" && obj.tag !== "CashOutWithPermission").map(obj => obj.id);

    let formUsers = data.addMembersForm?.map(obj => {
      return {
        id: obj.email.id,
        permissions: obj.permissions.map(obj => obj.id)
      }
    });

    formUsers = formUsers.filter(obj => {
      if(!obj.id) return false; 
      else return true;
    })

    const currentUser = {
      id: userData.id,
      permissions: fullControlPermissions
    }

    const managerId = data.manager_id ? data.manager_id.id : userData.id;

    formUsers = formUsers.filter(user => user.id != managerId && user.id != userData.id);  

    const managerExistsInUsers = formUsers.find(obj => obj.id === managerId);

    if(!managerExistsInUsers && managerId !== userData.id) {
      formUsers.push({
        id: managerId,
        permissions: fullControlPermissions
      })
    }

    const formData = {
      users: [currentUser, ...formUsers],
      code: data.code,
      name: data.name,
      en_name: data.en_name,
      manager_id: parseInt(managerId)
    }
    
    mutateAddSafe(formData);
  };

  const styles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "unset",
      outline: "none",
      height: "36px",
      width: "150px",
      border: "1px solid #CBD5E1",
      marginRight: "-5px",
      borderRadius: "6px",
      boxShadow: state.menuIsOpen ? "0 0 0 2px #2563eb" : "",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "inherit",
      backgroundColor: (state.isFocused || state.isSelected) && "#F8FAFC",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
    menu: (provided) => ({
      ...provided,
      padding: 0,
      position: "absolute",
      width: "250px",
      left: 0,
      zIndex: 1000
    }),
    menuList: (provided) => ({
      ...provided,
      padding: "0px 10px",      
      maxHeight: "250px",
      overflowY: "auto",
      "::-webkit-scrollbar": {
        width: "10px",
        height: "10px",
      },
      "::-webkit-scrollbar-track": {
        backgroundColor: "#f8fafc",
        borderRadius: "8px 0 0 8px",
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "#cbd5e1",
        border: "1px solid #e7f5ff",
        borderRadius: "10px",
      },
    }),
    loadingIndicator: (provided) => ({
      ...provided,
      position: "absolute",
      left: "47px",
    }),
  };

  const DropdownIndicator = () => (
    <div className="px-3 py-2 max-h-full">
      <UserRoleSelect />
    </div>
  );

  const SelectMenuControlIcon = ({ children, ...rest }) => (
    <components.Control {...rest}>
      <div className="px-2">
        <Selector />
      </div>

      {children}
    </components.Control>
  );

  const formatManagerOptionLabel = (data) => {
    return (
      <div className="flex items-center justify-between">
        <div className="flex items-center selection-container">
          <div className="flex-shrink-0 selection-hide">
            {data.profile_picture_ext ? (
              <img className="h-10 w-10 rounded-full" src={data.url} alt="user" />
            ) : (
              <div className="h-10 w-10 rounded-full flex justify-center items-center bg-blue-400">
                {data.name[0]}
              </div>
            )}
          </div>
          <div className="mr-3">
            <p className="text-sm font-medium text-gray-900 hover:underline">
              {data.name}
            </p>
            <div className="flex space-x-1 text-sm text-gray-500 selection-hide">
              <p>{data.user_name}</p>
            </div>
          </div>
        </div>
        <span className="mx-4 text-sm text-body" style={{color: "#999"}}>{data.role_name}</span>
      </div>
    );
  };

  const formatUsersOptionLabel = (data) => {
    return (
      <div className="flex items-center justify-between">
        <div className="flex items-center selection-container">
          <div className="flex-shrink-0 selection-hide">
            {data.profile_picture_ext ? (
              <img className="h-10 w-10 rounded-full" src={data.url} alt="user" />
            ) : (
              <div className="h-10 w-10 rounded-full flex justify-center items-center bg-blue-400">
                {data.name[0]}
              </div>
            )}
          </div>
          <div className="mr-3">
            <p className="text-sm font-medium text-gray-900 hover:underline">
              {data.email}
            </p>
            <div className="flex space-x-1 text-sm text-gray-500 selection-hide">
              <p>{data.name}</p>
            </div>
          </div>
        </div>
        <span className="mx-4 text-sm text-body" style={{color: "#999"}}>{data.role_name}</span>
      </div>
    );
  };

  const formatOptionLabel = (data, context) => {
    const selectedValues = context.selectValue.map(obj => obj.value);

    return (
      <div className="flex justify-between items-center">
        <div className="flex justify-center items-center gap-x-3 selection-container">
          <p
            className={`text-sm text-body selection-weight ${
              context.selectValue &&
              context.selectValue[0]?.value === data?.value
                ? "font-bold"
                : "font-medium"
            }`}
          >
            {data.label}
          </p>
        </div>
        {context.selectValue && selectedValues.includes(data?.value) && (
          <div>
            <Check />
          </div>
        )}
      </div>
    );
  };

  const [showAlertConfirmModal, setShowAlertConfirmModal] = useState(false);
  const [showAlertCancelModal, setShowAlertCancelModal] = useState(false);

  return (
    <div
      className="relative"
      onClick={() => (!isDirty ? onClose() : setShowAlertCancelModal(true))}
    >
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <Breadcrumb items={[{title: t("safes"), action: () => !isDirty ? onClose() : setShowAlertCancelModal(true)}, {title: t("add_new_safe"), action: () => {}}]} />
        <div className="bg-white add-safe-container">
          <form noValidate={true}>
            <div className="AddSafeInputRow">
              <div style={{width: "49%"}}>
                <Input
                  register={register}
                  Icon={K}
                  type="text"
                  name="code"
                  fieldName={t("safe_code")}
                  label={t("safe_code")}
                  placeholder={t("safe_code")}
                  hint={t("safe_code_hint")}
                  required={true}
                  max="5000000"
                  validate={{
                    validateRepetition: async (value) => {
                      if (value) {
                        const { data } = await apiClient.get(
                          "safe/codenameexiest",
                          { params: { id: 0, code: value } }
                        );

                        return data.exists
                          ? t("safe_code_exists")
                          : null;
                      }
                    },
                    validate: (value) => {
                      if (value) {
                        if (!value.match(/^[a-zA-Z0-9_.-]*$/)) {
                          return t("safe_code_characters")
                        }
                        else {
                          return null
                        }
                      }
                    },
                    validateLength: (value) => {
                      if (value.length > 20 || value.length < 3) {
                          return t("safe_code_characters_validation")
                      }
                      else {
                        return null
                      }                      
                    },
                  }}
                  errors={errors?.code}
                />
              </div>
              <div style={{width: "49%"}}>
                <Input
                  register={register}
                  type="text"
                  name="name"
                  fieldName={t("safe_name")}
                  label={t("safe_name")}
                  placeholder={t("safe_name")}
                  hint={t("safe_name_hint")}
                  required={true}
                  validate={{
                    validateRepetition: async (value) => {
                      if (value) {
                        const { data } = await apiClient.get(
                          "safe/codenameexiest",
                          { params: { id: 0, name: value } }
                        );

                        return data.exists
                          ? t("safe_name_exists")
                          : null;
                      }
                    },
                    validate: (value) => {
                      if (value) {
                        if (!value.match(/([a-zA-Z \u0621-\u064A])+([ -~])*/)) {
                          return t("safe_name_characters")
                        }
                        else {
                          return null
                        }
                      }
                    },
                    validateLength: (value) => {
                      if (value.length > 50 || value.length < 3) {
                          return t("safe_name_characters_validation");
                      }
                      else {
                        return null
                      }                      
                    },
                  }}
                  errors={errors?.name}
                />
              </div>
            </div>
            <div className="AddSafeInputRow">
              <div style={{width: "100%"}}>
                <Input
                  register={register}
                  type="text"
                  name="en_name"
                  fieldName={t("en_safe_name")}
                  label={t("en_safe_name")}
                  placeholder={t("en_safe_name")}
                  hint={t("en_safe_name_hint")}
                  required={true}
                  validate={{
                    validateRepetition: async (value) => {
                      if (value) {
                        const { data } = await apiClient.get(
                          "safe/codenameexiest",
                          { params: { id: 0, en_name: value } }
                        );

                        return data.exists
                          ? t("safe_name_exists")
                          : null;
                      }
                    },
                    validate: (value) => {
                      if (value) {
                        if (!value.match(/([a-zA-Z \u0621-\u064A])+([ -~])*/)) {
                          return t("safe_name_characters")
                        }
                        else {
                          return null
                        }
                      }
                    },
                    validateLength: (value) => {
                      if (value.length > 50 || value.length < 3) {
                          return t("safe_name_characters_validation");
                      }
                      else {
                        return null
                      }                      
                    },
                  }}
                  errors={errors?.en_name}
                />
              </div>
            </div>
            <div className="AddSafeInputRow">
              <div className="col-span-8 sm:col-span-4" style={{width: "100%"}}>
                <SelectMenu
                  register={register}
                  Icon={UserRoleSelect}
                  defaultValue={{id: userData.id, name: userData.name}}
                  name="manager_id"
                  fieldName={t("the_manager")}
                  label={t("the_manager")}
                  placeholder={t("the_manager")}
                  hint={t("pick_safe_manager")}
                  onChangeHandler={onManagerChange}
                  options={users}
                  isLoading={isUsersLoading}
                  isError={isUsersError}
                  Controller={Controller}
                  control={control}
                  errors={errors?.manager}
                  formatOptionLabel={formatManagerOptionLabel}
                />
              </div>
            </div>
            <div className="usersContainerHeader">
              <span className="text-sm text-body font-medium">{t("safe_users")}</span>
              <span className="AddSafeFormSecondaryText">{t("safe_users_hint")}</span>
            </div>
            <div id="usersContainer" className="flex flex-col gap-y-4 px-4 pt-5 pb-6 scroll-bar usersContainerEdit" ref={usersContainerRef}>
              {fields.map((field, index) => {
                return (
                  <div key={field.id} className="grid grid-cols-12 gap-x-3" style={field.manager ? {display: "none"} : {}} onClick={(e) => scrollToIndex(e, index)}>
                    <div className="col-span-11">
                      <div className="flex items-end">
                        <div className="col-span-6 sm:col-span-3" style={{width: "80%"}}>
                          <SelectMenu
                            register={register}
                            Icon={Email}
                            name={`addMembersForm[${index}].email`}
                            fieldName={t("email")}
                            label={t("email")}
                            placeholder="someone@example.com"
                            options={usersOptions}
                            isLoading={isUsersLoading}
                            isError={isUsersError}
                            onOpenHandler={() => scrollToIndex(null, index)}
                            validate={{
                              validateRepetition: async (value) => {
                                if (value) {
                                  const isUserSharmoot =
                                    watchAll.addMembersForm.some(
                                      (field, i) =>
                                        field.email.email === value.email && i !== index
                                    );
                                    console.log(value);
                                  return isUserSharmoot
                                    ? t("email_exists")
                                    : null;
                                }
                              },
                            }}
                            Controller={Controller}
                            control={control}
                            errors={errors?.addMembersForm &&
                            errors?.addMembersForm[index]?.email
                            }
                            formatOptionLabel={formatUsersOptionLabel}
                          />
                        </div>
                        <div className="">
                          <Controller
                            name={`addMembersForm[${index}].permissions`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value },
                            }) => {
                              return (
                                <Select
                                  placeholder={t("permissions")}
                                  options={permissions}
                                  isLoading={isPermissionsLoading}
                                  styles={styles}
                                  components={{
                                    Control: SelectMenuControlIcon,
                                    DropdownIndicator,
                                  }}
                                  onChange={(selectedOption) => {
                                    if(!selectedOption || selectedOption.length < 1) return;
                                    const allSelectedTags = selectedOption.map(obj => obj.tag);
                                    let newSelectedOptions = selectedOption;

                                    if(selectedOption[selectedOption.length - 1].tag === "CashOutWithoutPermission" && allSelectedTags.includes("CashOutWithPermission")) 
                                      newSelectedOptions = selectedOption.filter(obj => obj.tag !== "CashOutWithPermission");
                                    else if(selectedOption[selectedOption.length - 1].tag === "CashOutWithPermission" && allSelectedTags.includes("CashOutWithoutPermission")) 
                                      newSelectedOptions = selectedOption.filter(obj => obj.tag !== "CashOutWithoutPermission" && obj.tag !== "CashFullControl");
                                    else if(selectedOption[selectedOption.length - 1].tag === "CashOutWithPermission" && allSelectedTags.includes("CashFullControl")) 
                                      newSelectedOptions = selectedOption.filter(obj => obj.tag !== "CashFullControl");
                                    else if(selectedOption[selectedOption.length - 1].tag === "CashInWithPermission" && allSelectedTags.includes("CashInWithoutPermission")) 
                                      newSelectedOptions = selectedOption.filter(obj => obj.tag !== "CashInWithoutPermission" && obj.tag !== "CashFullControl");
                                    else if(selectedOption[selectedOption.length - 1].tag === "CashInWithPermission" && allSelectedTags.includes("CashFullControl")) 
                                      newSelectedOptions = selectedOption.filter(obj => obj.tag !== "CashFullControl");
                                    else if(selectedOption[selectedOption.length - 1].tag === "CashInWithoutPermission" && allSelectedTags.includes("CashInWithPermission")) 
                                      newSelectedOptions = selectedOption.filter(obj => obj.tag !== "CashInWithPermission")
                                    else if(selectedOption[selectedOption.length -1].tag === "CashFullControl") 
                                      newSelectedOptions = permissions?.filter(obj => obj.tag !== "CashInWithPermission" && obj.tag !== "CashOutWithPermission" && obj.tag !== "CashReadOnly");
                                    else if(selectedOption[selectedOption.length -1].tag === "CashReadOnly") 
                                      newSelectedOptions = permissions?.filter(obj => obj.tag === "CashReadOnly");

                                    if(selectedOption[selectedOption.length -1].tag !== "CashReadOnly")
                                      newSelectedOptions = newSelectedOptions.filter(obj => obj.tag !== "CashReadOnly") 

                                    onChange(newSelectedOptions);
                                  }}
                                  //
                                  value={value}
                                  isMulti={true}
                                  controlShouldRenderValue={false}
                                  hideSelectedOptions={false}
                                  isSearchable={false}
                                  //
                                  onBlur={(e) => onBlur(e)}
                                  formatOptionLabel={formatOptionLabel}
                                />
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <button
                      type="button"
                      className="col-span-1 flex justify-center items-center"
                      style={{paddingTop: "20px"}}
                      onClick={() => removeFieldSet(index)}
                      disabled={fields.length === 1}
                    >
                      <div
                        className={`${
                          fields.length === 1 ? "cursor-not-allowed" : ""
                        }`}
                      >
                        <Trash fillColor={fields.length === 1 ? "gray" : ""} />
                      </div>
                    </button>
                  </div>
                );
              })}
            </div>
            <div className="col-span-3 col-start-8 flex justify-start items-center" style={{paddingBottom: "80px"}}>
                <button
                  type="button"
                  className="text-xs mx-4 my-4 left-5 font-medium rounded-md text-secondary bg-secondary border border-transparent hover:border-secondary bg-opacity-20 p-1.5"
                  onClick={addFieldSet}
                >
                  {t("add_new_cell")}
                </button>
            </div>
            <hr />
            <div className="p-4 grid grid-cols-10 gap-x-3 fixedFooter">
              <button
                type="button"
                className={`relative col-span-2 py-2 px-2 rounded-md text-base leading-5 font-medium ${
                  isMutateAddMemebersLoading || !isValid
                    ? "cursor-not-allowed bg-outline text-side-icons"
                    : "bg-secondary text-white"
                }`}
                disabled={isMutateAddMemebersLoading || !isValid}
                onClick={() => setShowAlertConfirmModal(true)}
              >
                {t("create")}
              </button>

              <button
                type="button"
                className={`col-span-2 py-2 px-2 rounded-md text-base leading-5 font-medium border border-outline hover:border-secondary ${
                  isMutateAddMemebersLoading
                    ? "cursor-not-allowed bg-outline text-side-icons"
                    : "bg-white"
                }`}
                onClick={() =>
                  !isDirty ? onClose() : setShowAlertCancelModal(true)
                }
                disabled={isMutateAddMemebersLoading}
              >
                {t("cancel")}
              </button>

 
            </div>
          </form>
        </div>
      </div>
      <>
        <Modal
          showModal={showAlertConfirmModal}
          onClose={() => setShowAlertConfirmModal(false)}
        >
          <Alert
            hasFooter={true}
            alertType="confirm"
            title={t("confirm_add_safe")}
            body={() => (
              <div>
                {t("alert_text_add_safe")}
              </div>
            )}
            confirmText={t("create")}
            cancelText={t("cancel")}
            isSubmitLoading={isMutateAddMemebersLoading}
            handleConfirm={handleSubmit(handleAddSafe)}
            handleCancel={() =>
              !isMutateAddMemebersLoading && setShowAlertConfirmModal(false)
            }
          />
        </Modal>
      </>

      <>
        <Modal
          showModal={showAlertCancelModal}
          onClose={() => setShowAlertCancelModal(false)}
        >
          <Alert
            hasFooter={true}
            alertType="cancel"
            title={t("cancel_add_safe")}
            body={() => (
              <div>
              {t("alert_text_cancel_add_safe")}
              </div>
            )}
            confirmText={t("confirm")}
            cancelText={t("cancel")}
            handleConfirm={onClose}
            handleCancel={() => setShowAlertCancelModal(false)}
          />
        </Modal>
      </>
    </div>
  );
};

export default AddSafe;
