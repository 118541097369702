import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchChartSafeData(
  safes,
  range,
  t
) {
  return useQuery(
    [
      "chartSafeData",
      safes,
      range
    ],
    () => {
      const params = {safes, range};

      return apiClient
        .get("/safe/charts/balance", {
          params,
        })
        .then(({data: res}) => {
          const safesArray = JSON.parse(safes);
          let data;
          if(safesArray.length > 0) {
            data = res.map(response => {
              const newObj = {
                date: response.date,
                colors: {},
                safes: safesArray.sort(function(a, b) {return a - b;})
              }
              const allBalances = response.data.map(o => {return {balance: o.balance, id: o.id}}).sort(function(a, b) {return a.balance - b.balance;}).map(s => s.id);
              const totalBalances = response.data.map(obj => obj.balance);
              for(let safe of response.data) {
                let color = "#59A0FF";
                const order = allBalances.indexOf(safe.id);
                if (allBalances.length === 1) color = "#59A0FF"; 
                else if (order === 0 && allBalances.length > 1) color = "#94B6E3";
                else if (order === allBalances.length - 1) color = "#3D4C5E";
                else if (order === 1) color = "#59A0FF";
                else if (order === 2) color = "#4E8CDE";
                else if (order === 3) color = "#3C6CAB";
                newObj[`${safe.name}`] = safe.balance;
                newObj.colors[`${safe.name}`] = color;
                newObj.colors[t("chart_type_safes_balance")] = "#59A0FF";
                newObj.colors[t("chart_type_withdrawals")] = "#FFCD86";
                newObj.colors[t("chart_type_documents")] = "#FF805C";
                newObj.colors[t("chart_type_deposits")] = "#42EBB3";
              }
              newObj[t("chart_type_safes_balance")] = totalBalances.reduce((a, b) => a + b, 0);
              return newObj;
            });
          }
          else if(safesArray.length === 0) {
            data = res.map(response => {
              const newObj = {
                date: response.date,
                colors: {},
                safes: []
              }
              newObj[t("all_safes")] = response.data[0].balance;
              newObj[t("chart_type_safes_balance")] = response.data[0].balance;

              newObj.colors[t("all_safes")] = "#59A0FF";
              newObj.colors[t("chart_type_safes_balance")] = "#59A0FF";
              
              newObj.colors[t("chart_type_deposits")] = "#42EBB3";
              newObj.colors[t("chart_type_withdrawals")] = "#FFCD86";
              newObj.colors[t("chart_type_documents")] = "#FF805C";

              return newObj;
            });
          }
          return data;
        });
    }
  );
}
