import Select, { components } from "react-select";

export default function ValueContainer({ children, ...props }) {
    let [values, input] = children;
  
    if (Array.isArray(values) && values.length > 2) {
      values = `${values.length} خزينة مختارة`;
    }
  
    return (
      <components.ValueContainer {...props}>
        {values}
        {input}
      </components.ValueContainer>
    );
};