import AcceptedSvg from "../../components/resources/accepted svg/AcceptedSvg";
import RejectedSvg from "../../components/resources/rejected svg/RejectedSvg";
import SuspendedSvg from "../../components/resources/suspended svg/SuspendedSvg";
import CurrentSvg from "../../components/resources/current svg/CurrentSvg";
import FinishedSvg from "../../components/resources/finished svg/FinishedSvg";
import { convertDate, formatDate } from "../../utils/convertDate";
import Health from "../../components/resources/icons/Health";
import TableSortIcon from "../../components/resources/icons/TableSortIcon";
import Key from "../../components/resources/icons/Key";
import { toolPin } from "../../utils/toolPin";
import DataBase from "../../components/resources/icons/Database";
import Cash from "../../components/resources/icons/Cash";
import SafeBox from "../../components/resources/icons/SafeBox";
import DateIcon from "../../components/resources/icons/DateIcon";
import UsersAvatars from "../../components/common/UsersAvatars.jsx";
import UserTag from "../../components/resources/icons/UserTag";
import ActiveDeactive from "../../components/resources/icons/ActiveDeactive";
import TableMenu from "../../components/common/TableMenu";

export const getColumnsHeader = (
  activeTab, 
  showResponseDate = true,
  setActiveDeactiveId,
  setShowDeactivateModal,
  userData,
  hoverId,
  t,
  i18n
  ) => [
  {
    title: activeTab === 2 ? () => (
      <div class="flex items-center justify-center">
        <div>
          <Key />
        </div>
        <div className="col-title">{t("transaction_code")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ) : t("transaction_code"),
    dataIndex: "id",
    key: "id",
    width: 100,
    render: (text, row) => <div className="flex justify-center" style={row.deactivated ? {opacity: 0.5} : {}}>{text}</div>,
    selected: true,
    customizeKey: t("transaction_code"),
  },
  {
    title: <div className={`${i18n.resolvedLanguage === "en" ? "flex justify-start" : "flex justify-end"}`}>{t("transaction_name")}</div>,
    dataIndex: "name",
    key: "name",
    width: 200,
    render: (text, row) => <div className={`${i18n.resolvedLanguage === "en" ? "flex justify-start" : "flex justify-end"}`} style={row.deactivated ? {opacity: 0.5} : {}}>{text}</div>,
    selected: activeTab !== 2 && showResponseDate,
    customizeKey: t("transaction_name"),
  },
  {
    title: activeTab === 2 ? (
      <div class="flex items-center justify-center">
        <div>
          <Cash />
        </div>
        <div className="col-title">{t("amount")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ) :<div class="flex items-center justify-center">{t("amount")}</div>,
    dataIndex: "amount",
    key: "amount",
    width: 100,
    sorter: (a, b) => {},
    render: (text, row) => <div className="flex justify-center items-center" style={row.deactivated ? {opacity: 0.5} : {}}>{toolPin(String(`${text} ج.م`))}</div>,
    selected: true,
    customizeKey: t("amount"),
  },
  {
    title: <div className="flex justify-center items-center">{t("category_name")}</div>,
    dataIndex: "category",
    key: "category",
    width: 150,
    sorter: (a, b) => {},
    render: (text, row) => <div className="flex justify-center items-center" style={row.deactivated ? {opacity: 0.5} : {}}>{text}</div>,
    selected: activeTab !== 2,
    customizeKey: t("category_name"),
  },
  {
    title: () => (
      <div class="flex items-center justify-center">
        <div>
          <DataBase />
        </div>
        <div className="col-title flex justify-center">{t("category_name")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "category_name",
    key: "category",
    width: 150,
    sorter: (a, b) => {},
    render: (text, row) => <div className="flex justify-center items-center" style={row.deactivated ? {opacity: 0.5} : {}}>{text}</div>,
    selected: activeTab === 2,
    customizeKey: t("category_name"),
  },
  {
    title: (
      <div class="flex items-center justify-center">
        <div>
          <SafeBox />
        </div>
        <div className="col-title">{t("money_request_source")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "safe_name",
    key: "safe_name",
    width: 150,
    sorter: (a, b) => {},
    render: (text, row) => <div className="flex justify-center items-center" style={row.deactivated ? {opacity: 0.5} : {}}>{text}</div>,
    selected: activeTab === 2,
    customizeKey: t("money_request_source"),
  },
  {
    title: <div className="flex justify-center">{activeTab === 0 ? t("sender") : t("recipient")}</div>,
    dataIndex: "sender_name",
    key: "sender_name",
    width: 150,
    sorter: (a, b) => {},
    render: (text, row) => <div className="flex justify-center" style={row.deactivated ? {opacity: 0.5} : {}}>{text}</div>,
    selected: activeTab !== 2,
    customizeKey: t("user_name"),
  },
  {
    title: <div className="flex justify-center">{t("sent_or_response_date")}</div>,
    dataIndex: "response_date",
    key: "response_date",
    width: 250,
    sorter: (a, b) => {},
    render: (text, row, index) => {
      return (
        <div className="flex justify-center" style={row.deactivated ? {opacity: 0.5} : {}}>
          {convertDate(
            row.response_date ? row.response_date : row.response_date
          )}
        </div>
      );
    },
    selected: activeTab !== 2 && showResponseDate,
    customizeKey: t("date"),
  },
  {
    subject: "",
    title: () => (
      <div class="flex items-center justify-center">
        <div>
          <UserTag />
        </div>
        <div className="mx-2 text-xs font-bold text-body">{t("transaction_users")}</div>
      </div>
    ),
    dataIndex: "users",
    key: "users",
    sorter: true,
    render: (text, row) => (
      <div className="col-desc flex justify-center" style={row.deactivated ? {opacity: 0.5} : {}}>
        <UsersAvatars usersArr={JSON.parse(text)} />
      </div>
    ),
    selected: !showResponseDate,
    width: 150,
  },
  {
    title: (
      <div class="flex items-center justify-center">
        <div>
          <DateIcon />
        </div>
        <div className="col-title flex justify-center">{t("creation_date")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "creation_date",
    key: "creation_date",
    width: 250,
    sorter: (a, b) => {},
    render: (text, row, index) => {
      return (
        <div className="flex justify-center items-center" style={row.deactivated ? {opacity: 0.5} : {}}>
          {convertDate(row.creation_date)}
        </div>
      );
    },
    selected: activeTab === 2 || !showResponseDate,
    customizeKey: t("creation_date"),
  },
  {
    title: <div className="flex justify-center items-center">
      {t("response_status")}
    </div>,
    dataIndex: "approved",
    key: "approved",
    width: 150,
    sorter: (a, b) => {},
    render: (text, row, index) => {
      return text === 1 ? (
        <div className="tool-pin-container relative flex justify-center" style={row.deactivated ? {opacity: 0.5} : {}}>
          <AcceptedSvg />
          <p className="tool-pin px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-6 z-10">
            {t("accepted")}
          </p>
        </div>
      ) : text === 0 ? (
        <div className="tool-pin-container relative flex justify-center" style={row.deactivated ? {opacity: 0.5} : {}}>
          <RejectedSvg />
          <p className="tool-pin px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-6 z-10">
            {t("rejected")}
          </p>
        </div>
      ) : (
        <div className="tool-pin-container relative flex justify-center" style={row.deactivated ? {opacity: 0.5} : {}}>
          <SuspendedSvg />
          <p className="tool-pin px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-6 z-10">
            {t("money_request_no_reply")}
          </p>
        </div>
      );
    },
    selected: activeTab !== 2,
    customizeKey: t("response_status"),
  },
  {
    title: () => (
      <div class="flex items-center justify-center">
        <div>
          <Health />
        </div>
        <div className="col-title flex justify-center">{t("status")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "approved",
    key: "approved opration",
    sorter: true,
    render: (text, row) => (
      <div className="col-desc flex justify-center" style={row.deactivated ? {opacity: 0.5} : {}}>
        {text === 1 && row.status === 0 ? (
          <span className="text-xs font-medium text-confirmation py-1.5 px-2.5 bg-confirmation bg-opacity-20 rounded-xl">
            {t("transaction_closed")} 
          </span>
        ) : text === 1 ? (
          <span className="text-xs font-medium text-secondary py-1.5 px-2.5 bg-secondary bg-opacity-20 rounded-xl">
            {t("transaction_running")}
          </span>
        ) : text === 0 ? (
          <span className="text-xs font-medium text-danger py-1.5 px-2.5 bg-danger bg-opacity-20 rounded-xl">
            {t("rejected")}
          </span>
        ) : (
          <div className="tool-pin-container relative">
            <span className="text-xs font-medium text-onhold py-1.5 px-2.5 bg-onhold bg-opacity-20 rounded-xl">
              {t("transaction_pending")}
            </span>
          </div>
        )}
      </div>
    ),
    width: 150,
    selected: activeTab === 2 || !showResponseDate,
    customizeKey: t("status"),
  },
  {
    title: t("money_request_status"),
    dataIndex: "status",
    key: "status",
    width: 150,
    sorter: (a, b) => {},
    render: (text, row, index) => {
      return (
        <div className="flex justify-center" style={row.deactivated ? {opacity: 0.5} : {}}>
          {row.approved === null || row.approved === 0 ? (
            <div className="tool-pin-container relative">
              <div>---</div>
              <p className="tool-pin w-56 px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-6 left-0 z-10">
                {t("money_request_no_status")}
              </p>
            </div>
          ) : text === 1 ? (
            <div className="tool-pin-container relative">
              <div>
                <CurrentSvg />
              </div>
              <p className="tool-pin w-max px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-6 -right-2 z-10">
              {t("transaction_running")}
              </p>
            </div>
          ) : (
            <div className="tool-pin-container relative">
              <div>
                <FinishedSvg />
              </div>
              <p className="tool-pin w-max px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-6 -right-2 z-10">
              {t("transaction_closed")} 
              </p>
            </div>
          )}
        </div>
      );
    },
    selected: activeTab !== 2,
    customizeKey: t("money_request_status"),
  },
  {
    title: "",
    dataIndex: "",
    key: "",
    render: (text, row) => (
      <TableMenu 
        showButton={true}
        options={[
          {
            icon: <ActiveDeactive active={1} fill="#1E293B" />,
            text: t("suspend"),
            action: () => {
              setActiveDeactiveId(row.id);
              setShowDeactivateModal(true);
            },
            exists: row.deactivated != 1 && row.creator == userData.id
          }
        ].filter(item => item.exists)} />
    ),
    width: 100,
    selected: true,
  },
];
