const DeactivatRole = () => {
  return (
    <div>
      <svg
        width={48}
        height={48}
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width={48} height={48} rx={24} fill="#FFD4D4" />
        <path
          d="M23.97 34.75C18.05 34.75 13.22 29.93 13.22 24C13.22 18.07 18.05 13.25 23.97 13.25C29.89 13.25 34.72 18.07 34.72 24C34.72 29.93 29.9 34.75 23.97 34.75ZM23.97 14.75C18.87 14.75 14.72 18.9 14.72 24C14.72 29.1 18.87 33.25 23.97 33.25C29.07 33.25 33.22 29.1 33.22 24C33.22 18.9 29.07 14.75 23.97 14.75Z"
          fill="#DC2626"
        />
        <path
          d="M22.01 27.95H20.71C19.79 27.95 19.25 27.42 19.25 26.53V21.47C19.25 20.58 19.8 20.05 20.71 20.05H22C22.92 20.05 23.46 20.58 23.46 21.47V26.53C23.47 27.42 22.92 27.95 22.01 27.95ZM20.75 26.45H21.97V21.55H20.76L20.75 26.45Z"
          fill="#DC2626"
        />
        <path
          d="M27.29 27.95H26C25.08 27.95 24.54 27.42 24.54 26.53V21.47C24.54 20.58 25.09 20.05 26 20.05H27.29C28.21 20.05 28.75 20.58 28.75 21.47V26.53C28.75 27.42 28.2 27.95 27.29 27.95ZM26.03 26.45H27.25V21.55H26.04L26.03 26.45Z"
          fill="#DC2626"
        />
      </svg>
    </div>
  );
};

export default DeactivatRole;
