import PropTypes from "prop-types";

function Icon(props) {
  const { className, fillColor, ...restOfProps } = props;
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...restOfProps}
    >
      <path
        d="M9 17.0615C8.5275 17.0615 8.085 16.8816 7.755 16.5441L6.61501 15.4041C6.52501 15.3141 6.285 15.2166 6.165 15.2166H4.545C3.57 15.2166 2.78249 14.429 2.78249 13.454V11.8341C2.78249 11.7141 2.68499 11.4741 2.59499 11.3841L1.455 10.2441C1.125 9.91405 0.9375 9.47153 0.9375 8.99903C0.9375 8.52653 1.1175 8.084 1.455 7.754L2.59499 6.61399C2.68499 6.52399 2.78249 6.28401 2.78249 6.16401V4.54407C2.78249 3.56907 3.57 2.7815 4.545 2.7815H6.165C6.285 2.7815 6.52501 2.684 6.61501 2.594L7.755 1.45398C8.415 0.793984 9.585 0.793984 10.245 1.45398L11.385 2.594C11.475 2.684 11.715 2.7815 11.835 2.7815H13.455C14.43 2.7815 15.2175 3.56907 15.2175 4.54407V6.16401C15.2175 6.28401 15.315 6.52399 15.405 6.61399L16.545 7.754C16.875 8.084 17.0625 8.52653 17.0625 8.99903C17.0625 9.47153 16.8825 9.91405 16.545 10.2441L15.405 11.3841C15.315 11.4741 15.2175 11.7141 15.2175 11.8341V13.454C15.2175 14.429 14.43 15.2166 13.455 15.2166H11.835C11.715 15.2166 11.475 15.3141 11.385 15.4041L10.245 16.5441C9.915 16.8816 9.4725 17.0615 9 17.0615ZM3.38999 10.589C3.68999 10.889 3.90749 11.4141 3.90749 11.8341V13.454C3.90749 13.8065 4.1925 14.0916 4.545 14.0916H6.165C6.585 14.0916 7.11 14.309 7.41 14.609L8.55 15.749C8.79 15.989 9.21 15.989 9.45 15.749L10.59 14.609C10.89 14.309 11.415 14.0916 11.835 14.0916H13.455C13.8075 14.0916 14.0925 13.8065 14.0925 13.454V11.8341C14.0925 11.4141 14.31 10.889 14.61 10.589L15.75 9.44901C15.87 9.32901 15.9375 9.17153 15.9375 8.99903C15.9375 8.82653 15.87 8.66905 15.75 8.54905L14.61 7.40903C14.31 7.10903 14.0925 6.58401 14.0925 6.16401V4.54407C14.0925 4.19157 13.8075 3.9065 13.455 3.9065H11.835C11.415 3.9065 10.89 3.68904 10.59 3.38904L9.45 2.24903C9.21 2.00903 8.79 2.00903 8.55 2.24903L7.41 3.38904C7.11 3.68904 6.585 3.9065 6.165 3.9065H4.545C4.1925 3.9065 3.90749 4.19157 3.90749 4.54407V6.16401C3.90749 6.58401 3.68999 7.10903 3.38999 7.40903L2.25 8.54905C2.13 8.66905 2.0625 8.82653 2.0625 8.99903C2.0625 9.17153 2.13 9.32901 2.25 9.44901L3.38999 10.589Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
      <path
        d="M11.2502 12C10.8302 12 10.4927 11.6625 10.4927 11.25C10.4927 10.8375 10.8302 10.5 11.2427 10.5C11.6552 10.5 11.9927 10.8375 11.9927 11.25C11.9927 11.6625 11.6627 12 11.2502 12Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
      <path
        d="M6.75751 7.5C6.33751 7.5 6 7.1625 6 6.75C6 6.3375 6.3375 6 6.75 6C7.1625 6 7.5 6.3375 7.5 6.75C7.5 7.1625 7.17001 7.5 6.75751 7.5Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
      <path
        d="M6.75008 11.8126C6.60758 11.8126 6.46508 11.7601 6.35258 11.6476C6.13508 11.4301 6.13508 11.0701 6.35258 10.8526L10.8526 6.35258C11.0701 6.13508 11.4301 6.13508 11.6476 6.35258C11.8651 6.57008 11.8651 6.93012 11.6476 7.14762L7.14758 11.6476C7.03508 11.7601 6.89258 11.8126 6.75008 11.8126Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
    </svg>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
};

export default Icon;
