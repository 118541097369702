import { useState, useMemo, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Table from "../../components/common/Table";
import ExpandedRow from "../../components/moneyRequest/ExpandedRow";
import ExpandedRowFullControl from "../../components/safe/Withdraw/preview/ExpandedRowFullControl/index";
import ExpandedRowWithoutPerm from "../../components/safe/Withdraw/preview/ExpandedRowWithoutPerm/index";
import ExpandedRowWithPerm from "../../components/safe/Withdraw/preview/ExpandedRowWithPerm/index";
import useFetchUserData from "../../hooks/reactQuery/useFetchUserData";
import { getColumnsHeader } from "../MoneyRequests/tableColumnsHeader";
import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
import useFetchSingleMoneyRequest from "../../hooks/reactQuery/useFetchSingleMoneyRequest";
import useMutateHoldMoneyRequest from "../../hooks/reactQuery/useMutateHoldMoneyRequest";
import Alert from "../../components/common/Alert";
import Modal from "../../components/common/Modal";
import { useTranslation } from "react-i18next";

const SingleMoneyRequest = () => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();
  const params = useParams();
  const safeId = params.safeId;
  const moneyRequestId = params.id;

  const location = useLocation();

  const history = useHistory();

  const currentSafe = userData.safes.find(
    (safe) => safe.id == safeId && safe.active == 1
  );

  const [hoverId, setHoverId] = useState(null);

  const [activeDeactiveId, setActiveDeactiveId] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);

  const [columnsHeaderState, setColumnsHeader] = useState(() =>
    getColumnsHeader(
      0, 
      false,   
      setActiveDeactiveId,
      setShowDeactivateModal,
      userData,
      hoverId,
      t,
      i18n
    ).filter(
      (el) =>
        el.key !== "status" &&
        el.key !== "approved" &&
        el.dataIndex !== "category"
    ).filter(item => item.selected)
  );

  /*useEffect(() => {
    setColumnsHeader(getColumnsHeader(
      0, 
      false,   
      setActiveDeactiveId,
      setShowDeactivateModal,
      userData,
      hoverId
    ).filter(
      (el) =>
        el.key !== "status" &&
        el.key !== "approved" &&
        el.dataIndex !== "category"
    ).filter(item => item.selected));
  }, [hoverId]);*/

  const [selectedRows, setSelectedRows] = useState([]);

  const {
    isLoading: isTableLoading,
    data: tableData,
    error: tableError,
  } = useFetchSingleMoneyRequest(moneyRequestId);

  const memoizedTableData = useMemo(
    () => (tableData ? tableData : []),
    [tableData]
  );

  const [expandedRows, setExpandedRows] = useState([]);

  const onTableRowExpand = (expanded, record) => {
    var keys = [];
    if (expanded) {
      keys.push(record.id);
    }
    setExpandedRows(keys);
  };

  useEffect(() => {
    if (tableData) setExpandedRows([tableData[0].id]);
  }, [tableData]);

  const { isLoading: isMutateHoldLoading, mutate: mutateHold } =
  useMutateHoldMoneyRequest(() => setShowDeactivateModal(false));

  return (
    <>
      {location.state && (
        <Breadcrumb
          items={[
            {
              title: location.state.comingFrom
                ? location.state.comingFrom
                : "home",
              action: () =>
                history.push(
                  location.state.fallback ? location.state.fallback : "/home"
                ),
            },
            { title: location.state.title, action: () => {} },
          ]}
        />
      )}

      <div className="mx-4 my-3">
        <Table
          rowKey="id"
          data={memoizedTableData}
          columns={columnsHeaderState}
          isLoading={isTableLoading}
          error={tableError}
          total={1}
          setSelectedRows={setSelectedRows}
          onTableRowExpand={onTableRowExpand}
          expandedRows={expandedRows}
          setExpandedRows={setExpandedRows}
          expandedRow={(row) => (
            row.withdrawn == 1 ? 
            currentSafe.permissions.includes("CashInWithPermission") ? 
            <ExpandedRowWithPerm row={row} safeId={safeId} />:
            currentSafe.permissions.includes("CashFullControl") ?
            <ExpandedRowFullControl row={row} safeId={safeId} /> :
            currentSafe.permissions.includes("CashInWithoutPermission") ?
            <ExpandedRowWithoutPerm row={row} safeId={safeId} />:
            <ExpandedRowFullControl row={row} safeId={safeId}/> 
            : <ExpandedRow
              row={row}
              activeTab={row.creator == userData.id ? 0 : 1}
            />
          )}
          isPaginationDisabled={true}
          pageSize={1}
          currentPage={1}
          hasFooter={false}
          hasSelection={false}
          setHoverId={setHoverId}
        />
        <Modal
          showModal={showDeactivateModal}
          onClose={() => setShowDeactivateModal(false)}
        >
          <Alert
            hasFooter={true}
            alertType="cancel"
            title="حجب العملية"
            body={() => (
              <div>
                {t("alert_text_block")}
              </div>
            )}
            confirmText="حجب العملية"
            cancelText="الغاء"
            isSubmitLoading={isMutateHoldLoading}
            handleConfirm={() => mutateHold(activeDeactiveId)}
            handleCancel={() => !isMutateHoldLoading && setShowDeactivateModal(false)}
          />
        </Modal>
      </div>
    </>
  );
};

export default SingleMoneyRequest;
