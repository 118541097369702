import { useState, useEffect } from "react";

import Shapes from "../../../Shapes";
import Spinner from "../../../Spinner";
import Close from "../../../../resources/icons/Close";
import Search from "./../../../../resources/icons/Search";
import { useTranslation } from "react-i18next";

const categoriesShapesOption = (category) => (
  <div className="p-2">{category.name}</div>
);

const CategsShapes = ({ iscategoriesLoading, categories, setCategories }) => {
  let [searchField, setSearchField] = useState("");

  const handleSearchFieldChange = (e) => {
    let value = e.target.value;
    setSearchField(value);
  };
  const handleClearSearchField = () => {
    setSearchField("");
  };

  const {t, i18n} = useTranslation();


  useEffect(() => {
    setCategories((prevCategories) => {
      return prevCategories?.map((category) => {
        if (category.name.includes(searchField)) {
          category.isShown = true;
        } else {
          category.isShown = false;
        }
        return category;
      });
    });
  }, [searchField]);

  return iscategoriesLoading ? (
    <div className="flex justify-center items-center p-6" dir={i18n.resolvedLanguage === "en" ? "ltr" : "rtl"}>
      <div className="w-6 h-6">
        <Spinner />
      </div>
    </div>
  ) : (
    <div className="grid grid-cols-3 gap-y-7 py-5 px-6" dir={i18n.resolvedLanguage === "en" ? "ltr" : "rtl"}>
      <div className="relative col-span-1">
        <input
          type="text"
          name="searchField"
          value={searchField}
          onChange={(e) => handleSearchFieldChange(e)}
          placeholder={t("search_placeholder")}
          className="px-10 border-outline focus:border-secondary rounded-md w-full text-sm font-medium text-side-icons"
          autoComplete="off"
        />
        <div className="absolute top-2.5 right-3">
          <Search />
        </div>
        <button
          className={`absolute top-3 left-3 transform scale-75 ${
            !searchField ? "hidden" : ""
          }`}
          onClick={handleClearSearchField}
        >
          <Close />
        </button>
      </div>
      <div className="col-span-3">
        <Shapes
          shapes={categories?.filter((user) => user.isShown)}
          setShapes={setCategories}
          formatShapesOption={categoriesShapesOption}
        />
      </div>
    </div>
  );
};

export default CategsShapes;
