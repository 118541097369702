import PropTypes from "prop-types";

function Icon(props) {
  const { className, ...restOfProps } = props;
  return (
    <svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      className={`${className}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restOfProps}
    >
      <g clipPath="url(#clip0_2688:48987)">
        <path
          d="M3.90596 11.5818C2.90538 11.5818 2.01535 10.9411 1.69216 9.98737L1.6703 9.9155C1.59408 9.66292 1.56215 9.45051 1.56215 9.23799V4.97656L0.0458864 10.038C-0.149126 10.7824 0.295259 11.5542 1.04086 11.7599L10.7055 14.3481C10.8261 14.3794 10.9468 14.3944 11.0656 14.3944C11.688 14.3944 12.2568 13.9812 12.4162 13.3724L12.9793 11.5818H3.90596Z"
          fill="#F8FAFC"
        />
        <path
          d="M15.0003 2.05082V9.23857C15.0003 10.0136 14.3691 10.6449 13.5941 10.6449H3.90628C3.8438 10.6449 3.78131 10.6386 3.72501 10.6323C3.06879 10.5511 2.55631 10.0136 2.50629 9.35107C2.5 9.31353 2.5 9.27599 2.5 9.23857V2.05082C2.5 1.2758 3.13127 0.644531 3.90628 0.644531H13.5941C14.3691 0.644531 15.0003 1.2758 15.0003 2.05082Z"
          fill="#F8FAFC"
        />
        <path
          d="M6.87306 3.7696C6.87306 4.45992 6.31343 5.01967 5.62311 5.01967C4.93268 5.01967 4.37305 4.45992 4.37305 3.7696C4.37305 3.07928 4.93268 2.51953 5.62311 2.51953C6.31343 2.51953 6.87306 3.07928 6.87306 3.7696Z"
          fill="#EB9B54"
        />
        <path
          d="M14.9999 7.48129V9.23766C14.9999 10.0127 14.3687 10.6439 13.5937 10.6439H3.90589C3.8434 10.6439 3.78092 10.6377 3.72461 10.6314L10.1623 4.19378C10.5873 3.76874 11.2873 3.76874 11.7123 4.19378L14.9999 7.48129Z"
          fill="#10B981"
        />
        <path
          d="M11.2872 10.6439H3.90585C3.84336 10.6439 3.78088 10.6376 3.72457 10.6313C3.06835 10.5501 2.55587 10.0126 2.50586 9.35009L5.47464 6.3812C5.89969 5.95627 6.59963 5.95627 7.02467 6.3812L11.2872 10.6439Z"
          fill="#10B981"
        />
      </g>
      <defs>
        <clipPath id="clip0_2688:48987">
          <rect width={15} height={15} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
};

export default Icon;
