import React, { Fragment, useState } from "react";

import { useForm, Controller, useWatch } from "react-hook-form";

import Modal from "../../common/Modal";
import Alert from "../../common/Alert";
import TextArea from "../../common/inputs/Textarea";
import UploadInput from "../../common/inputs/Upload";
import CloseSvg from "../../resources/close svg/CloseSvg";
import AttachmentSvg from "../../resources/attachment svg/AttachmentSvg";
import SuccessBlueSvg from "../../resources/success blue svg/SuccessBlueSvg";
import useFetchUserData from "../../../hooks/reactQuery/useFetchUserData";
import useFetchFinancials from "../../../hooks/reactQuery/useFetchFinancials";
import useMutateRejectMpneyRequest from "../../../hooks/reactQuery/useMutateRejectMpneyRequest";
import useMutateAcceptMoneyRequest from "../../../hooks/reactQuery/useMutateAcceptMoneyRequest";
import "./Respond.scss";
import { useTranslation } from "react-i18next";

const Respond = ({ onClose, userBalance, activeRow }) => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();
  const [showRejectionAlert, setShowRejectionAlert] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
    control,
    trigger,
  } = useForm({
    mode: "all",
  });

  const watchAttachments = useWatch({
    control,
    name: "attachments",
  });

  const { isLoading: isMutateRejectionLoading, mutate: mutateRejection } =
    useMutateRejectMpneyRequest(onClose, userData.id, userData.activeCompanyId);

  const handleRequestMoneyRespond = (data) => {
    let modifiedAttachments = data.attachments?.map((attachment) => {
      return Object.assign({
        guid: attachment.guid,
        name: attachment.name,
      });
    });
    data.attachments = modifiedAttachments;
    mutateRejection({ data, activeRow });
  };

  return (
    <div className="overlay" onClick={onClose}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <div className="bg-white lg:grid lg:grid-cols-12 lg:gap-x-5 max-w-screen-md money-request-respond-container">
          <form
            className="col-span-12"
            onSubmit={handleSubmit(handleRequestMoneyRespond)}
          >
            <div className="sm:rounded-md sm:overflow-hidden">
              <div className="relative text-center p-4">
                <div className="flex justify-center">
                  <div className="ml-2">
                    <SuccessBlueSvg />
                  </div>

                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    {t("money_request_reject")}
                  </h3>
                </div>

                <button
                  type="button"
                  className="absolute top-4 left-4"
                  onClick={onClose}
                >
                  <div>
                    <CloseSvg />
                  </div>
                </button>
              </div>
              <hr />
              <div className="bg-gray-50 py-6 px-4 space-y-6 sm:p-6">
                <div>
                  <p className="mt-1 text-sm text-gray-500">
                    {t("alert_text_confirm_reject_money_request_text", {sender: activeRow.sender_name, amount: activeRow.amount, balance: userBalance})}
                  </p>
                </div>

                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6">
                    <TextArea
                      register={register}
                      name="response_notes"
                      fieldName={t("notes")}
                      label={t("notes")}
                      placeholder={t("notes")}
                      hint="أدخل ملاحظات تود إرفاقها مع العملية."
                      required={false}
                      maxLength="2000"
                      errors={errors?.response_notes}
                    />
                  </div>
                </div>

                <div className="sm:col-span-2 sm:col-start-10">
                  <div className="flex justify-end">
                    <label
                      htmlFor="attachments"
                      className="border border-gray-300 rounded flex justify-evenly items-center cursor-pointer px-4 py-2"
                    >
                      <div className="ml-2">
                        <AttachmentSvg />
                      </div>
                      <p className="mx-1">{t("attachments")}</p>
                    </label>
                    <div />
                  </div>
                </div>

                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6">
                    <UploadInput
                      multiple={true}
                      accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf, image/*"
                      name="attachments"
                      id="attachments"
                      showUplodedFilesNames={true}
                      getValues={getValues}
                      fileSize="5000000"
                      errors={errors?.attachments}
                      Controller={Controller}
                      control={control}
                      setValue={setValue}
                      trigger={trigger}
                    />
                  </div>
                </div>
              </div>
              <hr />

              <div className="grid sm:grid-cols-10 md:grid-cols-8 gap-5 px-4 py-3 bg-white text-right sm:px-6">
                <Fragment>
                  <button
                    type="button"
                    className={`col-span-4 sm:col-start-2 md:col-span-2 md:col-start-3 gap-6 bg-black py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white  ${
                      !isValid
                        ? "cursor-not-allowed disabled bg-opacity-50"
                        : ""
                    }`}
                    disabled={!isValid}
                    onClick={() => {
                      setShowRejectionAlert(true);
                    }}
                  >
                  {t("confirm_reject")} 
                  </button>
                  <Modal
                    showModal={showRejectionAlert}
                    onClose={() => setShowRejectionAlert(false)}
                  >
                    <Alert
                      hasFooter={true}
                      alertType="cancel"
                      title={t("confirm_reject_money_request")}
                      body={() => (
                        <div>
                          {t("alert_text_confirm_reject_money_request", {sender: activeRow.sender_name, amount: activeRow.amount})}
                        </div>
                      )}
                      confirmText={t("confirm_reject")}
                      cancelText={t("cancel")}
                      handleConfirm={handleSubmit(handleRequestMoneyRespond)}
                      handleCancel={() => setShowRejectionAlert(false)}
                      isSubmitLoading={isMutateRejectionLoading}
                    />
                  </Modal>
                </Fragment>

                <button
                  type="button"
                  className="col-span-4 sm:col-start-6 md:col-span-2 md:col-start-5 gap-6 bg-white py-2 px-4 border border-red-400 rounded-md shadow-sm text-sm font-medium text-red-500 hover:bg-gray-50"
                  onClick={onClose}
                >
                  {t("cancel")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Respond;
