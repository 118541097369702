export const userImageUrl = (userData) => {
  return userData?.user_id !== null
    ? (process.env.REACT_APP_UPLOAD_FILES_BASE_URL
        ? process.env.REACT_APP_UPLOAD_FILES_BASE_URL
        : "") +
        (process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
          ? process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
          : "images/tarbeetapaymentrequests/") +
        `users/${userData?.user_id}/profilepicture` +
        `.${userData?.profile_picture_ext}`
    : "../img/default-user.svg";
};
