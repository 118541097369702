const TableTabs = ({
  urgent,
  setUrgent,
  notUrgent,
  setNotUrgent,
  accepted,
  setAccepted,
  rejected,
  setRejected,
}) => {
  return (
    <div className="pb-4 px-4">
      <fieldset className="grid grid-cols-8 gap-y-4 p-2">
        <div className="relative flex items-start col-span-4 sm:col-span-1">
          <div className="flex items-center h-5">
            <input
              id="urgent"
              aria-describedby="urgent-description"
              type="checkbox"
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              onChange={() => setUrgent((prevState) => !prevState)}
              checked={urgent}
            />
          </div>
          <div className="mr-3 text-sm">
            <label
              htmlFor="urgent"
              className="font-medium text-gray-700 cursor-pointer select-none"
            >
              عاجل
            </label>
          </div>
        </div>
        <div className="relative flex items-start col-span-4 sm:col-span-1">
          <div className="flex items-center h-5">
            <input
              id="not-urgent"
              aria-describedby="not-urgent-description"
              type="checkbox"
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              onChange={() => setNotUrgent((prevState) => !prevState)}
              checked={notUrgent}
            />
          </div>
          <div className="mr-3 text-sm">
            <label
              htmlFor="not-urgent"
              className="font-medium text-gray-700 cursor-pointer select-none"
            >
              غير عاجل
            </label>
          </div>
        </div>

        <div className="relative flex items-start col-span-4 sm:col-span-1">
          <div className="flex items-center h-5">
            <input
              id="accepted"
              aria-describedby="accepted-description"
              type="checkbox"
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              onChange={() => setAccepted((prevState) => !prevState)}
              checked={accepted}
            />
          </div>
          <div className="mr-3 text-sm">
            <label
              htmlFor="accepted"
              className="font-medium text-gray-700 cursor-pointer select-none"
            >
              مقبول
            </label>
          </div>
        </div>
        <div className="relative flex items-start col-span-4 sm:col-span-1">
          <div className="flex items-center h-5">
            <input
              id="rejected"
              aria-describedby="rejected-description"
              type="checkbox"
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              onChange={() => setRejected((prevState) => !prevState)}
              checked={rejected}
            />
          </div>
          <div className="mr-3 text-sm">
            <label
              htmlFor="rejected"
              className="font-medium text-gray-700 cursor-pointer select-none"
            >
              مرفوض
            </label>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default TableTabs;
