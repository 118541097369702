
function SafeBox(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5Z" stroke="#1E293B" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.7633 8.18259C10.6883 8.71509 10.3733 9.16509 9.93832 9.43509V10.9201C9.93832 11.4376 9.51832 11.8576 9.00082 11.8576C8.48332 11.8576 8.06332 11.4376 8.06332 10.9201V9.43509C7.62832 9.16509 7.31332 8.71509 7.23832 8.18259C7.22332 8.10009 7.21582 8.01009 7.21582 7.92009C7.21582 6.78009 8.29584 5.89509 9.48084 6.21009C10.0808 6.36759 10.5683 6.85509 10.7258 7.45509C10.7933 7.70259 10.8008 7.95009 10.7633 8.18259Z" stroke="#1E293B" stroke-miterlimit="10"/>
      <path d="M16.5012 8.18262H10.7637" stroke="#1E293B" stroke-miterlimit="10"/>
      <path d="M7.2375 8.1825H1.5" stroke="#1E293B" stroke-miterlimit="10"/>
    </svg>
  );
}

export default SafeBox;
