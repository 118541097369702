import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchPendingRequests({
  activeTab,
}) {
  return useQuery(
    [
      "getPendingRequestsData",
      activeTab,
    ],
    () => {
      const params = {};

      return apiClient
        .get(
          `/request_for_access/loadpendingrequests`,
          {
            params,
          }
        )
        .then(({ data }) => data);
    }
  );
}
