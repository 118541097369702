import { useEffect, useState } from "react";

import { NavLink, useLocation } from "react-router-dom";
import useCollapse from "react-collapsed";

import Spinner from "../../common/Spinner";
import WorkspaceSelect from "../../workspace/Select";
import HomeDashboard from "../../resources/icons/HomeDashboard";
import PayLicense from "../../resources/icons/PayLicense";
import HorizCollArrow from "../../resources/icons/HorizCollArrow";
import ChevronArrow from "../../resources/icons/ChevronArrow";
import MoneyInsert from "../../resources/icons/MoneyInsert";
import Calender from "../../resources/icons/Calender";
import ChartBar from "../../resources/icons/ChartBar";
import MoneyBag from "../../resources/icons/MoneyBag";
import Archive from "../../resources/icons/Archive";
import Settings from "../../resources/icons/Settings";
import ContactUs from "../../resources/icons/ContactUs";
import PendingRequests from "../../resources/icons/Attach";
import useFetchUserData from "../../../hooks/reactQuery/useFetchUserData";
import useFetchPaymentsCount from "../../../hooks/reactQuery/useFetchPaymentsCount";
import useFetchMoneyRequestsCount from "../../../hooks/reactQuery/useFetchMoneyRequestsCount";
import useFetchCashoutCount from "../../../hooks/reactQuery/useFetchCashoutCount";
import useFetchCashinCount from "../../../hooks/reactQuery/useFetchCashinCount";
import { useScrollRestoration } from "../../../hooks";
import actionTypes from "../../../context/types";
import { useDataLayerValue } from "../../../context/DataLayer";
import { useTranslation } from "react-i18next";
import "./SideBar.scss";

const Sidebar = () => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();

  const [navigation, setNavigation] = useState([
    {
      id: 1,
      name: t("spend_requests"),
      icon: PayLicense,
      current: false,
      path: "/payment-licenses",
      hasBorder: false,
      children: [
        {
          name: t("sent_spend_requests"),
          index: 0,
          hasPermission: userData.permission_tags.includes(
            "SendPurchaseRequest"
          ),
        },
        {
          name: t("received_spend_requests"),
          index: 1,
          hasPermission:
            userData.permission_tags.includes("AcceptOrRejectSpendRequest") ||
            userData.permission_tags.includes("ForwardPurchaseRequest"),
        },
      ],
      hasPermission:
        userData.permission_tags.includes("SendPurchaseRequest") ||
        userData.permission_tags.includes("AcceptOrRejectSpendRequest") ||
        userData.permission_tags.includes("ForwardPurchaseRequest"),
    },
    {
      id: 2,
      name: t("your_documents"),
      icon: MoneyInsert,
      current: false,
      path: "/documentations",
      hasBorder: false,
      hasPermission: userData.permission_tags.includes("Document"),
    },
    {
      id: 3,
      name: t("money_requests"),
      icon: Calender,
      current: false,
      path: "/money-requests",
      hasBorder: true,
      children: [
        {
          name: t("sent_money_requests"),
          index: 0,
          hasPermission: userData.permission_tags.includes("SendMoneyRequest"),
        },
        {
          name: t("received_money_requests"),
          index: 1,
          hasPermission:
            userData.permission_tags.includes("AcceptOrRejectSpendRequest") ||
            userData.permission_tags.includes("ForwardMoneyRequest"),
        },
        {
          name: t("withdrawals"),
          index: 2,
          hasPermission: userData.safes.length > 0,
        },
      ],
      hasPermission:
        userData.permission_tags.includes("SendMoneyRequest") ||
        userData.permission_tags.includes("AcceptOrRejectMoneyRequest") ||
        userData.permission_tags.includes("ForwardMoneyRequest") ||
        userData.permission_tags.includes("CashOutWithPermission") ||
        userData.permission_tags.includes("CashOutWithoutPermission"),
    },
    {
      id: 4,
      name: t("live_section"),
      icon: ChartBar,
      current: false,
      path: "/helicopter",
      hasBorder: false,
      children: [
        { name: t("all_transactions"), index: 0, hasPermission: true },
        { name: t("spend_requests"), index: 1, hasPermission: true },
        { name: t("money_requests_plural"), index: 2, hasPermission: true },
        { name: t("documentations"), index: 3, hasPermission: true },
      ],
      hasPermission:
        userData.permission_tags.includes("HelicopterReadOnly") ||
        userData.permission_tags.includes("HelicopterFullControl"),
    },
    {
      id: 5,
      name: t("safe"),
      icon: MoneyBag,
      current: false,
      path: "/safe",
      hasBorder: false,
      hasPermission:
        userData.permission_tags.includes("AddSafe") && userData.safes.length > 0 ||
        userData.permission_tags.includes("CashFullControl") && userData.safes.length > 0 ||
        userData.safes.length > 0,
    },
    // {
    //   id: 6,
    //   name: "الأرشيف",
    //   icon: Archive,
    //   current: false,
    //   path: "/archive",
    //   hasBorder: true,
    //   children: [
    //     {
    //       name: "التعاملات الصادرة",
    //       index: 0,
    //       hasPermission: userData.permission_tags.includes(
    //         "SendPurchaseRequest"
    //       ),
    //     },
    //     {
    //       name: "التعاملات الواردة",
    //       index: 1,
    //       hasPermission:
    //         userData.permission_tags.includes("AcceptOrRejectSpendRequest") ||
    //         userData.permission_tags.includes("ForwardPurchaseRequest"),
    //     },
    //   ],
    //   hasPermission:
    //     userData.permission_tags.includes("SendPurchaseRequest") ||
    //     userData.permission_tags.includes("AcceptOrRejectSpendRequest") ||
    //     userData.permission_tags.includes("ForwardPurchaseRequest"),
    // },
    {
      id: 6,
      name: t("settings"),
      icon: Settings,
      current: false,
      path: "/settings",
      hasBorder: false,
      hasPermission: true,
    },
    // {
    //   id: 8,
    //   name: "تواصل معنا",
    //   icon: ContactUs,
    //   current: false,
    //   path: "/contact-us",
    //   hasBorder: false,
    //   hasPermission: true,
    // },
  ]);

  const [{ activeTab }, dispatch] = useDataLayerValue();

  useScrollRestoration(activeTab);

  const updateActiveTab = (value) => {
    dispatch({
      type: actionTypes.ACTIVE_TAB,
      payload: value,
    });
  };

  const { isLoading: isPaymentsCountLoading, data: paymentsCount } =
    useFetchPaymentsCount({ activeCompanyId: userData.activeCompanyId });

  const { isLoading: isMoneyRequestsCountLoading, data: moneyRequestsCount } =
    useFetchMoneyRequestsCount({ activeCompanyId: userData.activeCompanyId });

  const { isLoading: isCashoutCountLoading, data: cashoutCount } =
    useFetchCashoutCount();

  const { isLoading: isCashinCountLoading, data: cashinCount } =
    useFetchCashinCount();

  const getBadgeCount = (id) => {
    switch (id) {
      case 1:
        return isPaymentsCountLoading ? (
          <div className="w-4 h-4">
            <Spinner />
          </div>
        ) : (
          paymentsCount.notifications_count
        );
      case 2:
        return false;
      case 3:
        return isMoneyRequestsCountLoading ? (
          <div className="w-4 h-4">
            <Spinner />
          </div>
        ) : (
          moneyRequestsCount.notifications_count
        );
      case 4:
        return false;
      case 5:
        return isCashinCountLoading || isCashoutCountLoading ? (
          <div className="w-4 h-4">
            <Spinner />
          </div>
        ) : (
          cashinCount.notifications_count + cashoutCount.notifications_count
        );
      case 6:
        return false;
      case 7:
        return false;
    }
  };

  const location = useLocation();

  useEffect(() => {
    if (
      [
        "mostafa.saady@cloudabill.com",
        "f@chickinworx.com",
        "islamalsoultan@gmail.com",
      ].includes(userData.email)
    ) {
      if (!navigation?.map((el) => el.id)?.includes(9)) {
        setNavigation([
          ...navigation,
          {
            id: 9,
            name: t("pending_requests"),
            icon: PendingRequests,
            current: false,
            path: "/pending-requests",
            hasBorder: false,
            hasPermission: true,
          },
        ]);
      }
    }
  }, [location]);

  const [isSideExpandedClick, setSideExpandedClick] = useState(true);
  const [isSideExpandedHover, setSideExpandedHover] = useState(false);

  useEffect(() => {
    dispatch({
      type: actionTypes.IS_SIDEBAR_EXPANDED,
      payload: {
        isSideBarExpanded: isSideExpandedClick || isSideExpandedHover,
      },
    });
  }, [isSideExpandedClick, isSideExpandedHover]);

  const [fillColor, setFillColor] = useState("");

  return (
    <aside
      className={`bg-sidebar-background h-screen sticky top-0 transition-all duration-300 z-10 overflow-visible pt-4 ${i18n.resolvedLanguage === "en" ? "pl-2" : ""}
     ${
       isSideExpandedClick || isSideExpandedHover
         ? "w-70"
         : "w-14 overflow-hidden"
     } relative transition-all duration-300
    `}
    >
      <button
        type="button"
        onClick={() => {
          setSideExpandedClick((prevExpanded) => !prevExpanded);
        }}
        onMouseOver={(e) => {
          setFillColor("#fff");
        }}
        onMouseLeave={(e) => {
          setFillColor("");
        }}
        className={`absolute ${i18n.resolvedLanguage === "ar" ? "-left-7" : "-right-7"} top-22 z-10 bg-white hover:bg-secondary rounded-full p-2 m-4 shadow-sm flex justify-center items-center transition-all duration-300 transform ${
          !isSideExpandedClick && !isSideExpandedHover && "rotate-90"
        }`}
      >
        <ChevronArrow fillColor={fillColor} />
      </button>
      <div
        className="mr-4 flex justify-start w-full bg-inherit z-10 relative"
        style={{ width: isSideExpandedClick ? "231px" : "auto" }}
      >
        <WorkspaceSelect
          isExpanded={isSideExpandedClick || isSideExpandedHover}
        />
      </div>
      <div className="sidebar-container">
        <nav className="mt-10" style={{ height: "calc(100% - 40px)" }}>
          <ul
            onMouseOver={() => {
              setSideExpandedHover(true);
            }}
            onMouseLeave={() => {
              setSideExpandedHover(false);
            }}
            className="flex flex-col h-full justify-between"
          >
            <div>
              {navigation.slice(0, 5).map((item) => {
                return item.hasPermission ? (
                  <li key={item.path}>
                    <ExpandedNavLink
                      item={item}
                      getBadgeCount={getBadgeCount}
                      isSideExpandedClick={isSideExpandedClick}
                      isSideExpandedHover={isSideExpandedHover}
                      updateActiveTab={updateActiveTab}
                      activeTab={activeTab}
                    />
                  </li>
                ) : null;
              })}
            </div>
            <div>
              {navigation.slice(5, navigation.length).map((item) => {
                return (
                  <li key={item.path}>
                    <ExpandedNavLink
                      item={item}
                      getBadgeCount={getBadgeCount}
                      isSideExpandedClick={isSideExpandedClick}
                      isSideExpandedHover={isSideExpandedHover}
                      updateActiveTab={updateActiveTab}
                      activeTab={activeTab}
                    />
                  </li>
                );
              })}
              <button className="change-language" onClick={() => {
                i18n.changeLanguage(i18n.resolvedLanguage === "ar" ? "en" : "ar");
                window.location.href = "/";
            }}>{i18n.resolvedLanguage === "ar" ? "English" : "العربية"}</button>
            </div>
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;

function ExpandedNavLink({
  item,
  getBadgeCount,
  isSideExpandedClick,
  isSideExpandedHover,
  updateActiveTab,
  activeTab,
}) {
  const location = useLocation();

  const [isExpanded, setExpanded] = useState(false);

  const {t, i18n} = useTranslation();


  useEffect(() => {
    setExpanded(true);
  }, [location.pathname != item.path]);

  useEffect(() => {
    !isSideExpandedClick && !isSideExpandedHover && setExpanded(false);
  }, [isSideExpandedClick, isSideExpandedHover]);

  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded: isExpanded && location.pathname === item.path,
    duration: 700,
  });

  return (
    <>
      <div key={item.path}>
        <NavLink
          name={"sideBarItem" + item.id}
          className="flex items-center py-3 hover:bg-white"
          activeClassName="bg-white"
          {...getToggleProps({
            onClick: () => setExpanded((prevExpanded) => !prevExpanded),
          })}
          exact
          to={item.path}
        >
          <div className="pl-5 pr-4">
            <item.icon
              strokeColor={location.pathname === item.path && "#3B82F6"}
            />
          </div>
          <div className={`w-full flex justify-between items-center ${i18n.resolvedLanguage === "ar" ? "pl-5" : ""}`}>
            <div className="w-25 h-6 overflow-hidden font-bold text-sm">
              <span
                className={`transition-all duration-300 ${
                  location.pathname === item.path
                    ? "text-secondary"
                    : "text-hints"
                }`}
              >
                {item.name}
              </span>
            </div>
            {getBadgeCount(item.id) !== false && (
              <span className="text-hints text-xs font-medium bg-separator rounded-xl px-px w-9 flex justify-center items-center">
                {getBadgeCount(item.id)}
              </span>
            )}

            <div
              className={`transform transition-all duration-700 ${
                isExpanded && location.pathname === item.path ? "rotate-90" : ""
              } ${!item.children ? "invisible" : ""}
            `}
            >
              <HorizCollArrow
                fillColor={
                  isExpanded && location.pathname === item.path && "#6B7280"
                }
              />
            </div>
          </div>
        </NavLink>
        <ul {...getCollapseProps()}>
          {item.children?.map((subItem, index) => {
            return subItem.hasPermission ? (
              <li
                role="button"
                key={subItem.name}
                className="pr-4 pl-3 py-2 flex items-center justify-between"
                onClick={() => {
                  updateActiveTab(index);
                }}
              >
                <div
                  className={`w-full flex justify-between items-center pr-3 font-bold text-xs leading-5 transition-all duration-700 ${
                    activeTab === index
                      ? "border-r-2 border-blue-600 text-secondary"
                      : "border-r-2 border-transparent text-hints"
                  }`}
                >
                  <span>{subItem.name}</span>
                  <span className="text-hints text-xs font-medium bg-separator rounded-xl px-px w-9 flex justify-center items-center">
                    0
                  </span>
                </div>
              </li>
            ) : null;
          })}
        </ul>
      </div>
      {item.hasBorder && <div className="border-b border-outline mx-2"></div>}
    </>
  );
}
