import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useMutatePendingRequestAccept() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ request }) => {
      let data = new FormData();
      data.append("id", request.id);

      return apiClient.post(`request_for_access/accept`, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getPendingRequestsData");
      },
      onError: (error) => {
        return error;
      },
    }
  );
}
