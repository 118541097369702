import React, { useEffect } from "react";
import ReactDOM from "react-dom";

import "./Modal.scss";

const Modal = ({ onClose, showModal, children }) => {
  // useEffect(() => {
  //   document.body.addEventListener("keydown", closeOnEscapeKeyDown);
  //   return function cleanUp() {
  //     document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
  //   };
  // }, []);
  // const closeOnEscapeKeyDown = (e) => {
  //   if ((e.charCode || e.keyCode) === 27) {
  //     onClose();
  //   }
  // };
  return !showModal
    ? null
    : ReactDOM.createPortal(
        <>
          {/* <div className={styles.overlay} onClick={onClose}>
            <div className={styles.modal} onClick={(e) => e.stopPropagation()}> */}
          {children}
          {/* </div>
          </div> */}
        </>,
        document.getElementById("root")
      );
};

export default Modal;
