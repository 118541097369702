const FinishedSvg = ({}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M11.9993 22.9031C18.0201 22.9031 22.901 18.0222 22.901 12.0014C22.901 5.9806 18.0201 1.09976 11.9993 1.09976C5.97849 1.09976 1.09766 5.9806 1.09766 12.0014C1.09766 18.0222 5.97849 22.9031 11.9993 22.9031Z"
        fill="#A5E8BB"
      />
      <path
        d="M11.9993 22.9031C18.0201 22.9031 22.901 18.0222 22.901 12.0014C22.901 5.9806 18.0201 1.09976 11.9993 1.09976C5.97849 1.09976 1.09766 5.9806 1.09766 12.0014C1.09766 18.0222 5.97849 22.9031 11.9993 22.9031Z"
        stroke="#33CC66"
        strokeMiterlimit={10}
      />
      <path
        d="M12.0015 16.8359C14.6731 16.8359 16.8388 14.6701 16.8388 11.9985C16.8388 9.32685 14.6731 7.16107 12.0015 7.16107C9.32984 7.16107 7.16406 9.32685 7.16406 11.9985C7.16406 14.6701 9.32984 16.8359 12.0015 16.8359Z"
        fill="#33CC66"
      />
    </svg>
  );
};

export default FinishedSvg;
