import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchWithdrawFullControl({
  safeId,
  currentPage,
  pageSize,
  order,
  columnKey,
  searchType,
  searchTerms,
}) {
  return useQuery(
    [
      "getWithdrawFullControl",
      currentPage,
      pageSize,
      order,
      columnKey,
      searchType,
      searchTerms,
    ],
    () => {
      const params = {safe_id: safeId};
      params.pageno = currentPage;
      params.pagesize = +pageSize;

      if (order && columnKey) {
        params.orderbycolumn = columnKey;
        params.isascending =
          order === "ascend" ? 1 : order === "descend" ? 0 : null;
      }
      if (searchType !== "" && searchTerms.length > 0) {
        params.search_type = searchType;
        params.search_terms = JSON.stringify(searchTerms);
      }

      return apiClient
        .get("safe/withdrawals", {
          params,
        })
        .then(({ data }) => data);
    }
  );
}
