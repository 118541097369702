import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useMutateEditRole(
  onClose,
  addNewRoleCheckbox,
  reset,
  setShowAlertConfirmModal,
  userData,
  setPerms,
  setAddNewRoleCheckbox
) {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      const formData = new FormData();

      formData.append("company_id", userData.activeCompanyId);
      formData.append("name", data.roleName);
      formData.append("en_name", data.en_name);
      formData.append("permissions", JSON.stringify(data.permissions));

      if (data.activatType === "deActivateRole") {
        formData.append("active", 0);
      }

      if (data.activatType === "activateRole") {
        formData.append("active", 1);
      }

      return apiClient.patch(`role/${data?.selectedRole?.id}`, formData);
    },
    {
      onSuccess: (res, sentData) => {
        toast.info(
          sentData.activatType === "deActivateRole"
            ? "تم تعطيل الدور"
            : sentData.activatType === "activateRole"
            ? "تم تفعيل الدور"
            : "تم تعديل الدور بنجاح"
        );
        queryClient.invalidateQueries("getAllRoles");
        queryClient.invalidateQueries("userData");
        setShowAlertConfirmModal(false);
        onClose();
      },
      onError: (error) => {
        onClose();
        return error;
      },
    }
  );
}
