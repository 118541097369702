import PropTypes from "prop-types";

function Icon(props) {
  const { className, fillColor, ...restOfProps } = props;
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...restOfProps}
    >
      <path
        d="M8.78361 18.2827C8.59194 18.2827 8.4086 18.1994 8.2836 18.0327L7.44193 16.9077C7.26693 16.6744 7.03362 16.541 6.78362 16.5243C6.52528 16.5077 6.28359 16.6244 6.08359 16.8327C4.87526 18.1244 3.9586 18.016 3.51694 17.8494C3.06694 17.6744 2.30859 17.0993 2.30859 15.2493V5.86602C2.30859 2.16602 3.37526 1.04102 6.86693 1.04102H13.1586C16.6503 1.04102 17.7169 2.16602 17.7169 5.86602V9.41601C17.7169 9.75767 17.4336 10.041 17.0919 10.041C16.7503 10.041 16.4669 9.75767 16.4669 9.41601V5.86602C16.4669 2.85769 15.9419 2.29102 13.1586 2.29102H6.86693C4.08359 2.29102 3.55859 2.85769 3.55859 5.86602V15.2493C3.55859 16.1243 3.77526 16.6077 3.97526 16.6827C4.12526 16.741 4.53361 16.6577 5.16695 15.9827C5.62528 15.4993 6.22527 15.241 6.85027 15.2743C7.46694 15.3077 8.05027 15.6327 8.44194 16.1577L9.29193 17.2827C9.50026 17.5577 9.44194 17.9494 9.16694 18.1577C9.04194 18.2494 8.90861 18.2827 8.78361 18.2827Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
      <path
        d="M13.3332 6.45898H6.6665C6.32484 6.45898 6.0415 6.17565 6.0415 5.83398C6.0415 5.49232 6.32484 5.20898 6.6665 5.20898H13.3332C13.6748 5.20898 13.9582 5.49232 13.9582 5.83398C13.9582 6.17565 13.6748 6.45898 13.3332 6.45898Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
      <path
        d="M12.5 9.79102H7.5C7.15833 9.79102 6.875 9.50768 6.875 9.16602C6.875 8.82435 7.15833 8.54102 7.5 8.54102H12.5C12.8417 8.54102 13.125 8.82435 13.125 9.16602C13.125 9.50768 12.8417 9.79102 12.5 9.79102Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
      <path
        d="M12.3503 18.1494C12.0336 18.1494 11.7336 18.0327 11.517 17.816C11.2586 17.5577 11.142 17.1827 11.2003 16.791L11.3586 15.666C11.4003 15.3743 11.5753 15.0244 11.7836 14.816L14.7336 11.866C15.1336 11.466 15.5253 11.2577 15.9503 11.216C16.467 11.166 16.9836 11.3827 17.4669 11.866C17.9503 12.3493 18.1669 12.8577 18.1169 13.3827C18.0753 13.7994 17.8586 14.1993 17.4669 14.5993L14.517 17.5494C14.3086 17.7577 13.9586 17.9327 13.6669 17.9744L12.5419 18.1327C12.4753 18.141 12.417 18.1494 12.3503 18.1494ZM16.092 12.4577C16.0836 12.4577 16.0753 12.4577 16.067 12.4577C15.9503 12.466 15.7919 12.5743 15.6169 12.7493L12.667 15.6994C12.642 15.7244 12.6003 15.8077 12.6003 15.841L12.4503 16.8827L13.492 16.7327C13.5253 16.7243 13.6086 16.6827 13.6336 16.6577L16.5836 13.7077C16.7586 13.5244 16.867 13.3744 16.8753 13.2577C16.892 13.091 16.7253 12.891 16.5836 12.7493C16.4503 12.616 16.2586 12.4577 16.092 12.4577Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
      <path
        d="M16.5998 15.2078C16.5415 15.2078 16.4832 15.1995 16.4332 15.1828C15.3332 14.8745 14.4582 13.9995 14.1498 12.8995C14.0582 12.5661 14.2498 12.2244 14.5832 12.1244C14.9165 12.0328 15.2582 12.2245 15.3498 12.5578C15.5415 13.2411 16.0832 13.7828 16.7665 13.9744C17.0998 14.0661 17.2915 14.4161 17.1998 14.7495C17.1248 15.0245 16.8748 15.2078 16.5998 15.2078Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
    </svg>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
};

export default Icon;
