import DotsSvg from "../../../components/resources/dots svg/DotsSvg";
import RedirectSvg from "../../../components/resources/redirect svg/RedirectSvg";
import KeyIcon from "../../../components/resources/icons/KeyIcon";
import TableSortIcon from "../../../components/resources/icons/TableSortIcon";
import CashAmountIcon from "../../../components/resources/icons/CashAmountIcon";
import UserAlt from "../../../components/resources/icons/UserAlt";
import UserTag from "../../../components/resources/icons/UserTag";
import CategoryIcon from "../../../components/resources/icons/CategoryIcon";
import DateIcon from "../../../components/resources/icons/DateIcon";
import Reply from "../../../components/resources/icons/Reply";
import Rejected from "../../../components/resources/icons/Rejected";
import Accepted from "../../../components/resources/icons/Accepted";
import Suspended from "../../../components/resources/icons/Suspended";
import EditNewRoleIcon from "../../resources/icons/EditRoleIcon";
import UsersAvatars from "../../../components/common/UsersAvatars.jsx";
import SafesIcon from "../../../components/resources/icons/SafesIcon";
import { toolPin } from "../../../utils/toolPin";
import InputDate from "../../../components/resources/icons/InputDate";
import {Link} from "react-router-dom";
import "./safes.scss";

export const columnsHeader = ({
  userData,
  setShowEditSafeModal,
  setCurrentEditSafe,
  t,
  i18n,
  canViewSafesIds
}) => [
  {
    subject: "كود الخزينة",
    title: () => (
      <div class="flex items-center">
        <div>
          <KeyIcon />
        </div>
        <div className="mx-2 row-text">{t("safe_code")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "code",
    key: "id",
    sorter: true,
    render: (text, row) => (
      <div className="flex justify-center text-xs text-body font-medium" style={!row.active ? {opacity: 0.5} : {}}>
        {text}
      </div>
    ),
    selected: true,
    width: 20,
  },
  {
    subject: "اسم الخزينة",
    title: () => (
      
      <div class="flex items-center">
        <div>
          <SafesIcon fillColor={"#292D32"}/>
        </div>
        <div className="mx-2 text-xs font-bold text-body">{t("safe_name")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "name",
    key: "name",
    sorter: true,
    render: (text, row) => (
      <div className="flex justify-start row-text" style={!row.active ? {opacity: 0.5} : {}} title={i18n.resolvedLanguage === "en" && row.en_name ? row.en_name : text}>
        {i18n.resolvedLanguage === "en" && row.en_name ? row.en_name.length > 13 ? "..." + row.en_name.slice(0, 13) : row.en_name : text.length > 13 ? "..." + text.slice(0, 13) : text}
      </div>
    ),
    selected: true,
    width: 200,
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <InputDate />
        </div>
        <div className="col-title">{t("creation_date")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "created_at",
    key: "created_at",
    sorter: true,
    render: (text, row) => (
      <div className="col-desc flex justify-center" style={!row.active ? {opacity: 0.5} : {}}>{toolPin(text.split("T")[0])}</div>
    ),
    selected: true,
    width: 50,
  },
  {
    subject: "",
    title: () => (
      <div class="flex items-center">
        <div>
          <UserTag />
        </div>
        <div className="mx-2 text-xs font-bold text-body">{t("safe_users")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "users_json_string",
    key: "users",
    sorter: true,
    render: (text, row) => (
      canViewSafesIds.includes(row.id) ? <div className="col-desc flex justify-center" style={!row.active ? {opacity: 0.5} : {}}>
        <UsersAvatars usersArr={JSON.parse(text)} />
      </div> : <div className="flex justify-center items-center">--</div>
    ),
    selected: true,
    width: 50,
  },
  {
    subject: "",
    title: () => (
      <div class="flex items-center">
        <div>
          <UserTag />
        </div>
        <div className="mx-2 text-xs font-bold text-body">{t("safe_manager")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "manager_json_string",
    key: "manager",
    sorter: true,
    render: (text, row) => (
      canViewSafesIds.includes(row.id) ? <div className="col-desc flex justify-center" style={!row.active ? {opacity: 0.5} : {}}>
        <UsersAvatars usersArr={JSON.parse(text)} />
      </div> : <div className="flex justify-center items-center">--</div>
    ),
    selected: true,
    width: 200,
  },
  {
    subject: "رصيد الخزينة الحالي",
    title: () => (
      <div class="flex items-center">
        <div>
          <CashAmountIcon />
        </div>
        <div className="mx-2 text-xs font-bold text-body">{t("safe_balance")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "balance",
    key: "balance",
    sorter: true,
    render: (text, row) => (
      canViewSafesIds.includes(row.id) ? <div className="flex justify-center row-text" style={!row.active ? {opacity: 0.5} : {}}>
      {new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(parseInt(text)).toString().replace(/EGP/, "") + " "}
      ج.م
      </div> : <div className="flex justify-center items-center">--</div>
    ),
    selected: true,
    width: 200,
  },
  {
    subject: "",
    title: () => null,
    dataIndex: "id",
    key: "id",
    sorter: false,
    render: (text, row) => (
      <div className="flex justify-end">
        {userData.safes.find(safe => safe.id === row.id)?.permissions.includes("EditSafe") 
        || userData.safes.find(safe => safe.id === row.id)?.permissions.includes("DeactivateSafe")
        || userData.safes.find(safe => safe.id === row.id)?.permissions.includes("CashFullControl") ?
          <div>
            <button
              type="button"
              className={`col-span-1 px-3 py-2 m-auto text-base`}
              onMouseDown={() => {
                setShowEditSafeModal(true);
                setCurrentEditSafe(row);
              }}
            >
              <div class="flex flex-row-reverse fill-current">
                <div>
                  <EditNewRoleIcon isButton />
                </div>
              </div>
            </button>
          </div>
        :null}
      </div>
    ),
    selected: true,
    width: 20,
  },
];
