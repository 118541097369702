import Archive from "./Archive";
import ExportToExcel from "./ExportToExcel";
import CustomizableTable from "./CustomizableTable";

import "./TableOperations.scss";

const TableOperations = ({
  activeTab,
  setColumns,
  originalColumnsHeader,
  selectedRows,
  excelFileName,
  mutateArchiving,
  FiltersComponent,
  dispatchFiltrers,
  filtersState,
  hasArchiving = true,
}) => {
  return (
    <div className="h-full text-sm font-medium text-secondary flex flex-wrap sm:justify-end justify-between items-center gap-x-4 col-start-6">
      <CustomizableTable
        activeTab={activeTab}
        setColumns={setColumns}
        originalColumnsHeader={originalColumnsHeader}
      />

      {hasArchiving && (
        <Archive
          activeTab={activeTab}
          selectedRows={selectedRows}
          mutateArchiving={mutateArchiving}
        />
      )}

      <ExportToExcel
        selectedRows={selectedRows}
        excelFileName={excelFileName}
      />

      <FiltersComponent
        activeTab={activeTab}
        dispatchFiltrers={dispatchFiltrers}
        filtersState={filtersState}
      />
    </div>
  );
};

export default TableOperations;
