import FieldError from "../../../resources/icons/FieldError";

const TextArea = ({
  rows = 5,
  register,
  name,
  label,
  placeholder,
  hint,
  fieldName,
  defaultValue,
  required,
  minLength,
  maxLength,
  pattern,
  validate,
  errors,
}) => {
  return (
    <div>
      <div className="flex justify-between items-center mb-1">
        {label && (
          <label htmlFor={name} className="text-sm text-body font-medium">
            {label}
          </label>
        )}

        {errors ? (
          <p className="text-xs leading-5 font-medium text-error">
            {errors.message}
          </p>
        ) : !required ? (
          <p className="text-sm font-medium text-hints">اختياري</p>
        ) : null}
      </div>
      <div className="mt-1 relative rounded-md shadow-sm">
        <textarea
          rows={rows}
          {...register(name, {
            required: required && {
              value: required,
              message: fieldName ? `${fieldName} مطلوب` : "مطلوب",
            },
            minLength: minLength && {
              value: minLength,
              message: `يجب ألا يقل ${fieldName + " عن " + minLength} أحرف`,
            },
            maxLength: maxLength && {
              value: maxLength,
              message: `يجب ألا يتجاوز  ${fieldName + " " + maxLength} حرفاً`,
            },
            pattern: pattern && {
              value: pattern.regex,
              message: pattern.errorMessage,
            },
            validate: validate && validate,
          })}
          id={name}
          className={`block w-full focus:outline-none border rounded text-sm text-body font-medium ${
            errors
              ? "focus:border-red-500 focus:ring-red-500 text-red-900 border-red-300 pl-10"
              : "border-outline"
          }`}
          placeholder={placeholder}
          defaultValue={defaultValue}
        ></textarea>
        {errors && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <FieldError />
          </div>
        )}
      </div>

      {hint && (
        <p className="mt-2 text-xs leading-5 font-normal text-hints">{hint}</p>
      )}
    </div>
  );
};

export default TextArea;
