const Accept = () => {
  return (
    <div>
      <svg
        width={10}
        height={8}
        viewBox="0 0 10 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.57994 7.58156C3.37994 7.58156 3.18994 7.50156 3.04994 7.36156L0.219941 4.53156C-0.0700586 4.24156 -0.0700586 3.76156 0.219941 3.47156C0.509941 3.18156 0.989941 3.18156 1.27994 3.47156L3.57994 5.77156L8.71994 0.631563C9.00994 0.341563 9.48994 0.341563 9.77994 0.631563C10.0699 0.921562 10.0699 1.40156 9.77994 1.69156L4.10994 7.36156C3.96994 7.50156 3.77994 7.58156 3.57994 7.58156Z"
          fill="#1E293B"
        />
      </svg>
    </div>
  );
};

export default Accept;
