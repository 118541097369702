
import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import AddNewRoleIcon from "../../../components/resources/icons/AddNewRoleIcon";
import Table from "../../../components/common/Table";
import apiClient from "../../../services/apiClient";
import useFetchUserData from "../../../hooks/reactQuery/useFetchUserData";
import { columnsHeader } from "./tableColumnsHeader.js";
import { useQueryClient } from "react-query";
import useFetchSafes from "../../../hooks/reactQuery/useFetchSafes";
import useFetchCompanyBalance from "../../../hooks/reactQuery/useFetchCompanyBalance";
import useFetchCurrentMoneyRequests from "../../../hooks/reactQuery/useFetchCurrentMoneyRequests";
import useFetchMonthlyCashin from "../../../hooks/reactQuery/useFetchMonthlyCashin";
import useFetchMonthlyExpenses from "../../../hooks/reactQuery/useFetchMonthlyExpenses";
import useFetchPendingCash from "../../../hooks/reactQuery/useFetchPendingCash";
import SafesIcon from "../../../components/resources/icons/SafesIcon";
import Holdings from "../../../components/resources/icons/Holdings";
import LatestIcon from "../../../components/resources/icons/LatestIcon";

import Chart from "../../../components/safe/Charts/StackedBar/index";
import Indicators from "../../../components/resources/icons/Indicators";

import "./safe.scss";
import HeaderSectionData from "../../../components/safe/Dashboard/HeaderSectionData";
import Latest from "../../../components/safe/Dashboard/Latest";
import OnHoldRow from "../../../components/safe/Dashboard/Latest/OnHoldRow";
import TotalSection from "../../../components/safe/Dashboard/TotalSection";
import ActiveMoneyRequests from "../../../components/safe/Dashboard/ActiveMoneyRequests";
import ActiveMoneyRequestsIcon from "../../../components/resources/icons/ActiveMoneyRequestsIcon";
import useFetchLastUpdates from "../../../hooks/reactQuery/useFetchLastUpdates";
import LastUpdatesRow from "../../../components/safe/Dashboard/Latest/LastUpdatesRow";
import { useTranslation } from "react-i18next";

const Safe = ({}) => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();

  const canViewSafesIds = userData.safes.filter(s => s.canView).map(s => s.id);

  const [showIndicators, setShowIndicators] = useState(true);

  const pageSize = process.env.REACT_APP_PAGE_SIZE;
  const [currentPage, setCurrentPage] = useState(1);
  const [columnKey, setColumnKey] = useState("");
  const [order, setOrder] = useState("");

  const [columnsHeaderState, setColumnsHeaderState] = useState(
    columnsHeader({
      userData,
      t,
      i18n,
      canViewSafesIds
    })
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [order, columnKey]);

  const {
    isLoading: isTableDataLoading,
    data: tableData,
    error: tableDataError,
    isPreviousData,
  } = useFetchSafes({
    userId: userData.id,
    activeCompanyId: userData.activeCompanyId,
    currentPage,
    pageSize,
    columnKey,
    order,
  });

  const {
    isLoading: isBalanceLoading,
    data: companyBalance,
    error: companyBalanceError,
  } = useFetchCompanyBalance(null);

  const {
    isLoading: isMonthlyCashinLoading,
    data: monthlyCashin,
    error: monthlyCashinError,
  } = useFetchMonthlyCashin(null);

  const {
    isLoading: isMonthlyExpensesLoading,
    data: monthlyExpenses,
    error: monthlyExpensesError,
  } = useFetchMonthlyExpenses(null);

  const {
    isLoading: isCurrentMoneyRequestsLoading,
    data: currentMoneyRequests,
    error: currentMoneyRequestError,
  } = useFetchCurrentMoneyRequests(null);

  const formattedCurrentMoneyRequest = currentMoneyRequests ? new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(currentMoneyRequests) : 0.00;
  const formattedMonthlyExpenses = monthlyExpenses ? new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(monthlyExpenses) : 0.00;
  const formattedMonthlyCashin = monthlyCashin ? new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(monthlyCashin) : 0.00;
  const formattedCompanyBalance = companyBalance ? new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(companyBalance) : 0.00;

  const [pendingCashState, setPendingCashState] = useState([]);
  const [pendingCashLastPage, setPendingCashLastPage] = useState(1);
  const [currentPendingCashPage, setCurrentPendingCashPage] = useState(1);

  const {
    isLoading: isPendingCashLoading,
    data: pendingCash,
    error: pendingCashError,
  } = useFetchPendingCash(null, currentPendingCashPage);

  useEffect(() => {
    if(pendingCash && pendingCash.length > 0) {
      setPendingCashLastPage(Math.ceil(pendingCash[1][0].count /10));
      setPendingCashState(currentPendingCash => [...currentPendingCash, ...pendingCash[0]]);
    }
  }, [pendingCash]);

  const memoizedTableData = useMemo(() => (tableData ? tableData[0] : []), [tableData]);

  const handleTableChange = (pagination, filters, sorter) => {
    setOrder(sorter?.order);
    setColumnKey(sorter?.columnKey);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const history = useHistory();

  const queryClient = useQueryClient();

  const [expandedRows, setExpandedRows] = useState([]);

  
  const onTableRowExpand = (expanded, record) => {
    var keys = [];
    if (expanded && canViewSafesIds.includes(record.id)) {
      history.push(`/safe/${record.id}`);
    }
    setExpandedRows(keys);
  };

  const CSRFToken = useQuery(
    "CSRFToken",
    () => {
      return apiClient.get("/getCSRFToken");
    },
    {
      onSuccess(response) {
        localStorage.setItem("CSRFToken", response.data.CSRFToken);
      },
    }
  );

  const [currentLastUpdatesPage, setCurrentLastUpdatesPage] = useState(1);
  const [lastUpdatesLastPage, setLastUpdatesLastPage] = useState(1);
  const [lastUpdatesState, setLastUpdatesState] = useState([]);
  const [lastUpdatesPageSize, setLastUpdatesPageSize] = useState(20);

  const {
    isLoading: isLastUpdatesLoading,
    data: lastUpdates,
    error: lastUpdatesError,
  } = useFetchLastUpdates(null, currentLastUpdatesPage, lastUpdatesPageSize);

  useEffect(() => {
    if(lastUpdates && lastUpdates.length > 0) {
      setLastUpdatesLastPage(Math.ceil(lastUpdates[1][0].count / lastUpdatesPageSize));
      setLastUpdatesState(oldData => [...oldData, ...lastUpdates[0]]);
    }
  }, [lastUpdates]);

  return (
    <div>
      <><div className="safes-header-section">
        <button className="bg-sidebar-background border border-outline py-2 px-3 rounded-md sm:w-max w-full flex justify-between items-center gap-x-1" style={showIndicators ? {border: "2px solid #3B82F6"}: {}} onClick={() => setShowIndicators(show => !show)}>
          <div>
            <Indicators fill={showIndicators ? "#3B82F6" : null}/>
          </div>
          <span className="text-sm text-body font-medium" style={showIndicators ? {color: "#3B82F6"} : {}}>{t("show_indicators")}</span>
        </button>
        <HeaderSectionData title={t("safes_balance")} balance={formattedCompanyBalance} cashin={formattedMonthlyCashin} expenses={formattedMonthlyExpenses} moneyRequests={formattedCurrentMoneyRequest} />
      </div>
      <div style={{transition: "height .5s ease-out", height: showIndicators ? "550px" : "0px", overflow:"hidden"}}>
        <Chart />
      </div>
      <div className="mx-4 my-3 flex justify-between">
        <Latest 
        title={t("pending_transactions")}
        icon={<Holdings />} 
        width="49%" 
        count={pendingCash && pendingCash.length > 0 ? pendingCash[1][0].count : 0} 
        amount={pendingCash && pendingCash.length > 0 ? new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP',  maximumSignificantDigits: 12}).format(pendingCash[1][0].total): 0} 
        currentPage={currentPendingCashPage} 
        lastPage={pendingCashLastPage} 
        setCurrentPage={setCurrentPendingCashPage} 
        isLoading={isPendingCashLoading}
        >
          {pendingCashState && pendingCashState.length > 0 ? pendingCashState.map(record => 
          <OnHoldRow data={record} />) : null}
        </Latest>

        <Latest 
          title={t("last_updates")} 
          icon={<LatestIcon />} 
          width="49%"
          count={lastUpdates && lastUpdates.length > 0 ? lastUpdates[1][0].count : 0} 
          currentPage={currentLastUpdatesPage} 
          lastPage={lastUpdatesLastPage} 
          setCurrentPage={setCurrentLastUpdatesPage} 
          isLoading={isLastUpdatesLoading}
        >
        {lastUpdatesState && lastUpdatesState.length > 0 ? lastUpdatesState.map(record => 
          <LastUpdatesRow data={record} />) : null}  
        </Latest>
      </div>

      {showIndicators ? <TotalSection /> : null}
  
      <div className="bg-white my-3">
        <div>
          <div className="mx-4 my-3">
            <div className="flex items-center justify-start safes_header">
            <ActiveMoneyRequestsIcon />
            <span className="mx-5">{t("running_money_request_data")}</span>
          </div>
          <ActiveMoneyRequests />
          </div>
        </div>
      </div></>

      <div className="bg-white">
        <div>
          <div className="mx-4 my-3">
            <div className="flex items-center justify-start safes_header">
            <SafesIcon />
            <span className="mx-5">{t("safes_list")}</span>
          </div>
          <Table
            rowKey="id"
            hasSelection={false}
            data={memoizedTableData}
            columns={columnsHeaderState.filter(obj => obj.selected)}
            isLoading={isTableDataLoading}
            error={tableDataError}
            pageSize={pageSize}
            currentPage={currentPage}
            total={tableData?.length}
            isPaginationDisabled={isPreviousData}
            onPageChange={onPageChange}
            onChange={handleTableChange}
            expandedRows={expandedRows}
            setExpandedRows={setExpandedRows}
            onTableRowExpand={onTableRowExpand} 
            expandedRow={() => <></>}
          />
          </div>
        </div>
      </div>

    </div>
  );
};

export default Safe;
