const formatdate = (
  date,
  // truncatedateiftoday = true,
  // truncatetimeifnottoday = true,
  // truncatedateifnottoday = false,
  // showweekday = false,
  // returnemptyfortoday = false,
  // truncatetimeiftoday = false
  truncatedateiftoday = true,
  truncatetimeiftoday = false,
  truncateweekdayiftoday = true,
  truncatedateifnottoday = false,
  truncatetimeifnottoday = true,
  truncateweekdayifnottoday = true
) => {
  let date1 = new Date(date);
  //let today = new Date();
  let date1notime = new Date(date);
  date1notime.setHours(0, 0, 0, 0);
  let todaynotime = new Date();
  todaynotime.setHours(0, 0, 0, 0);
  let requesttime = "";
  if (date1.getHours() < 12)
    //am
    requesttime =
      " ص " +
      date1.getHours().toString().padStart(2, 0) +
      ":" +
      date1.getMinutes().toString().padStart(2, 0);
  if (date1.getHours() >= 12) {
    //pm
    requesttime =
      " م " +
      (date1.getHours() > 12 ? date1.getHours() - 12 : date1.getHours())
        .toString()
        .padStart(2, 0) +
      ":" +
      date1.getMinutes().toString().padStart(2, 0);
  }
  let returndate = "";
  if (
    (!truncatetimeiftoday || date1notime - todaynotime !== 0) &&
    (!truncatetimeifnottoday || date1notime - todaynotime === 0)
  ) {
    //Include time
    returndate += requesttime;
  }
  if (
    (!truncatedateiftoday || date1notime - todaynotime !== 0) &&
    (!truncatedateifnottoday || date1notime - todaynotime === 0)
  )
    //Include date
    returndate +=
      date1.getDate().toString() +
      "/" +
      (date1.getMonth() + 1).toString() +
      "/" +
      date1.getFullYear().toString();
  if (
    (!truncateweekdayiftoday || date1notime - todaynotime !== 0) &&
    (!truncateweekdayifnottoday || date1notime - todaynotime === 0)
  )
    //Add week day
    returndate =
      " " +
      (date1.getDay() === 0
        ? "الأحد"
        : date1.getDay() === 1
        ? "الاثنين"
        : date1.getDay() === 2
        ? "الثلاثاء"
        : date1.getDay() === 3
        ? "الأربعاء"
        : date1.getDay() === 4
        ? "الخميس"
        : date1.getDay() === 5
        ? "الجمعة"
        : "السبت") +
      " - " +
      returndate;
  return returndate;
};
const getpassedtime = (date) => {
  let date1 = new Date(date);
  let today = new Date();
  let difference = Math.abs(today - date1); //difference in milliseconds
  let diff = Math.floor(difference / (1000 * 60 * 60 * 24 * 365.25)); //difference in years
  if (diff >= 1 && diff < 2)
    //for years
    return "منذ سنة";
  if (diff >= 2 && diff < 3)
    //for years
    return "منذ سنتين";
  if (diff >= 3 && diff <= 10)
    //for years
    return "منذ " + diff + " سنوات";
  if (diff >= 11)
    //for years
    return "منذ " + diff + " سنة";
  diff = today.getMonth() - date1.getMonth(); //difference in months
  diff = diff < 0 ? 12 + diff : diff; //In case months span two years
  let diffdays = today.getDate() - date1.getDate() < 0 ? -1 : 0; //Deduct a month if the day of todayis less than day of date
  diff = Math.floor(diff + diffdays);
  if (diff >= 1 && diff < 2)
    //for months
    return "منذ شهر";
  if (diff >= 2 && diff < 3)
    //for months
    return "منذ شهرين";
  if (diff >= 3 && diff <= 10)
    //for months
    return "منذ " + diff + " أشهر";
  if (diff >= 11)
    //for months
    return "منذ " + diff + " شهر";
  diff = Math.floor(difference / (1000 * 60 * 60 * 24)); //difference in days
  if (diff >= 1 && diff < 2)
    //for days
    return "منذ يوم واحد";
  if (diff >= 2 && diff < 3)
    //for days
    return "منذ يومين";
  if (diff >= 3 && diff <= 10)
    //for days
    return "منذ " + diff + " أيام";
  if (diff >= 11)
    //for days
    return "منذ " + diff + " يوم";
  diff = Math.floor(difference / (1000 * 60 * 60)); //difference in hours
  if (diff >= 1 && diff < 2)
    //for hours
    return "منذ ساعة واحدة";
  if (diff >= 2 && diff < 3)
    //for hours
    return "منذ ساعتين";
  if (diff >= 3 && diff <= 10)
    //for hours
    return "منذ " + diff + " ساعات";
  if (diff >= 11)
    //for hours
    return "منذ " + diff + " ساعة";
  diff = Math.floor(difference / (1000 * 60)); //difference in minutes
  if (diff < 1)
    //for minutes
    return "أقل من دقيقة واحدة";
  if (diff >= 1 && diff < 2)
    //for minutes
    return "منذ دقيقة واحدة";
  if (diff >= 2 && diff < 3)
    //for minutes
    return "منذ دقيقتين";
  if (diff >= 3 && diff <= 10)
    //for minutes
    return "منذ " + diff + " دقائق";
  if (diff >= 11)
    //for minutes
    return "منذ " + diff + " دقيقة";
  return diff;
};

const getDateMinuteDifference = (date) => {
  return (new Date().getTime() - new Date(date).getTime()) / 1000 / 60;
};

const dateMonth = (date) => {
  let date1 = new Date(date);
  const month = (date1.getMonth() + 1).toString();
  let months = [
    "يناير",
    "فبراير",
    "مارس",
    "ابريل",
    "مايو",
    "يونيه",
    "يوليو",
    "أغسطس",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
  ];
  let res = months[month - 1];
  return (
    date1.getDate().toString() +
    " " +
    res +
    " " +
    date1.getFullYear().toString()
  );
};

const forwardDate = (date) => {
  let date1 = new Date(date);
  const month = (date1.getMonth() + 1).toString();
  let months = [
    "يناير",
    "فبراير",
    "مارس",
    "ابريل",
    "مايو",
    "يونيه",
    "يوليو",
    "أغسطس",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
  ];

  return (
    new Date(date1).toLocaleString("ar-eg", { weekday: "long" }) +
    " - " +
    date1.getDate().toString() +
    " " +
    months[month - 1] +
    " " +
    date1.getFullYear().toString() +
    " - " +
    (date1.getHours() % 12 ? date1.getHours() % 12 : 12) +
    ":" +
    (date1.getMinutes() <= 9 ? "0" + date1.getMinutes() : date1.getMinutes()) +
    " " +
    (date1.getHours() > 11 ? "م" : "ص")
  );
};

const hoverDate = (date) => {
  let date1 = new Date(date);

  return (
    new Date(date1).toLocaleString("ar-eg", { weekday: "long" }) +
    " - " +
    (date1.getHours() % 12 ? date1.getHours() % 12 : 12) +
    ":" +
    (date1.getMinutes() <= 9 ? "0" + date1.getMinutes() : date1.getMinutes()) +
    " " +
    (date1.getHours() > 11 ? "م" : "ص")
  );
};

// 21 فبراير 10:00 ص
const displayDate = (date) => {
  let date1 = new Date(date);

  const month = (date1.getMonth() + 1).toString();
  let months = [
    "يناير",
    "فبراير",
    "مارس",
    "ابريل",
    "مايو",
    "يونيه",
    "يوليو",
    "أغسطس",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
  ];

  return (
    date1.getDate().toString() +
    " " +
    months[month - 1] +
    " " +
    (date1.getHours() % 12 ? date1.getHours() % 12 : 12) +
    ":" +
    (date1.getMinutes() <= 9 ? "0" + date1.getMinutes() : date1.getMinutes()) +
    " " +
    (date1.getHours() > 11 ? "م" : "ص")
  );
};

// الاثنين 6 يوليو. 10:00 ص
const notificationDate = (date) => {
  let date1 = new Date(date);

  const month = (date1.getMonth() + 1).toString();
  let months = [
    "يناير",
    "فبراير",
    "مارس",
    "ابريل",
    "مايو",
    "يونيه",
    "يوليو",
    "أغسطس",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
  ];

  return (
    new Date(date1).toLocaleString("ar-eg", { weekday: "long" }) +
    " " +
    date1.getDate().toString() +
    " " +
    months[month - 1] +
    ". " +
    (date1.getHours() % 12 ? date1.getHours() % 12 : 12) +
    ":" +
    (date1.getMinutes() <= 9 ? "0" + date1.getMinutes() : date1.getMinutes()) +
    " " +
    (date1.getHours() > 11 ? "م" : "ص")
  );
};

// الاثنين 6 يوليو. 10:00 ص
const getNotificationDate = (date) => {
  let date1 = new Date(date);
  let today = new Date();
  let difference = Math.abs(today - date1); //difference in milliseconds
  let diff = Math.floor(difference / (1000 * 60 * 60)); //difference in hours

  if (diff > 0 && diff < 24) return `${diff}h`;
  diff = Math.floor(difference / (1000 * 60)); //difference in minutes
  if (diff < 60) return `${diff}m`;

  const month = (date1.getMonth() + 1).toString();
  let months = [
    "يناير",
    "فبراير",
    "مارس",
    "ابريل",
    "مايو",
    "يونيه",
    "يوليو",
    "أغسطس",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
  ];

  return date1.getDate().toString() + " " + months[month - 1];
};

export {
  formatdate,
  getpassedtime,
  dateMonth,
  getDateMinuteDifference,
  forwardDate,
  hoverDate,
  displayDate,
  notificationDate,
  getNotificationDate,
};
