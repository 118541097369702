import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchParentMoneyRequest(moneyReqId) {
  return useQuery(
    ["parentMoneyRequest", moneyReqId],
    () =>
      apiClient
        .get(`document/${moneyReqId}/parent_money_request`)
        .then(({ data }) => data?.[0])
        .catch((e) => e),
    { enabled: moneyReqId ? true : false }
  );
}
