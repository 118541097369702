import { useState, useMemo, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Table from "../../components/common/Table";

import ExpandedRow from "../../components/documentations/ExpandedRow";
import useFetchUserData from "../../hooks/reactQuery/useFetchUserData";

import { columnsHeaderArr } from "./tableColumnsHeader";

import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
import useFetchSingleDocument from "../../hooks/reactQuery/useFetchSingleDocument";

import { useTranslation } from "react-i18next";


const SingleDocument = () => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();

  const params = useParams();
  const safeId = params.safeId;
  const documentId = params.id;

  const location = useLocation();

  const history = useHistory();

  const currentSafe = userData.safes.find(safe => safe.id == safeId && safe.active == 1);

  const {
    isLoading: isTableLoading,
    data: tableData,
    error: tableError,
  } = useFetchSingleDocument(
    documentId
  );

  const [columnsHeaderState, setColumnsHeader] = useState(() => columnsHeaderArr(null, t, i18n).filter(el => el.selected));
  const [selectedRows, setSelectedRows] = useState([]);


  useEffect(() => {
    console.log(tableData);
    if(tableData) setColumnsHeader(columnsHeaderArr(tableData[0], t, i18n).filter(el => el.selected));
  }, [tableData]);

  const memoizedTableData = useMemo(
    () => (tableData ? tableData : []),
    [tableData]
  );


  const [expandedRows, setExpandedRows] = useState([]);

  const onTableRowExpand = (expanded, record) => {
    var keys = [];
    if (expanded) {
      keys.push(record.id);
    }
    setExpandedRows(keys);
  };

  useEffect(() => {
    if(tableData) setExpandedRows([tableData[0].id]);
  }, [tableData]);

  return (
    <>
    {location.state && <Breadcrumb items={[{title: location.state.comingFrom ? location.state.comingFrom : "home", action: () => history.push(location.state.fallback ? location.state.fallback : "/home")}, {title: location.state.title, action: () => {}}]} />}
      
    <div className="mx-4 my-3">
      <Table
        rowKey="id"
        data={memoizedTableData}
        columns={columnsHeaderState}
        isLoading={isTableLoading}
        error={tableError}
        total={1}
        setSelectedRows={setSelectedRows}
        onTableRowExpand={onTableRowExpand}
        expandedRows={expandedRows}
        setExpandedRows={setExpandedRows}
        expandedRow={(row) => <ExpandedRow row={row}/> }
        isPaginationDisabled={true}
        pageSize={1}
        currentPage={1}
        hasFooter={false}
        hasSelection={false}
      />
    </div>
    </>
  );
};

export default SingleDocument;
