const UserIcon = ({ fillColor }) => {
  return (
    <div>
      <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 9C11.6569 9 13 7.65685 13 6C13 4.34315 11.6569 3 10 3C8.34315 3 7 4.34315 7 6C7 7.65685 8.34315 9 10 9Z"
          fill={fillColor ? fillColor : "#3B82F6"}
        />
        <path
          d="M3 18C3 14.134 6.13401 11 10 11C13.866 11 17 14.134 17 18H3Z"
          fill={fillColor ? fillColor : "#3B82F6"}
        />
      </svg>
    </div>
  );
};

export default UserIcon;
