const ReplyDate = () => {
  return (
    <div>
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 11H12C14.2091 11 16 12.7909 16 15V16M7 11L10 14M7 11L10 8"
          stroke="#94A3B8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 4.12891C3.46 4.64891 2 6.72891 2 10.9989V13.9989C2 18.9989 4 20.9989 9 20.9989H15C20 20.9989 22 18.9989 22 13.9989V10.9989C22 6.72891 20.54 4.64891 17 4.12891"
          stroke="#94A3B8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default ReplyDate;
