import React, { useState } from "react";

import { withRouter } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

import Input from "../../components/common/inputs/Input";
import TextArea from "../../components/common/inputs/Textarea";
import SelectMenu from "../../components/common/inputs/Select";
import ReactTagInput from "@pathofdev/react-tag-input";

import "./style.scss";
import { useMutation, useQuery } from "react-query";
import apiClient from "../../services/apiClient";
import { toast } from "react-toastify";
import Reaptcha from "reaptcha";
import Spinner from "../../components/common/Spinner";

const Register = ({ history }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm({
    mode: "onSubmit",
  });
  const [verified, setVerified] = React.useState(false);
  const onVerify = (e) => {
    setVerified(true);
  };

  const specializations = useQuery("specializations", () => {
    return apiClient.get(`/request_for_access/loadspecialization`);
  });

  const members = useQuery("members", () => {
    return apiClient.get("/request_for_access/loadmembers");
  });

  const responsibilities = useQuery("responsibilities", () => {
    return apiClient.get("/request_for_access/loadresponsibity");
  });

  const communicationTools = useQuery("communicationTools", () => {
    return apiClient.get("/request_for_access/loadcommunication");
  });

  const financialTools = useQuery("financialTools", () => {
    return apiClient.get("/request_for_access/loadfinancial");
  });

  const registerationMutation = useMutation(
    (body) => apiClient.post("/request_for_access/add", body),
    {
      onSuccess: (response) => {
        toast.info("تم التسجيل بنجاح", {
          autoClose: 3000,
        });
        history.replace("/home");
      },
    }
  );

  const isDataLoading = () => {
    return (
      specializations.isLoading ||
      members.isLoading ||
      responsibilities.isLoading ||
      communicationTools.isLoading ||
      financialTools.isLoading
    );
  };

  const handleRegister = (data) => {
    let body = new FormData();
    delete data["confirmPassword"];
    const companyCommunicationTools = [];
    const companyFinanceTools = [];
    communicationTools?.data?.data.forEach((item) => {
      if (data[item.name]) companyCommunicationTools.push(item.id);
      delete data[item.name];
    });
    financialTools?.data?.data.forEach((item) => {
      if (data[item.name]) companyFinanceTools.push(item.id);
      delete data[item.name];
    });
    data["companyCommunicationTools"] = companyCommunicationTools;
    data["companyFinanceTools"] = companyFinanceTools;
    data.companySpecialization = data.companySpecialization.value;
    data.companyResponsibility = data.companyResponsibility.value;
    data.companyMembers = data.companyMembers.value;
    if (!data.companyExtraCommunicationTools)
      data.companyExtraCommunicationTools = [];
    if (!data.companyExtraFinanceTools) data.companyExtraFinanceTools = [];

    body.append("request", JSON.stringify(data));
    registerationMutation.mutate(body);
  };

  const [communicationTags, setCommunicationTags] = useState([]);

  return (
    <form
      className="space-y-8 divide-y divide-gray-200 mx-auto px-5 max-w-screen-lg w-full"
      onSubmit={handleSubmit(handleRegister)}
    >
      <div className="space-y-8 divide-y divide-gray-200">
        <div className="pt-8">
          <div className="px-3 mb-3">
            <h3 className="text-2xl leading-6 font-medium text-gray-900">
              أهلا بك فى Trustbill ، يسعدنا قرارك بالانضمام الينا
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              نود أولا طرح بعض الأسئلة (2 دقيقة)
            </p>
          </div>

          <fieldset className="mt-10 w-full">
            <h5 className="text-xl leading-6 font-medium text-gray-900">
              المعلومات الشخصية
            </h5>
            <div className="mt-3 grid grid-cols-1 sm:grid-cols-6 gap-y-6 gap-x-4">
              <div className="sm:col-span-3">
                <Input
                  register={register}
                  type="text"
                  name="firstName"
                  fieldName="الاسم الأول"
                  label="الاسم الأول"
                  placeholder="الاسم الأول"
                  required={true}
                  maxLength="50"
                  errors={errors?.firstName}
                />
              </div>

              <div className="sm:col-span-3">
                <Input
                  register={register}
                  type="text"
                  name="lastName"
                  fieldName="الاسم الأخير"
                  label="الاسم الأخير"
                  placeholder="الاسم الأخير"
                  required={true}
                  maxLength="50"
                  errors={errors?.lastName}
                />
              </div>

              <div className="sm:col-span-4">
                <Input
                  register={register}
                  type="text"
                  name="username"
                  fieldName="اسم المستخدم"
                  label="اسم المستخدم"
                  placeholder="اسم المستخدم"
                  required={true}
                  maxLength="50"
                  pattern={{
                    regex: /^(?!.*\s)(?=.+)/,
                    errorMessage:
                      "* لا تستخدم مسافات اقتصر فقط على الحروف والأرقام والرموز",
                  }}
                  validate={{
                    validateRepetition: async (value) => {
                      const { data } = await apiClient.get(
                        "/request_for_access/checkifexists",
                        { params: { username: value } }
                      );
                      return !data.exists || "* اسم المستخدم موجود";
                    },
                  }}
                  errors={errors?.username}
                />
              </div>
              <div className="sm:col-span-3">
                <Input
                  register={register}
                  type="email"
                  name="email"
                  fieldName="البريد الإلكترونى"
                  label="البريد الإلكترونى"
                  placeholder="البريد الإلكترونى"
                  required={true}
                  maxLength="50"
                  pattern={{
                    regex:
                      /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
                    errorMessage: "بريد الكترونى غير صالح",
                  }}
                  validate={{
                    validateRepetition: async (value) => {
                      const { data } = await apiClient.get(
                        "/request_for_access/checkifexists",
                        { params: { email: value } }
                      );
                      return !data.exists || "* البريد الإلكترونى موجود";
                    },
                  }}
                  errors={errors?.email}
                />
              </div>
              <div className="sm:col-span-3">
                <Input
                  register={register}
                  type="number"
                  name="phoneNumber"
                  fieldName="رقم الهاتف"
                  label="رقم الهاتف"
                  placeholder="رقم الهاتف"
                  required={true}
                  maxLength="15"
                  errors={errors?.phoneNumber}
                />
              </div>

              <div className="sm:col-span-3">
                <Input
                  register={register}
                  type="password"
                  isPassword={true}
                  name="password"
                  fieldName="كلمة المرور"
                  label="كلمة المرور"
                  placeholder="كلمة المرور"
                  required={true}
                  minLength="8"
                  errors={errors?.password}
                />
              </div>

              <div className="sm:col-span-3">
                <Input
                  register={register}
                  type="password"
                  isPassword={true}
                  name="confirmPassword"
                  fieldName="تأكيد كلمة المرور"
                  label="تأكيد كلمة المرور"
                  placeholder="تأكيد كلمة المرور"
                  required={true}
                  validate={{
                    validateRepetition: (value) => {
                      return (
                        value === getValues("password") ||
                        "* كلمة السر غير متطابقة"
                      );
                    },
                  }}
                  minLength="8"
                  errors={errors?.confirmPassword}
                />
              </div>

              <div className="sm:col-span-6"></div>
              <div className="sm:col-span-6"></div>
            </div>
          </fieldset>

          <hr />

          <fieldset className="mt-10 w-full">
            <h5 className="text-xl leading-6 font-medium text-gray-900">
              معلومات الشركة
            </h5>
            <div className="mt-3 grid grid-cols-1 sm:grid-cols-6 gap-y-6 gap-x-4">
              <div className="sm:col-span-3">
                <Input
                  register={register}
                  type="text"
                  name="companyName"
                  fieldName="اسم الشركة"
                  label="اسم الشركة"
                  placeholder="اسم الشركة"
                  required={true}
                  maxLength="50"
                  errors={errors?.companyName}
                />
              </div>

              <div className="sm:col-span-3">
                <SelectMenu
                  name="companySpecialization"
                  fieldName="تخصص الشركة"
                  label="تخصص شركتك"
                  placeholder="تخصص شركتك"
                  options={specializations?.data?.data?.map((item) => {
                    return { value: item.id, label: item.name };
                  })}
                  Controller={Controller}
                  control={control}
                  required={true}
                  errors={errors?.companySpecialization}
                />
              </div>

              <div className="sm:col-span-3">
                <SelectMenu
                  name="companyResponsibility"
                  fieldName="مسئوليتك بالشركة"
                  label="مسئوليتك بالشركة"
                  placeholder="مسئوليتك بالشركة"
                  options={responsibilities?.data?.data.map((item) => {
                    return { label: item.name, value: item.id };
                  })}
                  Controller={Controller}
                  control={control}
                  required={true}
                  errors={errors?.companyResponsibility}
                />
              </div>

              
              <div className="sm:col-span-3">
                <SelectMenu
                  name="companyMembers"
                  fieldName="عدد أفراد الشركة"
                  label="عدد أفراد شركتك"
                  placeholder="عدد أفراد شركتك"
                  options={members?.data?.data.map((item) => {
                    return {
                      label:
                        item.min_number_of_members +
                        (item.max_number_of_members === null
                          ? "+"
                          : " - " + item.max_number_of_members),
                      value: item.id,
                      id: item.id,
                    };
                  })}
                  Controller={Controller}
                  control={control}
                  required={true}
                  errors={errors?.companyMembers}
                />
              </div>

              <div className="sm:col-span-6"></div>
              <div className="sm:col-span-6"></div>
            </div>
          </fieldset>

          <hr />

          <fieldset className="mt-10 mb-4 w-full">
            <h5 className="text-xl leading-6 font-medium text-gray-900">
              أدوات الشركة
            </h5>
            <p className="mt-1 text-sm text-gray-500">
              ما الأدوات التى تستخدمها للتواصل مع الأفراد فى شركتك؟
            </p>
            <div className="mt-3 grid grid-cols-1 sm:grid-cols-6 gap-y-1 gap-x-4">
              {communicationTools?.data?.data.map((tool) => (
                <div className="mt-3 sm:col-span-4">
                  <div className="relative flex items-start">
                    <div className="flex h-5">
                      <div className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded">
                        <Input
                          register={register}
                          type="checkbox"
                          required={false}
                          name={tool.name}
                          errors={errors && errors[tool.name]}
                        />
                      </div>
                    </div>
                    <div className="mr-3 text-sm">
                      <label
                        htmlFor={tool.name}
                        className="font-medium text-gray-700"
                      >
                        {tool.name}
                      </label>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </fieldset>

          <hr />

          <fieldset className="mt-4 w-full">
            <p className="mt-1 text-sm text-gray-500">
              ما الأدوات التى تستخدمها لإدارة الموارد المالية بالشركة؟
            </p>
            <div className="mt-3 grid grid-cols-1 sm:grid-cols-6 gap-y-1 gap-x-4">
              {financialTools?.data?.data.map((tool) => (
                <div className="mt-3 sm:col-span-4">
                  <div className="relative flex items-start">
                    <div className="flex h-5">
                      <div className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded">
                        <Input
                          register={register}
                          type="checkbox"
                          required={false}
                          name={tool.name}
                          errors={errors && errors[tool.name]}
                        />
                      </div>
                    </div>
                    <div className="mr-3 text-sm">
                      <label
                        htmlFor={tool.name}
                        className="font-medium text-gray-700"
                      >
                        {tool.name}
                      </label>
                    </div>
                  </div>
                </div>
              ))}
              <div className="sm:col-span-6"></div>
              <div className="sm:col-span-6"></div>
            </div>
          </fieldset>

          <div className="mt-2 grid grid-cols-1 sm:grid-cols-6 gap-y-6 gap-x-4">
            <div className="sm:col-span-6">
              <Controller
                name="companyExtraCommunicationTools"
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <ReactTagInput
                    className="add-tools"
                    tags={communicationTags}
                    name="companyExtraCommunicationTools"
                    placeholder="يمكنك اضافة أداة ثم Enter"
                    maxTags={15}
                    editable={true}
                    readOnly={false}
                    removeOnBackspace={true}
                    onChange={(newTags) => {
                      {
                        //   setFieldValue("companyExtraCommunicationTools", newTags);
                        setCommunicationTags(newTags);
                        onChange(newTags);
                      }
                    }}
                  />
                )}
              />
            </div>

            <div className="sm:col-span-6"></div>
            <div className="sm:col-span-6"></div>
          </div>

          <hr />

          <div className="mt-10">
            <h5 className="text-xl leading-6 font-medium text-gray-900">
              كيف يمكن ل Trustbill أن تساعدك؟
            </h5>
            <div className="mt-3">
              <TextArea
                register={register}
                name="tarbeetaHelp"
                fieldName="الاراء"
                placeholder="اكتب ما يخطر ببالك"
                required={false}
                maxLength="2000"
                errors={errors?.tarbeetaHelp}
              />
            </div>
          </div>
        </div>
      </div>
      <Reaptcha
        sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY}
        onVerify={onVerify}
      />
      <div className="py-5 bg-white">
        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <button
            type="submit"
            className={`sm:col-span-2 sm:col-start-3 bg-black py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white ${
              !verified ? "disabled" : ""
            }`}
            disabled={!verified}
          >
            إرسال
          </button>
        </div>
      </div>
    </form>
  );
};

export default withRouter(Register);
