import React from 'react'
import TableResult from '.'
import TimeOutIcon from '../../resources/icons/TimeOut'

import { useHistory } from 'react-router-dom'
const TimeOut = ({text}) => {
  const history = useHistory()
  return (
    <TableResult
      Icon={TimeOutIcon}
      title='أوبس'
      text={text?text:'يبدو ان النظام قد استغرق وقتا أطول من المعتاد لمعالجة طلبك.'}
      button={true}
      buttonText='عودة الي الرئيسية'
      action={() => {
        history.push('/payment-licenses')
      }}
    />
  )
}

export default TimeOut
