import PropTypes from "prop-types";

function Icon(props) {
  const { className, fillColor, ...restOfProps } = props;
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...restOfProps}
    >
      <path
        d="M6 4.3125C5.6925 4.3125 5.4375 4.0575 5.4375 3.75V1.5C5.4375 1.1925 5.6925 0.9375 6 0.9375C6.3075 0.9375 6.5625 1.1925 6.5625 1.5V3.75C6.5625 4.0575 6.3075 4.3125 6 4.3125Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
      <path
        d="M12 4.3125C11.6925 4.3125 11.4375 4.0575 11.4375 3.75V1.5C11.4375 1.1925 11.6925 0.9375 12 0.9375C12.3075 0.9375 12.5625 1.1925 12.5625 1.5V3.75C12.5625 4.0575 12.3075 4.3125 12 4.3125Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
      <path
        d="M6.375 10.8747C6.2775 10.8747 6.18 10.8598 6.09 10.8148C6 10.7773 5.91749 10.7247 5.84249 10.6572C5.70749 10.5147 5.625 10.3197 5.625 10.1247C5.625 10.0272 5.6475 9.92974 5.685 9.83974C5.7225 9.74974 5.77499 9.66725 5.84249 9.59225C5.91749 9.52475 6 9.47224 6.09 9.43474C6.36 9.32224 6.6975 9.38225 6.90751 9.59225C6.97501 9.66725 7.0275 9.74974 7.065 9.83974C7.1025 9.92974 7.125 10.0272 7.125 10.1247C7.125 10.3197 7.04251 10.5147 6.90751 10.6572C6.765 10.7922 6.57 10.8747 6.375 10.8747Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
      <path
        d="M9 10.8742C8.805 10.8742 8.60999 10.7917 8.46749 10.6567C8.39999 10.5817 8.3475 10.4992 8.31 10.4092C8.2725 10.3192 8.25 10.2217 8.25 10.1242C8.25 9.92917 8.33249 9.73418 8.46749 9.59168C8.67749 9.38168 9.0075 9.31416 9.285 9.43416C9.375 9.47166 9.4575 9.52418 9.53251 9.59168C9.66751 9.73418 9.75 9.92917 9.75 10.1242C9.75 10.2217 9.735 10.3192 9.69 10.4092C9.6525 10.4992 9.60001 10.5817 9.53251 10.6567C9.4575 10.7242 9.375 10.7767 9.285 10.8142C9.195 10.8592 9.0975 10.8742 9 10.8742Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
      <path
        d="M6.375 13.4992C6.2775 13.4992 6.18 13.4767 6.09 13.4392C6 13.4017 5.91749 13.3492 5.84249 13.2817C5.70749 13.1392 5.625 12.9517 5.625 12.7492C5.625 12.6517 5.6475 12.5542 5.685 12.4642C5.7225 12.3667 5.77499 12.2917 5.84249 12.2167C5.91749 12.1492 6 12.0967 6.09 12.0592C6.36 11.9392 6.6975 12.0067 6.90751 12.2167C6.97501 12.2917 7.0275 12.3667 7.065 12.4642C7.1025 12.5542 7.125 12.6517 7.125 12.7492C7.125 12.9517 7.04251 13.1392 6.90751 13.2817C6.765 13.4167 6.57 13.4992 6.375 13.4992Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
      <path
        d="M15.375 7.38086H2.625C2.3175 7.38086 2.0625 7.12586 2.0625 6.81836C2.0625 6.51086 2.3175 6.25586 2.625 6.25586H15.375C15.6825 6.25586 15.9375 6.51086 15.9375 6.81836C15.9375 7.12586 15.6825 7.38086 15.375 7.38086Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
      <path
        d="M13.5 17.8125C11.535 17.8125 9.9375 16.215 9.9375 14.25C9.9375 12.285 11.535 10.6875 13.5 10.6875C15.465 10.6875 17.0625 12.285 17.0625 14.25C17.0625 16.215 15.465 17.8125 13.5 17.8125ZM13.5 11.8125C12.1575 11.8125 11.0625 12.9075 11.0625 14.25C11.0625 15.5925 12.1575 16.6875 13.5 16.6875C14.8425 16.6875 15.9375 15.5925 15.9375 14.25C15.9375 12.9075 14.8425 11.8125 13.5 11.8125Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
      <path
        d="M14.6175 14.8496H12.375C12.0675 14.8496 11.8125 14.5946 11.8125 14.2871C11.8125 13.9796 12.0675 13.7246 12.375 13.7246H14.6175C14.925 13.7246 15.18 13.9796 15.18 14.2871C15.18 14.5946 14.9325 14.8496 14.6175 14.8496Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
      <path
        d="M13.5 15.9984C13.1925 15.9984 12.9375 15.7434 12.9375 15.4359V13.1934C12.9375 12.8859 13.1925 12.6309 13.5 12.6309C13.8075 12.6309 14.0625 12.8859 14.0625 13.1934V15.4359C14.0625 15.7434 13.8075 15.9984 13.5 15.9984Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
      <path
        d="M11.5275 17.0625H6C3.2625 17.0625 1.6875 15.4875 1.6875 12.75V6.375C1.6875 3.6375 3.2625 2.0625 6 2.0625H12C14.7375 2.0625 16.3125 3.6375 16.3125 6.375V12.27C16.3125 12.5025 16.17 12.7125 15.945 12.795C15.7275 12.8775 15.48 12.8175 15.3225 12.6375C14.8575 12.1125 14.19 11.8125 13.4925 11.8125C12.15 11.8125 11.055 12.9075 11.055 14.25C11.055 14.6925 11.175 15.1275 11.4075 15.5025C11.535 15.7275 11.7 15.915 11.88 16.0725C12.06 16.2225 12.1275 16.47 12.045 16.695C11.9775 16.9125 11.7675 17.0625 11.5275 17.0625ZM6 3.1875C3.855 3.1875 2.8125 4.23 2.8125 6.375V12.75C2.8125 14.895 3.855 15.9375 6 15.9375H10.365C10.0875 15.4275 9.9375 14.85 9.9375 14.25C9.9375 12.285 11.535 10.6875 13.5 10.6875C14.0925 10.6875 14.6775 10.8375 15.1875 11.115V6.375C15.1875 4.23 14.145 3.1875 12 3.1875H6Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
    </svg>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
};

export default Icon;
