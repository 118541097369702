import PropTypes from "prop-types";

function Icon(props) {
  const { className, fillColor, ...restOfProps } = props;
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...restOfProps}
    >
      <rect width={20} height={20} fill="white" />
      <path
        d="M4.16683 19.7917C2.7835 19.7917 1.48349 19.0583 0.783495 17.8667C0.408495 17.2667 0.208496 16.5583 0.208496 15.8333C0.208496 13.65 1.9835 11.875 4.16683 11.875C6.35016 11.875 8.12516 13.65 8.12516 15.8333C8.12516 16.5583 7.92516 17.2667 7.55016 17.875C6.85016 19.0583 5.55016 19.7917 4.16683 19.7917ZM4.16683 13.125C2.67516 13.125 1.4585 14.3417 1.4585 15.8333C1.4585 16.325 1.59183 16.8083 1.85016 17.225C2.34183 18.0583 3.2085 18.5417 4.16683 18.5417C5.12516 18.5417 5.99183 18.05 6.48349 17.2333C6.74183 16.8083 6.87516 16.3333 6.87516 15.8333C6.87516 14.3417 5.6585 13.125 4.16683 13.125Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
      <path
        d="M3.69157 17.2826C3.53324 17.2826 3.3749 17.2243 3.2499 17.0993L2.4249 16.2743C2.18324 16.0327 2.18324 15.6326 2.4249 15.391C2.66657 15.1493 3.06657 15.1493 3.30824 15.391L3.70824 15.791L5.04157 14.5576C5.29157 14.3243 5.69157 14.341 5.9249 14.591C6.15824 14.841 6.14157 15.241 5.89157 15.4743L4.11657 17.116C3.99157 17.2243 3.84157 17.2826 3.69157 17.2826Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
      <path
        d="M5.15853 6.50756C4.95853 6.50756 4.7752 6.41589 4.6502 6.24923C4.50854 6.05756 4.49187 5.80754 4.6002 5.5992C4.74187 5.31587 4.94187 5.04089 5.2002 4.79089L7.90854 2.07422C9.29187 0.699219 11.5419 0.699219 12.9252 2.07422L14.3835 3.55758C15.0002 4.16591 15.3752 4.98258 15.4169 5.84924C15.4252 6.04091 15.3502 6.22422 15.2085 6.34922C15.0669 6.47422 14.8752 6.53255 14.6919 6.49922C14.5252 6.47422 14.3502 6.4659 14.1669 6.4659H5.83353C5.63353 6.4659 5.44187 6.48256 5.2502 6.50756C5.2252 6.50756 5.19187 6.50756 5.15853 6.50756ZM6.5502 5.20756H14.0169C13.9085 4.92423 13.7335 4.6659 13.5002 4.43257L12.0335 2.94921C11.1419 2.06588 9.68354 2.06588 8.78354 2.94921L6.5502 5.20756Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
      <path
        d="M14.1666 18.959H6.35824C6.09157 18.959 5.85823 18.7923 5.76657 18.5507C5.6749 18.3007 5.7499 18.0256 5.9499 17.859C6.1499 17.6923 6.33323 17.4757 6.46657 17.2423C6.73323 16.8173 6.86657 16.334 6.86657 15.8423C6.86657 14.3507 5.6499 13.134 4.15824 13.134C3.38324 13.134 2.64157 13.4673 2.1249 14.059C1.9499 14.2506 1.6749 14.3257 1.43324 14.234C1.19157 14.1423 1.0249 13.909 1.0249 13.6507V10.0007C1.0249 7.43398 2.60824 5.57566 5.06657 5.26732C5.29157 5.23399 5.5499 5.20898 5.81657 5.20898H14.1499C14.3499 5.20898 14.6082 5.21732 14.8749 5.25898C17.3332 5.54232 18.9416 7.40898 18.9416 10.0007V14.1673C18.9582 17.034 17.0332 18.959 14.1666 18.959ZM7.6499 17.709H14.1666C16.3166 17.709 17.7082 16.3173 17.7082 14.1673V10.0007C17.7082 8.05065 16.5666 6.70897 14.7166 6.4923C14.5166 6.45897 14.3416 6.45898 14.1666 6.45898H5.83324C5.63324 6.45898 5.44157 6.47564 5.2499 6.50064C3.41657 6.73397 2.29157 8.06732 2.29157 10.0007V12.3507C2.85824 12.0423 3.50824 11.8757 4.16657 11.8757C6.3499 11.8757 8.1249 13.6507 8.1249 15.834C8.1249 16.4923 7.95824 17.1423 7.6499 17.709Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
      <path
        d="M18.3332 14.3743H15.8332C14.5665 14.3743 13.5415 13.3493 13.5415 12.0827C13.5415 10.816 14.5665 9.79102 15.8332 9.79102H18.3332C18.6748 9.79102 18.9582 10.0743 18.9582 10.416C18.9582 10.7577 18.6748 11.041 18.3332 11.041H15.8332C15.2582 11.041 14.7915 11.5077 14.7915 12.0827C14.7915 12.6577 15.2582 13.1243 15.8332 13.1243H18.3332C18.6748 13.1243 18.9582 13.4077 18.9582 13.7493C18.9582 14.091 18.6748 14.3743 18.3332 14.3743Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
    </svg>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
};

export default Icon;
