import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchPayments({
  activeTab,
  currentPage,
  pageSize,
  order,
  columnKey,
  searchType,
  searchTerms,
  users,
  categories,
  isapproved,
  fromAmount,
  toAmount,
  fromSendDate,
  toSendDate,
  fromMaxResponseDate,
  toMaxResponseDate,
  fromResponseDate,
  toResponseDate,
}) {
  return useQuery(
    [
      "getPayments",
      activeTab,
      currentPage,
      pageSize,
      order,
      columnKey,
      searchType,
      searchTerms,
      users,
      categories,
      isapproved,
      fromAmount,
      toAmount,
      fromSendDate,
      toSendDate,
      fromMaxResponseDate,
      toMaxResponseDate,
      fromResponseDate,
      toResponseDate,
    ],
    () => {
      const params = {};
      params.pageno = currentPage;
      params.pagesize = pageSize;

      if (order && columnKey) {
        params.isascending =
          order === "ascend" ? 1 : order === "descend" ? 0 : null;

        switch (true) {
          case columnKey === "request_date" ||
            columnKey === "response_time" ||
            columnKey === "created_at":
            params.orderbycolumn = "latest_date";
            break;

          case columnKey === "category_name":
            params.orderbycolumn = "category_id";
            break;

          case columnKey === "sender_name":
            params.orderbycolumn = "created_by";
            break;

          case columnKey === "recipient_name":
            params.orderbycolumn = "recipient_id";
            break;

          default:
            params.orderbycolumn = columnKey;
            break;
        }
      }

      if (searchType !== "" && searchTerms.length > 0) {
        params.search_type = searchType;
        params.search_terms = JSON.stringify(searchTerms);
      }

      if (users.length > 0) {
        params.users = JSON.stringify(users);
      }
      if (categories.length > 0) {
        params.categories = JSON.stringify(categories);
      }
      if (isapproved.length > 0) {
        params.isapproved = JSON.stringify(isapproved);
      }
      if (fromAmount) {
        params.fromAmount = fromAmount;
      }
      if (toAmount) {
        params.toAmount = toAmount;
      }
      if (fromSendDate) {
        params.fromSendDate = fromSendDate;
      }
      if (toSendDate) {
        params.toSendDate = toSendDate;
      }
      if (fromMaxResponseDate) {
        params.fromMaxResponseDate = fromMaxResponseDate;
      }
      if (toMaxResponseDate) {
        params.toMaxResponseDate = toMaxResponseDate;
      }
      if (fromResponseDate) {
        params.fromResponseDate = fromResponseDate;
      }
      if (toResponseDate) {
        params.toResponseDate = toResponseDate;
      }

      return apiClient
        .get(
          `spend_request/${
            activeTab === 1 ? "loadallreceived" : "loadallactivesent"
          }`,
          {
            params,
          }
        )
        .then(({ data }) => data);
    }
    // { keepPreviousData: true }
  );
}
