import { useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { useTranslation } from "react-i18next";


const SIDEBAR_NAVBAR_VISIBLE_ROUTES = [
  "/payment-licenses",
  "/dashboard",
  "/documentations",
  "/money-requests",
  "/moneyRequestDetails",
  "/helicopter",
  "/safe",
  "/safe-withdraw",
  "/safe-deposit",
  "/archive",
  "/settings",
  "/document",
  "/payment-license",
  "/contact-us",
  "/not-found",
  "/pending-requests",
  "/balance"
];

const Layout = ({ children }) => {
  const { pathname } = useLocation();

  const {t, i18n} = useTranslation();

  const hasSideAndNav = SIDEBAR_NAVBAR_VISIBLE_ROUTES.includes(pathname) 
  || /\/safe\/[0-9]?/.test(pathname)
  || /\/cashin\/[0-9]?/.test(pathname)
  || /\/money_request\/[0-9]?/.test(pathname)
  || /\/spend_request\/[0-9]?/.test(pathname)
  || /\/document\/[0-9]?/.test(pathname);

  return (
    <div className="flex" dir={i18n.resolvedLanguage === "ar" ? "rtl" : "ltr"}>
      {hasSideAndNav && document.cookie.includes("Bearer") && (
        <div>
          <Sidebar />
        </div>
      )}
      <div className="grid grid-cols-1 w-full">
        <div className="col-span-1">
          <div>{hasSideAndNav && <Header />}</div>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
