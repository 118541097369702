import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchMoneyReqs(moneyReqId) {
  return useQuery(["getMoneyReq", moneyReqId], () =>
    apiClient
      ?.get(`money_request/${moneyReqId}/children`, {
        params: {
          id: moneyReqId,
        },
      })
      .then((res) => {
        res?.data[0]?.forEach((element) => {
          element.newId = `${element.id}M`;
        });
        return res?.data[0];
      })
      .catch((e) => e)
  );
}
