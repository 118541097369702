import TableSortIcon from "../../../resources/icons/TableSortIcon";


export const ColumnsHeader = (total_amount, total_remaining_amount, total_document, safeId, t, i18n) => [
  {
    subject: "المتعهد",
    title: () => (
      <div class="flex items-center justify-center">
        <div style={{fontSize: "12px", fontWeight: "500", margin: "0px 3px"}}>{t("debtor")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "recipient_name",
    key: "username",
    sorter: true,
    render: (text, row) => (
      row.recipient_id ? <div className="flex items-center justify-center" style={{color: "#94A3B8", fontSize: "12px", fontWeight: "400", opacity: row.deactivated ? 0.4 : 1}}>
        <img src={row.profile_picture_url} style={{width: "20px", height: "20px", borderRadius: "50%", margin: "0px 3px"}} />
        <span title={text}>{text.length <= 11 ? text : `..${text.slice(0, 11)}`}</span>
      </div> : <div className="flex items-center justify-center" style={{color: "#94A3B8", fontSize: "12px", fontWeight: "400", opacity: row.deactivated ? 0.4 : 1}}>
        <img src={row.creator_profile_picture_url} style={{width: "20px", height: "20px", borderRadius: "50%", margin: "0px 3px"}} />
        <span title={row.creator_name}>{row.creator_name.length <= 11 ? row.creator_name : `..${row.creator_name.slice(0, 11)}`}</span>
      </div>
    ),
    selected: true,
    width: 150,
  },
  {
    subject: "اسم الخزينة",
    title: () => (
      <div class="flex items-center justify-center">
        <div style={{fontSize: "12px", fontWeight: "500", margin: "0px 3px"}}>{t("safe_name")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "safe_name",
    key: "safename",
    sorter: true,
    render: (text, row) => (
      <div className="flex items-center justify-center" style={row.deactivated ? {opacity: 0.4} : {}}>
        <span style={{backgroundColor: "#F8FAFC", borderRadius: "10px", padding: "2px 10px", color: "#94A3B8", fontSize: "12px", fontWeight: "500"}}>
          {text}
        </span>
      </div>
    ),
    width: 150,
    selected: !safeId ? true : false
  },
  {
    subject: "تصنيف العهدة",
    title: () => (
      <div class="flex items-center justify-center">
        <div style={{fontSize: "12px", fontWeight: "500", margin: "0px 3px"}}>{t("category_name")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "category_name",
    key: "category",
    sorter: true,
    render: (text, row) => (
      <div className="flex items-center justify-center" style={row.deactivated ? {opacity: 0.4} : {}}>
        <span style={{backgroundColor: "#F8FAFC", borderRadius: "10px", padding: "2px 10px", color: "#94A3B8", fontSize: "12px", fontWeight: "500"}}>
          {text}
        </span>
      </div>
    ),
    width: 150,
    selected: true
  },
  {
    subject: "القيمة الاصلية",
    title: () => (
      <div class="flex justify-center items-center">
        <div className="flex items-center justify-center">
          <span style={{fontSize: "12px", fontWeight: "500"}}>{t("original_amount")}</span> 
          <span style={{color: "#3F84F6", margin: "0px 5px"}}>{`(${total_amount && new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(total_amount)})`}</span>
        </div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "amount",
    key: "amount",
    sorter: true,
    render: (text, row) => (
      <div className="flex items-center justify-center" style={row.deactivated ? {opacity: 0.4} : {}}>
        <span style={{backgroundColor: "#E7F0FF", borderRadius: "10px", padding: "2px 10px", color: "#59A0FF", fontSize: "12px", fontWeight: "500"}}>
          {new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(text)}
        </span>
      </div>
    ),
    width: 250,
    selected: true
  },
  {
    subject: "قيمة المصروفات",
    title: () => (
      <div class="flex items-center justify-center">
        <div className="flex items-center justify-center">
          <span style={{fontSize: "12px", fontWeight: "500"}}>{t("documents_amount")}</span> 
          <span style={{color: "#FF805C", margin: "0px 5px"}}>{`(${total_document && new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(total_document)})`}</span>
        </div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "document_amount",
    key: "document",
    sorter: true,
    render: (text, row) => (
      <div className="flex items-center justify-center" style={row.deactivated ? {opacity: 0.4} : {}}>
        <span style={{backgroundColor: "#FFD4D4", borderRadius: "10px", padding: "2px 10px", color: "#FF805C", fontSize: "12px", fontWeight: "500"}}>
          {new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(text)}
        </span>
      </div>
    ),
    selected: true,
    width: 250,
  },
  {
    subject: "القيمة الحالية",
    title: () => (
      <div class="flex items-center justify-center">
        <div className="flex items-center justify-center">
          <span style={{fontSize: "12px", fontWeight: "500"}}>{t("current_amount")}</span> 
          <span style={{color: "#FFCD86", margin: "0px 5px"}}>{`(${total_remaining_amount && new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(total_remaining_amount)} EGP)`}</span>
        </div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "remaining_amount",
    key: "remainingAmount",
    sorter: true,
    render: (text, row) => (
      <div className="flex items-center justify-center" style={row.deactivated ? {opacity: 0.4} : {}}>
        <span style={{backgroundColor: "#FFF1D5", borderRadius: "10px", padding: "2px 10px", color: "#FFCD86", fontSize: "12px", fontWeight: "500"}}>
          {new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(text)}
        </span>
      </div>
    ),
    selected: true,
    width: 250,
  }
];
