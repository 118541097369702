import { toolPin } from "../../utils/toolPin";
import TableSortIcon from "../../components/resources/icons/TableSortIcon";
import KeyIcon from "../../components/resources/icons/KeyIcon";
import CashAmountIcon from "../../components/resources/icons/CashAmountIcon";
import CategoryIcon from "../../components/resources/icons/CategoryIcon";
import MainFinancial from "../../components/resources/icons/MainFinancial";
import Receipt from "../../components/resources/icons/Receipt";
import InputDate from "../../components/resources/icons/InputDate";
import SafesIcon from "../../components/resources/icons/SafesIcon";

export const columnsHeaderArr = (data, t, i18n) => [
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <KeyIcon />
        </div>
        <div className="col-title">{t("transaction_code")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "id",
    key: "id",
    sorter: true,
    render: (text) => <div className="col-desc flex justify-start">{toolPin(String(text))}</div>,
    width: 100,
    pinned: true,
    selected: true,
    customizeKey: t("transaction_code"),
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <CashAmountIcon />
        </div>
        <div className="col-title">{t("amount")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "amount",
    key: "amount",
    sorter: true,
    render: (text) => <div className="col-desc">{toolPin(String(text))}</div>,
    width: 100,
    selected: true,
    customizeKey: t("amount"),
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <CategoryIcon />
        </div>
        <div className="col-title">{t("category")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "category",
    key: "category",
    sorter: true,
    render: (text) => (
      <div className="text-sm text-body font-medium bg-sidebar-background p-2 rounded-xl flex justify-center items-center border border-transparent hover:border-secondary">
        {toolPin(text)}
      </div>
    ),
    width: 150,
    selected: true,
    customizeKey: t("category"),
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <MainFinancial />
        </div>
        <div className="col-title">{t("parent_money_request")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "parent_name",
    key: "parent_name",
    sorter: true,
    render: (text) => (
      <div
        role="button"
        className="text-sm text-body font-medium bg-sidebar-background p-2 rounded-xl flex justify-center items-center"
      >
        {toolPin(text)}
      </div>
    ),
    width: 150,
    selected: data?.parent_name ? true : false,
    customizeKey: t("parent_money_request"),
  },
  {
    subject: "اسم الخزينة",
    title: () => (
      <div class="flex items-center justify-center">
        <SafesIcon fillColor={"#292D32"}/>
        <div style={{fontSize: "12px", fontWeight: "500", margin: "0px 3px"}}>{t("safe_name")}</div>
      </div>
    ),
    dataIndex: "safe_name",
    key: "safename",
    sorter: true,
    render: (text, row) => (
      <div className="flex items-center justify-center">
        <span style={{color: "#94A3B8", fontSize: "12px", fontWeight: "500"}}>
          {text}
        </span>
      </div>
    ),
    width: 150,
    selected: data?.safe_name ? true : false
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <Receipt />
        </div>
        <div className="col-title">{t("document_type")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "periodic",
    key: "periodic",
    sorter: true,
    render: (text) => (
      <div className="col-desc flex justify-center">
        {toolPin(text === 1 ? t("periodic") : t("rare"))}
      </div>
    ),
    width: 150,
    pinned: true,
    selected: true,
    customizeKey: t("document_type"),
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <InputDate />
        </div>
        <div className="col-title">{t("spend_date")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "payment_date",
    key: "payment_date",
    sorter: true,
    render: (text) => (
      <div className="col-desc flex justify-center">{toolPin(text.split("T")[0])}</div>
    ),
    width: 100,
    selected: true,
    customizeKey: t("spend_date"),
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <InputDate />
        </div>
        <div className="col-title">{t("documentation_date")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "creation_date",
    key: "creation_date",
    sorter: true,
    render: (text) => (
      <div className="col-desc">{toolPin(text.split("T")[0])}</div>
    ),
    width: 100,
    selected: true,
    customizeKey: t("creation_date"),
  },
];
