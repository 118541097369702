import { useState, useEffect, useReducer, useMemo, useRef } from "react";
import { useHistory, useLocation } from "react-router";
import Table from "../../components/common/Table";
import { columnsHeaderArray } from "./tableColumnsHeader";
import { useQueryClient } from "react-query";
import useFetchUserData from "../../hooks/reactQuery/useFetchUserData";
import useFetchBalanceDetails from "../../hooks/reactQuery/useFetchBalanceDetails";
import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
import BalanceDetailsExpandedRow from "./ExpandedRow/index";

import "./balanceDetails.scss";

const BalanceDetails = ({}) => {
  const { data: userData } = useFetchUserData();

  const history = useHistory();
  const pageSize = process.env.REACT_APP_PAGE_SIZE;
  const [currentPage, setCurrentPage] = useState(1);
  const [columnKey, setColumnKey] = useState("");
  const [order, setOrder] = useState("");

  const location = useLocation();
  const expandedDocId = useRef(location.expandedDocId);
  const [columnsHeader, setColumnsHeader] = useState(columnsHeaderArray);

  const {
    isLoading: isTableDataLoading,
    data: tableData,
    error: tableDataError,
    isPreviousData,
  } = useFetchBalanceDetails({
    user_id: userData.id,
    currentPage,
    pageSize,
    order,
    columnKey,
  });

  const memoizedTableData = useMemo(
    () => (tableData ? tableData[0] : []),
    [tableData]
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [order, columnKey]);


  const handleTableChange = (pagination, filters, sorter) => {
    setOrder(sorter?.order);
    setColumnKey(sorter?.columnKey);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const queryClient = useQueryClient();

  const [expandedRows, setExpandedRows] = useState([]);

  const onTableRowExpand = (expanded, record) => {
    var keys = [];
    if (expanded) {
      keys.push(record.id);
    }
    setExpandedRows(keys);
  };

  return (
    <>
      <div>
        <Breadcrumb items={[{title: "رصيد الحساب الحالي", action: () => history.push("/")}, {title: `تفاصيل الرصيد - العهد الجارية`, action: () => {}, active: true}]} />
        <div className="flex justify-between items-center mx-4 my-3">
          <div className="balance-details-card blue-border">
            <div className="flex justify-between items-center">
              <span className="balance-details-card-text">اجمالي القيمة الاصلية</span>
              <span className="balance-details-card-text" style={{fontWeight: "500"}}>{tableData && tableData[1].length > 0 ? tableData[1][0].overall_count : 0} عهدة</span>
            </div>
            <span className="balance-details-card-num" style={{color: "#59A0FF"}}>{new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 9}).format(tableData && tableData[1].length > 0 ? parseInt(tableData[1][0].total_amount) : 0).toString().replace(/EGP/, "") + " "}  ج.م </span>
          </div>
          <div className="balance-details-card red-border">            
            <div className="flex justify-between items-center">
              <span className="balance-details-card-text">قيمة المصروفات</span>
              <span className="balance-details-card-text" style={{fontWeight: "500"}}>{tableData && tableData[1].length > 0 ? tableData[1][0].total_documents ? tableData[1][0].total_documents : 0 : 0} مصروف</span>
            </div>
            <span className="balance-details-card-num" style={{color: "#FF5858"}}>{new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 9}).format(tableData && tableData[1].length > 0 ? tableData[1][0].total_documents_amount ? parseInt(tableData[1][0].total_documents_amount) : 0 : 0).toString().replace(/EGP/, "") + " "}  ج.م </span>            
          </div>
          <div className="balance-details-card orange-border">
            <div className="flex justify-between items-center">
              <span className="balance-details-card-text">اجمالي القيمة الحالية</span>
            </div>
            <span className="balance-details-card-num" style={{color: "#FFB972"}}>{new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 9}).format(tableData && tableData[1].length > 0 ? parseInt(tableData[1][0].total_remaining_amount) : 0).toString().replace(/EGP/, "") + " "}  ج.م </span>
          </div>
        </div>
        <div className="mx-4 my-3">
          <Table
            rowKey="id"
            data={memoizedTableData}
            columns={columnsHeader}
            isLoading={isTableDataLoading}
            error={tableDataError}
            pageSize={pageSize}
            currentPage={currentPage}
            total={tableData && tableData[1] && tableData[1][0]?.overall_count}
            onChange={handleTableChange}
            onPageChange={onPageChange}
            expandedRows={expandedRows}
            setExpandedRows={setExpandedRows}
            onTableRowExpand={onTableRowExpand}
            hasSelection={false}
            expandedRow={(row) => (
              <BalanceDetailsExpandedRow row={row} />
            )}
          />
        </div>
      </div>
    </>
  );
};

export default BalanceDetails;
