const SubTitle = ({}) => {
  return (
    <div>
      <svg
        width={19}
        height={18}
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.80273 16.5H11.3027C15.0527 16.5 16.5527 15 16.5527 11.25V6.75C16.5527 3 15.0527 1.5 11.3027 1.5H6.80273C3.05273 1.5 1.55273 3 1.55273 6.75V11.25C1.55273 15 3.05273 16.5 6.80273 16.5Z"
          stroke="#1E293B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.1785 12.8086H11.791"
          stroke="#1E293B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.78024 12.8086H4.92773"
          stroke="#1E293B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.1768 9.98828H9.0293"
          stroke="#1E293B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.00523 9.98828H4.92773"
          stroke="#1E293B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default SubTitle;
