const TimeTag = () => {
  return (
    <div>
      <svg
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 6V9.75"
          stroke="#1E293B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 16.5C5.3775 16.5 2.4375 13.56 2.4375 9.9375C2.4375 6.315 5.3775 3.375 9 3.375C12.6225 3.375 15.5625 6.315 15.5625 9.9375"
          stroke="#1E293B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.75 1.5H11.25"
          stroke="#1E293B"
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.175 13.8751V13.0051C11.175 11.9326 11.94 11.4901 12.87 12.0301L13.62 12.4651L14.37 12.9001C15.3 13.4401 15.3 14.3176 14.37 14.8576L13.62 15.2926L12.87 15.7276C11.94 16.2676 11.175 15.8251 11.175 14.7526V13.8751Z"
          stroke="#1E293B"
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default TimeTag;
