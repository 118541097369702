import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useMutateCompany(activeCompanyId) {
  const queryClint = useQueryClient();

  return useMutation(
    (data) => {
      const formData = new FormData();
      formData.append("name", data.companyName);
      formData.append("en_name", data.en_name);
      formData.append("specialization", data.companySpecialization.value);
      formData.append("responsibility", data.responsibityOptions.value);
      formData.append("members", data.companyMembers.value);
      data.file && formData.append("companyPhoto", data.file);

      return apiClient.patch(`company/${activeCompanyId}`, formData);
    },
    {
      onSuccess: () => {
        toast.info("تم تعديل معلومات الشركة.", { autoClose: 3000 });
        queryClint.invalidateQueries("userData");
      },
      onError: (error) => error,
    }
  );
}
