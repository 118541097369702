import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchCompanyBalance(safeId) {
  return useQuery(["companyBalance", safeId], () => {
    const params = {};
    if(safeId) params.safe_id = safeId;

    return apiClient.get("/company/balance", {params}).then((response) => {
      return response.data.balance;
    });
  });
}
