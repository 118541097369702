import { toolPin } from "../../../../../utils/toolPin";

import UsersAvatars from "../../../../../components/common/UsersAvatars.jsx";

import TableSortIcon from "../../../../../components/resources/icons/TableSortIcon";
import KeyIcon from "../../../../../components/resources/icons/KeyIcon";
import CashAmountIcon from "../../../../../components/resources/icons/CashAmountIcon";
import UserAlt from "../../../../../components/resources/icons/UserAlt";
import CategoryIcon from "../../../../../components/resources/icons/CategoryIcon";
import InputDate from "../../../../../components/resources/icons/InputDate";
import Health from "../../../../../components/resources/icons/Health";

export const columnsHeader = (t, i18n) => [
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <KeyIcon />
        </div>
        <div className="col-title">{t("transaction_code")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "id",
    key: "id",
    sorter: true,
    render: (text) => <div className="col-desc flex justify-center">{toolPin(String(text))}</div>,
    width: 100,
    customizeKey: t("transaction_code"),
    selected: true
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <CashAmountIcon />
        </div>
        <div className="col-title">{t("amount")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "amount",
    key: "amount",
    sorter: true,
    render: (text) => <div className="col-desc flex justify-center">     
    {new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(parseInt(text)).toString().replace(/EGP/, "") + " "}
    ج.م</div>,
    width: 100,
    customizeKey: t("amount"),
    selected: true
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <UserAlt />
        </div>
        <div className="col-title">{t("recipient")}</div>
      </div>
    ),
    dataIndex: "recievers",
    key: "recievers",
    render: (text) => (
      <div className="col-desc flex justify-center">
        <UsersAvatars usersArr={text} />
      </div>
    ),
    width: 100,
    customizeKey: t("recipient"),
    selected: true
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <CategoryIcon />
        </div>
        <div className="col-title">{t("category")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "category_name",
    key: "category_name",
    sorter: true,
    render: (text) => (
      <div className="text-sm text-body font-medium bg-sidebar-background p-2 rounded-xl flex justify-center items-center border border-transparent hover:border-secondary">
        {toolPin(text)}
      </div>
    ),
    width: 150,
    customizeKey: t("category"),
    selected: true
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <InputDate />
        </div>
        <div className="col-title">{t("creation_date")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "creation_date",
    key: "creation_date",
    sorter: true,
    render: (text) => (
      <div className="col-desc flex justify-center">{toolPin(text.split("T")[0])}</div>
    ),
    width: 100,
    customizeKey: t("creation_date"),
    selected: true
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <Health />
        </div>
        <div className="col-title">{t("money_request_status")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "approved",
    key: "approved",
    sorter: true,
    render: (text, row) => (
      <div className="col-desc flex justify-center">
        {text === 1 && row.status === 0 ? (
          <span className="text-xs font-medium text-confirmation py-1.5 px-2.5 bg-confirmation bg-opacity-20 rounded-xl">
            {t("transaction_closed")} 
          </span>
        ) : text === 1 ? (
          <span className="text-xs font-medium text-secondary py-1.5 px-2.5 bg-secondary bg-opacity-20 rounded-xl">
            {t("transaction_running")}
          </span>
        ) : text === 0 ? (
          <span className="text-xs font-medium text-danger py-1.5 px-2.5 bg-danger bg-opacity-20 rounded-xl">
            {t("rejected")}
          </span>
        ) : (
          <span className="text-xs font-medium text-onhold py-1.5 px-2.5 bg-onhold bg-opacity-20 rounded-xl">
            {t("transaction_pending")}
          </span>
        )}
      </div>
    ),
    width: 150,
    customizeKey: t("money_request_status"),
    selected: true
  },
];
