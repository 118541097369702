const Reply = () => {
  return (
    <div>
      <svg
        width={16}
        height={14}
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 5.5H8.5C11.8137 5.5 14.5 8.18629 14.5 11.5V13M1 5.5L5.5 10M1 5.5L5.5 1"
          stroke="#1E293B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Reply;
