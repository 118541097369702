const ShareIcon = ({ fillColor }) => {
  return (
    <div>
      <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.9656 10.6096C16.6573 10.6096 16.3989 10.3763 16.3656 10.0679C16.1656 8.23461 15.1823 6.58461 13.6656 5.53461C13.3906 5.34295 13.3239 4.96795 13.5156 4.69295C13.7073 4.41795 14.0823 4.35128 14.3573 4.54295C16.1656 5.80128 17.3323 7.76795 17.5739 9.94295C17.6073 10.2763 17.3656 10.5763 17.0323 10.6096C17.0073 10.6096 16.9906 10.6096 16.9656 10.6096Z"
          fill={fillColor ? fillColor : "#3B82F6"}
        />
        <path
          d="M3.11479 10.6497C3.09812 10.6497 3.07312 10.6497 3.05646 10.6497C2.72312 10.6163 2.48146 10.3163 2.51479 9.98301C2.73979 7.80801 3.88979 5.84135 5.68146 4.57468C5.94812 4.38301 6.33146 4.44968 6.52312 4.71635C6.71479 4.99135 6.64812 5.36635 6.38146 5.55801C4.88146 6.62468 3.90646 8.27468 3.72312 10.0997C3.68979 10.4163 3.42312 10.6497 3.11479 10.6497Z"
          fill={fillColor ? fillColor : "#3B82F6"}
        />
        <path
          d="M13.3239 17.5849C12.2989 18.0766 11.1989 18.3266 10.0489 18.3266C8.84886 18.3266 7.7072 18.0599 6.64053 17.5182C6.34053 17.3766 6.22386 17.0099 6.37386 16.7099C6.51553 16.4099 6.8822 16.2932 7.1822 16.4349C7.7072 16.7016 8.26553 16.8849 8.8322 16.9932C9.59886 17.1432 10.3822 17.1516 11.1489 17.0182C11.7155 16.9182 12.2739 16.7432 12.7905 16.4932C13.0989 16.3516 13.4655 16.4682 13.5989 16.7766C13.7489 17.0766 13.6322 17.4432 13.3239 17.5849Z"
          fill={fillColor ? fillColor : "#3B82F6"}
        />
        <path
          d="M10.0414 1.67578C8.74974 1.67578 7.69141 2.72578 7.69141 4.02578C7.69141 5.32578 8.74141 6.37578 10.0414 6.37578C11.3414 6.37578 12.3914 5.32578 12.3914 4.02578C12.3914 2.72578 11.3414 1.67578 10.0414 1.67578Z"
          fill={fillColor ? fillColor : "#3B82F6"}
        />
        <path
          d="M4.20938 11.5586C2.91771 11.5586 1.85938 12.6086 1.85938 13.9086C1.85938 15.2086 2.90938 16.2586 4.20938 16.2586C5.50938 16.2586 6.55937 15.2086 6.55937 13.9086C6.55937 12.6086 5.50104 11.5586 4.20938 11.5586Z"
          fill={fillColor ? fillColor : "#3B82F6"}
        />
        <path
          d="M15.7914 11.5586C14.4997 11.5586 13.4414 12.6086 13.4414 13.9086C13.4414 15.2086 14.4914 16.2586 15.7914 16.2586C17.0914 16.2586 18.1414 15.2086 18.1414 13.9086C18.1414 12.6086 17.0914 11.5586 15.7914 11.5586Z"
          fill={fillColor ? fillColor : "#3B82F6"}
        />
      </svg>
    </div>
  );
};

export default ShareIcon;
