import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { useForm } from "react-hook-form";

import Input from "../../common/inputs/Input";

import Modal from "../../common/Modal";
import ForgetPassword from "../ForgetPassword";

import { useMutation, useQuery, useQueryClient } from "react-query";

import apiClient from "../../../services/apiClient";
import { useTranslation } from "react-i18next";
import "./Login.scss";

const Login = ({ history, location, onClose }) => {
  const {t, i18n} = useTranslation();
  let pass = location.search ? location.search.split("?")[1].split("=")[1] : "";
  const [showForgetPasswordModal, setShowForgetPasswordModal] = useState(false);

  const queryClient = useQueryClient();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onSubmit",
  });

  const saveTokeninCookie = (token) => {
    let expirationDate = new Date();
    let cookieString = "";
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    cookieString =
      `Bearer=${token}; path=/; expires=` + expirationDate.toUTCString();
    document.cookie = cookieString;
  };

  const updateStorage = () => {
    localStorage.setItem(
      "activeCompanyId",
      localStorage.getItem("activeCompanyId")
        ? localStorage.getItem("activeCompanyId")
        : JSON.stringify(companies.data.data[0].id)
    );
    history.replace("/");
  };

  const userInfo = useQuery(
    "userInfo",
    (params) => {
      return apiClient.get("/auth/getinviteduserinfobytoken", {
        params,
      });
    },
    {
      enabled: pass != "",
    }
  );

  const loginMutation = useMutation((body) => apiClient.post("/auth", body), {
    onSuccess: (response) => {
      saveTokeninCookie(response.data.accessToken);
      if (pass !== "") {
        addToCompanyMutation.mutate({invitation_id: userInfo.id});
      }
    },
  });

  const addToCompanyMutation = useMutation(
    (body) =>
      apiClient.post(
        `/user/${loginMutation.data.data.userId}/addtocompany`,
        body
      ),
    {
      onSuccess: () => {
        history.replace("/");
      },
    }
  );

  const companies = useQuery(
    "companies",
    () => {
      return apiClient.get(
        `user/${loginMutation.data.data.data.userId}/companies`
      );
    },
    {
      enabled: loginMutation.data !== undefined,
      onSuccess: () => queryClient.invalidateQueries("userData"),
    }
  );

  const handleLogin = (data) => {
    loginMutation.mutate(data);
  };

  useEffect(() => {
    const accessToken = document.cookie.includes("Bearer")
      ? document.cookie
          .split("; ")
          .find((row) => row.startsWith("Bearer"))
          .split("=")[1]
      : null;
    if (companies.data && accessToken) updateStorage();
  }, [companies]);

  return (
    <div className="overlay" onClick={() => onClose()}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <form
          noValidate={true}
          className="bg-gray-50 flex flex-col justify-center py-6 rounded-lg login-container"
          onSubmit={handleSubmit(handleLogin)}
        >
          <div>
            <div className="sm:mx-auto sm:w-full">
              <img
                className="mx-auto h-12 w-auto"
                src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                alt="Workflow"
              />
              <h2 className="mt-2 text-center text-lg font-extrabold text-gray-900">
                {t("login_process")}
              </h2>
            </div>
            <div className="mt-8 sm:mx-auto sm:w-full">
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <div className="space-y-6">
                  <div>
                    <Input
                      register={register}
                      type="email"
                      name="email"
                      fieldName={t("email")}
                      label={t("email")}
                      placeholder={t("email")}
                      required={true}
                      maxLength="50"
                      errors={errors?.email}
                    />
                  </div>
                  <div>
                    <Input
                      register={register}
                      type="password"
                      isPassword={true}
                      name="password"
                      fieldName={t("password")}
                      label={t("password")}
                      placeholder={t("password")}
                      required={true}
                      minLength="8"
                      errors={errors?.password}
                    />
                  </div>
                  {loginMutation.isError && (
                    <div
                      class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                      role="alert"
                    >
                      <span class="block sm:inline">
                        {loginMutation.error.response.data.errors ==
                        "Request has not been approved yet"
                          ? t("not_yet_accepted")
                          : loginMutation.error.response.data.errors == "your account not active"?
                            t("deactivated_user")
                          : t("invalid_credentials")}
                      </span>
                    </div>
                  )}
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                      />
                      <label
                        htmlFor="remember-me"
                        className="mr-2 block text-sm text-gray-900"
                      >
                        {t("remember me")}
                      </label>
                    </div>
                    <div className="text-sm">
                      <Fragment>
                        <button
                          type="button"
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                          onClick={() => setShowForgetPasswordModal(true)}
                        >
                          {t("reset_password")}
                        </button>
                      </Fragment>
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 bg-black py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white"
                    >
                      {t("login")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Modal
        showModal={showForgetPasswordModal}
        onClose={() => setShowForgetPasswordModal(false)}
      >
        <ForgetPassword
          onClose={() => setShowForgetPasswordModal(false)}
          // url="api/url"
        />
      </Modal>
    </div>
  );
};

export default withRouter(Login);
