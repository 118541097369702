import PropTypes from "prop-types";

function Icon(props) {
  const { className, ...restOfProps } = props;
  return (
    <svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      className={`${className}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restOfProps}
    >
      <path
        d="M14.5312 12.6562H7.96875C7.71 12.6562 7.5 12.4462 7.5 12.1875C7.5 11.9288 7.5 3.07125 7.5 2.8125C7.5 2.55375 7.71 2.34375 7.96875 2.34375H14.5312C14.79 2.34375 15 2.55375 15 2.8125V12.1875C15 12.4462 14.79 12.6562 14.5312 12.6562Z"
        fill="#F8FAFC"
      />
      <path
        d="M9.84375 5.15625H7.96875C7.71 5.15625 7.5 4.94625 7.5 4.6875C7.5 4.42875 7.71 4.21875 7.96875 4.21875H9.84375C10.1025 4.21875 10.3125 4.42875 10.3125 4.6875C10.3125 4.94625 10.1025 5.15625 9.84375 5.15625Z"
        fill="#10B981"
      />
      <path
        d="M9.84375 7.03125H7.96875C7.71 7.03125 7.5 6.82125 7.5 6.5625C7.5 6.30375 7.71 6.09375 7.96875 6.09375H9.84375C10.1025 6.09375 10.3125 6.30375 10.3125 6.5625C10.3125 6.82125 10.1025 7.03125 9.84375 7.03125Z"
        fill="#10B981"
      />
      <path
        d="M9.84375 8.90625H7.96875C7.71 8.90625 7.5 8.69625 7.5 8.4375C7.5 8.17875 7.71 7.96875 7.96875 7.96875H9.84375C10.1025 7.96875 10.3125 8.17875 10.3125 8.4375C10.3125 8.69625 10.1025 8.90625 9.84375 8.90625Z"
        fill="#10B981"
      />
      <path
        d="M9.84375 10.7812H7.96875C7.71 10.7812 7.5 10.5713 7.5 10.3125C7.5 10.0538 7.71 9.84375 7.96875 9.84375H9.84375C10.1025 9.84375 10.3125 10.0538 10.3125 10.3125C10.3125 10.5713 10.1025 10.7812 9.84375 10.7812Z"
        fill="#10B981"
      />
      <path
        d="M12.6562 5.15625H11.7188C11.46 5.15625 11.25 4.94625 11.25 4.6875C11.25 4.42875 11.46 4.21875 11.7188 4.21875H12.6562C12.915 4.21875 13.125 4.42875 13.125 4.6875C13.125 4.94625 12.915 5.15625 12.6562 5.15625Z"
        fill="#10B981"
      />
      <path
        d="M12.6562 7.03125H11.7188C11.46 7.03125 11.25 6.82125 11.25 6.5625C11.25 6.30375 11.46 6.09375 11.7188 6.09375H12.6562C12.915 6.09375 13.125 6.30375 13.125 6.5625C13.125 6.82125 12.915 7.03125 12.6562 7.03125Z"
        fill="#10B981"
      />
      <path
        d="M12.6562 8.90625H11.7188C11.46 8.90625 11.25 8.69625 11.25 8.4375C11.25 8.17875 11.46 7.96875 11.7188 7.96875H12.6562C12.915 7.96875 13.125 8.17875 13.125 8.4375C13.125 8.69625 12.915 8.90625 12.6562 8.90625Z"
        fill="#10B981"
      />
      <path
        d="M12.6562 10.7812H11.7188C11.46 10.7812 11.25 10.5713 11.25 10.3125C11.25 10.0538 11.46 9.84375 11.7188 9.84375H12.6562C12.915 9.84375 13.125 10.0538 13.125 10.3125C13.125 10.5713 12.915 10.7812 12.6562 10.7812Z"
        fill="#10B981"
      />
      <path
        d="M8.26781 0.577194C8.16094 0.488132 8.0175 0.449694 7.8825 0.477819L0.3825 1.88407C0.160312 1.92532 0 2.11844 0 2.34438V12.6569C0 12.8819 0.160312 13.0759 0.3825 13.1172L7.8825 14.5234C7.91062 14.5291 7.93969 14.5319 7.96875 14.5319C8.0775 14.5319 8.18344 14.4944 8.26781 14.4241C8.37563 14.335 8.4375 14.2019 8.4375 14.0631V0.938132C8.4375 0.798444 8.37563 0.666257 8.26781 0.577194Z"
        fill="#10B981"
      />
      <path
        d="M6.44673 9.0661L4.96454 7.37204L6.4636 5.44454C6.62298 5.24017 6.58548 4.94579 6.38204 4.78642C6.17861 4.62704 5.88423 4.66454 5.72392 4.86798L4.33548 6.65298L3.16548 5.31611C2.99392 5.11923 2.69767 5.10142 2.50454 5.27204C2.30954 5.44267 2.28986 5.73892 2.46048 5.93298L3.74954 7.40673L2.44267 9.08673C2.28329 9.2911 2.32079 9.58548 2.52423 9.74485C2.61048 9.81141 2.71267 9.84329 2.81298 9.84329C2.95267 9.84329 3.09048 9.78141 3.18329 9.66235L4.37861 8.12485L5.74173 9.68204C5.83454 9.78891 5.96392 9.84329 6.09423 9.84329C6.20392 9.84329 6.31361 9.80485 6.40267 9.72704C6.59767 9.55642 6.61735 9.26017 6.44673 9.0661Z"
        fill="#CBD5E1"
      />
    </svg>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
};

export default Icon;
