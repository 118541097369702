const Role = () => {
  return (
    <div>
      <svg
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.86249 1.5V16.5"
          stroke="#1E293B"
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.86249 3H12.2625C14.2875 3 14.7375 4.125 13.3125 5.55L12.4125 6.45C11.8125 7.05 11.8125 8.025 12.4125 8.55L13.3125 9.45C14.7375 10.875 14.2125 12 12.2625 12H3.86249"
          stroke="#1E293B"
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Role;
