const AcceptMoneyReq = () => {
  return (
    <div>
      <svg
        width={48}
        height={48}
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width={48} height={48} rx={24} fill="#E7F0FF" />
        <path
          d="M27.24 21.25H14C13.59 21.25 13.25 20.91 13.25 20.5C13.25 20.09 13.59 19.75 14 19.75H27.24C27.65 19.75 27.99 20.09 27.99 20.5C27.99 20.91 27.65 21.25 27.24 21.25Z"
          fill="#3B82F6"
        />
        <path
          d="M19.29 29.25H18C17.59 29.25 17.25 28.91 17.25 28.5C17.25 28.09 17.59 27.75 18 27.75H19.29C19.7 27.75 20.04 28.09 20.04 28.5C20.04 28.91 19.71 29.25 19.29 29.25Z"
          fill="#3B82F6"
        />
        <path
          d="M26.5 29.25H23C22.59 29.25 22.25 28.91 22.25 28.5C22.25 28.09 22.59 27.75 23 27.75H26.5C26.91 27.75 27.25 28.09 27.25 28.5C27.25 28.91 26.91 29.25 26.5 29.25Z"
          fill="#3B82F6"
        />
        <path
          d="M29.5605 33.25H19.9805C19.5705 33.25 19.2305 32.91 19.2305 32.5C19.2305 32.09 19.5705 31.75 19.9805 31.75H29.5605C32.7005 31.75 33.2505 31.21 33.2505 28.11V18.89C33.2505 18.48 33.5905 18.14 34.0005 18.14C34.4105 18.14 34.7505 18.48 34.7505 18.89V28.1C34.7505 32.05 33.5405 33.25 29.5605 33.25Z"
          fill="#3B82F6"
        />
        <path
          d="M15.71 32.89C15.62 32.89 15.52 32.87 15.43 32.84C13.62 32.12 13.25 30.43 13.25 28.11V19.89C13.25 15.95 14.46 14.75 18.44 14.75H29.55C30.69 14.75 31.53 14.84 32.19 15.04C32.59 15.16 32.81 15.57 32.7 15.97C32.58 16.37 32.16 16.59 31.77 16.48C31.25 16.33 30.55 16.26 29.55 16.26H18.44C15.3 16.26 14.75 16.8 14.75 19.9V28.11C14.75 30.5 15.18 31.13 15.99 31.45C16.38 31.6 16.56 32.04 16.41 32.42C16.29 32.71 16.01 32.89 15.71 32.89Z"
          fill="#3B82F6"
        />
        <path
          d="M13.9995 34.7499C13.8095 34.7499 13.6195 34.6799 13.4695 34.5299C13.1795 34.2399 13.1795 33.7599 13.4695 33.4699L33.4695 13.4699C33.7595 13.1799 34.2395 13.1799 34.5295 13.4699C34.8195 13.7599 34.8195 14.2399 34.5295 14.5299L14.5295 34.5299C14.3795 34.6799 14.1895 34.7499 13.9995 34.7499Z"
          fill="#3B82F6"
        />
      </svg>
    </div>
  );
};

export default AcceptMoneyReq;
