import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useMutateDepositReject(setShowRejectModal, safeId) {
  const queryClient = useQueryClient();

  return useMutation(
    (activeRow) => {
      const formData = new FormData();

      formData.append("id", activeRow.id);
      formData.append("reject", 1);

      return apiClient.post(`cashin/reject?safe_id=${safeId}`, formData);
    },
    {
      onSuccess: () => {
        toast.info("تم رفض طلب الإيداع.");
        queryClient.invalidateQueries("getDepositFullControl");
        queryClient.invalidateQueries("getLastUpdates");
        queryClient.invalidateQueries("getSingleCashin");
        queryClient.invalidateQueries("userData");
        queryClient.invalidateQueries("userBalance");
        setShowRejectModal(false);
      },
      onError: (error) => {
        setShowRejectModal(false);
        return error;
      },
    }
  );
}
