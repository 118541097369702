import AcceptedSvg from "../../resources/accepted svg/AcceptedSvg";
import RejectedSvg from "../../resources/rejected svg/RejectedSvg";
import SuspendedSvg from "../../resources/suspended svg/SuspendedSvg";
import CurrentSvg from "../../resources/current svg/CurrentSvg";
import FinishedSvg from "../../resources/finished svg/FinishedSvg";

import { convertDate } from "../../../utils/convertDate";
import MoneyRequestDetailsFinancialDocumentationsIcon from "../../resources/icons/MoneyRequestDetailsFinancialDocumentationsIcon";
import MoneyRequestDetailsAcceptedMoneyRequestsIcon from "../../resources/icons/MoneyRequestDetailsAcceptedMoneyRequestsIcon";
import TableSortIcon from "../../resources/icons/TableSortIcon";
import KeyIcon from "../../resources/icons/KeyIcon";
import CashAmountIcon from "../../resources/icons/CashAmountIcon";
import CategoryIcon from "../../resources/icons/CategoryIcon";
import DateIcon from "../../resources/icons/DateIcon";
import ExpenseTypeIcon from "../../resources/icons/ExpenseTypeIcon";

export const columnsHeaderAll = (t, i18n) => [
  {
    title: () => {
      return (
        <div class="flex flex-row">
          <div>
            <KeyIcon />
          </div>
          <div className="mx-2 text-xs font-bold">{t("transaction_code")}</div>
          <div>
            <TableSortIcon />
          </div>
        </div>
      );
    },
    dataIndex: "id",
    key: "id",
  },
  {
    title: () => {
      return (
        <div class="flex flex-row">
          <div>
            <CashAmountIcon />
          </div>
          <div className="mx-2 text-xs font-bold">{t("amount")}</div>
          <div>
            <TableSortIcon />
          </div>
        </div>
      );
    },
    dataIndex: "amount",
    key: "amount",
    render: (text, row, index) => {
      return <div className="flex justify-center">{row.amount} {t("egp")}</div>;
    },
  },
  {
    title: () => {
      return (
        <div class="flex flex-row">
          <div>
            <CategoryIcon />
          </div>
          <div className="mx-2 text-xs font-bold">{t("category")}</div>
          <div>
            <TableSortIcon />
          </div>
        </div>
      );
    },
    dataIndex: "category",
    key: "category",
    render: (text, row, index) => {
      return (
        <div className="bg-gray-100 rounded-lg w-32">
          <div className="text-center text-xs font-bold py-2 px-1">
            {row.category}
          </div>
        </div>
      );
    },
  },
  {
    title: () => {
      return (
        <div class="flex flex-row">
          <div>
            <DateIcon />
          </div>
          <div className="mx-2 text-xs font-bold">{t("creation_date")}</div>
          <div>
            <TableSortIcon />
          </div>
        </div>
      );
    },
    dataIndex: "creation_date",
    key: "creation_date",
    render: (text, row, index) => {
      return (
        <div className="flex justify-center">
          {convertDate(
            row.response_date ? row.response_date : row.creation_date
          )}
        </div>
      );
    },
  },
  {
    title: () => {
      return (
        <div class="flex flex-row">
          <div>
            <ExpenseTypeIcon />
          </div>
          <div className="mx-2 text-xs font-bold">{t("transaction_type")}</div>
          <div>
            <TableSortIcon />
          </div>
        </div>
      );
    },
    dataIndex: "periodic",
    key: "periodic",
    render: (text, row, index) => {
      return (
        <div class="flex flex-row">
          <div>
            {row.periodic === 0 || row.periodic === 1 ? (
              <MoneyRequestDetailsAcceptedMoneyRequestsIcon fillColor="#94A3B8" />
            ) : (
              <MoneyRequestDetailsFinancialDocumentationsIcon fillColor="#94A3B8" />
            )}
          </div>
          <div className="mx-2">
            {row.periodic === 0 || row.periodic === 1
              ? t("transaction_type_document")
              : t("money_request")}
          </div>
        </div>
      );
    },
  },
];

export const columnsHeaderDocs = (t, i18n) => [
  {
    title: () => {
      return (
        <div class="flex flex-row">
          <div>
            <KeyIcon />
          </div>
          <div className="mx-2 text-xs font-bold">{t("transaction_code")}</div>
          <div>
            <TableSortIcon />
          </div>
        </div>
      );
    },
    dataIndex: "id",
    key: "id",
  },
  {
    title: () => {
      return (
        <div class="flex flex-row">
          <div>
            <CashAmountIcon />
          </div>
          <div className="mx-2 text-xs font-bold">{t("amount")}</div>
          <div>
            <TableSortIcon />
          </div>
        </div>
      );
    },
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: () => {
      return (
        <div class="flex flex-row">
          <div>
            <CategoryIcon />
          </div>
          <div className="mx-2 text-xs font-bold">{t("category")}</div>
          <div>
            <TableSortIcon />
          </div>
        </div>
      );
    },
    dataIndex: "category",
    key: "category",
    render: (text, row, index) => {
      return (
        <div className="bg-gray-100 rounded-lg w-32">
          <div className="text-center text-xs font-bold py-2 px-1">
            {row.category}
          </div>
        </div>
      );
    },
  },
  {
    title: () => {
      return (
        <div class="flex flex-row">
          <div>
            <DateIcon />
          </div>
          <div className="mx-2 text-xs font-bold">{t("creation_date")}</div>
          <div>
            <TableSortIcon />
          </div>
        </div>
      );
    },
    dataIndex: "creation_date",
    key: "creation_date",
    render: (text, row, index) => {
      return <div>{convertDate(text)}</div>;
    },
  },
];

export const columnsHeaderMoneyReqs = (t, i18n) => [
  {
    title: () => {
      return (
        <div class="flex flex-row">
          <div>
            <KeyIcon />
          </div>
          <div className="mr-2 ml-2 text-xs font-bold">{t("transaction_code")}</div>
          <div>
            <TableSortIcon />
          </div>
        </div>
      );
    },
    dataIndex: "id",
    key: "id",
  },
  {
    title: () => {
      return (
        <div class="flex flex-row">
          <div>
            <CashAmountIcon />
          </div>
          <div className="mr-2 ml-2 text-xs font-bold">{t("amount")}</div>
          <div>
            <TableSortIcon />
          </div>
        </div>
      );
    },
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: () => {
      return (
        <div class="flex flex-row">
          <div>
            <CategoryIcon />
          </div>
          <div className="mx-2 text-xs font-bold">{t("category")}</div>
          <div>
            <TableSortIcon />
          </div>
        </div>
      );
    },
    dataIndex: "category",
    key: "category",
    render: (text, row, index) => {
      return (
        <div className="bg-gray-100 rounded-lg w-32">
          <div className="text-center text-xs font-bold py-2 px-1">
            {row.category}
          </div>
        </div>
      );
    },
  },
  {
    title: () => {
      return (
        <div class="flex flex-row">
          <div>
            <DateIcon />
          </div>
          <div className="mx-2 text-xs font-bold">{t("creation_date")}</div>
          <div>
            <TableSortIcon />
          </div>
        </div>
      );
    },
    dataIndex: "creation_date",
    key: "creation_date",
    render: (text, row, index) => {
      return <div>{convertDate(text)}</div>;
    },
  },
];
