import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useMutateHoldDocument(
  onClose
) {
  const queryClient = useQueryClient();
  return useMutation(
    (id) => {
      return apiClient.patch(`document/hold`, {id});
    },
    {
      onSuccess: (res, sentData) => {
        toast.error("تم حجب العملية");
        queryClient.invalidateQueries("getDocumentations");
        queryClient.invalidateQueries("getSingleDocument");
        queryClient.invalidateQueries("userData");
        onClose();
      },
      onError: (error) => {
        onClose();
        return error;
      },
    }
  );
}
