import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchCategory(customKey, showFiltering, staleTime) {
  return useQuery(
    customKey ? customKey : "category",
    () =>
      apiClient
        ?.get("category")
        .then(({ data }) =>
          data.map((category) => {
            return {
              ...category,
              label: category.name,
              value: category.id,
            };
          })
        )
        .catch((e) => e),
    {
      enabled: showFiltering,
      staleTime,
    }
  );
}
