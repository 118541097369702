import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchLatestRecipients() {
  const activeCompanyId = JSON.parse(localStorage.getItem("activeCompanyId"));
  return useQuery("lastrecipients", () =>
    apiClient
      ?.get(`spend_request/lastrecipients`)
      .then(({ data }) =>      
        data.map((user) => {
          return {
            ...user,
            label: user.name,
            value: user.id,
          };
        })
      )
      .catch((e) => e)
  );
}
