const AcceptedSvg = ({}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <circle cx="15" cy="15" r="12.5" fill="#5473FF" />

      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.7375 9.47504L12.5 17.7125L8.0125 13.2375L6.25 15L12.5 21.25L22.5 11.25L20.7375 9.47504ZM15 2.50002C8.1 2.50002 2.5 8.10002 2.5 15C2.5 21.9 8.1 27.5 15 27.5C21.9 27.5 27.5 21.9 27.5 15C27.5 8.10002 21.9 2.50002 15 2.50002ZM15 25C9.47504 25 5.00004 20.525 5.00004 15C5.00004 9.47503 9.47504 5.00003 15 5.00003C20.525 5.00003 25 9.47503 25 15C25 20.525 20.525 25 15 25Z"
        fill="white"
      />
    </svg>
  );
};

export default AcceptedSvg;
