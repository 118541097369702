const ChartBar = ({ strokeColor }) => {
  return (
    <div>
      <svg
        width={24}
        height={25}
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 4.5V3.5H3V4.5H4ZM20 4.5H21V3.5H20V4.5ZM6.29289 11.7929C5.90237 12.1834 5.90237 12.8166 6.29289 13.2071C6.68342 13.5976 7.31658 13.5976 7.70711 13.2071L6.29289 11.7929ZM10 9.5L10.7071 8.79289C10.3166 8.40237 9.68342 8.40237 9.29289 8.79289L10 9.5ZM13 12.5L12.2929 13.2071C12.6834 13.5976 13.3166 13.5976 13.7071 13.2071L13 12.5ZM17.7071 9.20711C18.0976 8.81658 18.0976 8.18342 17.7071 7.79289C17.3166 7.40237 16.6834 7.40237 16.2929 7.79289L17.7071 9.20711ZM7.29289 20.7929C6.90237 21.1834 6.90237 21.8166 7.29289 22.2071C7.68342 22.5976 8.31658 22.5976 8.70711 22.2071L7.29289 20.7929ZM12 17.5L12.7071 16.7929C12.3166 16.4024 11.6834 16.4024 11.2929 16.7929L12 17.5ZM15.2929 22.2071C15.6834 22.5976 16.3166 22.5976 16.7071 22.2071C17.0976 21.8166 17.0976 21.1834 16.7071 20.7929L15.2929 22.2071ZM3 3.5C2.44772 3.5 2 3.94772 2 4.5C2 5.05228 2.44772 5.5 3 5.5V3.5ZM21 5.5C21.5523 5.5 22 5.05228 22 4.5C22 3.94772 21.5523 3.5 21 3.5V5.5ZM4 5.5H20V3.5H4V5.5ZM19 4.5V16.5H21V4.5H19ZM19 16.5H5V18.5H19V16.5ZM5 16.5V4.5H3V16.5H5ZM5 16.5H3C3 17.6046 3.89543 18.5 5 18.5V16.5ZM19 16.5V18.5C20.1046 18.5 21 17.6046 21 16.5H19ZM7.70711 13.2071L10.7071 10.2071L9.29289 8.79289L6.29289 11.7929L7.70711 13.2071ZM9.29289 10.2071L12.2929 13.2071L13.7071 11.7929L10.7071 8.79289L9.29289 10.2071ZM13.7071 13.2071L17.7071 9.20711L16.2929 7.79289L12.2929 11.7929L13.7071 13.2071ZM8.70711 22.2071L12.7071 18.2071L11.2929 16.7929L7.29289 20.7929L8.70711 22.2071ZM11.2929 18.2071L15.2929 22.2071L16.7071 20.7929L12.7071 16.7929L11.2929 18.2071ZM3 5.5H21V3.5H3V5.5Z"
          fill={strokeColor ? strokeColor : "#94A3B8"}
        />
      </svg>
    </div>
  );
};

export default ChartBar;
