import { useEffect, useState, useMemo } from "react";

import HeaderSection from "../../components/common/HeaderSection";
import TableTabs from "../../components/archive/TableTabs";
import Table from "../../components/common/Table";
import ExpandedRow from "../../components/archive/ExpandedRow";
import Spinner from "../../components/common/Spinner";
import FieldErrorSvg from "../../components/resources/field error svg/FieldErrorSvg";
import DrawerSvg from "../../components/resources/drawer svg/DrawerSvg";
import RedCashSvg from "../../components/resources/red cash svg/RedCashSvg";
import GreenCashSvg from "../../components/resources/green cash svg/GreenCashSvg";
import PapersSvg from "../../components/resources/papers svg/PapersSvg";

import { useDataLayerValue } from "../../context/DataLayer";
import useFetchArchive from "../../hooks/reactQuery/useFetchArchive";
import useFetchUserData from "../../hooks/reactQuery/useFetchUserData";
import { columnsHeader } from "./tableColumnsHeader";
import actionTypes from "../../context/types";

const Archive = ({}) => {
  const { data: userData } = useFetchUserData();

  const [{ activeTab, searchType, searchTerms }, dispatch] = useDataLayerValue();

  if(activeTab == 0 && !userData.permission_tags.includes("SendPurchaseRequest")) {
    dispatch({
      type: actionTypes.ACTIVE_TAB,
      payload: 1,
    });
  }

  const pageSize = process.env.REACT_APP_PAGE_SIZE;
  const [currentPage, setCurrentPage] = useState(1);
  const [urgent, setUrgent] = useState(false);
  const [notUrgent, setNotUrgent] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [columnKey, setColumnKey] = useState("");
  const [order, setOrder] = useState("");

  useEffect(() => {
    setCurrentPage(1);
  }, [
    activeTab,
    searchType,
    searchTerms,
    urgent,
    notUrgent,
    accepted,
    rejected,
    order,
    columnKey,
  ]);

  useEffect(() => {
    setUrgent(false);
    setNotUrgent(false);
    setAccepted(false);
    setRejected(false);
  }, [activeTab]);

  const {
    isLoading: isTableDataLoading,
    data: tableData,
    error: tableDataError,
    isPreviousData,
  } = useFetchArchive({
    userId: userData.id,
    activeCompanyId: userData.activeCompanyId,
    activeTab,
    currentPage,
    pageSize,
    urgent,
    notUrgent,
    accepted,
    rejected,
    order,
    columnKey,
    searchType,
    searchTerms,
  });

  const memoizedTableData = useMemo(
    () => (tableData ? tableData[0] : []),
    [tableData]
  );

  //
  // table
  const handleTableChange = (pagination, filters, sorter) => {
    setOrder(sorter.order);
    setColumnKey(sorter.columnKey);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const [expandedRows, setExpandedRows] = useState([]);
  const onTableRowExpand = (expanded, record) => {
    var keys = [];
    if (expanded) {
      keys.push(record.id);
    }
    setExpandedRows(keys);
  };

  const [selectedRows, setSelectedRows] = useState([]);

  return (
    <>
      {/* <HeaderSection
        PageLogo={DrawerSvg}
        title={
          <div className="flex items-center">
            أهلا بك يا &nbsp;
            {isUserDataLoading ? (
              <div className="w-4 h-4">
                {" "}
                <Spinner />{" "}
              </div>
            ) : userDataError ? (
              <div className="w-4 h-4">
                {" "}
                <FieldErrorSvg />{" "}
              </div>
            ) : (
              <span>{`${userData?.first_name}`}</span>
            )}
          </div>
        }
        description="يمكنك من هنا الاطلاع على الطلبات المالية التي تم اتخاذ إجراء بشأنها."
        title1="إجمالى القيمة المرفوضة"
        value1={
          tableData && tableData[1] && tableData[1][0]?.overall_rejected_total
        }
        state1={{ isLoading: isTableDataLoading, error: tableDataError }}
        title2="إجمالى القيمة المقبولة"
        value2={
          tableData && tableData[1] && tableData[1][0]?.overall_accepted_total
        }
        state2={{ isLoading: isTableDataLoading, error: tableDataError }}
        title3="إجمالى الطلبات"
        value3={tableData && tableData[1] && tableData[1][0]?.overall_count}
        state3={{ isLoading: isTableDataLoading, error: tableDataError }}
        isTableDataLoading={isTableDataLoading}
        tableDataError={tableDataError}
        TotalRequests={RedCashSvg}
        TotalValueSvg={GreenCashSvg}
        CurrentBalanceSvg={PapersSvg}
      /> */}

      <HeaderSection
        searchDropDownApiUrl={"spend_request/archived/dropdowndata"}
        activeTab={activeTab}
        setColumns={columnsHeader}
        originalColumnsHeader={columnsHeader}
        selectedRows={selectedRows}
        hasToolbar={false}
      />

      <TableTabs
        urgent={urgent}
        setUrgent={setUrgent}
        notUrgent={notUrgent}
        setNotUrgent={setNotUrgent}
        accepted={accepted}
        setAccepted={setAccepted}
        rejected={rejected}
        setRejected={setRejected}
      />

      <div className="mx-4 my-3">
        <Table
          rowKey="id"
          data={memoizedTableData}
          columns={columnsHeader}
          isLoading={isTableDataLoading}
          error={tableDataError}
          pageSize={pageSize}
          currentPage={currentPage}
          isPaginationDisabled={isPreviousData}
          total={tableData && tableData[1] && tableData[1][0]?.overall_count}
          onChange={handleTableChange}
          onPageChange={onPageChange}
          setSelectedRows={setSelectedRows}
          expandedRows={expandedRows}
          setExpandedRows={setExpandedRows}
          onTableRowExpand={onTableRowExpand}
          buttonEmptyPage={false}
          textEmptyPage="لا توجد أي عمليات مؤرشفة بعد، بمجرد أرشفة إحدي العمليات، سيتم عرضها في هذه الصفحة. "
          expandedRow={(row) => <ExpandedRow row={row} />}
        />
      </div>
    </>
  );
};

export default Archive;
