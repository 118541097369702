import { Redirect, Route } from "react-router-dom";
import useFetchUserData from "../../hooks/reactQuery/useFetchUserData";

const ProtectedRoute = ({
  condition,
  Component,
  redirect,
  path,
  permissions,
  ...props
}) => {
  const { data: userData } = useFetchUserData();

  let hasPermission = false;

  if(permissions.length === 0) {
    hasPermission = true;
  }
  else if( permissions.length > 0) {
    for(let p of permissions) {
      if(userData.permission_tags.includes(p)) {
        hasPermission = true;
      }
    }
  }
  if (condition) hasPermission = true;

  return (
    <Route
      exact
      path={path}
      render={() =>
        condition && hasPermission ? (
          <Component {...props} />
        ) : (
          <Redirect to={redirect} />
        )
      }
    />
  );
};

export default ProtectedRoute;
