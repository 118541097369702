import "./style.scss";
import { useTranslation } from "react-i18next";
const RequestForAccessImage = ({ tab }) => {
  const {t, i18n} = useTranslation();
  return (
    <div className="request_access_image" style={{right: i18n.resolvedLanguage === "en" ? "auto" : 0, left: i18n.resolvedLanguage === "en" ? 0 : "auto"}}>
      <div className="request_access_imageContainer">
        {tab === 1 ? <img className="request_access_image_img" src="/img/personal_info.png" /> 
        : tab === 2 ? <img className="request_access_image_img" src="/img/work_info.png" /> : 
        tab === 3 ? <img className="request_access_image_img" src="/img/tools_info.png" /> : null}
      </div>
      <div className="request_access_image_text_container">
        {tab === 1 ?  
          <>
            <span style={{fontSize: "22px", fontWeight: "700", color: "#fff", textAlign: "right"}}>
            {t("request_for_access_personal_text_1")}
            </span>
            <span style={{fontSize: "16px", fontWeight: "500", color: "#fff", textAlign: "right"}}>
            {t("request_for_access_personal_text_2")}
            </span>
            <span style={{fontSize: "16px", fontWeight: "500", color: "#fff", textAlign: "right"}}>
            {t("request_for_access_personal_text_3")}
            </span>
          </>
          : tab === 2 ?
          <>
            <span style={{fontSize: "22px", fontWeight: "700", color: "#fff", textAlign: "right"}}>
            {t("request_for_access_business_text_1")}
            </span>
            <span style={{fontSize: "16px", fontWeight: "500", color: "#fff", textAlign: "right"}}>
            {t("request_for_access_business_text_2")}
            </span>
            <span style={{fontSize: "16px", fontWeight: "500", color: "#fff", textAlign: "right"}}>
            {t("request_for_access_business_text_3")}
            </span>
          </>
          : tab === 3 ? 
          <>
            <span style={{fontSize: "22px", fontWeight: "700", color: "#fff", textAlign: "right"}}>
            {t("request_for_access_tools_text_1")}
            </span>
            <span style={{fontSize: "16px", fontWeight: "500", color: "#fff", textAlign: "right"}}>
            {t("request_for_access_tools_text_2")}
            </span>
            <span style={{fontSize: "16px", fontWeight: "500", color: "#fff", textAlign: "right"}}>
            {t("request_for_access_tools_text_3")}
            </span>
            <span style={{fontSize: "16px", fontWeight: "500", color: "#fff", textAlign: "right"}}>
            {t("request_for_access_tools_text_4")}
            </span>
          </>
          : null
        }
      </div>
    </div>
  );
};

export default RequestForAccessImage;
