const Settings = ({ strokeColor }) => {
  return (
    <div>
      <svg
        width={24}
        height={25}
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.3246 4.81731C10.751 3.0609 13.249 3.0609 13.6754 4.81731C13.9508 5.95193 15.2507 6.49038 16.2478 5.88285C17.7913 4.94239 19.5576 6.7087 18.6172 8.25218C18.0096 9.24925 18.5481 10.5492 19.6827 10.8246C21.4391 11.251 21.4391 13.749 19.6827 14.1754C18.5481 14.4508 18.0096 15.7507 18.6172 16.7478C19.5576 18.2913 17.7913 20.0576 16.2478 19.1172C15.2507 18.5096 13.9508 19.0481 13.6754 20.1827C13.249 21.9391 10.751 21.9391 10.3246 20.1827C10.0492 19.0481 8.74926 18.5096 7.75219 19.1172C6.2087 20.0576 4.44239 18.2913 5.38285 16.7478C5.99038 15.7507 5.45193 14.4508 4.31731 14.1754C2.5609 13.749 2.5609 11.251 4.31731 10.8246C5.45193 10.5492 5.99037 9.24926 5.38285 8.25218C4.44239 6.7087 6.2087 4.94239 7.75219 5.88285C8.74926 6.49037 10.0492 5.95193 10.3246 4.81731Z"
          stroke={strokeColor ? strokeColor : "#94A3B8"}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 12.5C15 14.1569 13.6569 15.5 12 15.5C10.3431 15.5 9 14.1569 9 12.5C9 10.8431 10.3431 9.5 12 9.5C13.6569 9.5 15 10.8431 15 12.5Z"
          stroke={strokeColor ? strokeColor : "#94A3B8"}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Settings;
