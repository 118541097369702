import { useEffect, useState, useRef } from 'react';
import ArrowDown from '../../../resources/icons/ArrowDown';
import Selector from '../../../resources/icons/Selector';
import DateRangePickerComponent from '../../../common/DateRangePicker/DateRangePicker';
import "../dashboard.scss";
import PyChart from '../../Charts/PieChart';
import ArrowUp from '../../../resources/icons/ArrowUp';
import { useTranslation } from "react-i18next";

export default function Total(props) {    
    const {t, i18n} = useTranslation();

    const [expanded, setExpanded] = useState(false);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const filterBtnRef = useRef(null);
    const datePickerRef = useRef(null);
    const [scrolledToBottom, setScrolledToBottom] = useState(false);
    const [noImg, setNoImg] = useState(false);

    const scrollHandler = (e) => {
        if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight - 60) { 
            setScrolledToBottom(true);
        } 
        else {
            setScrolledToBottom(false);
        }
    }

    useEffect(() => {
        if(scrolledToBottom && !props.isLoading ) {
            if(props.currentPage < props.lastPage) 
            props.setCurrentPage(props.currentPage + 1);
        }
    }, [scrolledToBottom]);

    

    useEffect(() => {
        document.addEventListener("click", e => {
          if(datePickerRef && datePickerRef.current && filterBtnRef && filterBtnRef.current && showDatePicker) {
            if(!datePickerRef.current.contains(e.target) && !filterBtnRef.current.contains(e.target)) setShowDatePicker(false);
          }
        });
        
        return () => {
          document.removeEventListener("click", e => {
            if(datePickerRef && datePickerRef.current && filterBtnRef && filterBtnRef.current && showDatePicker) {
              if(!datePickerRef.current.contains(e.target) && !filterBtnRef.current.contains(e.target)) setShowDatePicker(false);
            }
          });
        }
    }, [showDatePicker]);

    return (
        <div className='total-container' style={expanded ? {height: "1027px"} : {height: "790px"}}>
            <div className='total-container-header'>
                <div className='total-container-header-info'>
                    <div className='total-container-header-info-text'>
                        <span className='total-container-info-text-header'>{props.title}</span>
                        <span className='total-container-info-text-amount'>{props.total ? props.total : 0}</span>
                        <span className='total-container-info-text-total'> {t("count")} / {props.count}</span>
                    </div>
                    {props.icon}
                </div>
                <div className='total-container-header-stat'>
                    <span className='stat'>
                        {props.compare && props.compare.higher ? <ArrowUp fill={props.upColor} /> : <ArrowDown fill={props.downColor} />}
                        <span style={{color: props.compare && props.compare.higher ? props.upColor : props.downColor, margin: "0px 2px"}}>%{props.compare && props.compare.precentage}</span>
                        <span style={{
                            fontFamily: 'Tajawal',
                            fontWeight: 400,
                            fontSize: "12px",
                            color: "#6B7280",
                            margin: "0px 2px"
                        }}>{props.compare && props.compare.higher ? t("gt") : t("lt")}</span>
                        <span style={{
                            fontFamily: 'Tajawal',
                            fontWeight: 400,
                            fontSize: "12px",
                            color: "#6B7280"
                        }}>{t("from_specified_time")}</span>
                    </span>
                    <button className='total-filter-btn' onClick={() => setShowDatePicker(!showDatePicker)} ref={filterBtnRef}><Selector fill="#6B7280"/>{props.selectionRange[0].label}</button>
                    {showDatePicker ? <div className='total-section-date-picker' style={props.alignDatePickerLeft ? {direction: "ltr", left: 0, right: "auto"} : {direction: "ltr"}} ref ={datePickerRef}>
                        <DateRangePickerComponent selectionRange={props.selectionRange} setSelectionRange={props.setSelectionRange} />
                    </div> : null}   
                </div>
            </div>
            <div className={`total-container-transactions`} style={expanded ? {height: "515px"} : {height: "278px"}}>
                <span className="total-container-transactions-title">{props.transactionsTitle}</span>
                <div className={`transactions-container ${expanded ? "total-container-scrollable" : ""}`} onScroll={scrollHandler} style={props.transactions.length < 1 ? {display: "flex", justifyContent: "center", alignItems: "center"} : {}}>
                    {props.transactions.length < 1 ? t("no_data_for_date") :
                    props.transactions.map(record =>
                    <div className='total-container-row' onClick={() => props.onClick(record.safe_id, record.id)} style={record.deactivated ? {opacity: 0.5} : {}}>
                        <div className='total-container-row-user'>
                            {noImg ? <div className='user-circle'>{record.user_name.charAt(0).toUpperCase()}</div>
                            : <img className='total-container-row-user-img' src={record.profile_picture_url} onError={({currentTarget}) => {
                                currentTarget.onerror = null; 
                                setNoImg(true);
                            }}/>}
                            <span className='total-container-row-user-name'>{record.user_name}</span>
                        </div>
                        <div className='total-container-row-category'>
                            <span title={record.safe_name}>{record.safe_name.length > 13 ? "..." + record.safe_name.slice(0, 13) : record.safe_name}</span>
                        </div>
                        <div className='total-container-row-amount'>
                            <span className='amount'>{new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(record.amount)}</span>
                            <span className='date'>{record.date}</span>
                        </div>
                    </div>)}
                </div>
                <button className='total-container-transactions-more' onClick={() => setExpanded(!expanded)}>{`${expanded ? t("less") : t("more")}`}</button>
            </div>
            <div className='total-container-chart'>
                <span className='total-container-chart-header'>
                {t("top_categories")}
                </span>
                <div className='total-container-chart-container'>
                    <PyChart data={props.pieChartData} />
                </div>
                <div className='total-container-chart-footer'>
                    {props.pieChartData.map(data => <div className='row'>
                        <span className='circle' style={{backgroundColor: data.fill}}></span>
                        <span className='name'>{data.name}</span>
                    </div>)}
                </div>
            </div>
        </div>
    );
}