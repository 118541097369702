import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useMutateActivateSafe(onClose, userData) {
  const queryClient = useQueryClient();

  return useMutation(
    (data) => {
      const formData = new FormData();
      formData.append("safe", data.safe_id);
      return apiClient.post(`safe/activation?company_id=${localStorage.getItem("activeCompanyId")}&safe_id=${data.safe_id}`, formData);
    },
    {
      onSuccess: (response, data) => {
        console.log(data);
        toast.info("تم تعديل الخزينة بنجاح");
        queryClient.invalidateQueries("getAllSafes");
        queryClient.invalidateQueries("userData");
        onClose();
      },
      onError: (error) => {
        onClose();
        return error;
      },
    }
  );
}
