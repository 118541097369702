import React, { useState, useEffect } from "react";

import { useLocation } from "react-router-dom";
import Select from "react-select";
import Creatable from "react-select/creatable";

import useFetchUserData from "./../../../hooks/reactQuery/useFetchUserData";
import useFetchSearchMenu from "../../../hooks/reactQuery/useFetchSearchMenu";

import { useDataLayerValue } from "../../../context/DataLayer";
import actionTypes from "../../../context/types";

import Check from "../../resources/icons/Check";
import KeyIcon from "../../resources/icons/KeyIcon";
import Database from "../../resources/icons/Database";
import UserAlt from "../../resources/icons/UserAlt";
import MainFinancial from "../../resources/icons/MainFinancial";
import Note from "../../resources/icons/Note";
import AllOptions from "../../resources/icons/AllOptions";
import SearchIcon from "../../resources/icons/Search";
import { useTranslation } from "react-i18next";
import "./Search.scss";

const Search = ({ searchDropDownApiUrl, activeTab = 0 }) => {
  const { pathname } = useLocation();

  const { data: userData } = useFetchUserData();

  const [{}, dispatch] = useDataLayerValue();

  const [searchTerms, setSearchTerms] = useState([]);

  const [searchType, setSearchType] = useState(() => ({
    label: "الجميع",
    value: "all",
    Icon: AllOptions,
  }));

  const [searchTerm, setSearchTerm] = useState("");

  const {t, i18n} = useTranslation();

  const searchTypes = [
    { label: t("transaction_code"), value: "process_id", Icon: KeyIcon },
    { label: t("category_name"), value: "category_name", Icon: Database },
    pathname === "/documentations"
      ? {
          label: t("parent_money_request"),
          value: "money_request_name",
          Icon: MainFinancial,
        }
      : { label: t("user_name"), value: "user_name", Icon: UserAlt },
    { label: t("transaction_name"), value: "process_name", Icon: Note },
    { label: t("all"), value: "all", Icon: AllOptions },
  ];

  useEffect(() => {
    setSearchType({ label: t("all"), value: "all", Icon: AllOptions });
    setSearchTerms([]);
  }, [activeTab]);

  const updateSearchType = () => {
    dispatch({
      type: actionTypes.SEARCH_TYPE,
      payload: searchType?.value,
    });
  };
  useEffect(() => {
    if (searchType !== "") {
      updateSearchType();
    }
  }, [searchType]);

  const updateSearchTerms = () => {
    dispatch({
      type: actionTypes.SEARCH_TERMS,
      payload: searchTerms?.map((term) => {
        return pathname === "/helicopter" && activeTab === 0
          ? { name: term.label }
          : term.id !== null && term.id !== undefined
          ? { id: term.value }
          : { name: term.label };
      }),
    });
  };
  useEffect(() => {
    updateSearchTerms();
  }, [searchTerms]);

  //

  const styles = (selectType) => ({
    control: (provided, state) => ({
      ...provided,
      minHeight: "unset",
      border: "none",
      outline: "none",
      boxShadow: state.menuIsOpen ? "0 0 0 2px #2563eb" : "0 0 0 1px #CBD5E1",
      transition: "none",
      // maxHeight: "35.657px",
      overflow: "hidden",
      borderRadius:
      i18n.resolvedLanguage === "en" ? "0px" :
        selectType === "typesSelect"
          ? "6px 0 0 6px"
          : selectType === "searchSelect"
          ? "0 6px 6px 0"
          : "6px",
      backgroundColor:
        selectType === "typesSelect"
          ? "#F8FAFC"
          : selectType === "searchSelect"
          ? "#fff"
          : "#fff",
      // zIndex:
      //   searchFocus && !typesFocus ? 60 : !searchFocus && typesFocus ? 60 : 50,
    }),
    option: (provided, state) => ({
      ...provided,
      color: "inherit",
      backgroundColor: (state.isFocused || state.isSelected) && "#F8FAFC",
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 2,
      width:
        selectType === "typesSelect"
          ? "224px"
          : selectType === "searchSelect"
          ? "385px"
          : "100%",
      // left: 0,
      left: selectType === "typesSelect" || i18n.resolvedLanguage === "en" && 0,
      right: selectType === "searchSelect" && i18n.resolvedLanguage === "ar" && 0,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      padding: 0,
      backgroundColor: state.isSelected ? "#DEEBFF" : provided.backgroundColor,
    }),
  });

  const optionsFormatOptionLabel = (option, context) => (
    <div
      aria-details="option-search-result-container"
      className="flex justify-between items-center px-4 p-2"
    >
      <div className="flex justify-center items-center gap-x-3.5">
        {option.hasOwnProperty("profile_picture_ext") && (
          <div aria-details="option-search-result-layout">
            {option.profile_picture_ext ? (
              <img
                className="w-6 h-6 rounded-full max-w-full"
                src={option.url}
                alt="user"
              />
            ) : (
              <div className="w-6 h-6 rounded-full flex justify-center items-center bg-onhold text-sm font-medium text-body">
                {option.name[0]}
              </div>
            )}
          </div>
        )}

        <p
          className={`text-sm text-body font-medium ${
            context.selectValue && context.selectValue[0]?.id === option?.id
              ? "font-medium"
              : ""
          }`}
        >
          {option.label}
        </p>

        {option.hasOwnProperty("user_name") && (
          <p
            aria-details="option-search-result-layout"
            className="text-xs leading-5 font-medium text-hints"
          >
            {option.user_name}
          </p>
        )}
      </div>
    </div>
  );

  const customFormatOptionLabel = (option, context) => {
    return (
      <div
        aria-details="option-search-types-container"
        className={`flex justify-between items-center px-4 p-2 ${
          option.label === t("transaction_code") ? "border-b border-separator" : ""
        } ${option.label === t("all") ? "border-t border-separator" : ""}`}
      >
        <>
          <div
            className="flex justify-center items-center gap-x-3.5"
            aria-details="option-search-types-layout"
          >
            <div>
              <option.Icon />
            </div>
            <p
              className={`text-sm text-body font-normal ${
                context.selectValue &&
                context.selectValue[0]?.value === option?.value
                  ? "font-medium"
                  : ""
              }`}
            >
              {option.label}
            </p>
          </div>
          {context.selectValue &&
            context.selectValue[0]?.value === option?.value && (
              <div>
                <Check />
              </div>
            )}
        </>
      </div>
    );
  };

  const [searchFocus, setSearchFocus] = useState(false);
  const [typesFocus, setTypesFocus] = useState(false);

  const { isLoading: isSearchMenuLoading, data: searchMenuData } =
    useFetchSearchMenu({
      apiUrl: searchDropDownApiUrl,
      searchType: searchType?.value,
      searchTerm: searchTerm,
      sentOrReceived: activeTab,
      activeCompanyId: userData.activeCompanyId,
    });

  return (
    <>
      <div className="grid grid-cols-8" style={{ maxWidth: "385px" }}>
        <div
          className={`col-span-5 ${
            searchFocus && !typesFocus ? "z-10" : "z-5"
          }`}
        >
          <Creatable
            placeholder={
              <div className="flex gap-x-2.5" style={i18n.resolvedLanguage === "en" ? { borderRadius: "0px" } : {}}>
                <div>
                  <SearchIcon />
                </div>
                <p className="text-sm font-medium text-side-icons">
                  {t("search_placeholder")}
                </p>
              </div>
            }
            isMulti
            options={
              searchMenuData
                ? searchMenuData.length > 0
                  ? [...searchMenuData]
                  : []
                : []
            }
            isLoading={isSearchMenuLoading}
            isClearable
            isDisabled={!searchType}
            value={searchTerms}
            onChange={(selectedValues) =>
              setSearchTerms(() => {
                return selectedValues ? [...selectedValues] : [];
              })
            }
            onInputChange={(searchTerm) => setSearchTerm(searchTerm)}
            formatCreateLabel={(inputText) => `${inputText} ابحث عن`}
            formatOptionLabel={optionsFormatOptionLabel}
            menuPlacement="auto"
            styles={styles("searchSelect")}
            createOptionPosition="first"
            components={{
              DropdownIndicator: () => <></>,
            }}
            onFocus={() => setSearchFocus(true)}
            onBlur={() => setSearchFocus(false)}
          />
        </div>
        <div
          className={`col-span-3 ${
            !searchFocus && typesFocus ? "z-10" : "z-5"
          }`}
        >
          <Select
            placeholder=""
            options={
              pathname === "/safe" && activeTab === 0
                ? searchTypes.filter((type) => type.value !== "process_name")
                : searchTypes
            }
            value={searchType}
            onChange={(selectedValue) => setSearchType(() => selectedValue)}
            styles={styles("typesSelect")}
            isSearchable={false}
            menuPlacement="auto"
            formatOptionLabel={customFormatOptionLabel}
            onFocus={() => setTypesFocus(true)}
            onBlur={() => setTypesFocus(false)}
          />
        </div>
      </div>
    </>
  );
};

export default Search;
