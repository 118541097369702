import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchCompaniesData(userId) {
  return useQuery(["companiesBalancesData", userId], () =>
    apiClient
      ?.get(`company/${userId}/companiesBalances`)
      .then(({ data }) => data)
      .catch((e) => e)
  );
}
