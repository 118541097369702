import HeaderSection from "../../components/common/HeaderSection";
import { useEffect, useState, useRef } from "react";
import { useDataLayerValue } from "../../context/DataLayer";
import useFetchHelicopter from "../../hooks/reactQuery/useFetchHelicopter";
import useFetchUserBalance from "../../hooks/reactQuery/useFetchUserBalance";
import Table from "../../components/common/Table";
import { displayDate } from "../../utils/dateFunctions";
import "./Helicopter.scss";
import { readLines } from "../../utils/multiLines";
import Spinner from "../../components/common/Spinner";
import BlueDotSvg from "../../components/resources/blue dot svg/BlueDotSvg";
import useFetchFinLength from "../../hooks/reactQuery/useFetchFinLength";
import useFetchDocsCount from "../../hooks/reactQuery/useFetchDocsCount";
import Modal from "../../components/common/Modal";
import CloseAndDetails from "../../components/moneyRequest/CloseAndDetails";
import useFetchUserData from "../../hooks/reactQuery/useFetchUserData";
import folders from "../../constants/static_folders";
import { useTranslation } from "react-i18next";
import DateRangePickerComponent from "../../components/common/DateRangePicker/DateRangePicker";
import Selector from "../../components/resources/icons/Selector";
import {
  startOfMonth,
  addYears,
  endOfMonth
} from "date-fns";

const Helicopter = ({}) => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();

  const [{ activeTab, searchType, searchTerms }] = useDataLayerValue();
  const [showDatePicker, setShowDatePicker] = useState(false);

  const filterBtnRef = useRef(null);
  const datePickerRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", e => {
      if(datePickerRef && datePickerRef.current && filterBtnRef && filterBtnRef.current && showDatePicker) {
        if(!datePickerRef.current.contains(e.target) && !filterBtnRef.current.contains(e.target)) setShowDatePicker(false);
      }
    });
    
    return () => {
      document.removeEventListener("click", e => {
        if(datePickerRef && datePickerRef.current && filterBtnRef && filterBtnRef.current && showDatePicker) {
          if(!datePickerRef.current.contains(e.target) && !filterBtnRef.current.contains(e.target)) setShowDatePicker(false);
        }
      });
    }
  }, [showDatePicker]);

  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: startOfMonth(addYears(new Date(), -50)),
      endDate: endOfMonth(new Date()),
      label: t("all"),
      key: "selection"
    }
  ]);

  const static_base_url =
    process.env.REACT_APP_UPLOAD_FILES_BASE_URL +
    process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH;

  const getAttachmentIndex = (attachments, attachment, index) => {
    const attachmentName = attachment.name ? attachment.name : attachment;
    return attachments
      .slice(0, index)
      .filter((att) => att.name === attachmentName).length;
  };

  const [activeRow, setActiveRow] = useState({});
  const pageSize = 50;
  const [currentPage, setCurrentPage] = useState(1);
  const [columnKey, setColumnKey] = useState("");
  const [order, setOrder] = useState("");
  const [expandedRows, setExpandedRows] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  useEffect(() => {
    setFromDate(selectionRange[0].startDate);
    setToDate(selectionRange[0].endDate);
  }, [selectionRange]);

  const [showloseAndDetails, setShowloseAndDetails] = useState(false);

  const {
    isLoading: isFinLengthLoading,
    data: finLengthData,
    error: finLengthError,
  } = useFetchFinLength(
    expandedRows && expandedRows[0] && expandedRows[0].id.split("_")[0],
    expandedRows && expandedRows[0] && expandedRows[0].type == 2
  );
  const {
    isLoading: isDocsCountLoading,
    data: docsCountData,
    error: docsCountError,
  } = useFetchDocsCount(
    expandedRows && expandedRows[0] && expandedRows[0].id.split("_")[0],
    expandedRows && expandedRows[0] && expandedRows[0].type == 2
  );

  const {
    isLoading: isUserBalanceLoading,
    data: userBalance,
    error: userBalanceError,
  } = useFetchUserBalance();

  const {
    isLoading: isTableDataLoading,
    data: tableData,
    error: tableDataError,
    isPreviousData,
  } = useFetchHelicopter(
    activeTab,
    currentPage,
    pageSize,
    order,
    columnKey,
    searchType,
    searchTerms,
    fromDate,
    toDate
  );

  const columnsHeader = [
    {
      title: t("transaction_code"),
      dataIndex: "id",
      key: "id",
      render: (text, row) => <div className="flex justify-start" style={row.deactivated ? {opacity: 0.5} : {}}>{text}</div>,
      width: 120,
    },
    // {
    //   title: "اسم العملية",
    //   dataIndex: "name",
    //   key: "name",
    //   width: 120,
    // },
    {
      title: t("sender"),
      dataIndex: "sender_name",
      key: "sender_name",
      render: (text, row) => <div className="flex justify-start" style={row.deactivated ? {opacity: 0.5} : {}}>{text}</div>,
      width: 120,
    },
    {
      title: activeTab !== 3 ? t("recipient") : t("creation_date"),
      dataIndex: activeTab === 3 ? "creation_date" : "recipient_name",
      key: activeTab === 3 ? "creation_date" : "recipient_name",
      render: (text, row, index) => {
        return activeTab === 3 ? (
          <div className="flex justify-start" style={row.deactivated ? {opacity: 0.5} : {}}>{displayDate(row.creation_date)}</div>
        ) : (
          <div className="flex justify-start" style={row.deactivated ? {opacity: 0.5} : {}}>{row.recipient_name}</div>
        );
      },
      width: 120,
    },
    {
      title: t("amount"),
      dataIndex: "amount",
      key: "amount",
      render: (text, row) => <div className="flex justify-start" style={row.deactivated ? {opacity: 0.5} : {}}>{text}</div>,
      sorter: (a, b) => a.amount - b.amount,
      width: 120,
    },
    {
      title:
        activeTab === 0
          ? t("creation_date")
          : activeTab === 3
          ? t("spend_date")
          : t("creation_date"),
      dataIndex: activeTab !== 3 ? "creation_date" : "payment_date",
      key: activeTab !== 3 ? "creation_date" : "payment_date",
      sorter: (a, b) =>
        activeTab === 3
          ? a.creation_date - b.creation_date
          : a.payment_date - b.payment_date,
      render: (text, row, index) => {
        return (
          <div className="flex justify-start" style={row.deactivated ? {opacity: 0.5} : {}}>
            {displayDate(
              activeTab === 3 ? row.payment_date : row.creation_date
            )}
          </div>
        );
      },
      width: 120,
    },
    {
      title: t("category"),
      dataIndex: "category_name",
      key: "category_id",
      render: (text, row) => <div className="flex justify-start" style={row.deactivated ? {opacity: 0.5} : {}}>{text}</div>,
      sorter: (a, b) => a.category_name - b.category_name,
      width: 120,
    },
    {
      title:
        activeTab === 0
          ? t("transaction_type")
          : activeTab === 1
          ? t("permission_status")
          : activeTab === 2
          ? t("money_request_status")
          : t("subordinate_money_request"),
      dataIndex:
        activeTab === 0
          ? "request_type"
          : activeTab === 1
          ? "approved"
          : activeTab === 2
          ? "status"
          : "money_request_name",
      key:
        activeTab === 0
          ? "request_type"
          : activeTab === 1
          ? "approved"
          : activeTab === 2
          ? "status"
          : "money_request_name",
      render: (text, row, index) => {
        return activeTab === 0 ? (
          <p className="highlighted-btn flex justify-start" style={row.deactivated ? {opacity: 0.5} : {}}>
            {row.request_type === 1
              ? t("spend_request")
              : row.request_type === 2
              ? row.withdrawn === 1
                ? t("withdraw")
                : t("money_request")
              : row.request_type === 3
              ? t("document")
              : t("deposit")}
          </p>
        ) : activeTab === 1 ? (
          <p className={`flex justify-start`} style={row.deactivated ? {opacity: 0.5} : {}}>
            {row.approved === 1 ? (
              <div style={row.deactivated ? {opacity: 0.5} : {}}>
                <div className="status-icon">
                  <img
                    style={{ paddingRight: "unset" }}
                    src="../img/accepted_icon.svg"
                    alt="accepted request"
                    className="request-status"
                  />
                  <span className="status-hover">{t("accepted")}</span>
                </div>
              </div>
            ) : row.approved === 0 ? (
              <div style={row.deactivated ? {opacity: 0.5} : {}}>
                <div className="status-icon">
                  <img
                    style={{ paddingRight: "unset" }}
                    src="../img/rejected.svg"
                    alt="accepted request"
                    className="request-status"
                  />
                  <span className="status-hover">{t("rejected")}</span>
                </div>
              </div>
            ) : (
              <div style={row.deactivated ? {opacity: 0.5} : {}}>
                <div className="status-icon">
                  <img
                    style={{ paddingRight: "unset" }}
                    src="../img/pending.svg"
                    alt="not reviewed"
                    className="request-status"
                  />
                  <span className="status-hover">{t("transaction_pending")}</span>
                </div>
              </div>
            )}
          </p>
        ) : activeTab === 2 ? (
          row.approved === 1 ? (
            row.status === 1 ? (
              <div className={`flex justify-start`} style={row.deactivated ? {opacity: 0.5} : {}}>
                <div className="status-icon">
                  <img
                    src="../img/open-icon.svg"
                    alt="accepted request"
                    className="request-status"
                  />
                  <span className="status-hover status-hover--request">
                    {t("transaction_running")}
                  </span>
                </div>
              </div>
            ) : (
              <div style={row.deactivated ? {opacity: 0.5} : {}}>
                <div className="status-icon">
                  <img
                    src="../img/closed-icon.svg"
                    alt="rejected request"
                    className="request-status"
                  />
                  <span className="status-hover status-hover--request">
                    {t("transaction_closed")} 
                  </span>
                </div>
              </div>
            )
          ) : (
            <div style={row.deactivated ? {opacity: 0.5} : {}}>
              <div className="status-icon">
                <img
                  src="../img/_____.svg"
                  alt="rejected request"
                  className="request-status"
                />
                <span className="status-hover status-hover--request">
                  {t("money_request_no_status")}
                </span>
              </div>
            </div>
          )
        ) : (
          <div className={`flex ${i18n.resolvedLanguage === "en" ? "justify-start" : "justify-end"}`} style={row.deactivated ? {opacity: 0.5} : {}}>{row.money_request_name}</div>
        );
      },
      width: 120,
    },
  ];

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTab, order, columnKey, searchType, searchTerms]);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setOrder(sorter?.order);
    setColumnKey(sorter?.columnKey);
  };

  const onTableRowExpand = (expanded, record) => {
    var keys = [];
    if (expanded) {
      keys.push({
        id: record.id + "_" + record.request_type,
        type: record.request_type,
      });
      setActiveRow(record);
    }
    setExpandedRows(keys);
  };

  const [selectedRows, setSelectedRows] = useState([]);

  return (
    <>
      <div>
        <HeaderSection
          searchDropDownApiUrl={"admin/all/dropdowndata"}
          activeTab={activeTab}
          setColumns={columnsHeader}
          originalColumnsHeader={columnsHeader}
          selectedRows={selectedRows}
          hasToolbar={false}
        />

        <div className='total-section-header px-4'>
          <button className='total-section-filter-btn' onClick={() => setShowDatePicker(!showDatePicker)} ref={filterBtnRef}>
            <Selector fill="#3B82F6"/>{selectionRange[0].label}
          </button>
          {showDatePicker ? <div className='total-section-date-picker' style={{direction: "ltr"}} ref ={datePickerRef}>
          <DateRangePickerComponent selectionRange={selectionRange} setSelectionRange={setSelectionRange} />
        </div> : null}           
        </div>

        <div className="mx-4 my-3">
          <Table
            data={tableData && tableData.data}
            columns={columnsHeader}
            isLoading={isTableDataLoading}
            error={tableDataError}
            currentPage={currentPage}
            pageSize={pageSize}
            total={tableData && tableData.overall_count}
            isPaginationDisabled={isPreviousData}
            onPageChange={onPageChange}
            setSelectedRows={setSelectedRows}
            onChange={handleTableChange}
            expandedRows={expandedRows.map((item) => item.id)}
            setExpandedRows={setExpandedRows}
            onTableRowExpand={onTableRowExpand}
            textEmptyPage={
              t("helicopter_empty_page")
            }
            expandedRow={(record, index, indent, expanded) =>
              record.request_type === 1 ? (
                <div className={`grid grid-cols-6 gap-y-2 ${i18n.resolvedLanguage === "en" ? "text-left ml-10" : "text-right mr-10"}`}>
                  <div className="col-span-1">
                    <p>{t("main_category")}</p>
                    <p>{record.category_name}</p>
                  </div>
                  <div className="col-span-1">
                    <p>{t("subcategory")}</p>
                    <p>
                      {record.subcategory_name ? record.subcategory_name : "-"}
                    </p>
                  </div>
                  <div className="col-span-1">
                    <p>{t("max_delivery_time")}</p>
                    <p>{displayDate(record.request_date)}</p>
                  </div>
                  <div className="col-span-1">
                    <p>{t("response_status")}</p>
                    <p>{record.approved == 1 ? t("accepted") : t("rejected")}</p>
                  </div>
                  <div className="col-span-1">
                    <p>{t("request_status")}</p>
                    <p>{record.urgent == 1 ? t("urgent") : t("inessential")}</p>
                  </div>
                  <div className="col-span-3 row-start-2">
                    <p>{t("notes")} {record.sender_name}</p>
                    <p>
                      {record.notes
                        ? readLines(record.notes)
                        : t("no_notes")}
                    </p>
                  </div>
                  <div className="col-span-5 mt-2">
                    <p className="flex items-center">
                      {" "}
                      {t("attachments")} {record.sender_name}
                    </p>
                    <p>
                      {record.original_attachments ? (
                        record.original_attachments.map((attachment, index) => (
                          <a
                            className="file-preview"
                            href={`${static_base_url}/${
                              folders.spend_requests
                            }/${record.id}/${
                              getAttachmentIndex(
                                record.original_attachments,
                                attachment,
                                index
                              ) > 0
                                ? getAttachmentIndex(
                                    record.original_attachments,
                                    attachment,
                                    index
                                  ) + "/"
                                : ""
                            }${attachment}`}
                            target="_blank"
                            rel="noreferrer"
                            key={index}
                          >
                            <span>
                              {attachment.length > 18
                                ? "..." + attachment.substr(0, 15)
                                : attachment}
                            </span>
                          </a>
                        ))
                      ) : (
                        <p>{t("no_attachments")}</p>
                      )}
                    </p>
                  </div>
                  <div className="col-span-5 mt-2">
                    <p>{t("notes")} {record.recipient_name}</p>
                    <p>
                      {record.response_notes
                        ? readLines(record.response_notes)
                        : t("no_notes")}
                    </p>
                  </div>
                  <div className="col-span-5 mt-2">
                    <p className="flex items-center">
                      {" "}
                      {t("attachments")} {record.recipient_name}
                    </p>
                    <p>
                      {record.replied_attachments ? (
                        record.replied_attachments.map((attachment, index) => (
                          <a
                            className="file-preview"
                            href={`${static_base_url}/${
                              folders.spend_requests
                            }/${record.id}/${
                              getAttachmentIndex(
                                record.replied_attachments,
                                attachment,
                                index
                              ) > 0
                                ? getAttachmentIndex(
                                    record.replied_attachments,
                                    attachment,
                                    index
                                  ) + "/"
                                : ""
                            }${attachment}`}
                            target="_blank"
                            rel="noreferrer"
                            key={index}
                          >
                            <span>
                              {attachment.length > 18
                                ? "..." + attachment.substr(0, 15)
                                : attachment}
                            </span>
                          </a>
                        ))
                      ) : (
                        <p>{t("no_attachments")}</p>
                      )}
                    </p>
                  </div>
                </div>
              ) : record.request_type === 2 ? (
                record.withdrawn === 0 ? (
                  record.approved === null ? (
                    <div className="grid grid-cols-5 mr-20">
                      <div className="col-span-3">
                        <p>{t("account_balance")}</p>
                        <p>{record.sender_balance}</p>
                      </div>
                      <div className="col-span-3 mt-2">
                        <p>{t("money_request_purposre")}</p>
                        <p>{record.description}</p>
                      </div>
                      <div className="col-span-3 mt-2">
                        <p className="flex items-center">
                          {t("attachments")}
                        </p>
                        <p className="flex items-center">
                          {record.original_attachments ? (
                            record.original_attachments.map(
                              (attachment, index) => (
                                <a
                                  className="ml-2 text-red-400"
                                  href={`${static_base_url}/${
                                    folders.money_requests
                                  }/${record.id}/${
                                    getAttachmentIndex(
                                      record.original_attachments,
                                      attachment,
                                      index
                                    ) > 0
                                      ? getAttachmentIndex(
                                          record.original_attachments,
                                          attachment,
                                          index
                                        ) + "/"
                                      : ""
                                  }${attachment}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  key={index}
                                >
                                  <span>
                                    {attachment.length > 18
                                      ? "..." + attachment.substr(0, 15)
                                      : attachment}
                                  </span>
                                </a>
                              )
                            )
                          ) : (
                            <p>{t("no_attachments")}</p>
                          )}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className={`grid grid-cols-5 ${i18n.resolvedLanguage === "en" ? "text-left ml-10" : "text-right mr-10"}`}>
                      <div className="col-span-1">
                        <p>{t(
                          "send_date"
                        )}</p>
                        <p>{displayDate(record.creation_date)}</p>
                      </div>
                      <div className="col-span-1">
                        <p>{t("num_of_documents")}</p>
                        <p>
                          {isFinLengthLoading || isDocsCountLoading ? (
                            <div className="w-6 h-6">
                              <Spinner />
                            </div>
                          ) : (
                            <div className="flex items-center">
                              <span>
                                {+finLengthData + +docsCountData} مصروف
                              </span>
                              <div className="mx-2 flex items-center col-span-1 tool-pin-container relative">
                                <button
                                  onClick={() => {
                                    setShowloseAndDetails(true);
                                  }}
                                >
                                  <BlueDotSvg />
                                </button>
                                <p className="tool-pin w-max px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-6 -right-20 z-10">
                                  {t("open_money_request_documents")}
                                </p>
                              </div>
                            </div>
                          )}
                        </p>
                      </div>

                      {record.sender_balance && (
                        <div className="col-span-1">
                          <p>{t("account_balance")}</p>
                          <p>{record.sender_balance}</p>
                        </div>
                      )}
                      {record.closing_date && (
                        <div className="col-span-1">
                          <p>{t("closing_date")}</p>
                          <p>{displayDate(record.closing_date)}</p>
                        </div>
                      )}
                      <div className="col-span-1">
                        <p>
                          {record.status === 1
                            ? t("current_amount")
                            : t("closing_amount")}
                        </p>
                        <p>{record.remaining_amount}</p>
                      </div>
                      {record.parent_aid_name && (
                        <div className="col-span-1">
                          <p>{t("parent_money_request")}</p>
                          <p>{record.parent_aid_name}</p>
                        </div>
                      )}
                      <div className="col-span-5 mt-2">
                        <p>{t("money_request_purpose")}</p>
                        <p>{record.description}</p>
                      </div>
                      <div className="col-span-5 mt-2">
                        <p>{t("your_response")}</p>
                        <p>
                          {record.response_notes
                            ? record.response_notes
                            : t("no_response")}
                        </p>
                      </div>
                      <div className="col-span-3 mt-2">
                        <p className="flex items-center">{t("attachments")}</p>
                        <p>
                          {record.response_attachments ? (
                            record.response_attachments.map(
                              (attachment, index) => (
                                <a
                                  className="file-preview"
                                  href={`${static_base_url}/${
                                    folders.money_requests
                                  }/${record.id}/${
                                    getAttachmentIndex(
                                      record.response_attachments,
                                      attachment,
                                      index
                                    ) > 0
                                      ? getAttachmentIndex(
                                          record.response_attachments,
                                          attachment,
                                          index
                                        ) + "/"
                                      : ""
                                  }${attachment}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  key={index}
                                >
                                  <span>
                                    {attachment.length > 18
                                      ? "..." + attachment.substr(0, 15)
                                      : attachment}
                                  </span>
                                </a>
                              )
                            )
                          ) : (
                            <p>{t("no_attachments")}</p>
                          )}
                        </p>
                      </div>
                    </div>
                  )
                ) : (
                  <div className={`grid grid-cols-5 ${i18n.resolvedLanguage === "en" ? "text-left ml-10" : "text-right mr-10"}`}>
                    <div className="col-span-1">
                      <p>{t("num_of_documents")}</p>
                      <p>
                        {isFinLengthLoading || isDocsCountLoading ? (
                          <div className="w-6 h-6">
                            <Spinner />
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <span>{+finLengthData + +docsCountData} مصروف</span>
                            <div className="mx-2 flex items-center col-span-1 tool-pin-container relative">
                              <button
                                onClick={() => {
                                  setShowloseAndDetails(true);
                                }}
                              >
                                <BlueDotSvg />
                              </button>
                              <p className="tool-pin w-max px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-6 -right-20 z-10">
                                {t("open_money_request_documents")}
                              </p>
                            </div>
                          </div>
                        )}
                      </p>
                    </div>
                    <div className="col-span-1">
                      <p>{t("account_balance")}</p>
                      <p>{record.sender_balance}</p>
                    </div>
                    {record.status === 1 ? (
                      <div className="col-span-1">
                        <p>{t("current_amount")}</p>
                        <p>{record.remaining_amount}</p>
                      </div>
                    ) : (
                      <>
                        <div className="col-span-1">
                          <p>{t("closing_amount")}</p>
                          <p>{record.remaining_amount}</p>
                        </div>

                        <div className="col-span-1">
                          <p>{t("closing_date")}</p>
                          <p>{displayDate(record.closing_date)}</p>
                        </div>
                      </>
                    )}

                    <div className="col-span-5 mt-2">
                      <p>{t("transaction_notes")}</p>
                      <p>
                        {!record.description
                          ? t("no_notes")
                          : record.description}
                      </p>
                    </div>

                    {/*  */}
                    <div className="col-span-5 mt-2">
                      <p className="flex items-center">{t("attachments")}</p>
                      <p>
                        {record["original_attachments"] ? (
                          record["original_attachments"].map(
                            (attachment, index) => (
                              <a
                                className="file-preview"
                                href={`${static_base_url}/${
                                  folders.withdrawals
                                }/${record.id}/${
                                  getAttachmentIndex(
                                    record["original_attachments"],
                                    attachment,
                                    index
                                  ) > 0
                                    ? getAttachmentIndex(
                                        record["original_attachments"],
                                        attachment,
                                        index
                                      ) + "/"
                                    : ""
                                }${attachment}`}
                                target="_blank"
                                rel="noreferrer"
                                key={index}
                              >
                                <span>
                                  {attachment.length > 18
                                    ? "..." + attachment.substr(0, 15)
                                    : attachment}
                                </span>
                              </a>
                            )
                          )
                        ) : (
                          <p>{t("no_attachments")}</p>
                        )}
                      </p>
                    </div>
                  </div>
                )
              ) : record.request_type === 3 ? (
                <div className="grid grid-cols-5 mr-20">
                  <div className="col-span-5 mt-2">
                    <p>{t("spend_purpose")}</p>
                    <p>
                      {!record.description
                        ? t("no_notes")
                        : record.description}
                    </p>
                  </div>
                  <div className="col-span-5 mt-2">
                    <p className="flex items-center">{t("attachments")}</p>
                    <p>
                      {record.attachments ? (
                        record.attachments.map((attachment, index) => (
                          <a
                            className="file-preview"
                            href={`${static_base_url}/${folders.documents}/${
                              record.id
                            }/${
                              getAttachmentIndex(
                                record.attachments,
                                attachment,
                                index
                              ) > 0
                                ? getAttachmentIndex(
                                    record.attachments,
                                    attachment,
                                    index
                                  ) + "/"
                                : ""
                            }${attachment}`}
                            target="_blank"
                            rel="noreferrer"
                            key={index}
                          >
                            <span>
                              {attachment.length > 18
                                ? "..." + attachment.substr(0, 15)
                                : attachment}
                              {record.attachments + "dfdf"}
                            </span>
                          </a>
                        ))
                      ) : (
                        <p>{t("no_attachments")}</p>
                      )}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="grid grid-cols-5 mr-20">
                  <div className="col-span-5 mt-2">
                    <p>{t("transaction_notes")}</p>
                    <p>
                      {activeTab === 0 && !record.notes
                        ? t("no_notes")
                        : record.notes}
                      {activeTab === 1 && !record.description
                        ? t("no_notes")
                        : record.description}
                    </p>
                  </div>
                  <div className="col-span-5 mt-2">
                    <p className="flex items-center">{t("attachments")}</p>
                    <p>
                      {record[
                        activeTab === 0 ? "attachments" : "original_attachments"
                      ] ? (
                        record[
                          activeTab === 0
                            ? "attachments"
                            : "original_attachments"
                        ].map((attachment, index) => (
                          <a
                            className="file-preview"
                            href={`${static_base_url}/${
                              activeTab === 0
                                ? folders.deposits
                                : folders.money_requests
                            }/${record.id}/${
                              getAttachmentIndex(
                                record[
                                  activeTab === 0
                                    ? "attachments"
                                    : "original_attachments"
                                ],
                                attachment,
                                index
                              ) > 0
                                ? getAttachmentIndex(
                                    record[
                                      activeTab === 0
                                        ? "attachments"
                                        : "original_attachments"
                                    ],
                                    attachment,
                                    index
                                  ) + "/"
                                : ""
                            }${attachment}`}
                            target="_blank"
                            rel="noreferrer"
                            key={index}
                          >
                            <span>
                              {attachment.length > 18
                                ? "..." + attachment.substr(0, 15)
                                : attachment}
                            </span>
                          </a>
                        ))
                      ) : (
                        <p>{t("no_attachments")}</p>
                      )}
                    </p>
                  </div>
                </div>
              )
            }
          />
        </div>
      </div>
      <Modal
        showModal={showloseAndDetails}
        onClose={() => setShowloseAndDetails(false)}
      >
        <CloseAndDetails
          onClose={() => setShowloseAndDetails(false)}
          type="moneyRequestDetails"
          docId={
            expandedRows && expandedRows[0] && expandedRows[0].id.split("_")[0]
          }
          activeRow={activeRow}
          showFooter={false}
          inValidteQueryKey="getSafeOutData"
        />
      </Modal>
    </>
  );
};

export default Helicopter;
