import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchFinLength(finId, isMoneyRequest = true) {
  return useQuery(
    ["finLength", finId],
    () => {
      return apiClient
        ?.get(`money_request/${finId}/finlength`, {
          params: { id: finId },
        })
        .then(({ data }) => data[0].requestsLength)
        .catch((e) => e);
    },
    {
      enabled: isMoneyRequest && !isNaN(finId),
    }
  );
}
