import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchTeam({
  pageSize,
  currentPage,
  order,
  columnKey,
}) {
  const params = {};
  params.pageno = currentPage;
  params.pagesize = pageSize;

  if (order && columnKey) {
    params.isascending =
      order === "ascend" ? 1 : order === "descend" ? 0 : null;

    params.orderbycolumn = columnKey;
    if (columnKey === "name") {
      params.orderbycolumn = "first_name";
    }
  }

  return useQuery(
    ["team", currentPage, order, columnKey],
    () =>
      apiClient
        .get("user/all", {
          params,
        })
        .then(({ data }) => data),
    { keepPreviousData: true }
  );
}
