export const toolPin = (text) => (
  <div className="tool-pin-container relative inline-block">
    {text?.slice(0, 20)}
    {text?.length > 20 && "..."}
    {text?.length > 20 && (
      <p id="mail" className="tool-pin max-w-full px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-4 z-10">
        {text}
      </p>
    )}
  </div>
);
