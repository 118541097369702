import { useState, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Table from "../../../common/Table";
import { ColumnsHeader } from "./tableColumnsHeader";
import useMutateMoneyRequestUpdateChecked from "../../../../hooks/reactQuery/useMutateMoneyRequestUpdateChecked";
import { useQueryClient } from "react-query";
import useFetchUserData from "../../../../hooks/reactQuery/useFetchUserData";
import useFetchActiveMoneyRequests from "../../../../hooks/reactQuery/useFetchActiveMoneyRequests";
import "./ActiveMoneyRequests.scss";
import { previousSaturday } from "date-fns";
import { useTranslation } from "react-i18next";

const ActiveMoneyRequests = ({safeId}) => {
  const {t, i18n} = useTranslation();
  const { data: userData } = useFetchUserData();

  const history = useHistory();

  const [expanded, setExpanded] = useState(false);
  const [lastPage, setLastPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const pageSize = 10;
  const [columnKey, setColumnKey] = useState("");
  const [order, setOrder] = useState("");

  const location = useLocation();

  const {
    isLoading: isTableDataLoading,
    data: tableData,
    error: tableDataError,
  } = useFetchActiveMoneyRequests(
    safeId,
    currentPage,
    pageSize,
    columnKey,
    order,
    currentPage,
  );

  const [columnsHeader, setColumnsHeader] = useState(() => ColumnsHeader(
    tableData && tableData.length > 0 ? tableData[1][0].total_amount : 0,
    tableData && tableData.length > 0 ? tableData[1][0].total_remaining_amount : 0,
    tableData && tableData.length > 0 ? tableData[2][0].total_document_amount : 0,
    safeId,
    t,
    i18n
  ).filter(obj => obj.selected));

  useEffect(() => {
    if(tableData && tableData.length > 0) {
      setColumnsHeader(() => ColumnsHeader(
        tableData[1][0].total_amount, tableData[1][0].total_remaining_amount,
        tableData[2][0].total_document_amount,
        safeId,
        t,
        i18n
      ).filter(obj => obj.selected));
      setLastPage(Math.ceil(tableData[3][0].count / pageSize));
    }
  }, [tableData]);

  const [memoizedTableData, setMemoizedTableData] = useState([]);

  useEffect(() => {
    if(tableData && tableData.length > 0) {
      if(parseInt(tableData[3][0].currentPage) > previousPage) {
        setMemoizedTableData(oldData => [...oldData, ...tableData[0]]);
        setPreviousPage(parseInt(tableData[3][0].currentPage));
      }
      else {
        setMemoizedTableData(tableData[0]);
      }
    }
  }, [tableData]);

  const handleTableChange = (pagination, filters, sorter) => {
    setOrder(sorter?.order);
    setColumnKey(sorter?.columnKey);
    setCurrentPage(1);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const [expandedRows, setExpandedRows] = useState([]);

  const onTableRowExpand = (expanded, record) => {
    //if(expanded) history.push(`/safes/${userData.safes[0].id}/money_request/${record.id}`);
    if(expanded) history.push({
      pathname: `/safes/${record.safeId ? record.safeId : userData.safes[0].id}/money_request/${record.id}`,
      state: { comingFrom: t("running_money_request_data"), fallback: "/safe", title: t("money_request")}
    });
    setExpandedRows([]);
  };

  const [scrolledToBottom, setScrolledToBottom] = useState(false);


  const scrollHandler = (e) => {
    if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight - 60) { 
      setScrolledToBottom(true);
    } 
    else {
      setScrolledToBottom(false);
    }
  }

  useEffect(() => {
    if(scrolledToBottom && !isTableDataLoading ) {
      if(currentPage < lastPage) {
        setPreviousPage(currentPage);
        setCurrentPage(currentPage => currentPage + 1);
      }
    }
  }, [scrolledToBottom]);

  const [selectedRows, setSelectedRows] = useState([]);

  return (
    <div className="active-money-requests">   
      <div className="active-money-requests-table" style={expanded ? {height: "500px", paddingBottom: "40px"} : {height: "300px", overflow: "hidden"}} onScroll={scrollHandler}>
        <Table
          rowKey="id"
          data={memoizedTableData}
          columns={columnsHeader}
          isLoading={isTableDataLoading}
          error={tableDataError}
          pageSize={memoizedTableData.length}
          total={memoizedTableData.length}
          currentPage={1}
          isPaginationDisabled={true}
          onChange={handleTableChange}
          onPageChange={onPageChange}
          setSelectedRows={setSelectedRows}
          expandedRows={expandedRows}
          setExpandedRows={setExpandedRows}
          onTableRowExpand={onTableRowExpand}
          expandedRow={() => <></>}
          hasFooter={false}
          scrollable={true}
          hasSelection={false}
          maxHeight={expanded ? "500px" : "300px"}
          onScroll={scrollHandler}
        />
      </div>
      <button className="active-money-requests-more" onClick={() => setExpanded(isExpanded => !isExpanded)}>{`${expanded ? t("less") : t("more")}`}</button>
    </div>
  );
};

export default ActiveMoneyRequests;
