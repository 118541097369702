import Export from "../../../resources/icons/Export";
import { exportToCSV } from "../../../../utils/exportToExcel";
import { useTranslation } from "react-i18next";

const ExportToExcel = ({ selectedRows, excelFileName }) => {

  const {t, i18n} = useTranslation();

  return (
    <div className="relative inline-block text-left h-full">
      <button
        className={`h-10 export-table-btn transition-all duration-300 border border-transparent hover:border-secondary-hover flex items-center justify-center rounded-md ${
          selectedRows?.length === 0 ? "cursor-not-allowed" : ""
        }`}
        onClick={() => {
          exportToCSV(selectedRows, excelFileName);
        }}
        disabled={selectedRows?.length === 0}
      >
        <span className="absolute left-8 w-max">{t("export")}</span>
        <div className="absolute left-1 export-table">
          <Export />
        </div>
      </button>
    </div>
  );
};

export default ExportToExcel;
