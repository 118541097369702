import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useMutatePaymentLicenseUpdateChecked(
  userId,
  activeCompanyId
) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ paymentLicense, paymentLicenseType }) => {
      // notify the server that the user expanded the row

      // paymentLicenseType = 0 then it's received
      if (paymentLicenseType === 0) {
        if (paymentLicense.recipient_id === userId) {
          let data = new FormData();
          data.append("request_id", paymentLicense.id);
          data.append("is_request", 1);
          return apiClient.post(`spend_request/${userId}/updatechecked`, data);
        } else {
          let data = new FormData();
          data.append("forward_id", paymentLicense.received_forward_id);
          data.append("is_request", 1);
          return apiClient.post(
            `spend_request/${userId}/updateforwardchecked`,
            data
          );
        }
      } else {
        if (paymentLicense.created_by === userId) {
          let data = new FormData();
          data.append("request_id", paymentLicense.id);
          data.append("is_request", 0);
          return apiClient.post(`spend_request/${userId}/updatechecked`, data);
        } else {
          let data = new FormData();
          data.append("forward_id", paymentLicense.sent_forward_id);
          data.append("is_request", 0);
          return apiClient.post(
            `spend_request/${userId}/updateforwardchecked`,
            data
          );
        }
      }
    },
    {
      onSuccess: () => {
        // for server validation
        queryClient.invalidateQueries("getPayments");
        queryClient.setQueryData("paymentsCount", (data) => ({
          ...data,
          notifications_count: data.notifications_count - 1,
        }));
      },
      onError: (error) => {
        return error;
      },
    }
  );
}
