import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import {
  useForm,
  useFieldArray,
  useFormContext,
  FormProvider,
} from "react-hook-form";
import Form from "../../../../components/safe/Withdraw/Create/index";
import Modal from "../../../../components/common/Modal";
import Alert from "../../../../components/common/Alert";
import Plus from "../../../../components/resources/icons/Plus";
import useFetchUserData from "../../../../hooks/reactQuery/useFetchUserData";
import useFetchSafeBalance from "../../../../hooks/reactQuery/useFetchSafeBalance";
import useMutateCashWithDrawal from "../../../../hooks/reactQuery/useMutateCashWithDrawal";
import { useDataLayerValue } from "../../../../context/DataLayer";
import { useTranslation } from "react-i18next";

const WithdrawForm = ({}) => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();


  const history = useHistory();

  const [{ isFileUploading }] = useDataLayerValue();

  const [showAlertCancelModal, setShowAlertCancelModal] = useState(false);
  const [showAlertConfirmModal, setShowAlertConfirmModal] = useState(false);

  const [trackAddField, setTrackAddField] = useState(true);

  const { data: safeBalance } = useFetchSafeBalance();

  const methods = useForm({
    mode: "all",
    defaultValues: {
      withdrawForm: [
        {
          name: "",
          amount: "",
          category_id: "",
          safe_id: "",
          description: "",
          attachments: [],
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "withdrawForm",
  });

  const addFieldSet = () => {
    append({
      name: "",
      amount: "",
      category_id: "",
      safe_id: "",
      description: "",
      attachments: [],
    });
  };

  const removeFieldSet = (fieldIndex) => {
    remove(fieldIndex);
  };

  const { isLoading: isMutateWithdrawLoading, mutate: mutateWithdraw } =
    useMutateCashWithDrawal(setShowAlertConfirmModal, history);

  const handleSubmitWithdrawCash = (data) => {
    data = data.withdrawForm.map((field) => ({
      ...field,
      category_id: field.category_id.value,
      with_permission: field.safe_id.permissions.includes("CashInWithoutPermission") || field.safe_id.permissions.includes("CashFullControl") ? 0 : 1,
      safe_id: field.safe_id.value,
      attachments: field.attachments?.map((attachment) => {
        return Object.assign({
          guid: attachment.guid,
          name: attachment.name,
        });
      }),
    }));
    mutateWithdraw(data);
  };

  const [totalValue, setTotalValue] = useState(0);

  const [activeFieldArr, setActiveFieldArr] = useState(fields[0]?.id);

  return (
    <FormProvider {...methods}>
      <form className="space-y-8 divide-y divide-gray-200 w-full bg-white relative">
        <div className="px-4" style={{ minHeight: "550px" }}>
          {fields.map((field, index) => {
            return (
              <div>
                <ExpandedFieldArray
                  key={field.id}
                  fields={fields}
                  index={index}
                  field={field}
                  removeFieldSet={removeFieldSet}
                  trackTotalValue={(totalValue) => setTotalValue(totalValue)}
                  activeFieldArr={activeFieldArr}
                  setActiveFieldArr={setActiveFieldArr}
                  trackAddField={trackAddField}
                  safeBalance={safeBalance}
                />
              </div>
            );
          })}
        </div>

        <div className="py-5 lg:sticky bottom-0 bg-white px-4 lg:pr-16">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-y-6 lg:gap-x-4">
            <div className="col-span-9 grid lg:grid-cols-9 grid-cols-1 gap-y-3 h-fit-content">
              <button
                type="button"
                className="lg:col-span-2 col-span-1 row-start-3 lg:row-start-1 lg:w-32 flex justify-center items-center gap-x-3 bg-white py-2 px-4 border border-outline rounded-md shadow-sm text-sm font-bold text-body"
                onClick={() => {
                  addFieldSet();
                  setTrackAddField((prevState) => !prevState);
                }}
              >
                <div>
                  <Plus />
                </div>
                <span>{t("add_another")}</span>
              </button>

              <div className="col-span-4 lg:col-start-6 grid grid-cols-1 lg:grid-cols-10 gap-y-6 gap-x-2">
                <Fragment>
                  <button
                    type="button"
                    className={`lg:col-span-5 lg:col-start-2 py-2 px-4 text-base leading-5 font-bold rounded-md shadow-sm border border-outline ${
                      !methods.formState?.isValid || isFileUploading
                        ? "cursor-not-allowed bg-outline text-white"
                        : "bg-white text-hints"
                    }`}
                    onClick={() => setShowAlertConfirmModal(true)}
                    disabled={!methods.formState?.isValid || isFileUploading}
                  >
                    سحب
                  </button>
                  <Modal
                    showModal={showAlertConfirmModal}
                    onClose={() => setShowAlertConfirmModal(false)}
                  >
                    <Alert
                      hasFooter={true}
                      alertType="confirm"
                      title={t("confirm")}
                      body={() => (
                        <div>
                          {t("alert_text_withdraw_from_safe", {amount: totalValue})}
                        </div>
                      )}
                      confirmText={t("action_withdraw")}
                      cancelText={t("cancel")}
                      handleConfirm={methods.handleSubmit(
                        handleSubmitWithdrawCash
                      )}
                      handleCancel={() =>
                        !isMutateWithdrawLoading &&
                        setShowAlertConfirmModal(false)
                      }
                      isSubmitLoading={isMutateWithdrawLoading}
                    />
                  </Modal>
                </Fragment>

                <Fragment>
                  <button
                    type="button"
                    className="lg:col-span-4 py-2 px-4 text-base leading-5 font-bold rounded-md shadow-sm border border-outline"
                    onClick={() => setShowAlertCancelModal(true)}
                  >
                    {t("cancel")}
                  </button>
                  <Modal
                    showModal={showAlertCancelModal}
                    onClose={() => setShowAlertCancelModal(false)}
                  >
                    <Alert
                      hasFooter={true}
                      alertType="cancel"
                      title={t("confirm")}
                      body={() => (
                        <div>
                          {t("alert_text_cancel")}
                        </div>
                      )}
                      confirmText={t("confirm")}
                      cancelText={t("cancel")}
                      handleConfirm={() => history.push("/safe")}
                      handleCancel={() => setShowAlertCancelModal(false)}
                    />
                  </Modal>
                </Fragment>
              </div>
            </div>

            <div className="lg:col-span-2 lg:col-start-11 bg-white py-2 px-4 rounded-md shadow-sm lg:text-left text-center">
              <span className="text-sm font-bold text-body">
                {t("total_amount")}
              </span>{" "}
              <span className="text-secondary text-sm font-bold">
                {isNaN(totalValue) ? 0 : totalValue}
              </span>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default WithdrawForm;

function ExpandedFieldArray({
  fields,
  index,
  field,
  removeFieldSet,
  trackTotalValue,
  activeFieldArr,
  setActiveFieldArr,
  trackAddField,
  safeBalance,
}) {
  const {
    formState: { errors },
  } = useFormContext();

  const [isExpanded, setExpanded] = useState(true);

  useEffect(() => {
    if (activeFieldArr !== field.id) {
      setExpanded(false);
    }
  }, [activeFieldArr]);

  useEffect(() => {
    setExpanded(true);
    setActiveFieldArr(fields[fields.length - 1].id);
  }, [trackAddField]);

  return (
    <div className="flex">
      <div className="flex flex-col items-center ml-4 relative top-4">
        <div
          className={`flex justify-center items-center py-2 px-3 text-white text-base leading-4 font-medium rounded-lg relative transition-all duration-300 ${
            errors?.withdrawForm && errors?.withdrawForm[index]
              ? "bg-error"
              : "bg-side-icons"
          }`}
        >
          <span className="w-2 flex justify-center items-center">
            {index + 1}
          </span>
        </div>
        <div className="flex justify-center h-full">
          <div
            className={`w-px bg-side-icons h-0 transition-all duration-300 ${
              index + 1 !== fields.length && "h-full"
            }`}
          ></div>
        </div>
      </div>
      <Form
        fields={fields}
        index={index}
        field={field}
        removeFieldSet={removeFieldSet}
        trackTotalValue={trackTotalValue}
        activeFieldArr={activeFieldArr}
        setActiveFieldArr={setActiveFieldArr}
        isExpanded={isExpanded}
        setExpanded={setExpanded}
        safeBalance={safeBalance}
      />
    </div>
  );
}
