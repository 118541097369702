import AcceptedSvg from "../../components/resources/accepted svg/AcceptedSvg";
import RejectedSvg from "../../components/resources/rejected svg/RejectedSvg";
import SuspendedSvg from "../../components/resources/suspended svg/SuspendedSvg";
import CurrentSvg from "../../components/resources/current svg/CurrentSvg";
import FinishedSvg from "../../components/resources/finished svg/FinishedSvg";
import { convertDate, formatDate } from "../../utils/convertDate";
import Health from "../../components/resources/icons/Health";
import TableSortIcon from "../../components/resources/icons/TableSortIcon";
import Key from "../../components/resources/icons/Key";
import { toolPin } from "../../utils/toolPin";
import DataBase from "../../components/resources/icons/Database";
import Cash from "../../components/resources/icons/Cash";
import SafeBox from "../../components/resources/icons/SafeBox";
import DateIcon from "../../components/resources/icons/DateIcon";

export const getColumnsHeader = (activeTab, t, i18n) => [
  {
    title: t("transaction_code"),
    dataIndex: "id",
    key: "id",
    width: 100,
    render: (text, row) => <div className="col-desc flex justify-start">{text}</div>,
    selected: true,
    customizeKey: t("transaction_code"),
  },
  {
    title: t("name"),
    dataIndex: "first_name",
    key: "first_name",
    width: 100,
    customizeKey: t("name"),
    render: (text, row) => <div className="col-desc flex justify-start">{`${row.first_name} ${row.last_name}`}</div>,
  },
  {
    title: t("user_name"),
    dataIndex: "user_name",
    key: "user_name",
    width: 100,
    render: (text, row) => <div className="col-desc flex justify-start">{text}</div>,
    customizeKey: t("user_name"),
  },
  {
    title: t("email"),
    dataIndex: "email",
    key: "email",
    width: 100,
    render: (text, row) => <div className="col-desc flex justify-start">{text}</div>,
    customizeKey: t("email"),
  },
  {
    title: t("company_name"),
    dataIndex: "company_name",
    key: "company_name",
    width: 100,
    render: (text, row) => <div className="col-desc flex justify-start">{text}</div>,
    customizeKey: t("company_name"),
  },
];
