import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchCompanyRoles() {
  return useQuery("companyRoles", () =>
    apiClient.get("role").then(({ data }) => {
      data?.map((role) => ({
        ...role,
        label: role.name,
        value: role.id,
      }));
      return data?.map((role) => ({
        ...role,
        label: role.name,
        value: role.id,
      }));
    })
  );
}
