import { useMutation, useQueryClient } from "react-query";

import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useMutateCloseFinancial(onClose, inValidteQueryKey) {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, user_id }) => {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("user_id", user_id);

      return apiClient.post("money_request/close", formData);
    },
    {
      onSuccess: () => {
        toast.info("تم تقفيل العهدة بنجاح.", { autoClose: 3000 });
        queryClient.invalidateQueries(inValidteQueryKey);
        queryClient.invalidateQueries("userData");
        onClose();
      },
      onError: (error) => {
        onClose();
        return error;
      }
    }
  );
}
