import React, { useState, useEffect } from "react";

import { useForm, Controller } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";

import Input from "../../components/common/inputs/Input";
import UploadInput from "../../components/common/inputs/Upload";
import useFetchInvitedUserData from "./../../hooks/reactQuery/useFetchInvitedUserData";
import useMutateInvitedUser from "./../../hooks/reactQuery/useMutateInvitedUser";

import useFetchUserExistsForRFA from "./../../hooks/reactQuery/useFetchUserExistsForRFA";

import apiClient from "../../services/apiClient";
import Name from "../../components/resources/icons/Name";
import UserName from "../../components/resources/icons/UserName";
import Shield from "../../components/resources/icons/Shield";
import Spinner from "../../components/common/Spinner";
import _ from "lodash";

const WelcomeUser = ({}) => {
  const { search } = useLocation();

  const history = useHistory();

  const queryClint = useQueryClient();

  // force log out
  localStorage.removeItem("activeCompanyId");
  document.cookie = "Bearer= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
  // redirect to home login if user exists
  useEffect(() => {
    queryClint.setQueryData("userData", () => {});
    !search &&
      history.push({
        pathname: "home",
        state: { userExists: true },
      });
  }, [search]);

  const { data: invitedUserData } = useFetchInvitedUserData(
    search.split("=")[1]
  );

  const [file, setFile] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(
    "img/upload-profile.png"
  );

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
    setError,
    clearErrors,
    control,
    trigger,
  } = useForm({
    mode: "all",
  });

  const watchUserName = watch("userName", "");

  const {
    isLoading: isUserExistsForRFALoading,
    data: userExistsForRFAData,
    error: userExistsForRFAError,
  } = useFetchUserExistsForRFA({
    userName: watchUserName,
  });

  useEffect(() => {
    trigger("userName");
  }, [isUserExistsForRFALoading, userExistsForRFAData, userExistsForRFAError]);

  useEffect(() => {
    document.body.addEventListener("keyup", onKeyUpHandler);
    return function cleanUp() {
      document.body.removeEventListener("keyup", onKeyUpHandler);
    };
  }, []);

  const onKeyUpHandler = (e) => {
    trigger("userName");
  };

  const CSRFToken = useQuery(
    "CSRFToken",
    () => {
      return apiClient.get("/getCSRFToken");
    },
    {
      onSuccess(response) {
        const cookieString = `CSRFToken=${response.data.CSRFToken}`;
        document.cookie = cookieString;
      },
    }
  );
  const h2Style = { fontSize: "24px", fontWeight: "700", marginTop: "2rem" };
  const { isLoading: isMutateInvitationLoading, mutate: mutateInvitation } =
    useMutateInvitedUser(history);

  const handleWelcomeUser = (data) => {
    data.file = file;
    data.fullName = data.fullName.trim();
    data.firstName = data.fullName.split(" ")[0];
    data.lastName = data.fullName.split(" ")[1];
    data.invitation_id = invitedUserData.id;
    mutateInvitation({ data, userData: invitedUserData });
  };
  const photoPreview = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        setFile(file);
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return search ? (
    <div className="bg-gray-50 lg:grid lg:grid-cols-12 md:grid md:grid-cols-12 lg:gap-x-5 h-screen ">
      <div
        className="col-span-4 mx-auto max-w-screen-lg w-full h-full flex flex-col  justify-center "
        style={{ backgroundImage: "linear-gradient(#3B82F6,#0A46BA)" }}
      >
        <div className="flex flex-col justify-center  p-5 mt-16">
          <img
            className="object-cover ml-4 mr-2 mt-8"
            src="/img/welcome-user.svg"
            style={({ width: "80%" }, { paddingLeft: "10px" })}
          />
          <div className="text-white mr-2">
            <h2 className="text-white" style={h2Style}>
              أهلا بك،
            </h2>
            <p className="font-medium text-base leading-7 not-italic">
              لقد تم دعوتك إلى فريق عمل {invitedUserData?.name + " "}
              من قبل مدير الفريق .
            </p>
            <p className="text-lg font-bold not-italic">
              سعداء بانضمامك إلي مجتمع تراست بيل :)
            </p>
          </div>
        </div>
        <div
          className="text-white flex  h-full  items-end gap-4 p-5"
          style={{ fontSize: "14px" }}
        >
          <small>
            <a href="#">المدونة المحاسبية </a>
          </small>
          <small>
            <a href="#">الخصائص والمميزات </a>
          </small>
          <small>
            <a href="#">من نحن </a>
          </small>
          <small>
            <a href="#">تواصل معنا </a>
          </small>
        </div>
      </div>
      <form
        className="col-span-8 mx-auto max-w-screen-lg w-full"
        onSubmit={handleSubmit(handleWelcomeUser)}
      >
        <div className="sm:rounded-md sm:overflow-hidden h-full flex flex-col justify-between">
          <div>
            <div className="flex flex-row items-center gap-3 justify-start py-5">
              <img
                src="/img/welcome-user_icon.svg"
                className="mr-5"
                alt="logo"
              />
              <h3 className="text-lg leading-6  font-bold "> ترست بيل </h3>
            </div>
            <hr />
          </div>

          <div className="pb-6 px-4 space-y-6 mt-4 lg:mt-0">
            <div className="grid grid-cols-6 gap-6 mt-4">
              <div className="col-span-6">
                <div className="flex flex-col justify-center items-center select-none">
                  <div>
                    <label
                      className="flex flex-col justify-center items-center relative cursor-pointer"
                      htmlFor="companyImage"
                    >
                      <div className="w-16 h-16">
                        <img
                          className="w-full h-full object-cover rounded-lg"
                          htmlFor="photo-upload"
                          alt=""
                          src={imagePreviewUrl}
                        />
                      </div>
                    </label>
                  </div>
                  <UploadInput
                    multiple={false}
                    accept="image/*"
                    name="companyImage"
                    id="companyImage"
                    showUplodedFilesNames={false}
                    getValues={getValues}
                    setError={setError}
                    clearErrors={clearErrors}
                    fileSize="5000000"
                    errors={errors.companyImage}
                    Controller={Controller}
                    control={control}
                    setValue={setValue}
                    trigger={trigger}
                    photoPreview={photoPreview}
                  />
                  <button
                    className="mt-2 py-2 px-4 rounded-md bg-white border"
                    type="button"
                    style={{
                      borderColor: "#CBD5E1",
                      color: "#1E293B",
                      fontWeight: 500,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      document.getElementById("companyImage").click();
                    }}
                  >
                    صورة الحساب
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <div
                className="grid grid-row-1  p-5 mx-auto"
                style={{ border: "1px solid #CBD5E1", borderRadius: "6px" }}
              >
                <div className="mb-2">
                  <Input
                    register={register}
                    type="text"
                    Icon={Name}
                    name="fullName"
                    label="الاسم الكامل"
                    fieldName="الاسم الكامل"
                    placeholder="الاسم الكامل"
                    required={true}
                    maxLength="50"
                    errors={errors.fullName}
                    hint="يمكمك كتابته بالعربية وغير العربية يتم عرض هذا الأسم لتعريفك خلال العمل."
                    pattern={{
                      regex: /^[\u0621-\u064Aa-zA-Z]+\s{1}/g,
                      errorMessage: "* من فضلك ضع مسافة",
                    }}
                    validate={{
                      userNameExist: (value) => {
                        return !/^[\u0621-\u064Aa-zA-Z]+\s[\u0621-\u064Aa-zA-Z]+\s*$/.test(
                          value
                        )
                          ? "* يجب ان يكون الاسم مكون من كلمتين فقط"
                          : true;
                      },
                    }}
                  />
                </div>
                <div className="my-2">
                  <div className="">
                    <Input
                      register={register}
                      type="text"
                      name="userName"
                      Icon={UserName}
                      label="اسم المستخدم"
                      fieldName="اسم المستخدم"
                      placeholder="اسم المستخدم"
                      hint="سيتم تمييزك بهذا الأسم في حالة تكرر الأسم الكامل مع اعضاء اخرين"
                      required={true}
                      maxLength="30"
                      pattern={{
                        regex:
                          /^(?=[a-zA-Z0-9._]{9,30}$)(?!.*[_.]{2})[^_.].*[^_.]$/,
                        errorMessage:
                          "*  اقتصر فقط على الحروف والأرقام والرموز بين 9 الي 30 حرف",
                      }}
                      validate={{
                        validateRepetition: (value) => {
                          if (isUserExistsForRFALoading) {
                            return (
                              <div className="">
                                <Spinner />
                              </div>
                            );
                          } else {
                            if (userExistsForRFAData != null) {
                              if (userExistsForRFAData.exists) {
                                return "* اسم المستخدم موجود";
                              } else {
                                return true;
                              }
                            } else {
                              return false;
                            }
                          }
                        },
                      }}
                      errors={errors.userName}
                    />
                  </div>
                </div>
                <div className="my-2">
                  <Input
                    register={register}
                    type="password"
                    Icon={Shield}
                    isPassword={true}
                    name="password"
                    label="كلمة المرور"
                    fieldName="كلمة المرور"
                    placeholder="كلمة المرور"
                    hint="احرص على تضمين حروف وارقام ورموز فيما لا يقل عن 8 عناصر"
                    required={true}
                    pattern={{
                      regex: /\w{8,}/,
                      errorMessage:
                        "* يجب ان تحتوى كلمة المرور على  مجموعة من الحروف والارقام ",
                    }}
                    errors={errors.password}
                  />
                </div>
              </div>
            </div>
            <div className="flex  justify-center">
              <div className="flex  justify-center px-4 py-3 bg-white text-right sm:px-6 w-full">
                <button
                  type="submit"
                  className={`bg-blue-500 hover:bg-blue-700 ${
                    !isValid ||
                    isUserExistsForRFALoading ||
                    isMutateInvitationLoading
                      ? "cursor-not-allowed disabled bg-opacity-50"
                      : ""
                  }
                 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline
                `}
                  style={{ width: "26rem" }}
                >
                  تسجيل الدخول
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  ) : (
    <></>
  );
};

export default WelcomeUser;
