import { useEffect, useState, useRef } from 'react';
import useFetchUserData from "../../../../hooks/reactQuery/useFetchUserData";
import Select, { components } from "react-select";
import Check from "../../../resources/icons/Check";
import Selector from "../../../resources/icons/Selector";
import ValueContainer from "../../../common/DynamicSelect/index";
import DateRangePickerComponent from '../../../common/DateRangePicker/DateRangePicker';
import {
  startOfMonth,
  endOfMonth,
  formatRelative
} from "date-fns";
import "../charts.scss";
import ScrollableBarChart from '../ScrollableBarChart/ScrollableBarChart.tsx';
import useFetchChartSafeData from "../../../../hooks/reactQuery/useFetchChartSafeData";
import useFetchChartDepositsData from "../../../../hooks/reactQuery/useFetchChartDepositsData";
import useFetchChartWithdrawalsData from "../../../../hooks/reactQuery/useFetchChartWithdrawalsData";
import useFetchChartDocumentsData from "../../../../hooks/reactQuery/useFetchChartDocumentsData";
import { useDataLayerValue } from "../../../../context/DataLayer";
import { useTranslation } from "react-i18next";

export default function Chart(props) {
  const { 
      data: userData,
      isLoading: isUserDataLoading,
      isError: isUserDataError
  } = useFetchUserData();

  const {t, i18n} = useTranslation();

  const safesOptions = [...userData.safes].filter(safe => safe.company_id === parseInt(localStorage.getItem("activeCompanyId")))
  .filter(safe => safe.active).filter(safe => safe.canView)
  .map(obj => {
      return {
          ...obj,
          label: obj.name,
          value: obj.id
      }
  });

  const [safesOptionsState, setSafeOptionsState] = useState(safesOptions);

  const [currentSafes, setCurrentSafes] = useState(props.safeId ? userData.safes.filter(safe => safe.id == props.safeId) : []);

  useEffect(() => {
      if(currentSafes && Array.isArray(currentSafes)) {
          const notSelectedSafes = safesOptions.filter(safe => {
              const isSelected = currentSafes?.find(s => s.id == safe.id);
              if(!isSelected) return true; else return false;
          });
          setSafeOptionsState([...currentSafes, ...notSelectedSafes]);
      }
  }, [currentSafes]);

  const [data, setData] = useState([]);
  const [totalValue, setTotalValue] = useState(null);

  const [activeKey, setActiveKey] = useState("all");

  const [showDatePicker, setShowDatePicker] = useState(false);

  const [selectedDateLabel, setSelectedDateLabel] = useState(null);

  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      label: t("current_month"),
      key: "selection"
    }
  ]);

  useEffect(() => {
    setSelectedDateLabel(selectionRange[0].label);
  }, [selectionRange]);

  const { 
    data: chartSafeData,
    isLoading: isChartSafeDataLoading,
    isError: isChartSafeDataError
  } = useFetchChartSafeData(JSON.stringify(currentSafes.map(safe => safe.id)), JSON.stringify(selectionRange), t);

  const { 
    data: chartDepositsData,
    isLoading: isChartDepositsDataLoading,
    isError: isChartDepositsDataError
  } = useFetchChartDepositsData(JSON.stringify(currentSafes.map(safe => safe.id)), JSON.stringify(selectionRange), t);

  const { 
    data: chartWithdrawalsData,
    isLoading: isChartWithdrawalsDataLoading,
    isError: isChartWithdrawalsDataError
  } = useFetchChartWithdrawalsData(JSON.stringify(currentSafes.map(safe => safe.id)), JSON.stringify(selectionRange), t);

  const { 
    data: chartDocumentsData,
    isLoading: isChartDocumentsDataLoading,
    isError: isChartDocumentsDataError
  } = useFetchChartDocumentsData(JSON.stringify(currentSafes.map(safe => safe.id)), JSON.stringify(selectionRange), t);

  const options = [
    {
      key: "cashin",
      name: t("chart_type_deposits"),
      color: "#42EBB3",
      click: () => {
        setActiveKey(activeKey === "cashin" ? "all" : "cashin");
      }
    },
    {
      key: "withdrawals",
      name: t("chart_type_withdrawals"),
      color: "#FFCD86",
      click: () => {
        setActiveKey(activeKey === "withdrawals" ? "all" : "withdrawals");
      }
    },
    {
      key: "documents",
      name: t("chart_type_documents"),
      color: "#FF805C",
      click: () => {
        setActiveKey(activeKey === "documents" ? "all" : "documents");
      }
    },
    {
      key: "balance",
      name: t("chart_type_safe_balance"),
      color: "#59A0FF",
      click: () => {
        setActiveKey(activeKey === "balance" ? "all" : "balance");
      }
    },
  ]


  const setAll = () => {
    const allData = chartSafeData.map(obj => {
      const depositsInTheSameDay = chartDepositsData.data.find(day => day.date === obj.date)[t("chart_type_deposits")];
      const withdrawalsInTheSameDay = chartWithdrawalsData.data.find(day => day.date === obj.date)[t("chart_type_withdrawals")];
      const documentsInTheSameDay = chartDocumentsData.data.find(day => day.date === obj.date)[t("chart_type_documents")];
      const newDay = {...obj};
      newDay[t("chart_type_deposits")] = depositsInTheSameDay;
      newDay[t("chart_type_withdrawals")] = withdrawalsInTheSameDay;
      newDay[t("chart_type_documents")] = documentsInTheSameDay;
      return newDay;
    });

    setData(allData);
  }

  useEffect(() => {
    if(activeKey === "balance" && chartSafeData) {
      setData(chartSafeData);
      setTotalValue(null);
    }
    if(activeKey === "cashin" && chartDepositsData) {
      setData(chartDepositsData.data);
      setTotalValue(new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(chartDepositsData.totalValue));
    }
    if(activeKey === "withdrawals" && chartWithdrawalsData) {
      setData(chartWithdrawalsData.data);
      setTotalValue(new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(chartWithdrawalsData.totalValue));
    }
    if(activeKey === "documents" && chartDocumentsData) {
      setData(chartDocumentsData.data);
      setTotalValue(new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(chartDocumentsData.totalValue));
    }
    if(activeKey === "all" && chartDepositsData && chartSafeData && chartWithdrawalsData && chartDocumentsData) {
      setAll();
      setTotalValue(null);
    }
  }, [activeKey, chartSafeData, chartDepositsData, chartWithdrawalsData, chartDocumentsData]);

  const styles = {
      control: (provided, state) => ({
        ...provided,
        minHeight: "unset",
        outline: "none",
        height: "38px",
        width: "350px",
        border: "1px solid #CBD5E1",
        marginRight: "-5px",
        borderRadius: "6px",
        boxShadow: state.menuIsOpen ? "0 0 0 2px #2563eb" : "",
      }),
      option: (provided, state) => ({
        ...provided,
        color: "inherit",
        backgroundColor: (state.isFocused || state.isSelected) && "#F8FAFC",
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
      }),
      clearIndicator: (provided) => ({
        ...provided
      }),
      menu: (provided) => ({
        ...provided,
        width: "350px",
        position: "absolute",
        zIndex: "100000",
        right: -5
      }),
      menuList: (provided) => ({
        ...provided,
        maxHeight: "250px",
        overflowY: "auto",
        "::-webkit-scrollbar": {
          width: "10px",
          height: "10px",
        },
        "::-webkit-scrollbar-track": {
          backgroundColor: "#f8fafc",
          borderRadius: "8px 0 0 8px",
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: "#cbd5e1",
          border: "1px solid #e7f5ff",
          borderRadius: "10px",
        },
      }),
      loadingIndicator: (provided) => ({
        ...provided,
        position: "absolute",
        left: "47px",
      }),
  };


  const DropdownIndicator = () => (
      <div className="px-3 py-2">
        <Selector />
      </div>
  );

  const formatOptionLabel = (data, context) => {
      const selectedValues = context.selectValue.map(obj => obj.value);
  
      return (
        <div className="flex justify-between items-center">
          <div className="flex justify-center items-center gap-x-3 selection-container">
            <p
              className={`text-sm text-body selection-weight ${
                context.selectValue &&
                context.selectValue[0]?.value === data?.value
                  ? "font-bold"
                  : "font-medium"
              }`}
            >
              {data.label}
            </p>
          </div>
          {context.selectValue && selectedValues.includes(data?.value) && (
            <div>
              <Check />
            </div>
          )}
        </div>
      );
  };

  const chartDateRef = useRef(null);
  const datePickerRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", e => {
      if(datePickerRef && datePickerRef.current && chartDateRef && chartDateRef.current && showDatePicker) {
        if(!datePickerRef.current.contains(e.target) && !chartDateRef.current.contains(e.target)) setShowDatePicker(false);
      }
    });
    
    return () => {
      document.removeEventListener("click", e => {
        if(datePickerRef && datePickerRef.current && chartDateRef && chartDateRef.current && showDatePicker) {
          if(!datePickerRef.current.contains(e.target) && !chartDateRef.current.contains(e.target)) setShowDatePicker(false);
        }
      });
    }
  }, [showDatePicker]);

  const displayDateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };

  return (
  <div className='mx-4 my-3'>
    <div className='chart-container'>
        <div className='chart-header'>
            <div className='chart-date' ref={chartDateRef}>
              <div className='chart-date-container' onMouseDown={() => setShowDatePicker(!showDatePicker)}>
                <div className='chart-date-text'>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.5 6C9.66576 6 9.82473 6.06773 9.94194 6.18829L11.8169 8.11686C12.061 8.36791 12.061 8.77495 11.8169 9.026C11.5729 9.27705 11.1771 9.27705 10.9331 9.026L9.5 7.55199L8.06694 9.026C7.82286 9.27705 7.42714 9.27705 7.18306 9.026C6.93898 8.77495 6.93898 8.36791 7.18306 8.11686L9.05806 6.18829C9.17527 6.06773 9.33424 6 9.5 6ZM7.18306 11.974C7.42714 11.723 7.82286 11.723 8.06694 11.974L9.5 13.448L10.9331 11.974C11.1771 11.723 11.5729 11.723 11.8169 11.974C12.061 12.2251 12.061 12.6321 11.8169 12.8831L9.94194 14.8117C9.69786 15.0628 9.30214 15.0628 9.05806 14.8117L7.18306 12.8831C6.93898 12.6321 6.93898 12.2251 7.18306 11.974Z" fill="#3B82F6"/>
                  </svg>
                  <span style={{marginRight: "5px"}}>
                  {selectionRange[0].startDate.toLocaleDateString(
                    i18n.resolvedLanguage === "en" ? "en-US" : 'ar-EG', 
                    displayDateOptions
                  )} - {selectionRange[0].endDate.toLocaleDateString(
                    i18n.resolvedLanguage === "en" ? "en-US" : 'ar-EG', 
                    displayDateOptions
                  )}
                  </span>
                </div>
                <div style={{display: "flex", flexDirection:"column"}}>
                  <span style={{paddingRight: "28px", fontSize: "12px", fontWeight: "500", color: "#94A3B8"}}>{selectedDateLabel ? selectedDateLabel : null}</span>
                  <span style={{paddingRight: "28px", fontSize: "12px", fontWeight: "500", color: "#94A3B8"}}>{totalValue ? `${t("amount")} : ${totalValue}` : null}</span>
                </div>
              </div>
              {showDatePicker ? <div className='date-picker' style={{
                direction: i18n.resolvedLanguage === "ar" ? "ltr" : "rtl", 
                left: i18n.resolvedLanguage === "en" ? "0" : "auto",
                right: i18n.resolvedLanguage === "ar" ? "50%" : "auto"
              }} ref ={datePickerRef}>
                <DateRangePickerComponent selectionRange={selectionRange} setSelectionRange={setSelectionRange} />
              </div> : null}
            </div>
            {props.safeId || activeKey === "all" ? null : <div>
                <Select
                    placeholder={t("filter_by_safe")}
                    options={safesOptionsState}
                    isLoading={isUserDataLoading}
                    styles={styles}
                    components={{
                        DropdownIndicator, ValueContainer
                    }}
                    onChange={(selectedOption) => {
                      if(selectedOption && selectedOption.length > 5) 
                        return alert(t("max_safes_select", {number: 5}));
                      setCurrentSafes(selectedOption ? selectedOption : []);
                    }}
                    hideSelectedOptions={false}
                    value={currentSafes}
                    isMulti={true}
                    isSearchable={true}
                    onBlur={(e) => {}}
                    formatOptionLabel={formatOptionLabel}
                />
            </div>}
            <div className='chart-options'>
                {options.map(option =>                 
                <button key={option.key} className='chart-option' onClick={option.click}>
                    <span style={{marginTop: "3px", width: "5px", height:"10px", borderRadius: "5px", backgroundColor: activeKey === option.key || activeKey === "all" ? option.color : "#CBD5E1"}}></span>
                    <span className='chart-option-text' style={activeKey === option.key ? {color: option.color} : activeKey === "all" ? {color: "#94A3B8"} : {color: "#CBD5E1"}}>{option.name}</span>
                </button>)}
            </div>
        </div>
        <div className='chart-body'>
          <ScrollableBarChart data={data} activeTab={activeKey} currentSafes={currentSafes} numOfBars={10} />
        </div>
    </div>
  </div>
  );
}

