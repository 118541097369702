import { useState, useEffect } from "react";
import InputDate from "../../../resources/icons/InputDate";
import InputText from "../../../resources/icons/InputText";
import InputPassword from "../../../resources/icons/InputPassword";
import InputNumber from "../../../resources/icons/InputNumber";
import FieldError from "../../../resources/icons/FieldError";
import PasswordHide from "../../../resources/icons/PasswordHide";
import PasswordShow from "../../../resources/icons/PasswordShow";
import "./Input.scss";
import Email from "../../../resources/icons/Email";
import Shield from "../../../resources/icons/Shield";
import { useTranslation } from "react-i18next";

const Input = ({
  register,
  name,
  type,
  isPassword,
  label,
  placeholder,
  hint,
  fieldName,
  value,
  defaultValue,
  required,
  minToday = false,
  min,
  max,
  minLength,
  maxLength,
  pattern,
  validate,
  onChange,
  errors,
  isDisabled,
  Icon,
  children,
}) => {
  // Toggle type between text and password
  const [textPassword, reverseTextPassword] = useState(true);

  const {t, i18n} = useTranslation();

  useEffect(() => {
    const inputElement = document.getElementById(name);
    const handleInputWheel = () => {
      inputElement.blur();
    };
    inputElement.addEventListener("mousewheel", handleInputWheel);
    return () => {
      inputElement.removeEventListener("mousewheel", handleInputWheel);
    };
  }, []);

  return (
    <>
      <div className="flex justify-between items-center mb-1" dir={i18n.resolvedLanguage === "en" ? "ltr" : "rtl"}>
        {label && (
          <label htmlFor={name} className="text-sm text-body font-medium">
            {label}
          </label>
        )}

        {errors && type !== "checkbox" && (
          <p className="text-xs leading-5 font-medium text-error">
            {errors.message}
          </p>
        )}
      </div>
      <div className="relative rounded-md shadow-sm">
        <div className={`input-icon pointer-events-none absolute ${i18n.resolvedLanguage === "en" ? "left-px" : "right-px"} top-px bg-separator rounded-r flex justify-center items-center px-2`}>
          {Icon ? (
            <Icon />
          ) : (
            (type === "text" && <InputText />) ||
            (type === "number" && <InputNumber />) ||
            (type === "date" && <InputDate />) ||
            (type === "email" && <Email />) ||
            (type === "password" && <Shield />)
          )}
        </div>
        <input
          onChange={onChange}
          min={
            type === "date" &&
            minToday &&
            new Date().toISOString().split("T")[0]
          }
          type={
            isPassword && textPassword ? "password" : isPassword ? "text" : type
          }
          {...(register &&
            register(name, {
              value,
              required: required && {
                value: required,
                message: t("field_required", {field_name: fieldName ? fieldName : ""})
              },
              min: min && {
                value: min,
                message: t("min_validation", {min}),
              },
              max: max && {
                value: max,
                message: t("max_validation"),
              },
              minLength: minLength && {
                value: minLength,
                message: t("min_chars_validation", {field_name: fieldName, number: minLength}),
              },
              maxLength: maxLength && {
                value: maxLength,
                message: t("max_chars_validation", {field_name: fieldName, number: maxLength}),
              },
              pattern: pattern && {
                value: pattern.regex,
                message: pattern.errorMessage,
              },
              validate: validate && validate,
            }))}
          id={name}
          className={`block w-full focus:outline-none border rounded text-sm text-body font-medium ${
            i18n.resolvedLanguage === "en" ? "pl-12 text-left" : "pr-12 text-right"
          } ${
            errors
              ? "focus:border-red-500 focus:ring-red-500 text-red-900 border-red-300 pl-10"
              : "border-outline"
          } ${isDisabled && "cursor-not-allowed disabled opacity-30"}`}
          disabled={isDisabled}
          placeholder={placeholder}
          defaultValue={defaultValue && defaultValue}
        />

        {errors && !isPassword && type !== "checkbox" && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <FieldError />
          </div>
        )}

        {isPassword && (
          <div
            className={`cursor-pointer absolute inset-y-0 ${i18n.resolvedLanguage === "en" ? "right-1" : "left-1"} pl-3 flex items-center`}
            onClick={() => reverseTextPassword((prevState) => !prevState)}
          >
            {textPassword ? <PasswordHide /> : <PasswordShow />}
          </div>
        )}

        {children && (
          <div
            className="absolute left-px top-px rounded-l bg-gray-400"
            style={{
              // border: "1px solid currentColor",
              height: "calc(100% - 2px)",
            }}
          >
            {children}
          </div>
        )}
      </div>

      {hint && (
        <p className="mt-2 text-xs leading-5 font-normal text-hints">{hint}</p>
      )}
    </>
  );
};

export default Input;
