import { useState, useMemo, useReducer } from "react";
import Filters from "../../../../../components/documentations/Filters/index";
import TableOperations from "../../../../../components/common/TableOperations/index";
import Search from "../../../../../components/common/Search/index";
import HeaderSection from "../../../../../components/common/HeaderSection";
import Table from "../../../../../components/common/Table";
import ExpandedRowFullControl from "../../../../../components/safe/Deposit/preview/ExpandedRowFullControl/index";
import useFetchUserData from "../../../../../hooks/reactQuery/useFetchUserData";
import useFetchDepositFullControl from "../../../../../hooks/reactQuery/useFetchDepositFullControl";
import { useDataLayerValue } from "../../../../../context/DataLayer";
import { columnsHeader } from "./tableColumnsHeader";
import { useTranslation } from "react-i18next";

const pageSize = process.env.REACT_APP_PAGE_SIZE;

const DepositFullControl = ({safeId}) => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();

  const [{ activeTab, searchType, searchTerms }] = useDataLayerValue();

  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useState("");
  const [columnKey, setColumnKey] = useState("");
  const [columnsHeaderState, setColumnsHeader] = useState(columnsHeader(t, i18n));


  const [selectedRows, setSelectedRows] = useState([]);

  const {
    isLoading: isTableLoading,
    data: tableData,
    error: tableError,
  } = useFetchDepositFullControl({
    safeId,
    userId: userData.id,
    currentPage,
    pageSize,
    order,
    columnKey,
    searchType,
    searchTerms,
  });

  const memoizedTableData = useMemo(
    () => (tableData ? tableData[0] : []),
    [tableData]
  );

  const [expandedRows, setExpandedRows] = useState([]);
  const onTableRowExpand = (expanded, record) => {
    var keys = [];
    if (expanded) {
      keys.push(record.id);
    }
    setExpandedRows(keys);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setOrder(sorter?.order);
    setColumnKey(sorter?.columnKey);
  };

  const filtersInitState = {
    categories: [],
    periodic: [],
    fromAmount: null,
    toAmount: null,
    fromDate: null,
    toDate: null,
    fromPaymentDate: null,
    toPaymentDate: null,
  };

  function filtersReducer(prevState, { type, payload }) {
    return {
      ...prevState,
      ...payload,
    };
  }
  const [filtersState, dispatchFiltrers] = useReducer(
    filtersReducer,
    filtersInitState
  );

  return (
    <div>
      <div className="my-4 headerSection">
        <div className="searchContainer">
            <Search
              searchDropDownApiUrl={`safe/cashin/dropdown?safe_id=${safeId}`}
              activeTab={activeTab}
            />
        </div>
        <div className="flex">
          <span style={{color: "#94A3B8", marginRight: "10px"}}> {t("count")} / {tableData ? tableData[1][0].overall_count : ""}</span>
          <span style={{color: "#94A3B8", marginRight: "20px"}}> {t("amount")} / 
          {new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(parseInt(tableData ? tableData[1][0].overall_total : 0)).toString().replace(/EGP/, "") + " "}
          {t("egp")}
          </span>
        </div>
        <div className="col-span-2 sm:col-span-1">
          <TableOperations
            activeTab={0}
            setColumns={setColumnsHeader}
            originalColumnsHeader={columnsHeader(t, i18n)}
            selectedRows={selectedRows}
            FiltersComponent={Filters}
            dispatchFiltrers={dispatchFiltrers}
            filtersState={filtersState}
          />
        </div>
      </div>
      <div className="mx-4 my-3">
        <Table
          rowKey="id"
          data={memoizedTableData}
          columns={columnsHeaderState}
          isLoading={isTableLoading}
          error={tableError}
          pageSize={pageSize}
          currentPage={currentPage}
          total={tableData?.[1]?.[0].overall_count}
          setSelectedRows={setSelectedRows}
          onTableRowExpand={onTableRowExpand}
          expandedRows={expandedRows}
          setExpandedRows={setExpandedRows}
          onPageChange={onPageChange}
          onChange={handleTableChange}
          expandedRow={(row) => <ExpandedRowFullControl row={row} safeId={safeId} />}
        />
      </div>
    </div>
  );
};

export default DepositFullControl;
