import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import apiClient from '../../services/apiClient'

export default function useMutateWorkspace(onClose) {
  const queryClint = useQueryClient()

  return useMutation(
    data => {
      const formData = new FormData()
      formData.append('name', data.companyName);
      formData.append('en_name', data.en_name);
      formData.append('specialization', data.companySpecialization.value);
      formData.append('responsibility', data.companyResponsibility.value);
      formData.append('members', data.companyMembers.value);

      data.file && formData.append('photo', data.file);

      return apiClient.post('user/addcompany', formData)
    },
    {
      onSuccess: (response, data) => {
        toast.info('تم اضافة مساحة عمل جديدة.', { autoClose: 3000 });
        queryClint.invalidateQueries('companies');
        queryClint.invalidateQueries('userData');
        onClose();
      },
      onError: error => {
        onClose();
        return error
      }
    }
  )
}
