const TimeOut = () => {
  return (
    <div>
      <svg
        width={380}
        height={242}
        viewBox='0 0 380 242'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M327.362 57.7839C323.393 64.8657 314.703 65.8388 300.781 64.4926C290.311 63.4789 280.763 62.6945 270.291 56.9853C262.962 52.9917 257.161 47.5895 252.926 42.3424C248.337 36.6579 241.933 30.1702 245.073 23.7248C249.388 14.8713 274.339 7.41464 298.568 19.6087C325.184 33.008 331.243 50.8629 327.362 57.7839Z'
          fill='url(#paint0_linear_4665_98035)'
        />
        <path
          d='M368.162 95.5983C359.278 100.006 347.485 94.3382 347.485 94.3382C347.485 94.3382 350.105 81.5277 358.995 77.1263C367.881 72.7184 379.669 78.3799 379.669 78.3799C379.669 78.3799 377.048 91.1904 368.162 95.5983Z'
          fill='url(#paint1_linear_4665_98035)'
        />
        <path
          d='M11.706 151.254C21.5632 157.161 35.7057 151.7 35.7057 151.7C35.7057 151.7 33.8563 136.663 23.9932 130.761C14.136 124.853 0 130.308 0 130.308C0 130.308 1.84885 145.346 11.706 151.254Z'
          fill='url(#paint2_linear_4665_98035)'
        />
        <path
          d='M322.858 72.7526C318.398 72.7526 314.782 76.3687 314.782 80.8293C314.782 85.2899 318.398 88.906 322.858 88.906C327.319 88.906 330.935 85.2899 330.935 80.8293C330.935 76.3687 327.319 72.7526 322.858 72.7526Z'
          fill='#3B82F6'
        />
        <path
          d='M118.045 46.6624C114.373 46.6624 111.397 49.6391 111.397 53.311C111.397 56.983 114.373 59.9597 118.045 59.9597C121.717 59.9597 124.694 56.983 124.694 53.311C124.694 49.6391 121.717 46.6624 118.045 46.6624Z'
          fill='#3B82F6'
        />
        <path
          d='M59.0799 179.806C54.8979 179.806 51.5077 176.415 51.5077 172.233C51.5077 168.051 54.8979 164.661 59.0799 164.661C63.2619 164.661 66.6521 168.051 66.6521 172.233C66.6521 176.415 63.2619 179.806 59.0799 179.806Z'
          fill='#3B82F6'
        />
        <path
          d='M346.778 127.257C344.826 127.257 343.244 125.675 343.244 123.723C343.244 121.771 344.826 120.189 346.778 120.189C348.729 120.189 350.312 121.771 350.312 123.723C350.312 125.675 348.729 127.257 346.778 127.257Z'
          fill='#3B82F6'
        />
        <path
          d='M366.169 230.672C363.66 230.672 361.626 228.638 361.626 226.129C361.626 223.619 363.66 221.585 366.169 221.585C368.678 221.585 370.712 223.619 370.712 226.129C370.712 228.638 368.678 230.672 366.169 230.672Z'
          fill='#CBD5E1'
        />
        <path
          d='M33.1661 194.389C29.5417 194.389 26.6035 191.451 26.6035 187.826C26.6035 184.202 29.5417 181.264 33.1661 181.264C36.7906 181.264 39.7288 184.202 39.7288 187.826C39.7288 191.451 36.7906 194.389 33.1661 194.389Z'
          fill='#CBD5E1'
        />
        <path
          d='M365.434 188.881C362.676 188.881 360.44 186.645 360.44 183.887C360.44 181.129 362.676 178.893 365.434 178.893C368.192 178.893 370.428 181.129 370.428 183.887C370.428 186.645 368.192 188.881 365.434 188.881Z'
          fill='#CBD5E1'
        />
        <path
          d='M326.156 31.8304C323.089 31.8304 320.603 29.3443 320.603 26.2774C320.603 23.2105 323.089 20.7244 326.156 20.7244C329.223 20.7244 331.709 23.2105 331.709 26.2774C331.709 29.3443 329.223 31.8304 326.156 31.8304Z'
          fill='#CBD5E1'
        />
        <path
          d='M34.563 101.881C35.2758 104.541 33.6971 107.276 31.0369 107.988C28.3767 108.701 25.6424 107.122 24.9296 104.462C24.2168 101.802 25.7954 99.0677 28.4556 98.3549C31.1159 97.6421 33.8502 99.2208 34.563 101.881Z'
          fill='#CBD5E1'
        />
        <path
          d='M133.632 6.34076C132.486 6.34076 131.557 5.41168 131.557 4.2656C131.557 3.11951 132.486 2.19043 133.632 2.19043C134.778 2.19043 135.707 3.11951 135.707 4.2656C135.707 5.41168 134.778 6.34076 133.632 6.34076Z'
          fill='#CBD5E1'
        />
        <path
          d='M334.116 163.338C331.297 163.338 329.013 161.51 329.013 159.256C329.013 157.002 331.297 155.174 334.116 155.174C336.934 155.174 339.218 157.002 339.218 159.256C339.218 161.51 336.934 163.338 334.116 163.338Z'
          fill='#CBD5E1'
        />
        <path
          d='M225.445 5.08433C225.961 7.01008 224.818 8.98951 222.892 9.50552C220.966 10.0215 218.987 8.87869 218.471 6.95294C217.955 5.02719 219.098 3.04776 221.024 2.53176C222.949 2.01575 224.929 3.15858 225.445 5.08433Z'
          fill='#CBD5E1'
        />
        <path
          d='M71.8887 38.3612H71.9618C72.3921 44.46 76.9278 44.5537 76.9278 44.5537C76.9278 44.5537 71.9259 44.6515 71.9259 51.6986C71.9259 44.6515 66.9245 44.5537 66.9245 44.5537C66.9245 44.5537 71.4585 44.46 71.8887 38.3612ZM68.7262 217.434H68.7957C69.2089 223.516 73.5637 223.609 73.5637 223.609C73.5637 223.609 68.7615 223.706 68.7615 230.732C68.7615 223.706 63.96 223.609 63.96 223.609C63.96 223.609 68.3125 223.516 68.7262 217.434Z'
          fill='#CBD5E1'
        />
        <path
          d='M311.993 108.101C301.785 120.832 284.232 119.89 257.072 112.621C236.645 107.151 217.972 102.44 199.095 87.6601C185.882 77.3208 176.163 64.6918 169.501 52.888C162.281 40.1004 151.725 25.121 160.08 13.3785C171.561 -2.75138 223.5 -9.29279 267.514 22.892C315.861 58.2548 321.977 95.6602 311.993 108.101Z'
          fill='url(#paint3_linear_4665_98035)'
        />
        <path
          d='M281.128 192.321C276.21 220.271 209.202 263.854 147.516 227.62C118.827 210.768 125.174 195.34 106.564 168.736C95.4712 152.88 77.5397 138.671 76.1924 119.058C75.0254 102.127 81.9422 85.829 92.9021 76.7291C117.551 56.2651 159.606 48.1035 188.047 95.9832C216.487 143.863 293.802 120.234 281.128 192.321Z'
          fill='url(#paint4_linear_4665_98035)'
        />
        <path
          d='M199.684 38.3289V148.541H188.064C188.064 143.627 183.507 136.485 181.229 133.527C152.179 109.301 141.926 95.2395 141.926 73.4741C141.926 53.0012 153.888 42.082 161.406 38.3289H199.684ZM199.684 38.3289V148.541H211.186C211.186 143.627 215.696 136.485 217.951 133.527C246.705 109.301 256.853 95.2395 256.853 73.4741C256.853 53.0012 245.013 42.082 237.571 38.3289H199.684Z'
          fill='#3B82F6'
        />
        <path
          d='M199.684 231.052V121.43H188.064C188.064 126.317 183.507 133.422 181.229 136.363C152.179 160.46 141.926 174.446 141.926 196.095C141.926 216.458 153.888 227.319 161.406 231.052H199.684ZM199.684 231.052V121.43H211.186C211.186 126.317 215.696 133.422 217.951 136.363C246.705 160.46 256.853 174.446 256.853 196.095C256.853 216.458 245.013 227.319 237.571 231.052H199.684Z'
          fill='#3B82F6'
        />
        <path
          d='M257.757 240.92H142.143V235.949C142.143 232.959 144.57 230.533 147.566 230.533H252.558C255.429 230.533 257.757 232.858 257.757 235.726V240.92Z'
          fill='#CBD5E1'
        />
        <path
          d='M162.514 68.3135C167.723 69.0797 175.238 70.0162 182.865 70.3934C190.526 70.7724 198.166 70.5779 203.691 69.1487C220.526 64.7921 231.001 63.654 237.168 63.5579L162.514 68.3135ZM162.514 68.3135C157.96 67.644 153.96 71.2633 153.96 75.8781V80.4858C153.96 82.3588 154.568 84.3892 155.54 86.4643C156.519 88.5531 157.908 90.775 159.569 93.0558C162.892 97.6169 167.39 102.526 172.126 107.221C181.606 116.619 192.178 125.294 196.595 128.829L162.514 68.3135ZM162.514 68.3135L196.595 128.829L162.514 68.3135ZM196.595 128.829C197.593 129.628 198.186 130.863 198.186 132.185V202.796C198.186 204.76 197.243 206.583 195.683 207.703C192.076 210.292 187.333 215.288 183.558 219.538C181.435 221.932 179.36 224.368 177.334 226.845L176.948 227.32L176.847 227.445L176.821 227.477L176.814 227.486L176.812 227.488L176.812 227.488L177.899 228.361L176.812 227.489L174.994 229.755L196.595 128.829ZM196.595 128.829L174.994 229.755L196.595 128.829ZM174.994 229.755H177.899H174.994ZM174.994 229.755H177.899H174.994ZM222.039 228.361L223.105 227.463V227.462L223.103 227.46L223.096 227.452L223.07 227.421L222.968 227.3L222.578 226.842C220.529 224.446 218.443 222.081 216.321 219.749C212.538 215.593 207.846 210.696 204.42 208.007L204.419 208.006C203.243 207.085 202.541 205.649 202.541 204.115V132.165C202.541 130.848 203.125 129.613 204.112 128.803H204.113C208.442 125.245 218.789 116.533 228.064 107.106C232.699 102.396 237.098 97.4731 240.349 92.9038C241.974 90.6182 243.332 88.3933 244.289 86.304C245.239 84.2277 245.832 82.2008 245.832 80.3355V72.2741C245.832 67.4407 241.966 63.4831 237.168 63.5579L222.039 228.361ZM222.039 228.361L223.106 227.463L222.039 228.361ZM222.039 228.361L223.106 227.463L222.039 228.361ZM177.899 229.755H225.037H177.899ZM177.899 229.755H225.037H177.899ZM225.037 229.755L223.106 227.463L225.037 229.755ZM225.037 229.755L223.106 227.463L225.037 229.755Z'
          fill='white'
        />
        <path
          d='M225.037 229.755L223.106 227.463M162.514 68.3135C167.723 69.0797 175.238 70.0162 182.865 70.3934C190.526 70.7724 198.166 70.5779 203.691 69.1487C220.526 64.7921 231.001 63.654 237.168 63.5579L162.514 68.3135ZM162.514 68.3135C157.96 67.644 153.96 71.2633 153.96 75.8781V80.4858C153.96 82.3588 154.568 84.3892 155.54 86.4643C156.519 88.5531 157.908 90.775 159.569 93.0558C162.892 97.6169 167.39 102.526 172.126 107.221C181.606 116.619 192.178 125.294 196.595 128.829L162.514 68.3135ZM162.514 68.3135L196.595 128.829L162.514 68.3135ZM196.595 128.829C197.593 129.628 198.186 130.863 198.186 132.185V202.796C198.186 204.76 197.243 206.583 195.683 207.703C192.076 210.292 187.333 215.288 183.558 219.538C181.435 221.932 179.36 224.368 177.334 226.845L176.948 227.32L176.847 227.445L176.821 227.477L176.814 227.486L176.812 227.488L176.812 227.488L177.899 228.361L176.812 227.489L174.994 229.755L196.595 128.829ZM196.595 128.829L174.994 229.755L196.595 128.829ZM174.994 229.755H177.899H174.994ZM174.994 229.755H177.899H174.994ZM222.039 228.361L223.105 227.463V227.462L223.103 227.46L223.096 227.452L223.07 227.421L222.968 227.3L222.578 226.842C220.529 224.446 218.443 222.081 216.321 219.749C212.538 215.593 207.846 210.696 204.42 208.007L204.419 208.006C203.243 207.085 202.541 205.649 202.541 204.115V132.165C202.541 130.848 203.125 129.613 204.112 128.803H204.113C208.442 125.245 218.789 116.533 228.064 107.106C232.699 102.396 237.098 97.4731 240.349 92.9038C241.974 90.6182 243.332 88.3933 244.289 86.304C245.239 84.2277 245.832 82.2008 245.832 80.3355V72.2741C245.832 67.4407 241.966 63.4831 237.168 63.5579L222.039 228.361V228.361ZM222.039 228.361L223.106 227.463L222.039 228.361ZM222.039 228.361L223.106 227.463L222.039 228.361ZM177.899 229.755H225.037H177.899ZM177.899 229.755H225.037H177.899ZM225.037 229.755L223.106 227.463L225.037 229.755Z'
          stroke='white'
          strokeWidth='4.73'
        />
        <path
          d='M252.364 42.9059H147.343C144.472 42.9059 142.143 40.5802 142.143 37.7123V32.5194H257.757V37.519C257.757 40.4953 255.344 42.9059 252.364 42.9059Z'
          fill='#CBD5E1'
        />
        <path
          d='M206.968 77.3574V77.3576C206.969 81.9617 208.177 86.4851 210.473 90.4761C212.768 94.467 216.071 97.7858 220.05 100.101C220.05 100.101 220.05 100.101 220.05 100.101L244.366 114.253L244.367 114.253C248.386 116.591 252.953 117.823 257.604 117.823C262.254 117.823 266.821 116.591 270.84 114.253L270.841 114.253L295.157 100.101L295.157 100.101C299.135 97.7843 302.436 94.465 304.73 90.4743C307.025 86.4835 308.233 81.9608 308.233 77.3576V77.3574V51.6255V51.6254C308.233 47.0214 307.024 42.4981 304.729 38.5072C302.433 34.5164 299.131 31.1977 295.152 28.8825C295.152 28.8824 295.151 28.8823 295.151 28.8823L270.835 14.7305L270.835 14.7303C266.816 12.3921 262.248 11.1603 257.598 11.1603C252.948 11.1603 248.381 12.3921 244.361 14.7303L244.361 14.7305L220.045 28.8825L220.045 28.8827C216.067 31.199 212.766 34.5181 210.471 38.5088C208.177 42.4994 206.969 47.0221 206.968 51.6253V51.6255V77.3574Z'
          fill='#3B82F6'
          stroke='#F8FAFC'
          strokeWidth={4}
        />
        <path
          d='M257.596 83.4043C257.418 83.4041 257.243 83.4389 257.079 83.5068C256.915 83.5746 256.767 83.6742 256.642 83.7998C256.517 83.9253 256.418 84.0744 256.35 84.2383C256.283 84.4023 256.249 84.5779 256.25 84.7551C256.257 85.1086 256.403 85.4451 256.656 85.6924C256.908 85.9398 257.248 86.0783 257.601 86.0783C257.955 86.0783 258.294 85.9398 258.547 85.6924C258.799 85.4451 258.945 85.1086 258.952 84.7551C258.952 84.5773 258.917 84.4013 258.848 84.2371C258.78 84.0729 258.68 83.9239 258.554 83.7984C258.428 83.6729 258.278 83.5735 258.114 83.5059C257.95 83.4383 257.773 83.4038 257.596 83.4043M257.601 64.4915V40.1753V64.4915Z'
          stroke='white'
          strokeWidth='15.588'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <defs>
          <linearGradient
            id='paint0_linear_4665_98035'
            x1='300.631'
            y1='103.679'
            x2='265.264'
            y2='-55.2453'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='white' />
            <stop offset={1} stopColor='#EEEEEE' />
          </linearGradient>
          <linearGradient
            id='paint1_linear_4665_98035'
            x1='334.043'
            y1='109.346'
            x2='407.328'
            y2='51.3774'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='white' />
            <stop offset={1} stopColor='#EEEEEE' />
          </linearGradient>
          <linearGradient
            id='paint2_linear_4665_98035'
            x1='49.8511'
            y1='170.271'
            x2='-29.4637'
            y2='96.5766'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='white' />
            <stop offset={1} stopColor='#EEEEEE' />
          </linearGradient>
          <linearGradient
            id='paint3_linear_4665_98035'
            x1='243.826'
            y1='190.272'
            x2='226.209'
            y2='-136.536'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='white' />
            <stop offset={1} stopColor='#EEEEEE' />
          </linearGradient>
          <linearGradient
            id='paint4_linear_4665_98035'
            x1='178.175'
            y1='342.78'
            x2='183.082'
            y2='-136.098'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='white' />
            <stop offset={1} stopColor='#EEEEEE' />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}
export default TimeOut
