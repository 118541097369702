export const convertDate = (isoDate) => {
  if(!isoDate) return "-";
  return new Date(isoDate).toLocaleDateString("ar-EG-u-nu-latn", {
    // weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    // second: "numeric",
  });
};

export const formatDate = (date) => {
  let date1 = new Date(date);
  let today = new Date();
  let difference = Math.abs(today - date1); //difference in milliseconds

  let diff = Math.floor(difference / (1000 * 60 * 60 * 24)); //difference in days

  if (diff >= 2) {
    return date.split("T")[0];
  }
  if (diff >= 1 && diff < 2)
    //for days
    return "بالأمس";
  diff = Math.floor(difference / (1000 * 60 * 60)); //difference in hours
  if (diff >= 1 && diff < 2)
    //for hours
    return "منذ ساعة واحدة";
  if (diff >= 2 && diff < 3)
    //for hours
    return "منذ ساعتين";
  if (diff >= 3 && diff <= 10)
    //for hours
    return "منذ " + diff + " ساعات";
  if (diff >= 11)
    //for hours
    return "منذ " + diff + " ساعة";
  diff = Math.floor(difference / (1000 * 60)); //difference in minutes
  if (diff < 1)
    //for minutes
    return "أقل من دقيقة";
  if (diff >= 1 && diff < 2)
    //for minutes
    return "منذ دقيقة";
  if (diff >= 2 && diff < 3)
    //for minutes
    return "منذ دقيقتين";
  if (diff >= 3 && diff <= 10)
    //for minutes
    return "منذ " + diff + " دقائق";
  if (diff >= 11)
    //for minutes
    return "منذ " + diff + " دقيقة";
  return diff;
};
