import React, { Fragment, useState } from "react";

import { useForm, Controller, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";

import Input from "../../common/inputs/Input";
import TextArea from "../../common/inputs/Textarea";
import SelectMenu from "../../common/inputs/Select";
import UploadInput from "../../common/inputs/Upload";
import Modal from "../../common/Modal";
import Alert from "../../common/Alert";
import CloseSvg from "../../resources/close svg/CloseSvg";
import AttachmentSvg from "../../resources/attachment svg/AttachmentSvg";

import useFetchUserData from "../../../hooks/reactQuery/useFetchUserData";
import useFetchUsers from "../../../hooks/reactQuery/useFetchUsers";
import useFetchCategory from "../../../hooks/reactQuery/useFetchCategory";
import useMutateMoneyRequest from "../../../hooks/reactQuery/useMutateMoneyRequest";

import "./RequestMoney.scss";
import useMutateCategory from "../../../hooks/reactQuery/useMutateCategory";
import { useTranslation } from "react-i18next";
const MoneyRequestModal = ({ onClose }) => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();
  let history = useHistory();

  const [showAlertConfirmModal, setShowAlertConfirmModal] = useState(false);
  const [showAlertCancelModal, setShowAlertCancelModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    getValues,
    setValue,
    control,
    trigger,
  } = useForm({
    mode: "all",
  });

  const watchAttachments = useWatch({
    control,
    name: "attachments",
  });

  const newCategoryOptionMutation = useMutateCategory(
    userData.id,
    userData.activeCompanyId
  );

  const {
    data: categories,
    isLoading: iscategoriesLoading,
    isError: isEategoriesError,
  } = useFetchCategory();

  const {
    data: users,
    isLoading: isUsersLoading,
    isError: isUsersError,
  } = useFetchUsers(userData.id);

  const formatOptionLabel = (data) => {
    return (
      <div className="flex items-center selection-container">
        <div className="flex-shrink-0 selection-hide">
          {data.profile_picture_ext ? (
            <img className="h-10 w-10 rounded-full" src={data.url} alt="user" />
          ) : (
            <div className="h-10 w-10 rounded-full flex justify-center items-center bg-blue-400">
              {data.name[0]}
            </div>
          )}
        </div>
        <div className="mr-3">
          <p className="text-sm font-medium text-gray-900 hover:underline">
            {data.name}
          </p>
          <div className="flex space-x-1 text-sm text-gray-500 selection-hide">
            <p>{data.user_name}</p>
          </div>
        </div>
      </div>
    );
  };

  const { isLoading, mutate, status } = useMutateMoneyRequest(onClose, history);
  const handleRequestMoney = (data) => {
    const formData = {
      name: data.name,
      amount: data.amount,
      description: data.name,
      category_id: data.categoryid.id,
      recipient_id: data.recipient_id.id,
      withdrawn: 0,

      attachments: data.attachments?.map((attachment) => {
        return Object.assign({
          guid: attachment.guid,
          name: attachment.name,
        });
      }),

      user_id: userData.id,
      company_id: userData.activeCompanyId,
    };
    mutate(formData);
  };
  return (
    <div
      className="overlay"
      onClick={() => (!isDirty ? onClose() : setShowAlertCancelModal(true))}
      dir={i18n.resolvedLanguage === "en" ? "ltr" : "rtl"}
    >
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <div className="bg-white lg:grid lg:grid-cols-12 lg:gap-x-5 money-request-container">
          <form className="col-span-12">
            <div className="sm:rounded-md sm:overflow-hidden">
              <div className="relative text-center p-4">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {t("ask_for_money_request")}
                </h3>
                <button
                  type="button"
                  className="absolute top-4 left-4"
                  onClick={() =>
                    !isDirty ? onClose() : setShowAlertCancelModal(true)
                  }
                >
                  <div>
                    <CloseSvg />
                  </div>
                </button>
              </div>
              <hr />
              <div className="bg-gray-50 py-6 px-4 space-y-6 sm:p-6">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    {t("ask_for_money_request")}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    {t("ask_for_money_request_text")}
                  </p>
                </div>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <Input
                      register={register}
                      type="text"
                      name="name"
                      fieldName={t("money_request_name")}
                      label={t("money_request_name")}
                      placeholder={t("money_request_name")}
                      hint={t("money_request_name_hint")}
                      required={true}
                      maxLength="50"
                      errors={errors?.name}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <SelectMenu
                      name="recipient_id"
                      fieldName={t("recipient")}
                      label={t("recipient")}
                      placeholder={t("recipient")}
                      hint={t("recipient_hint")}
                      options={users}
                      isLoading={isUsersLoading}
                      isError={isUsersError}
                      Controller={Controller}
                      control={control}
                      required={true}
                      errors={errors?.recipient_id}
                      formatOptionLabel={formatOptionLabel}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <Input
                      register={register}
                      type="number"
                      name="amount"
                      fieldName={t("requested_amount")}
                      label={t("requested_amount")}
                      placeholder={t("requested_amount")}
                      hint={t("requested_amount_hint")}
                      required={true}
                      min="1"
                      max="10000000"
                      errors={errors?.amount}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <SelectMenu
                      name="categoryid"
                      fieldName={t("category_name")}
                      label={t("category_name")}
                      placeholder={t("category_name")}
                      hint={t("category_hint")}
                      options={categories}
                      isLoading={iscategoriesLoading}
                      isError={isEategoriesError}
                      newOptionMutation={newCategoryOptionMutation}
                      isCreatable={true}
                      Controller={Controller}
                      control={control}
                      required={true}
                      errors={errors?.categoryid}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6">
                    <TextArea
                      register={register}
                      name="description"
                      fieldName={t("money_request_purpose")}
                      label={t("money_request_purpose")}
                      placeholder={t("money_request_purpose")}
                      hint={t("money_request_purpose_hint")}
                      required={true}
                      maxLength="2000"
                      errors={errors?.description}
                    />
                  </div>
                </div>

                <div className="sm:col-span-2 sm:col-start-10">
                  <div className="flex justify-end">
                    <label
                      htmlFor="attachments"
                      className="border border-gray-300 rounded flex justify-evenly items-center cursor-pointer px-4 py-2"
                    >
                      <div className="ml-2">
                        <AttachmentSvg />
                      </div>
                      <p>{t("attachments")}</p>
                    </label>
                    <div />
                  </div>
                </div>

                <div className="col-span-6">
                  <div className="max-w-full">
                    <UploadInput
                      multiple={true}
                      accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf, image/*"
                      name="attachments"
                      id="attachments"
                      showUplodedFilesNames={true}
                      getValues={getValues}
                      fileSize="5000000"
                      errors={errors?.attachments}
                      Controller={Controller}
                      control={control}
                      setValue={setValue}
                      trigger={trigger}
                    />
                  </div>
                </div>
              </div>
              <hr />

              <div className="grid sm:grid-cols-6 gap-5 px-4 py-3 bg-white text-right sm:px-6">
                <Fragment>
                  <button
                    type="button"
                    className={`col-span-1 sm:col-start-3 gap-6 flex justify-start items-center bg-black px-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white  ${
                      !isValid
                        ? "cursor-not-allowed disabled bg-opacity-50"
                        : ""
                    }`}
                    disabled={!isValid}
                    onClick={() => setShowAlertConfirmModal(true)}
                  >
                    {t("send")}
                  </button>
                  <Modal
                    showModal={showAlertConfirmModal}
                    onClose={() => setShowAlertConfirmModal(false)}
                  >
                    <Alert
                      hasFooter={true}
                      alertType="confirm"
                      title={t("confirm_send_money_request")}
                      body={() => (
                        <div>
                          {t("alert_text_confirm_send_money_request", {recipient: getValues("recipient_id")?.label, amount: getValues("amount")})}
                        </div>
                      )}
                      confirmText={t("confirm_send_money_request")}
                      cancelText={t("cancel")}
                      isSubmitLoading={isLoading}
                      handleConfirm={handleSubmit(handleRequestMoney)}
                      handleCancel={() =>
                        !isLoading && setShowAlertConfirmModal(false)
                      }
                    />
                  </Modal>
                </Fragment>

                <Fragment>
                  <button
                    type="button"
                    className="col-span-1 sm:col-start-4 gap-6 bg-white py-2 px-4 border border-red-400 rounded-md shadow-sm text-sm font-medium text-red-500 hover:bg-gray-50"
                    onClick={() =>
                      !isDirty ? onClose() : setShowAlertCancelModal(true)
                    }
                  >
                    {t("cancel")}
                  </button>
                  <Modal
                    showModal={showAlertCancelModal}
                    onClose={() => setShowAlertCancelModal(false)}
                  >
                    <Alert
                      hasFooter={true}
                      alertType="cancel"
                      title={t("confirm")}
                      body={() => (
                        <div>
                          {t("alert_text_cancel")}
                        </div>
                      )}
                      confirmText={t("confirm")}
                      cancelText={t("cancel")}
                      handleConfirm={onClose}
                      handleCancel={() => setShowAlertCancelModal(false)}
                    />
                  </Modal>
                </Fragment>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MoneyRequestModal;
