import { useDataLayerValue } from "../../context/DataLayer";
import useFetchUserBalance from "../../hooks/reactQuery/useFetchUserBalance";
import useFetchMoneyRequests from "../../hooks/reactQuery/useFetchMoneyRequests";
import Spinner from "../../components/common/Spinner";
import FieldErrorSvg from "../../components/resources/field error svg/FieldErrorSvg";
import { useState, useEffect, useMemo, useRef } from "react";
import moment from "moment";
import useFetchDocumentations from "../../hooks/reactQuery/useFetchDocumentations";
import useFetchCompaniesData from "../../hooks/reactQuery/useFetchCompaniesData";
import useFetchPendingMoneyRequestsPaymentLicenses from "../../hooks/reactQuery/useFetchPendingMoneyRequestsPaymentLicenses";
import useFetchLatestInteractions from "../../hooks/reactQuery/useFetchLatestInteractions";
import useFetchUserData from "../../hooks/reactQuery/useFetchUserData";

const Dashboard = ({}) => {
  const { data: userData } = useFetchUserData();

  const pageSize = process.env.REACT_APP_PAGE_SIZE;
  const [
    receivedCurrentMoneyRequestsCurrentPage,
    setReceivedCurrentMoneyRequestsCurrentPage,
  ] = useState(1);
  const [
    receivedCurrentMoneyRequestsColumnKey,
    setReceivedCurrentMoneyRequestsColumnKey,
  ] = useState("");
  const [
    receivedCurrentMoneyRequestsOrder,
    setReceivedCurrentMoneyRequestsOrder,
  ] = useState("");

  const [
    sentCurrentMoneyRequestsCurrentPage,
    setSentCurrentMoneyRequestsCurrentPage,
  ] = useState(1);
  const [
    sentCurrentMoneyRequestsColumnKey,
    setSentCurrentMoneyRequestsColumnKey,
  ] = useState("");
  const [sentCurrentMoneyRequestsOrder, setSentCurrentMoneyRequestsOrder] =
    useState("");

  const [
    sentFinishedMoneyRequestsCurrentPage,
    setSentFinishedMoneyRequestsCurrentPage,
  ] = useState(1);
  const [
    sentFinishedMoneyRequestsColumnKey,
    setSentFinishedMoneyRequestsColumnKey,
  ] = useState("");
  const [sentFinishedMoneyRequestsOrder, setSentFinishedMoneyRequestsOrder] =
    useState("");

  const [documentationsCurrentPage, setDocumentationsCurrentPage] = useState(1);
  const [documentationsColumnKey, setDocumentationsColumnKey] = useState("");
  const [documentationsOrder, setDocumentationsOrder] = useState("");

  const [
    pendingMoneyRequestsPaymentLicensesCurrentPage,
    setPendingMoneyRequestsPaymentLicensesCurrentPage,
  ] = useState(1);
  const [
    pendingMoneyRequestsPaymentLicensesColumnKey,
    setPendingMoneyRequestsPaymentLicensesColumnKey,
  ] = useState("");
  const [
    pendingMoneyRequestsPaymentLicensesOrder,
    setPendingMoneyRequestsPaymentLicensesOrder,
  ] = useState("");

  const [latestInteractionsCurrentPage, setLatestInteractionsCurrentPage] =
    useState(1);

  const {
    isLoading: isLatestInteractionsLoading,
    data: latestInteractions,
    error: latestInteractionsError,
  } = useFetchLatestInteractions({
    currentPage: latestInteractionsCurrentPage,
    pageSize,
  });

  const {
    isLoading: isUserBalanceLoading,
    data: userBalance,
    error: userBalanceError,
  } = useFetchUserBalance();

  const {
    isLoading: isReceivedCurrentMoneyRequestsLoading,
    data: receivedCurrentMoneyRequestsData,
    error: receivedCurrentMoneyRequestsDataError,
    isReceivedCurrentMoneyRequestsPreviousData,
  } = useFetchMoneyRequests({
    userId: userData.id,
    activeCompanyId: userData.activeCompanyId,
    activeTab: 0,
    subActiveTab: 1,
    currentPage: receivedCurrentMoneyRequestsCurrentPage,
    pageSize,
    order: receivedCurrentMoneyRequestsOrder,
    columnKey: receivedCurrentMoneyRequestsColumnKey,
  });

  const {
    isLoading: isSentCurrentMoneyRequestsLoading,
    data: sentCurrentMoneyRequestsData,
    error: sentCurrentMoneyRequestsDataError,
    isSentCurrentPreviousData,
  } = useFetchMoneyRequests({
    userId: userData.id,
    activeCompanyId: userData.activeCompanyId,
    activeTab: 1,
    subActiveTab: 1,
    currentPage: sentCurrentMoneyRequestsCurrentPage,
    pageSize,
    order: sentCurrentMoneyRequestsOrder,
    columnKey: sentCurrentMoneyRequestsColumnKey,
  });

  const {
    isLoading: isSentFinishedMoneyRequestsLoading,
    data: sentFinishedMoneyRequestsData,
    error: sentFinishedMoneyRequestsDataError,
    isSentFinishedPreviousData,
  } = useFetchMoneyRequests({
    userId: userData.id,
    activeCompanyId: userData.activeCompanyId,
    activeTab: 1,
    subActiveTab: 2,
    currentPage: sentFinishedMoneyRequestsCurrentPage,
    pageSize,
    order: sentFinishedMoneyRequestsOrder,
    columnKey: sentFinishedMoneyRequestsColumnKey,
  });

  const {
    isLoading: isDocumentationsLoading,
    data: documentationsData,
    error: documentationsDataError,
    isDocumentationsPreviousData,
  } = useFetchDocumentations({
    activeTab: 0,
    currentPage: documentationsCurrentPage,
    pageSize,
    order: documentationsOrder,
    columnKey: documentationsColumnKey,
  });

  const {
    isLoading: isCompaniesDataLoading,
    data: companiesData,
    error: companiesDataError,
  } = useFetchCompaniesData(userData.id);

  const {
    isLoading: isPendingMoneyRequestsPaymentLicensesDataLoading,
    data: pendingMoneyRequestsPaymentLicensesData,
    error: pendingMoneyRequestsPaymentLicensesDataError,
    isPendingMoneyRequestsPaymentLicensesPreviousData,
  } = useFetchPendingMoneyRequestsPaymentLicenses({
    currentPage: pendingMoneyRequestsPaymentLicensesCurrentPage,
    pageSize,
    order: pendingMoneyRequestsPaymentLicensesOrder,
    columnKey: pendingMoneyRequestsPaymentLicensesColumnKey,
  });

  const getUserPicture = (id, profile_picture_ext) => {
    return profile_picture_ext
      ? (process.env.REACT_APP_UPLOAD_FILES_BASE_URL
          ? process.env.REACT_APP_UPLOAD_FILES_BASE_URL
          : "") +
          (process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
            ? process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
            : "images/tarbeetapaymentrequests/") +
          `users/${id}/profilepicture` +
          `.${profile_picture_ext}`
      : "../img/default-user.svg";
  };

  return (
    <div className="p-5">
      <h3>Dashboard</h3>

      <p>رصيد حسابك الحالي</p>
      <p>
        {isUserBalanceLoading ? (
          <div className="w-6 h-6 p-px mt-px">
            <Spinner />
          </div>
        ) : userBalanceError ? (
          <div className="w-6 h-6 p-px mt-px">
            <FieldErrorSvg />
          </div>
        ) : (
          userBalance + " ج.م"
        )}
      </p>
      {/* activeTab = 0 and subActiveTab = 1 عهد واردة وجارية */}
      <p>عمليات قيد التنفيذ</p>
      <ul className="list-disc">
        {isReceivedCurrentMoneyRequestsLoading ? (
          <div className="w-6 h-6 p-px mt-px">
            <Spinner />
          </div>
        ) : receivedCurrentMoneyRequestsDataError ? (
          <div className="w-6 h-6 p-px mt-px">
            <FieldErrorSvg />
          </div>
        ) : (
          receivedCurrentMoneyRequestsData[0]?.map((item) => {
            return (
              <li>
                {item?.id} -{item?.name} -{item?.amount} -
                {item?.remaining_amount} -{" "}
                {moment(item?.creation_date).fromNow()}
              </li>
            );
          })
        )}
      </ul>
      {/* activeTab = 1 and subActiveTab = 1 عهد صادرة وجارية */}
      <p>عمليات قيد التنفيذ الصادرة فقط</p>
      <ul className="list-disc">
        {isSentCurrentMoneyRequestsLoading ? (
          <div className="w-6 h-6 p-px mt-px">
            <Spinner />
          </div>
        ) : sentCurrentMoneyRequestsDataError ? (
          <div className="w-6 h-6 p-px mt-px">
            <FieldErrorSvg />
          </div>
        ) : (
          sentCurrentMoneyRequestsData[0]?.map((item) => {
            return (
              <li>
                {item?.id} -{item?.name} -{item?.amount} -
                {item?.remaining_amount} -{" "}
                {moment(item?.creation_date).fromNow()}
              </li>
            );
          })
        )}
      </ul>
      <p>عمليات معلقة</p>
      <ul className="list-disc">
        {isPendingMoneyRequestsPaymentLicensesDataLoading ? (
          <div className="w-6 h-6 p-px mt-px">
            <Spinner />
          </div>
        ) : pendingMoneyRequestsPaymentLicensesDataError ? (
          <div className="w-6 h-6 p-px mt-px">
            <FieldErrorSvg />
          </div>
        ) : (
          pendingMoneyRequestsPaymentLicensesData?.data?.map((item) => {
            return (
              <li>
                {item?.id} -{item?.name} -{item?.amount} -
                {item?.remaining_amount} -{" "}
                {moment(item?.creation_date).fromNow()} -{" "}
                {item?.request_type === 1
                  ? "إذن صرف"
                  : item?.request_type === 2
                  ? item?.withdrawn === 1
                    ? "عملية سحب"
                    : "طلب عهدة"
                  : item?.request_type === 3
                  ? "تدوين مالي"
                  : "عملية إيداع"}
              </li>
            );
          })
        )}
      </ul>
      <p>الحوالات</p>
      <p>
        {isSentFinishedMoneyRequestsLoading ? (
          <div className="w-6 h-6 p-px mt-px">
            <Spinner />
          </div>
        ) : sentFinishedMoneyRequestsDataError ? (
          <div className="w-6 h-6 p-px mt-px">
            <FieldErrorSvg />
          </div>
        ) : (
          sentFinishedMoneyRequestsData[1][0]?.overall_total +
          " " +
          moment(
            sentFinishedMoneyRequestsData[0][
              sentFinishedMoneyRequestsData[0].length - 1
            ]?.creation_date
          ).fromNow()
        )}
      </p>
      <p>قيد التنفيذ</p>
      <p>
        {isReceivedCurrentMoneyRequestsLoading ? (
          <div className="w-6 h-6 p-px mt-px">
            <Spinner />
          </div>
        ) : receivedCurrentMoneyRequestsDataError ? (
          <div className="w-6 h-6 p-px mt-px">
            <FieldErrorSvg />
          </div>
        ) : (
          receivedCurrentMoneyRequestsData[1][0]?.overall_inactive_total +
          " " +
          moment(
            receivedCurrentMoneyRequestsData[0][
              receivedCurrentMoneyRequestsData[0].length - 1
            ]?.creation_date
          ).fromNow()
        )}
      </p>
      <p>المصروفات</p>
      <p>
        {isDocumentationsLoading ? (
          <div className="w-6 h-6 p-px mt-px">
            <Spinner />
          </div>
        ) : documentationsDataError ? (
          <div className="w-6 h-6 p-px mt-px">
            <FieldErrorSvg />
          </div>
        ) : (
          documentationsData[1][0]?.overall_total +
          " " +
          moment(
            documentationsData[0][documentationsData[0].length - 1]
              ?.creation_date
          ).fromNow()
        )}
      </p>
      <p>الأرصدة الحالية</p>
      <ul className="list-disc">
        {isCompaniesDataLoading ? (
          <div className="w-6 h-6 p-px mt-px">
            <Spinner />
          </div>
        ) : companiesDataError ? (
          <div className="w-6 h-6 p-px mt-px">
            <FieldErrorSvg />
          </div>
        ) : (
          companiesData?.map((item) => {
            return (
              <li>
                <p>
                  {item?.companyData?.name} -{" "}
                  {item?.companyData?.capital_balance}
                </p>
                <ul className="list-disc mr-5">
                  {item?.companyMemebersData?.map((companyMember) => {
                    return (
                      <li>
                        {companyMember?.id} - {companyMember?.name} -{" "}
                        {companyMember?.balance}
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })
        )}
      </ul>
      <p>آخر التحديثات</p>
      <p>
        {isLatestInteractionsLoading ? (
          <div className="w-6 h-6 p-px mt-px">
            <Spinner />
          </div>
        ) : latestInteractionsError ? (
          <div className="w-6 h-6 p-px mt-px">
            <FieldErrorSvg />
          </div>
        ) : (
          latestInteractions?.map((item) => {
            return (
              <li>
                <img
                  className="w-8 h-8 rounded-full flex justify-center items-center"
                  src={getUserPicture(
                    item?.creator_user_id,
                    item?.creator_profile_picture_ext
                  )}
                />
                <img
                  className="w-8 h-8 rounded-full flex justify-center items-center"
                  src={getUserPicture(
                    item?.affected_user_id,
                    item?.affected_profile_picture_ext
                  )}
                />
                <p>{item?.type}</p>
                <p>{moment(item?.created_at).fromNow()}</p>
              </li>
            );
          })
        )}
      </p>
    </div>
  );
};

export default Dashboard;
