import actionTypes from "../../context/types";

import Withdraw from "./Tables/Withdraw/index";
import WithdrawWithPerm from "./Tables/Withdraw/withdrawWithPerm/index";

import Deposit from "./Tables/Deposit/index";
import DepositWithPerm from "./Tables/Deposit/DepositWithPerm/index";

import useFetchUserData from "../../hooks/reactQuery/useFetchUserData";
import useFetchLastUpdates from "../../hooks/reactQuery/useFetchLastUpdates";
import useFetchPendingCash from "../../hooks/reactQuery/useFetchPendingCash";

import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
import Indicators from "../../components/resources/icons/Indicators";
import { useDataLayerValue } from "../../context/DataLayer";
import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import "./SingleSafe.scss";
import Chart from "../../components/safe/Charts/StackedBar";
import LastUpdatesRow from "../../components/safe/Dashboard/Latest/LastUpdatesRow";
import LatestIcon from "../../components/resources/icons/LatestIcon";
import Holdings from "../../components/resources/icons/Holdings";
import Latest from "../../components/safe/Dashboard/Latest";
import OnHoldRow from "../../components/safe/Dashboard/Latest/OnHoldRow";
import TotalSection from "../../components/safe/Dashboard/TotalSection";
import ActiveMoneyRequests from "../../components/safe/Dashboard/ActiveMoneyRequests";
import ActiveMoneyRequestsIcon from "../../components/resources/icons/ActiveMoneyRequestsIcon";
import useFetchCompanyBalance from "../../hooks/reactQuery/useFetchCompanyBalance";
import HeaderSectionData from "../../components/safe/Dashboard/HeaderSectionData";
import useFetchMonthlyCashin from "../../hooks/reactQuery/useFetchMonthlyCashin";
import useFetchMonthlyExpenses from "../../hooks/reactQuery/useFetchMonthlyExpenses";
import useFetchCurrentMoneyRequests from "../../hooks/reactQuery/useFetchCurrentMoneyRequests";
import { useTranslation } from "react-i18next";

const SingleSafe = () => {
    const { data: userData } = useFetchUserData();
    const [showIndicators, setShowIndicators] = useState(true);
    const {t, i18n} = useTranslation();

    const history = useHistory();

    const params = useParams();
    const safeId = params.id;

    const currentSafe = userData.safes.find(safe => safe.id == safeId && safe.active == 1);
    if(!currentSafe) history.push("/");

    const canDeposit = currentSafe.permissions.includes("CashInWithPermission") || currentSafe.permissions.includes("CashInWithoutPermission");
    const canWithdraw = currentSafe.permissions.includes("CashOutWithPermission") || currentSafe.permissions.includes("CashOutWithoutPermission");

    const [disableCashout, setDisableCashout] = useState(false);
    const [disableCashin, setDisableCashin] = useState(false);
    const [mainPage, setMainPage] = useState("cashin");
    const [subPage, setSubPage] = useState(null);

    useEffect(() => {
        if(!currentSafe) {
            history.push("/"); 
            return;
        }

        if(!currentSafe.permissions.includes("SafeDataView")) {
            history.push("/");
        }

        if(!currentSafe.permissions.includes("CashInWithPermission")
        &&!currentSafe.permissions.includes("CashInWithoutPermission")
        &&!currentSafe.permissions.includes("CashOutWithPermission")
        &&!currentSafe.permissions.includes("CashOutWithoutPermission")
        &&!currentSafe.permissions.includes("CashFullControl")
        &&!currentSafe.permissions.includes("SafeDataView")
        ) history.push("/");

        if(!currentSafe.permissions.includes("CashOutWithPermission")
        && !currentSafe.permissions.includes("CashOutWithoutPermission")
        &&!currentSafe.permissions.includes("CashFullControl")
        &&!currentSafe.permissions.includes("SafeDataView")
        ) {
            setDisableCashout(true);
            if(mainPage === "cashout") 
                setMainPage("cashin");
        }

        if(!currentSafe.permissions.includes("CashInWithPermission")
        && !currentSafe.permissions.includes("CashInWithoutPermission")
        &&!currentSafe.permissions.includes("CashFullControl")
        &&!currentSafe.permissions.includes("SafeDataView")
        ) {
            setDisableCashin(true);
            if(mainPage === "cashin") 
                setMainPage("cashout");
        }
    }, []);

    useEffect(() => {
        if(disableCashin && mainPage === "cashin") setMainPage("cashout");
        if(disableCashout && mainPage === "cashout") setMainPage("cashin");
        if(mainPage === "cashout" && subPage === "money_requests" || mainPage === "cashout" && !subPage) 
            setSubPage("money_requests");
        else if(mainPage === "cashout" && subPage === "documents")
            setSubPage("documents");
        else setSubPage(null);
    }, [mainPage]);

    const [pendingCashState, setPendingCashState] = useState([]);
    const [pendingCashLastPage, setPendingCashLastPage] = useState(1);
    const [currentPendingCashPage, setCurrentPendingCashPage] = useState(1);
  
    const {
      isLoading: isPendingCashLoading,
      data: pendingCash,
      error: pendingCashError,
    } = useFetchPendingCash(safeId, currentPendingCashPage);
  
    useEffect(() => {
      if(pendingCash && pendingCash.length > 0) {
        setPendingCashLastPage(Math.ceil(pendingCash[1][0].count /10));
        setPendingCashState(currentPendingCash => [...currentPendingCash, ...pendingCash[0]]);
      }
    }, [pendingCash]);

    const [currentLastUpdatesPage, setCurrentLastUpdatesPage] = useState(1);
    const [lastUpdatesLastPage, setLastUpdatesLastPage] = useState(1);
    const [lastUpdatesState, setLastUpdatesState] = useState([]);
    const [lastUpdatesPageSize, setLastUpdatesPageSize] = useState(20);
  
    const {
      isLoading: isLastUpdatesLoading,
      data: lastUpdates,
      error: lastUpdatesError,
    } = useFetchLastUpdates(safeId, currentLastUpdatesPage, lastUpdatesPageSize);
  
    useEffect(() => {
      if(lastUpdates && lastUpdates.length > 0) {
        setLastUpdatesLastPage(Math.ceil(lastUpdates[1][0].count / lastUpdatesPageSize));
        setLastUpdatesState(oldData => [...oldData, ...lastUpdates[0]]);
      }
    }, [lastUpdates]);


    const {
        isLoading: isBalanceLoading,
        data: companyBalance,
        error: companyBalanceError,
    } = useFetchCompanyBalance(safeId);
    const formattedCompanyBalance = companyBalance ? new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(companyBalance) : 0.00;

    const {
        isLoading: isMonthlyCashinLoading,
        data: monthlyCashin,
        error: monthlyCashinError,
    } = useFetchMonthlyCashin(safeId);
    const formattedMonthlyCashin = monthlyCashin ? new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(monthlyCashin) : 0.00;

    const {
        isLoading: isMonthlyExpensesLoading,
        data: monthlyExpense,
        error: monthlyExpenseError,
    } = useFetchMonthlyExpenses(safeId);
    const formattedMonthlyExpenses = monthlyCashin ? new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(monthlyExpense) : 0.00;

    const {
        isLoading: isCurrentMoneyRequestsLoading,
        data: currentMoneyRequests,
        error: currentMoneyRequestError,
    } = useFetchCurrentMoneyRequests(safeId);
    const formattedCurrentMoneyRequest = currentMoneyRequests ? new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(currentMoneyRequests) : 0.00;

    return (
    <>
        <Breadcrumb items={[{title: t("safes"), action: () => history.push("/settings?tab=6")}, {title: t("safe_name_dynamic", {safe_name: currentSafe?.name}), action: () => {}}]} />
        {userData.permission_tags.includes("CashFullControl")
        || currentSafe.permissions.includes("SafeDataView") ?
        <>
        <div className="mx-4 my-4 col-span-2 sm:col-span-1">
            <div className="safes-header-section">
                <button className="bg-sidebar-background border border-outline py-2 px-3 rounded-md sm:w-max w-full flex justify-between items-center gap-x-1" style={showIndicators ? {border: "2px solid #3B82F6"}: {}} onClick={() => setShowIndicators(show => !show)}>
                    <div>
                        <Indicators fill={showIndicators ? "#3B82F6" : null}/>
                    </div>
                    <span className="text-sm text-body font-medium" style={showIndicators ? {color: "#3B82F6"} : {}}>{t("show_indicators")}</span>
                </button>
                <HeaderSectionData title={t("safe_balance")} balance={formattedCompanyBalance} cashin={formattedMonthlyCashin} expenses={formattedMonthlyExpenses} moneyRequests={formattedCurrentMoneyRequest} />
            </div>
        </div>   
        <div style={{transition: "height .5s ease-out", height: showIndicators ? "550px" : "0px", overflow:"hidden"}}>
            <Chart safeId={safeId}/>
        </div>
        <div className="mx-4 my-3 flex justify-between">
            <Latest 
            title={t("pending_transactions")} 
            icon={<Holdings />} 
            width="49%" 
            count={pendingCash && pendingCash.length > 0 ? pendingCash[1][0].count : 0} 
            amount={pendingCash && pendingCash.length > 0 ? new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP',  maximumSignificantDigits: 12}).format(pendingCash[1][0].total): 0} 
            currentPage={currentPendingCashPage} 
            lastPage={pendingCashLastPage} 
            setCurrentPage={setCurrentPendingCashPage} 
            isLoading={isPendingCashLoading}
            >
            {pendingCashState && pendingCashState.length > 0 ? pendingCashState.map(record => 
            <OnHoldRow data={record} />) : null}
            </Latest>

            <Latest 
            title={t("last_updates")} 
            icon={<LatestIcon />} 
            width="49%"
            count={lastUpdates && lastUpdates.length > 0 ? lastUpdates[1][0].count : 0} 
            currentPage={currentLastUpdatesPage} 
            lastPage={lastUpdatesLastPage} 
            setCurrentPage={setCurrentLastUpdatesPage} 
            isLoading={isLastUpdatesLoading}
            >
            {lastUpdatesState && lastUpdatesState.length > 0 ? lastUpdatesState.map(record => 
                <LastUpdatesRow data={record} />) : null}  
            </Latest>
        </div>

        {showIndicators ? <TotalSection safeId={safeId} /> : null} </> : null}

        <div className="bg-white my-3">
            <div>
                <div className="mx-4 my-3">
                    <div className="flex items-center justify-start safes_header">
                    <ActiveMoneyRequestsIcon />
                    <span className="mx-5">{t("running_money_request_data")}</span>
                </div>
                    <ActiveMoneyRequests safeId={safeId} />
                </div>
            </div>
        </div>

        <div className="mx-4 grid grid-cols-2">     
            {canDeposit ?        
            <button
            type="button"
            className={`col-span-1 flex items-center justify-center py6 ${
                mainPage === "cashin"
                    ? "border-b-2 border-secondary"
                    : "border-b border-separator"
                }`}
            onClick = {() => setMainPage("cashin")}
            >
                <h4 className={`text-base leading-5 font-medium ${disableCashin ? "disabledButton" : mainPage === "cashin" ? "text-secondary" : ""}`}>{t("chart_type_deposits")}</h4>
            </button> : null }
            {canWithdraw ? 
            <button
            type="button"
            className={`relative col-span-1 flex items-center py-6 responsiveBtn ${
                mainPage === "cashout"
                    ? "border-b-2 border-secondary"
                    : "border-b border-separator"
                }`}
            onClick={() => setMainPage("cashout")}
            >
                <h4 className={`text-base leading-5 font-medium ${disableCashout ? "disabledButton" : mainPage === "cashout" ? "text-secondary" : ""}`}>{t("chart_type_withdrawals")}</h4>
                {
                currentSafe && currentSafe.permissions.includes("CashFullControl")
                || currentSafe && currentSafe.permissions.includes("CashOutWithoutPermission")
                || currentSafe.permissions.includes("SafeDataView") ?    
                <div className="floatingButtons">
                    <button 
                        type="button" 
                        className={`floatingButton ${subPage === "money_requests" ? "subActive" : ""}`}
                        onClick={() => setSubPage("money_requests")}
                    >
                    {t("money_requests_plural")} 
                    </button>                    
                    <button 
                        type="button" 
                        className={`floatingButton ${subPage === "documents" ? "subActive" : ""}`}
                        onClick={() => setSubPage("documents")}
                    >
                        {t("documentations")}
                    </button>
                    
                </div> : null}
            </button> : null}
        </div>

        {
            mainPage === "cashout" ?
                currentSafe && currentSafe.permissions.includes("CashFullControl")
                || currentSafe.permissions.includes("SafeDataView") ?
                <Withdraw safeId={safeId} permission_tags={currentSafe.permissions} subTab={subPage} /> : <WithdrawWithPerm safeId={safeId} />
            : mainPage === "cashin" ? 
                currentSafe && currentSafe.permissions.includes("CashFullControl")
                || currentSafe.permissions.includes("SafeDataView") ?
                <Deposit safeId={safeId} permission_tags={currentSafe.permissions} /> : <DepositWithPerm safeId={safeId} />
            : null
        }
    </>
    );
};

export default SingleSafe;
