const UserName = () => {
  return (
    <div>
      <svg
        width={20}
        height={20}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M16.2586 4.87435L11.3086 2.01602C10.5003 1.54935 9.50026 1.54935 8.68359 2.01602L3.74193 4.87435C2.93359 5.34102 2.43359 6.20768 2.43359 7.14935V12.8493C2.43359 13.7827 2.93359 14.6493 3.74193 15.1243L8.69193 17.9827C9.50026 18.4493 10.5003 18.4493 11.3169 17.9827L16.2669 15.1243C17.0753 14.6577 17.5753 13.791 17.5753 12.8493V7.14935C17.5669 6.20768 17.0669 5.34935 16.2586 4.87435ZM10.0003 6.11602C11.0753 6.11602 11.9419 6.98268 11.9419 8.05768C11.9419 9.13268 11.0753 9.99935 10.0003 9.99935C8.92526 9.99935 8.05859 9.13268 8.05859 8.05768C8.05859 6.99102 8.92526 6.11602 10.0003 6.11602ZM12.2336 13.8827H7.76693C7.09193 13.8827 6.70026 13.1327 7.07526 12.5743C7.64193 11.7327 8.74193 11.166 10.0003 11.166C11.2586 11.166 12.3586 11.7327 12.9253 12.5743C13.3003 13.1243 12.9003 13.8827 12.2336 13.8827Z'
          fill='#94A3B8'
        />
      </svg>
    </div>
  )
}

export default UserName
