import { createContext, useContext, useMemo, useReducer } from "react";

export const DataLayerContext = createContext();

export const DataLayer = ({ intialState, reducer, children }) => {
  const [state, dispatch] = useReducer(reducer, intialState);
  const value = useMemo(() => [state, dispatch], [state]);
  return (
    <DataLayerContext.Provider value={value}>
      {children}
    </DataLayerContext.Provider>
  );
};

export const useDataLayerValue = () => useContext(DataLayerContext);
