import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useMutateHoldMoneyRequest(
  onClose
) {
  const queryClient = useQueryClient();
  return useMutation(
    (id) => {
      return apiClient.patch(`money_request/hold`, {id});
    },
    {
      onSuccess: (res, sentData) => {
        toast.error("تم حجب العملية");
        queryClient.invalidateQueries("getMoneyRequestsData");
        queryClient.invalidateQueries("getSingleMoneyRequest");
        queryClient.invalidateQueries("userData");
        onClose();
      },
      onError: (error) => {
        onClose();
        return error;
      },
    }
  );
}
