import React, { PureComponent } from 'react';
import { PieChart, Pie, Tooltip, ResponsiveContainer } from 'recharts';

export default class PyChart extends PureComponent {

  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={200} height={200}>
            {this.props.data.map(d => <Pie
                dataKey="value"
                isAnimationActive={true}
                data={this.props.data}
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill={d.fill}
            />)}
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
