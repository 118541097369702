import { useState } from "react";

import WithdrawFullControl from "./WithdrawFullControl";
import WithdrawWithoutPerm from "./WithdrawWithoutPerm";
import Documentations from "./Documentations.js/index";

const Withdraw = (props) => {
  return (
    <div>
      {props.subTab === "money_requests" ? (
        props.permission_tags.includes("CashFullControl") ||
        props.permission_tags.includes("CashReadOnly") ? (
          <>
            <WithdrawFullControl safeId={props.safeId} />
          </>
        ) : (
          <>
            <WithdrawWithoutPerm safeId={props.safeId} />
          </>
        )
      ) : (
        <>
          <Documentations safeId={props.safeId} permission_tags={props.permission_tags}/>
        </>
      )}
    </div>
  );
};

export default Withdraw;
