import { useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";

import Selector from "../../../resources/icons/Selector";
import Check from "../../../resources/icons/Check";
import MainFinancial from "./../../../resources/icons/MainFinancial";
import useClickOutside from "../../../../hooks/useClickOutside";
import "./SelectMenu.scss";
import { useTranslation } from "react-i18next";
import { fileExtByFileName } from "../../../../utils/fileUtils";

const SelectMenu = ({
  Controller,
  control,
  name,
  label,
  placeholder,
  hint,
  Icon,
  fieldName,
  isLoading,
  isError,
  options,
  LatestOptionsMarkup,
  defaultValue,
  required,
  validate,
  errors,
  isCreatable = false,
  isClearable = true,
  isRtl = true,
  isMulti = false,
  isSearchable = true,
  menuPlacement = "auto",
  customStyles,
  maxMenuHeight,
  formatOptionLabel,
  newOptionMutation,
  changeValue: clearSelectedValue,
  isDisabled = false,
  onChangeHandler = () => {},
  onOpenHandler = () => {}
}) => {
  // control menu open and close
  const [open, setOpen] = useState(false);
  const {t, i18n} = useTranslation();

  // Create new option
  const createOption = (label) => ({
    label,
    value: label.toLowerCase(),
  });
  const handleCreate = (inputValue) => {
    const newOption = createOption(inputValue);
    newOptionMutation.mutate(newOption);
  };

  // Default styles
  const styles = {
    container: (provided, state) => ({
      ...provided,
      dir: i18n.resolvedLanguage === "en" ? "ltr" : "rtl",
      direction: i18n.resolvedLanguage === "en" ? "ltr" : "rtl",
    }),
    control: (provided, state) => ({
      ...provided,
      minHeight: "unset",
      border: "none",
      outline: "none",
      boxShadow:
        state.menuIsOpen && errors
          ? "0 0 0 2px #EF4444"
          : errors
          ? "0 0 0 1px #FCA5A5"
          : state.menuIsOpen
          ? "0 0 0 2px #2563eb"
          : "0 0 0 1px #CBD5E1",
      transition: "none",
      maxHeight: "38px",
      overflow: "hidden",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "inherit",
      backgroundColor: (state.isFocused || state.isSelected) && "#F8FAFC",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 2000,
    }),
    menuList: (provided) => ({
      ...provided,
      overflow: "overlay",
      "::-webkit-scrollbar": {
        width: "10px",
        height: "10px",
      },
      "::-webkit-scrollbar-track": {
        backgroundColor: "#f8fafc",
        borderRadius: "8px 0 0 8px",
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "#cbd5e1",
        border: "1px solid #e7f5ff",
        borderRadius: "10px",
      },
    }),
  };

  // Custom format option lable
  const customFormatOptionLabel = (data, context) => {
    return (
      <div className="flex justify-between items-center">
        {!data?.__isNew__ && (
          <>
            <div className="flex justify-center items-center gap-x-3 selection-container">
              <p
                className={`text-sm text-body selection-weight ${
                  context.selectValue &&
                  context.selectValue[0]?.value === data?.value
                    ? "font-bold"
                    : "font-medium"
                }`}
              >
                {data.label}
              </p>
            </div>
            {context.selectValue &&
              context.selectValue[0]?.value === data?.value &&
              !isDisabled && (
                <div>
                  <Check />
                </div>
              )}
          </>
        )}
        {data?.__isNew__ && (
          <div className="flex justify-center items-center">{data.label}</div>
        )}
      </div>
    );
  };

  // User can create options or not!
  const SelectComponent = isCreatable ? CreatableSelect : Select;

  //
  // Clear selected value upon certain condition
  let selectRef = null;
  const clearValue = () => {
    selectRef?.select?.select?.clearValue();
  };
  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    clearValue();
  }, [clearSelectedValue]);

  // customize dropdown arrow and side icon
  const SelectMenuControlIcon = ({ children, ...rest }) => (
    <components.Control {...rest}>
      <div className="pointer-events-none bg-separator rounded-r flex justify-center items-center p-2.5 w-9">
        {Icon ? <Icon /> : <MainFinancial />}
      </div>

      {children}
    </components.Control>
  );
  const DropdownIndicator = () => (
    <div className="px-3 py-2">
      <Selector />
    </div>
  );

  // close menu on click outside
  const clickRef = useRef();

  useClickOutside(clickRef, () => {
    setOpen(false);
  });

  const onOpen = () => {
    onOpenHandler();
    setOpen(true);
  }

  return (
    <>
      <div className="flex justify-between items-center mb-1">
        <div className="flex gap-x-4">
          {label && (
            <label
              htmlFor={name}
              className="text-sm text-body font-medium min-w-max"
            >
              {label}
            </label>
          )}

          {LatestOptionsMarkup && LatestOptionsMarkup}
        </div>

        {errors && (
          <p className="text-xs leading-5 font-medium text-error">
            {errors.message}
          </p>
        )}
      </div>

      <div
        ref={clickRef}
        className={`relative ${isDisabled && "cursor-not-allowed disabled"}`}
      >
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={{
            required: required
              ? t("field_required", {field_name: fieldName ? fieldName : ""})
              : null,
            validate: validate && validate,
          }}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => {
            return (
              <SelectComponent
                menuIsOpen={open}
                onMenuOpen={() => onOpen()}
                onMenuClose={() => setOpen(false)}
                maxMenuHeight={maxMenuHeight}
                isLoading={isLoading}
                options={options}
                onChange={(selectedOption) => {
                  onChangeHandler(selectedOption);
                  onChange(
                    selectedOption?.constructor === Array
                      ? selectedOption?.map((option) => option)
                      : selectedOption
                  );
                }}
                //
                value={value}
                //
                ref={(ref) => {
                  selectRef = ref;
                }}
                //
                onBlur={(e) => onBlur(e)}
                onCreateOption={handleCreate}
                defaultValue={defaultValue}
                placeholder={placeholder ? placeholder : ""}
                styles={customStyles ? customStyles : styles}
                formatOptionLabel={
                  formatOptionLabel
                    ? formatOptionLabel
                    : customFormatOptionLabel
                }
                isClearable={isClearable}
                isRtl={isRtl}
                isMulti={isMulti}
                isSearchable={isSearchable}
                menuPlacement={menuPlacement}
                isDisabled={isDisabled}
                noOptionsMessage={() =>
                  isError
                    ? "!Some thing went wrong"
                    : options?.length === 0
                    ? "!No options"
                    : ""
                }
                components={{
                  Control: SelectMenuControlIcon,
                  DropdownIndicator,
                }}
              />
            );
          }}
        />
      </div>
      {hint && (
        <p className="mt-2 text-xs leading-5 font-normal text-hints">{hint}</p>
      )}
    </>
  );
};

export default SelectMenu;
