import PropTypes from "prop-types";

function Icon(props) {
  const { className, ...restOfProps } = props;
  return (
    <svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      className={`${className}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restOfProps}
    >
      <g clipPath="url(#clip0_2688:48950)">
        <path
          d="M8.63251 0.382469C8.55836 0.322234 8.46074 0.299207 8.36752 0.319978L0.242533 2.19498C0.0995354 2.22785 -0.00136303 2.35576 1.3922e-05 2.50248V13.1275C7.25158e-05 13.2817 0.112602 13.4128 0.265004 13.4362L8.38999 14.6863C8.56059 14.7125 8.72014 14.5955 8.74636 14.4249C8.74876 14.4092 8.74999 14.3934 8.74999 14.3775V0.627479C8.75028 0.532176 8.70701 0.441971 8.63251 0.382469Z"
          fill="#3B82F6"
        />
        <path
          d="M14.6875 13.4414H8.43751C8.26492 13.4414 8.125 13.3015 8.125 13.1289C8.125 12.9563 8.26492 12.8164 8.43751 12.8164H14.375V2.1914H8.43751C8.26492 2.1914 8.125 2.05147 8.125 1.87889C8.125 1.7063 8.26492 1.56641 8.43751 1.56641H14.6875C14.8601 1.56641 15 1.70633 15 1.87892V13.1289C15 13.3015 14.8601 13.4414 14.6875 13.4414Z"
          fill="#3B82F6"
        />
        <path
          d="M5.31348 10.3132C5.17423 10.3131 5.05192 10.2208 5.01348 10.087L4.06347 6.76323L3.11346 10.087C3.04485 10.2528 2.85477 10.3317 2.68889 10.2631C2.60914 10.2301 2.5458 10.1667 2.51282 10.087L1.26284 5.712C1.21051 5.54752 1.30145 5.3718 1.46592 5.31948C1.63039 5.26715 1.80612 5.35809 1.85844 5.52256C1.86029 5.52836 1.86196 5.53422 1.86348 5.54011L2.81346 8.86323L3.76347 5.5395C3.83208 5.37365 4.02216 5.29481 4.18804 5.36342C4.26779 5.39641 4.33113 5.45975 4.36411 5.5395L5.31348 8.86326L6.26349 5.54014C6.30846 5.37567 6.47826 5.27878 6.64274 5.32375C6.64508 5.3244 6.6474 5.32507 6.64974 5.32575C6.81556 5.37327 6.91151 5.54615 6.8641 5.712L5.6141 10.087C5.5756 10.221 5.45293 10.3134 5.31348 10.3132Z"
          fill="#CBD5E1"
        />
        <path
          d="M13.4375 4.06252H8.43751C8.26492 4.06252 8.125 3.9226 8.125 3.75001C8.125 3.57742 8.26492 3.4375 8.43751 3.4375H13.4375C13.6101 3.4375 13.75 3.57742 13.75 3.75001C13.75 3.92263 13.6101 4.06252 13.4375 4.06252Z"
          fill="#3B82F6"
        />
        <path
          d="M13.4375 5.93752H8.43751C8.26492 5.93752 8.125 5.7976 8.125 5.62501C8.125 5.45242 8.26492 5.3125 8.43751 5.3125H13.4375C13.6101 5.3125 13.75 5.45242 13.75 5.62501C13.75 5.7976 13.6101 5.93752 13.4375 5.93752Z"
          fill="#3B82F6"
        />
        <path
          d="M13.4375 7.81252H8.43751C8.26492 7.81252 8.125 7.6726 8.125 7.50001C8.125 7.32742 8.26492 7.1875 8.43751 7.1875H13.4375C13.6101 7.1875 13.75 7.32742 13.75 7.50001C13.75 7.67263 13.6101 7.81252 13.4375 7.81252Z"
          fill="#3B82F6"
        />
        <path
          d="M13.4375 9.68752H8.43751C8.26492 9.68752 8.125 9.5476 8.125 9.37501C8.125 9.20242 8.26492 9.0625 8.43751 9.0625H13.4375C13.6101 9.0625 13.75 9.20242 13.75 9.37501C13.75 9.54763 13.6101 9.68752 13.4375 9.68752Z"
          fill="#3B82F6"
        />
        <path
          d="M13.4375 11.5625H8.43751C8.26492 11.5625 8.125 11.4226 8.125 11.25C8.125 11.0774 8.26492 10.9375 8.43751 10.9375H13.4375C13.6101 10.9375 13.75 11.0774 13.75 11.25C13.75 11.4226 13.6101 11.5625 13.4375 11.5625Z"
          fill="#3B82F6"
        />
      </g>
      <defs>
        <clipPath id="clip0_2688:48950">
          <rect width={15} height={15} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
};

export default Icon;
