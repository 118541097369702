import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Input from "../../components/common/inputs/Input";
import TextArea from "../../components/common/inputs/Textarea";
import SelectMenu from "../../components/common/inputs/Select";
import { categoriesLatestOptionsMarkup } from "../../utils/markUp";
import "./style.scss";
import { useMutation, useQuery } from "react-query";
import apiClient from "../../services/apiClient";
import { toast } from "react-toastify";
import People from "../../components/resources/icons/people";
import Building from "../../components/resources/icons/Building";
import UserRoleSelect from "../../components/resources/icons/UserRoleSelect";
import Receipt from "../../components/resources/icons/Receipt";
import Moneys from "../../components/resources/icons/Moneys";
import Plus from "../../components/resources/icons/Plus";
import Minus from "../../components/resources/icons/Minus";
import Add from "../../components/resources/icons/Add";
import ReactTagInput from "@pathofdev/react-tag-input";
import { useTranslation } from "react-i18next";

const WorkTools = ({ history, next, previous, completed, notCompleted, isSubmitting, cancel }) => {
  const {t, i18n} = useTranslation();
  const communicationToolsRequest = useQuery("communicationTools", () => {
    return apiClient.get("/request_for_access/loadcommunication");
  });

  const financialToolsRequest = useQuery("financialTools", () => {
    return apiClient.get("/request_for_access/loadfinancial");
  });

  const [financialTools, setFinancialTools] = useState([]);
  const [communicationTools, setCommunicationTools] = useState([]);

  const [extraFinancialTools, setExtraFinancialTools] = useState([]);
  const [extraCommunicationTools, setExtraCommunicationTools] = useState([]);
  
  const handleRegister = () => {
    //save data in localStorage
    localStorage.setItem("request_access_tools", JSON.stringify({
      financialTools,
      communicationTools,
      extraFinancialTools,
      extraCommunicationTools
    }));
    completed();
    next();
  };

  useEffect(() => {
    if(localStorage.getItem("request_access_tools")) {
      const workTools = JSON.parse(localStorage.getItem("request_access_tools"));
      setFinancialTools(workTools.financialTools);
      setCommunicationTools(workTools.communicationTools);
      setExtraFinancialTools(workTools.extraFinancialTools);
      setExtraCommunicationTools(workTools.extraCommunicationTools);
    }

    return () => {
      if (financialTools.length > 0 || communicationTools.length > 0) {
        handleRegister();
      }

      if(localStorage.getItem("request_access_completed")) {
        const completedTabs = JSON.parse(localStorage.getItem("request_access_completed"));
        if(completedTabs.includes(3)) {
          completed();
        }
      }
    }
  }, []);
  

  const toggleFinancialTool = tool => {
    setFinancialTools(tools => tools.find(t => t.id == tool.id) ? tools.filter(t => t.id != tool.id) : [...tools, tool]);
  }

  const toggleCommunicationTool = tool => {
    setCommunicationTools(tools => tools.find(t => t.id == tool.id) ? tools.filter(t => t.id != tool.id) : [...tools, tool]);
  }

  return (
    <div className="request_for_access_container">
      <div className="select_section_container">
        <span className="select_section_header">{t("financial_management")}</span>
        <div className="select_section_body">
          {financialToolsRequest?.data?.data.map((tool) => (
            <div key={tool.id} className={`select_section_chip ${financialTools.find(t => t.id == tool.id) ? "select_section_chip_selected" : ""}`} onClick={() => toggleFinancialTool(tool)}>
              {financialTools.find(t => t.id == tool.id) ? <Minus /> : <Add />}
              <span className="mx-1" style={{fontWeight: 500, fontSize: "12px", color: "#6B7280"}}>{tool.name}</span>
            </div>
          ))}
        </div>
        <span className="select_section_hint">{t("company_tools_hint")}</span>
      </div>

      <div className="select_section_container">
        <span className="select_section_header">{t("other_tools")}</span>
          <div style={{width: "100%"}}>
            <ReactTagInput
              className="add-tools"
              tags={extraFinancialTools}
              name="financialTools"
              placeholder={t("add_tool")}
              maxTags={15}
              editable={true}
              readOnly={false}
              removeOnBackspace={true}
              onChange={(newTags) => { 
                setExtraFinancialTools(newTags);
              }}
            />
          </div>
        <span className="select_section_hint">{t("no_right_tools")}</span>
      </div>

      <div className="select_section_container">
        <span className="select_section_header">{t("communication")}</span>
        <div className="select_section_body">
          {communicationToolsRequest?.data?.data.map((tool) => (
            <div key={tool.id} className={`select_section_chip ${communicationTools.find(t => t.id == tool.id) ? "select_section_chip_selected" : ""}`} onClick={() => toggleCommunicationTool(tool)}>
              {communicationTools.find(t => t.id == tool.id) ? <Minus /> : <Add />}
              <span className="mx-1" style={{fontWeight: 500, fontSize: "12px", color: "#6B7280"}}>{tool.name}</span>
            </div>
          ))}
        </div>
        <span className="select_section_hint">{t("company_tools_communication_hint")}</span>
      </div>

      <div className="select_section_container">
        <span className="select_section_header">{t("other_tools")}</span>
          <div style={{width: "100%"}}>
            <ReactTagInput
              className="add-tools"
              tags={extraCommunicationTools}
              name="communicationTools"
              placeholder={t("add_tool")}
              maxTags={15}
              editable={true}
              readOnly={false}
              removeOnBackspace={true}
              onChange={(newTags) => { 
                setExtraCommunicationTools(newTags);
              }}
            />
          </div>
        <span className="select_section_hint">{t("no_right_tools")}</span>
      </div>

      <div className="request_for_access_footer">
        <button className={`request_for_access_btn`}
          onClick={cancel}
        >{t("cancel")}</button>
        <div className="flex justify-center items-center">
          <button className={`request_for_access_btn`}
          onClick={previous}
          >{t("pagination_controls_previous")}</button>
          <button className={`request_for_access_btn ${financialTools.length < 1 || communicationTools.length < 1 || isSubmitting ? "btn_disabled" : ""}`}
          onClick={handleRegister}
          disabled={financialTools.length < 1 || communicationTools.length < 1}
          >{isSubmitting ? `${t("send")} ...` : t("send")}</button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(WorkTools);
