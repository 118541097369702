import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchNotifications(
  pageno,
  pagesize
) {
  return useQuery(
    [
      "notifications",
      pageno,
      pagesize
    ],
    () => {
      const params = {pageno, pagesize};

      return apiClient
        .get("/user/notifications", {
          params,
        })
        .then(({data}) => {
          return data;
        });
    }
  );
}
