import PropTypes from "prop-types";

function Icon(props) {
  const { className, strokeColor, ...restOfProps } = props;
  return (
    <svg
      width={16}
      height={18}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...restOfProps}
    >
      <path
        d="M14.75 9C14.75 10.245 11.75 11.25 8 11.25C4.25 11.25 1.25 10.245 1.25 9"
        stroke={strokeColor ? strokeColor : "#1E293B"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 6.00195C11.7279 6.00195 14.75 4.99459 14.75 3.75195C14.75 2.50931 11.7279 1.50195 8 1.50195C4.27208 1.50195 1.25 2.50931 1.25 3.75195C1.25 4.99459 4.27208 6.00195 8 6.00195Z"
        stroke={strokeColor ? strokeColor : "#1E293B"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.25 3.75195V14.252C1.25 15.497 4.25 16.502 8 16.502C11.75 16.502 14.75 15.497 14.75 14.252V3.75195"
        stroke={strokeColor ? strokeColor : "#1E293B"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
};

export default Icon;
