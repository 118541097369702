import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchResponsibilities(t, i18n) {
  return useQuery("responsibilities", () =>
    apiClient
      ?.get("request_for_access/loadresponsibity")
      .then(({ data }) =>
        data.map((responsibility) => {
          return {
            ...responsibility,
            label: i18n.resolvedLanguage === "en" ? responsibility.en_name : responsibility.name,
            value: responsibility.id,
          };
        })
      )
      .catch((e) => e)
  );
}
