import React from "react";

import { useForm, Controller } from "react-hook-form";

import Spinner from "../../common/Spinner";
import UploadInput from "../../common/inputs/Upload";

import Close from "../../resources/icons/Close";
import Attach from "../../resources/icons/Attach";

import useFetchUserData from "../../../hooks/reactQuery/useFetchUserData";
import useMutateAddSupportPaymentAttachments from "../../../hooks/reactQuery/useMutateAddSupportPaymentAttachments";

import { useDataLayerValue } from "../../../context/DataLayer";

import "./modify.scss";

const Modify = ({ onClose, activeRow }) => {
  const [{ isFileUploading }] = useDataLayerValue();

  const { data: userData } = useFetchUserData();

  const {
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
    control,
    trigger,
  } = useForm({
    mode: "all",
  });

  const {
    isLoading: isMutateSupportAttachmentsLoading,
    mutate: mutateSupportAttachments,
    isError: mutateSupportAttachmentsError,
  } = useMutateAddSupportPaymentAttachments(onClose);

  const handleModifyPaymentLicense = (data) => {
    let modifiedAttachments = data.attachments?.map((attachment) => {
      return Object.assign({
        guid: attachment.guid,
        name: attachment.name,
      });
    });
    data.attachments = modifiedAttachments;
    mutateSupportAttachments({ data, activeRow });
  };

  return (
    <div
      className="overlay"
      onClick={!isMutateSupportAttachmentsLoading && onClose}
    >
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <div className="bg-white max-w-screen-sm payment-modify-license-container">
          <div>
            <div className="py-4 px-6 flex justify-between">
              <div className="flex items-center gap-x-2.5">
                <div>
                  <Attach />
                </div>
                <span className="text-base leading-5 font-bold text-body">
                  تحميل مرفقات
                </span>
              </div>
              <button onClick={onClose}>
                <Close />
              </button>
            </div>
            <hr />
            <form onSubmit={handleSubmit(handleModifyPaymentLicense)}>
              <div className="p-6" style={{ height: "400px" }}>
                <UploadInput
                  multiple={true}
                  accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf, image/*"
                  name="attachments"
                  id="attachments"
                  showUplodedFilesNames={true}
                  getValues={getValues}
                  required={true}
                  fileSize="5000000"
                  errors={errors?.attachments}
                  Controller={Controller}
                  control={control}
                  setValue={setValue}
                  trigger={trigger}
                />
              </div>

              <hr />

              <div className="grid sm:grid-cols-11 grid-cols-2 gap-x-3 py-4 px-6 text-sm font-medium text-body">
                <button
                  type="submit"
                  className={`sm:col-span-3 py-2 border rounded-md shadow-sm flex justify-center items-center gap-x-2 ${
                    !isValid ||
                    isMutateSupportAttachmentsLoading ||
                    isFileUploading
                      ? "cursor-not-allowed bg-side-icons"
                      : "border-separator"
                  }`}
                  disabled={
                    !isValid ||
                    isMutateSupportAttachmentsLoading ||
                    isFileUploading
                  }
                >
                  <span>تأكيد</span>

                  {isMutateSupportAttachmentsLoading && (
                    <div className="w-4 h-4">
                      <Spinner />
                    </div>
                  )}
                </button>

                <button
                  type="button"
                  className={`sm:col-span-3 border border-separator rounded-md shadow-sm ${
                    isMutateSupportAttachmentsLoading
                      ? "cursor-not-allowed"
                      : ""
                  }`}
                  disabled={isMutateSupportAttachmentsLoading}
                  onClick={onClose}
                >
                  إلغاء
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modify;
