import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Input from "../../components/common/inputs/Input";
import TextArea from "../../components/common/inputs/Textarea";
import SelectMenu from "../../components/common/inputs/Select";
import { categoriesLatestOptionsMarkup } from "../../utils/markUp";
import "./style.scss";
import { useMutation, useQuery } from "react-query";
import apiClient from "../../services/apiClient";
import { toast } from "react-toastify";
import People from "../../components/resources/icons/people";
import Building from "../../components/resources/icons/Building";
import UserRoleSelect from "../../components/resources/icons/UserRoleSelect";
import Receipt from "../../components/resources/icons/Receipt";
import Moneys from "../../components/resources/icons/Moneys";
import { useTranslation } from "react-i18next";

const Work = ({ history, next, previous, completed, notCompleted, cancel }) => {
  const {t, i18n} = useTranslation();
  const [canSkip, setCanSkip] = useState(false);

  const specializations = useQuery("specializations", () => {
    return apiClient.get(`/request_for_access/loadspecialization`);
  });

  const members = useQuery("members", () => {
    return apiClient.get("/request_for_access/loadmembers");
  });

  const responsibilities = useQuery("responsibilities", () => {
    return apiClient.get("/request_for_access/loadresponsibity");
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    trigger,
    control,
    getValues,
    setValue,
    reset
  } = useForm({
    mode: "all",
    defaultValues: {
      companyName: "",
      companySpecialization: "",
      companyMembers: "",
      companyResponsibility: "",
      transactions: "",
      transactions_amount: "",
      tarbeetaHelp: ""
    },
  });

  const handleRegister = (data) => {
    //save data in localStorage
    localStorage.setItem("request_access_work", JSON.stringify(data));
    completed();
    next();
  };

  useEffect(() => {
    if(localStorage.getItem("request_access_work")) {
      const workData = JSON.parse(localStorage.getItem("request_access_work"));
      reset({
        companyName: workData.companyName,
        companySpecialization: workData.companySpecialization,
        companyMembers: workData.companyMembers,
        companyResponsibility: workData.companyResponsibility,
        transactions: workData.transactions ? workData.transactions : "",
        transactions_amount: workData.transactions_amount ? workData.transactions_amount : "",
        tarbeetaHelp: workData.tarbeetaHelp
      });
      setCanSkip(true);
    }

    return () => {
      if(isValid) {
        handleSubmit(handleRegister);
      }
      
      if(localStorage.getItem("request_access_completed")) {
        const completedTabs = JSON.parse(localStorage.getItem("request_access_completed"));
        if(!isDirty && completedTabs.includes(2)) {
          completed();
        }
      }
    }
  }, []);


  return (
    <div className="request_for_access_container">
      <form
        className="space-y-4 divide-y divide-gray-200 mx-auto px-5 max-w-screen-lg w-full"
        onSubmit={handleSubmit(handleRegister)}
      >
        <div className="space-y-4 divide-y divide-gray-200">
          <div className="pt-4">
            <fieldset className="w-full">
              <div className="mt-3 grid grid-cols-1 sm:grid-cols-4 gap-y-4 gap-x-4">
                <div className="sm:col-span-2">
                  <Input
                    register={register}
                    type="text"
                    name="companyName"
                    fieldName={t("company_name")}
                    label={t("company_name")}
                    placeholder={t("company_name")}
                    required={true}
                    maxLength="50"
                    errors={errors?.companyName}
                  />
                </div>

                <div className="sm:col-span-2">
                  <SelectMenu
                    name="companySpecialization"
                    fieldName={t("company_specialization")}
                    label={t("company_specialization")}
                    placeholder={t("company_specialization")}
                    options={specializations?.data?.data?.map((item) => {
                      return { value: item.id, label: item.name };
                    })}
                    latestOptions={specializations?.data?.data?.map((item) => {
                      return { value: item.id, label: item.name };
                    }).splice(0, 3)}
                    LatestOptionsMarkup={categoriesLatestOptionsMarkup(
                      specializations?.data?.data?.map((item) => {
                        return { value: item.id, label: item.name };
                      }),
                      getValues,
                      setValue,
                      trigger,
                      `companySpecialization`,
                      "150px"
                    )}
                    Icon={Building}
                    Controller={Controller}
                    control={control}
                    required={true}
                    errors={errors?.companySpecialization}
                  />
                </div>

                <div className="sm:col-span-2">
                  <div className="sm:col-span-4">
                    <SelectMenu
                      name="companyMembers"
                      fieldName={t("average_employees")}
                      label={t("average_employees")}
                      placeholder={t("average_employees")}
                      options={members?.data?.data.map((item) => {
                        return {
                          label:
                            item.min_number_of_members +
                            (item.max_number_of_members === null
                              ? "+"
                              : " - " + item.max_number_of_members),
                          value: item.id,
                          id: item.id,
                        };
                      })}
                      latestOptions={members?.data?.data.map((item) => {
                        return {
                          label:
                            item.min_number_of_members +
                            (item.max_number_of_members === null
                              ? "+"
                              : " - " + item.max_number_of_members),
                          value: item.id,
                          id: item.id,
                        };
                      }).splice(0, 3)}
                      LatestOptionsMarkup={categoriesLatestOptionsMarkup(
                        members?.data?.data.map((item) => {
                          return {
                            label:
                              item.min_number_of_members +
                              (item.max_number_of_members === null
                                ? "+"
                                : " - " + item.max_number_of_members),
                            value: item.id,
                            id: item.id,
                          };
                        }),
                        getValues,
                        setValue,
                        trigger,
                        `companyMembers`,
                        "150px"
                      )}
                      Icon={People}
                      Controller={Controller}
                      control={control}
                      required={true}
                      errors={errors?.companyMembers}
                    />
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <SelectMenu
                    name="companyResponsibility"
                    fieldName={t("company_responsibility")}
                    label={t("company_responsibility")}
                    placeholder={t("company_responsibility")}
                    latestOptions={responsibilities?.data?.data.map((item) => {
                      return { label: item.name, value: item.id };
                    }).splice(0, 3)}
                    LatestOptionsMarkup={categoriesLatestOptionsMarkup(
                      responsibilities?.data?.data.map((item) => {
                        return { label: item.name, value: item.id };
                      }),
                      getValues,
                      setValue,
                      trigger,
                      `companyResponsibility`,
                      "150px"
                    )}
                    options={responsibilities?.data?.data.map((item) => {
                      return { label: item.name, value: item.id };
                    })}
                    Icon={UserRoleSelect}
                    Controller={Controller}
                    control={control}
                    required={true}
                    errors={errors?.companyResponsibility}
                  />
                </div>

                <div className="mt-2 py-4 flex sm:col-span-4" style={{borderTop: "1px solid #CBD5E1"}}>
                  <div className="sm:col-span-2 ml-2">
                    <Input
                      register={register}
                      type="text"
                      name="transactions"
                      fieldName={t("company_average_transactions")}
                      label={t("company_average_transactions")}
                      placeholder={t("company_average_transactions")}
                      hint={t("company_average_transactions_hint")}
                      required={true}
                      maxLength="50"
                      pattern={{
                        regex: /^[0-9]*$/,
                        errorMessage:
                          t("no_spaces_just_numbers"),
                      }}
                      Icon={Receipt}
                      errors={errors?.transactions}
                    />
                  </div>

                  <div className="sm:col-span-2">
                    <Input
                      register={register}
                      type="text"
                      name="transactions_amount"
                      fieldName={t("company_average_transactions_amount_hint")}
                      label={t("company_average_transactions_amount_hint")}
                      placeholder={t("company_average_transactions_amount_hint")}
                      hint={t("company_average_transactions_amount_hint")}
                      required={true}
                      maxLength="50"
                      pattern={{
                        regex: /^[0-9]*$/,
                        errorMessage:
                          t("no_spaces_just_numbers"),
                      }}
                      Icon={Moneys}
                      errors={errors?.transactions_amount}
                    />
                  </div>
                </div>

                <div className="sm:col-span-4 mb-4">
                  <TextArea
                    register={register}
                    name="tarbeetaHelp"
                    fieldName={t("notes")}
                    placeholder={t("write_opinion")}
                    required={false}
                    maxLength="2000"
                    errors={errors?.tarbeetaHelp}
                  />
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </form>
      <div className="request_for_access_footer">
        <button className={`request_for_access_btn`}
          onClick={cancel}
        >{t("cancel")}</button>
        <div className="flex justify-center items-center">
          <button className={`request_for_access_btn`}
          onClick={previous}
          >{t("pagination_controls_previous")}</button>
          <button className={`request_for_access_btn ${!isValid || !isDirty && !canSkip ? "btn_disabled" : ""}`}
          onClick={handleSubmit(handleRegister)}
          disabled={!isValid || !isDirty && !canSkip}
          >{t("pagination_controls_next")}</button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Work);
