const Note = () => {
  return (
    <div>
      <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.6667 6.87501V15C16.6667 17.5 15.175 18.3333 13.3333 18.3333H6.66668C4.82501 18.3333 3.33334 17.5 3.33334 15V6.87501C3.33334 4.16667 4.82501 3.54167 6.66668 3.54167C6.66668 4.05834 6.87499 4.525 7.21665 4.86667C7.55832 5.20834 8.02501 5.41667 8.54168 5.41667H11.4583C12.4917 5.41667 13.3333 4.57501 13.3333 3.54167C15.175 3.54167 16.6667 4.16667 16.6667 6.87501Z"
          stroke="#94A3B8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3333 3.54167C13.3333 4.57501 12.4917 5.41667 11.4583 5.41667H8.54166C8.02499 5.41667 7.5583 5.20834 7.21663 4.86667C6.87497 4.525 6.66666 4.05834 6.66666 3.54167C6.66666 2.50834 7.50832 1.66667 8.54166 1.66667H11.4583C11.975 1.66667 12.4417 1.87501 12.7833 2.21667C13.125 2.55834 13.3333 3.02501 13.3333 3.54167Z"
          stroke="#94A3B8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66666 10.8333H9.99999"
          stroke="#94A3B8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66666 14.1667H13.3333"
          stroke="#94A3B8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Note;
