import React from 'react';
import TableResult from '.';
import UnderDevelopment from '../../resources/icons/UnderDevelopment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
const DevelopmentSection = () => {
  const history = useHistory();
  const {t, i18next} = useTranslation();
  return (
    <TableResult
      Icon={UnderDevelopment}
      title={t("currently_developing")}
      text={t("currently_developing_text")}
      button={true}
      buttonText={t("go_to_home")}
      action={() => {
        history.push('/payment-licenses')
      }}
    />
  )
}

export default DevelopmentSection
