import { useState, Fragment } from "react";

import Modal from "../../../../common/Modal";
import Alert from "../../../../common/Alert";
import Reject from "../../../../resources/icons/Reject";
import Accept from "../../../../resources/icons/Accept";

import useFetchUserData from "../../../../../hooks/reactQuery/useFetchUserData";
import useMutateDepositReject from "../../../../../hooks/reactQuery/useMutateDepositReject";
import useMutateDepositAccept from "../../../../../hooks/reactQuery/useMutateDepositAccept";

import { convertDate } from "./../../../../../utils/convertDate";
import { getAttachments } from "../../../../../utils/getAttachments";

import folders from "../../../../../constants/static_folders";
import { useTranslation } from "react-i18next";
const ExpandedRowFullControl = ({ row, safeId }) => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();
  const currentSafe = userData.safes.find(safe => safe.id == safeId); 

  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);

  const { isLoading: isAcceptLoading, mutate: mutateAccept } =
    useMutateDepositAccept(setShowAcceptModal, safeId);

  const { isLoading: isRejectLoading, mutate: mutateReject } =
    useMutateDepositReject(setShowRejectModal, safeId);

  return (
    <div className={`grid grid-cols-5 gap-4 ${i18n.resolvedLanguage === "en" ? "text-left ml-10" : "text-right mr-10"}`}>
      {row.approved !== null && (
        <div className="col-span-1">
          <p className="text-xs text-body font-bold">{t("response_date")}</p>
          <p className="text-sm text-hints font-medium mt-1">
            {row.reply_date?convertDate(row.reply_date): t("no_permission_needed")}
          </p>
        </div>
      )}

      {row.approved === null && currentSafe.permissions.includes("CashFullControl") &&
        userData.permission_tags.includes("CashFullControl") && (
          <div className="col-span-1 col-start-6 flex justify-end gap-x-4">
            <Fragment>
              <button
                className="money-req-reject-btn transition-all duration-300 hover:border-secondary border border-body h-3 flex items-center justify-center p-2 rounded-md"
                onClick={() => setShowRejectModal(true)}
              >
                <span className="absolute left-4">{t("reject")}</span>
                <div className="absolute -left-1 money-req-reject">
                  <Reject />
                </div>
              </button>
              <Modal
                showModal={showRejectModal}
                onClose={() => setShowRejectModal(false)}
              >
                <Alert
                  hasFooter={true}
                  alertType="cancel"
                  title={t("reject_deposit_request")}
                  body={() => (
                    <p>
                    {t("alert_text_reject_deposit_request", {creator_name: row.creator_name})}
                    </p>
                  )}
                  confirmText={t("reject_deposit_request")}
                  cancelText={t("cancel")}
                  handleConfirm={() => mutateReject(row)}
                  handleCancel={() => setShowRejectModal(false)}
                  isSubmitLoading={isRejectLoading}
                />
              </Modal>
            </Fragment>
            <Fragment>
              <button
                className="money-req-accept-btn transition-all duration-300 hover:border-secondary border border-body h-3 flex items-center justify-center p-2 rounded-md"
                onClick={() => setShowAcceptModal(true)}
              >
                <span className="absolute left-4">{t("accept")}</span>
                <div className="absolute money-req-accept">
                  <Accept />
                </div>
              </button>

              <Modal
                showModal={showAcceptModal}
                onClose={() => setShowAcceptModal(false)}
              >
                <Alert
                  hasFooter={true}
                  alertType="confirm"
                  title={t("accept_deposit_request")}
                  body={() => (
                    <p>
                      {t("alert_text_accept_deposit_request", {creator_name: row.creator_name})}
                    </p>
                  )}
                  confirmText={t("accept_deposit_request")}
                  cancelText={t("cancel")}
                  handleConfirm={() => mutateAccept(row)}
                  handleCancel={() => setShowAcceptModal(false)}
                  isSubmitLoading={isAcceptLoading}
                />
              </Modal>
            </Fragment>
          </div>
        )}

      <div className="col-span-5 row-start-2">
        <p className="text-xs text-body font-bold">{t("notes")}</p>
        <p className="text-sm text-hints font-medium mt-1">
          {row.notes ? row.notes : t("no_notes")}
        </p>
      </div>

      {row.attachments && (
        <div className="col-span-5 row-start-3">
          {getAttachments({
            title: t("attachments"),
            notFoundMessage: t("no_attachments"),
            attachProperity: row.attachments,
            attachUrl: folders.deposits,
            rowId: row.id,
          })}
        </div>
      )}
    </div>
  );
};

export default ExpandedRowFullControl;
