/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import BlueCheckSvg from "../resources/blue check svg/BlueCheckSvg";
import SelectorSvg from "../resources/selector svg/SelectorSvg";
import WorkspaceSvg from "../resources/workspace svg/WorkspaceSvg";
import WorkspaceCloseSvg from "../resources/workspace close svg/WorkspaceCloseSvg";
import HomeHashtagSvg from "../resources/home hashtag svg/HomeHashtagSvg";
import CogSvg from "../resources/cog svg/CogSvg";
import { QueryClientProvider, useQuery, useQueryClient } from "react-query";
import apiClient from "../../services/apiClient";
import Spinner from "../common/Spinner";
import WorkSpace from "../workspace/WorkSpace";
import Modal from "../common/Modal";
import { withRouter } from "react-router-dom";
import useFetchUserData from "../../hooks/reactQuery/useFetchUserData";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function WorkspaceSelect({ history, isExpanded }) {
  const { data: userData, isLoading: isUserDataLoading } = useFetchUserData();
  const activeCompany = JSON.parse(localStorage.getItem("activeCompanyId"))
  const [showWorkSpaceModal, setShowWorkSpaceModal] = useState(false);
  const [lastScrollFireTime, setLastScrollFireTime] = useState(0);

  const closeListBox = () => {
    document.getElementById("listbox-button").click();
  };

  const {t, i18n} = useTranslation();

  const getCompanyPicture = (id, extension) => {
    return extension
      ? (process.env.REACT_APP_UPLOAD_FILES_BASE_URL
          ? process.env.REACT_APP_UPLOAD_FILES_BASE_URL
          : "") +
          (process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
            ? process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
            : "images/tarbeetapaymentrequests/") +
          `companies/${id}/profilepicture` +
          `.${extension}`
      : "../img/default-company.svg";
  };

  const switchCompany = (company) => {
    localStorage.setItem("activeCompanyId", JSON.stringify(company.id));
    window.location.reload();
  };
  const scrollHandler = function (event) {
    let company;
    let now = new Date().getTime();

    if (lastScrollFireTime === 0 || now - lastScrollFireTime > 10000) {
      document.body.style.overflow = "hidden";
      const selectedIndex = userData.companies.findIndex(
        (element) => element.id === userData.activeCompany.id
      );
      if (event.deltaY > 0)
        // Shadd le ta7t
        company =
          selectedIndex === userData.companies.length - 1
            ? userData.companies[0]
            : userData.companies.find(
                (element, index) => index === selectedIndex + 1
              );
      // Shadd le fo2
      else
        company =
          selectedIndex === 0
            ? userData.companies[userData.companies.length - 1]
            : userData.companies.find(
                (element, index) => index === selectedIndex - 1
              );
      setLastScrollFireTime(now);
      switchCompany(company);
      // window.location.reload();
    }
  };

  useEffect(() => {
    const companiesScroll = document.getElementById("listbox-button");
    companiesScroll.addEventListener("mousewheel", (event) => {
      scrollHandler(event);
    });
    return () => {
      companiesScroll.removeEventListener("mousewheel", () => {});
    };
  }, []);

  return (
    <>
      <Listbox value={userData.activeCompany} onChange={switchCompany}>
        {({ open }) => (
          <>
            <div className="relative text-center">
              <Listbox.Button
                className={`transition-all duration-300 relative bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-3 py-2 text-left focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm cursor-pointer ${
                  isExpanded ? "w-48" : "w-8 flex justify-center items-center"
                }`}
              >
                {isExpanded && (
                  <div className="ml-3 absolute inset-y-0 left-0 flex items-center pr-2 pointer-events-none">
                    <SelectorSvg
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                )}
                <div id="listbox-button" className="flex items-center">
                  <div className={`flex-shrink-0 h-6 w-6 rounded-full ${i18n.resolvedLanguage === "en" ? "mr-2" : ""}`}>
                    <img
                      src={getCompanyPicture(
                        userData.activeCompany.id,
                        userData.activeCompany.profile_picture_ext
                      )}
                      // alt="workspace"
                    />
                  </div>

                  {isExpanded && (
                    <span className="mr-2 block truncate">
                      {i18n.resolvedLanguage === "en" && userData.activeCompany.en_name ? userData.activeCompany.en_name : userData.activeCompany.name}
                    </span>
                  )}
                </div>
              </Listbox.Button>

              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  static
                  className={`absolute z-10 mt-1 mr-2 overflow-y-auto w-full bg-white shadow-lg max-h-56 rounded-md text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm workspace-options ${i18n.resolvedLanguage === "en" ? "pl-2" : ""}`}
                  style={{
                    width: "360px",
                    maxHeight: "480px",
                  }}
                >
                  <Listbox.Option
                    key="-1"
                    className={() =>
                      classNames(
                        "text-gray-900",
                        "cursor-default select-none relative pt-3"
                      )
                    }
                    style={{
                      position: "sticky",
                      top: "0",
                      zIndex: "500",
                      backgroundColor: "white",
                    }}
                    disabled={true}
                  >
                    <>
                      <div className="flex items-center pr-3">
                        <WorkspaceSvg className="h-6 w-6" />

                        <span
                          className={classNames(
                            "font-normal",
                            `mr-2 ${i18n.resolvedLanguage === "en" ? "ml-2" : ""} block truncate`
                          )}
                          style={{
                            color: "#6B7280",
                            fontWeight: "bold",
                          }}
                        >
                          {t("workspaces")}
                        </span>
                      </div>

                      <span
                        className={classNames(
                          "text-white ",
                          `absolute inset-y-0 ${i18n.resolvedLanguage === "en" ? "right-4" : "left-4"} flex items-center pr-7 cursor-pointer`
                        )}
                        onClick={() => closeListBox()}
                      >
                        <WorkspaceCloseSvg
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </span>
                    </>
                    <hr className="mt-3" />
                  </Listbox.Option>
                  {isUserDataLoading ? (
                    <Spinner />
                  ) : (
                    userData.companies.map((company) => (
                      <Listbox.Option
                        key={company.id}
                        className={({ active }) =>
                          classNames(
                            active
                              ? "text-white bg-indigo-600"
                              : "text-gray-900",
                            "select-none relative py-2 pl-3 pr-3 cursor-pointer"
                          )
                        }
                        value={company}
                      >
                        {({ active }) => (
                          <>
                            <div className="flex items-center">
                              <img
                                src={getCompanyPicture(
                                  company.id,
                                  company.profile_picture_ext
                                )}
                                alt=""
                                className={`flex-shrink-0 h-9 w-9 rounded-full ${i18n.resolvedLanguage === "en" ? "mr-2" : ""}`}
                              />
                              <span
                                className={classNames(
                                  userData.activeCompanyId == company.id
                                    ? "font-semibold"
                                    : "font-normal",
                                  "mr-2 block truncate"
                                )}
                              >
                                {i18n.resolvedLanguage === "en" && company.en_name ? company.en_name : company.name}
                              </span>
                            </div>

                            {activeCompany == company.id ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-indigo-600",
                                  "absolute inset-y-0 left-4 flex items-center pr-4"
                                )}
                              >
                                <BlueCheckSvg
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                            {userData.activeCompanyId !== company.id &&
                            company.notification_count > 0 ? (
                              <span className="bg-gray-100 py-1 px-4 rounded-full absolute top-3 left-2 flex items-center">
                                {company.notification_count}
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))
                  )}
                  { userData.permission_tags.includes("CreateWorkspace") ?
                  <Listbox.Option
                    key="-1"
                    className={() =>
                      classNames(
                        "text-gray-900",
                        "select-none relative py-3 pr-3 cursor-pointer"
                      )
                    }
                    style={{
                      position: "sticky",
                      bottom: "0",
                      zIndex: "500",
                      backgroundColor: "white",
                    }}
                    disabled={true}
                  >
                    <div className="flex items-center z-50 justify-center pl-3">
                      <hr />

                      <button
                        type="button"
                        className="inline-flex items-center px-5 py-2 border border-transparent text-base font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        style={{
                          backgroundColor: "#3B82F6",
                          width: "90%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                        onClick={() => setShowWorkSpaceModal(true)}
                      >
                        <HomeHashtagSvg
                          className="mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        <span className={`${i18n.resolvedLanguage === "ar" ? "mr-2" : "ml-2"}`}>{t("new_workspace")}</span>
                      </button>
                    </div>
                    <div
                      className="flex items-center py-4"
                      onClick={() => history.replace("/settings")}
                    >
                      <CogSvg className="h-6 w-6" />

                      <span
                        className={classNames(
                          "font-normal",
                          `${i18n.resolvedLanguage === "ar" ? "mr-2" : "ml-2"} block truncate`
                        )}
                      >
                      {t("workspace_settings")}
                      </span>
                    </div>
                  </Listbox.Option> : null}

                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      <Modal
        showModal={showWorkSpaceModal}
        onClose={() => setShowWorkSpaceModal(false)}
      >
        <WorkSpace onClose={() => setShowWorkSpaceModal(false)} />
      </Modal>
    </>
  );
}

export default withRouter(WorkspaceSelect);
