import WordFileIcon from "../components/resources/icons/WordFileIcon";
import PDFFileIcon from "../components/resources/icons/PDFFileIcon";
import ImageFileIcon from "../components/resources/icons/ImageFileIcon";
import ExcelFileIcon from "../components/resources/icons/ExcelFileIcon";

export const bytesToSize = (bytes) => {
  var sizes = ["B", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + "" + sizes[i];
};

export const fileTypeIcon = (fileType) => {
  if (fileType.includes("image/")) {
    return <ImageFileIcon className="h-full"></ImageFileIcon>;
  } else if (fileType.includes("application/pdf")) {
    return <PDFFileIcon className="h-full"></PDFFileIcon>;
  } else if (fileType.includes("application/msword")) {
    return <WordFileIcon className="h-full"></WordFileIcon>;
  } else if (fileType.includes("application/vnd.ms-excel")) {
    return <ExcelFileIcon className="h-full"></ExcelFileIcon>;
  }
};

export const fileExtByFileName = (fileName) => {
  return fileName.split(".").pop();
};

export const fileTypeIconFromFileName = (fileName) => {
  if (fileName.match(/[\/.](gif|jpg|jpeg|tiff|png)$/i)) {
    return <ImageFileIcon className="h-full"></ImageFileIcon>;
  } else if (fileName.match(/[\/.](pdf)$/i)) {
    return <PDFFileIcon className="h-full"></PDFFileIcon>;
  } else if (fileName.match(/[\/.](doc|docx)$/i)) {
    return <WordFileIcon className="h-full"></WordFileIcon>;
  } else if (fileName.match(/[\/.](xls|xlsx|csv)$/i)) {
    return <ExcelFileIcon className="h-full"></ExcelFileIcon>;
  }
};
