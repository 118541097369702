import { useQuery, useQueryClient } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchUserData() {
  const activeCompanyId = JSON.parse(localStorage.getItem("activeCompanyId"));
  const queryClient = useQueryClient();
  return useQuery(
    "userData",
    () =>
      apiClient
        ?.get(`user/data`)
        .then(({ data }) => {
          if(!data.companies || data.companies.length < 1) {
            localStorage.removeItem("activeCompanyId");
            document.cookie = "Bearer= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
            window.location.href = "/home";
            return;
          }
          let currentCompany = activeCompanyId;
          const findCurrentCompany = data.companies.find(company => company.id == activeCompanyId);
          if(!findCurrentCompany) {
            currentCompany = data.companies[0].id;
            localStorage.setItem("activeCompanyId", currentCompany);
            queryClient.invalidateQueries();
            window.location.href = "/home";
          }
          const activeCompany = data.companies?.find(company => company.id == currentCompany);
          return {
          ...data,
          companies: [].concat.apply([], data.companies),
          activeCompanyId: currentCompany,
          activeCompany, 
          isAdmin: activeCompany.owner_id == data.id
        }
      }).catch((e) => null),
    {
      staleTime: Infinity,
    }
  );
  
}
