const SafesIcon = ({ fillColor }) => {
  return(
    <div>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.541 12.1333V10.7416C11.7292 10.5901 11.8895 10.4121 12.023 10.2167H17.4993C17.6815 10.2167 17.8327 10.3678 17.8327 10.55V13.4917C17.8327 15.89 15.8904 17.8333 13.4827 17.8333H6.50768C4.10882 17.8333 2.16602 15.8905 2.16602 13.4917V10.55C2.16602 10.3678 2.31716 10.2167 2.49935 10.2167H7.97572C8.10916 10.4121 8.26946 10.5901 8.45768 10.7416V12.1333C8.45768 12.9845 9.14821 13.675 9.99935 13.675C10.8505 13.675 11.541 12.9845 11.541 12.1333Z" fill={fillColor ? fillColor : "#3B82F6"} />
        <path d="M6.50768 2.16663H13.491C15.8899 2.16663 17.8327 4.10943 17.8327 6.50829V7.63329C17.8327 7.81548 17.6815 7.96663 17.4993 7.96663H12.3412C12.0744 7.2145 11.437 6.62067 10.6604 6.41653C9.3271 6.06264 8.08979 6.81596 7.67493 7.96663H2.49935C2.31716 7.96663 2.16602 7.81548 2.16602 7.63329V6.50829C2.16602 4.10943 4.10882 2.16663 6.50768 2.16663Z" fill={fillColor ? fillColor : "#3B82F6"} />
      </svg>
    </div>
  );
};

export default SafesIcon;
