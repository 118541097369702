import axios from "axios";
import { toast } from "react-toastify";
const baseUrl = process.env.REACT_APP_API_URL;


// Request interceptor
axios.interceptors.request.use(
  function (config) {
    const accessToken = document.cookie.includes("Bearer")
      ? document.cookie
          .split("; ")
          .find((row) => row.startsWith("Bearer"))
          .split("=")[1]
      : null;

    const CSRFToken = document.cookie.includes("CSRFToken")
      ? document.cookie
          .split("; ")
          .find((row) => row.startsWith("CSRFToken"))
          .split("=")[1]
      : localStorage.getItem("CSRFToken");
    

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + accessToken,
      "X-CSRF-Token": CSRFToken,
    };

    config.headers.common = headers;
    config.baseURL = baseUrl;
    config.withCredentials = true;
    config.params = {
      ...config.params,
      company_id: JSON.parse(localStorage.getItem("activeCompanyId")),
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    toast.error(error.response.data.message);
    if(error.response) {
      if(error.response.status === 401 && error.response.data === "The token is not correct") {
        localStorage.removeItem("activeCompanyId");
        document.cookie = "Bearer= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
        if(
          window.location.pathname !== "/home" 
          && window.location.pathname !== "/signup" 
          && window.location.pathname !== "/register"
          && window.location.pathname !== "/request-access"
        ) window.location.href = "/home";
      }
    }
    return Promise.reject(error);
  }
);

export default axios;
