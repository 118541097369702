import { useQuery } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useFetchHelicopter(
  activeTab,
  currentPage,
  pageSize,
  order,
  columnKey,
  searchType,
  searchTerms,
  fromDate,
  toDate
) {
  return useQuery(
    [
      "getHelicopter",
      activeTab,
      currentPage,
      pageSize,
      order,
      columnKey,
      searchType,
      searchTerms,
      fromDate,
      toDate
    ],
    () => {
      const params = {};
      params.pageno = currentPage;
      params.pagesize = pageSize;
      params.type = activeTab;

      if(fromDate && toDate) {
        params.fromdate = fromDate;
        params.todate = toDate;
      }

      if (order && columnKey) {
        params.orderbycolumn = columnKey;
        params.isascending =
          order === "ascend" ? 1 : order === "descend" ? 0 : null;
      }
      if (searchType !== "" && searchTerms.length > 0) {
        params.search_type = searchType;
        params.search_terms = JSON.stringify(searchTerms);
      }

      return apiClient
        .get("admin/all", {
          params,
        })
        .then((res) => {
          res.data.data.forEach((element) => {
            element.key = element.id + "_" + element.request_type;
          });
          return res?.data;
        })
    }
  );
}
