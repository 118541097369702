import ResponseSvg from "../../resources/response svg/ResponseSvg";

import { getAttachments } from "../../../utils/getAttachments";
import { convertDate } from "../../../utils/convertDate";

import folders from "../../../constants/static_folders";

const ExpandedRow = ({ row }) => (
  <div className="grid grid-cols-12 gap-4 mr-20">
    <div className="col-span-2">
      <p>التصنيف الرئيسي</p>
      <p>{row.category_name}</p>
    </div>
    <div className="col-span-2">
      <p>التصنيف الفرعي</p>
      <p>{row.subcategory_name ? row.subcategory_name : "---"}</p>
    </div>
    <div className="col-span-3">
      <p>تاريخ الإرسال</p>
      <p>{convertDate(row.created_at)}</p>
    </div>
    <div className="col-span-3">
      <p>الموعد الأقصى للتسليم</p>
      <p>{convertDate(row.request_date)}</p>
    </div>
    <div className="col-span-2">
      <p>حالة الطلب</p>
      <p>{row.urgent ? "عاجل" : "غير عاجل"}</p>
    </div>
    <div className="col-span-12">
      <p>ملاحظات {row.sender_name}</p>
      <p>{row.notes ? row.notes : "لا توجد ملاحظات"}</p>
    </div>
    <div className="col-span-12">
      <p className="flex items-center">
        <div className="ml-2">
          <ResponseSvg />
        </div>
        ردك
      </p>
      <p>{row.response_notes ? row.response_notes : "لا يوجد رد"}</p>
    </div>
    <div className="col-span-12">
      {getAttachments({
        title: "مرفقات",
        notFoundMessage: "لا توجد مرفقات",
        attachProperity:
          row.approved === 1
            ? row.original_attachments
            : row.replied_attachments,
        attachUrl: folders.attachments,
        rowId: row.id,
      })}
    </div>
  </div>
);

export default ExpandedRow;
