import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect, useMemo } from "react";

function Icon(props) {
  const { className, fillColor, ...restOfProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9688 22.75C6.04875 22.75 1.21875 17.93 1.21875 12C1.21875 6.07 6.04875 1.25 11.9688 1.25C17.8888 1.25 22.7188 6.07 22.7188 12C22.7188 17.93 17.8988 22.75 11.9688 22.75ZM11.9688 2.75C6.86875 2.75 2.71875 6.9 2.71875 12C2.71875 17.1 6.86875 21.25 11.9688 21.25C17.0688 21.25 21.2188 17.1 21.2188 12C21.2188 6.9 17.0688 2.75 11.9688 2.75Z"
        fill={fillColor ? fillColor : "#DC2626"}
      />
      <path
        d="M10.01 15.9508H8.71C7.79 15.9508 7.25 15.4208 7.25 14.5308V9.47078C7.25 8.58078 7.8 8.05078 8.71 8.05078H10C10.92 8.05078 11.46 8.58078 11.46 9.47078V14.5308C11.47 15.4208 10.92 15.9508 10.01 15.9508ZM8.75 14.4508H9.97V9.55078H8.76L8.75 14.4508Z"
        fill={fillColor ? fillColor : "#94A3B8"}
      />
      <path
        d="M15.2891 15.9508H13.9991C13.0791 15.9508 12.5391 15.4208 12.5391 14.5308V9.47078C12.5391 8.58078 13.0891 8.05078 13.9991 8.05078H15.2891C16.2091 8.05078 16.7491 8.58078 16.7491 9.47078V14.5308C16.7491 15.4208 16.1991 15.9508 15.2891 15.9508ZM14.0291 14.4508H15.2491V9.55078H14.0391L14.0291 14.4508Z"
        fill={fillColor ? fillColor : "#DC2626"}
      />
    </svg>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
};

export default Icon;
