const InputNumber = ({}) => {
  return (
    <div>
      <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.4077 15.1841H5.08268C4.64934 15.1841 4.241 15.1424 3.87433 15.0591C3.68267 15.0341 3.44934 14.9674 3.20768 14.8758C2.09934 14.4591 0.791016 13.4341 0.791016 10.8841V6.59242C0.791016 3.86742 2.35768 2.30078 5.08268 2.30078H12.4077C14.7077 2.30078 16.191 3.40076 16.591 5.40076C16.666 5.76742 16.6993 6.15908 16.6993 6.59242V10.8841C16.6993 13.6258 15.141 15.1841 12.4077 15.1841ZM5.09099 3.56746C3.04099 3.56746 2.04933 4.5591 2.04933 6.6091V10.9008C2.04933 12.3925 2.57434 13.3091 3.64934 13.7175C3.81601 13.7758 3.97435 13.8174 4.12435 13.8424C4.44102 13.9091 4.74933 13.9424 5.09099 13.9424H12.416C14.466 13.9424 15.4577 12.9508 15.4577 10.9008V6.6091C15.4577 6.2591 15.4327 5.95076 15.3743 5.66743C15.091 4.25076 14.1243 3.56746 12.416 3.56746H5.09099Z"
          fill="#94A3B8"
        />
        <path
          d="M14.9092 17.6849H7.58422C6.87589 17.6849 6.24255 17.5849 5.70088 17.3766C4.47588 16.9182 3.67587 15.9516 3.40087 14.5682C3.35921 14.3599 3.42589 14.1432 3.57589 14.0016C3.72589 13.8516 3.94256 13.7932 4.15089 13.8432C4.42589 13.9016 4.73422 13.9349 5.08422 13.9349H12.4092C14.4592 13.9349 15.4509 12.9433 15.4509 10.8933V6.60158C15.4509 6.25158 15.4259 5.94324 15.3676 5.65991C15.3259 5.45157 15.3926 5.24325 15.5342 5.09325C15.6842 4.94325 15.8926 4.87657 16.1009 4.92657C18.1009 5.3349 19.2009 6.81825 19.2009 9.10158V13.3933C19.2009 16.1266 17.6426 17.6849 14.9092 17.6849ZM4.93423 15.1849C5.2009 15.6682 5.60089 16.0099 6.15089 16.2099C6.55089 16.3599 7.03421 16.4349 7.59254 16.4349H14.9175C16.9675 16.4349 17.9592 15.4433 17.9592 13.3933V9.10158C17.9592 7.78491 17.5509 6.90992 16.7092 6.45158C16.7092 6.50158 16.7092 6.55158 16.7092 6.60158V10.8933C16.7092 13.6183 15.1425 15.1849 12.4175 15.1849H5.09254C5.03421 15.1849 4.98423 15.1849 4.93423 15.1849Z"
          fill="#94A3B8"
        />
        <path
          d="M8.75079 11.5758C7.19246 11.5758 5.92578 10.3091 5.92578 8.75079C5.92578 7.19246 7.19246 5.92578 8.75079 5.92578C10.3091 5.92578 11.5758 7.19246 11.5758 8.75079C11.5758 10.3091 10.3091 11.5758 8.75079 11.5758ZM8.75079 7.17578C7.88413 7.17578 7.17578 7.88413 7.17578 8.75079C7.17578 9.61746 7.88413 10.3258 8.75079 10.3258C9.61746 10.3258 10.3258 9.61746 10.3258 8.75079C10.3258 7.88413 9.61746 7.17578 8.75079 7.17578Z"
          fill="#94A3B8"
        />
        <path
          d="M3.98438 11.2097C3.64271 11.2097 3.35938 10.9263 3.35938 10.5847V6.91797C3.35938 6.5763 3.64271 6.29297 3.98438 6.29297C4.32604 6.29297 4.60938 6.5763 4.60938 6.91797V10.5847C4.60938 10.9263 4.33437 11.2097 3.98438 11.2097Z"
          fill="#94A3B8"
        />
        <path
          d="M13.5078 11.2097C13.1661 11.2097 12.8828 10.9263 12.8828 10.5847V6.91797C12.8828 6.5763 13.1661 6.29297 13.5078 6.29297C13.8495 6.29297 14.1328 6.5763 14.1328 6.91797V10.5847C14.1328 10.9263 13.8578 11.2097 13.5078 11.2097Z"
          fill="#94A3B8"
        />
      </svg>
    </div>
  );
};

export default InputNumber;
