import DepositFullControl from "./DepositFullControl/index";
import DepositWithoutPerm from "./DepositWithoutPerm/index";


const Deposit = (props) => {
  return (
    <div>
      {props.permission_tags.includes("CashFullControl") ? (
        <DepositFullControl safeId={props.safeId}/>
      ) : (
        <DepositWithoutPerm safeId={props.safeId} />
      )}
    </div>
  );
};

export default Deposit;
