import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect, useMemo } from "react";

function Icon(props) {
  const { className, isButton, fillColor, ...restOfProps } = props;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <svg
      onMouseEnter={() => {
        if (isButton) {
          setIsHovered(true);
        }
      }}
      onMouseLeave={() => {
        if (isButton) {
          setIsHovered(false);
        }
      }}
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {isButton && isHovered ? (
        <rect width={32} height={32} rx={16} fill="#E7F0FF" />
      ) : null}
      <path
        d="M14.4901 6.22859L9.50015 8.09859C8.35015 8.52859 7.41016 9.88858 7.41016 11.1186V18.5486C7.41016 19.7286 8.19017 21.2786 9.14017 21.9886L13.4402 25.1986C14.8502 26.2586 17.1701 26.2586 18.5801 25.1986L22.8802 21.9886C23.8302 21.2786 24.6101 19.7286 24.6101 18.5486V11.1186C24.6101 9.88858 23.6701 8.52859 22.5201 8.09859L17.5302 6.22859C16.6802 5.91859 15.3201 5.91859 14.4901 6.22859Z"
        stroke={
          fillColor == null
            ? isButton && isHovered
              ? "#3B82F6"
              : "#1E293B"
            : fillColor
        }
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0392 12.573L13.4991 16.1131C13.3591 16.2531 13.2291 16.513 13.1991 16.703L13.0091 18.053C12.9391 18.543 13.2791 18.883 13.7691 18.813L15.1191 18.623C15.3091 18.593 15.5791 18.463 15.7091 18.323L19.2491 14.783C19.8591 14.173 20.1491 13.463 19.2491 12.563C18.3591 11.673 17.6492 11.963 17.0392 12.573Z"
        stroke={
          fillColor == null
            ? isButton && isHovered
              ? "#3B82F6"
              : "#1E293B"
            : fillColor
        }
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5312 13.082C16.8313 14.162 17.6712 15.002 18.7512 15.302"
        stroke={
          fillColor == null
            ? isButton && isHovered
              ? "#3B82F6"
              : "#1E293B"
            : fillColor
        }
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
};

export default Icon;
