import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import NotificationsCard from "./NotificationsCard";
import BellSvg from "../resources/bell svg/BellSvg";
import BlueBellSvg from "../resources/blue bell svg/BlueBellSvg";
import { useQuery, useMutation, useQueryClient } from "react-query";
import useSubscribtion from "../../hooks/useSubscribtion";
import apiClient from "../../services/apiClient";
import "./Panel.scss";
import useFetchUserData from "../../hooks/reactQuery/useFetchUserData";
import useFetchNotifications from "../../hooks/reactQuery/useFetchNotifications";
import { useTranslation } from "react-i18next";

export default function NotificationsPanel() {
  const { data: userData } = useFetchUserData();
  const { hovered, setHover } = useState(false);

  const {t, i18n} = useTranslation();

  const [unreadCount, setUnreadCount] = useState(0);

  const queryClient = useQueryClient();
  const reFetchNotifications = (res) => {
    queryClient.invalidateQueries("notifications");
    queryClient.invalidateQueries("userBalance");

    if(res && (res.action == "safe" || res.action == "role" )) {
      queryClient.invalidateQueries("userData");
      queryClient.invalidateQueries("userBalance");
    }

    if(res && res.action == "charts_cashin") {
      queryClient.invalidateQueries("companyBalance");
      queryClient.invalidateQueries("monthlyCashin");
      queryClient.invalidateQueries("monthlyExpenses");
      queryClient.invalidateQueries("currentMoneyRequests");
      queryClient.invalidateQueries("chartDepositsData");
      queryClient.invalidateQueries("chartSafeData");
    }
    if(res && res.action == "charts_withdrawn") {
      queryClient.invalidateQueries("companyBalance");
      queryClient.invalidateQueries("monthlyCashin");
      queryClient.invalidateQueries("monthlyExpenses");
      queryClient.invalidateQueries("currentMoneyRequests");
      queryClient.invalidateQueries("chartWithdrawalsData");
      queryClient.invalidateQueries("chartSafeData");
    }
    if(res && res.action == "charts_expenses") {
      queryClient.invalidateQueries("companyBalance");
      queryClient.invalidateQueries("monthlyCashin");
      queryClient.invalidateQueries("monthlyExpenses");
      queryClient.invalidateQueries("currentMoneyRequests");
      queryClient.invalidateQueries("chartDocumentsData");
      queryClient.invalidateQueries("chartSafeData");
    }
  };
  useSubscribtion(userData.id, reFetchNotifications);

  // Get User's Last Checked
  const lastChecked = useQuery("lastChecked", () => {
    return apiClient.get(`user/lastchecked/${userData.id}`);
  });

  // Update User's Last Checked
  const lastCheckedMutation = useMutation(
    () => apiClient.post(`user/${userData.id}/updatelastchecked`),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("lastChecked");
      },
    }
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [lastPage, setLastPage] = useState(100);

  const [notificationsState, setNotificationState] = useState([]);

  const {
    isLoading: isNotificationsLoading,
    data: notifications,
    error: notificationsError,
  } = useFetchNotifications(currentPage, pageSize);

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if(checked) {
      setUnreadCount(0);
      lastCheckedMutation.mutate();
    }
  }, [checked]);

  useEffect(() => {
    if(notifications) { 
      if(currentPage === 1) setNotificationState(notifications[0]);
      else if (currentPage > 1) setNotificationState(old => [...notifications[0], ...old]);
      const count = notifications[1][0].total_unread ? notifications[1][0].total_unread : 0;
      setUnreadCount(count);
      setLastPage(Math.ceil(notifications[1][0].total_count / pageSize));
    }
  }, [notifications]);

  const [scrolledToBottom, setScrolledToBottom] = useState(false);

  const scrollHandler = (e) => {
    if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight - 60) { 
      setScrolledToBottom(true);
    } 
    else {
      setScrolledToBottom(false);
    }
  }

  useEffect(() => {
    if(scrolledToBottom && !isNotificationsLoading ) {
      if(currentPage < lastPage) setCurrentPage(page => page + 1);
    }
  }, [scrolledToBottom]);

  return (
    <Menu as="div" className="relative inline-block text-right" onClick={lastCheckedMutation.mutate}>
      {({ open }) => (
        <>
          <div
            className="flex items-center"
          >
            <Menu.Button className={`bill-circle ${open ? "bill-circle-active" : ""}`}>
              {unreadCount > 0 ? <span className="absolute -right-3 -top-1 notificationsCount">
                {unreadCount}
              </span> : null}
              <BellSvg active={open} />
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-left absolute left-2.5 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              style={{ zIndex: 100 }}
            >
              <div className="flow-root">
                <div className={`${i18n.resolvedLanguage === "en" ? "notification-header-en" : "mr-4 mt-5 mb-5"}`}>
                  <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
                    <BlueBellSvg />
                    <span className={`${i18n.resolvedLanguage === "en" ? "ml-4" : "mr-4"}`}>{t("notifications")}</span>
                  </h3>
                  <hr className="ml-4" />
                </div>
                <div className="notifications-scrollbox" onScroll={scrollHandler}>
                  <ul role="list" className="-mb-2 notifications-container">
                    {notificationsState.map((notification, index) => (
                      <NotificationsCard
                        notification={notification}
                        key={index}
                        checked={checked}
                        setChecked={index === 0 ? setChecked : () => {}}
                      />
                    ))}
                    {isNotificationsLoading ? <span>...loading</span> : null}
                  </ul>
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}
