import Check from "../components/resources/icons/Check";

export const usersFormatOptionLabel = (user, context) => (
  <div className="flex justify-between items-center">
    <div className="flex justify-center items-center gap-x-3 selection-container">
      <div className="flex-shrink-0 selection-hide">
        {user.profile_picture_ext ? (
          <img className="w-6 h-6 rounded-full" src={user.url} alt="user" />
        ) : (
          <div className="w-6 h-6 rounded-full flex justify-center items-center bg-onhold text-sm font-medium text-body">
            {user.name[0]}
          </div>
        )}
      </div>

      <p
        className={`text-sm text-body selection-weight ${
          context.selectValue && context.selectValue[0]?.id === user?.id
            ? "font-bold"
            : "font-medium"
        }`}
      >
        {user.name}
      </p>
      <p className="text-xs leading-5 text-hints selection-hide">
        {user.user_name}
      </p>
    </div>
    {context.selectValue && context.selectValue[0]?.id === user?.id && (
      <div>
        <Check />
      </div>
    )}
  </div>
);

export const categoriesLatestOptionsMarkup = (
  latestCategories,
  getValues,
  setValue,
  trigger,
  name,
  maxWidth
) => (
  <ul
    className={`flex flex-wrap items-center gap-x-4 h-5 overflow-hidden transition-all duration-700 ${
      !getValues(name) ? "w-full" : "w-0"
    }`}
    //
    style={{ maxWidth: maxWidth }}
    //
  >
    {latestCategories?.map((option) => {
      return (
        <li
          className="cursor-pointer bg-sidebar-background rounded text-xs leading-6 font-normal text-hints text-center overflow-hidden"
          onClick={() => {
            setValue(name, option);
            trigger(name);
          }}
        >
          {option.label}
        </li>
      );
    })}
  </ul>
);

export const usersLatestOptionsMarkup = (
  latestUsers,
  getValues,
  setValue,
  trigger,
  name,
  maxWidth
) => (
  <ul
    className={`flex flex-wrap items-center gap-x-4 h-5 overflow-hidden transition-all duration-700 ${
      !getValues(name) ? "w-full" : "w-0"
    }`}
    //
    style={{ maxWidth: maxWidth }}
    //
  >
    {latestUsers?.map((option) => {
      return (
        <li
          className="cursor-pointer bg-sidebar-background rounded text-xs leading-6 font-normal text-hints text-center overflow-hidden"
          onClick={() => {
            setValue(name, option);
            trigger(name);
          }}
        >
          {option.label}
        </li>
      );
    })}
  </ul>
);

export const usersSecondFormatOptionLabel = (user) => {
  return (
    <div className="flex items-center gap-x-1 px-2.5 py-1">
      <div>
        {user.profile_picture_ext ? (
          <img className="h-6 w-6 rounded-md" src={user.url} alt="user" />
        ) : (
          <div className="h-6 w-6 rounded-md flex justify-center items-center bg-onhold">
            {user.name[0]}
          </div>
        )}
      </div>
      <div>
        <p className="text-sm leading-6 font-medium text-body hover:underline">
          {user.name}
        </p>
        <div className="text-xs leading-5 font-normal text-hints">
          <p>{user.email}</p>
        </div>
      </div>
    </div>
  );
};
