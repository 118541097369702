import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Component, useEffect, useState } from "react";
import { ar, enUS } from "date-fns/locale";

import {
  addDays,
  addYears,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
} from "date-fns";

import { useTranslation } from "react-i18next";

export default function DateRangePickerComponent({selectionRange, setSelectionRange}) {
  const {t, i18n} = useTranslation();

  const defineds = {
    startOfWeek: startOfWeek(new Date(), {weekStartsOn: 6}),
    endOfWeek: endOfWeek(new Date(), {weekStartsOn: 6}),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7), {weekStartsOn: 6}),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7), {weekStartsOn: 6}),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfLast6Month: startOfMonth(addMonths(new Date(), -6)),
    endOfLast6Month: endOfMonth(addMonths(new Date(), 0))
  };

  const staticRangeHandler = {
    range: {},
    isSelected(range) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      );
    }
  };

  function createStaticRanges(ranges) {
    return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
  }
  function updateTimeZone(item){
    item.selection.startDate = new Date(item.selection.startDate)
    item.selection.endDate = new Date(item.selection.endDate)
    let hoursDiff = item.selection.startDate.getHours() - item.selection.startDate.getTimezoneOffset() / 60;
    let minutesDiff = (item.selection.startDate.getHours() - item.selection.startDate.getTimezoneOffset()) % 60;
    item.selection.startDate.setHours(hoursDiff);
    item.selection.startDate.setMinutes(minutesDiff);
    item.selection.endDate .setHours(hoursDiff);
    item.selection.endDate .setMinutes(minutesDiff);
  }
  const defaultStaticRanges = createStaticRanges([
    {
      label: t("today"),
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
        label: t("today")
      })
    },
    {
      label: t("yesterday"),
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday,
        label: t("yesterday")
      })
    },
    {
      label: t("this_week"),
      range: () => ({
        startDate: defineds.startOfWeek,
        endDate: defineds.endOfWeek,
        label: t("this_week")
      })
    },
    {
      label: t("previous_week"),
      range: () => ({
        startDate: defineds.startOfLastWeek,
        endDate: defineds.endOfLastWeek,
        label: t("previous_week")
      })
    },
    {
      label: t("current_month"),
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfMonth,
        label: t("current_month")
      })
    },
    {
      label: t("previous_month"),
      range: () => ({
        startDate: defineds.startOfLastMonth,
        endDate: defineds.endOfLastMonth,
        label: t("previous_month")
      })
    },
    {
      label: t("last_six_months"),
      range: () => ({
        startDate: defineds.startOfLast6Month,
        endDate: defineds.endOfLast6Month,
        label: t("last_six_months")
      })
    },
    {
      label: t("all"),
      range: () => ({
        startDate: startOfMonth(addYears(new Date(), -50)),
        endDate: defineds.endOfWeek,
        label: t("all")
      })
    }
  ]);


  return (
    <DateRangePicker
      locale={i18n.resolvedLanguage === "en" ? enUS : ar}
      onChange={(item) => {
          updateTimeZone(item);
          setSelectionRange([item.selection])
      }}
      showSelectionPreview={true}
      ranges={selectionRange}
      direction="horizontal"
      staticRanges={defaultStaticRanges}
      style={{direction: "ltr"}}
      maxDate={new Date()}
    />
  );
}