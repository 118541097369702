import DotsSvg from "../../../components/resources/dots svg/DotsSvg";
import RedirectSvg from "../../../components/resources/redirect svg/RedirectSvg";
import KeyIcon from "../../../components/resources/icons/KeyIcon";
import TableSortIcon from "../../../components/resources/icons/TableSortIcon";
import CashAmountIcon from "../../../components/resources/icons/CashAmountIcon";
import UserAlt from "../../../components/resources/icons/UserAlt";
import UserTag from "../../../components/resources/icons/UserTag";
import CategoryIcon from "../../../components/resources/icons/CategoryIcon";
import DateIcon from "../../../components/resources/icons/DateIcon";
import Reply from "../../../components/resources/icons/Reply";
import Rejected from "../../../components/resources/icons/Rejected";
import Accepted from "../../../components/resources/icons/Accepted";
import Suspended from "../../../components/resources/icons/Suspended";
import EditNewRoleIcon from "../../resources/icons/EditRoleIcon";

import "./roles.scss";

export const columnsHeader = ({
  setSelectedRole,
  setShowAddNewRoleModal,
  canEditRole,
  t, 
  i18n
}) => [
  {
    subject: "إسم الدور",
    title: () => (
      <div class="flex items-center">
        <div>
          <UserAlt />
        </div>
        <div className="mx-2 text-xs font-bold text-body">{t("name")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "name",
    key: "role",
    sorter: true,
    render: (text, row) => (
      <div
        className={`flex justify-start text-xs text-body font-medium ${
          row.active === 0 ? "opacity-50" : null
        }`}
      >
      {` ${i18n.resolvedLanguage === "en" && row.en_name ? row.en_name : text} `}
      {row.company_default_admin ? <span className="mx-1" style={{opacity: "0.6"}}>({t("workspace_owner")})</span> : row.company_default_member ? <span className="mx-1" style={{opacity: "0.6"}}>({t("default_role")})</span> : null}
      </div>
    ),
    width: 50,
  },

  {
    subject: "الأفراد",
    title: () => (
      <div class="flex items-center">
        <div>
          <UserTag />
        </div>
        <div className="mx-2 text-xs font-bold text-body">{t("users")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "users_json_string",
    key: "users",
    sorter: true,
    render: (text, row) => {
      let users = JSON.parse(text);
      return (
        <div className="flex items-center justify_center">
          {users?.slice(0, 5).map((user) => {
            return (
              <div className={`ml-1 ${row.active === 0 ? "opacity-50" : null}`}>
                {user.name ? (
                  <div>
                    {user.profile_picture_url ? (
                      <img
                        src={user.profile_picture_url}
                        alt=""
                        class="h-5 w-5 rounded-full"
                      />
                    ) : (
                      <span className="h-5 w-5 rounded-full bg-onhold flex justify-center items-center">
                        {user.name[0]}
                      </span>
                    )}
                  </div>
                ) : null}
              </div>
            );
          })}
          {users?.length - 5 > 0 ? (
            <div className="relative roles-dropdown-parent">
              <div className="bg-sidebar-background rounded-full h-8 w-8 flex justify-center items-center">
                <div className="text-center text-xs font-medium text-side-icons">
                  + {users?.length - 5}
                </div>
                <div className="roles-dropdown">
                  {users.map((user) => {
                    return (
                      <div className="roles-dropdown-row">
                        <div
                          className={`ml-1 ${
                            row.active === 0 ? "opacity-50" : null
                          }`}
                        >
                          {user.name ? (
                            <div>
                              {user.profile_picture_url ? (
                                <img
                                  src={user.profile_picture_url}
                                  alt=""
                                  class="h-7 w-7 rounded-full"
                                />
                              ) : (
                                <span className="h-7 w-7 rounded-full bg-onhold flex justify-center items-center">
                                  {user.name[0]}
                                </span>
                              )}
                            </div>
                          ) : null}
                        </div>
                        <span className="roles-dropdown-text">{user.name}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      );
    },
    width: 50,
  },
  {
    subject: "الصلاحيات",
    title: () => (
      <div class="flex items-center">
        <div>
          <KeyIcon />
        </div>
        <div className="mx-2 text-xs font-bold text-body">{t("permissions")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "permissions_json_string",
    key: "permissions",
    sorter: true,
    render: (text, row) => {
      let permissions = JSON.parse(text);
      return (
        <div
          className={`flex items-center ${
            row.active === 0 ? "opacity-50" : null
          }`}
        >
          {permissions?.slice(0, 3).map((permission) => {
            return (
              <div className="ml-1">
                <div className="bg-sidebar-background rounded-full h-auto w-auto flex justify-center items-center px-3 py-2">
                  <div className="text-center text-xs font-medium text-side-icons">
                    {i18n.resolvedLanguage === "en" ? permission.en_name : permission.name}
                  </div>
                </div>
              </div>
            );
          })}
          {permissions?.length - 3 > 0 ? (
            <div className="relative roles-dropdown-parent">
              <div className="bg-sidebar-background rounded-full h-8 w-8 flex justify-center items-center">
                <div className="text-center text-xs font-medium text-side-icons">
                  + {permissions?.length - 3}
                </div>

                <div className="roles-dropdown">
                  {permissions.map((permission) => {
                    return (
                      <div className="roles-dropdown-row">
                        <span className="roles-dropdown-text">
                        {i18n.resolvedLanguage === "en" ? permission.en_name : permission.name}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      );
    },
    width: 300,
  },
  {
    subject: "",
    title: () => null,
    dataIndex: "id",
    key: "id",
    sorter: false,
    render: (text, row) => (
      <div className="flex justify-end">
        {canEditRole && row.company_default_admin != 1 && row.company_default_member != 1 ? (
          <div>
            <button
              type="button"
              className={`col-span-1 px-3 py-2 m-auto text-base`}
              onClick={() => {
                setSelectedRole(() => row);
                setShowAddNewRoleModal(true);
              }}
            >
              <div class="flex flex-row-reverse fill-current">
                <div>
                  <EditNewRoleIcon isButton />
                </div>
              </div>
            </button>
          </div>
        ) : null}
      </div>
    ),
    width: 20,
  },
];
