import AcceptedSvg from "../../components/resources/accepted svg/AcceptedSvg";
import RejectedSvg from "../../components/resources/rejected svg/RejectedSvg";

import { convertDate } from "../../utils/convertDate";

export const columnsHeader = [
  {
    title: "رقم الطلب",
    dataIndex: "id",
    key: "id",
    width: 100,
  },
  {
    title: "اسم الطلب",
    dataIndex: "name",
    key: "name",
    width: 150,
  },
  {
    title: "مرسل الطلب",
    dataIndex: "sender_name",
    key: "sender_name",
    width: 150,
  },
  {
    title: "تاريخ الرد",
    dataIndex: "response_time",
    key: "response_time",
    width: 250,
    sorter: (a, b) => {},
    render: (text, row, index) => {
      return <div>{convertDate(text)}</div>;
    },
  },
  {
    title: "القيمة",
    dataIndex: "amount",
    key: "amount",
    width: 100,
    sorter: (a, b) => {},
  },
  {
    title: "حالة الرد",
    dataIndex: "approved",
    key: "approved",
    width: 100,
    sorter: (a, b) => {},
    render: (text) => {
      return (
        <div>
          {text === 1 ? (
            <div className="tool-pin-container relative">
              <div>
                <AcceptedSvg />
              </div>
              <p className="tool-pin px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-6 z-10">
                مقبول
              </p>
            </div>
          ) : (
            <div className="tool-pin-container relative">
              <div>
                <RejectedSvg />
              </div>
              <p className="tool-pin px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-6 z-10">
                مرفوض
              </p>
            </div>
          )}
        </div>
      );
    },
  },
];
