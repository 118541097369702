import { Fragment, useState } from "react";
import Modal from "./../../Modal/index";
import Alert from "../../Alert";
import Archiving from "../../../resources/icons/Archiving";
import useFetchUserData from "../../../../hooks/reactQuery/useFetchUserData";
import { useTranslation } from "react-i18next";

const Archive = ({ activeTab, selectedRows, mutateArchiving }) => {
  const { data: userData } = useFetchUserData();
  const [showArchive, setShowArchive] = useState(false);

  const {t, i18n} = useTranslation();

  return (
    <Fragment>
      <div className="relative inline-block text-left h-full">
        <button
          className={`h-10 archive-table-btn transition-all duration-300 border border-transparent hover:border-secondary-hover flex items-center justify-center rounded-md ${
            showArchive ? "border-secondary-hover archive-table-btn-w" : ""
          } ${selectedRows?.length === 0 ? "cursor-not-allowed" : ""}`}
          onClick={() => setShowArchive(true)}
          disabled={selectedRows?.length === 0}
        >
          <span className="absolute left-8 w-max">{t("archive")}</span>
          <div className="absolute left-1 archive-table">
            <Archiving strokeColor={showArchive && "#1D64D8"} />
          </div>
        </button>
      </div>

      <Modal showModal={showArchive} onClose={() => setShowArchive(false)}>
        <Alert
          hasFooter={true}
          alertType="confirm"
          icon={() => <Archiving strokeColor="#1D64D8" />}
          confirmBtnBg="bg-secondary focus:ring-secondary"
          title={t("archive_selected_transactions")}
          body={() => (
            <div>
              <p>
                {t("alert_text_archive")}
              </p>
            </div>
          )}
          confirmText={t("archive_confirm")}
          cancelText={t("cancel")}
          handleConfirm={() =>
            mutateArchiving?.mutate({
              selectedRows: selectedRows.filter((el) => el.approved !== null),
              userId: userData.id,
              activeTab,
              setShowArchive,
            })
          }
          handleCancel={() => setShowArchive(false)}
          isSubmitLoading={mutateArchiving?.isLoading}
        />
      </Modal>
    </Fragment>
  );
};

export default Archive;
