import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useMutateAcceptMoneyRequest(
  setShowAcceptAlert,
  activeCompanyId
) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ data, fromSafe, activeRow }) => {
      const formData = new FormData();

      formData.append("id", activeRow.id);
      formData.append("approved", 1);
      formData.append("fromSafe", fromSafe);
      data.financial_id?.value !== undefined &&
        data.financial_id?.value !== null &&
        formData.append("parent_request_id", data.financial_id.value);
      formData.append("amount", activeRow.amount);
      formData.append("company_id", activeCompanyId);
      formData.append("safe_id", data.safe_id.value);

      formData.append("sender_id", activeRow.creator);

      return apiClient.post("money_request/accept", formData);
    },
    {
      onSuccess: () => {
        setShowAcceptAlert(false);
        toast.info("تم قبول الطلب.");
        queryClient.invalidateQueries("getMoneyRequestsData");
        queryClient.invalidateQueries("getSingleMoneyRequest");
        queryClient.invalidateQueries("userData");
        queryClient.invalidateQueries("userBalance");
        queryClient.invalidateQueries("getLastUpdates");
      },
      onError: (error) => {
        setShowAcceptAlert(false);
        return error;
      },
    }
  );
}
