const WithdrawArrowSvg = ({}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 40 40"
      fill="none"
    >
      <rect width="40" height="40" rx="6" fill="#3B82F6" />
      <path
        d="M25.79 30.7402H14.21C11.47 30.7402 9.25 28.5102 9.25 25.7702V18.3602C9.25 17.0002 10.09 15.2902 11.17 14.4502L16.56 10.2502C18.18 8.99021 20.77 8.93021 22.45 10.1102L28.63 14.4402C29.82 15.2702 30.75 17.0502 30.75 18.5002V25.7802C30.75 28.5102 28.53 30.7402 25.79 30.7402ZM17.48 11.4302L12.09 15.6302C11.38 16.1902 10.75 17.4602 10.75 18.3602V25.7702C10.75 27.6802 12.3 29.2402 14.21 29.2402H25.79C27.7 29.2402 29.25 27.6902 29.25 25.7802V18.5002C29.25 17.5402 28.56 16.2102 27.77 15.6702L21.59 11.3402C20.45 10.5402 18.57 10.5802 17.48 11.4302Z"
        fill="white"
      />
      <path
        d="M21.5 26.75H18.5C16.43 26.75 14.75 25.07 14.75 23V20C14.75 17.93 16.43 16.25 18.5 16.25H21.5C23.57 16.25 25.25 17.93 25.25 20V23C25.25 25.07 23.57 26.75 21.5 26.75ZM18.5 17.75C17.26 17.75 16.25 18.76 16.25 20V23C16.25 24.24 17.26 25.25 18.5 25.25H21.5C22.74 25.25 23.75 24.24 23.75 23V20C23.75 18.76 22.74 17.75 21.5 17.75H18.5Z"
        fill="white"
      />
      <path
        d="M20 26.75C19.59 26.75 19.25 26.41 19.25 26V17C19.25 16.59 19.59 16.25 20 16.25C20.41 16.25 20.75 16.59 20.75 17V26C20.75 26.41 20.41 26.75 20 26.75Z"
        fill="white"
      />
      <path
        d="M24.5 22.25H15.5C15.09 22.25 14.75 21.91 14.75 21.5C14.75 21.09 15.09 20.75 15.5 20.75H24.5C24.91 20.75 25.25 21.09 25.25 21.5C25.25 21.91 24.91 22.25 24.5 22.25Z"
        fill="white"
      />
    </svg>
  );
};

export default WithdrawArrowSvg;
