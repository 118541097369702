const HandSvg = ({}) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="26"
        viewBox="0 0 20 26"
        fill="none"
      >
        <g clip-path="url(#clip0)">
          <path
            d="M3.39961 8.5C3.19961 7.9 3.09961 7.3 2.89961 6.7C2.09961 6.3 1.49961 5.4 1.49961 4.3C1.49961 2.7 2.79961 1.5 4.29961 1.5C5.79961 1.5 7.09961 2.8 7.09961 4.3C7.09961 4.5 7.09961 4.7 6.99961 4.9C7.29961 5.5 7.49961 6.2 7.69961 6.9C8.19961 6.2 8.59961 5.3 8.59961 4.3C8.69961 1.9 6.79961 0 4.39961 0C1.99961 0 0.0996094 1.9 0.0996094 4.3C0.0996094 6.4 1.49961 8.1 3.39961 8.5Z"
            fill="#A9B5BE"
          />
          <path
            d="M0.399343 18.7C1.49934 17.6 2.69934 18 4.29934 18.2C5.69934 18.4 6.99934 18 6.89934 17.3C6.79934 16.1 6.59934 15.6 6.09934 14C5.69934 12.8 4.99934 10.5 4.39934 8.4C3.59934 5.6 3.39934 4.3 4.49934 4C5.69934 3.6 6.39934 5.3 6.99934 7.7C7.69934 10.4 8.09934 11.6 8.29934 11.5C8.69934 11.4 8.19934 10.2 9.09934 10C10.2993 9.6 10.4993 10.6 10.8993 10.5C11.1993 10.4 11.0993 9.4 12.0993 9.2C13.0993 8.9 13.5993 10.1 13.9993 10C14.3993 9.9 14.3993 9.5 14.9993 9.3C15.5993 9.1 17.8993 10.1 19.0993 14.5C20.6993 20 18.8993 21.1 19.3993 22.9L12.1993 25.6C11.5993 24.2 9.79934 24.1 8.19934 23.2C6.59934 22.3 5.49934 20.5 1.29934 20.6C-0.300657 20.7 -0.200657 19.4 0.399343 18.7Z"
            fill="#A9B5BE"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="19.8" height="25.7" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default HandSvg;
