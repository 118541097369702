import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useMutateDepositAccept(setShowAcceptModal, safeId) {
  const queryClient = useQueryClient();

  return useMutation(
    (activeRow) => {
      const formData = new FormData();

      formData.append("id", activeRow.id);
      formData.append("accept", 1);

      return apiClient.post(`cashin/accept?safe_id=${safeId}`, formData);
    },
    {
      onSuccess: () => {
        toast.info("تم قبول طلب الإيداع.");
        queryClient.invalidateQueries("getDepositFullControl");
        queryClient.invalidateQueries("getLastUpdates");
        queryClient.invalidateQueries("getSingleCashin");
        queryClient.invalidateQueries("userData");
        queryClient.invalidateQueries("userBalance");
        setShowAcceptModal(false);
      },
      onError: (error) => {
        setShowAcceptModal(false);
        return error;
      },
    }
  );
}
