import React, { Fragment, useState } from "react";

import Modal from "../../common/Modal";
import MoneyRequest from "../Form";
import CloseSvg from "../../resources/close svg/CloseSvg";

import "./InsufficientFunds.scss";

const InsufficientFunds = ({ onClose }) => {
  const [showMoneyRequestModal, setShowMoneyRequestModal] = useState(false);

  return !showMoneyRequestModal ? (
    <div className="overlay" onClick={onClose}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <div className="bg-white lg:grid lg:grid-cols-12 lg:gap-x-5 money-request-no-cash-container">
          <div className="col-span-12">
            <div className="sm:rounded-md sm:overflow-hidden">
              <div className="relative text-center p-4">
                <h3 className="text-lg leading-6 font-medium text-red-400">
                  رصيدك لا يكفي!
                </h3>
                <button
                  type="button"
                  className="absolute top-4 left-4"
                  onClick={onClose}
                >
                  <div>
                    <CloseSvg />
                  </div>
                </button>
              </div>
              <hr />
              <div className="bg-gray-50 py-6 px-4 space-y-6 sm:p-6">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-blue-600">
                    رصيدك الحالي لا يكفي لاتمام عملية قبول العهدة
                  </h3>
                  <p className="mt-3 text-gray-700">
                    حتى تستطيع قبول طلبات العهد الواردة اليك, وتدوين مصروفاتك
                    المالية اول باول,
                    <br />
                    لابد أن تملك ما يكفي من الرصيد بحسابك أولا.
                  </p>
                  <p className="mt-3 text-gray-700">
                    يمكنك طلب عهدة مالية من{" "}
                    <Fragment>
                      <button
                        type="button"
                        className="text-red-400"
                        onClick={() => setShowMoneyRequestModal(true)}
                      >
                        هنا
                      </button>
                    </Fragment>
                  </p>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Modal
      showModal={showMoneyRequestModal}
      onClose={() => {
        setShowMoneyRequestModal(false);
        onClose();
      }}
    >
      <MoneyRequest
        onClose={() => {
          setShowMoneyRequestModal(false);
          onClose();
        }}
      />
    </Modal>
  );
};

export default InsufficientFunds;
