const UserAlt = () => {
  return (
    <div>
      <svg
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.80884 14.2238C4.6373 13.1902 6.74972 12.6004 8.99991 12.6004C11.2501 12.6004 13.3625 13.1902 15.191 14.2238M11.6999 7.20039C11.6999 8.69156 10.4911 9.90039 8.9999 9.90039C7.50873 9.90039 6.2999 8.69156 6.2999 7.20039C6.2999 5.70922 7.50873 4.50039 8.9999 4.50039C10.4911 4.50039 11.6999 5.70922 11.6999 7.20039ZM17.0999 9.00039C17.0999 13.4739 13.4734 17.1004 8.9999 17.1004C4.5264 17.1004 0.899902 13.4739 0.899902 9.00039C0.899902 4.52688 4.5264 0.900391 8.9999 0.900391C13.4734 0.900391 17.0999 4.52688 17.0999 9.00039Z"
          stroke="#1E293B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default UserAlt;
