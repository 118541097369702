import React, { useState, useEffect, Fragment } from "react";

import { useHistory } from "react-router-dom";
import {
  useForm,
  useFieldArray,
  FormProvider,
  useFormContext,
} from "react-hook-form";
import Form from "../../../components/paymentLicense/Form";
import Modal from "../../../components/common/Modal";
import Alert from "../../../components/common/Alert";
import Plus from "../../../components/resources/icons/Plus";
import useFetchUserData from "./../../../hooks/reactQuery/useFetchUserData";
import useMutatePaymentForm from "../../../hooks/reactQuery/useMutatePaymentForm";
import { useDataLayerValue } from "../../../context/DataLayer";
import "./Create.scss";
import { useTranslation } from "react-i18next";
const PaymentLicense = () => {
  const { data: userData } = useFetchUserData();
  const [{ isFileUploading }] = useDataLayerValue();
  const {t, i18n} = useTranslation();
  const history = useHistory();

  // Confirm modal
  const [showAlertConfirmModal, setShowAlertConfirmModal] = useState(false);
  // Cancel modal
  const [showAlertCancelModal, setShowAlertCancelModal] = useState(false);

  const [trackAddField, setTrackAddField] = useState(true);

  const methods = useForm({
    mode: "all",
    defaultValues: {
      paymentForm: [
        {
          name: "",
          periodic: "",
          categoryid: "",
          request_date: "",
          financial_id: "",
          amount: "",
          notes: "",
          paymentFiles: [],
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "paymentForm",
  });

  const addFieldSet = () => {
    append({
      name: "",
      periodic: "",
      categoryid: "",
      request_date: "",
      financial_id: "",
      amount: "",
      notes: "",
      paymentFiles: [],
    });
  };

  const removeFieldSet = (fieldIndex) => {
    remove(fieldIndex);
  };

  const { isLoading, mutate, status } = useMutatePaymentForm(
    userData.id,
    userData.activeCompanyId,
    setShowAlertConfirmModal,
    history
  );
  const handleSubmitpaymentForm = (data) => {
    const formData = data?.paymentForm?.map((field) => {
      return {
        name: field.name,
        amount: field.amount,
        request_date: field.request_date,
        recipient_id: field.recipient_id.id,
        category_id: field.categoryid.id,
        subcategory_id: field.subcategoryid?.id,
        urgent: field.switch ? 1 : 0,
        notes: field.notes,
        attachments: field.attachments?.map((attachment) => {
          return Object.assign({
            guid: attachment.guid,
            name: attachment.name,
          });
        }),
      };
    });
    mutate(formData);
  };

  const [totalValue, setTotalValue] = useState(0);

  const [activeFieldArr, setActiveFieldArr] = useState(fields[0]?.id);

  return (
    <FormProvider {...methods}>
      <form className="space-y-8 divide-y divide-gray-200 w-full bg-white relative">
        <div className="px-4 pay-forms-height">
          {fields.map((field, index) => {
            return (
              <ExpandedFieldArray
                key={field.id}
                fields={fields}
                index={index}
                removeFieldSet={removeFieldSet}
                field={field}
                setTotalValue={setTotalValue}
                activeFieldArr={activeFieldArr}
                setActiveFieldArr={setActiveFieldArr}
                trackAddField={trackAddField}
                setTrackAddField={setTrackAddField}
              />
            );
          })}
        </div>

        <div className="py-5 lg:sticky bottom-0 bg-white px-4 lg:pr-16">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-y-6 lg:gap-x-4">
            <div className="col-span-9 grid lg:grid-cols-9 grid-cols-1 gap-y-3 h-fit-content">
              <button
                type="button"
                className="lg:col-span-2 col-span-1 row-start-3 lg:row-start-1 lg:w-32 flex justify-center items-center gap-x-3 bg-white py-2 px-4 border border-outline rounded-md shadow-sm text-sm font-bold text-body"
                onClick={() => {
                  addFieldSet();
                  setTrackAddField((prevState) => !prevState);
                }}
              >
                <div>
                  <Plus />
                </div>
                <span>{t("add_another")}</span>
              </button>

              <div className="col-span-4 lg:col-start-6 grid grid-cols-1 lg:grid-cols-9 gap-y-6 gap-x-2">
                <Fragment>
                  <button
                    type="button"
                    className={`lg:col-span-4 lg:col-start-2 py-2 px-4 text-base leading-5 font-bold rounded-md shadow-sm border border-outline ${
                      !methods.formState?.isValid || isFileUploading
                        ? "cursor-not-allowed disabled bg-outline text-white"
                        : "bg-white text-hints"
                    }`}
                    onClick={() => setShowAlertConfirmModal(true)}
                    disabled={!methods.formState?.isValid || isFileUploading}
                  >
                  {t("send")}
                  </button>
                  <Modal
                    showModal={showAlertConfirmModal}
                    onClose={() => setShowAlertConfirmModal(false)}
                  >
                    <Alert
                      hasFooter={true}
                      alertType="confirm"
                      title={t("confirm")}
                      body={() => (
                        <div>
                        {t("alert_text_confirm_send")}
                        </div>
                      )}
                      confirmText={t("send")}
                      cancelText={t("cancel")}
                      isSubmitLoading={isLoading}
                      handleConfirm={methods.handleSubmit(
                        handleSubmitpaymentForm
                      )}
                      handleCancel={() =>
                        !isLoading && setShowAlertConfirmModal(false)
                      }
                    />
                  </Modal>
                </Fragment>

                <Fragment>
                  <button
                    type="button"
                    className={`lg:col-span-4 py-2 px-4 text-base leading-5 font-bold rounded-md shadow-sm border border-outline ${
                      !methods.formState?.isDirty
                        ? "cursor-not-allowed disabled bg-outline text-white"
                        : "bg-white text-hints"
                    }`}
                    onClick={() => setShowAlertCancelModal(true)}
                    disabled={!methods.formState?.isDirty}
                  >
                  {t("cancel")}
                  </button>
                  <Modal
                    showModal={showAlertCancelModal}
                    onClose={() => setShowAlertCancelModal(false)}
                  >
                    <Alert
                      hasFooter={true}
                      alertType="cancel"
                      title={t("confirm")}
                      body={() => (
                        <div>
                          {t("alert_text_cancel")}
                        </div>
                      )}
                      confirmText={t("discard")}
                      cancelText={t("cancel")}
                      handleConfirm={() => history.push("/")}
                      handleCancel={() => setShowAlertCancelModal(false)}
                    />
                  </Modal>
                </Fragment>
              </div>
            </div>

            <div className="lg:col-span-2 lg:col-start-11 bg-white py-2 px-4 rounded-md shadow-sm lg:text-left text-center">
              <span className="text-sm font-bold text-body">
              {t("total_amount")}
              </span>{" "}
              <span className="text-secondary text-sm font-bold">
                {isNaN(totalValue) ? 0 : totalValue}
              </span>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default PaymentLicense;

function ExpandedFieldArray({
  fields,
  index,
  removeFieldSet,
  field,
  setTotalValue,
  activeFieldArr,
  setActiveFieldArr,
  trackAddField,
}) {
  const {
    formState: { errors },
  } = useFormContext();
  const [isExpanded, setExpanded] = useState(true);

  useEffect(() => {
    if (activeFieldArr !== field.id) {
      setExpanded(false);
    }
  }, [activeFieldArr]);

  useEffect(() => {
    setExpanded(true);
    setActiveFieldArr(fields[fields.length - 1].id);
  }, [trackAddField]);

  return (
    <div className="flex">
      <div className="flex flex-col items-center ml-4 relative top-6">
        <div
          className={`flex justify-center items-center py-2 px-3 text-white text-base leading-4 font-medium rounded-lg relative transition-all duration-300 ${
            errors?.paymentForm && errors?.paymentForm[index]
              ? "bg-error"
              : "bg-side-icons"
          }`}
        >
          <span className="w-2 flex justify-center items-center">
            {index + 1}
          </span>
        </div>
        <div className="flex justify-center h-full">
          <div
            className={`w-px bg-side-icons h-0 transition-all duration-300 ${
              index + 1 !== fields.length && "h-full"
            }`}
          ></div>
        </div>
      </div>
      <Form
        fields={fields}
        index={index}
        field={field}
        removeFieldSet={removeFieldSet}
        trackTotalValue={(totalValue) => setTotalValue(totalValue)}
        activeFieldArr={activeFieldArr}
        setActiveFieldArr={setActiveFieldArr}
        isExpanded={isExpanded}
        setExpanded={setExpanded}
      />
    </div>
  );
}
