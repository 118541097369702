export const CardSendSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M12.0807 7.70833H1.66406C1.3224 7.70833 1.03906 7.42499 1.03906 7.08333C1.03906 6.74166 1.3224 6.45833 1.66406 6.45833H12.0807C12.4224 6.45833 12.7057 6.74166 12.7057 7.08333C12.7057 7.42499 12.4224 7.70833 12.0807 7.70833Z"
        fill="#F8FAFC"
      />
      <path
        d="M6.66667 14.375H5C4.65833 14.375 4.375 14.0917 4.375 13.75C4.375 13.4083 4.65833 13.125 5 13.125H6.66667C7.00833 13.125 7.29167 13.4083 7.29167 13.75C7.29167 14.0917 7.00833 14.375 6.66667 14.375Z"
        fill="#F8FAFC"
      />
      <path
        d="M12.0833 14.375H8.75C8.40833 14.375 8.125 14.0917 8.125 13.75C8.125 13.4083 8.40833 13.125 8.75 13.125H12.0833C12.425 13.125 12.7083 13.4083 12.7083 13.75C12.7083 14.0917 12.425 14.375 12.0833 14.375Z"
        fill="#F8FAFC"
      />
      <path
        d="M14.6307 17.7083H5.36406C2.0474 17.7083 1.03906 16.7083 1.03906 13.425V6.57501C1.03906 3.29167 2.0474 2.29167 5.36406 2.29167H12.0807C12.4224 2.29167 12.7057 2.57501 12.7057 2.91667C12.7057 3.25834 12.4224 3.54167 12.0807 3.54167H5.36406C2.7474 3.54167 2.28906 3.99167 2.28906 6.57501V13.4167C2.28906 16 2.7474 16.45 5.36406 16.45H14.6224C17.2391 16.45 17.6974 16 17.6974 13.4167V11.6833C17.6974 11.3417 17.9807 11.0583 18.3224 11.0583C18.6641 11.0583 18.9474 11.3417 18.9474 11.6833V13.4167C18.9557 16.7083 17.9474 17.7083 14.6307 17.7083Z"
        fill="#F8FAFC"
      />
      <path
        d="M16.6641 8.54167C16.3224 8.54167 16.0391 8.25833 16.0391 7.91667V2.91667C16.0391 2.66667 16.1891 2.43333 16.4224 2.34167C16.6557 2.25 16.9224 2.3 17.1057 2.475L18.7724 4.14167C19.0141 4.38333 19.0141 4.78333 18.7724 5.025C18.5307 5.26667 18.1307 5.26667 17.8891 5.025L17.2891 4.425V7.91667C17.2891 8.25833 17.0057 8.54167 16.6641 8.54167Z"
        fill="#F8FAFC"
      />
      <path
        d="M14.9979 5.20833C14.8396 5.20833 14.6812 5.15 14.5562 5.025C14.3146 4.78333 14.3146 4.38333 14.5562 4.14166L16.2229 2.475C16.4646 2.23333 16.8646 2.23333 17.1062 2.475C17.3479 2.71666 17.3479 3.11666 17.1062 3.35833L15.4396 5.025C15.3146 5.15 15.1562 5.20833 14.9979 5.20833Z"
        fill="#F8FAFC"
      />
    </svg>
  );
};
