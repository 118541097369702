const Balance = () => {
  return (
    <div>
      <svg
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.06369 12.6453V14.1678C8.06369 15.4578 6.86368 16.5003 5.38618 16.5003C3.90868 16.5003 2.70117 15.4578 2.70117 14.1678V12.6453C2.70117 13.9353 3.90118 14.8503 5.38618 14.8503C6.86368 14.8503 8.06369 13.9278 8.06369 12.6453Z"
          stroke="#1E293B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.06248 10.5826C8.06248 10.9576 7.95748 11.3025 7.77748 11.6025C7.33498 12.33 6.42747 12.7876 5.37747 12.7876C4.32747 12.7876 3.41996 12.3225 2.97746 11.6025C2.79746 11.3025 2.6925 10.9576 2.6925 10.5826C2.6925 9.93755 2.99249 9.36005 3.47249 8.94005C3.95999 8.51255 4.62746 8.25757 5.36996 8.25757C6.11246 8.25757 6.77999 8.52005 7.26749 8.94005C7.76249 9.35255 8.06248 9.93755 8.06248 10.5826Z"
          stroke="#1E293B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.06369 10.5825V12.645C8.06369 13.935 6.86368 14.85 5.38618 14.85C3.90868 14.85 2.70117 13.9275 2.70117 12.645V10.5825C2.70117 9.29249 3.90118 8.25 5.38618 8.25C6.12868 8.25 6.79621 8.51248 7.28371 8.93248C7.76371 9.35248 8.06369 9.93749 8.06369 10.5825Z"
          stroke="#1E293B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.5 8.22737V9.77242C16.5 10.1849 16.17 10.5224 15.75 10.5374H14.28C13.47 10.5374 12.7275 9.94489 12.66 9.13489C12.615 8.66239 12.795 8.21988 13.11 7.91238C13.3875 7.62738 13.77 7.4624 14.19 7.4624H15.75C16.17 7.4774 16.5 7.81487 16.5 8.22737Z"
          stroke="#1E293B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.5 7.875V6.375C1.5 4.335 2.73 2.91 4.6425 2.67C4.8375 2.64 5.04 2.625 5.25 2.625H12C12.195 2.625 12.3825 2.63249 12.5625 2.66249C14.4975 2.88749 15.75 4.32 15.75 6.375V7.46251H14.19C13.77 7.46251 13.3875 7.62749 13.11 7.91249C12.795 8.21999 12.615 8.66249 12.66 9.13499C12.7275 9.94499 13.47 10.5375 14.28 10.5375H15.75V11.625C15.75 13.875 14.25 15.375 12 15.375H10.125"
          stroke="#1E293B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Balance;
