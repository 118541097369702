const Archiving = ({ strokeColor }) => {
  return (
    <div>
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="table-operation-btn"
      >
        <path
          d="M4.25 7.5H20M4.25 7.5C3.00736 7.5 2 6.49264 2 5.25C2 4.00736 3.00736 3 4.25 3H20C21.2426 3 22.25 4.00736 22.25 5.25C22.25 6.49264 21.2426 7.5 20 7.5M4.25 7.5L4.25 18.75C4.25 19.9926 5.25736 21 6.5 21H17.75C18.9926 21 20 19.9926 20 18.75V7.5M9.875 12H14.375"
          stroke={strokeColor ? strokeColor : "#1E293B"}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 8H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V8Z"
          fill="white"
        />
        <rect
          x={13}
          y={17}
          width={2}
          height={6}
          rx={1}
          transform="rotate(-180 13 17)"
          fill={strokeColor ? strokeColor : "#1E293B"}
        />
        <rect
          x={9}
          y={15}
          width={2}
          height={6}
          rx={1}
          transform="rotate(-90 9 15)"
          fill={strokeColor ? strokeColor : "#1E293B"}
        />
      </svg>
    </div>
  );
};

export default Archiving;
