import * as React from "react";
import GridLayout from "react-grid-layout";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer
} from "recharts";

import "the-new-css-reset";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./styles.css";

import styled from "styled-components";

import CustomizedXAxisTick from "../StackedBar/CustomizedXAxisTick";
import CustomizedYAxisTick from "../StackedBar/CustomizedYAxisTick";
import CustomToolTip from "../StackedBar/CustomToolTip";
import { useTranslation } from "react-i18next";


const layout = {
  pc: {
    breakpoint: 768,
    col: 3
  },
  sp: {
    breakpoint: 0,
    col: 1
  }
};

const StyledYAxisResponsiveContainer = styled(ResponsiveContainer)`
  position: absolute;
  top: 0;
  left: -20px;
  pointer-events: none;

  .recharts-bar {
    display: none;
  }
  .recharts-xAxis {
    display: none;
  }
`;
const StyledDataResponsiveContainer = styled(ResponsiveContainer)`
  background-color: #fff;
  .recharts-yAxis {
    display: none;
  }
`;

const DEFAULT_WIDTH = 100;
const MARGIN = 12;

type ComponentProps = {
  data: [{
    colors: any
  }],
  currentSafes: [
    {
      name: "",
      id: 0
    }
  ],
  activeTab: any,
  numOfBars: any
}



export default function ScrollableBarChart({data, currentSafes, activeTab, numOfBars}: ComponentProps) {
  const {t, i18n} = useTranslation();

  const layouts: GridLayout.Layouts = {
    pc: [
      { i: "a", x: 0, y: 0, w: 3, h: 2 },
      { i: "b", x: 0, y: 2, w: 3, h: 2 }
    ]
  };

  const [width, setWidth] = React.useState(0);
  const [cardHeaderHeight, setCardHeaderHeight] = React.useState(0);
  const containerEl = React.useRef<HTMLDivElement>(null);
  const cardHeaderEl = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    setWidth(containerEl.current?.clientWidth || 500);
    setCardHeaderHeight(cardHeaderEl.current?.clientHeight || 0);
  }, []);

  return (
    <div className="container" ref={containerEl}>
      <GridLayout.Responsive
        className="layout"
        layouts={layouts}
        breakpoints={{ pc: layout.pc.breakpoint, sp: layout.sp.breakpoint }}
        cols={{ pc: layout.pc.col, sp: layout.sp.col }}
        width={width}
        isDraggable={false}
        isResizable={false}
        style={{
          height: "340px",
          width: "100%",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <div key="b">
          <div ref={cardHeaderEl}>
          </div>

          <div
            style={{
              position: "relative",
              height: `calc(100% - ${cardHeaderHeight}px - ${MARGIN}px)`,
              width: `${DEFAULT_WIDTH}%`,
              marginTop: "0px"
            }}
          >
            {/* XAxisとchartとCartesianGrid表示用 */}
            <div
              style={{
                height: "100%",
                overflowX: "scroll",
                overflowY: "hidden",
                width: `${DEFAULT_WIDTH}%`,
              }}
              className="scrollableChart"
            >
              <StyledDataResponsiveContainer width={`${DEFAULT_WIDTH * (data && data.length > numOfBars ? (1 + ((data.length - numOfBars) / numOfBars)) : 1)}%`}>
                <BarChart
                  data={data}
                  layout="horizontal"
                  margin={{ top: 0, right: MARGIN, bottom: 0, left: 0 }}
                >
                  <CartesianGrid strokeDasharray="0.1" vertical={false} />
                  <YAxis type="number" tickCount={12} tickLine={false} axisLine={false} tick={<CustomizedXAxisTick/>} />

                  <XAxis
                    type="category"
                    dataKey="date"
                    interval={0}
                    tickLine={false}
                    axisLine={false}
                    tick={<CustomizedXAxisTick/>}
                  />

                  <Tooltip cursor={{fill: 'transparent'}} content={<CustomToolTip />}/>

                  {activeTab === "all" ? 
                  <>
                    <Bar dataKey={t("chart_type_safes_balance")} stackId="a" barSize={20} fill="#59A0FF" radius={[0, 0, 0, 0]} />
                    <Bar dataKey={t("chart_type_documents")} stackId="a" barSize={20} fill="#FF805C" radius={[0, 0, 0, 0]} />
                    <Bar dataKey={t("chart_type_withdrawals")} stackId="a" barSize={20} fill="#FFCD86" radius={[0, 0, 0, 0]} />
                    <Bar dataKey={t("chart_type_deposits")} stackId="a" barSize={20} fill="#42EBB3" radius={[0, 0, 0, 0]} />
                  </>
                  :
                  <>
                    {currentSafes.length > 0 ? 
                    currentSafes.map(safe => <Bar key={safe.id} dataKey={`${safe.name}`} stackId="a" fill="#8884d8" barSize={20} radius={[0, 0, 0, 0]}>
                      {data.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={data[index].colors[`${safe.name}`]} />
                      ))}
                    </Bar>)
                    : <Bar dataKey={t("all_safes")} stackId="a" barSize={20} radius={[0, 0, 0, 0]}>
                      {data.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={data[index].colors[t("all_safes")]} />
                      ))}
                    </Bar>}
                  </>
                  }

                </BarChart>
              </StyledDataResponsiveContainer>
            </div>

            {/* YAxis表示用 */}
            <StyledYAxisResponsiveContainer width={`${DEFAULT_WIDTH}%`}>
              <BarChart
                data={data}
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              >
                <XAxis tickLine={false} axisLine={false} />
                {activeTab === "all" ? 
                  <>
                    <Bar dataKey={t("chart_type_safes_balance")} stackId="a" barSize={20} fill="#59A0FF" radius={[0, 0, 0, 0]} />
                    <Bar dataKey={t("chart_type_documents")} stackId="a" barSize={20} fill="#FF805C" radius={[0, 0, 0, 0]} />
                    <Bar dataKey={t("chart_type_withdrawals")} stackId="a" barSize={20} fill="#FFCD86" radius={[0, 0, 0, 0]} />
                    <Bar dataKey={t("chart_type_deposits")} stackId="a" barSize={20} fill="#42EBB3" radius={[0, 0, 0, 0]} />
                  </>
                  :
                  <>
                    {currentSafes.length > 0 ? 
                    currentSafes.map(safe => <Bar key={safe.id} dataKey={`${safe.name}`} stackId="a" fill="#8884d8" barSize={20} radius={[0, 0, 0, 0]}>
                      {data.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={data[index].colors[`${safe.name}`]} />
                      ))}
                    </Bar>)
                    : <Bar dataKey={t("all_safes")} stackId="a" barSize={20} radius={[0, 0, 0, 0]}>
                      {data.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={data[index].colors[t("all_safes")]} />
                      ))}
                    </Bar>}
                  </>
                  }
                <YAxis type="number" tickCount={12} axisLine={false} tickLine={false} tick={<CustomizedYAxisTick />} />
              </BarChart>
            </StyledYAxisResponsiveContainer>
          </div>
        </div>
      </GridLayout.Responsive>
    </div>
  );
}
