import {useEffect, useState} from "react";
import { Menu } from "@headlessui/react";
import {useHistory} from "react-router-dom";
import { CardReceiveSvg } from "../resources/card receive svg/CardReceiveSvg";
import { CardSendSvg } from "../resources/card send svg/CardSendSvg";
import { CheckSvg } from "../resources/check svg/CheckSvg";
import { CancelSvg } from "../resources/cancel svg/CancelSvg";
import { UserSvg } from "../resources/user svg/UserSvg";
import { MoneySvg } from "../resources/money svg/MoneySvg";
import { ForwardSvg } from "../resources/forward svg/ForwardSvg";
import { formatDate } from '../../utils/convertDate';
import "./Panel.scss";
import useFetchUserData from "../../hooks/reactQuery/useFetchUserData";
import { useQueryClient, useMutation } from "react-query";
import apiClient from "../../services/apiClient";
import ActiveDeactive from "../resources/icons/ActiveDeactive";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NotificationsCard({ notification, checked, setChecked }) {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();

  const types = [
    {
      type: "SPEND_REQUEST",
      title: t("notification_type_SPEND_REQUEST"),
      buildString: (data) => {
        return t("notification_type_SPEND_REQUEST_TEXT", {user_name: data.creator_name});
        //return(`تم ارسال اذن صرف اليك من قبل ${data.creator_name}`);
      },
      click: (history, data, safeId, callback) => {
        history.push({
          pathname: `/safes/${safeId}/spend_request/${data.spend_request_id}`,
          state: { comingFrom: t("notifications"), fallback: "/", title: t("spend_request")}
        });
        callback();
      },
      icon: <MoneySvg />,
      iconBackground: "bg-blue-500",
    },
    {
      type: "ACCEPT_SPEND_REQUEST",
      title: t("notification_type_ACCEPT_SPEND_REQUEST"),
      buildString: (data) => {
        if(data.recipient_id === data.affected_id) 
          return t("notification_type_ACCEPT_SPEND_REQUEST_TEXT", {user_name: data.creator_name});
          //return(`تم قبول طلبك باذن صرف من قبل ${data.creator_name}`);
        else
          return t("notification_type_ACCEPT_FORWARD_SPEND_REQUEST_TEXT_OTHER", {affected_name: data.affected_name, creator_name: data.creator_name});
          //return(`تم قبول طلب ${data.affected_name} باذن صرف من قبل ${data.creator_name}`);
      },
      click: (history, data, safeId, callback) => {
        history.push({
          pathname: `/safes/${safeId}/spend_request/${data.spend_request_id}`,
          state: { comingFrom: t("notifications"), fallback: "/", title: t("spend_request")}
        });
        callback();
      },
      icon: <CheckSvg />,
      iconBackground: "bg-green-500",
    },
    {
      type: "REJECT_SPEND_REQUEST",
      title: t("notification_type_REJECT_SPEND_REQUEST"),
      buildString: (data) => {
        if(data.recipient_id === data.affected_id) 
          return t("notification_type_REJECT_SPEND_REQUEST_TEXT", {user_name: data.creator_name});
          //return(`تم رفض طلبك باذن صرف من قبل ${data.creator_name}`);
        else 
          return t("notification_type_REJECT_FORWARD_SPEND_REQUEST_TEXT_OTHER", {affected_name: data.affected_name, creator_name: data.creator_name});
          //return(`تم رفض طلب ${data.affected_name} باذن صرف من قبل ${data.creator_name}`);
      },
      click: (history, data, safeId, callback) => {
        history.push({
          pathname: `/safes/${safeId}/spend_request/${data.spend_request_id}`,
          state: { comingFrom: t("notifications"), fallback: "/", title: t("spend_request")}
        });
        callback();
      },
      icon: <CancelSvg />,
      iconBackground: "bg-red-500",
    },
    {
      type: "REJECT_MONEY_REQUEST",
      title: t("notification_type_REJECT_MONEY_REQUEST"),
      buildString: (data) => {
        return t("notification_type_REJECT_MONEY_REQUEST_TEXT", {user_name: data.creator_name});
        //return(`تم رفض طلبك بعهدة مالية من قبل ${data.creator_name}`);
      },
      click: (history, data, safeId, callback) => {
        history.push({
          pathname: `/safes/${safeId}/money_request/${data.money_request_id}`,
          state: { comingFrom: t("notifications"), fallback: "/", title: t("money_request")}
        });
        callback();
      },
      icon: <CancelSvg />,
      iconBackground: "bg-red-500",
    },
    {
      type: "ACCEPT_FORWARD_SPEND_REQUEST",
      title: t("notification_type_ACCEPT_FORWARD_SPEND_REQUEST"),
      buildString: (data) => {
        if(data.recipient_id === data.affected_id)
          return t("notification_type_ACCEPT_FORWARD_SPEND_REQUEST_TEXT", {user_name: data.creator_name});
          //return(`تم قبول طلبك باذن صرف من قبل ${data.creator_name}`);
        else
          return t("notification_type_ACCEPT_FORWARD_SPEND_REQUEST_TEXT_OTHER", {affected_name: data.affected_name, creator_name: data.creator_name});
          //return(`تم قبول طلب ${data.affected_name} باذن صرف من قبل ${data.creator_name}`);
      },
      click: (history, data, safeId, callback) => {
        history.push({
          pathname: `/safes/${safeId}/spend_request/${data.spend_request_id}`,
          state: { comingFrom: t("notifications"), fallback: "/", title: t("spend_request")}
        });
        callback();
      },
      icon: <CheckSvg />,
      iconBackground: "bg-green-500",
    },
    {
      type: "REJECT_FORWARD_SPEND_REQUEST",
      title: t("notification_type_REJECT_FORWARD_SPEND_REQUEST"),
      buildString: (data) => {
        if(data.recipient_id === data.affected_id)
          return t("notification_type_REJECT_FORWARD_SPEND_REQUEST_TEXT", {user_name: data.creator_name});
          //return(`تم رفض طلبك باذن صرف من قبل ${data.creator_name}`);
        else
          return t("notification_type_REJECT_FORWARD_SPEND_REQUEST_TEXT_OTHER", {affected_name: data.affected_name, creator_name: data.creator_name});
          //return(`تم رفض طلب ${data.affected_name} باذن صرف من قبل ${data.creator_name}`);
  
      },
      click: (history, data, safeId, callback) => {
        history.push({
          pathname: `/safes/${safeId}/spend_request/${data.spend_request_id}`,
          state: { comingFrom: t("notifications"), fallback: "/", title: t("spend_request")}
        });
        callback();
      },
      icon: <CancelSvg />,
      iconBackground: "bg-red-500",
    },
    {
      type: "FORWARD_SPEND_REQUEST",
      title: t("notification_type_FORWARD_SPEND_REQUEST"),
      buildString: (data) => {
        if(data.recipient_id === data.affected_id)
          return t("notification_type_FORWARD_SPEND_REQUEST_TEXT", {user_name: data.creator_name});
          //return(`تم اعادة توجية طلبك باذن صرف الى ${data.creator_name}`);
        else
          return t("notification_type_FORWARD_SPEND_REQUEST_TEXT_OTHER", {creator_name: data.creator_name, affected_name: data.affected_name});
          //return(`تم اعادة توجية طلب ${data.affected_name} باذن صرف من قبل ${data.creator_name}`);
      },
      click: (history, data, safeId, callback) => {
        history.push({
          pathname: `/safes/${safeId}/spend_request/${data.spend_request_id}`,
          state: { comingFrom: t("notifications"), fallback: "/", title: t("spend_request")}
        });
        callback();
      },
      icon: <ForwardSvg />,
      iconBackground: "bg-yellow-500",
    },
    {
      type: "DOCUMENT",
      title: t("notification_type_DOCUMENT"),
      buildString: (data) => {
        return t("notification_type_DOCUMENT_TEXT", {
          creator_name: data.creator_name,
          amount: data.document_amount,
          safe_name: data.safe_name
        });
        //return(`${data.creator_name} قام بتدوين مصروف بقيمة ${data.document_amount} من خزينة ${data.safe_name}`);
      },
      click: (history, data, safeId, callback) => {
        if(data.document_id) {
          history.push({
            pathname: `/safes/${safeId}/document/${data.document_id}`,
            state: { comingFrom: t("notifications"), fallback: "/", title: t("document")}
          });
        }
        callback();
      },
      icon: <MoneySvg />,
      iconBackground: "bg-blue-500",
    },
    {
      type: "DOCUMENT_FROM_SAFE",
      title: t("notification_type_DOCUMENT_FROM_SAFE"),
      buildString: (data) => {
        return t("notification_type_DOCUMENT_FROM_SAFE_TEXT", {
          creator_name: data.creator_name,
          amount: data.document_amount,
          safe_name: data.safe_name
        });
        //return(`${data.creator_name} قام بتدوين مصروف بقيمة ${data.document_amount} من خزينة ${data.safe_name}`);
      },
      click: (history, data, safeId, callback) => {
        if(data.document_id) {
          history.push({
            pathname: `/safes/${safeId}/document/${data.document_id}`,
            state: { comingFrom: t("notifications"), fallback: "/", title: t("document")}
          });
        }
        callback();
      },
      icon: <MoneySvg />,
      iconBackground: "bg-blue-500",
    },
    {
      type: "MONEY_REQUEST",
      title: t("notification_type_MONEY_REQUEST"),
      buildString: (data) => {
        return t("notification_type_MONEY_REQUEST_TEXT", {user_name: data.creator_name});
        //return(`${data.creator_name} ارسل اليك طلب عهدة`);
      },
      click: (history, data, safeId, callback) => {
        history.push({
          pathname: `/safes/${safeId}/money_request/${data.money_request_id}`,
          state: { comingFrom: t("notifications"), fallback: "/", title: t("money_request")}
        });
        callback();
      },
      icon: <MoneySvg />,
      iconBackground: "bg-blue-500",
    },
    {
      type: "ACCEPT_MONEY_REQUEST",
      title: t("notification_type_ACCEPT_MONEY_REQUEST"),
      buildString: (data) => {
        if(data.recipient_id === data.affected_id)
          return("notification_type_ACCEPT_MONEY_REQUEST_TEXT", {user_name: data.creator_name});
          //return(`تم قبول طلبك للعهدة من قبل ${data.creator_name}`);
        else 
          return("notification_type_ACCEPT_MONEY_REQUEST_TEXT_OTHER", {affected_name: data.affected_name, creator_name: data.creator_name});
          //return(`تم قبول طلب ${data.affected_name} للعهدة من قبل ${data.creator_name}`);
      },
      click: (history, data, safeId, callback) => {
        history.push({
          pathname: `/safes/${safeId}/money_request/${data.money_request_id}`,
          state: { comingFrom: t("notifications"), fallback: "/", title: t("money_request")}
        });
        callback();
      },
      icon: <CheckSvg />,
      iconBackground: "bg-green-500",
    },
    {
      type: "ACCEPT_MONEY_REQUEST_FROM_SAFE",
      title: t("notification_type_ACCEPT_MONEY_REQUEST_FROM_SAFE"),
      buildString: (data) => {
        if(data.recipient_id === data.affected_id)
          return t("notification_type_ACCEPT_MONEY_REQUEST_FROM_SAFE_TEXT", {user_name: data.creator_name});
          //return(`تم قبول طلبك للعهدة من قبل ${data.creator_name}`);
        else 
          return t("notification_type_ACCEPT_MONEY_REQUEST_FROM_SAFE_TEXT_OTHER", {
            affected_name: data.affected_name, 
            creator_name: data.creator_name,
            safe_name: data.safe_name
          });  
          //return(`تم قبول طلب ${data.affected_name} للعهدة من قبل ${data.creator_name} من خزينة ${data.safe_name}`);
      },
      click: (history, data, safeId, callback) => {
        history.push({
          pathname: `/safes/${safeId}/money_request/${data.money_request_id}`,
          state: { comingFrom: t("notifications"), fallback: "/", title: t("money_request")}
        });
        callback();
      },
      icon: <CheckSvg />,
      iconBackground: "bg-green-500",
    },
    {
      type: "REJECT_FORWARD_MONEY_REQUEST",
      title: t("notification_type_REJECT_FORWARD_MONEY_REQUEST"),
      buildString: (data) => {
        if(data.recipient_id === data.affected_id)
          return t("notification_type_REJECT_FORWARD_MONEY_REQUEST_TEXT", {user_name: data.creator_name});
          //return(`تم رفض طلبك للعهدة من قبل ${data.creator_name}`);
        else
          return t("notification_type_REJECT_FORWARD_MONEY_REQUEST_TEXT_OTHER", {
            creator_name: data.creator_name,
            affected_name: data.affected_name

          });
          //return(`تم رفض طلب ${data.affected_name} للعهدة من قبل ${data.creator_name}`);
      },
      click: (history, data, safeId, callback) => {
        history.push({
          pathname: `/safes/${safeId}/money_request/${data.money_request_id}`,
          state: { comingFrom: t("notifications"), fallback: "/", title: t("money_request")}
        });
        callback();
      },
      icon: <CancelSvg />,
      iconBackground: "bg-red-500",
    },
    {
      type: "FORWARD_MONEY_REQUEST",
      title: t("notification_type_FORWARD_MONEY_REQUEST"),
      buildString: (data) => {
        if(data.recipient_id === data.affected_id)
          return t("notification_type_FORWARD_MONEY_REQUEST_TEXT", {user_name: data.creator_name});
          //return(`تم اعادة توجية طلبك بالعهدة المالية من قبل ${data.creator_name}`);
        else 
          return t("notification_type_FORWARD_MONEY_REQUEST_TEXT_OTHER", {
            affected_name: data.affected_name,
            creator_name: data.creator_name
          });
          //return(`تم اعادة توجية طلب ${data.affected_name} بالعهدة المالية من قبل ${data.creator_name}`);
      },
      click: (history, data, safeId, callback) => {
        history.push({
          pathname: `/safes/${safeId}/money_request/${data.money_request_id}`,
          state: { comingFrom: t("notifications"), fallback: "/", title: t("money_request")}
        });
        callback();
      },
      icon: <ForwardSvg />,
      iconBackground: "bg-yellow-500",
    },
    {
      type: "SETTLED_MONEY_REQUEST",
      title: t("notification_type_SETTLED_MONEY_REQUEST"),
      buildString: (data) => {
        const amount = new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.money_request_amount);
        return t("notification_type_SETTLED_MONEY_REQUEST_TEXT", {
          amount, 
          creator_name: data.creator_name,
          safe_name: data.safe_name
        });
        //return(`${data.creator_name} قام بتصفية عهدة مالية بقيمة ${new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.money_request_amount)} من خزينة ${data.safe_name}`);
      },
      click: (history, data, safeId, callback) => {
        history.push({
          pathname: `/safes/${safeId}/money_request/${data.money_request_id}`,
          state: { comingFrom: t("notifications"), fallback: "/", title: t("money_request")}
        });
        callback();
      },
      icon: <MoneySvg />,
      iconBackground: "bg-blue-500",
    },
    {
      type: "ACCEPT_CASHOUT",
      title: t("notification_type_ACCEPT_CASHOUT"),
      buildString: (data) => {
        if(data.recipient_id === data.affected_id) {
          const amount = new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.money_request_amount);
          return t("notification_type_ACCEPT_CASHOUT_TEXT", {
            amount,
            creator_name: data.creator_name,
            safe_name: data.safe_name
          });
          //return(`تم قبول طلبك بسحب ${new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.money_request_amount)} من خزينة ${data.safe_name} من قبل ${data.creator_name}`);
        }
        else {
          const amount = new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.money_request_amount);
          return t("notification_type_ACCEPT_CASHOUT_TEXT_OTHER", {
            amount,
            creator_name: data.creator_name,
            safe_name: data.safe_name,
            affected_name: data.affected_name
          });
          //return(`تم قبول طلب ${data.affected_name} بسحب ${new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.money_request_amount)} من خزينة ${data.safe_name} من قبل ${data.creator_name}`);
        }
      },
      click: (history, data, safeId, callback) => {
        history.push({
          pathname: `/safes/${safeId}/money_request/${data.money_request_id}`,
          state: { comingFrom: t("notifications"), fallback: "/", title: t("money_request")}
        });
        callback();
      },
      icon: <CheckSvg />,
      iconBackground: "bg-green-500",
    },
    {
      type: "REJECT_CASHOUT",
      title: t("notification_type_REJECT_CASHOUT"),
      buildString: (data) => {
        if(data.recipient_id === data.affected_id) {
          const amount = new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.money_request_amount);
          return t("notification_type_REJECT_CASHOUT_TEXT", {
            amount, 
            creator_name: data.creator_name,
            safe_name: data.safe_name
          });
          //return(`تم رفض طلبك بسحب ${new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.money_request_amount)} من خزينة ${data.safe_name} من قبل ${data.creator_name}`);
        }
        else {
          const amount = new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.money_request_amount);
          return t("notification_type_REJECT_CASHOUT_TEXT", {
            amount, 
            creator_name: data.creator_name,
            safe_name: data.safe_name,
            affected_name: data.affected_name
          });
          //return(`تم رفض طلب ${data.affected_name} بسحب ${new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.money_request_amount)} من خزينة ${data.safe_name} من قبل ${data.creator_name}`);
        }
      },
      click: (history, data, safeId, callback) => {
        history.push({
          pathname: `/safes/${safeId}/money_request/${data.money_request_id}`,
          state: { comingFrom: t("notifications"), fallback: "/", title: t("money_request")}
        });
        callback();
      },
      icon: <CancelSvg />,
      iconBackground: "bg-red-500",
    },
    {
      type: "CASHIN_WITHOUT_PERMISSION",
      title: t("notification_type_CASHIN_WITHOUT_PERMISSION"),
      buildString: (data) => {
        const amount = new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.cashin_amount);
        return t("notification_type_CASHIN_WITHOUT_PERMISSION_TEXT", {
          amount,
          creator_name: data.creator_name,
          safe_name: data.safe_name
        })
        //return(`${data.creator_name} اجرى عملية ايداع مالي بقيمة ${new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.cashin_amount)} في خزينة ${data.safe_name}`)
      },
      click: (history, data, safeId, callback) => {
        history.push({
          pathname: `/safes/${safeId}/cashin/${data.cashin_id}`,
          state: { comingFrom: t("notifications"), fallback: "/", title: t("cashin_without_permission")}
        });
        callback();
      },
      icon: <CardReceiveSvg />,
      iconBackground: "bg-blue-500",
    },
    {
      type: "CASHIN_WITH_PERMISSION",
      title: t("notification_type_CASHIN_WITH_PERMISSION"),
      buildString: (data) => {
        const amount = new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.cashin_amount);
        return t("notification_type_CASHIN_WITH_PERMISSION_TEXT", {
          amount,
          creator_name: data.creator_name,
          safe_name: data.safe_name
        });
        //return(`${data.creator_name} طلب ايداع مالي بقيمة ${new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.cashin_amount)} من خزينة ${data.safe_name}`)
      },
      click: (history, data, safeId, callback) => {
        history.push({
          pathname: `/safes/${safeId}/cashin/${data.cashin_id}`,
          state: { comingFrom: t("notifications"), fallback: "/", title: t("cashin_with_permission")}
        });
        callback();
      },
      icon: <CardReceiveSvg />,
      iconBackground: "bg-blue-500",
    },
    {
      type: "ACCEPT_CASHIN",
      title: t("notification_type_ACCEPT_CASHIN"),
      buildString: (data) => {
        if(data.recipient_id === data.affected_id) {
          const amount = new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.cashin_amount);
          return t("notification_type_ACCEPT_CASHIN_TEXT", {
            amount,
            creator_name: data.creator_name,
            safe_name: data.safe_name
          });
          //return(`تم قبول طلبك بايداع ${new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.cashin_amount)} من خزينة ${data.safe_name} من قبل ${data.creator_name}`);
        }
        else {
          const amount = new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.cashin_amount);
          return t("notification_type_ACCEPT_CASHIN_TEXT_OTHER", {
            amount, 
            creator_name: data.creator_name,
            safe_name: data.safe_name,
            affected_name: data.affected_name
          });
          //return(`تم قبول طلب ${data.affected_name} بايداع ${new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.cashin_amount)} من خزينة ${data.safe_name} من قبل ${data.creator_name}`);
        }
      },
      click: (history, data, safeId, callback) => {
        history.push({
          pathname: `/safes/${safeId}/cashin/${data.cashin_id}`,
          state: { comingFrom: t("notifications"), fallback: "/", title: t("cashin_with_permission")}
        });
        callback();
      },
      icon: <CheckSvg />,
      iconBackground: "bg-green-500",
    },
    {
      type: "REJECT_CASHIN",
      title: t("notification_type_REJECT_CASHIN"),
      buildString: (data) => {
        if(data.recipient_id === data.affected_id) {
          const amount = new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.cashin_amount);
          return t("notification_type_REJECT_CASHIN_TEXT", {
            amount,
            creator_name: data.creator_name,
            safe_name: data.safe_name
          });
          //return(`تم رفض طلبك بايداع ${new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.cashin_amount)} من خزينة ${data.safe_name} من قبل ${data.creator_name}`);
        }
        else {
          const amount = new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.cashin_amount);
          return t("notification_type_REJECT_CASHIN_TEXT_OTHER", {
            amount, 
            creator_name: data.creator_name,
            safe_name: data.safe_name,
            affected_name: data.affected_name
          });
          //return(`تم رفض طلب ${data.affected_name} بايداع ${new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.cashin_amount)} من خزينة ${data.safe_name} من قبل ${data.creator_name}`);
        }
      },
      click: (history, data, safeId, callback) => {
        history.push({
          pathname: `/safes/${safeId}/cashin/${data.cashin_id}`,
          state: { comingFrom: t("notifications"), fallback: "/", title: t("cashin_with_permission")}
        });
        callback();
      },
      icon: <CancelSvg />,
      iconBackground: "bg-red-500",
    },
    {
      type: "CASHOUT_WITH_PERMISSION",
      title: t("notification_type_CASHOUT_WITH_PERMISSION"),
      buildString: (data) => {
        const amount = new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.money_request_amount);
        return t("notification_type_CASHOUT_WITH_PERMISSION_TEXT", {
          amount, 
          creator_name: data.creator_name,
          safe_name: data.safe_name
        });
        //return(`${data.creator_name} طلب سحب مالي بقيمة ${new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.money_request_amount)} من خزينة ${data.safe_name}`)
      },
      click: (history, data, safeId, callback) => {
        history.push({
          pathname: `/safes/${safeId}/money_request/${data.money_request_id}`,
          state: { comingFrom: t("notifications"), fallback: "/", title: t("cashout_with_permission")}
        });
        callback();
      },
      icon: <CardSendSvg />,
      iconBackground: "bg-blue-500",
    },
    {
      type: "CASHOUT_WITHOUT_PERMISSION",
      title: t("notification_type_CASHOUT_WITHOUT_PERMISSION"),
      buildString: (data) => {
        const amount = new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.money_request_amount);
        return t("notification_type_CASHOUT_WITHOUT_PERMISSION_TEXT", {
          amount, 
          creator_name: data.creator_name,
          safe_name: data.safe_name
        });
        //return(`${data.creator_name} طلب سحب مالي بقيمة ${new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.money_request_amount)} في خزينة ${data.safe_name}`)
      },
      click: (history, data, safeId, callback) => {
        history.push({
          pathname: `/safes/${safeId}/money_request/${data.money_request_id}`,
          state: { comingFrom: t("notifications"), fallback: "/", title: t("cashout_without_permission")}
        });
        callback();
      },
      icon: <CardSendSvg />,
      iconBackground: "bg-blue-500",
    },
    {
      type: "CREATE_SAFE",
      title: t("notification_type_CREATE_SAFE"),
      buildString: (data) => {
        return t("notification_type_CREATE_SAFE_TEXT", {creator_name: data.creator_name, safe_name: data.safe_name});
        //return(`تم انشاء خزينة جديدة (${data.safe_name}) من قبل ${data.creator_name}`);
      },
      click: (history, data, safeId, callback) => {
        history.push(`/safe/${safeId}`);
        callback();
      },
      icon: <MoneySvg />,
      iconBackground: "bg-blue-500",
    },
    {
      type: "CREATE_DEFUALT_SAFE",
      title: t("notification_type_CREATE_DEFAULT_SAFE"),
      buildString: (data) => {
        return t("notification_type_CREATE_DEFAULT_SAFE_TEXT", {creator_name: data.creator_name, safe_name: data.safe_name});
        //return(`تم انشاء خزينة جديدة (${data.safe_name}) من قبل ${data.creator_name}`);
      },
      click: (history, data, safeId, callback) => {
        history.push(`/settings?tab=6&activeSafeId=${data.safe_id}`);
        callback();
      },
      icon: <MoneySvg />,
      iconBackground: "bg-blue-500",
    },
    {
      type: "EDIT_SAFE",
      title: t("notification_type_EDIT_SAFE"),
      buildString: (data) => {
        return t("notification_type_EDIT_SAFE_TEXT", {
          creator_name: data.creator_name,
          safe_name: data.safe_name
        });
        //return(`تم تعديل خزينة (${data.safe_name}) من قبل ${data.creator_name}`);
      },
      click: (history, data, safeId, callback) => {
        history.push(`/settings?tab=6&activeSafeId=${data.safe_id}`);
        callback();
      },
      icon: <MoneySvg />,
      iconBackground: "bg-blue-500",
    },
    {
      type: "ADD_SAFE_MEMBER",
      title: t("notification_type_ADD_SAFE_MEMBER"),
      buildString: (data) => {
        if(data.affected_id === data.recipient_id) {
          return t("notification_type_ADD_SAFE_MEMBER_TEXT", {safe_name: data.safe_name});
        }
        else {
          return t("notification_type_ADD_SAFE_MEMBER_TEXT_OTHER", {safe_name: data.safe_name, affected_name: data.affected_name});
        }
        //const str = data.affected_id === data.recipient_id ? (`تم اضافتك كعضو جديد بخزينة ${data.safe_name}`) : (`تم اضافة ${data.affected_name} كعضو جديد بخزينة ${data.safe_name}`);
        //return str;
      },
      click: (history, data, safeId, callback) => {
        history.push(`/settings?tab=6&activeSafeId=${data.safe_id}`);
        callback();
      },
      icon: <UserSvg />,
      iconBackground: "bg-blue-500",
    },
    {
      type: "ADD_ROLE",
      title: t("notification_type_ADD_ROLE"),
      buildString: (data) => {
        return t("notification_type_ADD_ROLE_TEXT", {role_name: data.role_name, creator_name: data.creator_name});
        //return(`تم اضافة دور جديد (${data.role_name}) من قبل ${data.creator_name}`);
      },
      click: (history, data, safeId, callback) => {
        history.push(`/settings?tab=5&activeRoleId=${data.role_id}`);
        callback();
      },
      icon: <UserSvg />,
      iconBackground: "bg-blue-500",
    },
    {
      type: "EDIT_ROLE",
      title: t("notification_type_EDIT_ROLE"),
      buildString: (data) => {
        return t("notification_type_EDIT_ROLE_TEXT", {role_name: data.role_name, creator_name: data.creator_name});
        //return(`تم تعديل الدور (${data.role_name}) من قبل ${data.creator_name}`);
      },
      click: (history, data, safeId, callback) => {
        history.push(`/settings?tab=5&activeRoleId=${data.role_id}`);
        callback();
      },
      icon: <UserSvg />,
      iconBackground: "bg-blue-500",
    },
    {
      type: "DEACTIVATE_ROLE",
      title: t("notification_type_DEACTIVATE_ROLE"),
      buildString: (data) => {
        return t("notification_type_DEACTIVATE_ROLE_TEXT", {role_name: data.role_name, creator_name: data.creator_name});
        //return(`تم تعطيل الدور (${data.role_name}) من قبل ${data.creator_name}`);
      },
      click: (history, data, safeId, callback) => {
        history.push(`/settings?tab=5&activeRoleId=${data.role_id}`);
        callback();
      },
      icon: <UserSvg />,
      iconBackground: "bg-blue-500",
    },
    {
      type: "EDIT_WORKSPACE",
      title: t("notification_type_EDIT_WORKSPACE"),
      buildString: (data) => {
        return t("notification_type_EDIT_WORKSPACE_TEXT", {creator_name: data.creator_name});
        //return(`تم تعديل مساحة العمل من قبل ${data.creator_name}`);
      },
      click: (history, data, safeId, callback) => {
        history.push(`/settings?tab=2`);
        callback();
      },
      icon: <UserSvg />,
      iconBackground: "bg-blue-500",
    },
    {
      type: "ADD_USER_TO_WORKSPACE",
      title: t("notification_type_ADD_USER_TO_WORKSPACE"),
      buildString: (data) => {
        return t("notification_type_ADD_USER_TO_WORKSPACE_TEXT", {user_name: data.affected_name});
        //return(`تم اضافة (${data.affected_name}) كعضو جديد بمساحة العمل`);
      },
      click: (history, data, safeId, callback) => {
        history.push(`/settings?tab=3`);
        callback();
      },
      icon: <UserSvg />,
      iconBackground: "bg-blue-500",
    },
    {
      type: "ACCEPT_USER_INVITATION",
      title: t("notification_type_ACCEPT_USER_INVITATION"),
      buildString: (data) => {
        return t("notification_type_ACCEPT_USER_INVITATION_TEXT", {user_name: data.creator_name});
        //return(`تم قبول دعوتك ل (${data.creator_name}) كعضو جديد بمساحة العمل`);
      },
      click: (history, data, safeId, callback) => {
        history.push(`/settings?tab=3`);
        callback();
      },
      icon: <UserSvg />,
      iconBackground: "bg-blue-500",
    },
    {
      type: "HOLD_SPEND_REQUEST",
      title: t("notification_type_HOLD_SPEND_REQUEST"),
      buildString: (data) => {
        return t("notification_type_HOLD_SPEND_REQUEST_TEXT", {amount: data.spend_request_amount, user_name: data.creator_name});
        //return(`الموظف (${data.creator_name}) حجب عملية اذن صرف بقيمة ${data.spend_request_amount} ج.م`);
      },
      click: (history, data, safeId, callback) => {
        history.push(`/safes/${safeId}/spend_request/${data.spend_request_id}`);
        callback();
      },
      icon: <ActiveDeactive active={1} fill="#fff" />,
      iconBackground: "bg-blue-500",
    },
    {
      type: "HOLD_MONEY_REQUEST",
      title: t("notification_type_HOLD_MONEY_REQUEST"),
      buildString: (data) => {
        return t("notification_type_HOLD_MONEY_REQUEST_TEXT", {
          user_name: data.creator_name,
          amount: data.money_request_amount,
          source: data.parent_request_id ? t("your_custody") : data.safe_name
        });
        //return(`الموظف (${data.creator_name}) حجب عملية عهدة مالية بقيمة ${data.money_request_amount} ج.م وتم رد مبلغ العهدة الاصلي ${data.parent_request_id ? "عهدتك" : data.safe_name}`);
      },
      click: (history, data, safeId, callback) => {
        history.push(`/safes/${safeId}/spend_request/${data.spend_request_id}`);
        callback();
      },
      icon: <ActiveDeactive active={1} fill="#fff" />,
      iconBackground: "bg-blue-500",
    }
  ];

  const currentType = types.find(type => type.type === notification.type);
  const history = useHistory();

  const [viewed, setViewed] = useState(notification.view === 0 ? false : true);
  const queryClient = useQueryClient();

  const viewMutation = useMutation(
    (body) => apiClient.put("/user/notifications/view", body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("notifications");
      },
    }
  );

  useEffect(() => {
    if(!checked) setChecked(true);
    console.log(currentType);
  }, []);

  return (
    currentType ? <Menu.Item>
      <li
        key={notification.id}
        className={`notifications-card ${!viewed ? "not-viewed-notification" : ""}`}
        onClick={() => currentType.click(
          history, 
          notification, 
          notification.safe_id ? notification.safe_id : userData.safes[0].id,
          () => viewMutation.mutate({id: notification.id})
        )}
      >
        <div className="relative pb-8">
          <div className="relative flex space-x-3 mx-3">
            <div>
              <span
                className={classNames(
                  currentType.iconBackground,
                  "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                )}
              >
                {currentType.icon}
              </span>
            </div>
            <div className="flex-1 space-y-1">
              <div className="flex items-center justify-between">
                <h3 className="text-sm font-medium mr-2">
                  {currentType.title}
                </h3>
                <p className="text-sm text-gray-500">
                  {formatDate(notification.created_at)}
                </p>
              </div>
              <p className={`text-sm text-gray-500 ${i18n.resolvedLanguage === "en" ? "text-left" : ""}`}>
                {currentType.buildString(notification)}
              </p>
            </div>
          </div>
        </div>
      </li>
    </Menu.Item> : null
  );
}
