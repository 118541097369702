import UserSettings from "../../components/settings/UserSettings";
import CompanySettings from "../../components/settings/CompanySettings";
import RolesSettings from "../../components/settings/RolesSettings";

import { useDataLayerValue } from "../../context/DataLayer";

import { useState, useEffect, useMemo, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import UserIcon from "../../components/resources/icons/UserIcon";
import ShareIcon from "../../components/resources/icons/ShareIcon";
import OfficeBuildingIcon from "../../components/resources/icons/OfficeBuildingIcon";
import PeopleIcon from "../../components/resources/icons/PeopleIcon";
import DatabaseIcon from "../../components/resources/icons/DatabaseIcon";
import RolesIcon from "../../components/resources/icons/RolesIcon";
import SafesIcon from "../../components/resources/icons/SafesIcon";
import "./Settings.scss";
import DevelopmentSection from "../../components/common/TableResults/DevelopmentSection";
import Members from "../../components/settings/Members";

import useFetchUserData from "../../hooks/reactQuery/useFetchUserData";
import SafesSettings from "../../components/settings/SafesSettings";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Settings = ({}) => {
  const {t, i18n} = useTranslation();
  const [{ activeTab }] = useDataLayerValue();

  const { search } = useLocation();
  const tab = new URLSearchParams(search).get("tab");

  const [subActiveTab, setSubActiveTab] = useState(tab ? parseInt(tab) : 0);

  const { data: userData } = useFetchUserData();

  const tabs = [
    {
      id: 0,
      title: t("settings_tabs_account"),
      icon: UserIcon,
      component: <UserSettings />,
      hasPermission: true,
    },
    {
      id: 1,
      title: t("settings_tabs_system"),
      icon: ShareIcon,
      component: (
        <div className="mt-12">
          {" "}
          <DevelopmentSection />
        </div>
      ),
      hasPermission: true,
    },
    {
      id: 2,
      title: t("settings_tabs_workspace"),
      icon: OfficeBuildingIcon,
      component: <CompanySettings />,
      hasPermission:
        userData.permission_tags.includes("CreateWorkspace") ||
        userData.permission_tags.includes("EditWorkspace") ||
        userData.permission_tags.includes("DeactivateWorkspace"),
    },
    {
      id: 3,
      title: t("settings_tabs_team"),
      icon: PeopleIcon,
      component: <Members />,
      hasPermission:
        userData.permission_tags.includes("AddMember") ||
        userData.permission_tags.includes("DeactivateMember") ||
        userData.permission_tags.includes("EditMemberRole") ||
        userData.permission_tags.includes("ViewMembers"),
    },
    {
      id: 4,
      title: t("settings_tabs_categories"),
      icon: DatabaseIcon,
      component: (
        <div className="mt-12">
          {" "}
          <DevelopmentSection />
        </div>
      ),
      hasPermission:
        userData.permission_tags.includes("EditCategories") ||
        userData.permission_tags.includes("ViewCategory"),
    },
    {
      id: 5,
      title: t("settings_tabs_roles"),
      icon: RolesIcon,
      component: <RolesSettings />,
      hasPermission:
        userData.permission_tags.includes("CreateRole") ||
        userData.permission_tags.includes("EditRole") ||
        userData.permission_tags.includes("DeactivateRole") ||
        userData.permission_tags.includes("ViewRoles"),
    },
    {
      id: 6,
      title: t("settings_tabs_safes"),
      icon: SafesIcon,
      component: <SafesSettings />,
      hasPermission:
        userData.permission_tags.includes("AddSafe") || userData.safes.length > 0
    },
  ];

  return (
    <div className="relative">
      <div className="grid place-items-center">
        <div className="flex tabs" aria-label="Tabs">
          {tabs?.map((tab) =>
            tab.hasPermission ? (
              <div
                class={`flex-none w-auto tab ${
                  subActiveTab === tab.id ? "active" : ""
                }`}
              >
                <button
                  name={"settingsTabs" + tab.id}
                  type="button"
                  className={` px-3 py-2 m-auto text-base ${
                    subActiveTab === tab.id
                      ? "bg-white-light border-b-2 border-secondary"
                      : "text-gray-400 font-normal"
                  }`}
                  onClick={() => setSubActiveTab(tab.id)}
                >
                  <div class="flex flex-row-reverse fill-current">
                    <div
                      className={`mx-2 text-base font-medium ${
                        subActiveTab === tab.id
                          ? "text-secondary"
                          : "text-hints"
                      }`}
                    >
                      {tab.title}
                    </div>
                    <div>
                      <tab.icon
                        fillColor={`${
                          subActiveTab === tab.id ? "#3B82F6" : "#6B7280"
                        }`}
                      />
                    </div>
                  </div>
                </button>
              </div>
            ) : null
          )}
        </div>
      </div>
      <hr></hr>
      <div>{tabs?.find((tab) => tab.id === subActiveTab)?.component}</div>
    </div>
  );
};

export default Settings;
