import PropTypes from "prop-types";

function Icon(props) {
  const { className, strokeColor, ...restOfProps } = props;
  return (
    <svg
      width={20}
      height={17}
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...restOfProps}
    >
      <path
        d="M15.1562 5.23002V3.1531C15.1562 2.00604 14.2328 1.07617 13.0938 1.07617H2.78125C1.64216 1.07617 0.71875 2.00604 0.71875 3.1531V9.38386C0.71875 10.5309 1.64216 11.4608 2.78125 11.4608H4.84375M6.90625 15.6146H17.2188C18.3578 15.6146 19.2812 14.6848 19.2812 13.5377V7.30694C19.2812 6.15989 18.3578 5.23002 17.2188 5.23002H6.90625C5.76716 5.23002 4.84375 6.15989 4.84375 7.30694V13.5377C4.84375 14.6848 5.76716 15.6146 6.90625 15.6146ZM14.125 10.4223C14.125 11.5694 13.2016 12.4992 12.0625 12.4992C10.9234 12.4992 10 11.5694 10 10.4223C10 9.27527 10.9234 8.3454 12.0625 8.3454C13.2016 8.3454 14.125 9.27527 14.125 10.4223Z"
        stroke={strokeColor ? strokeColor : "#1E293B"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
};

export default Icon;
