import React from 'react';
import TableResult from '.';
import EmptyView from '../../resources/icons/EmptyView';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const EmptyData = ({ text, action, button, buutonText }) => {
  const history = useHistory();
  const {t, i18n} = useTranslation();
  return (
    <TableResult
      Icon={EmptyView}
      title={t("empty_page")}
      text={`${
        text
          ? text
          : t("empty_page_text")
        }`}
      button={button}
      buttonText={`${buutonText ? buutonText : t("create_document")}`}
      action={
        action
          ? action
          : () => {
            history.push('/payment-licenses')
          }
      }
    />
  )
}

export default EmptyData
