import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useMutatePoke({ userId, forward, row }) {
  const queryClient = useQueryClient();

  return useMutation(
    () => {
      const formData = new FormData();

      formData.append(
        "id",
        row.created_by === userId
          ? row.id
          : forward?.find(
              (req) => req.spend_request_id === row.id && req.from === userId
            ).id
      );
      formData.append("is_request", row.created_by === userId ? 1 : 0);

      formData.append("recipient_id", row.recipient_id);
      formData.append("user_id", userId);

      return apiClient.post("spend_request/poke", formData);
    },
    {
      onSuccess: () => {
        toast.info("تم النكز.", { autoClose: 3000 });
        queryClient.invalidateQueries("getPayments");
      },
      onError: (error) => {
        return error;
      },
    }
  );
}
