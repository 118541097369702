// import { useQuery } from "react-query";

// import apiClient from "../../services/apiClient";

// export default function useFetchPermissions() {
//   return useQuery("getPermissions", () =>
//     apiClient?.get("permission").then(({ data }) =>
//       data?.map((el) => {
//         if (el.id === 10) {
//           el.permissions = [
//             {
//               name: "صلاحيات الاطلاع فقط تتيح لصاحبها الاطلاع على بيانات البث الحي والخزنة والفريق. دون القدرة على التحكم بها",
//               read_only: 0,
//             },
//           ];
//         }
//         if (el.id === 11) {
//           el.permissions = [
//             {
//               name: "صلاحيات الاطلاع والتحكم تعني امتلاك صلاحية كاملة على كل خصائص النظام. وهي صلاحيات لا تكون عادةً إلا مع الطبقة العليا من المديرين.",
//             },
//           ];
//         }
//         return el;
//       })
//     )
//   );
// }

import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchPermissions(selectedRole, simRole) {
  const params = {};
  if (selectedRole?.id !== null && selectedRole?.id !== undefined) {
    params.role_id = selectedRole.id;
  }
  if (simRole?.id !== null && simRole?.id !== undefined) {
    params.role_id = simRole.id;
  }
  return useQuery(["getPermissions", selectedRole, simRole], () =>
    apiClient?.get("permission", { params }).then(({ data }) =>
      data?.map((el) => {
        if (el.tag === "ReadOnlyPermissions") {
          el.permissions = [
            {
              name: "صلاحيات الاطلاع فقط تتيح لصاحبها الاطلاع على بيانات البث الحي والخزنة والفريق. دون القدرة على التحكم بها",
              read_only: 0,
            },
          ];
        }
        if (el.tag === "ReadWritePermissions") {
          el.permissions = [
            {
              name: "صلاحيات الاطلاع والتحكم تعني امتلاك صلاحية كاملة على كل خصائص النظام. وهي صلاحيات لا تكون عادةً إلا مع الطبقة العليا من المديرين.",
            },
          ];
        }
        return el;
      })
    )
  );
}
