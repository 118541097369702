export const MoneySvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M14.1641 17.2916H7.08073C6.73906 17.2916 6.45573 17.0083 6.45573 16.6666C6.45573 16.325 6.73906 16.0416 7.08073 16.0416H14.1641C16.5474 16.0416 17.7057 14.8833 17.7057 12.5V7.49998C17.7057 5.11665 16.5474 3.95831 14.1641 3.95831H5.83073C3.4474 3.95831 2.28906 5.11665 2.28906 7.49998V12.6667C2.28906 13.0083 2.00573 13.2917 1.66406 13.2917C1.3224 13.2917 1.03906 13.0083 1.03906 12.6667V7.49998C1.03906 4.45831 2.78906 2.70831 5.83073 2.70831H14.1641C17.2057 2.70831 18.9557 4.45831 18.9557 7.49998V12.5C18.9557 15.5416 17.2057 17.2916 14.1641 17.2916Z"
        fill="white"
      />
      <path
        d="M9.9974 12.7084C8.50573 12.7084 7.28906 11.4917 7.28906 10C7.28906 8.50835 8.50573 7.29169 9.9974 7.29169C11.4891 7.29169 12.7057 8.50835 12.7057 10C12.7057 11.4917 11.4891 12.7084 9.9974 12.7084ZM9.9974 8.54169C9.1974 8.54169 8.53906 9.20002 8.53906 10C8.53906 10.8 9.1974 11.4584 9.9974 11.4584C10.7974 11.4584 11.4557 10.8 11.4557 10C11.4557 9.20002 10.7974 8.54169 9.9974 8.54169Z"
        fill="white"
      />
      <path
        d="M15.4141 12.7084C15.0724 12.7084 14.7891 12.425 14.7891 12.0834V7.91669C14.7891 7.57502 15.0724 7.29169 15.4141 7.29169C15.7557 7.29169 16.0391 7.57502 16.0391 7.91669V12.0834C16.0391 12.425 15.7557 12.7084 15.4141 12.7084Z"
        fill="white"
      />
      <path
        d="M4.16927 18.9584C2.78594 18.9584 1.48594 18.225 0.78594 17.0334C0.41094 16.4334 0.210938 15.725 0.210938 15C0.210938 12.8167 1.98594 11.0417 4.16927 11.0417C6.3526 11.0417 8.1276 12.8167 8.1276 15C8.1276 15.725 7.9276 16.4334 7.5526 17.0417C6.8526 18.225 5.5526 18.9584 4.16927 18.9584ZM4.16927 12.2917C2.6776 12.2917 1.46094 13.5084 1.46094 15C1.46094 15.4917 1.59425 15.975 1.85258 16.3917C2.33591 17.2083 3.2276 17.7084 4.16927 17.7084C5.11094 17.7084 6.00263 17.2083 6.48596 16.4C6.7443 15.975 6.8776 15.5 6.8776 15C6.8776 13.5084 5.66094 12.2917 4.16927 12.2917Z"
        fill="white"
      />
      <path
        d="M1.87294 17.9167C1.71461 17.9167 1.55625 17.8584 1.43125 17.7334C1.18958 17.4917 1.18958 17.0916 1.43125 16.85L6.01458 12.2666C6.25625 12.025 6.6563 12.025 6.89797 12.2666C7.13963 12.5083 7.13963 12.9084 6.89797 13.15L2.31463 17.7334C2.18963 17.8584 2.03127 17.9167 1.87294 17.9167Z"
        fill="white"
      />
    </svg>
  );
};
