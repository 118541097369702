const LockSvg = ({}) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={32} height={32} fill="white" />
      <path
        d="M16.2841 2.66675C13.1842 2.66675 10.6596 5.18334 10.6596 8.27687V13.4976H8V20.0741V21.0757C8 25.6311 11.7177 29.3334 16.2841 29.3334C20.8505 29.3334 24.5682 25.6275 24.5682 21.0757V20.9872V20.0741V13.4976H21.9086V8.27687C21.9122 5.18334 19.3876 2.66675 16.2841 2.66675ZM12.7404 8.27687C12.7404 6.3266 14.3312 4.7409 16.2877 4.7409C18.2442 4.7409 19.835 6.3266 19.835 8.27687V13.4976H12.7404V8.27687ZM22.5585 20.0741V20.9872V21.0757C22.5585 24.5232 19.7462 27.3301 16.2841 27.3301C12.822 27.3301 10.0133 24.5232 10.0133 21.0757V20.0741V15.501H22.5585V20.0741Z"
        fill="#343C44"
      />
      <path
        d="M16.3025 23.8846C14.6018 23.8846 13.2109 22.5423 13.2109 20.914C13.2109 19.2856 14.6018 17.9434 16.3025 17.9434C18.0032 17.9434 19.394 19.2856 19.394 20.914C19.394 22.5398 18.0032 23.8846 16.3025 23.8846Z"
        fill="#343C44"
      />
      <path
        d="M16.0197 21.8531H16.0001C15.8757 21.8531 15.7537 21.7919 15.6927 21.6893L15.1778 20.9484C15.0754 20.8042 15.1168 20.6184 15.2608 20.5157C15.4048 20.413 15.5902 20.4546 15.6927 20.5988L16.0416 21.1147L16.9883 20.0438C17.0908 19.9191 17.2958 19.8996 17.4202 20.0243C17.5447 20.1269 17.5642 20.3323 17.4398 20.457L16.3295 21.7333C16.2441 21.7919 16.1417 21.8531 16.0197 21.8531Z"
        fill="white"
      />
    </svg>
  );
};

export default LockSvg;
