import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useMutateMoneyRequest(onClose, history) {
  const queryClient = useQueryClient();

  return useMutation(
    (data) => {
      const formData = new FormData();

      formData.append("name", data.name);
      formData.append("amount", data.amount);
      formData.append("description", data.description);
      formData.append("category_id", data.category_id);
      formData.append("recipient_id", data.recipient_id);
      formData.append("withdrawn", data.withdrawn);

      formData.append(
        "attachments",
        JSON.stringify(data.attachments ? data.attachments : [])
      );

      formData.append("user_id", data.user_id);
      formData.append("creator", data.user_id);
      formData.append("company_id", data.company_id);

      return apiClient.post("money_request/add", formData);
    },
    {
      onSuccess: () => {
        toast.info("تم إرسال الطلب بنجاح.", { autoClose: 3000 });
        queryClient.invalidateQueries("getMoneyRequestsData");
        queryClient.invalidateQueries("userData");
        queryClient.invalidateQueries("userBalance");
        onClose(false);
        setTimeout(() => {
          history.push("/money-requests");
        }, 1000);
      },
      onError: (error) => {
        onClose(false);
        return error;
      },
    }
  );
}
