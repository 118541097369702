import React, { Fragment, useState, useEffect, useMemo } from "react";

import { useForm, Controller } from "react-hook-form";

import Table from "../../common/Table";
import Spinner from "../../common/Spinner";
import Select from "react-select";
import Modal from "../../common/Modal";
import AddMembers from "../AddMembers";
import Input from "../../common/inputs/Input";
import SelectMenu from "../../common/inputs/Select";
import UploadInput from "../../common/inputs/Upload";
import TeamSvg from "../../resources/team svg/TeamSvg";
import PersonSvg from "../../resources/person svg/PersonSvg";

import useFetchUserData from "../../../hooks/reactQuery/useFetchUserData";
import useFetchSpecializations from "../../../hooks/reactQuery/useFetchSpecializations";
import useFetchResponsibilities from "../../../hooks/reactQuery/useFetchResponsibilities";
import useFetchMembersCount from "../../../hooks/reactQuery/useFetchMembersCount";
import useMutateCompany from "../../../hooks/reactQuery/useMutateCompany";
import useFetchTeam from "../../../hooks/reactQuery/useFetchTeam";
import useMutateUserRole from "../../../hooks/reactQuery/useMutateUserRole";
import { photoPreview } from "../../../utils/photoPreview";
import { tableColumnsHeader } from "./tableColumnsHeader";
import { useTranslation } from "react-i18next";

const CompanySettings = ({}) => {
  const {t, i18n} = useTranslation();
  const { data: userData } = useFetchUserData();

  const [showAddMembersModal, setShowAddMembersModal] = useState(false);
  const [userModifiedRoles, setUserModifiedRoles] = useState({});

  const userRoleOptions = [
    { label: t("manager"), value: 1 },
    { label: t("regulator"), value: 2 },
    { label: t("member"), value: 3 },
  ];

  const columnsHeader = tableColumnsHeader(
    setUserModifiedRoles,
    userRoleOptions
  );

  const [file, setFile] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState(
    "img/upload-profile.png"
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
    setError,
    clearErrors,
    control,
    trigger,
    reset,
  } = useForm({
    mode: "all",
  });

  useEffect(() => {
    const companyImagrUrl = userData.activeCompany
      ? (process.env.REACT_APP_UPLOAD_FILES_BASE_URL
          ? process.env.REACT_APP_UPLOAD_FILES_BASE_URL
          : "") +
        (process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
          ? process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
          : "images/tarbeetapaymentrequests/") +
        `companies/${userData.activeCompany.id}/profilepicture` +
        `.${userData.activeCompany.profile_picture_ext}`
      : "../img/default-company.svg";
    setImagePreviewUrl(companyImagrUrl);
  }, [userData.activeCompany]);

  useEffect(() => {
    if (userData.activeCompany) {
      reset({
        companyName: userData.activeCompany.name,
        en_name: userData.activeCompany.en_name,
        companySpecialization: {
          label: i18n.resolvedLanguage === "en" ? userData.activeCompany.specialization_en_name :  userData.activeCompany.specialization_name,
          value: userData.activeCompany.specialization,
        },
        responsibityOptions: {
          label: i18n.resolvedLanguage === "en" ? userData.activeCompany.responsibility_en_name :  userData.activeCompany.responsibility_name,
          value: userData.activeCompany.responsibility,
        },
        companyMembers: {
          label: userData.activeCompany.members_name,
          value: userData.activeCompany.members,
        },
      });
    }
  }, [reset, userData.activeCompany]);

  const { isLoading: isCompanyMutationLoading, mutate: companyMutation } =
    useMutateCompany(userData.activeCompany.id);

  const handleChangeCompanySettings = (data) => {
    data.file = file;
    if (
      file ||
      getValues("companySpecialization")?.value !=
        userData.activeCompany.specialization ||
      getValues("responsibityOptions")?.value !=
        userData.activeCompany.responsibility ||
      getValues("companyMembers")?.value != userData.activeCompany.members ||
      getValues("companyName") != userData.activeCompany.name ||
      getValues("en_name") != userData.activeCompany.en_name
    ) {
      companyMutation(data);
    }
    for (
      let index = 0;
      index < Object.keys(userModifiedRoles).length;
      index++
    ) {
      userModifiedRoles &&
        userRoleMutation({
          id: Object.keys(userModifiedRoles)[index],
          role: Object.values(userModifiedRoles)[index],
        });
    }
    setFile("");
    setUserModifiedRoles({});
  };

  const { data: specializations, isLoading: isSpecializationsLoading } =
    useFetchSpecializations(t, i18n);

  const { data: responsibilities, isLoading: isResponsibilitiesLoading } =
    useFetchResponsibilities(t, i18n);

  const { data: membersCount, isLoading: isMembersCountLoading } =
    useFetchMembersCount();

  const pageSize = process.env.REACT_APP_PAGE_SIZE;
  const [currentPage, setCurrentPage] = useState(1);

  const {
    isLoading: isUserRoleMutationLoading,
    mutate: userRoleMutation,
    status: userRoleMutationStatus,
  } = useMutateUserRole(userData.activeCompanyId, currentPage);

  const {
    isLoading: isTableDataLoading,
    data: tableData,
    error: tableDataError,
    isPreviousData,
  } = useFetchTeam(pageSize, currentPage);

  const memoizedTableData = useMemo(
    () => (tableData ? tableData[0] : []),
    [tableData]
  );

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const [expandedRows, setExpandedRows] = useState([]);
  const onTableRowExpand = (expanded, record) => {
    var keys = [];
    if (expanded) {
      keys.push(record.id);
    }
    setExpandedRows(keys);
  };

  return (
    <div className="bg-gray-50 lg:grid lg:grid-cols-12 lg:gap-x-5 w-full">
      <form
        className="col-span-12"
        onSubmit={handleSubmit(handleChangeCompanySettings)}
      >
        <div className="flex flex-col justify-between">
          <div className="py-6 px-6 space-y-6">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
              {t("company_info")}
              </h3>
            </div>
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6">
                <div className="flex flex-col justify-center items-center select-none">
                  <div>
                    <label
                      className="flex flex-col justify-center items-center relative cursor-pointer"
                      htmlFor="companyImage"
                    >
                      <div className="w-16 h-16">
                        <img
                          className="w-full h-full object-cover rounded-lg"
                          htmlFor="photo-upload"
                          alt="company image"
                          src={imagePreviewUrl}
                        />
                      </div>
                    </label>
                  </div>
                  {userData.permission_tags.includes("EditWorkspace") ? 
                  <UploadInput
                    multiple={false}
                    accept="image/*"
                    name="companyImage"
                    id="companyImage"
                    hint={t("pick_company_pick")}
                    showUplodedFilesNames={false}
                    getValues={getValues}
                    setError={setError}
                    clearErrors={clearErrors}
                    fileSize="5000000"
                    errors={errors.companyImage}
                    Controller={Controller}
                    control={control}
                    setValue={setValue}
                    trigger={trigger}
                    photoPreview={(e) =>
                      photoPreview(e, setFile, setImagePreviewUrl)
                    }
                  /> : null}
                </div>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  register={register}
                  type="text"
                  name="companyName"
                  fieldName={t("company_name")}
                  placeholder={t("company_name")}
                  defaultValue={userData.activeCompany.name}
                  required={true}
                  maxLength="50"
                  errors={errors.companyName}
                  isDisabled={!userData.permission_tags.includes("EditWorkspace")}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  register={register}
                  type="text"
                  name="en_name"
                  fieldName={t("company_en_name")}
                  placeholder={t("company_en_name")}
                  defaultValue={userData.activeCompany.en_name}
                  required={true}
                  maxLength="50"
                  errors={errors.en_name}
                  isDisabled={!userData.permission_tags.includes("EditWorkspace")}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <SelectMenu
                  name="companySpecialization"
                  fieldName={t("company_specialization")}
                  placeholder={t("company_specialization")}
                  defaultValue={{
                    label: i18n.resolvedLanguage === "en" ? userData.activeCompany.specialization_en_name :  userData.activeCompany.specialization_name,
                    value: userData.activeCompany.specialization,
                  }}
                  options={specializations}
                  isLoading={isSpecializationsLoading}
                  Controller={Controller}
                  control={control}
                  required={true}
                  errors={errors?.companySpecialization}
                  isDisabled={!userData.permission_tags.includes("EditWorkspace")}
                />
              </div>
            </div>

            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <SelectMenu
                  name="responsibityOptions"
                  fieldName={t("company_responsibility")}
                  placeholder={t("company_responsibility")}
                  defaultValue={{
                    label: i18n.resolvedLanguage === "en" ? userData.activeCompany.responsibility_en_name :  userData.activeCompany.responsibility_name,
                    value: userData.activeCompany.responsibility,
                  }}
                  options={responsibilities}
                  isLoading={isResponsibilitiesLoading}
                  Controller={Controller}
                  control={control}
                  required={true}
                  errors={errors?.responsibityOptions}
                  isDisabled={!userData.permission_tags.includes("EditWorkspace")}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <SelectMenu
                  name="companyMembers"
                  fieldName={t("company_members")}
                  placeholder={t("company_members")}
                  defaultValue={{
                    label: userData.activeCompany.members_name,
                    value: userData.activeCompany.members,
                  }}
                  options={membersCount}
                  isLoading={isMembersCountLoading}
                  Controller={Controller}
                  control={control}
                  required={true}
                  errors={errors?.companyMembers}
                  isDisabled={!userData.permission_tags.includes("EditWorkspace")}
                />
              </div>
            </div>
          </div>

          <div className="sticky bottom-0 px-4 py-3 bg-white text-right sm:px-6">
            <button
              type="submit"
              className={`flex items-center bg-black py-2 px-10 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white w-full sm:w-auto ${
                !isValid || isCompanyMutationLoading || !userData.permission_tags.includes("EditWorkspace")
                  ? "cursor-not-allowed disabled bg-opacity-50"
                  : ""
              }`}
              disabled={!isValid || isCompanyMutationLoading || !userData.permission_tags.includes("EditWorkspace")}
            >
              {t("confirm")}
              {isCompanyMutationLoading && (
                <div className="w-5 h-5 mr-4">
                  <Spinner />
                </div>
              )}
            </button>
          </div>
        </div>
      </form>

      <Modal
        showModal={showAddMembersModal}
        onClose={() => setShowAddMembersModal(false)}
      >
        <AddMembers onClose={() => setShowAddMembersModal(false)} />
      </Modal>
    </div>
  );
};

export default CompanySettings;
