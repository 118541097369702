const MainFinancial = () => {
  return (
    <div>
      <svg
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.7128 10.9125L10.9203 15.705C9.87031 16.755 8.14532 16.755 7.08782 15.705L2.29531 10.9125C1.24531 9.8625 1.24531 8.13751 2.29531 7.08001L7.08782 2.2875C8.13782 1.2375 9.86281 1.2375 10.9203 2.2875L15.7128 7.08001C16.7628 8.13751 16.7628 9.8625 15.7128 10.9125Z"
          stroke="#94A3B8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.6875 4.6875L13.3125 13.3125"
          stroke="#94A3B8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3125 4.6875L4.6875 13.3125"
          stroke="#94A3B8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default MainFinancial;
