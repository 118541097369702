const Holdings = () => {
  return (
    <div>
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="30" height="30" rx="6" fill="#E7F0FF"/>
          <path d="M4.7 23.24C4.71 23.23 4.71 23.22 4.71 23.22L10.21 17.72C10.22 17.71 10.23 17.71 10.24 17.7C9.61 17.26 8.84 17 8 17C5.79 17 4 18.79 4 21C4 21.75 4.21 22.46 4.58 23.06C4.62 23.12 4.66 23.18 4.7 23.24Z" fill="#59A0FF"/>
          <path d="M11.3 18.75C11.29 18.76 11.29 18.77 11.28 18.78L5.78 24.28C5.77 24.29 5.76 24.29 5.75 24.3C6.39 24.74 7.16 25 8 25C9.46 25 10.73 24.22 11.42 23.06C11.79 22.46 12 21.75 12 21C12 20.16 11.74 19.39 11.3 18.75Z" fill="#59A0FF"/>
          <path d="M20 7H10C7 7 5 8.5 5 12V15.56C5 15.93 5.38 16.16 5.71 16.01C6.69 15.56 7.82 15.39 9.01 15.6C11.64 16.07 13.57 18.51 13.5 21.18C13.49 21.6 13.43 22.01 13.32 22.41C13.24 22.72 13.49 23.01 13.81 23.01H20C23 23.01 25 21.51 25 18.01V12C25 8.5 23 7 20 7ZM15 17.5C13.62 17.5 12.5 16.38 12.5 15C12.5 13.62 13.62 12.5 15 12.5C16.38 12.5 17.5 13.62 17.5 15C17.5 16.38 16.38 17.5 15 17.5ZM22.25 17C22.25 17.41 21.91 17.75 21.5 17.75C21.09 17.75 20.75 17.41 20.75 17V13C20.75 12.59 21.09 12.25 21.5 12.25C21.91 12.25 22.25 12.59 22.25 13V17Z" fill="#59A0FF"/>
      </svg>
    </div>
  );
};

export default Holdings;
