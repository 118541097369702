import { useState, Fragment, useRef } from "react";

import { Menu, Transition } from "@headlessui/react";

import { toolPin } from "../../../utils/toolPin";

import Modal from "../../common/Modal";
import Alert from "../../common/Alert";
import Spinner from "../../common/Spinner";
import UserAlt from "../../resources/icons/UserAlt";
import UserTag from "../../resources/icons/UserTag";
import TimeTag from "../../resources/icons/TimeTag";
import Balance from "../../resources/icons/Balance";
import Role from "../../resources/icons/Role";
import UserRole from "../../resources/icons/UserRole";
import TableSortIcon from "../../resources/icons/TableSortIcon";
import Envelop from "../../resources/icons/Envelop";
import Check from "../../resources/icons/Check";
import ChevronArrow from "../../resources/icons/ChevronArrow";
import LatestInteract from "../../resources/icons/LatestInteract";
import UserAvatar from "../../resources/icons/userAvatar";

import useFetchCompanyRoles from "../../../hooks/reactQuery/useFetchCompanyRoles";
import useDeleteMember from "./../../../hooks/reactQuery/useDeleteMember";
import useActivatMember from "../../../hooks/reactQuery/useActivatMember";
import useChangUserRole from "../../../hooks/reactQuery/useChangUserRole";

import useClickOutside from "../../../hooks/useClickOutside";

import { userImageUrl } from "../../../utils/imagUrl";
import { formatDate } from "../../../utils/convertDate";

export const tableColumnsHeader = (
  canDeactivateUser,
  canEditUser,
  canDeleteInvitation,
  userData,
  t,
  i18n
) => [
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <UserAlt />
        </div>
        <div className="col-title">{t("name")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "name",
    key: "name",
    sorter: true,
    render: (text, row) =>
      row.pending ? (
        <div className="flex items-center justify-start gap-x-4 tool-pin-container">
          <div>
            <UserAvatar />
          </div>
          <div>
            <Envelop />
          </div>
          <p
            style={{ maxWidth: "147px" }}
            className="tool-pin px-2 py-1 rounded text-xs font-medium text-sidebar-background bg-gray-800 opacity-75 hidden absolute top-10 right-0 z-10"
          >
          {t("invitation_sent")}
          </p>
        </div>
      ) : (
        //
        //
        //
        <div
          className="flex items-center gap-x-4 bg-opacity-10"
          style={row.active == 0 ? { opacity: "0.4" } : {}}
        >
          <div>
            {row.profile_picture_ext ? (
              <img
                className="h-10 w-10 rounded-full"
                src={userImageUrl(row)}
                alt="user"
              />
            ) : (
              <div className="h-10 w-10 rounded-full flex justify-center items-center bg-onhold">
                {text[0]}
              </div>
            )}
          </div>
          <div className="flex flex-col">
            <h3 className="text-sm font-medium text-body">
              {row.name}
              {row.company_default_admin ? <span style={{opacity: "0.6", fontSize: "12px"}}> ({t("workspace_owner")}) </span> : null}
            </h3>
            <div className="flex items-center gap-x-1">
              <div>
                <LatestInteract />
              </div>
              <p className="text-sm font-normal text-hints">
                {formatDate(row.latest_interaction)}
              </p>
            </div>
          </div>
        </div>
        //
        //
        //
      ),
    width: 250,
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <UserTag />
        </div>
        <div className="col-title">{t("user_name")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "user_name",
    key: "user_name",
    sorter: true,
    render: (text, row) => (
      <div
        className="col-desc flex justify-start"
        style={row.active == 0 ? { opacity: "0.4" } : {}}
      >
        <div className="flex flex-col">
          {!row.pending && <p>{toolPin(String(text))}</p>}
          <p className="text-hints">{toolPin(String(row.email))}</p>
        </div>
      </div>
    ),
    width: 100,
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <TimeTag />
        </div>
        <div className="col-title">{t("joining_date")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "created_at",
    key: "created_at",
    sorter: true,
    render: (text, row) => (
      <div
        className="col-desc flex justify-center"
        style={row.active == 0 ? { opacity: "0.4" } : {}}
      >
        {!row.pending ? (
          new Date(text).toLocaleDateString("ar-EG-u-nu-latn", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })
        ) : (
          <div className="tool-pin-container">
            <div>
              <Envelop />
            </div>
            <p
              style={{ maxWidth: "147px" }}
              className="tool-pin px-2 py-1 rounded text-xs font-medium text-sidebar-background bg-gray-800 opacity-75 hidden absolute top-10 right-0 z-10"
            >
            {t("invitation_sent")}
            </p>
          </div>
        )}
      </div>
    ),
    width: 100,
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <Balance />
        </div>
        <div className="col-title">{t("account_balance")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "balance",
    key: "balance",
    sorter: true,
    render: (text, row) => (
      <div
        className="col-desc flex justify-center"
        style={row.active == 0 ? { opacity: "0.4" } : {}}
      >
        {!row.pending ? (
          toolPin(String(text))
        ) : (
          <div className="tool-pin-container">
            <div>
              <Envelop />
            </div>
            <p
              style={{ maxWidth: "147px" }}
              className="tool-pin px-2 py-1 rounded text-xs font-medium text-sidebar-background bg-gray-800 opacity-75 hidden absolute top-10 right-0 z-10"
            >
              {t("invitation_sent")}
            </p>
          </div>
        )}
      </div>
    ),
    width: 100,
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <Role />
        </div>
        <div className="col-title">{t("role")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "role_name",
    key: "role_name",
    sorter: true,
    render: (text, row) => {
      return (
        <div className="col-desc">
          <div className="flex justify-between items-center">
            {(canDeactivateUser && row.company_default_admin != 1 && userData.id !== row.user_id) ||
            (canEditUser && row.company_default_admin != 1 && userData.id !== row.user_id) ||
            (canDeleteInvitation && row.company_default_admin != 1 && userData.id !== row.user_id) ? (
              <DropDownMenu
                row={row}
                canDeactivateUser={canDeactivateUser}
                canDeleteInvitation={canDeleteInvitation}
                canEditUser={canEditUser}
                t={t}
              />
            ) : (
              <span style={row.active == 0 ? { opacity: "0.4" } : {}}>
                {row.role_name}
              </span>
            )}
          </div>
        </div>
      );
    },
    width: 225,
  },
];

function DropDownMenu({
  row,
  canDeactivateUser,
  canDeleteInvitation,
  canEditUser,
  t
}) {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const [isRolesOpen, setRolesOpen] = useState(false);

  // close menu on click outside
  const clickRef = useRef();
  useClickOutside(clickRef, () => {
    setMenuOpen(() => false);
  });

  const [removeModal, setRemoveModal] = useState(false);
  const { isLoading: isDeleteLoading, mutate: mutateDelete } = useDeleteMember(
    setRemoveModal,
    row
  );
  const handleDeleteMember = () => {
    mutateDelete();
  };

  const [activatModal, setActivatModal] = useState(false);
  const { isLoading: isActivatLoading, mutate: mutateActivat } =
    useActivatMember(setActivatModal, row);
  const handleActivatMember = () => {
    mutateActivat();
  };

  return (
    <div ref={clickRef} className="w-full">
      {/* <Menu.Button> */}
      <button
        onClick={(e) => {
          // e.stopPropagation();
          if (row.company_default_admin == 1) {
            e.preventDefault();
            return;
          }
          setMenuOpen((prevState) => !prevState);
        }}
        className="w-full flex items-center justify-between"
      >
        <span style={row.active == 0 ? { opacity: "0.4" } : {}}>
          {toolPin(row.role_name)}
        </span>
        <div>
          <UserRole fillColor={isMenuOpen ? "#3B82F6" : ""} />
        </div>
      </button>
      {/* </Menu.Button> */}
      <Menu as="div" className="relative inline-block text-left h-full">
        {({}) => (
          <>
            <div>
              <Transition
                show={isMenuOpen}
                as={Fragment}
                enter="transition easIn duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                id="pop-menu"
              >
                <Menu.Items
                  style={{ width: "224px", zIndex: "3" }}
                  className="origin-top-right absolute -right-5 -top-8 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  {row.company_default_admin != 1 ? (
                    <div className="grid bg-white text-sm font-normal">
                      {canEditUser ? (
                        <button
                          className="flex items-center justify-between py-3 px-4 text-body"
                          onClick={() =>
                            setRolesOpen((prevState) => !prevState)
                          }
                        >
                          <span>{t("change_role")}</span>
                          {isRolesOpen && (
                            <div className="transform rotate-180">
                              <ChevronArrow />
                            </div>
                          )}
                        </button>
                      ) : null}
                      <hr />
                      {isRolesOpen ? (
                        <Roles row={row} t={t}/>
                      ) : row.pending ? (
                        canDeleteInvitation ? (
                          <button
                            className="flex items-center justify-start py-3 px-4 text-danger"
                            onClick={() => setRemoveModal(true)}
                          >
                          {t("cancel")}
                          </button>
                        ) : null
                      ) : canDeactivateUser ? (
                        <button
                          className="flex items-center justify-start py-3 px-4 text-danger"
                          onClick={() => setActivatModal(true)}
                        >
                          {row.active === 1 ? t("deactivate_member") : t("activate_member")}
                        </button>
                      ) : null}
                    </div>
                  ) : null}
                </Menu.Items>
              </Transition>
            </div>
          </>
        )}
      </Menu>
      <Modal showModal={activatModal} onClose={() => setActivatModal(false)}>
        <Alert
          hasFooter={true}
          alertType={row.active === 1 ? "cancel" : "confirm"}
          title={row.active === 1 ? t("deactivate_member_permissions") : t("reactivate_member")}
          body={() => (
            <div>
              {row.active === 1 ? t("alert_text_deactivate_member", {user_name: row.name}) : t("alert_text_activate_member", {user_name: row.name})}
            </div>
          )}
          confirmText={row.active === 1 ? t("deactivate") : t("activate")}
          cancelText={t("cancel")}
          isSubmitLoading={isActivatLoading}
          handleConfirm={handleActivatMember}
          handleCancel={() => !isActivatLoading && setActivatModal(false)}
        />
      </Modal>

      <Modal showModal={removeModal} onClose={() => setRemoveModal(false)}>
        <Alert
          hasFooter={true}
          alertType="cancel"
          title={t("cancel_invitation")}
          body={() => (
            <div>
            {t("alert_text_cancel_invitation")}
            </div>
          )}
          confirmText={t("cancel_invitation")}
          cancelText={t("cancel")}
          isSubmitLoading={isDeleteLoading}
          handleConfirm={handleDeleteMember}
          handleCancel={() => !isDeleteLoading && setRemoveModal(false)}
        />
      </Modal>
    </div>
  );
}

function Roles({ row, t }) {
  const { data: compRoles, isLoading: isCompRolesLoading } =
    useFetchCompanyRoles();

  const [newRole, setNewRole] = useState({});

  const [changeRoleModal, setChangeRoleModal] = useState(false);
  const { mutate: mutateRole, isLoading: isMutateRoleLoading } =
    useChangUserRole(setChangeRoleModal, row);
  const handleChangeRole = () => {
    mutateRole(newRole);
  };

  return (
    <>
      <ul style={{ maxHeight: "300px" }} className="overflow-y-auto scroll-bar">
        {compRoles?.map((role) => (
          <li className="bg-secondary bg-opacity-5 flex items-center justify-between py-3 px-4 text-sm font-normal text-body">
            {isCompRolesLoading ? (
              <div>
                <Spinner />
              </div>
            ) : (
              <button
                className={`flex items-center justify-between w-full ${
                  role.value === row.role_id ? "cursor-not-allowed" : ""
                }`}
                disabled={role.value === row.role_id}
                onClick={() => {
                  setNewRole(role);
                  setChangeRoleModal(true);
                }}
              >
                <span>{role.label}</span>
                {role.value === row.role_id && (
                  <div>
                    <Check />
                  </div>
                )}
              </button>
            )}
          </li>
        ))}
      </ul>
      <Modal
        showModal={changeRoleModal}
        onClose={() => setChangeRoleModal(false)}
      >
        <Alert
          hasFooter={true}
          alertType="confirm"
          title={t("edit_member_role")}
          body={() => (
            <div>
              {t("alert_text_confirm_change_role", {user_name: row.name, old_role: row.role_name, new_role: newRole.label})}
            </div>
          )}
          confirmText={t("edit")}
          cancelText={t("cancel")}
          isSubmitLoading={isMutateRoleLoading}
          handleConfirm={handleChangeRole}
          handleCancel={() => !isMutateRoleLoading && setChangeRoleModal(false)}
        />
      </Modal>
    </>
  );
}
