const UserAvatar = () => {
  return (
    <div>
      <svg
        width={40}
        height={40}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_5385_123841)">
          <path
            d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
            fill="#F8FAFC"
          />
          <path
            d="M40 34.9899V40.0016H0V35.0082C2.32658 31.899 5.34651 29.3755 8.81965 27.6383C12.2928 25.9012 16.1233 24.9983 20.0067 25.0016C28.18 25.0016 35.44 28.9249 40 34.9899ZM26.67 14.9999C26.67 16.768 25.9676 18.4637 24.7174 19.714C23.4671 20.9642 21.7714 21.6666 20.0033 21.6666C18.2352 21.6666 16.5395 20.9642 15.2893 19.714C14.039 18.4637 13.3367 16.768 13.3367 14.9999C13.3367 13.2318 14.039 11.5361 15.2893 10.2859C16.5395 9.03563 18.2352 8.33325 20.0033 8.33325C21.7714 8.33325 23.4671 9.03563 24.7174 10.2859C25.9676 11.5361 26.67 13.2318 26.67 14.9999Z"
            fill="#CBD5E1"
          />
        </g>
        <defs>
          <clipPath id="clip0_5385_123841">
            <path
              d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default UserAvatar;
