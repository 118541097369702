import { useEffect, useState, useRef } from 'react';
import "../dashboard.scss";
import { useTranslation } from "react-i18next";

export default function Latest(props) {
  const {t, i18n} = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);
  const [scrolledToBottom, setScrolledToBottom] = useState(false);

  const expand = () => {
    let newExpanded = !isExpanded;
    if(!isExpanded && props.children.length <= 4) newExpanded = false;
    setIsExpanded(newExpanded);
  }

  const scrollHandler = (e) => {
    if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight - 60) { 
      setScrolledToBottom(true);
    } 
    else {
      setScrolledToBottom(false);
    }
  }

  useEffect(() => {
    if(scrolledToBottom && !props.isLoading ) {
      if(props.currentPage < props.lastPage) 
        props.setCurrentPage(props.currentPage + 1);
    }
  }, [scrolledToBottom]);

  return (
    <div className='latest-container' style={{width: props.width, height: isExpanded ? "569px" : "342px"}}>
      <div className='latest-container-header'>
        <div className='latest-container-header-right'>
          {props.icon}
          <p className='mx-2'>{props.title}</p>
        </div>
        {props.count && props.amount ? 
        <div className='latest-container-header-left'>
          <span className='mx-2'>{t("count")} / {props.count}</span>
          <span style={{marginRight: "20px"}}> {t("amount")} / {props.amount}</span>
        </div> : null}
      </div>
      <div className={`latest-container-body ${isExpanded ? "latest-container-body-scrollable" : ""}`} onScroll={scrollHandler}>
        {props.children}
        {isExpanded && scrolledToBottom && props.isLoading ? <div className='loading-circle-container'>
          ...loading
        </div> : null}
      </div>
      <button className='latest-container-footer' onClick={() => expand()}>
        <span>{isExpanded ? t("less") : t("more")}</span>
      </button>
    </div>
  );
}