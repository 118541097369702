import { useQuery } from "react-query";
import apiClient from "../../services/apiClient";
import { formatDate } from '../../utils/convertDate';

export default function useFetchWithdrawalsDashboard(
  safeId,
  pageno,
  pageSize,
  range
) {
  return useQuery(
    ["getWithdrawalsDashboard", safeId, range, pageno, pageSize],
    () => {
      const params = {};
      params.safe_id = safeId;
      params.pageno = pageno;
      params.pagesize = pageSize;
      params.range = JSON.stringify(range);

      return apiClient
        .get(`/money_request/cashout/approved`, {
          params,
        })
        .then(({ data }) => {
          let dataArray = [...data];
          dataArray[0] = data[0].map(record => {
            return {
              ...record, 
              date: formatDate(record.creation_date),
              profile_picture_url: (process.env.REACT_APP_UPLOAD_FILES_BASE_URL
                ? process.env.REACT_APP_UPLOAD_FILES_BASE_URL
                : "") +
              (process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
                ? process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
                : "") +
              `users/${record.creator}/profilepicture` +
              `.${record.profile_picture_ext}`
            }
          });
          return dataArray;
        });
    },
    { keepPreviousData: true.valueOf, retry: false }
  );
}
