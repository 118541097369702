import { useState, useRef, Fragment } from "react";

import { Menu, Transition } from "@headlessui/react";

import useClickOutside from "../../../hooks/useClickOutside";

import { userImageUrl } from "../../../utils/imagUrl";

const UsersAvatars = ({ usersArr }) => {
  return (
    <div>
      {usersArr?.length === 1 && (
        <div className="flex items-center justify-start gap-x-1">
          <div className="w-5 h-5">
            {usersArr[0].profile_picture_ext ? (
              <img
                className="h-5 w-5 rounded-full"
                src={userImageUrl({
                  user_id: usersArr[0].id,
                  profile_picture_ext: usersArr[0].profile_picture_ext,
                })}
              />
            ) : (
              <div className="h-5 w-5 rounded-full flex justify-center items-center bg-onhold">
                {usersArr[0].name[0]}
              </div>
            )}
          </div>
          <p className="text-sm leading-4 font-medium text-body">
            {usersArr[0].name}
          </p>
        </div>
      )}
      <div className="flex items-center gap-x-1">
        {usersArr?.length > 1 &&
          usersArr?.slice(0, 4).map((user) => {
            return (
              <div key={user.id}>
                <div className="w-5 h-5 tool-pin-container relative">
                  {user.profile_picture_ext ? (
                    <>
                      <img
                        className="h-5 w-5 rounded-full"
                        src={userImageUrl({
                          user_id: user.id,
                          profile_picture_ext: user.profile_picture_ext,
                        })}
                      />
                    </>
                  ) : (
                    <div className="h-5 w-5 rounded-full flex justify-center items-center bg-onhold">
                      {user.name[0]}
                    </div>
                  )}
                  <p className="tool-pin px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-5 right-0 z-10">
                    {user.name}
                  </p>
                </div>
              </div>
            );
          })}

        {usersArr?.length > 4 && <DropDownMenu usersArr={usersArr} />}
      </div>
    </div>
  );
};

export default UsersAvatars;

function DropDownMenu({ usersArr }) {
  const [isMenuOpen, setMenuOpen] = useState(false);

  // close menu on click outside
  const clickRef = useRef();
  useClickOutside(clickRef, () => {
    setMenuOpen(() => false);
  });

  return (
    <div ref={clickRef} className="w-full">
      {/* <Menu.Button> */}
      <button
        onClick={(e) => {
          e.stopPropagation();
          setMenuOpen((prevState) => !prevState);
        }}
      >
        +
      </button>
      {/* </Menu.Button> */}
      <Menu as="div" className="relative inline-block text-left h-full">
        {({}) => (
          <>
            <div>
              <Transition
                show={isMenuOpen}
                as={Fragment}
                enter="transition easIn duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  style={{ zIndex: "3", maxHeight: "200px" }}
                  className="w-max overflow-y-auto origin-top-right absolute right-1 top-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div className="flex flex-col gap-y-4 p-4">
                    {usersArr?.map((user) => {
                      return (
                        <div
                          key={user.id}
                          className="flex items-center justify-start gap-x-2"
                        >
                          <div className="w-5 h-5">
                            {user.profile_picture_ext ? (
                              <img
                                className="h-5 w-5 rounded-full"
                                src={userImageUrl({
                                  user_id: user.id,
                                  profile_picture_ext: user.profile_picture_ext,
                                })}
                              />
                            ) : (
                              <div className="h-5 w-5 rounded-full flex justify-center items-center bg-onhold">
                                {user.name[0]}
                              </div>
                            )}
                          </div>
                          <p className="text-sm leading-4 font-medium text-body">
                            {user.name}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </Menu.Items>
              </Transition>
            </div>
          </>
        )}
      </Menu>
    </div>
  );
}
