const LatestIcon = () => {
  return (
    <div>
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="30" height="30" rx="6" fill="#E7F0FF"/>
          <path d="M14.9981 7.65002C10.2181 7.65002 6.32812 11.54 6.32812 16.32C6.32812 21.1 10.2181 25 14.9981 25C19.7781 25 23.6681 21.11 23.6681 16.33C23.6681 11.55 19.7781 7.65002 14.9981 7.65002ZM15.7481 16C15.7481 16.41 15.4081 16.75 14.9981 16.75C14.5881 16.75 14.2481 16.41 14.2481 16V11C14.2481 10.59 14.5881 10.25 14.9981 10.25C15.4081 10.25 15.7481 10.59 15.7481 11V16Z" fill="#59A0FF"/>
          <path d="M17.8906 6.45H12.1106C11.7106 6.45 11.3906 6.13 11.3906 5.73C11.3906 5.33 11.7106 5 12.1106 5H17.8906C18.2906 5 18.6106 5.32 18.6106 5.72C18.6106 6.12 18.2906 6.45 17.8906 6.45Z" fill="#59A0FF"/>
      </svg>
    </div>
  );
};

export default LatestIcon;
