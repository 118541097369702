const InfoCircle = () => {
  return (
    <div>
      <svg
        width={32}
        height={33}
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.0001 8.5C11.5921 8.5 8.00006 12.092 8.00006 16.5C8.00006 20.908 11.5921 24.5 16.0001 24.5C20.4081 24.5 24.0001 20.908 24.0001 16.5C24.0001 12.092 20.4081 8.5 16.0001 8.5ZM15.4001 13.3C15.4001 12.972 15.6721 12.7 16.0001 12.7C16.3281 12.7 16.6001 12.972 16.6001 13.3V17.3C16.6001 17.628 16.3281 17.9 16.0001 17.9C15.6721 17.9 15.4001 17.628 15.4001 17.3V13.3ZM16.7361 20.004C16.6961 20.108 16.6401 20.188 16.5681 20.268C16.4881 20.34 16.4001 20.396 16.3041 20.436C16.2081 20.476 16.1041 20.5 16.0001 20.5C15.8961 20.5 15.7921 20.476 15.6961 20.436C15.6001 20.396 15.5121 20.34 15.4321 20.268C15.3601 20.188 15.3041 20.108 15.2641 20.004C15.2241 19.908 15.2001 19.804 15.2001 19.7C15.2001 19.596 15.2241 19.492 15.2641 19.396C15.3041 19.3 15.3601 19.212 15.4321 19.132C15.5121 19.06 15.6001 19.004 15.6961 18.964C15.8881 18.884 16.1121 18.884 16.3041 18.964C16.4001 19.004 16.4881 19.06 16.5681 19.132C16.6401 19.212 16.6961 19.3 16.7361 19.396C16.7761 19.492 16.8001 19.596 16.8001 19.7C16.8001 19.804 16.7761 19.908 16.7361 20.004Z"
          fill="#94A3B8"
        />
      </svg>
    </div>
  );
};

export default InfoCircle;
