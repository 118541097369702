const Key = ({}) => {
  return (
    <div>
      <svg
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.4549 4.90945C12.3586 4.90945 13.0913 5.64207 13.0913 6.54581M16.364 6.54581C16.364 9.25702 14.1661 11.4549 11.4549 11.4549C10.9589 11.4549 10.4802 11.3814 10.0289 11.2446L8.18217 13.0913H6.54581V14.7276H4.90945V16.364H2.4549C2.00303 16.364 1.63672 15.9977 1.63672 15.5458V13.4302C1.63672 13.2132 1.72292 13.0051 1.87636 12.8516L6.75614 7.97184C6.61936 7.52055 6.54581 7.04177 6.54581 6.54581C6.54581 3.83459 8.74368 1.63672 11.4549 1.63672C14.1661 1.63672 16.364 3.83459 16.364 6.54581Z"
          stroke="#1E293B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Key;
