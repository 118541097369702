import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Spinner from "../Spinner";
import Cancel from "../../resources/icons/Cancel";
import Confirm from "../../resources/icons/Confirm";

const Alert = ({
  title,
  body,
  icon,
  confirmBtnBg,
  isConfirmBtnDisabled,
  hasFooter,
  alertType,
  confirmText,
  cancelText,
  handleConfirm,
  handleCancel,
  isSubmitLoading,
  submitStatus,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  return (
    <div
      className="overlay"
      // onClick={!isSubmitLoading && handleCancel}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="modal contents" onClick={(e) => e.stopPropagation()}>
        <div className="bg-white rounded-lg px-4 pt-5 pb-4 shadow-xl transform transition-all w-11/12 sm:max-w-lg sm:w-full sm:p-6">
          <div className="flex flex-col gap-y-5">
            <div className="w-full flex justify-center">
              <div
                className={`w-max flex items-center justify-center rounded-full ${
                  alertType === "confirm"
                    ? "bg-secondary-background"
                    : "bg-danger-background"
                }`}
              >
                {icon ? (
                  icon()
                ) : alertType === "confirm" ? (
                  <Confirm />
                ) : (
                  <Cancel />
                )}
              </div>
            </div>
            <div className="flex flex-col gap-y-2">
              <h3
                className="text-lg leading-6 font-medium text-body text-center"
                id="modal-title"
              >
                {title}
              </h3>
              <div>
                <p className="text-center text-sm font-medium text-hints">
                  {body()}
                </p>
              </div>
            </div>
          </div>
          {hasFooter && (
            <div className="mt-6 flex justify-between items-center gap-x-3">
              <button
                name="confirmButton"
                type="button"
                className={`flex justify-center items-center gap-x-2 w-full rounded-md border border-transparent shadow-sm py-2 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                  confirmBtnBg
                    ? confirmBtnBg
                    : alertType === "cancel"
                    ? "bg-danger focus:ring-danger"
                    : "bg-confirmation focus:ring-confirmation"
                } ${isSubmitLoading || isSubmitted ? "cursor-not-allowed bg-opacity-50" : ""}`}
                onClick={() => {
                  setIsSubmitted(true);
                  handleConfirm();
                }}
                disabled={isSubmitLoading || isSubmitted}
              >
                <span>{confirmText}</span>
                {isSubmitLoading && (
                  <div className="w-4 h-4">
                    <Spinner />
                  </div>
                )}
              </button>

              <button
                name="cancelButton"
                type="button"
                className={`flex justify-center items-center gap-x-2 w-full rounded-md shadow-sm py-2 text-sm font-medium text-body focus:outline-none focus:ring-2 focus:ring-offset-2 bg-white border border-separator ${
                  isSubmitLoading ? "cursor-not-allowed bg-opacity-50" : ""
                }`}
                onClick={() => {
                  handleCancel();
                }}
                disabled={isSubmitLoading}
              >
                {cancelText}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Alert);
