import React, { Fragment, useEffect, useMemo, useState } from "react";

import Modal from "../../components/common/Modal";
import Alert from "../../components/common/Alert";
import HomeIcon from "../../components/resources/icons/HomeIcon";
import BreadcrumbSeparatorIcon from "../../components/resources/icons/BreadcrumbSeparatorIcon";

import MoneyRequestDetailsAllExpensesIcon from "../../components/resources/icons/MoneyRequestDetailsAllExpensesIcon";
import MoneyRequestDetailsFinancialDocumentationsIcon from "../../components/resources/icons/MoneyRequestDetailsFinancialDocumentationsIcon";
import MoneyRequestDetailsAcceptedMoneyRequestsIcon from "../../components/resources/icons/MoneyRequestDetailsAcceptedMoneyRequestsIcon";

import "../../components/moneyRequest/CloseAndDetails/CloseAndDetails.scss";
import Table from "../../components/common/Table";
import useFetchDocs from "../../hooks/reactQuery/useFetchDocs";
import useFetchMoneyReqs from "../../hooks/reactQuery/useFetchMoneyReqs";

import {
  columnsHeaderAll,
  columnsHeaderDocs,
  columnsHeaderMoneyReqs,
} from "../../components/moneyRequest/CloseAndDetails/tableColumnsHeader";
import useMutateCloseFinancial from "../../hooks/reactQuery/useMutateCloseFinancial";
import ExpandedRow from "../../components/moneyRequest/CloseAndDetails/ExpandedRow";
import { useHistory, useLocation } from "react-router";
import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
import useFetchUserData from "./../../hooks/reactQuery/useFetchUserData";

import { useTranslation } from "react-i18next";

const MoneyRequestDetails = ({}) => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const type = location.type;
  const docId = location.docId;
  var docOrMoneyReqID = docId;
  const activeRow = location.activeRow;
  const showFooter = location.showFooter;
  const inValidteQueryKey = location.inValidteQueryKey;
  const moneyRequestsActiveTab = location.moneyRequestsActiveTab;

  const breadcrumbItems = [
    {
      id: "BreadcrumbItem1",
      title:
        moneyRequestsActiveTab === 1
          ? "طلبات العهد الواردة"
          : moneyRequestsActiveTab === 0
          ? "طلبات العهد الصادرة"
          : moneyRequestsActiveTab === 2
          ? "مسحوبات من الخزينة"
          : "غير معروف",
      action: () => {
        history.push({
          pathname: "/money-requests",
          moneyRequestsActiveTab: moneyRequestsActiveTab,
        });
      },
    },
    {
      id: "BreadcrumbItem2",
      title: activeRow?.name,
      action: () => {
        history.push({
          pathname: "/money-requests",
          moneyRequestsActiveTab: moneyRequestsActiveTab,
          expandedDocId: docId,
        });
      },
    },
    {
      id: "BreadcrumbItem3",
      title: type === "closeMoneyRequest" ? "تقفيل العهدة" : "تفاصيل العهدة",
      action: () => {},
    },
  ];

  const onClose = () => {
    history.replace("/money-requests");
  };

  const [showAlertConfirmModal, setShowAlertConfirmModal] = useState(false);

  const pageSize = process.env.REACT_APP_PAGE_SIZE;
  const [activeTab, setActiveTab] = useState(0);

  const handleTableChange = (pagination, filters, sorter) => {};

  const onPageChange = (page) => {};

  const [expandedRows, setExpandedRows] = useState([]);
  const onTableRowExpand = (expanded, record) => {
    var keys = [];
    if (expanded) {
      keys.push(record.newId);
    }
    setExpandedRows(keys);
  };

  const {
    isLoading: isDocsLoading,
    data: docsData,
    error: docsError,
  } = useFetchDocs(docOrMoneyReqID);
  const {
    isLoading: isMoneyReqsLoading,
    data: moneyReqsData,
    error: moneyReqsError,
  } = useFetchMoneyReqs(docOrMoneyReqID);

  const [allData, setAllData] = useState([]);
  useEffect(() => {
    let data = [];
    if (docsData) data = data.concat(docsData);
    if (moneyReqsData) data = data.concat(moneyReqsData);
    setAllData(data);
  }, [moneyReqsData, docsData]);

  const memoizedTableData = useMemo(
    () =>
      activeTab === 0
        ? docsData && moneyReqsData
          ? allData
          : docsData
          ? docsData
          : moneyReqsData
          ? moneyReqsData
          : []
        : activeTab === 1
        ? docsData
          ? docsData
          : []
        : activeTab === 2
        ? moneyReqsData
          ? moneyReqsData
          : []
        : [],
    [activeTab, docsData, moneyReqsData, allData]
  );

  const {
    isLoading: isCloseFinancialMutateLoading,
    mutate: CloseFinancialMutate,
    isError: CloseFinancialMutateError,
  } = useMutateCloseFinancial(onClose, inValidteQueryKey);
  const handleCloseFinancial = (activeRowId) => {
    CloseFinancialMutate({ user_id: userData.id, id: activeRowId });
  };

  useEffect(() => {
    if (!location.docId) {
      history.replace("/money-requests");
    }
  }, [location]);

  return (
    <div className="bg-white-light w-full">
      <Breadcrumb items={breadcrumbItems}></Breadcrumb>
      <div className="bg-white-light">
        <div className="col-span-12">
          <div className="sm:rounded-md sm:overflow-hidden">
            <div className="bg-white-light py-8 px-4 space-y-6">
              {type === "closeMoneyRequest" && (
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    قفل عهدتك و خلص ضميرك
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    بعد انتهاء حاجتك من العهدة, يمكنك تصفيتها مع المسؤول الذي
                    طلبت منه العهدة. لحظة أن تقرر تصفية العهدة, سوف يرجع جميع
                    المتبقي من عهدتك إلي مسؤول العهدة الأصلي, و لن تتمكن من
                    استرجاع العهدة من جديد.
                  </p>
                </div>
              )}

              <div className="grid grid-cols-3 gap-3">
                <div
                  className="bg-white-light rounded-lg border-2 border-gray-300 px-4 py-4"
                  style={{
                    boxShadow: "0px 2px 50px 0px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <p className="text-center text-sm font-normal mb-2">
                    عدد المصروفات بالعهدة
                  </p>
                  <p className="text-center text-2xl font-medium">
                    {allData.length}
                  </p>
                </div>
                <div
                  className="bg-white-light rounded-lg border-2 border-green-600 px-4 py-4"
                  style={{
                    boxShadow: "0px 2px 50px 0px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <p className="text-center text-sm font-normal mb-2">
                    قيمة العهدة الأصلية
                  </p>
                  <p className="text-center text-2xl font-medium">
                    {activeRow?.amount}
                  </p>
                </div>
                <div
                  className="bg-white-light rounded-lg border-2 border-red-500 px-4 py-4"
                  style={{
                    boxShadow: "0px 2px 50px 0px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <p className="text-center text-sm font-normal mb-2">
                    قيمة ما تم صرفه
                  </p>
                  <p className="text-center text-2xl font-medium">
                    {activeRow?.amount - activeRow?.remaining_amount}
                  </p>
                </div>
              </div>
            </div>
            <nav
              className="grid grid-cols-7 gap-4 bg-white-light"
              aria-label="Tabs"
            >
              <button
                type="button"
                className={`col-span-7 sm:col-span-1 sm:col-start-3 px-3 py-2 m-auto text-base ${
                  activeTab === 0
                    ? "bg-white-light text-black font-bold border-b-2 border-black"
                    : "text-gray-400 font-normal"
                }`}
                onClick={() => setActiveTab(0)}
              >
                <div class="flex flex-row-reverse">
                  <div className="mr-2">كل المصروفات</div>
                  <div>
                    <MoneyRequestDetailsAllExpensesIcon
                      fillColor={`${activeTab === 0 ? "#1E293B" : "#94A3B8"}`}
                    />
                  </div>
                </div>
              </button>
              <button
                type="button"
                className={`col-span-7 sm:col-span-1 sm:col-start-4 px-3 py-2 m-auto text-base ${
                  activeTab === 1
                    ? "bg-white-light text-black font-bold border-b-2 border-black"
                    : "text-gray-400 font-normal"
                }`}
                onClick={() => setActiveTab(1)}
              >
                <div class="flex flex-row-reverse">
                  <div className="mr-2">تدوينات مالية</div>
                  <div>
                    <MoneyRequestDetailsFinancialDocumentationsIcon
                      fillColor={`${activeTab === 1 ? "#1E293B" : "#94A3B8"}`}
                    />
                  </div>
                </div>
              </button>
              <button
                type="button"
                className={`col-span-7 sm:col-span-1 sm:col-start-5 px-3 py-2 m-auto text-base ${
                  activeTab === 2
                    ? "bg-white-light text-black font-bold border-b-2 border-black"
                    : "text-gray-400 font-normal"
                }`}
                onClick={() => setActiveTab(2)}
              >
                <div class="flex flex-row-reverse">
                  <div className="mr-2">عهد مقبولة</div>
                  <div>
                    <MoneyRequestDetailsAcceptedMoneyRequestsIcon
                      fillColor={`${activeTab === 2 ? "#1E293B" : "#94A3B8"}`}
                    />
                  </div>
                </div>
              </button>
            </nav>
            <div className="bg-white">
              <Table
                rowKey="newId"
                data={memoizedTableData}
                columns={
                  activeTab === 0
                    ? columnsHeaderAll
                    : activeTab === 1
                    ? columnsHeaderDocs
                    : activeTab === 2
                    ? columnsHeaderMoneyReqs
                    : null
                }
                pageSize={pageSize}
                isLoading={isMoneyReqsLoading || isDocsLoading}
                error={moneyReqsError || docsError}
                currentPage={1}
                total={0}
                hasFooter={false}
                isPaginationDisabled={true}
                onTableRowExpand={onTableRowExpand}
                expandedRows={expandedRows}
                setExpandedRows={setExpandedRows}
                onPageChange={onPageChange}
                onChange={handleTableChange}
                expandedRow={(row) => (
                  <ExpandedRow row={row} activeTab={activeTab} />
                )}
              />
            </div>
            {showFooter && (
              <div className="grid grid-cols-8 gap-5 px-4 py-3 bg-white text-right sm:px-6">
                <Fragment>
                  <button
                    type="button"
                    className="col-span-8 sm:col-span-2 sm:col-start-3 gap-6 bg-black py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white"
                    onClick={() => setShowAlertConfirmModal(true)}
                  >
                    تقفيل العهدة
                  </button>
                  <Modal
                    showModal={showAlertConfirmModal}
                    onClose={() => setShowAlertConfirmModal(false)}
                  >
                    <Alert
                      hasFooter={true}
                      alertType="confirm"
                      title="تأكيد تقفيل العهدة"
                      body={() => (
                        <div>
                          {t("alert_text_confirm_close_money_request", {amount: activeRow?.remaining_amount})}
                        </div>
                      )}
                      confirmText="تأكيد التقفيل"
                      cancelText="الغاء"
                      handleConfirm={() => handleCloseFinancial(activeRow?.id)}
                      handleCancel={() =>
                        !isCloseFinancialMutateLoading &&
                        setShowAlertConfirmModal(false)
                      }
                      isSubmitLoading={isCloseFinancialMutateLoading}
                    />
                  </Modal>
                </Fragment>

                <Fragment>
                  <button
                    type="button"
                    className="col-span-8 sm:col-span-2 sm:col-start-5 gap-6 bg-white-light py-2 px-4 border border-red-400 rounded-md shadow-sm text-sm font-medium text-red-500 hover:bg-white-light"
                    onClick={() => onClose()}
                  >
                    إلغاء
                  </button>
                </Fragment>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoneyRequestDetails;
