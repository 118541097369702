import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchMonthlyExpenses(safeId) {
    const params = {};
    if(safeId) params.safe_id = safeId;
    return useQuery(["monthlyExpenses", safeId], () => {
    return apiClient.get("/document/monthlyaverage", {params}).then((response) => {
      return response.data.total;
    })
  });
}
