import { useEffect, useState, useRef } from "react";

import Select, { components } from "react-select";

import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";

import Input from "../../common/inputs/Input";
import SelectMenu from "../../common/inputs/Select";
import Modal from "../../common/Modal";
import Alert from "../../common/Alert";

import ActiveDeactive from "../../resources/icons/ActiveDeactive";
import Email from "../../resources/icons/Email";
import BluePlay from "../../resources/icons/BluePlay";
import Trash from "../../resources/icons/Trash";
import Selector from "../../resources/icons/Selector";
import K from "../../resources/icons/K";
import UserRoleSelect from "../../resources/icons/UserRoleSelect";
import DeactivatRole from "../../resources/icons/DeactivatRole";
import Activate from "../../resources/icons/Activate";

import useFetchUserData from "../../../hooks/reactQuery/useFetchUserData";
import useFetchSafePermissions from "../../../hooks/reactQuery/useFetchSafePermissions";
import useFetchUsers from "../../../hooks/reactQuery/useFetchUsers";

import apiClient from "../../../services/apiClient";

import "./EditSafe.scss";
import Check from "../../resources/icons/Check";
import Breadcrumb from "../../common/Breadcrumb/Breadcrumb";
import useMutateEditSafe from "../../../hooks/reactQuery/useMutateEditSafe";
import useMutateActivateSafe from "../../../hooks/reactQuery/useMutateActivateSafe";
import { useTranslation } from "react-i18next";

const EditSafe = ({ onClose, currentEditSafe }) => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();

  const canEditSafe = userData?.safes.find(safe => safe.id == currentEditSafe.id).permissions.includes("EditSafe") || 
  userData?.safes.find(safe => safe.id == currentEditSafe.id).permissions.includes("CashFullControl");

  const canDeactivateSafe = userData?.safes.find(safe => safe.id == currentEditSafe.id).permissions.includes("DeactivateSafe") ||
  userData?.safes.find(safe => safe.id == currentEditSafe.id).permissions.includes("CashFullControl");
  
  const { data: permissions, isLoading: isPermissionsLoading } = useFetchSafePermissions(t, i18n);

  const [touched, setTouched] = useState(false);

  const usersContainerRef = useRef(null);

  const scrollToIndex = (e, index) => {
    if(e) {
      if(e.target.tagName === "svg" || e.target.tagName === "button" || e.target.tagName === "path") return;   
    }
    if(usersContainerRef && usersContainerRef.current && index) {
      if(usersContainerRef.current.children[index]) {
        usersContainerRef.current.scrollTop = usersContainerRef.current.children[index].offsetTop - usersContainerRef.current.offsetTop;
      }
    }
  }

  const {
    data: users,
    isLoading: isUsersLoading,
    isError: isUsersError,
  } = useFetchUsers(userData.id);

  const safeMembers = users?.map(obj => {
    return {
      ...obj, 
      label: obj.email
    }
  });

  const [usersOptions, setUsersOptions] = useState([]);

  useEffect(() => {
    setUsersOptions(safeMembers);
  }, [users]);


  const currentSafeMembers = currentEditSafe.users.map(user => {
    return {
      email: {...user},
      permissions: user.permissions ? permissions?.filter(obj => {
        const doesExist = user.permissions.find(id => obj.id == id);
        if(!doesExist) return false; else return true;
      }) : [permissions?.[0]],
      isAdmin: user.id == userData.activeCompany.owner_id,
      active: user.active,
      old: true,
      self: user.id === userData.id
    }
  }).filter(user => user.email.id != currentEditSafe.manager_id);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,
    getValues,
    reset,
  } = useForm({
    mode: "all",
    defaultValues: {
      manager_id: {id: currentEditSafe.manager_id, name: currentEditSafe.manager_name, profile_picture_ext: currentEditSafe.profile_picture_ext},
      addMembersForm: currentSafeMembers.length === 0 ? [
        {
          email: "",
          permissions: [permissions?.[0]],
        },
        {
          email: "",
          permissions: [permissions?.[0]],
        },
        {
          email: "",
          permissions: [permissions?.[0]],
        }
      ] : [...currentSafeMembers]
    },
  });

  useEffect(() => {
    const currSafeMembers = currentEditSafe.users.map(user => {
      return {
        email: {...user},
        permissions: user.permissions ? permissions?.filter(obj => {
          const doesExist = user.permissions.find(id => obj.id == id);
          if(!doesExist) return false; else return true;
        }) : [permissions?.[0]],
        active: user.active,
        old: true,
        self: user.id === userData.id
      }
    }).filter(user => user.email.id != currentEditSafe.manager_id);

    reset({
      addMembersForm: currSafeMembers.length > 0 ? currSafeMembers : [
        {
          email: "",
          permissions: [permissions?.[0]],
        },
        {
          email: "",
          permissions: [permissions?.[0]],
        },
        {
          email: "",
          permissions: [permissions?.[0]],
        }
      ],
      manager_id: {id: currentEditSafe.manager_id, name: currentEditSafe.manager_name, profile_picture_ext: currentEditSafe.profile_picture_ext}
    });
  }, [reset, permissions]);

  const watchAll = useWatch({ control });

  const { fields, append, remove, update, prepend } = useFieldArray({
    control,
    name: "addMembersForm",
  });

  const updateUsers = (e, index, obj) => {
    e.preventDefault();
    update(index, obj);
  }

  const addFieldSet = () => {
    append({
      email: "",
      permissions: [permissions?.[0]],
    });
    scrollToIndex(null, fields.length - 1);
  };

  const removeFieldSet = (fieldIndex) => {
    remove(fieldIndex);
  };


  const { isLoading: isMutateAddMemebersLoading, mutate: mutateEditSafe } =
    useMutateEditSafe(onClose, userData);

  const { isLoading: isMutateActivateSafeLoading, mutate: mutateActivateSafe } =
    useMutateActivateSafe(onClose, userData);

  const handleEditSafe = (data) => {


    const fullControlPermissions = permissions?.filter(obj => obj.tag !== "CashInWithPermission" && obj.tag !== "CashOutWithPermission" && obj.tag !== "CashReadOnly").map(obj => obj.id);

    let formUsers = data.addMembersForm?.filter(obj => obj.email !== "").map(obj => {
      return {
        id: obj.email.id,
        permissions: obj.permissions.map(obj => obj.id),
        active: obj.active
      }
    });

    formUsers = formUsers.filter(user => user.id != data.manager_id.id);  

    const managerExistsInUsers = formUsers.find(obj => obj.id === data.manager_id.id);

    if(!managerExistsInUsers) {
      formUsers.push({
        id: data.manager_id.id,
        permissions: fullControlPermissions,
        active: 1
      })
    }

    const formData = {
      users: formUsers,
      code: data.code,
      name: data.name,
      en_name: data.en_name,
      manager_id: parseInt(data.manager_id.id),
      safe_id: currentEditSafe.id
    }

    mutateEditSafe(formData);
  };

  const onManagerChange = (option) => {
    if(option.id != currentEditSafe.manager_id) {
      setTouched(true);
      const currentFields = (getValues("addMembersForm"));
      const findOldManagerInUsers = currentFields.find(obj => obj.email?.id == currentEditSafe.manager_id);

      //hide manager
      currentFields.forEach((value, index) => {
        if(value.email.id == option.id) update(index, {...value, manager: true});
        else update(index, {...value, manager: false});
      });

      if(!findOldManagerInUsers) {
        prepend({
          email: currentEditSafe.users.find(user => user.id == currentEditSafe.manager_id),
          permissions: [permissions?.[0], permissions?.[2]],
          active: 1,
          old: true
        });
      }
    }
    setUsersOptions(safeMembers.filter(obj => obj.id != option.id));
  }

  const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "unset",
      border: "none",
      outline: "none",
      boxShadow:
        state.menuIsOpen && isUsersError
          ? "0 0 0 2px #EF4444"
          : isUsersError
          ? "0 0 0 1px #FCA5A5"
          : state.menuIsOpen
          ? "0 0 0 2px #2563eb"
          : "0 0 0 1px #CBD5E1",
      transition: "none",
      maxHeight: "40px",
      overflow: "hidden",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "inherit",
      backgroundColor: (state.isFocused || state.isSelected) && "#F8FAFC",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: "none",
    }),
    menuList: (provided) => ({
      ...provided,
      overflow: "overlay",
      "::-webkit-scrollbar": {
        width: "10px",
        height: "10px",
      },
      "::-webkit-scrollbar-track": {
        backgroundColor: "#f8fafc",
        borderRadius: "8px 0 0 8px",
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "#cbd5e1",
        border: "1px solid #e7f5ff",
        borderRadius: "10px",
      },
    }),
  };

  const styles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "unset",
      outline: "none",
      height: "40px",
      width: "150px",
      border: "1px solid #CBD5E1",
      marginRight: "-5px",
      borderRadius: "6px",
      boxShadow: state.menuIsOpen ? "0 0 0 2px #2563eb" : "",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "inherit",
      backgroundColor: (state.isFocused || state.isSelected) && "#F8FAFC",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
    menu: (provided) => ({
      ...provided,
      padding: 0,
      position: "absolute",
      width: "250px",
      left: 0,
      zIndex: 1000
    }),
    menuList: (provided) => ({
      ...provided,
      padding: "0px 10px",      
      maxHeight: "250px",
      overflowY: "auto",
      "::-webkit-scrollbar": {
        width: "10px",
        height: "10px",
      },
      "::-webkit-scrollbar-track": {
        backgroundColor: "#f8fafc",
        borderRadius: "8px 0 0 8px",
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "#cbd5e1",
        border: "1px solid #e7f5ff",
        borderRadius: "10px",
      },
    }),
    loadingIndicator: (provided) => ({
      ...provided,
      position: "absolute",
      left: "47px",
    }),
  };

  const DropdownIndicator = () => (
    <div className="px-3 py-2 max-h-full">
      <UserRoleSelect />
    </div>
  );

  const SelectMenuControlIcon = ({ children, ...rest }) => (
    <components.Control {...rest}>
      <div className="px-2">
        <Selector />
      </div>

      {children}
    </components.Control>
  );

  const formatManagerOptionLabel = (data) => {
    return (
      <div className="flex items-center justify-between">
        <div className="flex items-center selection-container">
          <div className="flex-shrink-0 selection-hide">
            {data.profile_picture_ext ? (
              <img className="h-10 w-10 rounded-full" src={data.url} alt="user" />
            ) : (
              <div className="h-10 w-10 rounded-full flex justify-center items-center bg-blue-400">
                {data.name[0]}
              </div>
            )}
          </div>
          <div className="mr-3">
            <p className="text-sm font-medium text-gray-900 hover:underline">
              {data.name}
            </p>
            <div className="flex space-x-1 text-sm text-gray-500 selection-hide">
              <p>{data.user_name}</p>
            </div>
          </div>
        </div>
        <span className="mx-4 text-sm text-body" style={{color: "#999"}}>{data.role_name}</span>
      </div>
    );
  };

  const formatUsersOptionLabel = (data) => {
    return (
      <div className="flex items-center justify-between">
        <div className="flex items-center selection-container">
          <div className="flex-shrink-0 selection-hide">
            {data.profile_picture_ext ? (
              <img className="h-7 w-7 rounded-full" src={data.url} alt="user" />
            ) : (
              <div className="h-7 w-7 rounded-full flex justify-center items-center bg-blue-400">
                {data.name[0]}
              </div>
            )}
          </div>
          <div className="mr-4">
            <p className="text-sm font-medium text-gray-900 hover:underline">
              {data.email}
            </p>
            <div className="flex space-x-1 text-sm text-gray-500 selection-hide">
              <p>{data.name}</p>
              {currentEditSafe.manager_id == data.id ? <span className="mx-3 text-sm text-body" style={{color: "#999", margin: "0px 5px"}}>(مدير سابق)</span>: null}
            </div>
          </div>
        </div>
        <span className="mx-3 text-sm text-body" style={{color: "#999"}}>{data.role_name}</span>
      </div>
    );
  };

  const formatOptionLabel = (data, context) => {
    const selectedValues = context.selectValue.map(obj => obj.value);
    return (
      <div className="flex justify-between items-center">
        <div className="flex justify-center items-center gap-x-3 selection-container">
          <p
            className={`text-sm text-body selection-weight ${
              context.selectValue &&
              context.selectValue[0]?.value === data?.value
                ? "font-bold"
                : "font-medium"
            }`}
          >
            {data.label}
          </p>
        </div>
        {context.selectValue && selectedValues.includes(data?.value) && (
          <div>
            <Check />
          </div>
        )}
      </div>
    );
  };

  const [showAlertConfirmModal, setShowAlertConfirmModal] = useState(false);
  const [showAlertCancelModal, setShowAlertCancelModal] = useState(false);
  const [showActivateSafeModal, setShowActivateSafeModal] = useState(false);

  return (
    <div
      className="relative"
      onClick={() => (!isDirty ? onClose() : setShowAlertCancelModal(true))}
    >
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <Breadcrumb items={[{title: t("safes"), action: () => !isDirty ? onClose() : setShowAlertCancelModal(true)}, {title: t("edit_safe_dynamic", {safe_name: currentEditSafe.name}), action: () => {}}]} />
        <div className="bg-white add-safe-container">
          <form noValidate={true}>
            <div className="AddSafeInputRow">
              <div style={{width: "49%"}}>
                <Input
                  register={register}
                  Icon={K}
                  type="text"
                  name="code"
                  fieldName={t("safe_code")}
                  label={t("safe_code")}
                  placeholder={t("safe_code")}
                  hint={t("safe_code_no_edit")}
                  value={currentEditSafe.code}
                  isDisabled={true}
                  errors={errors?.code}
                />
              </div>
              <div style={{width: "49%"}}>
                <Input
                  register={register}
                  type="text"
                  name="name"
                  fieldName={t("safe_name")}
                  label={t("safe_name")}
                  defaultValue={currentEditSafe.name}
                  hint={t("safe_name_hint")}
                  required={true}
                  min="1"
                  max="50"
                  validate={{
                    validateRepetition: async (value) => {
                      if (value && value !== currentEditSafe.name) {
                        const { data } = await apiClient.get(
                          "safe/codenameexiest",
                          { params: { name: value, id: currentEditSafe.id } }
                        );

                        if(!data.exists) setTouched(true);

                        return data.exists
                          ? t("safe_name_exists")
                          : null;
                      }
                    },
                    validate: (value) => {
                      if (value) {
                        if (!value.match(/([a-zA-Z \u0621-\u064A])+([ -~])*/)) {
                          return t("safe_name_characters")
                        }
                        else {
                          setTouched(true)
                          return null;
                        }
                      }
                    },
                    validateLength: (value) => {
                      if (value.length > 50 || value.length < 3) {
                          return t("safe_name_characters_validation");
                      }
                      else {
                        setTouched(true);
                        return null;
                      }                      
                    },
                  }}
                  errors={errors?.name}
                />
              </div>
            </div>
            <div className="AddSafeInputRow">
              <div style={{width: "100%"}}>
                <Input
                  register={register}
                  type="text"
                  name="en_name"
                  fieldName={t("en_safe_name")}
                  defaultValue={currentEditSafe.en_name}
                  label={t("en_safe_name")}
                  placeholder={t("en_safe_name")}
                  hint={t("en_safe_name_hint")}
                  required={true}
                  validate={{
                    validateRepetition: async (value) => {
                      if (value) {
                        const { data } = await apiClient.get(
                          "safe/codenameexiest",
                          { params: { en_name: value, id: currentEditSafe.id } }
                        );

                        return data.exists
                          ? t("safe_name_exists")
                          : null;
                      }
                    },
                    validate: (value) => {
                      if (value) {
                        if (!value.match(/([a-zA-Z \u0621-\u064A])+([ -~])*/)) {
                          return t("safe_name_characters")
                        }
                        else {
                          return null
                        }
                      }
                    },
                    validateLength: (value) => {
                      if (value.length > 50 || value.length < 3) {
                          return t("safe_name_characters_validation");
                      }
                      else {
                        return null
                      }                      
                    },
                  }}
                  errors={errors?.en_name}
                />
              </div>
            </div>
            <div className="AddSafeInputRow">
              <div className="col-span-8 sm:col-span-4" style={{width: "100%"}}>
                <SelectMenu
                  register={register}
                  Icon={UserRoleSelect}
                  name="manager_id"
                  fieldName={t("manager")}
                  label={t("manager")}
                  placeholder={t("manager")}
                  hint={t("pick_safe_manager")}
                  options={users}
                  onChangeHandler={onManagerChange}
                  isLoading={isUsersLoading}
                  isError={isUsersError}
                  Controller={Controller}
                  control={control}
                  errors={errors?.manager_id}
                  formatOptionLabel={formatManagerOptionLabel}
                />
              </div>
            </div>
            <div className="usersContainerHeader">
              <span className="text-sm text-body font-medium">{t("safe_users")}</span>
              <span className="AddSafeFormSecondaryText">{t("safe_users_hint")}</span>
            </div>
            <div id="usersContainer" className="flex flex-col gap-y-4 px-4 pt-5 pb-6 scroll-bar usersContainerEdit" ref={usersContainerRef}>
              {fields.map((field, index) => {
                return (
                  <div key={field.id} className="grid grid-cols-12 gap-x-3" style={field.manager ? {display: "none"} : {}} onClick={(e) => scrollToIndex(e, index)}>
                    <div className="col-span-11">
                      <div className="flex items-end">
                        <div className="col-span-6 sm:col-span-3" style={{width: "80%", opacity: field.active || !field.old ? "1" : "0.3"}}>
                          <SelectMenu
                            register={register}
                            Icon={Email}
                            name={`addMembersForm[${index}].email`}
                            fieldName={t("email")}
                            label={t("email")}
                            options={usersOptions}
                            isLoading={isUsersLoading}
                            isError={isUsersError}
                            required={false}
                            isDisabled={field.old || field.isAdmin}
                            onOpenHandler={() => scrollToIndex(null, index)}
                            customStyles={selectStyles}
                            onChangeHandler={() => setTouched(true)}
                            validate={{
                              validateRepetition: async (value) => {
                                if (value && !field.old) {
                                  const isUserSharmoot =
                                    watchAll.addMembersForm.some(
                                      (field, i) =>
                                        field.email.email === value.email && i !== index
                                    );
                                  return isUserSharmoot
                                    ? t("email_exists")
                                    : null;
                                }
                                else return null;
                              },
                            }}
                            Controller={Controller}
                            control={control}
                            errors={errors?.addMembersForm &&
                            errors?.addMembersForm[index]?.email
                            }
                            formatOptionLabel={formatUsersOptionLabel}
                          />
                        </div>
                        <div style={{opacity: field.active || !field.old ? "1" : "0.5"}}>
                          <Controller
                            name={`addMembersForm[${index}].permissions`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value },
                            }) => {
                              return (
                                <Select
                                  placeholder={t("permissions")}
                                  defaultValue={field.old ? permissions?.filter(p => field.permissions?.find(obj => obj.id == p.id)) : [permissions?.[0]]}
                                  options={permissions}
                                  isLoading={isPermissionsLoading}
                                  styles={styles}
                                  components={{
                                    Control: SelectMenuControlIcon,
                                    DropdownIndicator,
                                  }}
                                  onChange={(selectedOption) => {
                                    if(!selectedOption || selectedOption.length < 1) return;
                                    const allSelectedTags = selectedOption.map(obj => obj.tag);
                                    let newSelectedOptions = selectedOption;

                                    if(selectedOption[selectedOption.length - 1].tag === "CashOutWithoutPermission" && allSelectedTags.includes("CashOutWithPermission")) 
                                      newSelectedOptions = selectedOption.filter(obj => obj.tag !== "CashOutWithPermission");
                                    else if(selectedOption[selectedOption.length - 1].tag === "CashOutWithPermission" && allSelectedTags.includes("CashOutWithoutPermission")) 
                                      newSelectedOptions = selectedOption.filter(obj => obj.tag !== "CashOutWithoutPermission" && obj.tag !== "CashFullControl");
                                    else if(selectedOption[selectedOption.length - 1].tag === "CashInWithPermission" && allSelectedTags.includes("CashInWithoutPermission")) 
                                      newSelectedOptions = selectedOption.filter(obj => obj.tag !== "CashInWithoutPermission" && obj.tag !== "CashFullControl");
                                    else if(selectedOption[selectedOption.length - 1].tag === "CashOutWithPermission" && allSelectedTags.includes("CashFullControl")) 
                                      newSelectedOptions = selectedOption.filter(obj => obj.tag !== "CashFullControl");
                                    else if(selectedOption[selectedOption.length - 1].tag === "CashInWithPermission" && allSelectedTags.includes("CashFullControl")) 
                                      newSelectedOptions = selectedOption.filter(obj => obj.tag !== "CashFullControl");
                                    else if(selectedOption[selectedOption.length - 1].tag === "CashInWithoutPermission" && allSelectedTags.includes("CashInWithPermission")) 
                                      newSelectedOptions = selectedOption.filter(obj => obj.tag !== "CashInWithPermission");
                                    else if(selectedOption[selectedOption.length -1].tag === "CashFullControl") 
                                      newSelectedOptions = permissions?.filter(obj => obj.tag !== "CashInWithPermission" && obj.tag !== "CashOutWithPermission");
                                    else if(selectedOption[selectedOption.length -1].tag === "CashReadOnly") 
                                      newSelectedOptions = permissions?.filter(obj => obj.tag === "CashReadOnly");
                                      
                                    if(selectedOption[selectedOption.length -1].tag !== "CashReadOnly")
                                      newSelectedOptions = newSelectedOptions.filter(obj => obj.tag !== "CashReadOnly") 
                                    setTouched(true);
                                    onChange(newSelectedOptions);
                                  }}
                                  value={value}
                                  //
                                  isMulti={true}
                                  controlShouldRenderValue={false}
                                  hideSelectedOptions={false}
                                  isSearchable={false}
                                  //
                                  isDisabled={!field.active && field.old || field.self || field.isAdmin}
                                  onBlur={(e) => onBlur(e)}
                                  formatOptionLabel={formatOptionLabel}
                                />
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {field.old && !field.self ? 
                    <button 
                      className="col-span-1 flex justify-center items-center"
                      style={{paddingTop: "20px"}}
                      onClick={(e) => {
                        updateUsers(e, index, {...field, active: field.active == 1 ? 0 : 1});
                        setTouched(true);
                      }}
                    >
                      <ActiveDeactive active={field.active} />
                    </button>
                    : !field.self ? <button
                      type="button"
                      className="col-span-1 flex justify-center items-center"
                      style={{paddingTop: "20px"}}
                      onClick={() => {
                        setTouched(true);
                        removeFieldSet(index);
                      }}
                      disabled={fields.length === 1}
                    >
                      <div
                        className={`${
                          fields.length === 1 ? "cursor-not-allowed" : ""
                        }`}
                      >
                        <Trash fillColor={fields.length === 1 ? "gray" : ""} />
                      </div>
                    </button> : null}
                  </div>
                );
              })}
            </div>
            <div className="col-span-3 col-start-8 flex justify-start items-center" style={{paddingBottom: "80px"}}>
                <button
                  type="button"
                  className="text-xs mx-4 my-4 left-5 font-medium rounded-md text-secondary bg-secondary border border-transparent hover:border-secondary bg-opacity-20 p-1.5"
                  onClick={addFieldSet}
                >
                  {t("add_new_cell")}
                </button>
            </div>
            <hr />
            <div className="p-4 grid grid-cols-10 gap-x-3 fixedFooter">
              <button
                type="button"
                className={`relative col-span-1 py-2 px-2 rounded-md text-base leading-5 font-medium ${
                  isMutateAddMemebersLoading || !isValid || !canEditSafe || !isDirty || !touched
                    ? "cursor-not-allowed bg-outline text-side-icons"
                    : "bg-secondary text-white"
                }`}
                style={{maxWidth: "200px"}}
                disabled={isMutateAddMemebersLoading || !isValid || !canEditSafe || !isDirty || !touched}
                onClick={() => setShowAlertConfirmModal(true)}
              >
                {t("save_edit_safe")}
              </button>

              <button
                type="button"
                className={`col-span-1 py-2 px-2 rounded-md text-base leading-5 font-medium border border-outline hover:border-secondary ${
                  isMutateAddMemebersLoading
                    ? "cursor-not-allowed bg-outline text-side-icons"
                    : "bg-white"
                }`}
                onClick={() =>
                  !isDirty ? onClose() : setShowAlertCancelModal(true)
                }
                disabled={isMutateAddMemebersLoading}
              >
                {t("cancel")}
              </button>
              {canDeactivateSafe && currentEditSafe.company_default != 1 ?
                <button
                  type="button"
                  className={`text-base leading-5 font-medium text-white relative px-2 py-2 rounded-md col-span-2 col-start-7 ${
                    currentEditSafe.active ? "bg-danger" : "bg-confirmation"
                  }`}
                  onClick={() => setShowActivateSafeModal(true)}
                >
                  {currentEditSafe.active ?  t("deactivate_safe") : t("reactivate_safe")}
                </button> : null }
                
                <Modal
                  showModal={showActivateSafeModal}
                  onClose={() => setShowActivateSafeModal(false)}
                >
                  {currentEditSafe?.active ? (
                    <>
                      <Alert
                        icon={DeactivatRole}
                        hasFooter={true}
                        alertType="cancel"
                        title={
                          <h3 className="text-lg leading-6 font-medium text-danger">
                            {t("deactivate_safe")}
                          </h3>
                        }
                        body={() => (
                          <div className="text-sm font-medium text-hints">
                            {t("alert_text_deactivate_safe")}
                          </div>
                        )}
                        confirmText={t("confirm")}
                        cancelText={t("cancel")}
                        handleConfirm={() => {
                          mutateActivateSafe({safe_id: currentEditSafe.id});
                          onClose();
                        }}
                        handleCancel={() =>
                          !isMutateAddMemebersLoading &&
                          setShowActivateSafeModal(false)
                        }
                        isSubmitLoading={isMutateAddMemebersLoading}
                      />
                    </>
                  ) : (
                    <>
                      <Alert
                        icon={Activate}
                        hasFooter={true}
                        alertType="confirm"
                        title={
                          <h3 className="text-lg leading-6 font-medium">
                            {t("activate_safe")}
                          </h3>
                        }
                        body={() => (
                          <div className="text-sm font-medium text-hints">
                            {t("reactivate_safe")}
                          </div>
                        )}
                        confirmText={t("confirm")}
                        cancelText={t("cancel")}
                        handleConfirm={() => {
                          mutateActivateSafe({safe_id: currentEditSafe.id});
                          onClose();
                        }}
                        handleCancel={() =>
                          !isMutateAddMemebersLoading &&
                          setShowActivateSafeModal(false)
                        }
                        isSubmitLoading={isMutateAddMemebersLoading}
                      />
                    </>
                    )}
                </Modal>
            </div>
          </form>
        </div>
      </div>
      <>
        <Modal
          showModal={showAlertConfirmModal}
          onClose={() => setShowAlertConfirmModal(false)}
        >
          <Alert
            icon={BluePlay}
            hasFooter={true}
            alertType="confirm"
            title={t("edit_safe")}
            body={() => (
              <div>
                {t("alert_text_confirm_edit")}
              </div>
            )}
            confirmText={t("edit")}
            cancelText={t("cancel")}
            isSubmitLoading={isMutateAddMemebersLoading}
            handleConfirm={handleSubmit(handleEditSafe)}
            handleCancel={() =>
              !isMutateAddMemebersLoading && setShowAlertConfirmModal(false)
            }
          />
        </Modal>
      </>

      <>
        <Modal
          showModal={showAlertCancelModal}
          onClose={() => setShowAlertCancelModal(false)}
        >
          <Alert
            hasFooter={true}
            alertType="cancel"
            title={t("cancel_edit_safe")}
            body={() => (
              <div>
              {t("alert_text_cancel_add_safe")}
              </div>
            )}
            confirmText={t("confirm")}
            cancelText={t("cancel")}
            handleConfirm={onClose}
            handleCancel={() => setShowAlertCancelModal(false)}
          />
        </Modal>
      </>
    </div>
  );
};

export default EditSafe;
