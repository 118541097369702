import { formatNumber } from "../../../../utils/formatNumbers";

export default function CustomizedYAxisTick(props) {
    const {x, y, payload} = props;
    const number = formatNumber(payload.value);
    return (
        <g transform={`translate(${x},${y})`}>
            <rect fill="#fff" stroke-width="2" height="30" width="60" y={-20} x={-55} stroke-linecap="null" stroke-linejoin="null" stroke-dasharray="null" stroke="#fff"/>
            <text x={-10} y={0} textAnchor="start" fill="#666">{number}</text>
        </g>
    )
};