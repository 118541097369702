import { useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useMutateCategory(userId, activeCompanyId) {
  const queryClint = useQueryClient();

  return useMutation(
    (newOption) => {
      let data = new FormData();
      data.append("name", newOption.label);
      data.append("creator_id", userId);
      data.append("company_id", activeCompanyId);
      return apiClient.post("category/add", data);
    },
    {
      // When mutate is called:
      onSuccess: (responseData) => {
        return queryClint.setQueryData("category", (data) => [
          {
            label: responseData.data.body.name,
            value: responseData.data.data.insertId,
            id: responseData.data.data.insertId,
            name: responseData.data.body.name,
            creator_id: responseData.data.body.creator_id,
            company_id: responseData.data.body.company_id,
          },
          ...data,
        ]);
      },
      onError: (e) => {
        return e;
      },
    }
  );
}
