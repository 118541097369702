const LatestInteract = () => {
  return (
    <div>
      <svg
        width={17}
        height={17}
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.95837 9.20825H8.50004"
          stroke="#6B7280"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.95837 12.0417H11.3334"
          stroke="#6B7280"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.91663 1.41675H6.37496C2.83329 1.41675 1.41663 2.83341 1.41663 6.37508V10.6251C1.41663 14.1667 2.83329 15.5834 6.37496 15.5834H10.625C14.1666 15.5834 15.5833 14.1667 15.5833 10.6251V7.08342"
          stroke="#6B7280"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.2917 3.54159C16.2917 5.10559 15.0223 6.37492 13.4583 6.37492C11.8943 6.37492 10.625 5.10559 10.625 3.54159C10.625 1.97759 11.8943 0.708252 13.4583 0.708252C15.0223 0.708252 16.2917 1.97759 16.2917 3.54159Z"
          stroke="#6B7280"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.1667 4.24992L13.6285 3.90822C13.5348 3.84911 13.4584 3.70689 13.4584 3.59053V2.83325"
          stroke="#6B7280"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default LatestInteract;
