export const UserSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17 19.6978C16.2834 19.9046 15.4365 20 14.443 20H9.557C8.56352 20 7.71661 19.9046 7 19.6978C7.17915 17.6302 9.35342 16 12 16C14.6466 16 16.8208 17.6302 17 19.6978Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.4 4H9.6C5.6 4 4 5.63099 4 9.70846V14.6014C4 17.684 4.912 19.3721 7.088 20C7.264 17.8797 9.4 16.2079 12 16.2079C14.6 16.2079 16.736 17.8797 16.912 20C19.088 19.3721 20 17.684 20 14.6014V9.70846C20 5.63099 18.4 4 14.4 4ZM12 13.9246C10.416 13.9246 9.136 12.6116 9.136 10.997C9.136 9.38228 10.416 8.07747 12 8.07747C13.584 8.07747 14.864 9.38228 14.864 10.997C14.864 12.6116 13.584 13.9246 12 13.9246Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 10.9958C15 12.6527 13.6592 14 12 14C10.3408 14 9 12.6527 9 10.9958C9 9.33893 10.3408 8 12 8C13.6592 8 15 9.33893 15 10.9958Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
