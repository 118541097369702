import { Fragment, useState, useEffect, useRef } from "react";
import { Menu, Transition } from "@headlessui/react";
import Shapes from "../../Shapes/index";
import Close from "../../../resources/icons/Close";
import CustomizeTable from "../../../resources/icons/CustomizeTable";
import useClickOutside from "../../../../hooks/useClickOutside";
import { useTranslation } from "react-i18next";

const CustomizableTable = ({
  activeTab,
  setColumns,
  originalColumnsHeader,
}) => {
  const [customizedTable, setCustomizedTable] = useState(originalColumnsHeader);
  const [selectAllCols, setSelectAllCols] = useState(false);

  const {t, i18n} = useTranslation();

  useEffect(() => {
    setCustomizedTable(originalColumnsHeader);
    setColumns(originalColumnsHeader.filter((el) => el.selected || el.pinned));
  }, [activeTab]);

  useEffect(() => {
    if (customizedTable.every((el) => el.selected)) {
      setSelectAllCols(true);
    } else {
      setSelectAllCols(false);
    }
  }, [customizedTable]);

  const [isMenuOpen, setMenuOpen] = useState(false);

  // close menu on click outside
  const clickRef = useRef();
  useClickOutside(clickRef, () => {
    setMenuOpen(false);
  });

  const formatShapesOption = (shape) => (
    <div className="p-2">{shape.customizeKey}</div>
  );

  return (
    <Menu as="div" className="relative inline-block text-left h-full">
      {({ open }) => (
        <>
          <Menu.Button>
            <button
              className={`h-10 customize-table-btn transition-all duration-300 border border-transparent hover:border-secondary-hover flex items-center justify-center rounded-md ${
                isMenuOpen ? "border-secondary-hover customize-table-btn-w" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                setMenuOpen((prevState) => !prevState);
              }}
            >
              <span className="absolute left-8 w-max">{t("limit_table_view")}</span>
              <div className="absolute left-1 customize-table">
                <CustomizeTable strokeColor={isMenuOpen && "#1D64D8"} />
              </div>
            </button>
          </Menu.Button>

          <div ref={clickRef}>
            <Transition
              show={isMenuOpen}
              as={Fragment}
              enter="transition easIn duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                style={{ width: "621px" }}
                className={`z-10 origin-top-right absolute ${i18n.resolvedLanguage === "en" ? "right-0" : "left-0"} mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
              >
                <div className="grid bg-white">
                  <div className="py-6 px-6 border-b border-outline flex items-center justify-between">
                    <div className="flex items-center gap-x-2">
                      <div>
                        <CustomizeTable />
                      </div>
                      <span className="text-base leading-5 text-body font-bold">
                        {t("limit_table_view")}
                      </span>
                    </div>
                    <button
                      onClick={() => {
                        setMenuOpen(false);
                      }}
                    >
                      <Close />
                    </button>
                  </div>
                  <div className="py-6 px-6 border-b border-outline flex flex-col gap-y-4">
                    <div className="flex items-center gap-x-2">
                      <span className="text-sm text-body font-medium">
                      {t("select_specific_info")}  
                      </span>
                    </div>
                    <div className="flex flex-wrap gap-x-2 gap-y-4">
                      <Shapes
                        shapes={customizedTable}
                        setShapes={setCustomizedTable}
                        formatShapesOption={formatShapesOption}
                      />
                    </div>
                  </div>
                  <div className="border-b border-outline flex items-center gap-x-2 px-6 py-4">
                    <input
                      type="checkbox"
                      id="selectAllCols"
                      name="selectAllCols"
                      checked={selectAllCols}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setCustomizedTable((prevOpts) =>
                            prevOpts.map((opt) => {
                              opt.selected = true;
                              return opt;
                            })
                          );
                        }
                        setSelectAllCols(e.target.checked);
                      }}
                    />
                    <label
                      htmlFor="selectAllCols"
                      className="text-sm text-body font-medium cursor-pointer select-none"
                    >
                    {t("show_all_info")} 
                    </label>
                  </div>
                  <div className="flex justify-between items-center px-6 py-3">
                    <div>
                      <button
                        className="py-2 px-10 bg-secondary rounded text-white font-bold text-base leading-4"
                        onClick={() => {
                          setColumns((prevOpts) => {
                            return customizedTable?.filter(
                              (el) => el.selected || el.pinned
                            );
                          });
                          setMenuOpen(false);
                        }}
                      >
                        {t("confirm")}
                      </button>
                      <button
                        className="text-base leading-5 font-bold text-hints px-10"
                        onClick={() => {
                          setMenuOpen(false);
                        }}
                      >
                        {t("cancel")}
                      </button>
                    </div>
                    <button
                      className="text-sm font-medium text-hints"
                      onClick={() => {
                        setCustomizedTable(originalColumnsHeader);

                        setColumns(
                          originalColumnsHeader.filter(
                            (el) => el.selected || el.pinned
                          )
                        );

                        setMenuOpen(false);
                      }}
                    >
                    {t("reset_all")}
                    </button>
                  </div>
                </div>
              </Menu.Items>
            </Transition>
          </div>
        </>
      )}
    </Menu>
  );
};

export default CustomizableTable;
