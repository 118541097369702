import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchCashoutCount() {
  return useQuery("cashoutCount", () =>
    apiClient
      ?.get(`money_request/cashoutnotificationscount`)
      .then(({ data }) => data)
      .catch((e) => e)
  );
}
