import Input from "../../../inputs/Input";
import { useTranslation } from "react-i18next";

const Amount = ({ register, fromAmount, toAmount }) => {
  const {t, i18n} = useTranslation();

  const amountInputs = [
    { name: "fromAmount", defaultValue: fromAmount.originalFromAmount },
    { name: "toAmount", defaultValue: toAmount.originalToAmount },
  ];

  return (
    <div className="mt-10 px-6 grid grid-cols-3 gap-4" dir={i18n.resolvedLanguage === "en" ? "ltr" : "rtl"}>
      {amountInputs.map((input) => (
        <div>
          {input.name === "fromAmount" ? <Input
            register={register}
            type="number"
            name={input.name}
            fieldName={t("from")}
            label={t("from")}
            hint={t("from_amount_hint")}
            defaultValue={input.defaultValue}
            required={false}
            min="0"
            max={10000000}
          />
          : <Input
          register={register}
          type="number"
          name={input.name}
          fieldName={t("to")}
          label={t("to")}
          hint={t("to_amount_hint")}
          defaultValue={input.defaultValue}
          required={false}
          min="0"
          max={10000000}
        />}
        </div>
      ))}
    </div>
  );
};

export default Amount;
