import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useMutateAcceptPaymentLicense(
  setShowRespond,
  userId,
  activeCompanyId
) {
  const queryClient = useQueryClient();

  return useMutation(
    (activeRow) => {
      const formData = new FormData();

      formData.append("id", activeRow.id);
      formData.append("approved", 1);

      formData.append("created_by", activeRow.created_by);
      formData.append("user_id", userId);
      formData.append("company_id", activeCompanyId);

      return apiClient.post("spend_request/accept", formData);
    },
    {
      onSuccess: () => {
        toast.info("تم قبول إذن الصرف.", { autoClose: 3000 });
        queryClient.invalidateQueries("getPayments");
        queryClient.invalidateQueries("getSingleSpendRequest");
        queryClient.invalidateQueries("userData");
        queryClient.invalidateQueries("userBalance");
        setShowRespond(false);
      },
      onError: (error) => {
        setShowRespond(false);
        return error;
      },
    }
  );
}
