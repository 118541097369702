import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchSingleSpendRequest(id) {
  return useQuery(
    [
      "getSingleSpendRequest",
      id
    ],
    () => {
      const params = {}
      return apiClient
        .get(`/spend_request/load/${id}`, {
          params,
        })
        .then(({ data }) => {
          const creator = {
            id: data[0].creator,
            name: data[0].creator_name,
            profile_picture_ext: data[0].profile_picture_ext
          };
          const recipient = data[0].recipient_id ? {
            id: data[0].recipient,
            name: data[0].recipient_name,
            profile_picture_ext: data[0].recipient_profile_picture_ext
          } : null;

          const usersArray = [creator];
          if(recipient) usersArray.push(recipient);

          const newData = {
            ...data[0],
            sender_name: data[0].creator_name,
            category: data[0].category_name,
            users: usersArray
          }
          return [newData];
        });
    }
  );
}
