const Search = () => {
  return (
    <div>
      <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 4C14.2091 4 16 5.79086 16 8C16 10.2091 14.2091 12 12 12C9.79086 12 8 10.2091 8 8C8 5.79086 9.79086 4 12 4ZM18 8C18 4.68629 15.3137 2 12 2C8.68629 2 6 4.68629 6 8C6 9.29583 6.41079 10.4957 7.10925 11.4765L2.29289 16.2929C1.90237 16.6834 1.90237 17.3166 2.29289 17.7071C2.68342 18.0976 3.31658 18.0976 3.70711 17.7071L8.52347 12.8907C9.50428 13.5892 10.7042 14 12 14C15.3137 14 18 11.3137 18 8Z"
          fill="#CBD5E1"
        />
      </svg>
    </div>
  );
};

export default Search;
