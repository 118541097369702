import { toolPin } from "../../../utils/toolPin";

import TableSortIcon from "../../../components/resources/icons/TableSortIcon";
import KeyIcon from "../../../components/resources/icons/KeyIcon";
import CashAmountIcon from "../../../components/resources/icons/CashAmountIcon";
import CategoryIcon from "../../../components/resources/icons/CategoryIcon";
import MainFinancial from "../../../components/resources/icons/MainFinancial";
import Receipt from "../../../components/resources/icons/Receipt";
import InputDate from "../../../components/resources/icons/InputDate";
import TruncateNotes from "../../../components/common/truncate/TruncateNotes";
import TableMenu from "../../../components/common/TableMenu";
import ActiveDeactive from "../../../components/resources/icons/ActiveDeactive";

export const columnsHeaderArr = (
  setActiveRow,
  setActiveDeactiveId, 
  setShowDeactivateModal, 
  userData, 
  hoverId,
  t,
  i18n
  ) => [
  {
    title: () => (
      <div class="flex items-center justify-center">
        <div>
          <KeyIcon />
        </div>
        <div className="col-title">{t("transaction_code")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "id",
    key: "id",
    sorter: true,
    render: (text, row) => <div className="col-desc flex justify-center" style={row.deactivated ? {opacity: 0.5} : {}}>{toolPin(String(text))}</div>,
    width: 100,
    pinned: true,
    selected: true,
    customizeKey: t("transaction_code"),
  },
  {
    title: () => (
      <div class="flex items-center justify-center">
        <div>
          <CashAmountIcon />
        </div>
        <div className="col-title">{t("amount")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "amount",
    key: "amount",
    sorter: true,
    render: (text, row) => <div className="col-desc flex justify-center" style={row.deactivated ? {opacity: 0.5} : {}}>{toolPin(String(text))}</div>,
    width: 100,
    selected: true,
    customizeKey: t("amount"),
  },
  {
    title: () => (
      <div class="flex items-center justify-center">
        <div>
          <CategoryIcon />
        </div>
        <div className="col-title">{t("category")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "category",
    key: "category",
    sorter: true,
    render: (text, row) => (
      <div className="text-sm text-body font-medium bg-sidebar-background p-2 rounded-xl flex justify-center items-center border border-transparent hover:border-secondary" style={row.deactivated ? {opacity: 0.5} : {}}>
        {toolPin(text)}
      </div>
    ),
    width: 150,
    selected: true,
    customizeKey: t("category"),
  },
  {
    title: () => (
      <div class="flex items-center justify-center">
        <div>
          <MainFinancial />
        </div>
        <div className="col-title">{t("parent_money_request")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "money_request_name",
    key: "money_request_name",
    sorter: true,
    onCell: (record, rowIndex) => {
      return {
        onClick: (event) => {
          event.stopPropagation();
          setActiveRow(record);
        },
      };
    },
    render: (text, row) => (
      <div
        role="button"
        className="flex justify-center text-sm text-body font-medium bg-sidebar-background p-2 rounded-xl flex justify-center items-center"
        style={row.deactivated ? {opacity: 0.5} : {}}
      >
        {toolPin(text|| `${t("from_safe")} ${row.safe_name}`)}
      </div>
    ),
    width: 150,
    selected: true,
    customizeKey: t("parent_money_request"),
  },
  {
    title: () => (
      <div class="flex items-center justify-center">
        <div>
          <Receipt />
        </div>
        <div className="col-title">{t("document_type")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "periodic",
    key: "periodic",
    sorter: true,
    render: (text, row) => (
      <div className="col-desc flex justify-center" style={row.deactivated ? {opacity: 0.5} : {}}>
        {toolPin(text === 1 ? t("periodic") : t("rare"))}
      </div>
    ),
    width: 150,
    pinned: true,
    selected: true,
    customizeKey: t("document_type"),
  },
  {
    title: () => (
      <div class="flex items-center justify-center">
        <div>
          <InputDate />
        </div>
        <div className="col-title flex justify-center">{t("spend_date")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "payment_date",
    key: "payment_date",
    sorter: true,
    render: (text, row) => (
      <div className="col-desc flex justify-center" style={row.deactivated ? {opacity: 0.5} : {}}>{toolPin(text.split("T")[0])}</div>
    ),
    width: 100,
    selected: true,
    customizeKey: t("spend_date"),
  },
  {
    title: () => (
      <div class="flex items-center justify-center">
        <div>
          <InputDate />
        </div>
        <div className="col-title">{t("creation_date")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "creation_date",
    key: "creation_date",
    sorter: true,
    render: (text, row) => (
      <div className="col-desc flex justify-center" style={row.deactivated ? {opacity: 0.5} : {}}>{toolPin(text.split("T")[0])}</div>
    ),
    width: 100,
    selected: false,
    customizeKey: t("creation_date"),
  },
  {
    title: () => (
      <div class="flex items-center justify-center">
        <div className="col-title">{t("transaction_notes")}</div>
      </div>
    ),
    dataIndex: "description",
    key: "description",
    render: (text, row) => (
      <div className="flex justify-center" style={row.deactivated ? {opacity: 0.5} : {}}>
        <TruncateNotes row={row} propertyName="description" />
      </div>
    ),
    width: 150,
    selected: false,
    customizeKey: t("transaction_notes"),
  },
  {
    title: "",
    dataIndex: "",
    key: "",
    render: (text, row) => (
      <TableMenu 
        showButton={true}
        options={[
          {
            icon: <ActiveDeactive active={1} fill="#1E293B" />,
            text: t("suspend"),
            action: () => {
              setActiveDeactiveId(row.id);
              setShowDeactivateModal(true);
            },
            exists: row.deactivated != 1 && row.creator == userData.id
          }
        ].filter(item => item.exists)} />
    ),
    width: 100,
    selected: true,
  },
];
