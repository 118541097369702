const Timer = ({}) => {
  return (
    <div>
      <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.7 18.9596H7.30002C5.72502 18.9596 4.57502 18.3013 4.13335 17.168C3.67502 15.9846 4.10001 14.518 5.20002 13.5263L9.06668 10.0013L5.20002 6.4763C4.10001 5.48464 3.67502 4.01797 4.13335 2.83464C4.57502 1.69297 5.72502 1.04297 7.30002 1.04297H12.7C14.275 1.04297 15.425 1.7013 15.8667 2.83464C16.325 4.01797 15.9 5.48464 14.8 6.4763L10.9333 10.0013L14.8083 13.5263C15.9 14.518 16.3333 15.9846 15.875 17.168C15.425 18.3013 14.275 18.9596 12.7 18.9596ZM10 10.843L6.04168 14.443C5.34168 15.0846 5.03335 16.018 5.30002 16.7096C5.55002 17.3513 6.25835 17.7096 7.30002 17.7096H12.7C13.7417 17.7096 14.45 17.3596 14.7 16.7096C14.9667 16.018 14.6667 15.0846 13.9583 14.443L10 10.843ZM7.30002 2.29297C6.25835 2.29297 5.55002 2.64297 5.30002 3.29297C5.03335 3.98464 5.33335 4.91797 6.04168 5.55964L10 9.15964L13.9583 5.55964C14.6583 4.91797 14.9667 3.98464 14.7 3.29297C14.45 2.6513 13.7417 2.29297 12.7 2.29297H7.30002Z"
          fill="#6B7280"
        />
      </svg>
    </div>
  );
};

export default Timer;
