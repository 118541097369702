import { Fragment, useEffect, useState } from "react";

const BellSvg = (props) => {
  const [hover, setHover] = useState(false);

  if(props.active) {
    return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" onMouseLeave={() => setHover(false)} onMouseOver={() => setHover(true)}>
      <path d="M9.01987 18.5299C6.68987 18.5299 4.35987 18.1599 2.14987 17.4199C1.30987 17.1299 0.669869 16.5399 0.389869 15.7699C0.0998691 14.9999 0.199869 14.1499 0.659869 13.3899L1.80987 11.4799C2.04987 11.0799 2.26987 10.2799 2.26987 9.80992V6.91992C2.26987 3.19992 5.29987 0.169922 9.01987 0.169922C12.7399 0.169922 15.7699 3.19992 15.7699 6.91992V9.80992C15.7699 10.2699 15.9899 11.0799 16.2299 11.4899L17.3699 13.3899C17.7999 14.1099 17.8799 14.9799 17.5899 15.7699C17.2999 16.5599 16.6699 17.1599 15.8799 17.4199C13.6799 18.1599 11.3499 18.5299 9.01987 18.5299ZM9.01987 1.66992C6.12987 1.66992 3.76987 4.01992 3.76987 6.91992V9.80992C3.76987 10.5399 3.46987 11.6199 3.09987 12.2499L1.94987 14.1599C1.72987 14.5299 1.66987 14.9199 1.79987 15.2499C1.91987 15.5899 2.21987 15.8499 2.62987 15.9899C6.80987 17.3899 11.2399 17.3899 15.4199 15.9899C15.7799 15.8699 16.0599 15.5999 16.1899 15.2399C16.3199 14.8799 16.2899 14.4899 16.0899 14.1599L14.9399 12.2499C14.5599 11.5999 14.2699 10.5299 14.2699 9.79992V6.91992C14.2699 4.01992 11.9199 1.66992 9.01987 1.66992Z" fill="#3B82F6"/>
    </svg>
    );
  }
  else if (hover) {
    return (
      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" onMouseLeave={() => setHover(false)} onMouseOver={() => setHover(true)}>
        <path d="M15.3419 12.49L14.3419 10.83C14.1319 10.46 13.9419 9.76 13.9419 9.35V6.82C13.9419 4.47 12.5619 2.44 10.5719 1.49C10.0519 0.57 9.0919 0 7.9919 0C6.9019 0 5.9219 0.59 5.4019 1.52C3.4519 2.49 2.1019 4.5 2.1019 6.82V9.35C2.1019 9.76 1.9119 10.46 1.7019 10.82L0.691896 12.49C0.291896 13.16 0.201896 13.9 0.451896 14.58C0.691896 15.25 1.2619 15.77 2.0019 16.02C3.9419 16.68 5.9819 17 8.0219 17C10.0619 17 12.1019 16.68 14.0419 16.03C14.7419 15.8 15.2819 15.27 15.5419 14.58C15.8019 13.89 15.7319 13.13 15.3419 12.49Z" fill="#3B82F6"/>
      </svg>
    );
  }
  else {
    return (
      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" onMouseLeave={() => setHover(false)} onMouseOver={() => setHover(true)}>
        <path d="M15.3419 12.49L14.3419 10.83C14.1319 10.46 13.9419 9.76 13.9419 9.35V6.82C13.9419 4.47 12.5619 2.44 10.5719 1.49C10.0519 0.57 9.0919 0 7.9919 0C6.9019 0 5.9219 0.59 5.4019 1.52C3.4519 2.49 2.1019 4.5 2.1019 6.82V9.35C2.1019 9.76 1.9119 10.46 1.7019 10.82L0.691896 12.49C0.291896 13.16 0.201896 13.9 0.451896 14.58C0.691896 15.25 1.2619 15.77 2.0019 16.02C3.9419 16.68 5.9819 17 8.0219 17C10.0619 17 12.1019 16.68 14.0419 16.03C14.7419 15.8 15.2819 15.27 15.5419 14.58C15.8019 13.89 15.7319 13.13 15.3419 12.49Z" fill="#292D32"/>
      </svg>
    );
  }
};

export default BellSvg;
