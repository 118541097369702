import { useEffect, useState } from "react";

const Trash = ({ fillColor }) => {
  const [hovered, setHovered] = useState(false);
 
  return (
    <div onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      {!hovered ? 
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
          fill={fillColor ? fillColor : "#DC2626"}
        />
        <path
          d="M17.899 9.79902C17.879 9.79902 17.849 9.79902 17.829 9.79902C14.369 9.45902 10.9291 9.31902 7.51905 9.66902L6.17904 9.79902C5.75904 9.83902 5.39905 9.53902 5.35905 9.12902C5.31905 8.71902 5.61904 8.34901 6.02904 8.30901L7.36904 8.17901C10.879 7.82901 14.419 7.95901 17.979 8.30901C18.389 8.34901 18.689 8.71902 18.649 9.12902C18.609 9.50902 18.279 9.79902 17.899 9.79902Z"
          fill={fillColor ? fillColor : "#DC2626"}
        />
        <path
          d="M14.2895 9.14093C13.9295 9.14093 13.6095 8.88094 13.5495 8.52094L13.4095 7.67093C13.3895 7.53093 13.3495 7.31093 13.3095 7.26093C13.3095 7.26093 13.2095 7.20093 12.8495 7.20093H11.1295C10.7595 7.20093 10.6595 7.26093 10.6595 7.26093C10.6395 7.30093 10.5995 7.52093 10.5795 7.66093L10.4395 8.52094C10.3695 8.93094 9.9795 9.20093 9.5795 9.14093C9.1695 9.07093 8.8995 8.68093 8.9595 8.28093L9.0995 7.42093C9.2095 6.78093 9.3895 5.71094 11.1295 5.71094H12.8495C14.5995 5.71094 14.7795 6.83092 14.8795 7.43092L15.0195 8.28093C15.0895 8.69093 14.8095 9.08093 14.4095 9.14093C14.3795 9.14093 14.3295 9.14093 14.2895 9.14093Z"
          fill={fillColor ? fillColor : "#DC2626"}
        />
        <path
          d="M14.1 18.3091H9.89C7.36 18.3091 7.24999 16.7891 7.17999 15.7791L6.75 9.16914C6.72 8.75914 7.03999 8.39914 7.44999 8.36914C7.86999 8.33914 8.22 8.65915 8.25 9.06915L8.67999 15.6691C8.74999 16.6491 8.76 16.7991 9.89 16.7991H14.1C15.23 16.7991 15.24 16.6491 15.31 15.6691L15.74 9.06915C15.77 8.65915 16.11 8.33914 16.54 8.36914C16.95 8.39914 17.27 8.74914 17.24 9.16914L16.81 15.7691C16.74 16.7891 16.64 18.3091 14.1 18.3091Z"
          fill={fillColor ? fillColor : "#DC2626"}
        />
      </svg>
      :
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 5C0 2.23858 2.23858 0 5 0H19C21.7614 0 24 2.23858 24 5V19C24 21.7614 21.7614 24 19 24H5C2.23858 24 0 21.7614 0 19V5Z" fill="#FFD4D4"/>
        <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z" fill="#DC2626"/>
        <path d="M17.9 9.8C17.88 9.8 17.85 9.8 17.83 9.8C14.37 9.46 10.93 9.31999 7.52003 9.66999L6.18002 9.8C5.76002 9.84 5.40002 9.54 5.36002 9.13C5.32002 8.72 5.62002 8.34999 6.03002 8.30999L7.37002 8.17999C10.88 7.82999 14.42 7.95999 17.98 8.30999C18.39 8.34999 18.69 8.72 18.65 9.13C18.61 9.51 18.28 9.8 17.9 9.8Z" fill="#DC2626"/>
        <path d="M14.29 9.13995C13.93 9.13995 13.61 8.87996 13.55 8.51996L13.41 7.66995C13.39 7.52995 13.35 7.30995 13.31 7.25995C13.31 7.25995 13.21 7.19995 12.85 7.19995H11.13C10.76 7.19995 10.66 7.25995 10.66 7.25995C10.64 7.29995 10.6 7.51996 10.58 7.65996L10.44 8.51996C10.37 8.92996 9.97999 9.19995 9.57999 9.13995C9.16999 9.06995 8.89999 8.67995 8.95999 8.27995L9.09999 7.41995C9.20999 6.77995 9.38999 5.70996 11.13 5.70996H12.85C14.6 5.70996 14.78 6.82995 14.88 7.42995L15.02 8.27995C15.09 8.68995 14.81 9.07995 14.41 9.13995C14.38 9.13995 14.33 9.13995 14.29 9.13995Z" fill="#DC2626"/>
        <path d="M14.1 18.3101H9.89C7.36 18.3101 7.24999 16.7901 7.17999 15.7801L6.75 9.17012C6.72 8.76012 7.03999 8.40011 7.44999 8.37011C7.86999 8.34011 8.22 8.66013 8.25 9.07013L8.67999 15.6701C8.74999 16.6501 8.76 16.8001 9.89 16.8001H14.1C15.23 16.8001 15.24 16.6501 15.31 15.6701L15.74 9.07013C15.77 8.66013 16.11 8.34011 16.54 8.37011C16.95 8.40011 17.27 8.75012 17.24 9.17012L16.81 15.7701C16.74 16.7901 16.64 18.3101 14.1 18.3101Z" fill="#DC2626"/>
      </svg>}
    </div>

  );
};

export default Trash;
