import React, { useState } from "react";

import { useForm, Controller } from "react-hook-form";

import Input from "../common/inputs/Input";
import SelectMenu from "../common/inputs/Select";
import UploadInput from "../common/inputs/Upload";
import CloseSvg from "../resources/close svg/CloseSvg";

import useFetchSpecializations from "../../hooks/reactQuery/useFetchSpecializations";
import useFetchResponsibilities from "../../hooks/reactQuery/useFetchResponsibilities";
import useFetchMembersCount from "../../hooks/reactQuery/useFetchMembersCount";
import useMutateWorkspace from "../../hooks/reactQuery/useMutateWorkspace";

import "./WorkSpace.scss";

import { useTranslation } from "react-i18next";


const AddWorkspace = ({ onClose }) => {
  const [file, setFile] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState(
    "img/upload-profile.png"
  );

  const {t, i18n} = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
    control,
    trigger,
  } = useForm({
    mode: "all",
  });

  const {
    data: specializations,
    isLoading: isSpecializationsLoading,
    error: specializationsError,
  } = useFetchSpecializations(t, i18n);

  const {
    data: responsibilities,
    isLoading: isResponsibilitiesLoading,
    error: responsibilitiesError,
  } = useFetchResponsibilities(t, i18n);

  const {
    data: membersCount,
    isLoading: isMembersCountLoading,
    error: membersCountError,
  } = useFetchMembersCount();

  const {
    isLoading: isWorkspaceMutationLoading,
    mutate: workspaceMutation,
    status: workspaceMutationStatus,
  } = useMutateWorkspace(onClose);

  const handleAddWorkSpace = (data) => {
    data.file = file;
    workspaceMutation(data);
  };
  const photoPreview = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        setFile(file);
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="overlay" 
    dir={i18n.resolvedLanguage === "en" ? "ltr" : "rtl"}
    onClick={!isWorkspaceMutationLoading && onClose}
    >
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <div className="bg-white lg:grid lg:grid-cols-12 lg:gap-x-5 work-space-container">
          <form
            className="col-span-12"
            onSubmit={handleSubmit(handleAddWorkSpace)}
          >
            <div className="sm:rounded-md sm:overflow-hidden">
              <div className="relative text-center p-4">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                {t("new_workspace")}  
                </h3>
                <button
                  type="button"
                  className="absolute top-4 left-4"
                  onClick={onClose}
                >
                  <div>
                    <CloseSvg />
                  </div>
                </button>
              </div>
              <hr />
              <div className="bg-gray-50 py-6 px-4 space-y-6 sm:p-6">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {t("new_workspace_text_1")}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                  {t("new_workspace_text_2")}
                  </p>
                </div>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6">
                    <div className="flex flex-col justify-center items-center select-none">
                      <div>
                        <label
                          className="flex flex-col justify-center items-center relative cursor-pointer"
                          htmlFor="workSpaceFile"
                        >
                          <div className="w-16 h-16">
                            <img
                              className="w-full h-full object-cover rounded-lg"
                              alt="upload workspace image"
                              src={imagePreviewUrl}
                            />
                          </div>
                        </label>
                      </div>
                      <UploadInput
                        multiple={false}
                        accept="image/*"
                        name="workSpaceFile"
                        id="workSpaceFile"
                        hint={t("pick_profile_pic")}
                        showUplodedFilesNames={false}
                        getValues={getValues}
                        fileSize="5000000"
                        errors={errors.workSpaceFile}
                        Controller={Controller}
                        control={control}
                        setValue={setValue}
                        trigger={trigger}
                        photoPreview={photoPreview}
                      />
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <Input
                      register={register}
                      type="text"
                      name="companyName"
                      fieldName={t("company_name")}
                      placeholder={t("company_name")}
                      required={true}
                      maxLength="50"
                      errors={errors.companyName}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <Input
                      register={register}
                      type="text"
                      name="en_name"
                      fieldName={t("company_en_name")}
                      placeholder={t("company_en_name")}
                      required={true}
                      maxLength="50"
                      errors={errors.en_name}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <SelectMenu
                      name="companySpecialization"
                      fieldName={t("company_specialization")}
                      placeholder={t("company_specialization")}
                      isLoading={isSpecializationsLoading}
                      options={specializations}
                      Controller={Controller}
                      control={control}
                      required={true}
                      errors={errors?.companySpecialization}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <SelectMenu
                      name="companyResponsibility"
                      fieldName={t("company_responsibility")}
                      placeholder={t("company_responsibility")}
                      isLoading={isResponsibilitiesLoading}
                      options={responsibilities}
                      Controller={Controller}
                      control={control}
                      required={true}
                      errors={errors?.companyResponsibility}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <SelectMenu
                      name="companyMembers"
                      fieldName={t("company_members")}
                      placeholder={t("company_members")}
                      isLoading={isMembersCountLoading}
                      options={membersCount}
                      Controller={Controller}
                      control={control}
                      required={true}
                      errors={errors?.companyMembers}
                    />
                  </div>
                </div>
              </div>
              <hr />

              <div className="grid sm:grid-cols-6 gap-5 px-4 py-3 bg-white text-right sm:px-6">
                <button
                  type="submit"
                  className={`col-span-1 sm:col-start-3 gap-6 bg-black py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white  ${
                    !isValid || isWorkspaceMutationLoading
                      ? "cursor-not-allowed disabled bg-opacity-50"
                      : ""
                  }`}
                  disabled={!isValid || isWorkspaceMutationLoading}
                >
                  {t("confirm")}
                </button>

                <button
                  type="button"
                  className={`col-span-1 sm:col-start-4 gap-6 bg-white py-2 px-4 border border-red-400 rounded-md shadow-sm text-sm font-medium text-red-500 hover:bg-gray-50 ${
                    isWorkspaceMutationLoading &&
                    "cursor-not-allowed disabled bg-opacity-50"
                  }`}
                  disabled={isWorkspaceMutationLoading}
                  onClick={onClose}
                >
                  {t("cancel")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddWorkspace;
