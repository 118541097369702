import React, { Fragment, useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import useCollapse from "react-collapsed";

import Input from "../../../common/inputs/Input";
import Modal from "../../../common/Modal";
import Alert from "../../../common/Alert";
import Close from "../../../resources/icons/Close";
import AddNewRoleIcon from "../../../resources/icons/AddNewRoleIcon";
import CollapseArrow from "../../../resources/icons/CollapseArrow";
import InfoCircle from "../../../resources/icons/InfoCircle";

import useFetchUserData from "../../../../hooks/reactQuery/useFetchUserData";
import useFetchPermissions from "../../../../hooks/reactQuery/useFetchPermissions";
import useMutateAddNewRole from "../../../../hooks/reactQuery/useMutateAddNewRole";
import useMutateEditRole from "../../../../hooks/reactQuery/useMutateEditRole";
import useFetchCompanyRoles from "../../../../hooks/reactQuery/useFetchCompanyRoles";
import Spinner from "../../../common/Spinner";
import FieldError from "../../../resources/icons/FieldError";
import DeactivatRole from "../../../resources/icons/DeactivatRole";
import ActivatRole from "../../../resources/icons/ActivatRole";
import CancelAddRole from "../../../resources/icons/CancelAddRole";
import EditRole from "../../../resources/icons/EditRole";
import { useTranslation } from "react-i18next";

const AddNewRole = ({ onClose, selectedRole }) => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();
  const [simRole, setSimRole] = useState({});

  const {
    data: permsData,
    isLoading: isPermsLoading,
    isError: isPermsError,
  } = useFetchPermissions(selectedRole, simRole);

  const [perms, setPerms] = useState([]);
  useEffect(() => {
    setPerms(() => permsData);
  }, [permsData]);

  // Confirm modal
  const [showAlertConfirmModal, setShowAlertConfirmModal] = useState(false);
  // Cancel Modal
  const [showAlertCancelModal, setShowAlertCancelModal] = useState(false);

  const [addNewRoleCheckbox, setAddNewRoleCheckbox] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
  } = useForm({
    mode: "all",
    defaultValues: {
      roleName: selectedRole ? selectedRole.name : "",
      en_name: selectedRole ? selectedRole.en_name : ""
    },
  });

  const { isLoading: isMutateAddNewRoleLoading, mutate: mutateAddNewRole } =
    useMutateAddNewRole(
      onClose,
      addNewRoleCheckbox,
      reset,
      setShowAlertConfirmModal,
      userData,
      setPerms,
      setAddNewRoleCheckbox
    );

  const { isLoading: isMutateEditRoleLoading, mutate: mutateEditRole } =
    useMutateEditRole(
      onClose,
      addNewRoleCheckbox,
      reset,
      setShowAlertConfirmModal,
      userData,
      setPerms,
      setAddNewRoleCheckbox
    );

  const handleAddNewRole = async (data) => {
    data.permissions = perms.map((el) => {
      if (
        el.tag === "ReadOnlyPermissions" ||
        el.tag === "ReadWritePermissions"
      ) {
        return { ...el, permissions: [] };
      }
      return el;
    });
    if (selectedRole) data.selectedRole = selectedRole;

    if (selectedRole) {
      mutateEditRole(data);
    } else {
      mutateAddNewRole(data);
    }
  };

  const { data: compRoles, isLoading: isCompRolesLoading } =
    useFetchCompanyRoles();

  const [showActivatRoleModal, setShowActivatRoleModal] = useState(false);

  const handelDeactivatRole = (data) => {
    data.permissions = perms;
    data.selectedRole = selectedRole;
    data.activatType = "deActivateRole";
    mutateEditRole(data);
  };

  const handelActivatRole = (data) => {
    data.permissions = perms;
    data.selectedRole = selectedRole;
    data.activatType = "activateRole";
    mutateEditRole(data);
  };

  return (
    <div
      className="overlay"
      onClick={() => (!isDirty ? onClose() : setShowAlertCancelModal(true))}
      dir={i18n.resolvedLanguage === "en" ? "ltr" : "rtl"}
    >
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <div
          className="bg-white lg:grid lg:grid-cols-12 lg:gap-x-5"
          style={{ width: "1062px" }}
        >
          <form className="col-span-12">
            <div className="sm:rounded-md sm:overflow-hidden">
              <div className="py-4 px-5 flex justify-between items-center">
                <div className="flex items-center gap-x-2">
                  <div>
                    {selectedRole ? (
                      <EditRole />
                    ) : (
                      <AddNewRoleIcon isButton={false} />
                    )}
                  </div>
                  <h3 className="text-base leading-5 font-bold text-body">
                    {selectedRole?.name == null ||
                    selectedRole.name == undefined
                      ? t("add_role")
                      : t("edit_role")}
                  </h3>
                </div>
                <button
                  type="button"
                  onClick={() =>
                    !isDirty ? onClose() : setShowAlertCancelModal(true)
                  }
                >
                  <Close />
                </button>
              </div>
              <hr />

              <div
                style={{ height: "450px" }}
                className="overflow-y-auto scroll-bar grid grid-cols-2"
              >
                <div className="p-5 col-span-1">
                  <Input
                    register={register}
                    type="text"
                    name="roleName"
                    fieldName={t("role_position")}
                    label={t("role_position")}
                    placeholder=""
                    hint={t("role_position_hint")}
                    required={true}
                    min="1"
                    max="10000000"
                    maxLength={50}
                    errors={errors?.roleName}
                  />
                </div>
                <div className="p-5 col-span-1">
                  <Input
                    register={register}
                    type="text"
                    name="en_name"
                    fieldName={t("role_en_position")}
                    label={t("role_en_position")}
                    placeholder=""
                    hint={t("role_en_position_hint")}
                    required={true}
                    min="1"
                    max="10000000"
                    maxLength={50}
                    errors={errors?.en_name}
                  />
                </div>
                <div className="p-5 pt-0 col-span-2">
                  {!selectedRole &&  <div className="flex items-center gap-x-3 pb-1">
                    <h5 className="text-sm font-medium text-body">
                    {t("permissions_group")}
                    </h5>

                    <div className="flex items-center gap-x-5">
                      {compRoles?.map((role) => (
                        <button
                          type="button"
                          style={{ borderRadius: "111px" }}
                          className="px-2 bg-sidebar-background text-xs leading-6 font-normal text-hints"
                          onClick={() => setSimRole(() => role)}
                        >
                          {i18n.resolvedLanguage === "en" && role.en_name ? role.en_name : role.name}
                        </button>
                      ))}
                    </div>
                  </div> }
                  <div
                    className="border border-outline rounded-md px-4 py-3"
                    style={{ minHeight: "600px" }}
                  >
                    {isPermsLoading ? (
                      <div className="w-full flex justify-center">
                        <div className="w-10 h-10">
                          <Spinner />
                        </div>
                      </div>
                    ) : isPermsError ? (
                      <div className="w-full flex justify-center">
                        <div className="w-10 h-10">
                          <FieldError />
                        </div>
                      </div>
                    ) : (
                      perms?.map((perm, index) => (
                        <SelectPerms
                          permission={perm}
                          perms={perms}
                          setPerms={setPerms}
                          permIndex={index}
                          t={t}
                          i18n={i18n}
                        />
                      ))
                    )}
                  </div>
                </div>
              </div>

              <hr />

              <div className="p-5 bg-white text-right grid grid-cols-12 gap-x-3">
                <div className="col-span-2">
                  {(userData.permission_tags.includes("CreateRole") ||
                    userData.permission_tags.includes("EditRole")) && (
                    <Fragment>
                      <button
                        type="button"
                        className={`relative w-full py-2 px-2 rounded-md text-base leading-5 font-medium ${
                          isMutateAddNewRoleLoading ||
                          isMutateEditRoleLoading ||
                          !isValid ||
                          !perms?.some((el) =>
                            el.permissions?.some((el) => {
                              if (el.selected === true) {
                                return true;
                              } else {
                                return false;
                              }
                            })
                          )
                            ? "cursor-not-allowed bg-outline text-side-icons"
                            : "bg-secondary text-white"
                        } ${i18n.resolvedLanguage === "en" ? "flex justify-start" : ""}`}
                        disabled={
                          isMutateAddNewRoleLoading ||
                          isMutateEditRoleLoading ||
                          !isValid ||
                          !perms?.some((el) =>
                            el.permissions?.some((el) => {
                              if (el.selected === true) {
                                return true;
                              } else {
                                return false;
                              }
                            })
                          )
                        }
                        onClick={() => setShowAlertConfirmModal(true)}
                      >
                        {t("confirm")}
                      </button>
                      <Modal
                        showModal={showAlertConfirmModal}
                        onClose={() => setShowAlertConfirmModal(false)}
                      >
                        <Alert
                          hasFooter={true}
                          alertType="confirm"
                          confirmBtnBg="bg-secondary"
                          icon={() => {
                            return selectedRole ? (
                              <EditRole />
                            ) : (
                              <AddNewRoleIcon
                                fillColor="#3B82F6"
                                isButton={false}
                              />
                            );
                          }}
                          title={
                            selectedRole
                              ? t("complete_edit_role")
                              : t("complete_add_role")
                          }
                          body={() => (
                            <div>
                              {selectedRole ? (
                                <p className="text-sm font-medium text-hints">
                                  {t("alert_text_edit_role", {role_name: selectedRole.name})}
                                </p>
                              ) : (
                                <p>
                                {t("alert_text_add_role")}
                                </p>
                              )}

                              {!selectedRole && (
                                <div className="mt-2 text-right">
                                  <input
                                    type="checkbox"
                                    className="focus:ring-secondary h-4 w-4 rounded"
                                    onChange={() =>
                                      setAddNewRoleCheckbox(!addNewRoleCheckbox)
                                    }
                                    value={addNewRoleCheckbox}
                                  />
                                  <span className="mr-2 text-xs leading-5 font-medium text-hints">
                                  {t("add_another_role")}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                          confirmText={selectedRole ? t("edit") : t("create")}
                          cancelText={t("cancel")}
                          handleConfirm={handleSubmit(handleAddNewRole)}
                          handleCancel={() =>
                            (!isMutateAddNewRoleLoading ||
                              !isMutateEditRoleLoading) &&
                            setShowAlertConfirmModal(false)
                          }
                          isSubmitLoading={
                            isMutateAddNewRoleLoading || isMutateEditRoleLoading
                          }
                        />
                      </Modal>
                    </Fragment>
                  )}
                </div>
                <div
                  className={`col-span-2 ${
                    !userData.permission_tags.includes("EditRole") &&
                    selectedRole
                      ? "col-start-1"
                      : ""
                  } ${
                    !userData.permission_tags.includes("CreateRole") &&
                    !selectedRole
                      ? "col-start-1"
                      : ""
                  }`}
                >
                  <Fragment>
                    <button
                      type="button"
                      className={`border border-outline text-base leading-5 font-medium text-body relative w-full py-2 px-2 rounded-md ${i18n.resolvedLanguage === "en" ? "flex justify-start" : ""}`}
                      onClick={() =>
                        !isDirty ? onClose() : setShowAlertCancelModal(true)
                      }
                    >
                      {t("cancel")}
                    </button>
                    <Modal
                      showModal={showAlertCancelModal}
                      onClose={() => setShowAlertCancelModal(false)}
                    >
                      <Alert
                        icon={CancelAddRole}
                        hasFooter={true}
                        alertType="cancel"
                        title={
                          <h3 className="text-lg leading-6 font-bold text-body">
                            {selectedRole
                              ? t("complete_edit_role")
                              : t("complete_add_role")}
                          </h3>
                        }
                        body={() => (
                          <div className="text-sm font-medium text-hints">
                            {t("alert_text_cancel")}
                          </div>
                        )}
                        confirmText={t("confirm")}
                        cancelText={t("cancel")}
                        handleConfirm={onClose}
                        handleCancel={() => setShowAlertCancelModal(false)}
                      />
                    </Modal>
                  </Fragment>
                </div>
                {selectedRole && (
                  <div className="col-span-2 col-start-11">
                    {userData.permission_tags.includes("DeactivateRole") && (
                      <Fragment>
                        <button
                          type="button"
                          className={`text-base leading-5 font-medium text-white relative w-full py-2 px-2 rounded-md ${
                            selectedRole?.active
                              ? "bg-danger"
                              : "bg-confirmation"
                          } ${i18n.resolvedLanguage === "en" ? "flex justify-start" : ""}`}
                          onClick={() => setShowActivatRoleModal(true)}
                        >
                          {selectedRole?.active ? t("deactivate_role") : t("activate")}
                        </button>
                        <Modal
                          showModal={showActivatRoleModal}
                          onClose={() => setShowActivatRoleModal(false)}
                        >
                          {selectedRole?.active ? (
                            <>
                              <Alert
                                icon={DeactivatRole}
                                hasFooter={true}
                                alertType="cancel"
                                title={
                                  <h3 className="text-lg leading-6 font-medium text-danger">
                                  {t("deactivate_role")} 
                                  </h3>
                                }
                                body={() => (
                                  <div className="text-sm font-medium text-hints">
                                  {t("alert_text_deactivate_role")}
                                  </div>
                                )}
                                confirmText={t("deactivate")}
                                cancelText={t("cancel")}
                                handleConfirm={handleSubmit(
                                  handelDeactivatRole
                                )}
                                handleCancel={() =>
                                  !isMutateEditRoleLoading &&
                                  setShowActivatRoleModal(false)
                                }
                                isSubmitLoading={isMutateEditRoleLoading}
                              />
                            </>
                          ) : (
                            <>
                              <Alert
                                //icon={ActivatRole}
                                hasFooter={true}
                                alertType="confirm"
                                confirmBtnBg="bg-secondary"
                                icon={() => <EditRole />}
                                title={
                                  <h3 className="text-lg leading-6 font-bold text-body">
                                   {t("complete_edit_role")}
                                  </h3>
                                }
                                body={() => (
                                  <div>
                                    <p className="text-sm font-medium text-hints">
                                      {t("alert_text_edit_role", {role_name: selectedRole.name})}

                                    </p>
                                  </div>
                                )}
                                confirmText={t("edit")}
                                cancelText={t("cancel")}
                                handleConfirm={handleSubmit(handelActivatRole)}
                                handleCancel={() =>
                                  !isMutateEditRoleLoading &&
                                  setShowActivatRoleModal(false)
                                }
                                isSubmitLoading={isMutateEditRoleLoading}
                              />
                            </>
                          )}
                        </Modal>
                      </Fragment>
                    )}
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNewRole;

function SelectPerms({ permission: perm, perms, setPerms, permIndex, t, i18n }) {
  const [isExpanded, setExpanded] = useState(true);
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded: isExpanded,
    duration: 700,
  });

  const isAllSelected = perms[permIndex].permissions.every((perm, index) => {
    /*if (
      perms[permIndex].tag === "SafePermissions"
      &&(index === 0 || index === 2)
    ) {
      return true;
    }*/

    return perm.selected === true;
  });
  const isSomeSelected = perms[permIndex].permissions.some(
    (perm) => perm.selected === true
  );

  return (
    <div className="flex flex-col items-start text-xs font-medium text-body pb-2">
      <div className="flex items-center justify-center pb-2">
        <button
          type="button"
          {...getToggleProps({
            onClick: () => {
              setExpanded((prevExpanded) => !prevExpanded);
            },
          })}
          className={`transition-all duration-700 transform ${
            isExpanded ? "rotate-0" : "rotate-90"
          }`}
        >
          <CollapseArrow
            fillColor={perms[permIndex].permissions.length === 0 ? "gray" : ""}
          />
        </button>
        <button
          type="button"
          className={`py-2 px-2.5 border rounded-sm transition-all duration-300 flex items-center gap-x-2 ${
            perms[permIndex].tag !== "ReadOnlyPermissions" &&
            perms[permIndex].tag !== "ReadWritePermissions" &&
            (isAllSelected
              ? "text-secondary border-secondary bg-secondary bg-opacity-20"
              : isSomeSelected
              ? "text-body border-secondary"
              : "border-transparent bg-sidebar-background")
          } 
          ${
            perms[permIndex].tag === "ReadOnlyPermissions" &&
            (perms.every((el) =>
              el.permissions.every((el) => {
                if (el.read_only === 1 && el.selected === false) {
                  return false;
                } else {
                  return true;
                }
              })
            )
              ? "text-secondary bg-secondary border-secondary bg-opacity-20"
              : perms.some((el) =>
                  el.permissions.some((el) => {
                    if (el.read_only && !!el.selected) {
                      return true;
                    }
                    return !!false;
                  })
                )
              ? "text-body bg-sidebar-background border-secondary"
              : "bg-sidebar-background border-transparent")
          }

          ${
            perms[permIndex].tag === "ReadWritePermissions" &&
            (perms.every((ell) =>
              ell.permissions.every((el, index) => {
                if (el.selected === false) {

                  /*if (
                    ell.tag === "SafePermissions" 
                    && (index === 0 || index === 2)
                  ) {
                    return true;
                  }*/

                  return false;
                } else {
                  return true;
                }
              })
            )
              ? "text-secondary bg-secondary border-secondary bg-opacity-20"
              : perms.some((ell) =>
                  ell.permissions.some((el) => {
                    if (
                      ell.tag === "ReadOnlyPermissions" ||
                      ell.tag === "ReadWritePermissions"
                    ) {
                      return false;
                    } else if (!!el.selected) {
                      return true;
                    }
                    return !!false;
                  })
                )
              ? "text-body bg-sidebar-background border-secondary"
              : "bg-sidebar-background border-transparent")
          }
          `}
          onClick={() => {
            perms[permIndex].tag === "ReadOnlyPermissions"
              ? setPerms((prevState) => {
                  return prevState.every((el) =>
                    el.permissions.every((el) => {
                      if (el.read_only && !!!el.selected) {
                        return false;
                      }
                      return !!true;
                    })
                  )
                    ? prevState?.map((el) => ({
                        ...el,
                        permissions: el.permissions?.map((elem) => ({
                          ...elem,
                          selected: elem.read_only ? false : elem.selected,
                        })),
                      }))
                    : prevState?.map((el) => ({
                        ...el,
                        permissions: el.permissions?.map((elem) => ({
                          ...elem,
                          selected: elem.read_only ? true : elem.selected,
                        })),
                      }));
                })
              : perms[permIndex].tag === "ReadWritePermissions"
              ? setPerms((prevState) => {
                  return prevState.every((ell) =>
                    ell.permissions.every((el, index) => {
                      if (!!!el.selected) {

                        /*if (
                          ell.tag === "SafePermissions" &&
                          (index === 0 || index === 2)
                        ) {
                          return true;
                        }*/

                        return false;
                      }
                      return !!true;
                    })
                  )
                    ? prevState?.map((el) => ({
                        ...el,
                        permissions: el.permissions?.map((elem) => ({
                          ...elem,
                          selected: false,
                        })),
                      }))
                    : prevState?.map((el) => ({
                        ...el,
                        permissions: el.permissions?.map((elem, index) => ({
                          ...elem,
                          selected: true,
                        })),
                      }));
                })
              : setPerms((prevState) =>
                  prevState.map((el) => {
                    return el.id === perms[permIndex].id
                      ? {
                          ...el,
                          permissions: isAllSelected
                            ? el.permissions.map((perm) => {
                                return { ...perm, selected: false };
                              })
                            : el.permissions.map((perm, index) => {
                                return {
                                  ...perm,
                                  selected: true
                                };
                              }),
                        }
                      : { ...el };
                  })
                );
          }}
        >
          {perms[permIndex].tag !== "ReadOnlyPermissions" &&
            perms[permIndex].tag !== "ReadWritePermissions" && (
              <div
                className={`w-2 h-px ${
                  perms[permIndex]?.permissions?.every((p, index) => {
                    /*if (
                      perms[permIndex].tag === "SafePermissions" &&
                      (index === 0 || index === 2)
                    ) {
                      return true;
                    }*/

                    return p.selected;
                  })
                    ? "bg-secondary"
                    : "bg-hints"
                }`}
              >
                <div
                  className={`w-2 h-px transition-all duration-300 transform ${
                    perms[permIndex]?.permissions?.every((p, index) => {
                      /*if (
                        perms[permIndex].tag === "SafePermissions" &&
                        (index === 0 || index === 2)
                      ) {
                        return true;
                      }*/

                      return p.selected;
                    })
                      ? "bg-secondary"
                      : "-rotate-90 bg-hints"
                  }`}
                ></div>
              </div>
            )}
          {perms[permIndex]?.tag === "ReadOnlyPermissions" && (
            <div
              className={`w-2 h-px ${
                perms?.every((el) =>
                  el.permissions?.every((el) => {
                    if (el.read_only && !!!el.selected) {
                      return false;
                    }
                    return !!true;
                  })
                )
                  ? "bg-secondary"
                  : "bg-hints"
              }`}
            >
              <div
                className={`w-2 h-px transition-all duration-300 transform ${
                  perms?.every((el) =>
                    el.permissions?.every((el) => {
                      if (el.read_only && !!!el.selected) {
                        return false;
                      }
                      return !!true;
                    })
                  )
                    ? "bg-secondary"
                    : "-rotate-90 bg-hints"
                }`}
              ></div>
            </div>
          )}
          {perms[permIndex]?.tag === "ReadWritePermissions" && (
            <div
              className={`w-2 h-px ${
                perms.every((ell) =>
                  ell.permissions.every((el, index) => {
                    if (el.selected === false) {

                      /*if (
                        ell.tag === "SafePermissions" &&
                        (index === 0 || index === 2)
                      ) {
                        return true;
                      }*/

                      return false;
                    } else {
                      return true;
                    }
                  })
                )
                  ? "bg-secondary"
                  : "bg-hints"
              }`}
            >
              <div
                className={`w-2 h-px transition-all duration-300 transform ${
                  perms.every((ell) =>
                    ell.permissions.every((el, index) => {
                      if (el.selected === false) {

                        /*if (
                          ell.tag === "SafePermissions" &&
                          (index === 0 || index === 2)
                        ) {
                          return true;
                        }*/

                        return false;
                      } else {
                        return true;
                      }
                    })
                  )
                    ? "bg-secondary"
                    : "-rotate-90 bg-hints"
                }`}
              ></div>
            </div>
          )}
          <p role="button">{i18n.resolvedLanguage === "en" ? perms[permIndex].en_name : perms[permIndex].name}</p>
        </button>
      </div>
      <div className="px-10" {...getCollapseProps()}>
        <ul className="flex items-center">
          {perms[permIndex].permissions?.map((perm, index) => (
            <li className= "ml-2">
              <button
                type="button"
                className={`flex items-center gap-x-2 py-2 px-2.5 border rounded-sm transition-all duration-300 ${
                  perms[permIndex].tag === "ReadOnlyPermissions" ||
                  perms[permIndex].tag === "ReadWritePermissions"
                    ? "bg-white border-transparent cursor-not-allowed"
                    : perm.selected
                    ? "bg-secondary bg-opacity-20 border-secondary text-secondary"
                    : "bg-sidebar-background border-transparent"
                }
                `}
                disabled={
                  perms[permIndex].tag === "ReadOnlyPermissions" ||
                  perms[permIndex].tag === "ReadWritePermissions"
                }
                onClick={() => {
                  perms[permIndex].tag === "ReadWritePermissions"
                    ? setPerms((prevState) => {
                        return prevState.every((el) =>
                          el.permissions.every((el) => {
                            if (!el.selected) {
                              return false;
                            }
                            return true;
                          })
                        )
                          ? prevState?.map((el) => ({
                              ...el,
                              permissions: el.permissions?.map((elem) => ({
                                ...elem,
                                selected: false,
                              })),
                            }))
                          : prevState?.map((el) => ({
                              ...el,
                              permissions: el.permissions?.map((elem) => ({
                                ...elem,
                                selected: true,
                              })),
                            }));
                      })
                    : perms[permIndex].tag === "ReadOnlyPermissions"
                    ? setPerms((prevState) => {
                        return prevState.every((el) =>
                          el.permissions.every((el) => {
                            if (el.read_only && !!!el.selected) {
                              return false;
                            }
                            return !!true;
                          })
                        )
                          ? prevState?.map((el) => ({
                              ...el,
                              permissions: el.permissions?.map((elem) => ({
                                ...elem,
                                selected: elem.read_only
                                  ? false
                                  : elem.selected,
                              })),
                            }))
                          : prevState?.map((el) => ({
                              ...el,
                              permissions: el.permissions?.map((elem) => ({
                                ...elem,
                                selected: elem.read_only ? true : elem.selected,
                              })),
                            }));
                      })
                    : setPerms((prevState) =>
                        prevState.map((el) => {
                          return el.id === perms[permIndex].id
                            ? {
                                ...el,
                                permissions: el.permissions.map((permm) => {
                                  return {
                                    ...permm,
                                    selected:
                                      permm.toggle === perm.id
                                        ? false
                                        : permm.id === perm.id
                                        ? !permm.selected
                                        : permm.selected,
                                  };
                                }),
                              }
                            : { ...el };
                        })
                      );
                }}
              >
                {perms[permIndex].tag !== "ReadOnlyPermissions" &&
                  perms[permIndex].tag !== "ReadWritePermissions" &&
                  perm.tag !== "CashOutWithoutPermission" &&
                  perm.tag !== "CashOutWithPermission" &&
                  perm.tag !== "CashInWithoutPermission" &&
                  perm.tag !== "CashInWithPermission" && (
                    <div
                      className={`w-2 h-px ${
                        perm.selected ? "bg-secondary" : "bg-hints"
                      }`}
                    >
                      <div
                        className={`w-2 h-px transition-all duration-300 transform ${
                          perm.selected ? "bg-secondary" : "-rotate-90 bg-hints"
                        }`}
                      ></div>
                    </div>
                  )}
                <div>{i18n.resolvedLanguage === "en" ? perm.en_name : perm.name}</div>
              </button>
            </li>
          ))}
          {perms[permIndex].tag === "HelicopterOverviewPermissions" && (
            <div className="tool-pin-container relative">
              <button type="button">
                <InfoCircle />
              </button>
              <p
                style={{ width: "421px" }}
                className="tool-pin px-2 py-1 rounded text-xs font-normal text-sidebar-background bg-gray-800 opacity-75 hidden absolute top-7 right-1.5 z-10"
              >
                التحكم ببيانات البث الحي تعني:
                <br />
                القدرة على التعديل واللإيقاف لأي عملية من العمليات المالية
                الموجودة بداخل النظام. هذه الخاصية عادةً لا يمتلكها إلا
                المحاسبون والإداريون من الطبقة العليا.
              </p>
            </div>
          )}
        </ul>
      </div>
    </div>
  );
}