import KeyIcon from "../../../components/resources/icons/KeyIcon";
import TableSortIcon from "../../../components/resources/icons/TableSortIcon";
import CashAmountIcon from "../../../components/resources/icons/CashAmountIcon";
import UserTag from "../../../components/resources/icons/UserTag";
import UsersAvatars from "../../../components/common/UsersAvatars.jsx";
import SafesIcon from "../../../components/resources/icons/SafesIcon";
import { toolPin } from "../../../utils/toolPin";
import InputDate from "../../../components/resources/icons/InputDate";
import {Link} from "react-router-dom";

export const columnsHeader = ({
  userData,
  t,
  i18n,
  canViewSafesIds
}) => [
  {
    subject: "كود الخزينة",
    title: () => (
      <div class="flex items-center justify-center">
        <div>
          <KeyIcon />
        </div>
        <div className="mx-2">{t("safe_code")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "code",
    key: "id",
    sorter: true,
    render: (text, row) => (
      <div className="text-xs text-body font-medium flex justify-center">
        {text}
      </div>
    ),
    selected: true,
    width: 150,
  },
  {
    subject: "اسم الخزينة",
    title: () => (
      
      <div class="flex items-center">
        <div>
          <SafesIcon fillColor={"#292D32"}/>
        </div>
        <div className="mx-2 text-xs font-bold text-body">{t("safe_name")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "name",
    key: "name",
    sorter: true,
    render: (text, row) => (
      <div title={i18n.resolvedLanguage === "en" && row.en_name ? row.en_name : text} className="flex justify-start">
        <Link to={`/safe/${row.id}`} className="row-text">
          {i18n.resolvedLanguage === "en" && row.en_name ? row.en_name.length > 13 ? "..." + row.en_name.slice(0, 13) : row.en_name : text.length > 13 ? "..." + text.slice(0, 13) : text}
        </Link>
      </div>
    ),
    selected: true,
    width: 150,
  },
  {
    subject: "رصيد الخزينة الحالي",
    title: () => (
      <div class="flex items-center justify-center">
        <div>
          <CashAmountIcon />
        </div>
        <div className="mx-2 text-xs font-bold text-body">{t("safe_balance")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "balance",
    key: "balance",
    sorter: true,
    render: (text, row) => (
      canViewSafesIds.includes(row.id) ? <div className="flex justify-center row-text">
      {new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(text).toString().replace(/EGP/, "") + " "}
      {t("egp")}
      </div> : <div className="flex justify-center items-center">--</div>
    ),
    selected: true,
    width: 150,
  },
  {
    subject: "قيمة العهدة الجارية",
    title: () => (
      <div class="flex items-center justify-center">
        <div>
          <CashAmountIcon />
        </div>
        <div className="mx-2 text-xs font-bold text-body">{t("current_money_requests")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "financial_custodies",
    key: "financial_custodies",
    sorter: true,
    render: (text, row) => (
      canViewSafesIds.includes(row.id) ? <div className="flex justify-center row-text">
      {new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(text).toString().replace(/EGP/, "") + " "}
      ج.م
      </div> : <div className="flex justify-center items-center">--</div>
    ),
    selected: userData.permission_tags.includes("CashReadOnly"),
    width: 200,
  },
  {
    subject: "",
    title: () => (
      <div class="flex items-center justify-center">
        <div>
          <UserTag />
        </div>
        <div className="mx-2 text-xs font-bold text-body">{t("safe_members")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "users_json_string",
    key: "users",
    sorter: true,
    render: (text, row) => (
      canViewSafesIds.includes(row.id) ? <div className="col-desc flex justify-center">
        <UsersAvatars usersArr={JSON.parse(text)} />
      </div> : <div className="flex justify-center items-center">--</div>
    ),
    selected: true,
    width: 200,
  },
  {
    subject: "",
    title: () => (
      <div class="flex items-center justify-center">
        <div>
          <UserTag />
        </div>
        <div className="mx-2 text-xs font-bold text-body">{t("safe_manager")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "manager_json_string",
    key: "manager",
    sorter: true,
    render: (text, row) => (
      canViewSafesIds.includes(row.id) ? <div className="col-desc flex justify-center">
        <UsersAvatars usersArr={JSON.parse(text)} />
      </div> : <div className="flex justify-center items-center">--</div>
    ),
    selected: true,
    width: 150,
  },
  {
    title: () => (
      <div class="flex items-center justify-center">
        <div>
          <InputDate />
        </div>
        <div className="col-title">{t("last_update")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "updated_at",
    key: "updated_at",
    sorter: true,
    render: (text, row) => (
      canViewSafesIds.includes(row.id) ? <div className="col-desc flex justify-center">{toolPin(text.split("T")[0])}</div> : <div className="flex justify-center items-center">--</div>
    ),
    selected: true,
    width: 50,
  },
];
