import React, { Fragment, useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import AddNewRoleIcon from "../../../components/resources/icons/AddNewRoleIcon";
import Table from "../../../components/common/Table";
import apiClient from "../../../services/apiClient";
import useFetchUserData from "../../../hooks/reactQuery/useFetchUserData";
import { useDataLayerValue } from "../../../context/DataLayer";
import { columnsHeader } from "./tableColumnsHeader.js";
import { useQueryClient } from "react-query";
import Modal from "../../common/Modal";
import useFetchSafes from "../../../hooks/reactQuery/useFetchSafes";
import AddSafe from "../AddSafe";
import EditSafe from "../EditSafe";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SafesSettings = ({}) => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();
  const [{ activeTab, searchType, searchTerm }] = useDataLayerValue();

  const canViewSafesIds = userData.safes.filter(s => s.canView).map(s => s.id);

  const { search } = useLocation();
  const activeSafeId = new URLSearchParams(search).get("activeSafeId");

  const [showAddSafeModal, setShowAddSafeModal] = useState(false);
  const [showEditSafeModal, setShowEditSafeModal] = useState(false);
  const [currentEditSafe, setCurrentEditSafe] = useState(false);


  const pageSize = process.env.REACT_APP_PAGE_SIZE;
  //const pageSize = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [columnKey, setColumnKey] = useState("");
  const [order, setOrder] = useState("");

  const [columnsHeaderState, setColumnsHeaderState] = useState(
    columnsHeader({
      userData,
      setShowEditSafeModal,
      setCurrentEditSafe,
      t,
      i18n,
      canViewSafesIds
    })
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [order, columnKey]);

  const {
    isLoading: isTableDataLoading,
    data: tableData,
    error: tableDataError,
    isPreviousData,
  } = useFetchSafes({
    userId: userData.id,
    activeCompanyId: userData.activeCompanyId,
    activeSafeId,
    currentPage,
    pageSize,
    columnKey,
    order,
  });

  const memoizedTableData = useMemo(() => (tableData ? tableData[0] : []), [tableData]);


  const handleTableChange = (pagination, filters, sorter) => {
    setOrder(sorter?.order);
    setColumnKey(sorter?.columnKey);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const history = useHistory();

  const queryClient = useQueryClient();

  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    setColumnsHeaderState(
      columnsHeader({
        userData,
        setShowEditSafeModal,
        setCurrentEditSafe,
        t,
        i18n,
        canViewSafesIds
      })
    );

    if(activeSafeId) {
      let activeRow = document.querySelector(
        '[data-row-key="' + activeSafeId + '"]'
      );
      if (activeRow != null) {
        activeRow.classList.add("animatedActiveSafe");
        activeRow.scrollIntoView();
      }
    }
  
  }, [tableData]);

  
  const onTableRowExpand = (expanded, record) => {
    if(showEditSafeModal) return;
    var keys = [];
    if (expanded && record.active && canViewSafesIds.includes(record.id)) {
      //history.push(`/safe/${record.id}`);
      setShowEditSafeModal(true);
      setCurrentEditSafe(record);
    }
    setExpandedRows(keys);
  };

  const CSRFToken = useQuery(
    "CSRFToken",
    () => {
      return apiClient.get("/getCSRFToken");
    },
    {
      onSuccess(response) {
        localStorage.setItem("CSRFToken", response.data.CSRFToken);
      },
    }
  );

  return (
    <>
    {showAddSafeModal && userData.permission_tags.includes("AddSafe") ?
      <AddSafe onClose={() => setShowAddSafeModal(false)} />
    : showEditSafeModal ?
      <EditSafe currentEditSafe={currentEditSafe} onClose={() => setShowEditSafeModal(false)} />
    :<div>
      {userData.permission_tags.includes("AddSafe") ? (
        <div className="flex justify-end items-center px-4 pt-3">
          <button
            className="border hover:border-secondary flex justify-between items-center gap-x-2 bg-secondary bg-opacity-20 rounded-md px-4 py-2"
            onClick={() => setShowAddSafeModal(true)}
          >
            <div>
              <AddNewRoleIcon fillColor="#3B82F6" />
            </div>
            <span className="text-sm font-bold text-secondary">
            {t("new_safe")}
            </span>
          </button>
        </div>
      ) : null}

      <div className="bg-white">
        <div>
          <div className="mx-4 my-3">
            <Table
              rowKey="id"
              hasSelection={false}
              data={memoizedTableData}
              columns={columnsHeaderState.filter(obj => obj.selected)}
              isLoading={isTableDataLoading}
              error={tableDataError}
              pageSize={pageSize}
              currentPage={currentPage}
              total={tableData?.length}
              isPaginationDisabled={true}
              onPageChange={onPageChange}
              onChange={handleTableChange}
              expandedRows={expandedRows}
              setExpandedRows={setExpandedRows}
              onTableRowExpand={onTableRowExpand} 
              expandedRow={() => <></>}
            />
          </div>
        </div>
      </div>
    </div>}
    </>
  );
};

export default SafesSettings;
