import React, { useEffect, useState, useRef } from "react";
import DotsV from "../../resources/icons/Dots";
import "./TableMenu.scss";
import { useTranslation } from "react-i18next";


const TableMenu = ({ 
  options, 
  showButton,
  onOpen = () => {}, 
  onClose = () => {}, 
}) => {

  const {t, i18n} = useTranslation();

  const optionHeight = 45;
  const menuHeight = optionHeight * options.length;

  const buttonRef = useRef(null);
  const menuContainer = useRef(null);
  const menu = useRef(null);

  const [showMenu, setShowMenu] = useState(false);
  const [offset, setOffset] = useState(null);
  const [position, setPosition] = useState("bottom");

  useEffect(() => {
    document.addEventListener("scroll", () => {
      setShowMenu(false);
    });
    document.addEventListener("click", e => {
      if(menuContainer && menuContainer.current) {
        if(!menuContainer.current.contains(e.target)){
          setShowMenu(false);
        }
      }
    }, true);

    return () => {
      document.removeEventListener("scroll", () => {
        setShowMenu(false);
      });
      document.removeEventListener("click", e => {
        if(menuContainer && menuContainer.current) {
          if(!menuContainer.current.contains(e.target)){
            setShowMenu(false);
          }
        }
      }, true);
    }
  }, []);

  useEffect(() => {
    if(showMenu) onOpen(); else onClose();

    if(buttonRef && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();

      const offset = { 
        top: rect.top, 
        left: i18n.resolvedLanguage === "ar" ? rect.left : rect.left - menu.current.offsetWidth, 
      };

      const offsetBottom = window.innerHeight - offset.top - 40;

      offset.bottom = offsetBottom;

      if(offsetBottom < menuHeight) setPosition("top");
      else setPosition("bottom");
      setOffset(offset);
    }
  }, [showMenu]);

  return (
    <div className="TableMenuContainer" ref={menuContainer}>
      <button className={`TableMenuButton ${showButton || showMenu ? "" : "hideTableMenuButton"}`} ref={buttonRef} onClick={(e) => {
        e.stopPropagation();
        /*const otherMenus = document.querySelectorAll(".TableMenuContainer .TableMenu");
        const otherMenusArray = Array.from(otherMenus);
        for(let m of otherMenusArray) {
          if(m.classList.contains("flex") && m !== menu.current) {
            m.classList.replace("flex", "none");
          }
        }
        if(menu && menu.current) {
          if(showMenu && menu.current.classList.contains("none")) {
            menu.current.classList.replace("none", "flex");
            return;
          }
        }*/
        setShowMenu(show => !show);
      }}>
        <DotsV />
      </button>
      <ul className={`TableMenu ${showMenu ? "flex" : "none"} ${!options && showMenu || options.length < 1 && showMenu ? "EmptyTableMenu" : ""}`} ref={menu}
        style = {
          offset ? position == "bottom" ? {top: offset.top + 40, left: offset.left} 
          : {bottom: offset.bottom, left: offset.left } 
          : {}
        }
        onClick={e => e.stopPropagation()}
      >
        {!options || options.length < 1 ? <span onClick={e => e.stopPropagation()}>{t("no_available_options")}</span> : null}
        {options.map(option => 
        <li className={`TableMenuOption ${option.disabled ? "TableMenuOptionDisabled" : ""}`} style={{height: `${optionHeight}px`}} onClick={
          (e) => {
            e.stopPropagation();
            option.action();
            setShowMenu(false);
          }
        }>
          <div className="TableMenuIcon">
            {option.icon}
          </div>
          <span className="TableMenuText">{option.text}</span>
        </li>
        )}
      </ul>
    </div>
  );
};

export default TableMenu;
