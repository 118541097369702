import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "ar",
    resources: {
      ar: {
        translation: {
          add_new_member: "اضافة عضو جديد",
          alert_text_archive: `
                سيتم نقل العمليات المحددة بالجدول الى الأرشيف.
                \n
                العمليات المنتهية هي المرفوضة أو المقبولة أو العهد التي تمت
                تصفيتها.
                \n
                اضغط على زر أرشفة العمليات بالأسفل لتأكيد الإجراء.`,

          alert_text_accept_spend_request: `
                سيتم قبول طلب إذن الصرف المقدم من اسلام.
                \n
                سيتم إشعار إسلام بموافقتك على طلبه.
                `,

          alert_text_reject_spend_request: `
                سيتم رفض طلب إذن الصرف المقدم من {{sender_name}}.
                \n
                سيتم إشعار {{sender_name}} برفضك لطلبه.
                `,

          alert_text_logout: `سيتم تسجيل خروجك من مساحة العمل وإعادتك إلى صفحة Trustbill
                الرئيسية`,

          alert_text_confirm_close_money_request: `
                {{amount}} سيتم تقفيل العهدة و استرداد مبلغ
                جنيه مصري
                \n
                بمجرد تقفيلك للعهدة، لن يعد بإمكانك التراجع. يمكنك
                الاطلاع على العهدة بخانة المنتهي بقسم نظام العهد
                بواجهتك.
                `,

          alert_text_confirm_send_money_request: `{{recipient}} سيتم إرسال طلب عهدة مالية إلى
                {{amount}} بقيمة
                \n
                جنيه مصري بمجرد إرسالك للطلب المالي، لن يعد بإمكانك
                حذفه. يمكنك الاطلاع على حالة الطلب المالي بخانة الصادر
                بقسم نظام العهد بواجهتك.`,

          alert_text_cancel: `سيتم حذف البيانات التى أدخلتها وتحويلك الى الصفحة
                الرئيسية هل أنت متأكد من إلغاء الطلب ؟`,

          alert_text_confirm_reject_money_request_text: `
                {{sender}} أنت علي وشك رفض عهدة من
                {{amount}} بقيمة
                جنيه مصري
                \n
                بمجرد رفض العهدة فلن يمكنك التراجع عنها, وسيظل حسابك كما هو
                {{balance}} جنيه
                مصري
                `,

          alert_text_confirm_reject_money_request: `
                سيتم رفض طلب عهدة من
                {{sender}}
                \n
                بقيمة
                {{amount}}
                \n
                جنيه مصري بمجرد رفضك للعهدة، لن يعد بإمكانك التراجع.
                يمكنك الاطلاع على حالة العهدة بخانة الصادر بقسم نظام
                العهد بواجهتك.
                `,

          alert_text_accept_deposit_request: `
                سيتم قبول طلب الإيداع المالي المقدم من طرف
                {{creator_name}}
                \n
                سيتم إشعار {{creator_name}} بموافقتك على طلبه.
                `,

          alert_text_reject_deposit_request: `
                سيتم رفض طلب الإيداع المالي المقدم من طرف
                {{creator_name}}
                .
                \n
                سيتم إشعار {{creator_name}} برفضك لطلبه.
                `,

          alert_text_reject_withdraw_request: `
                سيتم رفض طلب السحب المالي المقدم من طرف {{creator_name}}
                .
                \n
                سيتم إشعار {{creator_name}} برفضك لطلبه.
                `,
          alert_text_accept_withdraw_request: `
                سيتم قبول طلب السحب المالي المقدم من طرف
                {{creator_name}}.
                \n
                سيتم إشعار {{creator_name}} بموافقتك على طلبه.
                `,

          alert_text_confirm_add_members: `
                سيتم إرسال دعوات إضافة إلى الأعضاء الذين تمت كتابة بياناتهم.
                \n
                هل أنت متأكد من إتمام الإجراء وإرسال دعوات الإضافة؟
                `,

          alert_text_add_safe: `
                سيتم اضافة خزنة
                \n
                هل أنت متأكد من البيانات التي ادخلتها؟
                `,

          alert_text_cancel_add_safe: `
                سيتم الغاء البيانات التي أدخلتها. هل انت متأكد من عدم رغبتك
                بتعزيز بيئة عملك؟
                \n
                هذا الإجراء لا يمكن الرجوع عنه.
                `,

          alert_text_deactivate_safe: `
                عند تعطيل الخزينة سيتم إيقاف العمل بهذة الخزينة
                بصورة كاملة.
                \n
                يمكنك الرجوع عن هذا الإجرائ في أي وقت. بمجرد
                الضغط على نفس الزر السابق.
                `,

          alert_text_reactivate_safe: `
                سيتم اعادة تنشيط هذة الخزينة
                \n
                هل انت متاكد من رغبتك باعادة تنشيطها ؟
                `,

          alert_text_confirm_edit: `
                سيتم تنفيذ الاعدادات التي ادخلتها
                \n
                هل ترغب باتمام التعديل ؟
                `,

          alert_text_deactivate_member: `
                سيتم ايقاف {{user_name}} عن العمل
                \n
                لن يعود بإمكانه بعد تنفيذ الإيقاف الولوج إلى النظام.
                \n
                هل انت متأكد من إتمام الإجراء؟
                `,

          alert_text_activate_member: `
                سيتم تنشيط العضو {{user_name}}
                \n
                هل انت متأكد من إتمام الإجراء؟
                `,

          alert_text_cancel_invitation: `
                سيتم إلغاء دعوة العمل المرسلة إلى
                \n
                هل انت متأكد من إتمام الإجراء؟
                `,

          alert_text_confirm_change_role: `
                سيتم تعديل دور {{user_name}} من {{old_role}} إلى {{new_role}}.
                \n
                هل انت متأكد من إتمام الإجراء؟
                `,

          alert_text_edit_role: `
                سيتم تعديل دور
                {{role_name}}
                فور ضغطك على تعديل بالأسفل سيتم تطبيق
                التعديلات.
                \n
                هل أنت متأكد من إتمام الإجراء؟
                `,

          alert_text_add_role: `
                سيتم إنشاء دور جديد. هل تريد إتمام عملية
                الإنشاء؟
                \n
                سوف تجد الدور بصلاحياته موجودًا في جدول
                الأدوار بالإعدادات.
                `,

          alert_text_deactivate_role: `
                عند تعطيل الدور سيتم إيقاف العمل بهذا الدور
                بصورة كاملة.
                \n
                مما يعني عدم بقاء الصلاحيات كما هي. وعودة
                جميع المستخدمين إلى آخر دور تم إسناده إليهم.
                \n
                يمكنك الرجوع عن هذا الإجرائ في أي وقت. بمجرد
                الضغط على نفس الزر السابق.
                `,

          alert_text_cancel_role: `
                سيتم حذف البيانات التى أدخلتها وتحويلك الى
                جدول الأدوار هل أنت متأكد من إلغاء الإنشاء ؟
                `,

          alert_text_deactivate_role_text: `
                عند ﺗﻌﻄﻴﻞ الدور, ﺳﻴﺘﻢ إﻳﻘﺎف اﻟﻌﻤﻞ ﺑﻬﺬا
                الدور بصورة ﻛﺎﻣﻠﺔ،
                \n
                ﻣﻤﺎ ﻳﻌﻨﻲ ﻋﺪم ﺑﻘﺎء اﻟﺼﻠﺎﺣﻴﺎت ﻛﻤﺎ ﻫﻲ، وعودة
                ﺟﻤﻴﻊ اﻟﻤﺴﺘﺨﺪﻣﻴﻦ إﻟﻰ آﺧﺮ دور ﺗﻢ إسناده
                إﻟﻴﻬﻢ.
                \n
                ﻳﻤﻜﻨﻚ اﻟﺮﺟﻮع ﻋﻦ ﻫﺬا اﻟﺈﺟﺮاء ﻓﻲ أي وﻗﺖ،
                ﺑﻤﺠﺮد اﻟﻀﻐﻂ ﻋﻠﻰ ﻧﻔﺲ اﻟﺰر اﻟﺴﺎﺑﻖ.
                `,

          alert_text_edit_role_text: `
                عند تفعيل الدور, ﺳﻴﺘﻢ تفعيل اﻟﻌﻤﻞ ﺑﻬﺬا
                الدور بصورة ﻛﺎﻣﻠﺔ،
                \n
                ﻣﻤﺎ ﻳﻌﻨﻲ ﺑﻘﺎء اﻟﺼﻠﺎﺣﻴﺎت ﻛﻤﺎ ﻫﻲ، وعودة ﺟﻤﻴﻊ
                اﻟﻤﺴﺘﺨﺪﻣﻴﻦ إﻟﻰ الدور ﺗﻢ إسناده إﻟﻴﻬﻢ.
                \n
                ﻳﻤﻜﻨﻚ اﻟﺮﺟﻮع ﻋﻦ ﻫﺬا اﻟﺈﺟﺮاء ﻓﻲ أي وﻗﺖ،
                ﺑﻤﺠﺮد اﻟﻀﻐﻂ ﻋﻠﻰ ﻧﻔﺲ اﻟﺰر اﻟﺴﺎﺑﻖ.
                `,

          alert_text_add_document: `
                سيتم تسجيل عدد
                {{count}}
                مصروف بقيمة
                {{amount}}
                جنيه مصري. جنيه مصري يمكنك الاطلاع على جميع مصروفاتك
                بقائمة التدوينات بواجهتك
                `,

          alert_text_block: `
                هل انت متاكد من رغبتك في حجب هذة العملية؟
                كل الاثار المترتبة على هذة العملية سوف تزول من قاعدة البيانات.
                ولكن ستظل العملية محتفظة بمكانها بجدول العمليات الخاص بها
                `,

          alert_text_confirm_close_money_request: `
                سيتم تقفيل العهدة و استرداد مبلغ
                {{amount}}
                جنيه مصري.
                \n
                بمجرد تقفيلك للعهدة، لن يعد بإمكانك التراجع. يمكنك
                الاطلاع على العهدة بخانة المنتهي بقسم نظام العهد
                بواجهتك.
                `,

          alert_text_confirm_send: `
                ل أنت متأكد من بيانات الطلب المالية التى أدخلتها؟
                يمكنك العثور على بيانات طلبك المالى بقائمة الصادر
                بالرئيسية، يرجى العلم أنه فى حال تم استقبال طلبك والرد
                عليه فلن يمكنك حذفه
                `,

          alert_text_confirm_recharge: `
                سيتم شحن رصيد شركتك بما قيمته {{amount}}
                جنيه مصري بمجرد نقرك علي تأكيد الشحنة أدناه. لن يمكنك
                التراجع عن المبلغ الذي تم ضخه إلي الخزانة.
                `,

          alert_text_withdraw_from_safe: `
                سيتم سحب
                {{amount}}
                جنيه مصري من الخزنة
                `,
          no_sent_spend_requests:
            "يبدو أنه لا يوجد أذون صرف في هذه الصفحة بعد، هل تود إنشاء إذن صرف جديد؟",
          no_received_spend_requests:
            "لم تستقبل اي طلب اذن صرف حتي الان في حالة وصل اي طلب سيتم اعلامك",
          cancel_edit_safe: "الغاء تعديل الخزنة",
          confirm_edit_safe: "تاكيد تعديل الخزنة",
          edit_safe: "تعديل الخزنة",
          activate_safe: "تنشيط الخزنة",
          reactivate_safe: "اعادة تنشيط الخزنة",
          deactivate_safe: "تعطيل الخزنة",
          confirm_add_safe: "اتمام عملية انشاء خزنة جديدة",
          cancel_add_safe: "الغاء عملية إضافة الخزنة",
          cancel_add_members: "الغاء اضافة اعضاء",
          add_members: "اضافة اعضاء",
          add_new_cell: "اضافة خانة جديدة",
          send_invitations: "ارسال الدعوات",
          confirm_reject: "تاكيد الرفض",
          confirm_send: "ناكيد الارسال",
          confirm_send_money_request: "تاكيد ارسال طلب العهدة",
          confirm_reject_money_request: "تاكيد ارسال طلب العهدة",
          reject_deposit_request: "رفض طلب الايداع",
          accept_deposit_request: "قبول طلب الايداع",
          reject_withdraw_request: "رفض طلب السحب",
          accept_withdraw_request: "قبول طلب السحب",
          ask_for_money_request: "اطلب عهدة",
          send: "ارسال",
          create: "انشاء",
          edit: "تعديل",
          go_to_home: "عودة للرئيسية",
          currently_developing: "جاري التطوير",
          currently_developing_text:
            "هذا القسم من النظام قد التطوير سيتم اشعارات فور الانتهاء منه.",
          min_chars_validation:
            "يجب الا يقل عدد احرف {{field_name}} عن {{number}}",
          max_chars_validation:
            "يجب الا يزيد عدد احرف {{field_name}} عن {{number}}",
          max_validation: "عفوا لقد تجاوزت الحد المسموح",
          min_validation: "يحب الا تقل القيمة عن {{min}}",
          field_required: "{{field_name}} مطلوب",
          company_members: "عدد افراد الشركة",
          company_responsibility: "مسئوليتك بالشركة",
          company_specialization: "تخصص الشركة",
          company_name: "اسم الشركة",
          pick_company_pick: "اختر صورة الشركة",
          member: "عضو",
          manager: "مدير",
          the_manager: "المدير",
          regulator: "منظم",
          change_password: "تغيير كلمة المرور",
          name: "الاسم",
          first_name: "الاسم الاول",
          last_name: "الاسم الاخير",
          email: "البريد الالكتروني",
          no_spaces_allowed:
            "* لا تستخدم مسافات اقتصر فقط على الحروف والأرقام والرموز",
          pick_profile_pic: "اختر الصورة الشخصية",
          settings_tabs_account: "حسابي",
          settings_tabs_system: "النظام",
          settings_tabs_workspace: "مساحة العمل",
          settings_tabs_team: "الفريق",
          settings_tabs_categories: "التصنيفات",
          settings_tabs_roles: "الادوار",
          settings_tabs_safes: "الخزائن",
          accept_deposit_request: "قبول طلب الايداع",
          reject_deposit_request: "رفض طلب الايداع",
          creator: "منشيء العملية",
          accept_withdraw_request: "قبول طلب السحب",
          reject_withdraw_request: "رفض طلب السحب",
          accept: "قبول",
          num_of_documents_dynamic: "{{number}} مصروف",
          notes_dynamic: "ملاحظات {{user_name}}",
          transaction_status: "حالة العملية",
          accepted_money_requests: "عهد مقبولة",
          spent_amount: "قيمة ما تم صرفة",
          close_your_money_request: "قفل عهدتك و خلص ضميرك",
          close_your_money_request_text: `
                بعد انتهاء حاجتك من العهدة, يمكنك تصفيتها مع المسؤول الذي
                طلبت منه العهدة. لحظة أن تقرر تصفية العهدة, سوف يرجع جميع
                المتبقي من عهدتك إلي مسؤول العهدة الأصلي, و لن تتمكن من
                استرجاع العهدة من جديد.`,
          all_documents: "كل المصروفات",
          money_request_details: "تفاصيل العهدة",
          running_money_request: "عهدة جارية",
          closed_money_request: "عهدة منتهية",
          safe_code: "كود الخزينة",
          documents_amount: "قيمة المصروفات",
          original_amount: "القيمة الاصلية",
          debtor: "المتعهد",
          gt: "اكبر",
          lt: "اقل",
          document_transactions: "عمليات المصاريف",
          withdraw_transactions: "عمليات السحب",
          deposit_transactions: "عمليات الايداع",
          filter_by_safe: "تصفية بالخزينة",
          max_safe_select: "الحد الاقصى للاختيار {{number}} خزائن",
          all_safes: "جميع الخزائن",
          running_money_request_data: "بيانات العهد الحالية",
          spend_purpose: "سبب الصرف",
          urgent: "عاجل",
          inessential: "غير عاجل",
          main_category: "التصنيف الرئيسي",
          subcategory: "التصنيف الفرعي",
          max_delivery_time: "الموعد الاقصى للتسليم",
          request_status: "حالة الطلب",
          helicopter_empty_page:
            "يبدو أنه لا يوجد أي بيانات في هذه الصفحة بعد، في حالة تسجيل أي بيانات، سيتم عرضها هنا بصفحة البث الحي.",
          deposit: "عملية ايداع",
          widthraw: "عملية سحب",
          subordinate_money_request: "العهدة التابعة",
          permission_status: "حالة الاذن",
          sent_money_requests_empty_page:
            "يبدو أنه لا يوجد عهد مالية في هذه الصفحة بعد، هل تود إنشاء عهدة مالية جديدة؟",
          received_money_requests_empty_page:
            "لم تستقبل اى طلب عهدة حتى الان فى حالة وصول اى طلب سيتم اعلامك.",
          money_request_no_status:
            "العهدة المرفوضة و التي لم يتم الرد عليها ليست لها حالة اي انها ليست جارية او متقفلة",
          money_request_status: "حالة العهدة",
          money_request_no_reply: "عهدة لم يتم الرد عليها",
          transaction_users: "افراد العملية",
          money_request_source: "مصدر العهدة",
          has_open_children:
            "لا يمكنك تقفيل العهدة إلا بعد تقفيل كل العهد الصادرة منها",
          close_money_request: "تقفيل العهدة",
          confirm_close_money_request: "تاكيد تقفيل العهدة",
          current_amount: "القيمة الحالية",
          closing_amount: "القيمة عند التقفيل",
          no_response: "لا يوجد رد",
          your_response: "ردك",
          block_date: "تاريخ الحجب",
          closing_date: "تاريخ التقفيل",
          expense: "مصروف",
          open_money_request_documents: "فتح صفحة تدوينات العهدة",
          num_of_documents: "عدد المصروفات",
          back: "رجوع",
          safe_balance: "القيمة المتاحة في هذة الخزينة",
          safe_insufficient_balance:
            "قيمة العهدة المطلوبة تفوق القيمة المتاحة بالخزينة المختارة",
          pick_safe: "اختر الخزينة اللتي تريد اجراء العملية من خلالها.",
          accept_money_request_dialog:
            "سيتم قبول طلب {{user_name}} بعهدة مالية <br /> هل تفحصت بيانات العهدة و تاكدت انك تريد الموافقة على الطلب ؟",
          money_request_insufficient_balance:
            "قيمة العهدة المطلوبة تفوق قيمة العهدة المختارة",
          money_request_balance: "القيمة المُتاحة في هذه العهدة",
          pick_money_request: "اختر اسم العهدة التي تريد صرف المبلغ من خلالها.",
          accept_from_money_request: "قبول من عهدة",
          accept_from_safe: "قبول من الخزنة",
          money_request_accept: "قبول العهدة",
          money_request_reject: "رفض العهدة",
          user_balance: "رصيد {{user_name}} الحالي",
          closed_money_requests: "العهد المنتهية",
          current_money_requests: "العهد الجارية",
          all_money_requests: "كل العهد",
          no_notes: "لا توجد ملاحظات",
          send_ticket: "ابعت تذكرة",
          forward: "اعادة توجية",
          document_type: "نوع المصروف",
          block: "حجب العملية",
          create_document: "انشاء تدوينة مالية",
          no_attachments: "لا توجد مرفقات",
          expense_details: "سبب الصرف",
          none: "لا يوجد",
          documentation_date: "تاريخ التدوين",
          save: "حفظ",
          no_available_options: "لا يوجد خيارات متاحة",
          add_attachments: "اضافة مرفقات",
          upload_attachment: "حمل مرفق",
          suspend: "ايقاف",
          attachments: "ملحقات",
          notes: "ملاحظات",
          required_response_time: "الموعد المطلوب للرد",
          max_response_time: "الموعد الاقصى للرد",
          last_updates: "اخر التحديثات",
          your_custody: "عهدتك",
          cashin_without_permission: "ايداع مالي",
          cashin_with_permission: "طلب ايداع",
          cashout_without_permission: "سحب مالي",
          cashout_with_permission: "طلب سحب",
          logout: "تسجيل الخروج",
          egp: "ج.م",
          current_balance: "رصيد حسابك الحالي",
          selected_transactions: "الطلبات المحددة",
          selected_amount: "القيمة المحددة",
          amount: "القيمة",
          show_indicators: "اظهار المؤشرات",
          limit_table_view: "تخصيص عرض الجدول",
          archive: "ارشفة",
          archive_selected_transactions: "ارشفة العمليات المحددة",
          archive_confirm: "ارشفة العمليات",
          filteration: "تصفية",
          export: "تصدير",
          search_placeholder: "ما الذي تبحث عنة ؟",
          transaction_code: "كود العملية",
          transaction_name: "موضوع العملية",
          transaction_notes: "ملاحظات العملية",
          category_name: "اسم التصنيف",
          user_name: "اسم المستخدم",
          all: "الجميع",
          category: "التصنيف",
          recipient: "ألمرسل الية",
          sent_or_response_date: "تاريخ الارسال / الرد",
          recieved_or_response_date: "تاريخ الاستلام / الرد",
          response_date: "تاريخ الرد",
          response_status: "حالة الرد",
          new_transaction: "عملية جديدة",
          settings: "الاعدادات",
          requests: "الطلبات",
          spend_request: "اذن صرف",
          spend_requests: "اذون الصرف",
          spend_request_accept: "قبول اذن الصرف",
          spend_request_reject: "رفض اذن الصرف",
          sent_spend_requests: "طلبات اذون الصرف الصادرة",
          received_spend_requests: "طلبات اذون الصرف الواردة",
          your_documents: "مصاريفك",
          documents: "المصاريف",
          documentations: "التدوينات المالية",
          withdrawals: "مسحوبات الخزينة",
          money_requests_plural: "عهد مالية",
          money_requests: "نظام العهد",
          money_request: "عهدة مالية",
          sent_money_requests: "طلبات العهد الصادرة",
          received_money_requests: "طلبات العهد الواردة",
          live_section: "البث الحي",
          all_transactions: "كل العمليات",
          safe: "الخزنة",
          safes: "الخزائن",
          notifications: "الاشعارات",
          transaction_type: "نوع المعاملة",
          transaction_type_deposit: "ايداع في الخزينة",
          transaction_type_withdraw: "سحب من الخزينة",
          transaction_type_spend_request: "طلب اذن صرف",
          transaction_type_money_request: "طلب عهدة مالية",
          transaction_type_document: "تدوين مصروف",
          new_workspace: "مساحة عمل جديدة",
          workspaces: "مساحات العمل",
          workspace_settings: "اعدادات مساحة العمل",
          select_specific_info: "اختيار معلومات معينة",
          show_all_info: "عرض كل المعلومات",
          reset_all: "رجوع للاصل",
          person_name: "اسم الشخص",
          status: "الحالة",
          date: "التاريخ",
          spend_date: "تاريخ الصرف",
          document_type: "نوع المصروف",
          periodic: "متكرر",
          rare: "عارض",
          parent_money_request: "العهدة الام",
          sender: "المرسل",
          money_request_status: "حالة العهدة",
          money_request_purpose: "سبب العهدة",
          from_safe: "تم صرف من الخزنة مباشرة",
          creation_date: "تاريخ الانشاء",
          transaction_pending: "معلق",
          transaction_running: "قيد التنفيذ",
          transaction_closed: "تمت التصفية",
          transaction_type: "نوع المعاملة",
          pagination_controls_next: "التالي",
          pagination_controls_previous: "السابق",
          safes_balance: "رصيد جميع الخزائن",
          monthly_average: "متوسط شهري",
          average_monthly: "م.ش",
          chart_type_deposits: "ايداعات",
          chart_type_withdrawals: "مسحوبات",
          chart_type_documents: "مصاريف",
          chart_type_safe_balance: "رصيد الخزنة",
          chart_type_safes_balance: "رصيد الخزائن",
          current_month: "الشهر الحالي",
          previous_month: "الشهر السابق",
          current_week: "الاسبوع الحالي",
          previous_week: "الاسبوع السابق",
          this_week: "الاسبوع الحالي",
          last_six_months: "اخر 6 اشهر",
          today: "اليوم",
          yesterday: "الامس",
          pending_transactions: "العمليات المعلقة",
          last_updates: "اخر التحديثات",
          no_data_for_date: "لا يوجد بيانات بالمدة المحددة لمقارنتها",
          more: "اكثر",
          less: "اقل",
          top_categories: "اعلى التصنيفات تسجيلا",
          total_deposits: "اجمالي الايداعات",
          total_withdrawals: "اجمالي المسحوبات",
          total_documents: "اجمالي المصاريف",
          from_specified_time: "من الفترة المحددة",
          count: "عدد",
          safes_list: "قائمة الخزائن الحالية",
          safe_running_money_requests: "قيمة العهد الجارية",
          safe_members: "افراد الخزينة",
          safe_manager: "مدير الخزينة",
          last_update: "اخر تحديث",
          safe_name: "اسم الخزينة",
          safe_balance: "رصيد الخزينة الحالي",
          notification_type_SPEND_REQUEST: "طلب اذن صرف",
          notification_type_ACCEPT_SPEND_REQUEST: "اذن صرف مقبول",
          notification_type_REJECT_SPEND_REQUEST: "اذن صرف مرفوض",
          notification_type_REJECT_MONEY_REQUEST: "عهدة مالية مرفوضة",
          notification_type_ACCEPT_FORWARD_SPEND_REQUEST: "اذن صرف مقبول",
          notification_type_REJECT_FORWARD_SPEND_REQUEST: "اذن صرف مرفوض",
          notification_type_FORWARD_SPEND_REQUEST: "اعادة توجية اذن صرف",
          notification_type_DOCUMENT: "تدوين مصروف",
          notification_type_DOCUMENT_FROM_SAFE: "تدوين مصروف من الخزنة",
          notification_type_MONEY_REQUEST: "طلب عهدة",
          notification_type_ACCEPT_MONEY_REQUEST: "عهدة مالية مقبولة",
          notification_type_ACCEPT_MONEY_REQUEST_FROM_SAFE:
            "عهدة مالية مقبولة من الخزنة",
          notification_type_REJECT_FORWARD_MONEY_REQUEST: "عهدة مالية مرفوضة",
          notification_type_FORWARD_MONEY_REQUEST: "اعادة توجية عهدة مالية",
          notification_type_SETTLED_MONEY_REQUEST: "تصفية عهدة مالية",
          notification_type_ACCEPT_CASHOUT: "سحب مالي مقبول",
          notification_type_REJECT_CASHOUT: "سحب مالي مرفوض",
          notification_type_CASHIN_WITHOUT_PERMISSION: "ايداع مالي",
          notification_type_CASHIN_WITH_PERMISSION: "طلب ايداع مالي",
          notification_type_ACCEPT_CASHIN: "ايداع مالي مقبول",
          notification_type_REJECT_CASHIN: "ايداع مالي مرفوض",
          notification_type_CASHOUT_WITH_PERMISSION: "طلب سحب مالي",
          notification_type_CASHOUT_WITHOUT_PERMISSION: "سحب مالي",
          notification_type_CREATE_SAFE: "خزنة جديدة",
          notification_type_CREATE_DEFUALT_SAFE: "خزنة جديدة",
          notification_type_EDIT_SAFE: "تعديل اداري",
          notification_type_ADD_SAFE_MEMBER: "عضو خزينة جديد",
          notification_type_ADD_ROLE: "دور جديد",
          notification_type_EDIT_ROLE: "تعديل دور",
          notification_type_DEACTIVATE_ROLE: "تعطيل دور",
          notification_type_EDIT_WORKSPACE: "تعديل مساحة العمل",
          notification_type_ADD_USER_TO_WORKSPACE: "عضو جديد بمساحة العمل",
          notification_type_ACCEPT_USER_INVITATION: "تم قبول دعوتك",
          notification_type_HOLD_SPEND_REQUEST: "عملية حجب اذن صرف",
          notification_type_HOLD_MONEY_REQUEST: "عملية حجب عهدة مالية",
          last_updates_type_CREATE_SAFE: "خزينة جديدة",
          last_updates_type_EDIT_SAFE: "تعديل اداري",
          last_updates_type_ADD_SAFE_MEMBER: "عضو جديد",
          last_updates_type_CASHOUT_WITHOUT_PERMISSION: "سحب مالي",
          last_updates_type_ACCEPT_CASHOUT: "سحب مالي",
          last_updates_type_CASHIN_WITHOUT_PERMISSION: "ايداع مالي",
          last_updates_type_ACCEPT_CASHIN: "ايداع مالي",
          last_updates_type_DOCUMENT_FROM_SAFE: "تدوين مصروف من الخزينة",
          last_updates_type_ACCEPT_MONEY_REQUEST: "عهدة مالية مقبولة",
          last_updates_type_ACCEPT_MONEY_REQUEST_FROM_SAFE:
            "عهدة مالية مقبولة من الخزنة",
          last_updates_types_SETTLED_MONEY_REQUEST: "تصفية عهدة مالية",
          account_info: "معلومات الحساب",
          change_password: "تغيير كلمة المرور",
          confirm: "تاكيد",
          cancel: "الغاء",
          system: "النظام",
          company_info: "معلومات الشركة",
          team: "الفريق",
          categories: "التصنيفات",
          role: "الدور",
          joining_date: "تاريخ الانضمام",
          account_balance: "رصيد الحساب الحالي",
          new_member: "اضافة عضو جديد",
          users: "الافراد",
          permissions: "الصلاحيات",
          new_role: "اضافة دور جديد",
          new_safe: "اضافة خزينة جديدة",
          accepted: "مقبول",
          rejected: "مرفوض",
          reject: "رفض",
          from: "من",
          from_date_hint: "ادخل التاريخ الابتدائي لمدة التصفية",
          send_date: "تاريخ الإرسال",
          to: "الى",
          to_date_hint: "ادخل التاريخ النهائي لمدة التصفية",
          from_amount_hint: "ادخل المبلغ الابتدائي لقيمة التصفية.",
          to_amount_hint: "ادخل المبلغ النهائي لقيمة التصفية.",
          no_permission_needed: "هذا العضو لا يحتاج لاذن",
          notification_type_SPEND_REQUEST_TEXT:
            "تم ارسال اذن صرف اليك من قبل {{user_name}}",
          notification_type_ACCEPT_SPEND_REQUEST_TEXT:
            "تم قبول طلبك باذن صرف من قبل {{user_name}}",
          notification_type_REJECT_SPEND_REQUEST_TEXT:
            "تم رفض طلبك باذن صرف من قبل {{user_name}}",
          notification_type_REJECT_MONEY_REQUEST_TEXT:
            "تم رفض طلبك بعهدة مالية من قبل {{user_name}}",
          notification_type_ACCEPT_FORWARD_SPEND_REQUEST_TEXT:
            "تم قبول طلبك باذن صرف من قبل {{user_name}}",
          notification_type_ACCEPT_FORWARD_SPEND_REQUEST_TEXT_OTHER: `تم قبول طلب {{affected_name}} باذن صرف من قبل {{creator_name}}`,
          notification_type_REJECT_FORWARD_SPEND_REQUEST_TEXT:
            "تم رفض طلبك باذن صرف من قبل {{user_name}}",
          notification_type_REJECT_FORWARD_SPEND_REQUEST_TEXT_OTHER:
            "تم رفض طلب {{affected_name}} باذن صرف من قبل {{creator_name}}",
          notification_type_FORWARD_SPEND_REQUEST_TEXT:
            "تم اعادة توجية طلبك باذن صرف الى {{user_name}}",
          notification_type_FORWARD_SPEND_REQUEST_TEXT_OTHER:
            "تم اعادة توجية طلب {{affected_name}} باذن صرف من قبل {{creator_name}}",
          notification_type_DOCUMENT_TEXT:
            "{{creator_name}} قام بتدوين مصروف بقيمة {{amount}} من خزينة {{safe_name}}",
          notification_type_DOCUMENT_FROM_SAFE_TEXT:
            "{{creator_name}} قام بتدوين مصروف بقيمة {{amount}} من خزينة {{safe_name}}`",
          notification_type_MONEY_REQUEST_TEXT:
            "{{user_name}} ارسل اليك طلب عهدة",
          notification_type_ACCEPT_MONEY_REQUEST_TEXT:
            "تم قبول طلبك للعهدة من قبل {{user_name}}",
          notification_type_ACCEPT_MONEY_REQUEST_TEXT_OTHER:
            "تم قبول طلب {{affected_name}} للعهدة من قبل {{creator_name}}",
          notification_type_ACCEPT_MONEY_REQUEST_FROM_SAFE_TEXT:
            "تم قبول طلبك للعهدة من قبل {{user_name}}",
          notification_type_ACCEPT_MONEY_REQUEST_FROM_SAFE_TEXT_OTHER:
            "تم قبول طلب {{affected_name}} للعهدة من قبل {{creator_name}} من خزينة {{safe_name}}",
          notification_type_REJECT_FORWARD_MONEY_REQUEST_TEXT:
            "تم رفض طلبك للعهدة من قبل {{user_name}}",
          notification_type_REJECT_FORWARD_MONEY_REQUEST_TEXT_OTHER:
            "تم رفض طلب {{affected_name}} للعهدة من قبل {{creator_name}}",
          notification_type_FORWARD_MONEY_REQUEST_TEXT:
            "تم اعادة توجية طلبك بالعهدة المالية من قبل {{user_name}}",
          notification_type_FORWARD_MONEY_REQUEST_TEXT_OTHER:
            "تم اعادة توجية طلب {{affected_name}} بالعهدة المالية من قبل {{creator_name}}",
          notification_type_SETTLED_MONEY_REQUEST_TEXT:
            "{{creator_name}} قام بتصفية عهدة مالية بقيمة {{amount}} من خزينة {{safe_name}}",
          notification_type_ACCEPT_CASHOUT_TEXT:
            "تم قبول طلبك بسحب {{amount}} من خزينة {{safe_name}} من قبل {{creator_name}}",
          notification_type_ACCEPT_CASHOUT_TEXT_OTHER:
            "تم قبول طلب {{affected_name}} بسحب {{amount}} من خزينة {{safe_name}} من قبل {{creator_name}}",
          notification_type_REJECT_CASHOUT_TEXT:
            "تم رفض طلبك بسحب {{amount}} من خزينة {{safe_name}} من قبل {{creator_name}}",
          notification_type_REJECT_CASHOUT_TEXT_OTHER:
            "تم رفض طلب {{affected_name}} بسحب {{amount}} من خزينة {{safe_name}} من قبل {{creator_name}}",
          notification_type_CASHIN_WITHOUT_PERMISSION_TEXT:
            "{{creator_name}} اجرى عملية ايداع مالي بقيمة {{amount}} في خزينة {{safe_name}}",
          notification_type_CASHIN_WITH_PERMISSION_TEXT:
            "{{creator_name}} طلب ايداع مالي بقيمة {{amount}} من خزينة {{safe_name}}",
          notification_type_ACCEPT_CASHIN_TEXT:
            "تم قبول طلبك بايداع {{amount}} من خزينة {{safe_name}} من قبل {{creator_name}}",
          notification_type_ACCEPT_CASHIN_TEXT_OTHER:
            "تم قبول طلب {{affected_name}} بايداع {{amount}} من خزينة {{safe_name}} من قبل {{creator_name}}",
          notification_type_REJECT_CASHIN_TEXT:
            "تم رفض طلبك بايداع {{amount}} من خزينة {{safe_name}} من قبل {{creator_name}}",
          notification_type_REJECT_CASHIN_TEXT_OTHER:
            "تم رفض طلب {{affected_name}} بايداع {{amount}} من خزينة {{safe_name}} من قبل {{creator_name}}",
          notification_type_CASHOUT_WITH_PERMISSION_TEXT:
            "{{creator_name}} طلب سحب مالي بقيمة {{amount}} من خزينة {{safe_name}}",
          notification_type_CASHOUT_WITHOUT_PERMISSION_TEXT:
            "{{creator_name}} طلب سحب مالي بقيمة {{amount}} في خزينة {{safe_name}}",
          notification_type_CREATE_SAFE_TEXT:
            "تم انشاء خزينة جديدة ({{safe_name}}) من قبل {{creator_name}}",
          notification_type_CREATE_DEFAULT_SAFE_TEXT:
            "تم انشاء خزينة جديدة ({{safe_name}}) من قبل {{creator_name}}",
          notification_type_EDIT_SAFE_TEXT: `تم تعديل خزينة ({{safe_name}}) من قبل {{creator_name}}`,
          notification_type_ADD_SAFE_MEMBER_TEXT:
            "تم اضافتك كعضو جديد بخزينة {{safe_name}}",
          notification_type_ADD_SAFE_MEMBER_TEXT_OTHER:
            "تم اضافة {{user_name}} كعضو جديد بخزينة {{safe_name}}",
          notification_type_ADD_ROLE_TEXT:
            "تم اضافة دور جديد ({{role_name}}) من قبل {{creator_name}}",
          notification_type_EDIT_ROLE_TEXT:
            "تم تعديل الدور ({{role_name}}) من قبل {{creator_name}}",
          notification_type_DEACTIVATE_ROLE_TEXT:
            "تم تعطيل الدور ({{role_name}}) من قبل {{creator_name}}",
          notification_type_EDIT_WORKSPACE_TEXT: `تم تعديل مساحة العمل من قبل {{creator_name}}`,
          notification_type_ADD_USER_TO_WORKSPACE_TEXT:
            "تم اضافة ({{user_name}}) كعضو جديد بمساحة العمل",
          notification_type_ACCEPT_USER_INVITATION_TEXT:
            "تم قبول دعوتك ل ({{user_name}}) كعضو جديد بمساحة العمل",
          notification_type_HOLD_SPEND_REQUEST_TEXT:
            "الموظف ({{user_name}}) حجب عملية اذن صرف بقيمة {{amount}} ج.م",
          notification_type_HOLD_MONEY_REQUEST_TEXT:
            "الموظف ({{user_name}}) حجب عملية عهدة مالية بقيمة {{amount}} ج.م وتم رد مبلغ العهدة الاصلي {{source}}",
          safe_name_dynamic: "خزينة {{safe_name}}",
          last_updates_type_CREATE_SAFE_TEXT:
            "تم انشاء خزينة جديدة ({{safe_name}}) بواسطة {{user_name}}",
          last_updates_type_EDIT_SAFE_TEXT:
            "{{user_name}} اجرى تعديلا اداريا على خزينة {{safe_name}}",
          last_updates_type_ADD_SAFE_MEMBER_TEXT:
            "{{user_name}} عضو جديد بخزينة {{safe_name}}",
          last_updates_type_CASHOUT_WITHOUT_PERMISSION_TEXT:
            "{{user_name}} اجرى عملية سحب مالي بقيمة {{amount}} من خزينة {{safe_name}}",
          last_updates_type_ACCEPT_CASHOUT_TEXT:
            "{{affected_name}} اجرى عملية سحب مالي بقيمة {{amount}} من خزينة {{safe_name}}",
          last_updates_type_CASHIN_WITHOUT_PERMISSION_TEXT: `{{user_name}} اجرى عملية ايداع مالي بقيمة {{amount}} من خزينة {{safe_name}}`,
          last_updates_type_ACCEPT_CASHIN_TEXT:
            "{{user_name}} اجرى عملية ايداع مالي بقيمة {{amount}} من خزينة {{safe_name}}",
          last_updates_type_DOCUMENT_FROM_SAFE_TEXT:
            "{{user_name}} اجرى تدوين مصروف مالي بقيمة {{amount}} من خزينة {{safe_name}}",
          last_updates_type_ACCEPT_MONEY_REQUEST_TEXT:
            "{{user_name}} صرف عهدة مالية بقيمة {{amount}} من خزينة {{safe_name}}",
          last_updates_type_ACCEPT_MONEY_REQUEST_FROM_SAFE_TEXT:
            "{{user_name}} صرف عهدة مالية بقيمة {{amount}} تحت تصنيف {{category_name}}",
          last_updates_types_SETTLED_MONEY_REQUEST_TEXT:
            "{{user_name}} قام بتصفية عهدة مالية بقيمة {{amount}} تحت تصنيف {{category_name}}",
          no_team: "لم تقوم باضافة اى اعضاء حتى الان.",
          edit_member_role: "تعديل دور عضو",
          cancel_invitation: "الغاء الدعوة",
          activate: "تنشيط",
          reactivate_member: "اعادة تنشيط عضو",
          deactivate_member_permissions: "ايقاف صلاحيات عضو",
          deactivate: "ايقاف",
          activate_member: "تنشيط العضو",
          deactivate_member: "ايقاف العضو",
          change_role: "تغيير الدور",
          invitation_sent: "تم إرسال دعوة العمل وبانتظار قبولها من العضو",
          workspace_owner: "منشيء مساحة العمل",
          safe_code: "الكود",
          safe_code_no_edit: "كود الخزينة لا يمكن التعديل علية",
          safe_name_hint: "ادخل اسم الخزينة",
          safe_name_exists: "هذا الاسم موجود بخزينة اخرى",
          safe_name_characters: "اسم الخزينة يجب ان يحتوي على حروف",
          safe_name_characters_validation:
            "اسم الخزينة يجب ان يكون بين 3 و 50 حرف",
          pick_safe_manager:
            "اختر من القائمة اعلاه المدير المسؤول عن هذة الخزينة",
          safe_users: "افراد الخزينة",
          safe_users_hint:
            "اختر قائمة الافرد القائمين على هذة الخزينة من حيث عمليات الايداع و السحب باذن و بدونة",
          email_exists: "لقد أدخلت هذا البريد بالفعل.",
          edit_safe_dynamic: "تعديل خزينة {{safe_name}}",
          safe_code_hint: "ادخل الكود المحاسبي للخزينة التي تريد انشائها",
          safe_code_exists: "هذا الكود موجود بخزينة اخرى",
          safe_code_characters:
            " كود الخزينة يجب ان يحتوي فقط على حروف وارقام و -",
          safe_code_characters_validation:
            "كود الخزينة يجب ان يكون بين 3 و 20 حرف",
          add_new_safe: "اضافة خزينة جديدة",
          invalid_email: "بريد الكتروني غير صالح",
          email_already_exists: "هذا البريد الالكتروني مسجل بالفعل",
          add_new_role: "اضافة دور جديد",
          add_role: "اضافة دور",
          edit_role: "تعديل دور",
          permissions_group: "مجموعة الصلاحيات",
          complete_add_role: "اتمام عملية انشاء الدور",
          complete_edit_role: "اتمام عملية تعديل الدور",
          add_another_role: "اضافة دور اخر",
          deactivate_role: "تعطيل الدور",
          activate_role: "تفعيل الدور",
          pending_requests: "الطلبات المعلقة",
          no_pending_requests: "لا طلبات معلقة",
          amount_hint: "أدخل قيمة المبلغ المودع",
          spent_amount_hint: "ادخل قيمة المبلغ الذي تريد صرفة",
          pick_safe_first:
            "اختر الخزينة اللتي تريد اجراء العملية من خلالها اولا",
          category_hint: "اختر التصنيف الذي تمت عملية الإيداع من خلاله.",
          notes_hint:
            "هل هناك بعض الأمور التي تحتاج إلى توضيح؟ يمكنك الاستفاضة في شرحها من هنا.",
          optional: "اختياري",
          required_file: "الملف مطلوب",
          too_large_file: "*حجم الملف كبير جدًا",
          max: "بحد أقصى.",
          drag_drop: "اسحب و اترك",
          or: "او",
          money_request_name_hint:
            "ما الدافع وراء عملية السحب؟ اكتب أعلاه موضوع العملية إجمالاً.",
          transaction_name_no_characters:
            "يجب ان يحتوي موضوع العملية حرف علي الاقل",
          money_request_name_exists:
            "اسم العهدة يتوافق مع عهدة جارية بالفعل, من فضلك أختر اسم أخر",
          empty_page: "صفحة فارغة",
          create_document: "انشاء تدوينة مالية",
          add_another: "اضافة اخر",
          confirm_recharge: "تاكيد الشحنة",
          action_deposit: "ايداع",
          action_withdraw: "سحب",
          total_amount: "اجمالي القيمة",
          login: "سجل دخول",
          home_text_1: `
                    بتطلب أو بيطلب منك فلوس فى الشركة
                    \n
                    وبتتعب فى متابعة الطلبات المالية؟
                `,
          home_text_2: `
                    استعمل Trustbill لمتابعة وارسال كل
                    \n
                    الطلبات المالية بكل سهولة
                `,
          join_us: "انضم الينا",
          login_process: "تسجيل الدخول",
          password: "كلمة المرور",
          remember_me: "تذكرني",
          invalid_credentials: "يوجد خطأ بالبريد الإلكترونى أو كلمة السر",
          deactivated_user: "تم ايقاف نشاطك بواسطه مديرك",
          not_yet_accepted: "لم يتم قبول طلب إنضمامك بعد",
          password_reset_success: "تم تغيير كلمة السر بنجاح",
          passowrd_mismatch: "* كلمة السر غير متطابقة",
          password_confirmation: "تأكيد كلمة المرور",
          link_sent_mail: "تم إرسال رابط التعديل إلى بريدك الإلكتروني.",
          enter_email: "ادخل بريدك الالكتروني",
          email_not_found: "البريد الالكتروني غير موجود",
          reset_password: "استعد كلمة السر",
          request_for_access_personal: "المعلومات الشخصية",
          request_for_access_business: "معلومات العمل",
          request_for_access_tools: "ادوات العمل",

          request_for_access_personal_text_1:
            "معلوماتك الشخصية هي وسيلة تواصلنا معك",
          request_for_access_personal_text_2:
            "يتم الاستعانة بمعلوماتك الشخصية كالاسم و رقم الهاتف ",
          request_for_access_personal_text_3:
            "لتيسير التواصل معك من قبل فريق ترست بيل.",

          request_for_access_business_text_1: "اخبرنا اكثر عن طريقة عملك",
          request_for_access_business_text_2:
            "معلومات الشركة و طبيعة العمل بها سيكون في صدارة مساحة عملك",
          request_for_access_business_text_3:
            "و سوف يساعد فريقنا في تحسين الخدمات المقدمة",

          request_for_access_tools_text_1:
            "ما هي ادوات التواصل والادارة الرئيسية ؟",
          request_for_access_tools_text_2:
            "هل هناك ادوات تستخدمونها بينيا للتواصل. او لادارة الموارد المالية؟",
          request_for_access_tools_text_3:
            "من خلال هذة الخطوة. يمكننا تقديم خدمة تراعي طبيعة التواصل والادارة",
          request_for_access_tools_text_4: "داخل الشركة.",

          wrong_confirmation_code: "خطأ في الكود",
          full_name: "الاسم بالكامل",
          full_name_hint: "يتم عرض هذا الاسم لتعريفك خلال العمل",
          two_names_validation: "*يجب ان يتكون الاسم الكامل من اسمين",
          phone: "رقم الهاتف",
          phone_hint:
            "اكتب رقم هاتفك المحمول بالاعلى حتى نتمكن من خدمتك بصورة افضل",
          no_spaces_just_numbers: "*لا تستخدم مسافات و اقتصر فقط على الأرقام",
          email_verified: "تم تاكيد البريد الالكتروني",
          confirmation_code: "كود التاكيد",
          resend_code: "يمكنك طلب اعادة ارسال الكود خلال {{seconds}} ث",
          fill_all_data: "برجاء اكمال جميع البيانات المطلوبة",
          average_employees: "متوسط عدد أفراد الشركة",
          company_average_transactions: "متوسط عدد التعاملات",
          company_average_transactions_hint:
            "متوسط عدد التعاملات خلال الشهر. تتضمن العهد والمصروفات و المشتريات...",
          company_average_transactions_amount: "متوسط قيمة التعاملات",
          company_average_transactions_amount_hint:
            "متوسط قيمة التعاملات خلال الشهر. تتضمن العهد والمصروفات و المشتريات...",
          write_opinion: "اكتب ما يخطر ببالك",
          other_tools: "ادوات اخرى",
          financial_management: "الادارة المالية",
          company_tools_financial_hint:
            "اختر من القائمة اعلاة الادوات التي تستخدمها شركتك لادارة مواردها المالية",
          add_tool: "يمكنك اضافة أداة ثم Enter",
          communication: "التواصل",
          no_right_tools: "لم تجد احد الخيارات بالاعلى مناسبة ؟ اكتب هنا",
          company_tools_communication_hint:
            "اختر من القائمة اعلاة الادوات التي يستخدمها افراد شركتك للتواصل فيما بينهم",
          request_for_access_success_1: "اهلا بك يا {{user_name}},",
          request_for_access_success_2: "لقد تم استقبال طلبك لنسخة من ترست بيل",
          request_for_access_success_3:
            "سيتم التواصل معك من قبل فريق العمل لدينا خلال ايام",
          request_for_access_success_4: "سعداء برغبتك بالانضمام الى مجتمعنا :)",
          block_transaction: "حجب عملية",
          send_spend_request: "ارسال طلب اذن صرف",
          discard: "الغاء التدوين",
          request_name: "موضوع الطلب",
          spend_request_purpose:
            "ما الدافع وراء طلب إذن الصرف؟ اكتب أعلاه موضوع الطلب إجمالاً.",
          recipient_hint: "من هو الشخص المعني بالطلب؟",
          response_date_hint: "ما الموعد الذي يزمع صرف المبلغ فيه.",
          ask_for_money_request_text:
            "يمكنك هنا تقديم طلب لشحن رصيدك حسابك الحالي، حتى يمكنك تدوين مصروفاتك وقبول الطلبات المالية بشكل أكثر سلاسة ومهنية.",
          money_request_name: "اسم العهدة",
          money_request_name_hint: "أدخل عنوان العهدة المطلوبة.",
          requested_amount: "القيمة المطلوبة",
          requested_amount_hint:
            "أدخل اسم القيمة التي تود طلب إضافتها إلى رصيدك.",
          money_request_purpose_hint: "اشرح السبب الذي تود طلب العهدة من أجله.",
          money_request_balance_dynamic: "رصيد العهدة الحالي : {{balance}}",
          safe_balance_dynamic: "رصيد الخزينة الحالي : {{balance}}",
          pay_from_safe: "دفع المبلغ من الخزينة مباشرةً.",
          document_type_hint:
            "هل يتكرر هذا النوع من المصروف بشكل دوري, أم هو حدث لا يتكرر عادة.",
          spend_date_hint: "ادخل تاريخ الصرف الفعلي المُدون على إيصال الصرف",
          accept_member: "قبول العضو",
          new_workspace_text_1: "وسع دوائر عملك",
          new_workspace_text_2:
            "عن طريق اضافة مساحة عمل جديدة ، يمكنك التعامل بشكل أكثر سلاسة مع حساباتك المالية.",
          default_role: "الدور الافتراضي",
          en_safe_name: "اسم الخزينة باللغة الانجليزية",
          en_safe_name_hint: "ادخل اسم الخزينة باللغة الانجليزية",
          company_en_name: "اسم الشركة باللغة الانجليزية",
          role_position: "اسم الدور",
          role_position_hint: "ادخل اسم الدور مثال: محاسب",
          role_en_position: "اسم الدور باللغة الانجليزية",
          role_en_position_hint: "ادخل اسم الدور باللفة الانجليزية",
          save_edit_safe: "حفظ"
        },
      },
      en: {
        translation: {
          save_edit_safe: "Save",
          home_text_1: `
                    بتطلب أو بيطلب منك فلوس فى الشركة
                    \n
                    وبتتعب فى متابعة الطلبات المالية؟
                `,
          home_text_2: `
                    استعمل Trustbill لمتابعة وارسال كل
                    \n
                    الطلبات المالية بكل سهولة
                `,
          new_workspace_text_1: "وسع دوائر عملك",
          new_workspace_text_2:
            "عن طريق اضافة مساحة عمل جديدة ، يمكنك التعامل بشكل أكثر سلاسة مع حساباتك المالية.",
          role_en_position: "Role Name In English",
          role_en_position_hint: "Please Enter Role Name In English",
          company_en_name: "Company English Name",
          en_safe_name: "English Safe Name",
          en_safe_name_hint: "Please Type Safe Name In English",
          safe_users_hint: "Select the team of this safe",
          notes_hint:
            "Is there anything that needs further details? you can explain it here.",
          money_request_name_hint:
            "What is the motive behind the withdrawal transaction? Write above the general transaction subject.",
          empty_page_text:
            "It sounds like there are no transactions here yet, would you like to create a new expense transaction?",
          request_for_access_personal_text_1:
            "Your personal info is our way to contact with you",
          request_for_access_personal_text_2:
            "Your personal inf such as your name and phone number is used",
          request_for_access_personal_text_3:
            "to facilitate communication with you by the Trust Bell team",
          request_for_access_business_text_1: "اTell us more about your work",
          request_for_access_business_text_2:
            "Your company info and business details will be at the forefront of your workspace",
          request_for_access_business_text_3:
            "and will help our team to improve the services provided",
          request_for_access_tools_text_1:
            "What are the main communication and management tools you're using to run your company's business?",
          request_for_access_tools_text_2:
            "Are there any tools that you use to communicate? Or to manage the financial resources on your company?",
          request_for_access_tools_text_3:
            "By this step, We can provide a service that takes into account the nature",
          request_for_access_tools_text_4: "of your business",
          full_name_hint:
            "This name is displayed to identify you on the workspace",
          company_average_transactions_hint:
            "Average number of transactions during the month. It includes the custodies, expenses and purchases...",
          company_average_transactions_amount_hint:
            "Average amount of transactions during the month. It includes the custodies, expenses and purchases...",
          company_average_transactions_amount: "Avarage transaction value",
          company_tools_financial_hint:
            "Choose from the list above the tools that your company uses to manage its financial resources",
          no_right_tools:
            "Didn't find one of the above options suitable? write here",
          company_tools_communication_hint:
            "Choose from the list above the tools that your company members use to communicate with each other",
          request_for_access_success_1: "Welcome, {{user_name}},",
          request_for_access_success_2:
            "Your request to try TrustBill system has been received",
          request_for_access_success_3: "Our team will contact you within days",
          request_for_access_success_4:
            "Glad you want to join our community :)",
          spend_request_purpose:
            "What is the motive behind the request for permission to exchange? Write above the subject of your request",
          spent_amount_hint: "Enter the amount you want to spend",
          recipient_hint: "Who is the person concerned with the request?",
          ask_for_money_request_text:
            "Here you can submit a request to charge your account balance, so that you can write down expenses and accept petty-cash requests more efficiently.",
          money_request_name: "Petty-cash title",
          money_request_name_hint: "Enter the Petty-cash title.",
          requested_amount_hint:
            "Enter the value you want to add to your balance.",
          money_request_purpose_hint:
            "Why you want this amount as a petty-cash? you can explain the reason here.",
          pay_from_safe: "Pay from the safe directly",
          document_type_hint:
            "Does this type of expense recur periodically, or is it an event that does not usually recur?",
          spend_date_hint:
            "Enter the actual disbursement date written on the exchange receipt",
          default_role: "Default Role",
          accept_member: "Accept Member",
          money_request_balance_dynamic:
            "Current PettyCash Balance: {{balance}}",
          safe_balance_dynamic: "Current Safe Balance: {{balance}}",
          requested_amount: "Requested Amount",
          request_name: "Request Subject",
          discard: "Discard",
          send_spend_request: "Send Spend Request",
          block_transaction: "Block Transaction",
          communication: "Communication",
          add_tool: "You Can add Tool and press Enter",
          financial_management: "Financial Management",
          other_tools: "Other Tools",
          write_opinion: "Write What's On Your Mind",
          company_average_transactions: "Average Transactions Numbers",
          average_employees: "Average Employees Number In Company",
          fill_all_date: "Please, Fill All Required Data",
          resend_code:
            "You Can Resend Confirmation Code in {{seconds}} seconds",
          confirmation_code: "Confirmation Code",
          email_verified: "Email Verified Successfully",
          no_spaces_just_numbers: "No Spaces Allowed. Just Use Numbers",
          phone_hint: "Enter Your Phone Number",
          phone: "Phone Number",
          two_names_validation: "Full Name Must Be Two Names",
          full_name: "Full Name",
          wrong_confirmation_code: "Incorrect Confirmation Code",
          request_for_access_personal: "Personal Information",
          request_for_access_business: "Business Information",
          request_for_access_tools: "Work Tools",
          reset_password: "Reset Password",
          email_not_found: "Email Not Found",
          enter_email: "Enter Your Email",
          link_sent_mail: "A Link Was Sent To Your Email",
          password_confirmation: "Password Confirmation",
          passowrd_mismatch: "Your Passwords Must Match",
          password_reset_success: "Your Password Was Reset Successfully!",
          not_yet_accepted: "Your Invitation Hasn't been accepted yet!",
          deactivated_user: "Your are deactivated by your manager",
          invalid_credentials: "Invalid Credentials",
          remember_me: "Remember me",
          password: "Password",
          join_us: "Join Us",
          login: "Login",
          login_process: "Login",
          total_amount: "Total Amount",
          action_deposit: "Deposit",
          action_withdraw: "Withdraw",
          confirm_recharge: "Confirm Recharge",
          add_another: "Add another",
          create_document: "Create Document",
          empty_page: "Empty Page",
          money_request_name_exists: "Name Already Exists",
          transaction_name_no_characters:
            "Transaction Name Must Contains at least one character",
          or: "Or",
          drag_drop: "Drag and Drop",
          upload_attachment: "Upload Attachment",
          max: "Maximum",
          too_large_file: "Too Large File",
          required_file: "Required File",
          optional: "Optional",
          category_hint: "Select Category",
          pick_safe_first: "Please Pick A Safe First",
          amount_hint: "Enter amount",
          no_pending_requests: "No Pending Requests",
          pending_requests: "Pending Requests",
          activate_role: "Activate Role",
          deactivate_role: "Deactivate Role",
          add_another_role: "Add Another Role",
          complete_add_role: "Confirm Add Role",
          complete_edit_role: "Confirm Edit Role",
          permissions_group: "Permissions Group",
          role_position: "Position Name",
          role_position_hint:
            "Enter The Role Position Name. Ex: Accountant, Engineer",
          add_role: "Add Role",
          edit_role: "Edit Role",
          add_new_role: "Add New Role",
          invalid_email: "Invalid Email",
          add_new_safe: "Add New Safe",
          safe_code_characters_validation:
            "Safe Code Must Be Between 3 and 20 characters",
          safe_code_characters:
            "Safe Code Must Only Contains Characters, numbers and special characters",
          safe_code_exists: "Safe Code Already Exists!",
          safe_code_hint: "Safe Code",
          the_manager: "The Manager",
          edit_safe_dynamic: "Edit {{safe_name}} Safe",
          email_exists: "Email Already Exists!",
          email_already_exists: "This Email Is Already Registered",
          safe_users: "Safe's Users",
          pick_safe_manager: "Pick Safe Manager",
          safe_name_characters_validation:
            "Safe Name Must Be Between 3 and 50 characters",
          safe_name_characters: "Safe Name Must Contains Characters",
          safe_name_exists: "Safe Name Exists",
          safe_name_hint: "Enter Safe Name",
          safe_code: "Code",
          safe_code_no_edit: "Safe Code Can't Be Edited",
          name: "Name",
          workspace_owner: "Workspace's Owner",
          invitation_sent: "Invitation Sent, Waiting for member's approval",
          change_role: "Change Role",
          activate: "Activate",
          reactivate_member: "Reactivate Member",
          deactivate_member_permissions: "Deactivate Member's Permissions",
          deactivate: "Deactivate",
          activate_member: "Activate Member",
          deactivate_member: "Deactivate Member",
          edit: "Edit",
          cancel_invitation: "Cancel Invitation",
          edit_member_role: "Edit Member's Role",
          no_team: "No Team Members",
          add_new_member: "Add New Member",
          confirm_close_money_request: "Confirm Close Money Request",
          go_to_home: "Go To Homepage",
          min_chars_validation:
            "{{field_name}} Characters Must Be Greater Than {{number}}",
          max_chars_validation:
            "{{field_name}} Characters Must Be Less Than {{number}}",
          max_validation: "Maximum Amount Has Been Exceeded",
          min_validation: "Amount Must Be Greater Than {{min}}",
          field_required: "{{field_name}} Is Required",
          company_members: "Company Members",
          company_responsibility: "Your Responsibility",
          company_specialization: "Company Specialization",
          company_name: "Company Name",
          pick_company_pick: "Please Pic Your Company Picture",
          member: "Member",
          manager: "Manager",
          regulator: "Regulator",
          change_password: "Change Your Password",
          first_name: "First Name",
          last_name: "Last Name",
          email: "Email",
          no_spaces_allowed:
            "No Spaces Allowed, use only numbers and special characters",
          pick_profile_pic: "Please Pic Your Profile Picture",
          settings_tabs_account: "Profile",
          settings_tabs_system: "System",
          settings_tabs_workspace: "Workspace",
          settings_tabs_team: "Team",
          settings_tabs_categories: "Categories",
          settings_tabs_roles: "Roles",
          settings_tabs_safes: "Safes",
          accept_deposit_request: "Accept Deposit Request",
          reject_deposit_request: "Reject Deposit Request",
          creator: "Creator",
          reject_withdraw_request: "Reject Withdraw Request",
          accept_withdraw_request: "Accept Withdraw Request",
          accept: "Accept",
          num_of_documents_dynamic: "{{number}} Expenses",
          notes_dynamic: "{{user_name}} Notes",
          reject: "Reject",
          transaction_status: "Transaction Status",
          accepted_money_requests: "Accepted Money Requests",
          spent_amount: "Spent Amount",
          all_documents: "All Expenses",
          money_request_details: "Money Request Details",
          running_money_request: "Running Money Request",
          closed_money_request: "Closed Money Request",
          safe_code: "Safe Code",
          documents_amount: "Expenses Amount",
          original_amount: "Original Amount",
          debtor: "Debtor",
          gt: "Greater",
          lt: "lower",
          from_specified_time: "Than Specified Time",
          document_transactions: "Expenses",
          withdraw_transactions: "Withdraw Transactions",
          deposit_transactions: "Deposit Transactions",
          less: "less",
          filter_by_safe: "Filter By Safe",
          max_safe_select: "You Can't Select More Than {{number}} Safes",
          chart_type_safes_balance: "Safes' Balance",
          all_safes: "All Safes",
          monthly_average: "Monthly Average",
          running_money_request_data: "Current Money Requests Data",
          spend_purpose: "Spend Purpose",
          urgent: "Urgent",
          inessential: "Inessential",
          main_category: "Main Category",
          subcategory: "Subcategory",
          max_delivery_time: "Maximum Delivery Time",
          request_status: "Request Status",
          deposit: "Deposit",
          widthdraw: "Withdraw",
          subordinate_money_request: "Subordinate Money Request",
          permission_status: "Permission Status",
          transaction_type: "Transaction Type",
          money_request_status: "Money Request Status",
          transaction_users: "Transaction Users",
          money_request_source: "PettyCash Source",
          close_money_request: "Close PettyCash",
          current_amount: "Current Amount",
          closing_amount: "Closing Amount",
          no_response: "No Response",
          your_response: "Your Response",
          block_date: "Block Date",
          closing_date: "Closing Date",
          expense: "Expense",
          back: "Back",
          accept_from_money_request: "Accept From PettyCash",
          accept_from_safe: "Accept From Safe",
          money_request_accept: "Accept Money Request",
          money_request_reject: "Reject Money Request",
          money_request_purpose: "Money Request Purpose",
          user_balance: "{{user_name}} Current Balance:",
          closed_money_requests: "Closed PettyCash",
          current_money_requests: "Current PettyCash",
          all_money_requests: "All PettyCash",
          no_notes: "No Notes",
          send_ticket: "Send Ticket",
          forward: "Forward",
          document_type: "Document Type",
          create_document: "Create Document",
          no_attachments: "No Attachments",
          expense_details: "Expense Details",
          none: "None",
          documentation_date: "Documentation_date",
          to_amount_hint: "Enter The Maximum Amount",
          from_amount_hint: "Enter The Minimum Amount",
          save: "Save",
          response_date: "Response Date",
          max_response_time: "Maximum Response Time",
          from: "from",
          from_date_hint: "Enter The Start Date",
          send_date: "Send Date",
          to: "to",
          to_date_hint: "Enter The End Date",
          cancel: "Cancel",
          logout: "Logout",
          no_available_options: "No Available Options",
          add_attachments: "Add Attachments",
          egp: "EGP",
          suspend: "Block",
          block: "Block",
          attachments: "Attachments",
          notes: "Notes",
          required_response_time: "Required Response Time",
          last_updates: "Last Updates",
          your_custody: "Your Custody",
          cashin_without_permission: "Cashin",
          cashin_with_permission: "Cashin Request",
          cashout_without_permission: "Cashout",
          cashout_with_permission: "Cashout Request",
          current_balance: "your account balance",
          selected_transactions: "Selected records",
          selected_amount: "Selected value",
          amount: "Value",
          show_indicators: "Show insights",
          limit_table_view: "Customize table",
          archive: "Archive",
          archive_selected_transactions: "Archive Selected Transactions",
          archive_confirm: "Archive Transactions",
          filteration: "Filter",
          export: "Export",
          search_placeholder: "Type some keywords?",
          transaction_code: "code",
          transaction_name: "title",
          transaction_notes: "Transaction Notes",
          category_name: "Category",
          user_name: "Username",
          all: "All",
          category: "Category",
          recipient: "Recipient",
          sent_or_response_date: "Send/response date",
          received_or_response_date: "Received/response date",
          response_status: "Response status",
          new_transaction: "New document",
          settings: "Settings",
          requests: "Requests",
          spend_request: "Spend request",
          spend_requests: "Spend requests",
          spend_request_accept: "Accept Spend Request",
          spend_request_reject: "Reject Spend Request",
          sent_spend_requests: "Outgoing spend requests",
          received_spend_requests: "Incoming spend requests",
          your_documents: "Your expenses",
          documents: "Expenses",
          documentations: "Expense invoices",
          withdrawals: "Safe withdrawals",
          money_requests_plural: "PetttyCash",
          money_requests: "PettyCash",
          money_request: "PettyCash",
          sent_money_requests: "Outgoing PettyCash requests",
          received_money_requests: "Incoming PettyCash requests",
          live_section: "Helicopter overview",
          safe: "Safe",
          safes: "Safes",
          notifications: "Notification",
          transaction_type_deposit: "Deposit to safe",
          transaction_type_withdraw: "Withdraw from the safe",
          transaction_type_spend_request: "Spend request invoice",
          transaction_type_money_request: "PettyCash invoice",
          transaction_type_document: "Expense invoice",
          new_workspace: "New workspace",
          workspaces: "Workspaces",
          workspace_setting: "Workspace settings",
          select_specific_info: "Select certain data",
          show_all_info: "Show all data",
          reset_all: "Reset All",
          person_name: "Username",
          status: "Status",
          date: "Date",
          spend_date: "Expense date",
          document_type: "Expense type",
          periodic: "Frequent",
          rare: "Once time",
          parent_money_request: "Parent PettyCash",
          sender: "Sender",
          money_request_status: "PettyCash status",
          from_safe: "Expanse from the safe",
          creation_date: "Recording date",
          transaction_pending: "Pending",
          transaction_running: "Processing",
          transaction_closed: "Done settled",
          transaction_type: "Record type",
          pagination_controls_next: "Next",
          pagination_controls_previous: "Previous",
          safes_balance: "Total safes' balance",
          average_monthly: "M.Avarage",
          chart_type_deposits: "Deposits",
          chart_type_withdrawals: "Withdrawals",
          chart_type_documents: "Expenses",
          chart_type_safe_balance: "Safe balance",
          current_month: "Current month",
          previous_month: "Previous month",
          current_week: "Current week",
          previous_week: "Previous week",
          this_week: "Current week",
          last_six_months: "Last 6 months",
          today: "Today",
          yesterday: "Yesterday",
          pending_transactions: "Pending transactions",
          last_updates: "Last updates",
          no_data_for_date: "No data for the selected date",
          more: "More",
          top_categories: "Top categories",
          total_deposits: "Total deposits",
          total_withdrawals: "Total withdrawals",
          total_documents: "Total expenses",
          more_than_specified_time: "More on the selected period",
          less_than_specified_time: "Less on the selected period",
          count: "Count",
          safes_list: "Safes list",
          safe_running_money_requests: "PettyCash amount",
          safe_members: "Safe members",
          safe_manager: "Safe manager",
          last_update: "Last update",
          safe_name: "Safe title",
          safe_balance: "Safe balance",
          notification_type_SPEND_REQUEST: "Spend request",
          notification_type_ACCEPT_SPEND_REQUEST: "Approved spend request",
          notification_type_REJECT_SPEND_REQUEST: "Declined spend request",
          notification_type_REJECT_MONEY_REQUEST: "Declined PettyCash request",
          notification_type_ACCEPT_FORWARD_SPEND_REQUEST:
            "Approved spend request",
          notification_type_REJECT_FORWARD_SPEND_REQUEST:
            "Declined spend request",
          notification_type_FORWARD_SPEND_REQUEST: "Forward spend request",
          notification_type_DOCUMENT: "Document new expense",
          notification_type_DOCUMENT_FROM_SAFE:
            "Documented expense from the safe",
          notification_type_MONEY_REQUEST: "PettyCash request",
          notification_type_ACCEPT_MONEY_REQUEST: "Approved PettyCash request",
          notification_type_ACCEPT_MONEY_REQUEST_FROM_SAFE:
            "Approved PettyCash request from the safe",
          notification_type_REJECT_FORWARD_MONEY_REQUEST:
            "Declined PettyCash request",
          notification_type_FORWARD_MONEY_REQUEST: "Forward PettyCash request",
          notification_type_SETTLED_MONEY_REQUEST: "Settled PettyCash request",
          notification_type_ACCEPT_CASHOUT: "Approved withdrawal request",
          notification_type_REJECT_CASHOUT: "Declined withdrawal request",
          notification_type_CASHIN_WITHOUT_PERMISSION: "Deposit",
          notification_type_CASHIN_WITH_PERMISSION: "Depositing request",
          notification_type_ACCEPT_CASHIN: "Approved deposting request",
          notification_type_REJECT_CASHIN: "Declined deposting request",
          notification_type_CASHOUT_WITH_PERMISSION: "Withdrawal request",
          notification_type_CASHOUT_WITHOUT_PERMISSION:
            "Withdrawal transaction",
          notification_type_CREATE_SAFE: "New safe",
          notification_type_CREATE_DEFUALT_SAFE: "New safe",
          notification_type_EDIT_SAFE: "Administrative edit",
          notification_type_ADD_SAFE_MEMBER: "New safe member",
          notification_type_ADD_ROLE: "New role",
          notification_type_EDIT_ROLE: "Edit role",
          notification_type_DEACTIVATE_ROLE: "Revoke role",
          notification_type_EDIT_WORKSPACE: "Edit workspace",
          notification_type_ADD_USER_TO_WORKSPACE: "New workspace member",
          notification_type_ACCEPT_USER_INVITATION:
            "Your invitation has been accpted",
          notification_type_HOLD_SPEND_REQUEST:
            "Revoke spend request transaction",
          notification_type_HOLD_MONEY_REQUEST: "Revoke PettyCash transaction",
          last_updates_type_CREATE_SAFE: "New safe",
          last_updates_type_EDIT_SAFE: "Administrative edit",
          last_updates_type_ADD_SAFE_MEMBER: "New member",
          last_updates_type_CASHOUT_WITHOUT_PERMISSION: "Withdrawal",
          last_updates_type_ACCEPT_CASHOUT: "Withdrawal",
          last_updates_type_CASHIN_WITHOUT_PERMISSION: "Deposit",
          last_updates_type_ACCEPT_CASHIN: "Deposit",
          last_updates_type_DOCUMENT_FROM_SAFE:
            "Documented expense from the safe",
          last_updates_type_ACCEPT_MONEY_REQUEST: "Approved PettyCash request",
          last_updates_type_ACCEPT_MONEY_REQUEST_FROM_SAFE:
            "Approved PettyCash request from the safe",
          last_updates_types_SETTLED_MONEY_REQUEST: "Settle PettyCash request",
          account_info: "Account Details",
          change_password: "Change password",
          confirm: "Confirm",
          system: "System",
          company_info: "Company info",
          team: "Team",
          categories: "Categories",
          role: "Role",
          joining_date: "Joining date",
          account_balance: "Account balance",
          new_member: "Add new member",
          users: "Members",
          permissions: "Authorities",
          new_role: "Add new role",
          new_safe: "Add new safe",
          accepted: "Approved",
          rejected: "Declined",
          no_permission_needed: "This member don't need a permession",
          notification_type_SPEND_REQUEST_TEXT:
            "You've recieved a new spend request from {{user_name}}",
          notification_type_ACCEPT_SPEND_REQUEST_TEXT:
            "Your spend request has been approved by {{user_name}}",
          notification_type_REJECT_SPEND_REQUEST_TEXT:
            "Your spend request has been declined by {{user_name}}",
          notification_type_REJECT_MONEY_REQUEST_TEXT:
            "Your PettyCash request has been approved by {{user_name}}",
          notification_type_ACCEPT_FORWARD_SPEND_REQUEST_TEXT:
            "Your spend request has been approved by {{user_name}}",
          notification_type_ACCEPT_FORWARD_SPEND_REQUEST_TEXT_OTHER: `{{affected_name}}'s spend request has been approved by {{creator_name}}`,
          notification_type_REJECT_FORWARD_SPEND_REQUEST_TEXT:
            "Your spend request has been declined by {{user_name}}",
          notification_type_REJECT_FORWARD_SPEND_REQUEST_TEXT_OTHER:
            "{{affected_name}}'s spend request has been declined by {{creator_name}}",
          notification_type_FORWARD_SPEND_REQUEST_TEXT:
            "Your spend request has been forwarded to {{user_name}}",
          notification_type_FORWARD_SPEND_REQUEST_TEXT_OTHER:
            "{{affected_name}}'s spend request has been forward by {{creator_name}}",
          notification_type_DOCUMENT_TEXT:
            "{{creator_name}} create a new expense document of {{amount}} EGP from {{safe_name}} safe",
          notification_type_DOCUMENT_FROM_SAFE_TEXT:
            "{{creator_name}} create a new expense document of {{amount}} EGP From {{safe_name}} safe`",
          notification_type_MONEY_REQUEST_TEXT:
            "You have recieved a new PettyCash request from {{user_name}}",
          notification_type_ACCEPT_MONEY_REQUEST_TEXT:
            "Your PettyCash request has been approved by {{user_name}}",
          notification_type_ACCEPT_MONEY_REQUEST_TEXT_OTHER:
            "{{affected_name}}'s PettyCash request has been approved by {{creator_name}}",
          notification_type_ACCEPT_MONEY_REQUEST_FROM_SAFE_TEXT:
            "Your PettyCash request has been approved by {{user_name}}",
          notification_type_ACCEPT_MONEY_REQUEST_FROM_SAFE_TEXT_OTHER:
            "{{affected_name}}'s PettyCash request has been approved by {{creator_name}} from {{safe_name}} safe",
          notification_type_REJECT_FORWARD_MONEY_REQUEST_TEXT:
            "Your PettyCash request has been declined by {{user_name}}",
          notification_type_REJECT_FORWARD_MONEY_REQUEST_TEXT_OTHER:
            "{{affected_name}}'s PettyCash request has been declined by {{creator_name}}",
          notification_type_FORWARD_MONEY_REQUEST_TEXT:
            "Your PettyCash request has been forwarded by {{user_name}}",
          notification_type_FORWARD_MONEY_REQUEST_TEXT_OTHER:
            "{{affected_name}}'s PettyCash request has been forwarded by {{creator_name}}",
          notification_type_SETTLED_MONEY_REQUEST_TEXT:
            "{{creator_name}} has settled his PettyCash of {{amount}} EGP that been taken from {{safe_name}} safe",
          notification_type_ACCEPT_CASHOUT_TEXT:
            "Your withdrwal request of {{amount}} EGP From {{safe_name}} safe has been approved by {{creator_name}}",
          notification_type_ACCEPT_CASHOUT_TEXT_OTHER:
            "{{affected_name}}'s withdrwal request of {{amount}} EGP from {{safe_name}} safe has been approved by {{creator_name}}",
          notification_type_REJECT_CASHOUT_TEXT:
            "Your withdrwal request of {{amount}} EGP From {{safe_name}} safe has been declined by {{creator_name}}",
          notification_type_REJECT_CASHOUT_TEXT_OTHER:
            "{{affected_name}}'s withdrwal request of {{amount}} EGP from {{safe_name}} safe has been rejected by {{creator_name}}",
          notification_type_CASHIN_WITHOUT_PERMISSION_TEXT:
            "A new deposit transaction has been created by {{creator_name}} of  {{amount}} EGP to {{safe_name}} safe",
          notification_type_CASHIN_WITH_PERMISSION_TEXT:
            "A new deposit request from {{creator_name}} of {{amount}} EGP to {{safe_name}} safe",
          notification_type_ACCEPT_CASHIN_TEXT:
            "Your deposit request of {{amount} EGP to {{safe_name}} safe has been approved by {{creator_name}",
          notification_type_ACCEPT_CASHIN_TEXT_OTHER:
            "{{affected_name}}'s deposit request of {{amount}} EGP from {{safe_name}} safe has been approved by {{creator_name}}",
          notification_type_REJECT_CASHIN_TEXT:
            "Your deposit request of {{amount}} EGP to {{safe_name}} safe has been declined by {{creator_name}}",
          notification_type_REJECT_CASHIN_TEXT_OTHER:
            "{{affected_name}}'s deposit request of {{amount}} EGP to {{safe_name}} safe has been declined by {{creator_name}}",
          notification_type_CASHOUT_WITH_PERMISSION_TEXT:
            "{{creator_name}} ask for a deposit of {{amount}} EGP from {{safe_name}} safe",
          notification_type_CASHOUT_WITHOUT_PERMISSION_TEXT:
            "{{creator_name}} ask for withdrwal of {{amount}} EGP from {{safe_name}} safe",
          notification_type_CREATE_SAFE_TEXT:
            "A new safe titled by ({{safe_name}}) has been created by {{creator_name}}",
          notification_type_CREATE_DEFAULT_SAFE_TEXT:
            "A new safe titled by ({{safe_name}}) has been created by {{creator_name}}",
          notification_type_EDIT_SAFE_TEXT:
            "({{safe_name}}) safe has been edited by {{creator_name}}",
          notification_type_ADD_SAFE_MEMBER_TEXT:
            "You have become a new member to {{safe_name}} safe",
          notification_type_ADD_SAFE_MEMBER_TEXT_OTHER:
            "{{user_name}} has been added as a new member to {{safe_name}} safe",
          notification_type_ADD_ROLE_TEXT:
            "A new role titled by ({{role_name}}) has been created by {{creator_name}}",
          notification_type_EDIT_ROLE_TEXT:
            "({{role_name}}) role has been edited by {{creator_name}}",
          notification_type_DEACTIVATE_ROLE_TEXT:
            "({{role_name}}) has been Revoked by {{creator_name}}",
          notification_type_EDIT_WORKSPACE_TEXT: `The workspace has been edited by {{creator_name}}`,
          notification_type_ADD_USER_TO_WORKSPACE_TEXT:
            "({{user_name}}) has been added as a new member to the workspace",
          notification_type_ACCEPT_USER_INVITATION_TEXT:
            "({{user_name}}) accepted your invitation to join the workspace",
          notification_type_HOLD_SPEND_REQUEST_TEXT:
            "({{user_name}}) Revoked a spend request document of {{amount}} EGP",
          notification_type_HOLD_MONEY_REQUEST_TEXT:
            "Member ({{user_name}}) Revoked a PettyCash transaction of {{amount}} EGP. the rest of the original amount has returned to {{source}}",
          safe_name_dynamic: "{{safe_name}} safe",
          last_updates_type_CREATE_SAFE_TEXT:
            "A new safe titled by ({{safe_name}}) safe has been created by {{user_name}}",
          last_updates_type_EDIT_SAFE_TEXT:
            "A new edit to {{safe_name}} safe by {{user_name}}",
          last_updates_type_ADD_SAFE_MEMBER_TEXT:
            "{{user_name}} is now a member of {{safe_name}} safe",
          last_updates_type_CASHOUT_WITHOUT_PERMISSION_TEXT:
            "{{user_name}} create a new withdrwal transaction of {{amount}} EGP from {{safe_name}} safe",
          last_updates_type_ACCEPT_CASHOUT_TEXT:
            "{{affected_name}} create a new withdrwal transaction of {{amount}} EGP from {{safe_name}} safe",
          last_updates_type_CASHIN_WITHOUT_PERMISSION_TEXT: `{{user_name}} create a new withdrwal transaction of {{amount}} EGP from {{safe_name}} safe`,
          last_updates_type_ACCEPT_CASHIN_TEXT:
            "{{user_name}} create a new deposit transaction of {{amount}} EGP from {{safe_name}} safe",
          last_updates_type_DOCUMENT_FROM_SAFE_TEXT:
            "{{user_name}} create a new expense document of {{amount}} EGP directly from {{safe_name}} safe",
          last_updates_type_ACCEPT_MONEY_REQUEST_TEXT:
            "{{user_name}} create a new expense document of {{amount}} EGP directly from {{safe_name}} safe",
          last_updates_type_ACCEPT_MONEY_REQUEST_FROM_SAFE_TEXT:
            "{{user_name}} exchanged a PettyCash of {{amount}} EGP under {{category_name}} category",
          last_updates_types_SETTLED_MONEY_REQUEST_TEXT:
            "{{user_name}} settled a PettyCash of {{amount}} EGP under {{category_name}} category",
          alert_text_archive: `
    All selected transactions will get moved to archive
    \n
    Closed transaction is the accepted, rejectedn, and settled transactions.
    \n
   Click on archive transaction to confirm the action.
   `,
          alert_text_accept_spend_request: `
    The spend request from Islam will get approved.
    \n
    Islam will get notified that his request has been approved.
    `,
          alert_text_reject_spend_request: `The spend request that been sent from {{sender_name}} will be declined
    \n
    {{sender_name}} will get notified that his request has been declined.
    `,
          alert_text_logout: `You will logout from your account and returned to trustbill landing page
    `,
          alert_text_confirm_close_money_request: `
    The pettycash transaction will get settled, an an amount by {{amount}} EGP will get refunded.
    \n
    Once you settle the transaction, your action cannot undo. 
    You can View the pettycash in the settled tab in the pettycash section on the system sidebar.
    `,
          alert_text_confirm_send_money_request: `A petty cash request in the amount of {{amount}} will be send to {{recipient}} 
    \n
    Once you send this financial request, you'll not be able to unsend it.
    You can check this request stutes in the pettycash section on the system sidebar.
    `,
          alert_text_cancel: `All information you've entered will get lost, are you sure you want to cancel the transaction?
    `,
          alert_text_confirm_reject_money_request_text: `Your're about to decline a pettycash request from {{sender}} in the amount of {{amount}} EGP.
    \n
    you can't undo the declining action, and your balance will remain {{balance}} egp.
    `,
          alert_text_confirm_reject_money_request: `A pettycash request {{amount}} from {{sender}} will be declined
    \n
    in amount of {{amount}}
    \n
    Once you decline this request, you can't undo.
    You can check this request's info from incoming tab in the pettycash section on the system side bar.
    `,
          alert_text_accept_deposit_request: `The deposit request the beed sent by {{creator_name}} will be approved,
    \n
    {{creator_name}} will get notified of your response.
    `,
          alert_text_reject_deposit_request: `The deposit request the beed sent by {{creator_name}} will be declined,
    \n
    {{creator_name}} will get notified of your response.
    `,
          alert_text_reject_withdraw_request: `
    The withdraw request the been sent by {{creator_name}} will be declined.
    \n
    {{creator_name}} will get notified of your response.
    `,

          alert_text_accept_withdraw_request: `
    The withdraw request the been sent by {{creator_name}} will be approved.
    <br />
    {{creator_name}} will get notified of your response.
    `,
          alert_text_confirm_add_members: `
    The invitations will be sent to all emails you've entered, 
    \n
    Are you sure the data you've entered is correct?
    `,
          alert_text_add_safe: `
    A new safe will be added,
    \n
    Are you sure the data you've entered is correct?
    `,
          alert_text_cancel_add_safe: `
    All data you've entered will get lost, are you sure you don't want to enhance your trustbill team?
    `,
          alert_text_deactivate_safe: `
    Once the safe gets revoked, all its activities will be suspended
    \n
    You can reactivate this safe again by following the same previous steps.
    `,
          alert_text_reactivate_safe: `
    This safe will get reactivated
    \n
    Are you sure you want to reactivate it?
    `,
          alert_text_confirm_edit: `
    Your changes will get saved,
    \n
    do you want to confirm this action?
    `,

          alert_text_deactivate_member: `
    {{user_name}} will be suspended
    \n
    This member will no longer be able to access the workspace data.
    \n
    Do you want to confirm this action?
    `,
          alert_text_activate_member: `
    The member {{user_name}} will membership will get reactivated, 
    \n
    Do you want to confirm this action?
    `,
          alert_text_cancel_invitation: `
    The invitation that been sent to this member will get canceled.
    \n
    Do you want to confirm this action?
    `,
          alert_text_confirm_change_role: `
    The member {{user_name}}'s role will get changed from {{old_role}} to {{new_role}}.
    \n
    Do you want to confirm this action?
    `,
          alert_text_edit_role: `
    {{role_name}} role will get edited,
    once you confirm this action, the changes will get applied.
    \n
    Do you want to confirm this action?
    `,
          alert_text_add_role: `
    A new role will be created.
    \n
    You can check the role ant its authoroties from the rols tabe on your settings.
    `,
          alert_text_deactivate_role: `
    Once revoke this role, it will get completely suspended.
    \n
    Which means that all members that holds this rols will back to the standard rule, which is "member".
    \n
    Do you want to confirm this action? you can undo it by following the same previous steps.
    `,
          alert_text_cancel_role: `
    All data you've entered will get lost, Do you want to confirm this action?
    `,
          alert_text_deactivate_role_text: `
    Once revoke this role, it will get completely suspended.
    \n
    Which means that all members that holds this rols will back to the standard rule, which is "member".
    \n
    Do you want to confirm this action? you can undo it by following the same previous steps.
    `,
          alert_text_edit_role_text: `
    Once reactivate this role, it will back to run and support from the trustbill system.
    \n
    All this role's previous members will have to get reassigned again.
    \n
    Do you want to confirm this action? you can undo it by following the same previous steps.
    `,
          alert_text_add_document: `
    A {{count}} number of expenses will get recorded, in total amount of {{amount}} EGP.
    You can check all your expenses from the expenses data section on the system's sidebar
    `,

          alert_text_block: `
    Are you sure you want to hold this transaction?
    All traces of this transaction will be removed from the database.
    But the transaction itself will still keep its place in its transactions' table
    `,
          alert_text_confirm_close_money_request: `
    The pettycash transaction will get settled, and an amount of {{amount}} EGP will be refunded.
    \n
    Once you settle the transaction, your action cannot undo. 
    You can View the pettycash in the settled tab in the pettycash section on the system sidebar.
    `,
          alert_text_confirm_send: `
    Are you sure of the financial application data you entered?
    You can find your financial request data in the main outgoing list.
    Please note that if your request is received and responded to, you will not be able to delete it.
    `,
          alert_text_confirm_recharge: `
    Your company's balance will get charged by {{amount}} EGP.
    Once you click on the confirmation button, this amount will get charged to the company's safe.
    `,
          alert_text_withdraw_from_safe: `
    An amount by {{amount}} EGP will get withdrawn from the safe.
    `,
          no_sent_spend_requests:
            "It seems that there're no transactions on this page yet, would you like to create a new?",
          no_received_spend_requests:
            "It seems that there're no transactions on this page yet, if you received any requests, you will be notified.",
          cancel_edit_safe: "Cancel safe edit",
          confirm_edit_safe: "Confirm safe edit",
          edit_safe: "Edit safe",
          activate_safe: "Activate the safe",
          reactivate_safe: "Reactivate the safe",
          deactivate_safe: "Revoke safe",
          confirm_add_safe: "Confirm create new safe",
          cancel_add_safe: "Cancel create new safe",
          cancel_add_members: "Cancel user invitations",
          add_members: "Add new members",
          add_new_cell: "Create new safe",
          send_invitations: "Send invitations",
          confirm_reject: "Confirm declining",
          confirm_send: "Confirm sending",
          confirm_send_money_request: "Confirm send pettycash request",
          confirm_reject_money_request: "Confirm decline pettycash request",
          reject_deposit_request: "Decline deposit request",
          accept_deposit_request: "Approve deposit request",
          reject_withdraw_request: "Decline withdraw request",
          accept_withdraw_request: "Approve withdraw request",
          ask_for_money_request: "Request a pettycash",
          send: "Send",
          create: "Create",
          edit: "Edit",
          pick_money_request:
            "Select the pettycash transaction you want to spend the requested amount from.",
          money_request_balance: "The available balance on this transaction is",
          money_request_insufficient_balance:
            "The value of the requested amount exceeds the available balance on the choosen pettycash",
          accept_money_request_dialog:
            "{{user_name}}'s request for a pettycash will be approved. <br /> Did you check the request entries carefully and willing to approve it?",
          pick_safe:
            "Choose the safe through which you want to perform this transaction.",
          safe_balance: "The available value in this treasury",
          safe_insufficient_balance:
            "The value of the requested amount exceeds the available balance on the choosen safe",
          num_of_documents: "Expenses number",
          open_money_request_documents: "Open the pettycash expenses page",
          has_open_children:
            "You can't settle this transaction until all petty cash transactions under it get settled",
          money_request_no_reply: "Pending pettycash request",
          money_request_no_status:
            "The declined and unanswered pettycash request has no status, that is, it's neither processing nor settled.",
          sent_money_requests_empty_page:
            "It looks like there is no pettycash on this page yet, would you like to create a new one?",
          received_money_requests_empty_page:
            "You haven't received any pettycash request yet, if any request arrives, you will be notified.",
          helicopter_empty_page:
            "It seems that there's no data on this page yet, if any data gets recorded, it will be shown here on the helicopter overview section.",
          close_your_money_request:
            "Settle your custody and clear your conscience",
          close_your_money_request_text: `
    After you're done with the petty cash transaction amount, you can settle it with whom you've received this amount.
    All the remaining balance of this transaction will get returned to the original custodian Administrator, and you will not be able to take it back again.`,
          currently_developing: "development in progress",
          currently_developing_text:
            "This section of the system is under development. You'll be notified as soon as this section is ready.",
        },
      },
    },
  });
