import React from "react";

import CloseSvg from "../../resources/close svg/CloseSvg";

import "./InviteMembers.scss";

const InviteMembers = ({ onClose, emails, handleConfirm }) => {
  return (
    <div className="overlay" onClick={onClose}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <div className="bg-white lg:grid lg:grid-cols-12 lg:gap-x-5 max-w-screen-md invite-members-container">
          <form className="col-span-12">
            <div className="sm:rounded-md sm:overflow-hidden">
              <div className="relative text-center p-4">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  إضافة أعضاء
                </h3>
                <button
                  type="button"
                  className="absolute top-4 left-4"
                  onClick={onClose}
                >
                  <div>
                    <CloseSvg />
                  </div>
                </button>
              </div>
              <hr />

              {emails.length > 0 && (
                <>
                  <div className=" bg-gray-50">
                    <p className="text-lg font-medium text-gray-900 px-6 pt-2">
                      سيتم إرسال دعوات إضافية إالى:
                    </p>

                    <div className="flex flex-wrap px-6 pb-2">
                      {emails.map((mail) => (
                        <div
                          className="react-tag-input__tag max-w-max ml-2"
                          key={mail}
                        >
                          <div className="react-tag-input__tag__content">
                            {mail}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <hr />
                </>
              )}

              <div className="bg-gray-50 py-6 px-4 space-y-6 sm:p-6">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    تنبيه
                  </h3>
                  <ul className="list-disc font-medium text-gray-900 text-sm mt-2">
                    احرص على التأكد من عناوين البريد الإلكتروني التي أدخلتها حيث
                    أنك بهذه الخطوة ستتيح لهؤلاء الأفرا الآتي:
                    <li className="mr-12 mt-1">
                      إرسال واستقبال طلبات مالية على مساحة العمل.
                    </li>
                    <li className="mr-12 mt-1">
                      التعامل مالياً مع باقي أعضاء مساحة العمل الخاصة بك.
                    </li>
                  </ul>
                </div>
              </div>

              <hr />

              <div className="grid sm:grid-cols-8 gap-5 px-4 py-3 bg-white text-right sm:px-6">
                <button
                  type="button"
                  className="sm:col-span-2 sm:col-start-4 gap-6 bg-black py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white"
                  onClick={() => handleConfirm()}
                >
                  تأكيد
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InviteMembers;
