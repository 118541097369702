import { useState, useEffect } from "react";

import Modal from "../../common/Modal";

import FilterIcon from "../../resources/icons/Filter";
import useFetchCategory from "../../../hooks/reactQuery/useFetchCategory";
import { useForm, useWatch } from "react-hook-form";
import Shapes from "../../common/Shapes";
import Amount from "../../common/TableOperations/filter/Amount";
import CategsShapes from "../../common/TableOperations/filter/CategsShapes";
import Input from "./../../common/inputs/Input/index";
import Close from "../../resources/icons/Close";
import DocumentDate from "./../../resources/icons/DocumentDate";
import ExpenseDate from "./../../resources/icons/ExpenseDate";
import { useTranslation } from "react-i18next";

const fromAmountData = {
  originalFromAmount: null,
  finalFromAmount: null,
};
const toAmountData = {
  originalToAmount: null,
  finalToAmount: null,
};

const fromDateData = {
  originalFromDate: "",
  finalFromDate: "",
};
const toDateData = {
  originalToDate: "",
  finalToDate: "",
};

const fromPaymentDateData = {
  originalFromPaymentdate: "",
  finalFromPaymentdate: "",
};
const toPaymentDateData = {
  originalToPaymentDate: "",
  finalToPaymentDate: "",
};

const Filters = ({ activeTab, dispatchFiltrers, filtersState }) => {
  const [showFiltering, setShowFiltering] = useState(false);

  const [fromAmount, setFromAmount] = useState({
    ...fromAmountData,
  });
  const [toAmount, setToAmount] = useState({
    ...toAmountData,
  });

  const {t, i18n} = useTranslation();


  const periodicData = [
    { name: t("periodic"), key: 1, selected: false, finalSelected: false },
    { name: t("rare"), key: 0, selected: false, finalSelected: false },
  ];
  

  useEffect(() => {
    setFromAmount({ ...fromAmountData });
    setToAmount({ ...toAmountData });
  }, [activeTab]);

  const { data: categoriesData, isLoading: iscategoriesLoading } =
    useFetchCategory("customCategory", showFiltering, Infinity);

  const [categories, setCategories] = useState([]);
  useEffect(() => {
    setCategories(
      categoriesData?.map((category) => {
        category.key = category.id;
        category.isShown = true;
        category.selected = false;
        category.finalSelected = false;
        return category;
      })
    );
  }, [categoriesData, activeTab]);

  const [periodic, setPeriodic] = useState([...periodicData]);

  useEffect(() => {
    setPeriodic([...periodicData]);
  }, [activeTab]);

  const [fromDate, setFromDate] = useState({
    ...fromDateData,
  });
  const [toDate, setToDate] = useState({
    ...toDateData,
  });

  const [fromPaymentDate, setFromPaymentDate] = useState({
    ...fromPaymentDateData,
  });
  const [toPaymentDate, setToPaymentDate] = useState({
    ...toPaymentDateData,
  });

  useEffect(() => {
    setFromDate({ ...fromDateData });
    setToDate({ ...toDateData });
    setFromPaymentDate({ ...fromPaymentDateData });
    setToPaymentDate({ ...toPaymentDateData });
  }, [activeTab]);

  let hasFilter =
    filtersState.categories.length > 0 ||
    filtersState.periodic.length > 0 ||
    filtersState.fromAmount ||
    filtersState.toAmount ||
    filtersState.fromDate ||
    filtersState.toDate ||
    filtersState.fromPaymentDate ||
    filtersState.toPaymentDate;

  return (
    <>
      <div className="relative inline-block text-left h-full">
        <button
          className={`h-10 closing-table-btn transition-all duration-300 border border-transparent hover:border-secondary-hover flex items-center justify-center rounded-md ${
            hasFilter ? "border-secondary-hover closing-table-btn-w" : ""
          }`}
          onClick={() => setShowFiltering(true)}
        >
          <span className="absolute left-8 w-max">{t("filteration")}</span>
          <div className="absolute left-1 closing-table">
            <FilterIcon strokeColor={hasFilter ? "#1D64D8" : ""} />
          </div>
        </button>
      </div>

      <Modal showModal={showFiltering} onClose={() => setShowFiltering(false)}>
        <FiltersModal
          onClose={() => setShowFiltering(false)}
          iscategoriesLoading={iscategoriesLoading}
          categories={categories}
          setCategories={setCategories}
          periodic={periodic}
          setPeriodic={setPeriodic}
          fromAmount={fromAmount}
          setFromAmount={setFromAmount}
          toAmount={toAmount}
          setToAmount={setToAmount}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          fromPaymentDate={fromPaymentDate}
          setFromPaymentDate={setFromPaymentDate}
          toPaymentDate={toPaymentDate}
          setToPaymentDate={setToPaymentDate}
          dispatchFiltrers={dispatchFiltrers}
        />
      </Modal>
    </>
  );
};

export default Filters;

//
//
//
//
//
//
//

const FiltersModal = ({
  onClose,
  iscategoriesLoading,
  categories,
  setCategories,
  periodic,
  setPeriodic,
  fromAmount,
  setFromAmount,
  toAmount,
  setToAmount,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  fromPaymentDate,
  setFromPaymentDate,
  toPaymentDate,
  setToPaymentDate,
  dispatchFiltrers,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isSaved, setSaved] = useState(false);

  const { register, control, getValues } = useForm({
    defaultValues: {
      fromAmount: fromAmount.originalFromAmount,
      toAmount: toAmount.originalToAmount,
      fromDate: fromDate.originalFromDate,
      toDate: toDate.originalToDate,
      fromPaymentDate: fromPaymentDate.originalFromPaymentDate,
      toPaymentDate: toPaymentDate.originalToPaymentDate,
    },
  });

  const {t, i18n} = useTranslation();


  const tabs = [
    {
      name: t("amount"),
      selectedCount:
        (useWatch({ control, name: "fromAmount" }) ? 1 : 0) +
        (useWatch({ control, name: "toAmount" }) ? 1 : 0),
    },
    {
      name: t("user_name"),
      selectedCount: 0,
      disabled: true,
    },
    {
      name: t("category_name"),
      selectedCount: categories?.filter((user) => user.selected).length,
    },
    {
      name: t("status"),
      selectedCount: periodic?.filter((status) => status.selected).length,
    },
    {
      name: t("date"),
      selectedCount:
        (useWatch({ control, name: "fromDate" }) ? 1 : 0) +
        (useWatch({ control, name: "toDate" }) ? 1 : 0) +
        (useWatch({ control, name: "fromPaymentDate" }) ? 1 : 0) +
        (useWatch({ control, name: "toPaymentDate" }) ? 1 : 0),
    },
  ];

  const dates = [
    {
      name: "fromDate",
      defaultValue: fromDate.originalFromDate,
      fieldName: t("from"),
      label: t("from"),
      hint: t("from_date_hint"),
      rowLabel: t("documentation_date"),
      RowIcon: DocumentDate,
    },
    {
      name: "toDate",
      defaultValue: toDate.originalToDate,
      fieldName: t("to"),
      label: t("to"),
      hint: t("to_date_hint"),
    },

    {
      name: "fromPaymentDate",
      defaultValue: fromPaymentDate.originalFromPaymentDate,
      fieldName: t("from"),
      label: t("from"),
      hint: t("from_date_hint"),
      rowLabel: t("spend_date"),
      RowIcon: ExpenseDate,
    },
    {
      name: "toPaymentDate",
      defaultValue: toPaymentDate.originalToPaymentDate,
      fieldName: t("to"),
      label: t("to"),
      hint: t("to_date_hint"),
    },
  ];

  useEffect(() => {
    if (isSaved) {
      setCategories((prevCategs) =>
        prevCategs?.map((category) => {
          category.finalSelected = category.selected;
          return category;
        })
      );
      setPeriodic((prevResponses) =>
        prevResponses.map((response) => {
          response.finalSelected = response.selected;
          return response;
        })
      );

      setFromAmount({
        originalFromAmount: getValues("fromAmount"),
        finalFromAmount: getValues("fromAmount"),
      });
      setToAmount({
        originalToAmount: getValues("toAmount"),
        finalToAmount: getValues("toAmount"),
      });

      setFromDate({
        originalFromDate: getValues("fromDate"),
        finalFromDate: getValues("fromDate"),
      });
      setToDate({
        originalToDate: getValues("toDate"),
        finalToDate: getValues("toDate"),
      });

      setFromPaymentDate({
        originalFromPaymentDate: getValues("fromPaymentDate"),
        finalFromPaymentDate: getValues("fromPaymentDate"),
      });
      setToPaymentDate({
        originalToPaymentDate: getValues("toPaymentDate"),
        finalToPaymentDate: getValues("toPaymentDate"),
      });

      dispatchFiltrers({
        // type: "CHANGESTATE",
        payload: {
          categories: categories
            ?.filter((category) => category.selected)
            .map((category) => category.id),
          periodic: periodic
            ?.filter((status) => status.selected)
            .map((status) => status.key),
          fromAmount: getValues("fromAmount"),
          toAmount: getValues("toAmount"),

          fromDate: getValues("fromDate"),
          toDate: getValues("toDate"),
          fromPaymentDate: getValues("fromPaymentDate"),
          toPaymentDate: getValues("toPaymentDate"),
        },
      });
      onClose();
    } else {
      setCategories((prevCategs) =>
        prevCategs?.map((category) => {
          category.selected = category.finalSelected;
          return category;
        })
      );
      setPeriodic((prevResponses) =>
        prevResponses.map((response) => {
          response.selected = response.finalSelected;
          return response;
        })
      );
      setFromAmount((prevFromAmount) => ({
        ...prevFromAmount,
        originalFromAmount: prevFromAmount.finalFromAmount,
      }));
      setToAmount((prevToAmount) => ({
        ...prevToAmount,
        originalToAmount: prevToAmount.finalToAmount,
      }));

      setFromDate((prevDate) => ({
        ...prevDate,
        originalFromDate: prevDate.finalFromDate,
      }));
      setToDate((prevDate) => ({
        ...prevDate,
        originalToDate: prevDate.finalToDate,
      }));

      setFromPaymentDate((prevDate) => ({
        ...prevDate,
        originalFromPaymentDate: prevDate.finalFromPaymentDate,
      }));
      setToPaymentDate((prevDate) => ({
        ...prevDate,
        originalToPaymentDate: prevDate.finalToPaymentDate,
      }));
    }
  }, [isSaved]);

  const periodicShapesOption = (periodic) => (
    <div className="p-2">{periodic.name}</div>
  );

  return (
    <div className="overlay overlay--side" onClick={() => onClose()}>
      <div className="modal modal--side" onClick={(e) => e.stopPropagation()}>
        <div className="py-5 px-6 flex justify-between items-center border-b border-separator">
          <button
            className="bg-secondary text-sm font-medium text-white rounded-md py-1 px-8"
            onClick={() => {
              setSaved(true);
            }}
          >
            {t("save")}
          </button>
          <div className="flex items-center gap-x-2">
            <div>
              <FilterIcon />
            </div>
            <div className="text-base leading-5 font-bold text-body">{t("filteration")}</div>
          </div>
          <button onClick={onClose}>
            <Close />
          </button>
        </div>

        <div className="flex justify-center items-center border-b border-separator gap-x-10 text-sm font-medium text-hints" dir={i18n.resolvedLanguage === "en" ? "ltr" : "rtl"}>
          {tabs.map((tab, index) => (
            <button
              className={`py-4 relative top-px flex items-center gap-x-2 border-b-2 border-transparent ${
                activeTab === index ? "border-secondary text-secondary" : ""
              } ${tab.disabled ? "cursor-not-allowed opacity-40" : ""}`}
              onClick={() => setActiveTab(index)}
              disabled={tab.disabled}
            >
              <span>{tab.name}</span>
              {!tab.disabled && (
                <span
                  className={`text-xs font-medium rounded-xl px-px w-6 flex justify-center items-center transition-all duration-300 ${
                    activeTab === index
                      ? "text-secondary bg-secondary bg-opacity-40"
                      : "text-hints bg-separator"
                  }`}
                >
                  {tab.selectedCount}
                </span>
              )}
            </button>
          ))}
        </div>

        {activeTab === 0 && (
          <Amount
            register={register}
            fromAmount={fromAmount}
            toAmount={toAmount}
          />
        )}

        {activeTab === 2 && (
          <CategsShapes
            iscategoriesLoading={iscategoriesLoading}
            categories={categories}
            setCategories={setCategories}
          />
        )}

        {activeTab === 3 && (
          <div className="pt-10 px-6 flex flex-col gap-y-2.5" dir={i18n.resolvedLanguage === "en" ? "ltr" : "rtl"}>
            <div className="text-xs leading-5 font-medium text-body">
              {t("response_status")}
            </div>
            <div>
              <Shapes
                shapes={periodic}
                setShapes={setPeriodic}
                formatShapesOption={periodicShapesOption}
              />
            </div>
          </div>
        )}

        <div className="grid grid-cols-5 gap-x-4 px-4 pb-10" dir={i18n.resolvedLanguage === "en" ? "ltr" : "rtl"}>
          {activeTab === 4 &&
            dates.map((date, index) => (
              <>
                {index % 2 === 0 && (
                  <div className="col-span-5 px-6 pt-6 flex items-center gap-x-2.5">
                    <div>
                      <date.RowIcon />
                    </div>
                    <span>{date.rowLabel}</span>
                  </div>
                )}
                <div className="col-span-2 pr-6 pt-4 pb-6">
                  <Input
                    register={register}
                    type="date"
                    name={date.name}
                    fieldName={date.fieldName}
                    label={date.label}
                    hint={date.hint}
                    defaultValue={date.defaultValue}
                    required={false}
                  />
                </div>
                {index % 2 !== 0 && index !== dates.length - 1 && (
                  <hr className="col-start-1 col-span-5" />
                )}
              </>
            ))}
        </div>
      </div>
    </div>
  );
};
