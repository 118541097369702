import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchSpecializations(t, i18n) {
  return useQuery("specializations", () =>
    apiClient?.get("request_for_access/loadspecialization").then(({ data }) =>
      data.map((specialization) => {
        return {
          ...specialization,
          label: i18n.resolvedLanguage === "en" ? specialization.en_name : specialization.name,
          value: specialization.id,
        };
      })
    )
  );
}
