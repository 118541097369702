import { useTranslation } from "react-i18next";

export default function HeaderSectionData(props) {
    const {t, i18n} = useTranslation();

    return (
    <div className="safes-header-section-info" dir="rtl">
        <span style={{fontSize: "12px"}}>{props.title}</span>
        <span className="safes-header-section-balance">{props.balance && props.balance}</span>
        <div className="flex justify-between align-center" style={{minWidth: "300px", height: "34px"}}>
        <div className="flex safes-header-small-text">
            <span style={{margin: "0px 5px"}} title={t("monthly_average")}>{t("average_monthly")}</span>
            <span className="flex align-center justify-center mx-1">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.05546 10.5465L8.20879 10.5465L11.9488 10.5465C12.5888 10.5465 12.9088 9.77318 12.4555 9.31984L9.00212 5.86651C8.44879 5.31318 7.54879 5.31318 6.99545 5.86651L5.68212 7.17984L3.54212 9.31984C3.09545 9.77318 3.41545 10.5465 4.05546 10.5465Z" fill="#42EBB3"/>
            </svg>
            <span>{props.cashin ? props.cashin : 0.00}</span>
            </span>
            <span className="flex align-center justify-center mx-1">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.54629 6.67989L5.68629 8.81988L6.99296 10.1332C7.54629 10.6866 8.44629 10.6866 8.99963 10.1332L12.453 6.67988C12.9063 6.22655 12.5796 5.45322 11.9463 5.45322L8.20629 5.45322L4.05296 5.45322C3.41296 5.45322 3.09296 6.22655 3.54629 6.67989Z" fill="#FF805C"/>
            </svg>
            <span>{props.expenses ? props.expenses : 0.00}</span>
            </span>
        </div>
        <span style={{fontWeight: 500,fontSize: "16px", color: "#94A3B8", marginRight: "20px"}} title={t("current_money_requests")}>{`${props.moneyRequests && props.moneyRequests} ${props.moneyRequests == 0 ? "EGP" : ""}`}</span>
        </div>
    </div>
    )
};