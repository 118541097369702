import SkeletonImage from 'antd/lib/skeleton/Image';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { formatDate } from '../../../../utils/convertDate';
import "../dashboard.scss";
import { useTranslation } from "react-i18next";

export default function LastUpdatesRow({data}) {
  const history = useHistory();
  const {t, i18n} = useTranslation();

  const generateImgUrl = (data, type) => {
    let img = "";
    if(type === "CREATOR")
      img = (process.env.REACT_APP_UPLOAD_FILES_BASE_URL
        ? process.env.REACT_APP_UPLOAD_FILES_BASE_URL
        : "") +
      (process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
        ? process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
        : "") +
      `users/${data.creator_id}/profilepicture` +
      `.${data.creator_profile_picture_ext}`;
    else if (type === "AFFECTED")
      img = (process.env.REACT_APP_UPLOAD_FILES_BASE_URL
        ? process.env.REACT_APP_UPLOAD_FILES_BASE_URL
        : "") +
      (process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
        ? process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
        : "") +
      `users/${data.affected_id}/profilepicture` +
      `.${data.affected_profile_picture_ext}`;
    return img;
  }

  const buildTypes = (data) => [
    {
      type: "CREATE_SAFE",
      title: t("last_updates_type_CREATE_SAFE"),
      img: generateImgUrl(data, "CREATOR"),
      buildString: (data) => {
        return t("last_updates_type_CREATE_SAFE_TEXT", {user_name: data.creator_name, safe_name: data.safe_name});
        //return(`تم انشاء خزينة جديدة (${data.safe_name}) بواسطة ${data.creator_name}`);
      },
      click: () => {
        history.push({
          pathname: `/safe/${data.safe_id}`,
        });
      }
    },
    {
      type: "EDIT_SAFE",
      title: t("last_updates_type_EDIT_SAFE"),
      img: generateImgUrl(data, "CREATOR"),
      buildString: (data) => {
        return t("last_updates_type_EDIT_SAFE_TEXT", {user_name: data.creator_name, safe_name: data.safe_name});
        //return(`${data.creator_name} اجرى تعديلا اداريا على خزينة ${data.safe_name}`);
      },
      click: () => {
        history.push({
          pathname: `/safe/${data.safe_id}`,
        });
      }
    },
    {
      type: "ADD_SAFE_MEMBER",
      title: t("last_updates_type_ADD_SAFE_MEMBER"),
      img: generateImgUrl(data, "AFFECTED"),
      buildString: (data) => {
        return t("last_updates_type_ADD_SAFE_MEMBER_TEXT", {user_name: data.affected_name, safe_name: data.safe_name});
        //return (`${data.affected_name} عضو جديد بخزينة ${data.safe_name}`);
      },
      click: () => {
        history.push({
          pathname: `/safe/${data.safe_id}`,
        });
      }
    },
    {
      type: "CASHOUT_WITHOUT_PERMISSION",
      title: t("last_updates_type_CASHOUT_WITHOUT_PERMISSION"),
      img: generateImgUrl(data, "CREATOR"),
      buildString: (data) => {
        const amount = new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.money_request_amount);
        return t("last_updates_type_CASHOUT_WITHOUT_PERMISSION_TEXT", {
          user_name: data.creator_name, 
          amount,
          safe_name: data.safe_name
        });
        //return(`${data.creator_name} اجرى عملية سحب مالي بقيمة ${amount} من خزينة ${data.safe_name}`);
      },
      click: () => {
        history.push({
          pathname: `/safes/${data.safe_id}/money_request/${data.money_request_id}`,
          state: { comingFrom: t("last_updates"), fallback: "/safe", title: t("cashout_without_permission")}
        });
      }
    },
    {
      type: "ACCEPT_CASHOUT",
      title: t("last_updates_type_ACCEPT_CASHOUT"),
      img: generateImgUrl(data, "AFFECTED"),
      buildString: (data) => {
        const amount = new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.money_request_amount);
        return t("last_updates_type_ACCEPT_CASHOUT_TEXT", {
          affected_name: data.affected_name, 
          amount,
          safe_name: data.safe_name
        });
        //return(`${data.affected_name} اجرى عملية سحب مالي بقيمة ${amount} من خزينة ${data.safe_name}`);
      },
      click: () => {
        history.push({
          pathname: `/safes/${data.safe_id}/money_request/${data.money_request_id}`,
          state: { comingFrom: t("last_updates"), fallback: "/safe", title: t("cashout_with_permission")}
        });
      }
    },
    {
      type: "CASHIN_WITHOUT_PERMISSION",
      title: t("last_updates_type_CASHIN_WITHOUT_PERMISSION"),
      img: generateImgUrl(data, "CREATOR"),
      buildString: (data) => {
        const amount = new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.cashin_amount);
        return t("last_updates_type_CASHIN_WITHOUT_PERMISSION_TEXT", {
          user_name: data.creator_name, 
          amount,
          safe_name: data.safe_name
        });
        //return(`${data.creator_name} اجرى عملية ايداع مالي بقيمة ${amount} من خزينة ${data.safe_name}`);
      },
      click: () => {
        history.push({
          pathname: `/safes/${data.safe_id}/cashin/${data.cashin_id}`,
          state: { comingFrom: t("last_updates"), fallback: "/safe", title: t("cashin_without_permission")}
        });
      }
    },
    {
      type: "ACCEPT_CASHIN",
      title: t("last_updates_type_ACCEPT_CASHIN"),
      img: generateImgUrl(data, "AFFECTED"),
      buildString: (data) => {
        const amount = new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.cashin_amount);
        return t("last_updates_type_ACCEPT_CASHIN_TEXT", {
          user_name: data.affected_name, 
          amount,
          safe_name: data.safe_name
        });
        //return(`${data.affected_name} اجرى عملية ايداع مالي بقيمة ${amount} من خزينة ${data.safe_name}`);
      },
      click: () => {
        history.push({
          pathname: `/safes/${data.safe_id}/cashin/${data.cashin_id}`,
          state: { comingFrom: t("last_updates"), fallback: "/safe", title: t("cashin_with_permission")}
        });
      }
    },
    {
      type: "DOCUMENT_FROM_SAFE",
      title: t("last_updates_type_DOCUMENT_FROM_SAFE"),
      img: generateImgUrl(data, "CREATOR"),
      buildString: (data) => {
        const amount = new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.document_amount);
        return t("last_updates_type_DOCUMENT_FROM_SAFE_TEXT", {
          user_name: data.creator_name, 
          amount,
          safe_name: data.safe_name
        });
        //return(`${data.creator_name} اجرى تدوين مصروف مالي بقيمة ${amount} من خزينة ${data.safe_name}`);
      },
      click: () => {
        history.push({
          pathname: `/safes/${data.safe_id}/document/${data.document_id}`,
          state: { comingFrom: t("last_updates"), fallback: "/safe", title: t("document")}
        });
      }
    },
    {
      type: "ACCEPT_MONEY_REQUEST",
      title: t("last_updates_type_ACCEPT_MONEY_REQUEST"),
      img: generateImgUrl(data, "AFFECTED"),
      buildString: (data) => {
        const amount = new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.money_request_amount);
        return t("last_updates_type_ACCEPT_MONEY_REQUEST_TEXT", {
          user_name: data.affected_name, 
          amount,
          safe_name: data.safe_name
        });
        //return(`${data.affected_name} صرف عهدة مالية بقيمة ${amount} من خزينة ${data.safe_name}`);
      },
      click: () => {
        history.push({
          pathname: `/safes/${data.safe_id}/money_request/${data.money_request_id}`,
          state: { comingFrom: t("last_updates"), fallback: "/safe", title: t("money_request")}
        });
      }
    },
    {
      type: "ACCEPT_MONEY_REQUEST_FROM_SAFE",
      title: t("last_updates_type_ACCEPT_MONEY_REQUEST_FROM_SAFE"),
      img: generateImgUrl(data, "AFFECTED"),
      buildString: (data) => {
        const amount = new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.money_request_amount);
        return t("last_updates_type_ACCEPT_MONEY_REQUEST_FROM_SAFE_TEXT", {
          user_name: data.affected_name, 
          amount,
          category_name: data.category_name
        });
        //return(`${data.affected_name} صرف عهدة مالية بقيمة ${amount} تحت تصنيف ${data.category_name}`);
      },
      click: () => {
        history.push({
          pathname: `/safes/${data.safe_id}/money_request/${data.money_request_id}`,
          state: { comingFrom: t("last_updates"), fallback: "/safe", title: t("money_request")}
        });
      }
    },
    {
      type: "SETTLED_MONEY_REQUEST",
      title: t("last_updates_types_SETTLED_MONEY_REQUEST"),
      img: generateImgUrl(data, "CREATOR"),
      buildString: (data) => {
        const amount = new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(data.money_request_amount);
        return t("last_updates_types_SETTLED_MONEY_REQUEST_TEXT", {
          user_name: data.creator_name, 
          amount,
          category_name: data.category_name
        });
        //return(`${data.creator_name} قام بتصفية عهدة مالية بقيمة ${amount} تحت تصنيف ${data.category_name}`);
      },
      click: () => {
        history.push({
          pathname: `/safes/${data.safe_id}/money_request/${data.money_request_id}`,
          state: { comingFrom: t("last_updates"), fallback: "/safe", title: t("money_request")}
        });
      }
    }
  ];

  const [types, setTypes] = useState(buildTypes(data));
  const [currentType, setCurrentType] = useState(null);
  const [title, setTitle] = useState("");
  const [img, setImg] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState(formatDate(data.created_at));
  const [noImg, setNoImg] = useState(false);

  useEffect(() => {
    if(data) {
      const ctype = types.find(type => type.type === data.type);
      console.log("current type", ctype);
      console.log("data", data);
      if(ctype) {
        setTypes(buildTypes(data));
        setCurrentType(ctype);
      }
    }
  }, [data]);

  useEffect(() => {
    if(currentType) {
      setTitle(currentType.title);
      setImg(currentType.img);
      setDescription(currentType.buildString(data)); 
      setDate(formatDate(data.created_at));
    }
  }, [currentType]);

  return (
    currentType ? <div className='last-updates-row' onClick={() => currentType.click()}>
      <div className='last-updates-row-data'>
        {noImg ? 
          <div className='user-circle'>{data.creator_name ? data.creator_name.charAt(0).toUpperCase() : data.recipient_name.charAt(0).toUpperCase()}</div>
        :<img 
          className='last-updates-row-img' 
          src={img} 
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; 
            setNoImg(true);
          }}
        />}
        <div className='last-updates-row-text'>
          <span className='last-updates-row-title'>{title}</span>
          <span className='last-updates-row-description' title={description}>{description.length > 50 ? description.slice(0, 50) + "..." : description}</span>
        </div>
      </div>
      <span className='last-updates-row-date'>{date}</span>
    </div> : null
  );
}