import { useEffect } from "react";

import { useWatch, Controller, useFormContext } from "react-hook-form";
import useCollapse from "react-collapsed";

import Input from "../../common/inputs/Input";
import TextArea from "../../common/inputs/Textarea";
import SelectMenu from "../../common/inputs/Select";
import UploadInput from "../../common/inputs/Upload";
import SelectUser from "../../resources/icons/SelectUser";
import DataBase from "./../../resources/icons/Database";
import ChevronArrow from "../../resources/icons/ChevronArrow";
import Trash from "../../resources/icons/Trash";
import SubTitle from "../../resources/icons/Subtitle";
import Cash from "../../resources/icons/Cash";
import Timer from "../../resources/icons/Timer";

import useFetchUserData from "../../../hooks/reactQuery/useFetchUserData";
import useFetchUsersSendSpendRequest from "../../../hooks/reactQuery/useFetchUsers";
import useFetchCategory from "../../../hooks/reactQuery/useFetchCategory";
import useMutateCategory from "../../../hooks/reactQuery/useMutateCategory";
import useFetchLatestCategories from "./../../../hooks/reactQuery/useFetchLatestCategories";
import useFetchLatestRecipients from "./../../../hooks/reactQuery/useFetchLatestRecipients";

import {
  categoriesLatestOptionsMarkup,
  usersFormatOptionLabel,
  usersLatestOptionsMarkup,
} from "../../../utils/markUp";

import "./PaymentLicenseForm.scss";
import { useTranslation } from "react-i18next";
const Form = ({
  fields,
  index,
  field,
  removeFieldSet,
  trackTotalValue,
  activeFieldArr,
  setActiveFieldArr,
  isExpanded,
  setExpanded,
}) => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();

  const {
    register,
    formState: { errors },
    trigger,
    getValues,
    setValue,
    control,
  } = useFormContext();

  const {
    data: users,
    isLoading: isUsersLoading,
    isError: isUsersError,
  } = useFetchUsersSendSpendRequest(userData.id, [
    "AcceptOrRejectSpendRequest",
    "ForwardPurchaseRequest",
  ]);

  const {
    data: categories,
    isLoading: iscategoriesLoading,
    isError: isCategoriesError,
  } = useFetchCategory();

  const { data: latestCategories, isLoading: isLatestCategoriesLoading } =
    useFetchLatestCategories();

  const { data: latestUsers } = useFetchLatestRecipients();

  const watchAmount = useWatch({
    control,
    name: `paymentForm[${index}].amount`,
  });

  const watchAttachments = useWatch({
    control,
    name: `paymentForm[${index}].attachments`,
  });

  const calcTotalValue = () => {
    return fields
      .map((field, i) => {
        return +getValues(`paymentForm[${i}].amount`);
      })
      .reduce((accum, current) => {
        return (accum ? +accum : 0) + (current ? +current : 0);
      });
  };

  useEffect(() => {
    trackTotalValue(calcTotalValue());
  }, [watchAmount, fields.length]);

  const newCategoryOptionMutation = useMutateCategory(
    userData.id,
    userData.activeCompanyId
  );

  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded: isExpanded && activeFieldArr === field.id,
    duration: 700,
  });

  return (
    <div key={field.id} className="flex w-full mt-4">
      <fieldset className="w-full">
        <div className="border border-muted-light rounded">
          <div
            className={`relative flex items-center justify-between gap-x-20 px-6 py-3 transition-all duration-700 ${
              isExpanded && activeFieldArr === field.id
                ? "border-b"
                : "bg-sidebar-background border-transparent"
            }`}
            {...getToggleProps({
              onClick: () => {
                setActiveFieldArr(field.id);
                setExpanded((prevExpanded) => !prevExpanded);
              },
            })}
          >
            <div
              className={`bg-white p-0.5 rounded-full transition-all duration-700 transform ${
                isExpanded && activeFieldArr === field.id
                  ? "rotate-90"
                  : "rotate-180"
              }`}
            >
              <ChevronArrow />
            </div>

            <div
              className={`w-full hidden xl:grid grid-cols-4 gap-x-4 opacity-0 transition-all duration-700 ${
                (!isExpanded || !activeFieldArr === field.id) && "opacity-100"
              }`}
            >
              <div className="flex flex-wrap justify-center items-center gap-x-2">
                <div>
                  <SubTitle />
                </div>
                <span className="text-right w-32 h-6 overflow-hidden">
                  {getValues(`paymentForm[${index}].name`)}
                </span>
              </div>
              <div className="flex flex-wrap justify-center items-center gap-x-2 sm:h-6 overflow-hidden">
                <div>
                  <Cash />
                </div>
                <span className="text-right w-32 h-6 overflow-hidden">
                  {getValues(`paymentForm[${index}].amount`)}
                </span>
              </div>
              <div className="flex flex-wrap justify-center items-center gap-x-2 sm:h-6 overflow-hidden">
                <div className="flex items-start gap-x-2">
                  {!getValues(`paymentForm[${index}].recipient_id`)
                    ?.profile_picture_ext ? (
                    <span className="h-5 w-5 rounded-full bg-onhold flex justify-center items-center">
                      {
                        getValues(`paymentForm[${index}].recipient_id`)
                          ?.label[0]
                      }
                    </span>
                  ) : (
                    <div>
                      <img
                        className="h-5 w-5 rounded-full"
                        src={
                          getValues(`paymentForm[${index}].recipient_id`)?.url
                        }
                        alt="selected user"
                      />
                    </div>
                  )}

                  <span className="text-right w-32 h-6 overflow-hidden">
                    {getValues(`paymentForm[${index}].recipient_id`)?.label}
                  </span>
                </div>
              </div>
              <div className="flex flex-wrap justify-center items-center gap-x-2 sm:h-6 overflow-hidden">
                <div>
                  <Timer />
                </div>
                <span className="text-right w-32 h-6 overflow-hidden">
                  {getValues(`paymentForm[${index}].request_date`)}
                </span>
              </div>
            </div>

            <button
              type="button"
              className={fields.length === 1 ? "cursor-not-allowed" : ""}
              onClick={(e) => {
                e.stopPropagation();
                removeFieldSet(index);
              }}
              disabled={fields.length === 1}
            >
              <Trash fillColor={fields.length === 1 && "gray"} />
            </button>
          </div>

          <div {...getCollapseProps()}>
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-12 py-5 px-6">
              <div className="custom-grid-1 lg:col-span-9">
                <div>
                  <Input
                    register={register}
                    defaultValue={field.name}
                    type="text"
                    name={`paymentForm[${index}].name`}
                    fieldName={t("request_name")}
                    label={t("request_name")}
                    hint={t("spend_request_purpose")}
                    required={true}
                    maxLength="50"
                    errors={
                      errors?.paymentForm && errors?.paymentForm[index]?.name
                    }
                  />
                </div>

                <div>
                  <Input
                    register={register}
                    defaultValue={field.amount}
                    type="number"
                    name={`paymentForm[${index}].amount`}
                    fieldName={t("amount")}
                    label={t("amount")}
                    hint={t("spent_amount_hint")}
                    required={true}
                    min="1"
                    max="10000000"
                    errors={
                      errors?.paymentForm && errors?.paymentForm[index]?.amount
                    }
                  />
                </div>
              </div>

              <div className="lg:col-span-3 row-span-4 mb-6">
                <UploadInput
                  defaultValue={field.attachments}
                  multiple={true}
                  accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf, image/*"
                  name={`paymentForm[${index}].attachments`}
                  id={`paymentForm[${index}].attachments`}
                  showUplodedFilesNames={true}
                  getValues={getValues}
                  fileSize="5000000"
                  errors={
                    errors?.paymentForm &&
                    errors?.paymentForm[index]?.attachments
                  }
                  Controller={Controller}
                  control={control}
                  setValue={setValue}
                  trigger={trigger}
                />
              </div>

              <div className="lg:col-span-9">
                <SelectMenu
                  name={`paymentForm[${index}].recipient_id`}
                  defaultValue={field.recipient_id}
                  fieldName={t("recipient")}
                  label={t("recipient")}
                  hint={t("recipient_hint")}
                  Icon={SelectUser}
                  latestOptions={latestUsers}
                  LatestOptionsMarkup={usersLatestOptionsMarkup(
                    latestUsers,
                    getValues,
                    setValue,
                    trigger,
                    `paymentForm[${index}].recipient_id`,
                    "600px"
                  )}
                  options={users}
                  isLoading={isUsersLoading}
                  isError={isUsersError}
                  Controller={Controller}
                  control={control}
                  hasValue={getValues(`paymentForm[${index}].recipient_id`)}
                  setValue={setValue}
                  trigger={trigger}
                  required={true}
                  errors={
                    errors?.paymentForm &&
                    errors?.paymentForm[index]?.recipient_id
                  }
                  formatOptionLabel={usersFormatOptionLabel}
                />
              </div>

              <div className="custom-grid-1 lg:col-span-9">
                <div>
                  <SelectMenu
                    name={`paymentForm[${index}].categoryid`}
                    defaultValue={field.categoryid}
                    fieldName={t("category")}
                    label={t("category")}
                    hint={t("category_hint")}
                    Icon={DataBase}
                    latestOptions={latestCategories}
                    LatestOptionsMarkup={categoriesLatestOptionsMarkup(
                      latestCategories,
                      getValues,
                      setValue,
                      trigger,
                      `paymentForm[${index}].categoryid`,
                      "350px"
                    )}
                    options={categories}
                    isLoading={iscategoriesLoading}
                    isError={isCategoriesError}
                    newOptionMutation={newCategoryOptionMutation}
                    isCreatable={true}
                    Controller={Controller}
                    control={control}
                    hasValue={getValues(`paymentForm[${index}].categoryid`)}
                    setValue={setValue}
                    trigger={trigger}
                    required={true}
                    errors={
                      errors?.paymentForm &&
                      errors?.paymentForm[index]?.categoryid
                    }
                  />
                </div>

                <div>
                  <Input
                    register={register}
                    defaultValue={field.request_date}
                    type="date"
                    name={`paymentForm[${index}].request_date`}
                    fieldName={t("response_date")}
                    label={t("response_date")}
                    hint={t("response_date_hint")}
                    minToday
                    required={true}
                    errors={
                      errors?.paymentForm &&
                      errors?.paymentForm[index]?.request_date
                    }
                  />
                </div>
              </div>

              <div className="lg:col-span-9">
                <TextArea
                  register={register}
                  defaultValue={field.notes}
                  name={`paymentForm[${index}].notes`}
                  fieldName={t("notes")}
                  label={t("notes")}
                  hint={t("notes_hint")}
                  required={false}
                  maxLength="2000"
                  errors={
                    errors?.paymentForm && errors?.paymentForm[index]?.notes
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default Form;
