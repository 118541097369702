import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useMutateMoneyRequestUpdateChecked(userId) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ request }) => {
      if (request.recipient_id === userId) {
        let data = new FormData();
        data.append("id", request.id);

        // is_request 0 requester have taken action
        // is_request 1 reciever shown it
        // is_request 2 reciever shown that it's now closed
        data.append("is_request", request.closing_date ? 2 : 1);
        return apiClient.post(`money_request/${userId}/updatechecked`, data);
      } else {
        if (request.approved !== null) {
          let data = new FormData();
          data.append("id", request.id);
          data.append("is_request", 0);
          return apiClient.post(`money_request/${userId}/updatechecked`, data);
        }
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getMoneyRequestsData");
      },
      onError: (error) => {
        return error;
      },
    }
  );
}
