import { useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useMutateUserRole(activeCompanyId, currentPage) {
  const queryClint = useQueryClient();

  return useMutation(
    ({ id: userId, role }) => {
      let data = new FormData();
      data.append("id", userId);
      data.append("role", role);
      data.append("company_id", activeCompanyId);
      return apiClient.post("user/updateuserrole", data);
    },
    {
      // When mutate is called:
      onSuccess: () => {
        toast.info("تم تعديل الأدوار.", { autoClose: 3000 });
        queryClint.invalidateQueries("team");
      },
      onError: (error) => error,
    }
  );
}
