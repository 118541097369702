import { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import Modal from "../../common/Modal";
import Spinner from "../../common/Spinner";
import LockSvg from "../../resources/lock svg/LockSvg";
import BlueDotSvg from "../../resources/blue dot svg/BlueDotSvg";
import DocumentExpenseSvg from "../../resources/document expense svg/DocumentExpenseSvg";
import Respond from "../Respond";
import CloseAndDetails from "../CloseAndDetails";
import InsufficientFunds from "../InsufficientFunds";

import useFetchUserData from "../../../hooks/reactQuery/useFetchUserData";
import useFetchFinLength from "../../../hooks/reactQuery/useFetchFinLength";
import useFetchDocsCount from "../../../hooks/reactQuery/useFetchDocsCount";
import useFetchUserBalance from "../../../hooks/reactQuery/useFetchUserBalance";
import useFetchFinancials from "../../../hooks/reactQuery/useFetchFinancials";

import { convertDate, formatDate } from "../../../utils/convertDate";
import { getAttachments } from "../../../utils/getAttachments";

import folders from "../../../constants/static_folders";
import Alert from "../../common/Alert";
import SelectMenu from "./../../common/inputs/Select/index";
import { useForm, Controller } from "react-hook-form";
import AcceptMoneyReq from "./../../resources/icons/AcceptMoneyReq";
import useMutateAcceptMoneyRequest from "../../../hooks/reactQuery/useMutateAcceptMoneyRequest";
import { categoriesLatestOptionsMarkup } from "../../../utils/markUp";

import apiClient from "../../../services/apiClient";
import Safe from "../../resources/icons/Safe";
import { useTranslation } from "react-i18next";

const ExpandedRow = ({ row, activeTab }) => {
  const { 
    data: userData, 
    isLoading: isUserDataLoading,
    isError: isUserDataError
  } = useFetchUserData();

  const {t, i18n} = useTranslation();


  const companySafes = [...userData.safes].filter(safe => safe.company_id === parseInt(localStorage.getItem("activeCompanyId"))).filter(safe => safe.active);
  const canWithdrawFromSafes = companySafes.filter(safe => safe.permissions.includes("CashFullControl") || safe.permissions.includes("CashOutWithoutPermission"));

  const safesOptions = canWithdrawFromSafes.map(obj => {
    return {
      ...obj,
      label: obj.name,
      value: obj.id
    }
  });

  const latestSafes = canWithdrawFromSafes.splice(0, 5).map(obj => {
    return {
      ...obj,
      label: obj.name,
      value: obj.id
    }
  });

  let history = useHistory();

  const [showFooter, setShowFooter] = useState(false);
  const [showloseAndDetails, setShowloseAndDetails] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showInsufficientFunds, setShowInsufficientFunds] = useState(false);

  const { data: userBalance } = useFetchUserBalance();

  const { isLoading: isFinLengthLoading, data: finLengthData } =
    useFetchFinLength(row.id);
  const {
    isLoading: isDocsCountLoading,
    data: docsCountData,
    error: docsCountError,
  } = useFetchDocsCount(row.id);

  const {
    data: financialsData,
    isLoading: isFinancialsLoading,
    error: financialsError,
  } = useFetchFinancials(userData.id, userData.activeCompanyId);

  const [showAcceptAlert, setShowAcceptAlert] = useState(false);
  const [acceptSrc, setSrc] = useState("");

  const {
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
    getValues,
    clearErrors,
    control,
    reset,
  } = useForm({
    mode: "all",
  });

  const { isLoading: isMutateAcceptLoading, mutate: mutateAccept } =
    useMutateAcceptMoneyRequest(setShowAcceptAlert, userData.activeCompanyId);

  const handleAcceptMonReq = (data) => {
    mutateAccept({
      data,
      fromSafe: acceptSrc === "safe" ? 1 : 0,
      activeRow: row,
    });
  };

  useEffect(() => {
    setValue(`financial_id`, "");
    clearErrors(`financial_id`);
    setValue(`safe_id`, "");
    clearErrors(`safe_id`);
  }, [acceptSrc]);

  return (
    <>
      {row.approved === null ? (
        <div className={`grid grid-cols-6 gap-4 ${i18n.resolvedLanguage === "en" ? "text-left ml-10" : "text-right mr-10"}`}>
          <div className="col-span-1">
            <p>{t("user_balance", {user_name: row.sender_name})}</p>
            <p>{row.sender_balance}</p>
          </div>
          <div className="col-span-5 row-start-2">
            <p>{t("money_request_purpose")}</p>
            <p>{row.description}</p>
          </div>
          <div className="col-span-5 row-start-3">
            {getAttachments({
              title: t("attachments"),
              notFoundMessage: t("no_attachments"),
              attachProperity: row.original_attachments,
              attachUrl: folders.money_requests,
              rowId: row.id,
            })}
          </div>
          {activeTab === 1 &&
          userData.permission_tags.includes("AcceptOrRejectMoneyRequest") && userData.id != row.creator ? (
            <div className="col-span-5 row-start-4 grid grid-cols-5 gap-4">
              <>
                <button
                  type="button"
                  className="col-span-1 inline-flex justify-center items-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none sm:w-auto sm:text-sm bg-black"
                  onClick={() => {
                    // if (
                    //   financialsData?.some(
                    //     (fin) => fin.originalValue >= row.amount
                    //   )
                    // ) {
                      setShowAcceptAlert(true);
                    // } else {
                    //   setShowInsufficientFunds(true);
                    // }
                  }}
                >
                  {t("money_request_accept")}
                </button>
                <Modal
                  showModal={showAcceptAlert}
                  onClose={() => setShowAcceptAlert(false)}
                >
                  <Alert
                    hasFooter={true}
                    icon={AcceptMoneyReq}
                    confirmBtnBg={`bg-secondary ${
                      acceptSrc === "fin" && !isValid
                        ? "bg-opacity-70 cursor-not-allowed"
                        : acceptSrc === ""
                        ? "bg-opacity-70 cursor-not-allowed"
                        : ""
                    }`}
                    isConfirmBtnDisabled={
                      acceptSrc === "fin" && !isValid
                        ? true
                        : acceptSrc === ""
                        ? true
                        : false
                    }
                    alertType="confirm"
                    title={t("money_request_accept")}
                    body={() => (
                      <form
                        className="flex flex-col gap-y-4"
                        onSubmit={handleSubmit(handleAcceptMonReq)}
                      >
                          {t("accept_money_request_dialog", {user_name: row.sender_Name})}
                          <div className="flex justify-start items-center gap-x-4">
                       
                          <div className="flex items-center gap-x-1.5">
                            {safesOptions.length > 0 ? 
                            <>
                              <input
                                type="radio"
                                id="fromSafe"
                                name="selectAcceptSrc"
                                value={acceptSrc}
                                onChange={() => setSrc("safe")}
                              />
                              <label
                                className="cursor-pointer"
                                htmlFor="fromSafe"
                              >
                                {t("accept_from_safe")}
                              </label>
                            </>
                            : null}
                          </div>

                          <div className="flex items-center gap-x-1.5">
                            <input
                              type="radio"
                              id="fromFin"
                              name="selectAcceptSrc"
                              value={acceptSrc}
                              onChange={() => setSrc("fin")}
                            />
                            <label
                              className="cursor-pointer"
                              htmlFor="fromFin"
                            >
                              {t("accept_from_money_request")}
                            </label>
                            </div>
                        </div>
                        <div className="text-right">
                        {acceptSrc === "fin" ? 
                          <SelectMenu
                            name="financial_id"
                            fieldName={t("transaction_code")}
                            label={t("transaction_code")}
                            placeholder={t("transaction_code")}
                            hint={t("pick_money_request")}
                            options={financialsData}
                            isLoading={isFinancialsLoading}
                            Controller={Controller}
                            control={control}
                            required={true}
                            validate={{
                              validateMaxValue: (selectedOption) => {
                                return row.amount >
                                  selectedOption?.originalValue ? (
                                  <div>
                                    <p>
                                      {t("money_request_balance")} {" "}
                                      {selectedOption?.originalValue}
                                    </p>
                                    <p>
                                      {t("money_request_insufficient_balance")}
                                    </p>
                                  </div>
                                ) : null;
                              },
                            }}
                            errors={errors?.financial_id}
                          />
                        : acceptSrc === "safe" && safesOptions.length > 0 ?
                        <SelectMenu
                          name="safe_id"
                          fieldName={t("safe_name")}
                          Icon={Safe}
                          label={t("safe_name")}
                          hint={t("pick_safe")}
                          latestOptions={latestSafes}
                          LatestOptionsMarkup={categoriesLatestOptionsMarkup(
                            latestSafes,
                            getValues,
                            setValue,
                            trigger,
                            `safe_id`,
                            "150px"
                          )}
                          options={safesOptions}
                          isLoading={isUserDataLoading}
                          isError={isUserDataError}
                          validate={{
                            validateMaxValue: async (selectedOption) => {
                              const { data } = await apiClient.get(
                                "safe/balance",
                                { params: { safe_id: selectedOption.id } }
                              );

                              return row.amount >
                                data.balance ? (
                                <div>
                                  <p>
                                    {t("safe_balace")} {" "}
                                    {data.balance}
                                  </p>
                                  <p>
                                    {t("safe_insufficient_balance")}
                                  </p>
                                </div>
                              ) : null;
                            },
                          }}
                          Controller={Controller}
                          control={control}
                          required={true}
                          errors={errors?.safe_id}
                        />
                        : null}
                        </div>
                      </form>
                    )}
                    confirmText={t("money_request_accept")}
                    cancelText={t("back")}
                    handleConfirm={handleSubmit(handleAcceptMonReq)}
                    handleCancel={() => {
                      setShowAcceptAlert(false);
                      setSrc("");
                      // setValue("financial_id", "");
                      reset();
                      clearErrors("financial_id");
                    }}
                    isSubmitLoading={isMutateAcceptLoading}
                  />
                </Modal>
              </>
              <button
                type="button"
                className="col-span-1 inline-flex justify-center items-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none sm:w-auto sm:text-sm bg-red-600"
                onClick={() => {
                  setShowRejectModal(true);
                }}
              >
                {t("money_request_reject")}
              </button>
            </div>
          ) : null}
        </div>
      ) : (
        <div className={`grid grid-cols-6 gap-4 ${i18n.resolvedLanguage === "en" ? "text-left ml-10" : "text-right mr-10"}`}>
          <div className="col-span-1">
            <p>{t("num_of_documents")}</p>
            <p>
              {isFinLengthLoading || isDocsCountLoading ? (
                <div className="w-6 h-6">
                  <Spinner />
                </div>
              ) : (
                <div className="flex items-center">
                  <span>{+finLengthData + +docsCountData} {t("expense")}</span>
                  <div className="flex items-center mx-2 col-span-1 tool-pin-container relative">
                    <button
                      onClick={() => {
                        // showFooter && setShowFooter(false);
                        // setShowloseAndDetails(true);

                        history.push({
                          pathname: "/moneyRequestDetails",
                          moneyRequestsActiveTab: activeTab,
                          type: showFooter
                            ? "closeMoneyRequest"
                            : "moneyRequestDetails",
                          docId: row.id,
                          activeRow: row,
                          showFooter: showFooter,
                          inValidteQueryKey: "getMoneyRequestsData",
                        });
                      }}
                    >
                      <BlueDotSvg />
                    </button>
                    <p className="tool-pin w-max px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-6 -right-20 z-10">
                      {t("open_money_request_documents")}
                    </p>
                  </div>
                </div>
              )}
            </p>
          </div>

          {row.sender_balance && (
            <div className="col-span-1">
              <p>{t("account_balance")}</p>
              <p>{row.sender_balance}</p>
            </div>
          )}
          {row.closing_date && (
            <div className="col-span-1">
              <p>{t("closing_date")}</p>
              <p>{convertDate(row.closing_date)}</p>
            </div>
          )}
          
          {row.deactivated && row.deactivation_date ? (
            <div className="col-span-1">
              <p className="text-xs text-body font-bold">{t("block_date")}</p>
              <p className="text-sm text-hints font-medium mt-1">
                {convertDate(row.deactivation_date)}
              </p>
            </div>
          ) : null}

          <div className="col-span-1">
            <p>{row.status === 1 ? t("current_amount") : t("closing_amount")}</p>
            <p>{row.remaining_amount}</p>
          </div>
          {row.parent_aid_name && row.creator !== userData.id && (
            <div className="col-span-1">
              <p>{t("parent_money_request")}</p>
              <p>{row.parent_aid_name}</p>
            </div>
          )}
          <div className="col-span-5">
            <p>{t("money_request_purpose")}</p>
            <p>{row.description}</p>
          </div>
          <div className="col-span-5">
            <p>{t("your_response")}</p>
            <p>{row.response_notes ? row.response_notes : t("no_response")}</p>
          </div>
          <div className="col-span-5">
            {getAttachments({
              title: t("attachments"),
              notFoundMessage: t("no_attachments"),
              attachProperity: row.response_attachments,
              attachUrl: folders.money_requests,
              rowId: row.id,
            })}
          </div>

          {activeTab === 0 && row.status === 1 && row.approved === 1 && (
            <div className="col-span-5 row-start-5 grid grid-cols-5 gap-4">
              <div className="col-span-1 tool-pin-container relative">
                <button
                  onClick={() => {
                    !showFooter && setShowFooter(true);
                    row.has_open_children === 0 && setShowloseAndDetails(true);
                  }}
                  disabled={row.has_open_children === 1}
                  className={`${
                    row.has_open_children === 1 &&
                    "cursor-not-allowed disabled bg-opacity-50"
                  }`}
                >
                  <LockSvg />
                </button>
                <p className="tool-pin w-max px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-8 -right-9 z-10">
                  {row.has_open_children === 1
                    ? t("has_open_children")
                    : t("close_money_request")}
                </p>
              </div>

              <div
                className="col-span-1 tool-pin-container relative"
                onClick={() => {
                  history.push({
                    pathname: "/document",
                    state: {
                      label: row.name,
                      value: row.id,
                      ...row,
                    },
                  });
                }}
              >
                <div>
                  <DocumentExpenseSvg />
                </div>
                <p className="tool-pin w-max px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-8 -right-9 z-10">
                  {t("transaction_type_document")}
                </p>
              </div>
            </div>
          )}
        </div>
      )}

      <Modal
        showModal={showloseAndDetails}
        onClose={() => setShowloseAndDetails(false)}
      >
        <CloseAndDetails
          onClose={() => setShowloseAndDetails(false)}
          type={showFooter ? "closeMoneyRequest" : "moneyRequestDetails"}
          docId={row.id}
          activeRow={row}
          showFooter={showFooter}
          inValidteQueryKey="getMoneyRequestsData"
        />
      </Modal>

      <Modal
        showModal={showRejectModal}
        onClose={() => setShowRejectModal(false)}
      >
        <Respond
          onClose={() => setShowRejectModal(false)}
          userBalance={userBalance}
          activeRow={row}
        />
      </Modal>

      <Modal
        showModal={showInsufficientFunds}
        onClose={() => setShowInsufficientFunds(false)}
      >
        <InsufficientFunds onClose={() => setShowInsufficientFunds(false)} />
      </Modal>
    </>
  );
};

export default ExpandedRow;
