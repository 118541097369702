import actionTypes from "./types";

export const initialState = {
  sidebarTab: false,
  hasDocumentFormValue: false,
  hasPaymentLicenseFormValue: false,
  searchType: "",
  searchTerms: [],
  activeTab: 0,
  selectedRows: {
    length: 0,
    amount: 0,
  },
  isFileUploading: false,
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case actionTypes.SWITCH_TAB: {
      return {
        ...state,
        sidebarTab: payload,
      };
    }
    case actionTypes.DOCUMENT_FORM: {
      return {
        ...state,
        hasDocumentFormValue: true,
      };
    }
    case actionTypes.PAYMENT_LICENSE_FORM: {
      return {
        ...state,
        hasPaymentLicenseFormValue: false,
      };
    }
    case actionTypes.SEARCH_TYPE: {
      return {
        ...state,
        searchType: payload,
      };
    }
    case actionTypes.SEARCH_TERMS: {
      return {
        ...state,
        searchTerms: payload,
      };
    }
    case actionTypes.ACTIVE_TAB: {
      return {
        ...state,
        activeTab: payload,
      };
    }
    case actionTypes.RESET_STATE: {
      return initialState;
    }
    case actionTypes.IS_FILE_UPLOADING: {
      return {
        ...state,
        isFileUploading: payload,
      };
    }
    case actionTypes.SELECTED_ROWS: {
      return {
        ...state,
        selectedRows: payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
