import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchRoles({
  userId,
  activeCompanyId,
  currentPage,
  pageSize,
  columnKey,
  order,
}) {
  return useQuery(
    ["getAllRoles", currentPage, pageSize, columnKey, order],
    () => {
      const params = {};
      params.user_id = userId;
      params.company_id = activeCompanyId;
      params.pageno = currentPage;
      params.pagesize = pageSize;
      if (order && columnKey) {
        params.isascending =
          order === "ascend" ? 1 : order === "descend" ? 0 : null;
        params.orderbycolumn = columnKey;
      }

      return apiClient
        .get(`role/all`, {
          params,
        })
        .then(({ data }) => {
          let results = data[0];
          for (let index = 0; index < results.length; index++) {
            const element = results[index];
            element.users_json_string = JSON.stringify(element.users);
            element.permissions_json_string = JSON.stringify(
              element.permissions
            );
          }
          return [results, data[1]];
        });
    },
    { keepPreviousData: true.valueOf, retry: false }
  );
}
