import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchPendingCash(
  safe_id,
  pageno = 1
) {
  return useQuery(
    [
      "pendingCash",
      safe_id,
      pageno
    ],
    () => {
      const params = {pageno, pagesize: 10};
      params.range = JSON.stringify([{}]);

      if(safe_id) params.safe_id = safe_id;

      return apiClient
        .get("/safe/pendingcash", {
          params,
        })
        .then(({data}) => {
          let newData = data[0].map(record => {
            return {
              ...record, 
              profile_picture_url: (process.env.REACT_APP_UPLOAD_FILES_BASE_URL
                ? process.env.REACT_APP_UPLOAD_FILES_BASE_URL
                : "") +
              (process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
                ? process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
                : "") +
              `users/${record.user_id}/profilepicture` +
              `.${record.profile_picture_ext}`
            }
          })
          return [newData, data[1]];
        });
    }
  );
}
