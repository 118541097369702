const People = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.6079 6.47484C14.5495 6.4665 14.4912 6.4665 14.4329 6.47484C13.1412 6.43317 12.1162 5.37484 12.1162 4.07484C12.1162 2.74984 13.1912 1.6665 14.5245 1.6665C15.8495 1.6665 16.9329 2.7415 16.9329 4.07484C16.9245 5.37484 15.8995 6.43317 14.6079 6.47484Z" fill="#94A3B8"/>
        <path d="M17.3247 12.2498C16.3913 12.8748 15.083 13.1082 13.8747 12.9498C14.1913 12.2665 14.358 11.5082 14.3663 10.7082C14.3663 9.87485 14.183 9.08318 13.833 8.39151C15.0663 8.22485 16.3747 8.45818 17.3163 9.08318C18.633 9.94985 18.633 11.3748 17.3247 12.2498Z" fill="#94A3B8"/>
        <path d="M5.36647 6.47484C5.4248 6.4665 5.48314 6.4665 5.54147 6.47484C6.83314 6.43317 7.85814 5.37484 7.85814 4.07484C7.85814 2.7415 6.78314 1.6665 5.4498 1.6665C4.1248 1.6665 3.0498 2.7415 3.0498 4.07484C3.0498 5.37484 4.0748 6.43317 5.36647 6.47484Z" fill="#94A3B8"/>
        <path d="M5.45794 10.7083C5.45794 11.5167 5.63294 12.2833 5.94961 12.975C4.77461 13.1 3.54961 12.85 2.64961 12.2583C1.33294 11.3833 1.33294 9.95834 2.64961 9.08334C3.54128 8.48334 4.79961 8.24167 5.98294 8.37501C5.64128 9.07501 5.45794 9.86667 5.45794 10.7083Z" fill="#94A3B8"/>
        <path d="M10.1 13.225C10.0333 13.2167 9.95833 13.2167 9.88333 13.225C8.35 13.175 7.125 11.9167 7.125 10.3667C7.13333 8.78333 8.40833 7.5 10 7.5C11.5833 7.5 12.8667 8.78333 12.8667 10.3667C12.8583 11.9167 11.6417 13.175 10.1 13.225Z" fill="#94A3B8"/>
        <path d="M7.39199 14.9502C6.13366 15.7919 6.13366 17.1752 7.39199 18.0085C8.82533 18.9669 11.1753 18.9669 12.6087 18.0085C13.867 17.1669 13.867 15.7835 12.6087 14.9502C11.1837 13.9919 8.83366 13.9919 7.39199 14.9502Z" fill="#94A3B8"/>
    </svg>
  );
};

export default People;
