import Spinner from "../Spinner";
import HomeIcon from "../../../components/resources/icons/HomeIcon";
import BreadcrumbSeparatorIcon from "../../../components/resources/icons/BreadcrumbSeparatorIcon";

import { useHistory, useLocation } from "react-router";

const Breadcrumb = ({ items }) => {
  const history = useHistory();

  return (
    <div className="bg-white-light w-full text-sm font-medium">
      <ul className="w-full flex flex-wrap content-center items-center border-t-2 border-b-2">
        <li className="ml-2 mr-8">
          <button
            onClick={() => {
              history.push({
                pathname: "/home",
              });
            }}
          >
            <HomeIcon />
          </button>
        </li>
        <li className="mx-2">
          <BreadcrumbSeparatorIcon />
        </li>
        {items?.map((item, index) => (
          <>
            <li className="mx-2">
              <button onClick={item.action} style={item.active ? {color: "#3B82F6"}: {}}>{item.title}</button>
            </li>
            {index !== items.length - 1 ? (
              <li className="mx-2">
                <BreadcrumbSeparatorIcon />
              </li>
            ) : null}
          </>
        ))}
      </ul>
    </div>
  );
};

export default Breadcrumb;
