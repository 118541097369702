import PropTypes from "prop-types";

function Icon(props) {
  const { className, ...restOfProps } = props;
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...restOfProps}
    >
      <path
        d="M12.0827 13.4922H7.91602C7.57435 13.4922 7.29102 13.2089 7.29102 12.8672C7.29102 12.5255 7.57435 12.2422 7.91602 12.2422H12.0827C12.4243 12.2422 12.7077 12.5255 12.7077 12.8672C12.7077 13.2089 12.4243 13.4922 12.0827 13.4922Z"
        fill="#94A3B8"
      />
      <path
        d="M10 15.6257C9.65833 15.6257 9.375 15.3423 9.375 15.0007V10.834C9.375 10.4923 9.65833 10.209 10 10.209C10.3417 10.209 10.625 10.4923 10.625 10.834V15.0007C10.625 15.3423 10.3417 15.6257 10 15.6257Z"
        fill="#94A3B8"
      />
      <path
        d="M4.33275 8.71591C4.17442 8.71591 4.01609 8.64925 3.89109 8.53258C3.71609 8.35758 3.65775 8.08258 3.75775 7.84925L5.30775 4.14925C5.34109 4.07425 5.35775 4.02425 5.38275 3.98258C6.61609 1.14091 8.19109 0.449246 10.9744 1.51591C11.1328 1.57425 11.2578 1.69925 11.3244 1.85758C11.3911 2.01591 11.3911 2.19091 11.3244 2.34925L8.88275 8.01591C8.78275 8.24091 8.55775 8.39091 8.30775 8.39091H5.93275C5.45775 8.39091 5.00775 8.48258 4.57442 8.66591C4.49942 8.69925 4.41609 8.71591 4.33275 8.71591ZM8.84109 2.29091C7.80775 2.29091 7.17442 2.96591 6.51609 4.49925C6.50775 4.52425 6.49109 4.54925 6.48275 4.57425L5.39109 7.16591C5.57442 7.14925 5.74942 7.14091 5.93275 7.14091H7.89109L9.89942 2.47425C9.50776 2.34925 9.15775 2.29091 8.84109 2.29091Z"
        fill="#94A3B8"
      />
      <path
        d="M15.2415 8.55843C15.1832 8.55843 15.1165 8.5501 15.0582 8.53343C14.7499 8.44177 14.4082 8.39177 14.0582 8.39177H8.3082C8.09986 8.39177 7.89986 8.28343 7.7832 8.10843C7.67486 7.93343 7.64986 7.70844 7.7332 7.51677L10.1499 1.90843C10.2749 1.60843 10.6415 1.40843 10.9499 1.50843C11.0499 1.54177 11.1415 1.58343 11.2415 1.6251L13.2082 2.4501C14.3582 2.9251 15.1249 3.4251 15.6249 4.0251C15.7249 4.14177 15.8082 4.26677 15.8832 4.39177C15.9749 4.53343 16.0582 4.70844 16.1165 4.88344C16.1415 4.94177 16.1832 5.0501 16.2082 5.16677C16.4415 5.9501 16.3249 6.9251 15.8249 8.1751C15.7249 8.40843 15.4915 8.55843 15.2415 8.55843ZM9.2582 7.14177H14.0665C14.3332 7.14177 14.5915 7.16677 14.8499 7.20843C15.0832 6.48343 15.1332 5.9251 14.9999 5.4751C14.9832 5.4001 14.9665 5.36677 14.9582 5.33344C14.9082 5.2001 14.8749 5.1251 14.8332 5.05843C14.7749 4.96677 14.7332 4.89177 14.6665 4.81677C14.3082 4.38344 13.6749 3.98344 12.7332 3.6001L11.0832 2.90843L9.2582 7.14177Z"
        fill="#94A3B8"
      />
      <path
        d="M13.25 18.9592H6.75C6.51667 18.9592 6.3 18.9426 6.08333 18.9176C3.15833 18.7176 1.49167 17.0509 1.29167 14.0926C1.26667 13.9092 1.25 13.6842 1.25 13.4592V11.8342C1.25 9.95924 2.36667 8.26758 4.09167 7.51758C4.675 7.26758 5.3 7.14258 5.94167 7.14258H14.075C14.55 7.14258 15.0083 7.20925 15.4333 7.34258C17.3917 7.93425 18.7667 9.78424 18.7667 11.8342V13.4592C18.7667 13.6426 18.7583 13.8176 18.75 13.9842C18.5667 17.2426 16.6667 18.9592 13.25 18.9592ZM5.93333 8.39258C5.45833 8.39258 5.00833 8.48424 4.575 8.66758C3.30833 9.21758 2.49167 10.4592 2.49167 11.8342V13.4592C2.49167 13.6342 2.50833 13.8092 2.525 13.9759C2.68333 16.3509 3.85 17.5176 6.19167 17.6759C6.4 17.7009 6.56667 17.7176 6.74167 17.7176H13.2417C15.9917 17.7176 17.3417 16.5092 17.475 13.9259C17.4833 13.7759 17.4917 13.6259 17.4917 13.4592V11.8342C17.4917 10.3259 16.4833 8.97591 15.05 8.53424C14.7417 8.44258 14.4 8.39258 14.05 8.39258H5.93333Z"
        fill="#94A3B8"
      />
      <path
        d="M1.86719 12.4591C1.52552 12.4591 1.24219 12.1758 1.24219 11.8341V9.39248C1.24219 6.76748 3.10052 4.50081 5.66719 4.00081C5.89219 3.95914 6.12552 4.04248 6.27552 4.21748C6.41719 4.39248 6.45885 4.64248 6.36719 4.85081L4.90885 8.33414C4.84219 8.48414 4.72552 8.60081 4.58385 8.66748C3.31719 9.21748 2.50052 10.4591 2.50052 11.8341C2.49219 12.1758 2.21719 12.4591 1.86719 12.4591ZM4.66719 5.68414C3.60052 6.28414 2.82552 7.33414 2.58385 8.55914C2.95052 8.18414 3.37552 7.86748 3.85885 7.63414L4.66719 5.68414Z"
        fill="#94A3B8"
      />
      <path
        d="M18.133 12.4581C17.7913 12.4581 17.508 12.1748 17.508 11.8331C17.508 10.3248 16.4996 8.97482 15.0663 8.53315C14.8996 8.48315 14.758 8.36648 14.683 8.20815C14.608 8.04982 14.5996 7.86648 14.6663 7.70815C15.058 6.73315 15.158 6.02482 14.9996 5.47482C14.983 5.39982 14.9663 5.36648 14.958 5.33315C14.8496 5.09148 14.908 4.80815 15.0996 4.62482C15.2913 4.44148 15.583 4.39981 15.8163 4.52481C17.633 5.47481 18.758 7.34148 18.758 9.39148V11.8331C18.758 12.1748 18.4746 12.4581 18.133 12.4581ZM16.0413 7.57482C16.5663 7.81648 17.033 8.15815 17.4246 8.56648C17.2663 7.74981 16.8746 7.00815 16.2996 6.42482C16.258 6.77482 16.1746 7.15815 16.0413 7.57482Z"
        fill="#94A3B8"
      />
    </svg>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
};

export default Icon;
