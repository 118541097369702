import { useEffect, useRef, useState } from "react";

import { useQueryClient } from "react-query";
import { useWatch, Controller, useFormContext } from "react-hook-form";
import useCollapse from "react-collapsed";

import Input from "../../common/inputs/Input";
import TextArea from "../../common/inputs/Textarea";
import SelectMenu from "../../common/inputs/Select";
import UploadInput from "../../common/inputs/Upload";

import ChevronArrow from "../../resources/icons/ChevronArrow";
import Cash from "../../resources/icons/Cash";
import Timer from "../../resources/icons/Timer";
import Trash from "../../resources/icons/Trash";
import DataBase from "./../../resources/icons/Database";
import MainFinancial from "../../resources/icons/MainFinancial";
import Receipt from "../../resources/icons/Receipt";
import Wallet from "../../resources/icons/Wallet";
import Safe from "../../resources/icons/Safe";

import useFetchUserData from "../../../hooks/reactQuery/useFetchUserData";
import useFetchCategory from "../../../hooks/reactQuery/useFetchCategory";
import useMutateCategory from "../../../hooks/reactQuery/useMutateCategory";
import useFetchLatestCategories from "../../../hooks/reactQuery/useFetchLatestCategories";

import { categoriesLatestOptionsMarkup } from "../../../utils/markUp";
import useFetchSafeBalance from "./../../../hooks/reactQuery/useFetchSafeBalance";
import apiClient from "../../../services/apiClient";

import { useTranslation } from "react-i18next";

const Form = ({
  fields,
  financials,
  index,
  field,
  removeFieldSet,
  trackTotalValue,
  financialIdDefaultValue,
  activeFieldArr,
  setActiveFieldArr,
  isExpanded,
  setExpanded,
}) => {

  const { 
    data: userData,
    isLoading: isUserDataLoading,
    isError: isUserDataError
  } = useFetchUserData();

  const {t, i18n} = useTranslation();

  const periodicOptions = [
    { label: t("periodic"), value: 1 },
    { label: t("rare"), value: 0 },
  ];

  useEffect(() => console.log(financialIdDefaultValue), []);

  const [financialIdHint, setFinancialIdHint] = useState(t("pick_money_request"));
  const [safeHint, setSafeHint] = useState(t("pick_safe"));

  const safeWithdraw = userData?.safes.find(safe => safe.permissions.includes("CashFullControl") || safe.permissions.includes("CashWithOutPermission"));
  const canWithdrawFromSafe = !safeWithdraw ? false : true;

  const safesOptions = [...userData.safes].filter(safe => safe.company_id === parseInt(localStorage.getItem("activeCompanyId"))).filter(safe => safe.active).filter(safe => safe.deactivated != 1).map(obj => {
    return {
      ...obj,
      label: obj.name,
      value: obj.id
    }
  });

  const latestSafes = [...userData.safes].filter(safe => safe.company_id === parseInt(localStorage.getItem("activeCompanyId"))).filter(safe => safe.active).filter(safe => safe.deactivated != 1).splice(0, 5).map(obj => {
    return {
      ...obj,
      label: obj.name,
      value: obj.id
    }
  });

  const queryClint = useQueryClient();

  const {
    register,
    formState: { errors },
    trigger,
    clearErrors,
    getValues,
    setValue,
    control,
    setFocus,
  } = useFormContext();

  const {
    data: categories,
    isLoading: iscategoriesLoading,
    isError: isCategoriesError,
  } = useFetchCategory();

  const {
    data: financialsData,
    isLoading: isFinancialsLoading,
    isError: isFinancialsError,
  } = financials;

  const newCategoryOptionMutation = useMutateCategory(
    userData.id,
    userData.activeCompanyId
  );

  //
  //

  const watchAll = useWatch({
    control,
    name: `documentForm`,
  });

  const watchFinancialValue = useWatch({
    control,
    name: `documentForm[${index}].financial_id`,
  });

  const watchSafeId = useWatch({
    control,
    name: `documentForm[${index}].safe_id`,
  });

  const watchAmount = useWatch({
    control,
    name: `documentForm[${index}].amount`,
  });

  const [isAmountDisabled, setIsAmountDisabled] = useState(true);

  useEffect(() => {
    if(getValues(`documentForm[${index}].fromSafe`)) {
      if(!getValues(`documentForm[${index}].safe_id`) || getValues(`documentForm[${index}].safe_id`) === "") {
        setIsAmountDisabled(true);
      }
      else {
        setIsAmountDisabled(false);
      }
    }
    else {
      if(!getValues(`documentForm[${index}].financial_id`) || getValues(`documentForm[${index}].financial_id`) === "") {
        setIsAmountDisabled(true);
      }
      else {
        setIsAmountDisabled(false);
      }
    }

  }, [watchSafeId, watchFinancialValue]);

  const calcTotalValue = () => {
    return fields
      .map((field, i) => {
        return +getValues(`documentForm[${i}].amount`);
      })
      .reduce((accum, current) => {
        return (accum ? +accum : 0) + (current ? +current : 0);
      });
  };

  useEffect(() => {
    trackTotalValue(calcTotalValue());
  }, [watchAmount, fields.length]);

  const initialRender = useRef(true);
  // useEffect(() => {
  //   if (initialRender.current) {
  //     initialRender.current = false;
  //     return;
  //   }
  //   trigger(`documentForm[${index}].amount`);
  // }, [watchFinancialValue]);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    queryClint.setQueryData("financials", (options) =>
      options?.map((option) => {
        const _option = { ...option };
        _option.remainValue =
          _option.originalValue -
          watchAll
            .filter((f) => f.financial_id?.id === _option?.id)
            .reduce((acc, { amount }) => acc + +amount, 0);
        return _option;
      })
    );

    queryClint.setQueryData("getSafeBalance", (safeTotBalance) => ({
      ...safeTotBalance,
      remainValue:
        safeTotBalance?.originalValue -
        watchAll
          .filter((f) => f.fromSafe)
          .reduce((acc, { amount }) => acc + +amount, 0),
    }));
  }, [watchAll]);

  const remainingValue = financialsData?.find(
    (f) => f?.id === watchFinancialValue?.id
  )?.remainValue;

  useEffect(() => {
    setFocus(`documentForm[${index}].amount`);
  }, [watchFinancialValue, watchSafeId]);

  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded: isExpanded && activeFieldArr === field.id,
    duration: 700,
  });

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    if (+remainingValue + +watchAmount == 0) {
      trigger(`documentForm[${index}].amount`);
    }
  }, [watchAmount]);

  const { data: latestCategories } = useFetchLatestCategories();

  useEffect(() => {
    //setValue(`documentForm[${index}].financial_id`, "");
    clearErrors(`documentForm[${index}].financial_id`);
    setValue(`documentForm[${index}].safe_id`, "");
    clearErrors(`documentForm[${index}].safe_id`);
    setValue(`documentForm[${index}].amount`, "");
    clearErrors(`documentForm[${index}].amount`);
    setFocus(`documentForm[${index}].amount`);
  }, [getValues(`documentForm[${index}].fromSafe`)]);

  useEffect(() => {
    if(getValues(`documentForm[${index}].financial_id`))
      setFinancialIdHint(t("money_request_balance_dynamic", {balance: 
      `${new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(getValues(`documentForm[${index}].financial_id`).remainValue).toString().replace(/EGP/, "") + " "}
      ج.م`}));
  }, [getValues(`documentForm[${index}].financial_id`)]);

  useEffect(() => {
    console.log(getValues(`documentForm[${index}].safe_id`));
    if(getValues(`documentForm[${index}].safe_id`))
      setSafeHint(t("safe_balance_dynamic", {balance:
      `${new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(getValues(`documentForm[${index}].safe_id`).balance).toString().replace(/EGP/, "") + " "}
      ج.م`}));
  }, [getValues(`documentForm[${index}].safe_id`)]);

  const { data: safeTotalBalance } = useFetchSafeBalance();

  const [safeBalance, setSafeBalance] = useState(0);
  useEffect(() => {
    setSafeBalance(safeTotalBalance?.remainValue);
  }, [safeTotalBalance]);

  return (
    <div key={field.id} className="flex w-full mt-4">
      <fieldset className="w-full">
        <div className="border border-muted-light rounded">
          <div
            className={`relative flex items-center justify-between gap-x-20 px-6 py-3 transition-all duration-700 ${
              isExpanded && activeFieldArr === field.id
                ? "border-b"
                : "bg-sidebar-background border-transparent"
            }`}
            {...getToggleProps({
              onClick: () => {
                setActiveFieldArr(field.id);
                setExpanded((prevExpanded) => !prevExpanded);
              },
            })}
          >
            <div
              className={`bg-white p-0.5 rounded-full transition-all duration-700 transform ${
                isExpanded && activeFieldArr === field.id
                  ? "rotate-90"
                  : "rotate-180"
              }`}
            >
              <ChevronArrow />
            </div>
            <div
              className={`w-full hidden xl:grid grid-cols-4 gap-x-4 opacity-0 transition-all duration-700 ${
                (!isExpanded || !activeFieldArr === field.id) && "opacity-100"
              }`}
            >
              <div className="flex flex-wrap justify-center items-center gap-x-2 sm:h-6 overflow-hidden">
                <div>
                  <Cash />
                </div>
                <span className="text-right w-32 h-6 overflow-hidden">
                  {getValues(`documentForm[${index}].amount`)}
                </span>
              </div>

              <div className="flex flex-wrap justify-center items-center gap-x-2 sm:h-6 overflow-hidden">
                <div>
                  <DataBase />
                </div>
                <span className="text-right w-32 h-6 overflow-hidden">
                  {getValues(`documentForm[${index}].category_id`)?.label}
                </span>
              </div>

              <div className="flex flex-wrap justify-center items-center gap-x-2 sm:h-6 overflow-hidden">
                <div>
                  <Wallet />
                </div>
                <span className="text-right w-32 h-6 overflow-hidden">
                  {getValues(`documentForm[${index}].financial_id`)?.label}
                </span>
              </div>

              <div className="flex flex-wrap justify-center items-center gap-x-2 sm:h-6 overflow-hidden">
                <div>
                  <Timer />
                </div>
                <span className="text-right w-32 h-6 overflow-hidden">
                  {getValues(`documentForm[${index}].payment_date`)}
                </span>
              </div>
            </div>
            <button
              type="button"
              className={fields.length === 1 ? "cursor-not-allowed" : ""}
              onClick={() => {
                removeFieldSet(index);
              }}
              disabled={fields.length === 1}
            >
              <Trash fillColor={fields.length === 1 && "gray"} />
            </button>
          </div>

          <div {...getCollapseProps()}>
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-12 py-5 px-6">
              <div className="custom-grid-1 lg:col-span-9">
                <div>
                  <Input
                    register={register}
                    defaultValue={field?.amount}
                    type="number"
                    name={`documentForm[${index}].amount`}
                    fieldName={t("amount")}
                    label={t("amount")}
                    hint={getValues(`documentForm[${index}].fromSafe`) ? !getValues(`documentForm[${index}].safe_id`) || getValues(`documentForm[${index}].safe_id`) === "" ? "اختر الخزينة اللتي تريد اجراء العملية من خلالها اولا" : "أدخل القيمة التي تم صرفها" : !getValues(`documentForm[${index}].financial_id`) || getValues(`documentForm[${index}].financial_id`) === "" ? "اختر العهدة اللتي تريد اجراء العملية من خلالها اولا" : "أدخل القيمة التي تم صرفها"}
                    required={
                      watchFinancialValue ||
                      getValues(`documentForm[${index}].fromSafe`)
                        ? true
                        : false
                    }
                    min={1}
                    validate={{                     
                      maxValue: async(value) => {
                        if(getValues(`documentForm[${index}].fromSafe`)) {
                          const { data } = await apiClient.get(
                            "safe/balance",
                            { params: { safe_id: getValues(`documentForm[${index}].safe_id.id`) } }
                          );

                          return value > data.balance ? t("max_validation") : true 
                        }
                        else {
                          return value > getValues(`documentForm[${index}].financial_id`)?.remainValue ? t("max_validation") : true;
                        }
                      }
                    }}
                    isDisabled={isAmountDisabled}
                    errors={
                      errors?.documentForm &&
                      errors?.documentForm[index]?.amount
                    }
                  />
                </div>

                <div>
                  <SelectMenu
                    defaultValue={field.category_id}
                    name={`documentForm[${index}].category_id`}
                    fieldName={t("category")}
                    label={t("category")}
                    hint={t("category_hint")}
                    Icon={DataBase}
                    latestOptions={latestCategories}
                    LatestOptionsMarkup={categoriesLatestOptionsMarkup(
                      latestCategories,
                      getValues,
                      setValue,
                      trigger,
                      `documentForm[${index}].category_id`,
                      "150px"
                    )}
                    options={categories}
                    isLoading={iscategoriesLoading}
                    isError={isCategoriesError}
                    isCreatable={true}
                    Controller={Controller}
                    control={control}
                    required={true}
                    errors={
                      errors?.documentForm &&
                      errors?.documentForm[index]?.category_id
                    }
                    newOptionMutation={newCategoryOptionMutation}
                  />
                </div>
              </div>

              <div className="lg:col-span-3 row-span-4">
                <UploadInput
                  defaultValue={field.attachments}
                  multiple={true}
                  accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf, image/*"
                  name={`documentForm[${index}].attachments`}
                  id={`documentForm[${index}].attachments`}
                  showUplodedFilesNames={true}
                  getValues={getValues}
                  fileSize="5000000"
                  errors={
                    errors?.documentForm &&
                    errors?.documentForm[index]?.attachments
                  }
                  Controller={Controller}
                  control={control}
                  setValue={setValue}
                  trigger={trigger}
                />
              </div>

              <div className="lg:col-span-9">
                <div className="relative">
                  { getValues(`documentForm[${index}].fromSafe`) ?
                  <SelectMenu
                    defaultValue={field.safe_id}
                    name={`documentForm[${index}].safe_id`}
                    fieldName={t("safe_name")}
                    label={t("safe_name")}
                    hint={safeHint}
                    Icon={Safe}
                    latestOptions={latestSafes}
                    LatestOptionsMarkup={categoriesLatestOptionsMarkup(
                      latestSafes,
                      getValues,
                      setValue,
                      trigger,
                      `documentForm[${index}].safe_id`,
                      "150px"
                    )}
                    options={safesOptions}
                    isLoading={isUserDataLoading}
                    isError={isUserDataError}
                    Controller={Controller}
                    control={control}
                    required={getValues(`documentForm[${index}].fromSafe`) ? true : false}
                    errors={
                      errors?.documentForm &&
                      errors?.documentForm[index]?.safe_id
                    }
                  />
                  :
                  <SelectMenu
                    name={`documentForm[${index}].financial_id`}
                    defaultValue={
                      financialIdDefaultValue
                        ? financialIdDefaultValue
                        : field.financial_id
                    }
                    fieldName={t("parent_money_request")}
                    label={t("parent_money_request")}
                    hint={financialIdHint}
                    Icon={MainFinancial}
                    options={financialsData}
                    isLoading={isFinancialsLoading}
                    isError={isFinancialsError}
                    Controller={Controller}
                    control={control}
                    required={
                      getValues(`documentForm[${index}].fromSafe`)
                        ? false
                        : true
                    }
                    errors={
                      errors?.documentForm &&
                      errors?.documentForm[index]?.financial_id
                    }
                    isDisabled={getValues(`documentForm[${index}].fromSafe`)}
                  />
                  }
                  {canWithdrawFromSafe && (
                    <div className="absolute left-0 bottom-0">
                      <div className="flex items-center gap-x-2">
                        <label
                          className="text-xs leading-5 text-hints font-normal cursor-pointer select-none"
                          htmlFor={`documentForm[${index}].fromSafe`}
                        >
                        {t("pay_from_safe")}
                        </label>
                        <input
                          type="checkbox"
                          id={`documentForm[${index}].fromSafe`}
                          {...register(`documentForm[${index}].fromSafe`, {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="custom-grid-1 lg:col-span-9">
                <div>
                  <SelectMenu
                    defaultValue={field.periodic}
                    name={`documentForm[${index}].periodic`}
                    fieldName={t("document_type")}
                    label={t("document_type")}
                    hint={t("document_type_hint")}
                    Icon={Receipt}
                    latestOptions={periodicOptions}
                    LatestOptionsMarkup={categoriesLatestOptionsMarkup(
                      periodicOptions,
                      getValues,
                      setValue,
                      trigger,
                      `documentForm[${index}].periodic`,
                      "250px"
                    )}
                    options={periodicOptions}
                    Controller={Controller}
                    control={control}
                    required={true}
                    errors={
                      errors?.documentForm &&
                      errors?.documentForm[index]?.periodic
                    }
                  />
                </div>

                <div>
                  <Input
                    register={register}
                    defaultValue={field.payment_date}
                    type="date"
                    name={`documentForm[${index}].payment_date`}
                    fieldName={t("spend_date")}
                    label={t("spend_date")}
                    hint={t("spend_date_hint")}
                    required={true}
                    errors={
                      errors?.documentForm &&
                      errors?.documentForm[index]?.payment_date
                    }
                  />
                </div>
              </div>

              <div className="lg:col-span-9">
                <TextArea
                  register={register}
                  defaultValue={field.notes}
                  name={`documentForm[${index}].notes`}
                  fieldName={t("spend_purpose")}
                  label={t("spend_purpose")}
                  hint={t("notes_hint")}
                  required={false}
                  maxLength="2000"
                  errors={
                    errors?.documentForm && errors?.documentForm[index]?.notes
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default Form;
