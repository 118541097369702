const PasswordHide = ({}) => {
  return (
    <div>
      <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.2487 3.53327C8.82231 3.39901 9.40958 3.33189 9.9987 3.33327C15.832 3.33327 19.1654 9.99994 19.1654 9.99994C18.6595 10.9463 18.0562 11.8372 17.3654 12.6583M11.7654 11.7666C11.5365 12.0122 11.2605 12.2092 10.9538 12.3459C10.6472 12.4825 10.3161 12.556 9.98044 12.5619C9.64476 12.5678 9.31133 12.5061 9.00004 12.3803C8.68874 12.2546 8.40597 12.0675 8.16857 11.8301C7.93117 11.5927 7.74402 11.3099 7.61829 10.9986C7.49255 10.6873 7.4308 10.3539 7.43673 10.0182C7.44265 9.68252 7.51612 9.35148 7.65276 9.04481C7.7894 8.73815 7.98641 8.46215 8.23203 8.23327M14.9487 14.9499C13.5242 16.0358 11.7896 16.6373 9.9987 16.6666C4.16536 16.6666 0.832031 9.99994 0.832031 9.99994C1.86861 8.06819 3.30631 6.38045 5.0487 5.04994L14.9487 14.9499Z"
          stroke="#343C44"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.832031 0.833374L19.1654 19.1667"
          stroke="#343C44"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default PasswordHide;
