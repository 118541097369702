import { getAttachments } from "../../../../../utils/getAttachments";

import folders from "../../../../../constants/static_folders";

const ExpandedRowWithoutPerm = ({ row }) => {
  return (
    <div className="grid grid-cols-6 gap-4 mr-20">
      <div className="col-span-1">
        <p className="text-xs text-body font-bold">موضوع العملية</p>
        <p className="text-sm text-hints font-medium mt-1">{row.name}</p>
      </div>

      <div className="col-span-5 row-start-2">
        <p className="text-xs text-body font-bold">
          ملاحظات {row.creator_name}
        </p>
        <p className="text-sm text-hints font-medium mt-1">
          {row.notes ? row.notes : "لا توجد ملاحظات"}
        </p>
      </div>

      {row.attachments && (
        <div className="col-span-5 row-start-3">
          {getAttachments({
            title: "مرفقات",
            notFoundMessage: "لا توجد مرفقات",
            attachProperity: row.attachments,
            attachUrl: folders.deposits,
            rowId: row.id,
          })}
        </div>
      )}
    </div>
  );
};

export default ExpandedRowWithoutPerm;
