const WithdrawalsBox = ({fill}) => {
  return (
    <div>
      <svg width="47" height="46" viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.292969" width="46" height="46" rx="16" fill="#FFF1D5"/>
        <path d="M31.263 12.75H28.323C27.053 12.75 26.293 13.51 26.293 14.78V17.72C26.293 18.99 27.053 19.75 28.323 19.75H31.263C32.533 19.75 33.293 18.99 33.293 17.72V14.78C33.293 13.51 32.533 12.75 31.263 12.75ZM31.483 16.06C31.363 16.18 31.203 16.24 31.043 16.24C30.883 16.24 30.723 16.18 30.603 16.06L30.423 15.88V18.12C30.423 18.47 30.143 18.75 29.793 18.75C29.443 18.75 29.163 18.47 29.163 18.12V15.88L28.983 16.06C28.743 16.3 28.343 16.3 28.103 16.06C27.863 15.82 27.863 15.42 28.103 15.18L29.353 13.93C29.403 13.88 29.473 13.84 29.543 13.81C29.563 13.8 29.583 13.8 29.603 13.79C29.653 13.77 29.703 13.76 29.763 13.76C29.783 13.76 29.803 13.76 29.823 13.76C29.893 13.76 29.953 13.77 30.023 13.8C30.033 13.8 30.033 13.8 30.043 13.8C30.113 13.83 30.173 13.87 30.223 13.92C30.233 13.93 30.233 13.93 30.243 13.93L31.493 15.18C31.733 15.42 31.733 15.82 31.483 16.06Z" fill="#FFCD86"/>
        <path d="M13.293 22.46V27.46C13.293 29.75 15.143 31.6 17.433 31.6H29.143C31.433 31.6 33.293 29.74 33.293 27.45V22.46C33.293 21.79 32.753 21.25 32.083 21.25H14.503C13.833 21.25 13.293 21.79 13.293 22.46ZM19.293 28.25H17.293C16.883 28.25 16.543 27.91 16.543 27.5C16.543 27.09 16.883 26.75 17.293 26.75H19.293C19.703 26.75 20.043 27.09 20.043 27.5C20.043 27.91 19.703 28.25 19.293 28.25ZM25.793 28.25H21.793C21.383 28.25 21.043 27.91 21.043 27.5C21.043 27.09 21.383 26.75 21.793 26.75H25.793C26.203 26.75 26.543 27.09 26.543 27.5C26.543 27.91 26.203 28.25 25.793 28.25Z" fill="#FFCD86"/>
        <path d="M24.793 15.61V18.54C24.793 19.21 24.253 19.75 23.583 19.75H14.503C13.823 19.75 13.293 19.19 13.293 18.52C13.303 17.39 13.753 16.36 14.503 15.61C15.253 14.86 16.293 14.4 17.433 14.4H23.583C24.253 14.4 24.793 14.94 24.793 15.61Z" fill="#FFCD86"/>
      </svg>
    </div>
  );
};

export default WithdrawalsBox;
