import { useState } from "react";

const TruncateNotes = ({ row, propertyName }) => {
  const [truncate, setTruncate] = useState(false);

  return (
    <div className="mr-2 text-xs text-body font-medium flex flex-col gap-y-2">
      <div className={`mr-2 text-xs text-body font-medium`}>
        {row[propertyName]
          ? row[propertyName]?.slice(
              0,
              truncate ? row[propertyName]?.length : 20
            )
          : "لا توجد ملاحظات"}
      </div>

      {row[propertyName]?.length > 20 && (
        <span
          className="text-xs font-normal text-secondary"
          onClick={(e) => {
            e.stopPropagation();
            setTruncate((p) => !p);
          }}
        >
          {truncate ? "أقل" : "أكثر"}
        </span>
      )}
    </div>
  );
};

export default TruncateNotes;
