import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";

import App from "./App";
import "./tailwind.css";
import "./index.scss";
import "react-toastify/dist/ReactToastify.css";

import { DataLayer } from "./context/DataLayer";
import reducer, { initialState } from "./context/reducer";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Close from "./components/resources/icons/Close";
import "./i18";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <DataLayer reducer={reducer} intialState={initialState}>
        <QueryClientProvider client={queryClient}>
          <App />
          {/* <ReactQueryDevtools /> */}
        </QueryClientProvider>
      </DataLayer>
      <ToastContainer
        autoClose={3000}
        position="bottom-center"
        hideProgressBar
        transition={Zoom}
        closeButton={({ closeToast }) => {
          return (
            <button
              onClick={closeToast}
              className="flex justify-center items-center"
            >
              <Close />
            </button>
          );
        }}
        closeOnClick={false}
        draggable={false}
        limit={4}
        rtl
        theme="colored"
      />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
