import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useMutateAddMembers(onClose, userData) {
  const queryClint = useQueryClient();
  return useMutation(
    (data) => {
      const formData = new FormData();
      formData.append("emails", JSON.stringify(data));
      formData.append("user_name", userData.name);
      formData.append("company_id", userData.activeCompany.id);
      formData.append("company_name", userData.activeCompany.name);
      formData.append("user_id", userData.id);

      return apiClient.post(`user/${userData.id}/sendinvitations`, formData);
    },
    {
      onSuccess: (response, data) => {
        toast.info("تم إرسال الدعوات بنجاح");
        queryClint.invalidateQueries("team");
        onClose();
      },
      onError: (error) => {
        onClose();
        return error;
      },
    }
  );
}
