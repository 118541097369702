const ArrowDown = ({fill}) => {
  return (
    <div>
      <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.1997 8.33319L8.03986 2.17331L5.69324 5.69324L1 1" stroke={fill ? fill : "#FF805C"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.2656 8.33317H14.1989V5.3999" stroke={fill ? fill : "#FF805C"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  );
};

export default ArrowDown;
