const Wallet = () => {
  return (
    <div>
      <svg
        width={19}
        height={18}
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.2871 17.0625H5.78711C3.20711 17.0625 1.47461 15.33 1.47461 12.75V9C1.47461 6.69 2.89961 5.01751 5.11211 4.74001C5.32211 4.71001 5.55461 4.6875 5.78711 4.6875H13.2871C13.4671 4.6875 13.6996 4.695 13.9396 4.7325C16.1521 4.9875 17.5996 6.6675 17.5996 9V12.75C17.5996 15.33 15.8671 17.0625 13.2871 17.0625ZM5.78711 5.8125C5.60711 5.8125 5.43461 5.82749 5.26211 5.84999C3.61211 6.05999 2.59961 7.26 2.59961 9V12.75C2.59961 14.685 3.85211 15.9375 5.78711 15.9375H13.2871C15.2221 15.9375 16.4746 14.685 16.4746 12.75V9C16.4746 7.245 15.4471 6.03751 13.7821 5.84251C13.6021 5.81251 13.4446 5.8125 13.2871 5.8125H5.78711Z"
          fill="#1E293B"
        />
        <path
          d="M5.17916 5.85758C4.99916 5.85758 4.83416 5.77508 4.72166 5.62509C4.59416 5.45259 4.57916 5.22759 4.67666 5.04009C4.80416 4.78509 4.98416 4.53759 5.21666 4.31259L7.65416 1.86758C8.89916 0.630078 10.9242 0.630078 12.1692 1.86758L13.4817 3.19509C14.0367 3.74259 14.3742 4.47759 14.4117 5.25759C14.4192 5.43009 14.3517 5.59507 14.2242 5.70757C14.0967 5.82007 13.9242 5.87259 13.7592 5.84259C13.6092 5.82009 13.4517 5.81259 13.2867 5.81259H5.78666C5.60666 5.81259 5.43416 5.82758 5.26166 5.85008C5.23916 5.85758 5.20916 5.85758 5.17916 5.85758ZM6.43166 4.68759H13.1517C13.0542 4.43259 12.8967 4.20009 12.6867 3.99009L11.3667 2.65509C10.5642 1.86009 9.25166 1.86009 8.44166 2.65509L6.43166 4.68759Z"
          fill="#1E293B"
        />
        <path
          d="M17.0371 12.9375H14.7871C13.6471 12.9375 12.7246 12.015 12.7246 10.875C12.7246 9.735 13.6471 8.8125 14.7871 8.8125H17.0371C17.3446 8.8125 17.5996 9.0675 17.5996 9.375C17.5996 9.6825 17.3446 9.9375 17.0371 9.9375H14.7871C14.2696 9.9375 13.8496 10.3575 13.8496 10.875C13.8496 11.3925 14.2696 11.8125 14.7871 11.8125H17.0371C17.3446 11.8125 17.5996 12.0675 17.5996 12.375C17.5996 12.6825 17.3446 12.9375 17.0371 12.9375Z"
          fill="#1E293B"
        />
      </svg>
    </div>
  );
};

export default Wallet;
