import Search from "../Search";
import Indicators from "../../resources/icons/Indicators";
import TableOperations from "../TableOperations/index";
import { useDataLayerValue } from "../../../context/DataLayer";
import { useTranslation } from "react-i18next";

const HeaderSection = ({
  searchDropDownApiUrl,
  activeTab,
  setColumns,
  originalColumnsHeader,
  selectedRows,
  excelFileName,
  mutateArchiving,
  FiltersComponent,
  dispatchFiltrers,
  filtersState,
  hasArchiving,
  // temporary condition to block tool bar until its done for the page
  hasToolbar = true,
}) => {
  const {t, i18n} = useTranslation();

  return (
    <div className="grid grid-cols-2 gap-x-4 sm:gap-y-8 gap-y-4 p-4">
      <div className="col-span-2 sm:col-span-1">
        <button className="bg-sidebar-background border border-outline py-2 px-3 rounded-md sm:w-max w-full flex justify-between items-center gap-x-1">
          <div>
            <Indicators />
          </div>
          <span className="text-sm text-body font-medium">{t("show_indicators")}</span>
        </button>
      </div>
      <div className="col-span-2 sm:col-span-1">
        <SelectedRowsAmount />
      </div>

      {searchDropDownApiUrl && (
        <div className="col-span-2 sm:col-span-1">
          <Search
            searchDropDownApiUrl={searchDropDownApiUrl}
            activeTab={activeTab}
          />
        </div>
      )}
      {hasToolbar && (
        <div className="col-span-2 sm:col-span-1">
          <TableOperations
            activeTab={activeTab}
            setColumns={setColumns}
            originalColumnsHeader={originalColumnsHeader}
            selectedRows={selectedRows}
            excelFileName={excelFileName}
            mutateArchiving={mutateArchiving}
            FiltersComponent={FiltersComponent}
            dispatchFiltrers={dispatchFiltrers}
            filtersState={filtersState}
            hasArchiving={hasArchiving}
          />
        </div>
      )}
    </div>
  );
};

export default HeaderSection;

function SelectedRowsAmount() {
  const [{ selectedRows }] = useDataLayerValue();
  const {t} = useTranslation();

  return (
    <div className="text-xs font-medium text-body sm:flex sm:justify-end items-center">
      <div className="flex justify-between items-center gap-x-10 border border-outline rounded-md py-2 px-4 sm:px-9 bg-white">
        <div className="flex flex-col items-center justify-center">
          <span className="text-center">{t("selected_transactions")}</span>
          <span>{selectedRows.length}</span>
        </div>
        <div className="flex flex-col items-center justify-center">
          <span className="text-center">{t("selected_amount")}</span>
          <span>{selectedRows.amount}</span>
        </div>
      </div>
    </div>
  );
}
