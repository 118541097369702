const Moneys = () => {
  return (
    <div>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.4843 5.63029L15.4859 5.63794L15.4876 5.64554C15.5522 5.92523 15.5831 6.23618 15.5831 6.59977V10.8914C15.5831 12.0795 15.2462 12.8458 14.7346 13.3209C14.2162 13.8022 13.4398 14.0581 12.4165 14.0581H5.09147C4.74523 14.0581 4.4247 14.0272 4.13456 13.962L4.11927 13.9585L4.1038 13.9561C3.93575 13.9292 3.76803 13.8811 3.60609 13.8223C3.07507 13.6236 2.66696 13.3034 2.38572 12.8476C2.10074 12.3858 1.9248 11.7499 1.9248 10.8914V6.59977C1.9248 5.41174 2.26173 4.64543 2.77336 4.17034C3.29171 3.68902 4.06812 3.43311 5.09147 3.43311H12.4081C13.2759 3.43311 13.9682 3.61762 14.4729 3.96633C14.9666 4.30752 15.3273 4.83755 15.4843 5.63029ZM3.98314 11.1248C4.60095 11.1248 5.10814 10.6176 5.10814 9.99977V7.49977C5.10814 6.88196 4.60095 6.37477 3.98314 6.37477C3.36533 6.37477 2.85814 6.88196 2.85814 7.49977V9.99977C2.85814 10.6176 3.36533 11.1248 3.98314 11.1248ZM6.04981 8.74977C6.04981 10.2426 7.257 11.4498 8.74981 11.4498C10.2426 11.4498 11.4498 10.2426 11.4498 8.74977C11.4498 7.25696 10.2426 6.04977 8.74981 6.04977C7.257 6.04977 6.04981 7.25696 6.04981 8.74977ZM13.5081 11.1248C14.1259 11.1248 14.6331 10.6176 14.6331 9.99977V7.49977C14.6331 6.88196 14.1259 6.37477 13.5081 6.37477C12.8903 6.37477 12.3831 6.88196 12.3831 7.49977V9.99977C12.3831 10.6176 12.8903 11.1248 13.5081 11.1248Z" fill="#94A3B8" stroke="#94A3B8"/>
        <path d="M6.10213 16.3242L6.10216 16.3242L6.09488 16.3215C6.08295 16.3172 6.07109 16.3129 6.0593 16.3084H12.4081C14.0489 16.3084 15.4173 15.8187 16.3761 14.8611C17.3349 13.9035 17.8248 12.537 17.8248 10.9001V7.55943C17.9887 7.96951 18.0831 8.47627 18.0831 9.10007V13.3917C18.0831 14.5798 17.7462 15.3484 17.2332 15.8257C16.7137 16.309 15.9352 16.5667 14.9081 16.5667H7.59147C7.01431 16.5667 6.518 16.482 6.10213 16.3242Z" fill="#94A3B8" stroke="#94A3B8"/>
      </svg>
    </div>
  );
};

export default Moneys;
