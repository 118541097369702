import { useState, useEffect, useReducer, useMemo, useRef } from "react";
import { useHistory, useLocation } from "react-router";

import HeaderSection from "../../components/common/HeaderSection";
import TableTabs from "../../components/moneyRequest/TableTabs";
import Table from "../../components/common/Table";
import ExpandedRow from "../../components/pendingRequest/ExpandedRow";
import NoteSvg from "../../components/resources/note svg/NoteSvg";
import TotalRequests from "../../components/resources/total requests/TotalRequests";
import TotalValueSvg from "../../components/resources/total value svg/TotalValueSvg";
import CurrentBalanceSvg from "../../components/resources/current balance svg/CurrentBalanceSvg";

import { useDataLayerValue } from "../../context/DataLayer";
import useFetchUserBalance from "../../hooks/reactQuery/useFetchUserBalance";
import useFetchPendingRequests from "../../hooks/reactQuery/useFetchPendingRequests";

import { getColumnsHeader } from "./tableColumnsHeader";

import useMutateMoneyRequestUpdateChecked from "../../hooks/reactQuery/useMutateMoneyRequestUpdateChecked";
import { useQueryClient } from "react-query";

import actionTypes from "../../context/types";
import useFetchUserData from "./../../hooks/reactQuery/useFetchUserData";
import Filters from "../../components/paymentLicense/Filters";
import Modal from "../../components/common/Modal";
import MoneyRequest from "../../components/moneyRequest/Form";

import { useTranslation } from "react-i18next";
const pageSize = process.env.REACT_APP_PAGE_SIZE;

const PendingRequests = ({}) => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();

  const [{ activeTab, searchType, searchTerms }, dispatch] =
    useDataLayerValue();

  const columnsHeader = getColumnsHeader(activeTab, t, i18n);

  const location = useLocation();

  useEffect(() => {
    if (
      ![
        "mostafa.saady@cloudabill.com",
        "f@chickinworx.com",
        "islamalsoultan@gmail.com",
      ].includes(userData.email)
    ) {
      window.location.href = "/home";
    }
  }, [location]);

  const {
    isLoading: isTableDataLoading,
    data: tableData,
    error: tableDataError,
    isPreviousData,
  } = useFetchPendingRequests({
    activeTab,
  });

  const memoizedTableData = useMemo(
    () => (tableData ? tableData[0] : []),
    [tableData]
  );

  const queryClient = useQueryClient();

  const [expandedRows, setExpandedRows] = useState([]);

  const onTableRowExpand = (expanded, record) => {
    var keys = [];
    if (expanded) {
      keys.push(record.id);
    }
    setExpandedRows(keys);
  };

  return (
    <>
      <div className="mx-4 my-3 flex flex-col gap-y-3">
        <p className="text-xl">{t("pending_requests")}</p>
        <Table
          rowKey="id"
          columns={columnsHeader}
          data={memoizedTableData}
          isLoading={isTableDataLoading}
          error={tableDataError}
          total={tableData && tableData[1] && tableData[1][0]?.overall_count}
          hasSelection={false}
          expandedRows={expandedRows}
          setExpandedRows={setExpandedRows}
          onTableRowExpand={onTableRowExpand}
          scrollable={true}
          pageSize={pageSize}
          textEmptyPage={t("no_pending_requests")}
          expandedRow={(row) => <ExpandedRow row={row} activeTab={activeTab} />}
        />
      </div>
    </>
  );
};

export default PendingRequests;
