const SuspendedSvg = ({}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <circle cx="14.5" cy="14.5" r="10.5" fill="#EB9B54" />
      <line x1="20" y1="15" x2="8" y2="15" stroke="white" stroke-width="2" />
    </svg>
  );
};

export default SuspendedSvg;
