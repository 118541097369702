import { useState, useEffect, useMemo } from "react";
import Table from "../../common/Table";
import Modal from "../../common/Modal";
import AddMembers from "../AddMembers";
import UserAdd from "./../../resources/icons/UserAdd";
import useFetchTeam from "../../../hooks/reactQuery/useFetchTeam";
import useFetchUserData from "../../../hooks/reactQuery/useFetchUserData";
import { tableColumnsHeader } from "./tableColumnsHeader";
import { useTranslation } from "react-i18next";

const pageSize = process.env.REACT_APP_PAGE_SIZE;

const Members = () => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();

  const columnsHeader = tableColumnsHeader(
    userData.permission_tags.includes("DeactivateMember"),
    userData.permission_tags.includes("EditMemberRole"),
    userData.permission_tags.includes("DeactivateMember"),
    userData,
    t,
    i18n
  );

  const [showAddMembersModal, setShowAddMembersModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [columnKey, setColumnKey] = useState("");
  const [order, setOrder] = useState("");

  useEffect(() => {
    setCurrentPage(1);
  }, [order, columnKey]);

  const {
    isLoading: isTableDataLoading,
    data: tableData,
    error: tableDataError,
  } = useFetchTeam({ pageSize, currentPage, order, columnKey });

  const memoizedTableData = useMemo(
    () => (tableData?.users ? tableData.users : []),
    [tableData]
  );

  const handleTableChange = (pagination, filters, sorter) => {
    setOrder(sorter?.order);
    setColumnKey(sorter?.columnKey);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const [expandedRows, setExpandedRows] = useState([]);
  const onTableRowExpand = (expanded, record) => {
    var keys = [];
    if (expanded) {
      keys.push(record.id);
    }
    setExpandedRows(keys);
  };

  return (
    <div className="mx-4 my-3 flex flex-col gap-y-3">
      {userData.permission_tags.includes("AddMember") ? (
        <div className="flex justify-end items-center">
          <button
            name="addNewMemberTeamTableBtn"
            onClick={() => setShowAddMembersModal(true)}
            className="border hover:border-secondary flex justify-between items-center gap-x-2 bg-secondary bg-opacity-20 rounded-md px-4 py-2"
          >
            <div>
              <UserAdd fillColor="#3B82F6" />
            </div>
            <span className="text-sm font-bold text-secondary">
              {t("add_new_member")}
            </span>
          </button>
        </div>
      ) : null}
      <Table
        rowKey="id"
        columns={columnsHeader}
        data={memoizedTableData}
        isLoading={isTableDataLoading}
        error={tableDataError}
        pageSize={pageSize}
        currentPage={currentPage}
        total={tableData?.states?.[0].overall_count}
        hasSelection={false}
        expandedRows={expandedRows}
        setExpandedRows={setExpandedRows}
        onTableRowExpand={onTableRowExpand}
        onPageChange={onPageChange}
        onChange={handleTableChange}
        isFullHeight={true}
        //
        // Karim Makram work
        buttonEmptyPage={true}
        textEmptyPage={t("no_team")}
        textButtonEmptyPage={t("add_new_member")}
        actionEmptyPage={() => {
          document.getElementById("new-member").click();
        }}
        //
      />
      {userData.permission_tags.includes("AddMember") ? (
        <Modal
          showModal={showAddMembersModal}
          onClose={() => setShowAddMembersModal(false)}
        >
          <AddMembers onClose={() => setShowAddMembersModal(false)} />
        </Modal>
      ) : null}
    </div>
  );
};

export default Members;
