const EmptySpace = ({}) => {
  return (
    <div>
      <svg
        width={333}
        height={244}
        viewBox="0 0 333 244"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M35.3053 144.682C33.9592 147.084 31.0112 147.414 26.2878 146.958C22.736 146.613 19.4967 146.347 15.9443 144.41C13.4582 143.056 11.49 141.223 10.0535 139.443C8.49677 137.515 6.32368 135.314 7.38876 133.127C8.85275 130.124 17.3173 127.594 25.537 131.731C34.5664 136.277 36.6222 142.334 35.3053 144.682Z"
          fill="url(#paint0_linear_4664_97538)"
        />
        <path
          d="M305.2 128.835C299.696 132.94 290.962 130.545 290.962 130.545C290.962 130.545 291.156 121.495 296.663 117.394C302.167 113.29 310.897 115.681 310.897 115.681C310.897 115.681 310.703 124.731 305.2 128.835Z"
          fill="url(#paint1_linear_4664_97538)"
        />
        <path
          d="M24.6783 50.8553C29.9098 56.9105 40.4525 56.3799 40.4525 56.3799C40.4525 56.3799 42.5066 46.0324 37.2693 39.9802C32.0377 33.925 21.5002 34.4521 21.5002 34.4521C21.5002 34.4521 19.4467 44.8002 24.6783 50.8553Z"
          fill="url(#paint2_linear_4664_97538)"
        />
        <path
          d="M152.744 243.39C150.251 243.39 148.23 241.369 148.23 238.876C148.23 236.382 150.251 234.361 152.744 234.361C155.237 234.361 157.259 236.382 157.259 238.876C157.259 241.369 155.237 243.39 152.744 243.39Z"
          fill="#94A3B8"
        />
        <path
          d="M192.11 206.513C189.369 206.513 187.147 204.291 187.147 201.551C187.147 198.81 189.369 196.588 192.11 196.588C194.851 196.588 197.073 198.81 197.073 201.551C197.073 204.291 194.851 206.513 192.11 206.513Z"
          fill="#94A3B8"
        />
        <path
          d="M289.592 54.4478C287.38 54.4478 285.586 52.6541 285.586 50.4416C285.586 48.229 287.38 46.4353 289.592 46.4353C291.805 46.4353 293.599 48.229 293.599 50.4416C293.599 52.6541 291.805 54.4478 289.592 54.4478Z"
          fill="#94A3B8"
        />
        <path
          d="M10.8538 71.4579C11.562 74.101 9.99349 76.8178 7.3504 77.526C4.70731 78.2342 1.99055 76.6657 1.28233 74.0226C0.57412 71.3795 2.14265 68.6627 4.78573 67.9545C7.42882 67.2463 10.1456 68.8148 10.8538 71.4579Z"
          fill="#94A3B8"
        />
        <path
          d="M84.9985 28.9184C81.3581 28.9184 78.4071 25.9673 78.4071 22.327C78.4071 18.6867 81.3581 15.7356 84.9985 15.7356C88.6388 15.7356 91.5898 18.6867 91.5898 22.327C91.5898 25.9673 88.6388 28.9184 84.9985 28.9184Z"
          fill="#94A3B8"
        />
        <path
          d="M327.053 185.538C324.253 185.538 321.983 183.722 321.983 181.482C321.983 179.242 324.253 177.426 327.053 177.426C329.854 177.426 332.124 179.242 332.124 181.482C332.124 183.722 329.854 185.538 327.053 185.538Z"
          fill="#94A3B8"
        />
        <path
          d="M34.596 221.04C34.596 225.063 31.3354 228.323 27.3133 228.323C23.2911 228.323 20.0305 225.063 20.0305 221.04C20.0305 217.018 23.2911 213.758 27.3133 213.758C31.3354 213.758 34.596 217.018 34.596 221.04Z"
          fill="#94A3B8"
        />
        <path
          d="M278.445 175.403H278.578C279.363 186.531 287.639 186.703 287.639 186.703C287.639 186.703 278.513 186.881 278.513 199.74C278.513 186.881 269.387 186.703 269.387 186.703C269.387 186.703 277.66 186.531 278.445 175.403Z"
          fill="#94A3B8"
        />
        <path
          d="M145.719 149.423H231.715M78.1508 223.132H250.142C263.712 223.132 274.712 212.131 274.712 198.561V26.5702C274.712 13.0005 263.712 2 250.142 2H127.291C113.721 2 102.721 13.0005 102.721 26.5702V198.561C102.721 212.131 91.7204 223.132 78.1508 223.132C64.5805 223.132 53.5806 212.131 53.5806 198.561V63.4257C53.5806 49.8555 64.5805 38.8556 78.1508 38.8556H102.721L78.1508 223.132ZM145.719 51.1409H231.715H145.719ZM145.719 100.281H231.715H145.719Z"
          stroke="#94A3B8"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56.4719 129.365C56.4719 157.866 79.5741 180.968 108.074 180.968C136.574 180.968 159.676 157.866 159.676 129.365C159.676 100.866 136.574 77.7629 108.074 77.7629C79.5741 77.7629 56.4719 100.866 56.4719 129.365Z"
          fill="white"
          stroke="#3B82F6"
          strokeWidth="20.082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M45.2126 192.226L67.2612 170.178"
          stroke="#3B82F6"
          strokeWidth="20.082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M210.613 191.842V168.052C210.613 164.119 211.645 160.255 213.605 156.846C215.565 153.437 218.385 150.601 221.784 148.623L244.265 135.538C247.699 133.541 251.601 132.489 255.573 132.489C259.546 132.489 263.448 133.541 266.882 135.538L289.363 148.623C292.763 150.601 295.584 153.436 297.545 156.845C299.506 160.254 300.538 164.119 300.539 168.052V191.842C300.538 195.775 299.506 199.639 297.546 203.048C295.586 206.457 292.766 209.293 289.368 211.271L266.886 224.356C263.452 226.353 259.551 227.406 255.578 227.406C251.606 227.406 247.704 226.353 244.27 224.356L221.788 211.271C218.389 209.294 215.567 206.458 213.606 203.049C211.645 199.64 210.613 195.776 210.613 191.842Z"
          fill="#3B82F6"
        />
        <path
          d="M255.575 179.947V157.466V179.947ZM255.57 197.433C255.406 197.433 255.244 197.465 255.093 197.528C254.941 197.59 254.804 197.682 254.688 197.799C254.573 197.915 254.481 198.053 254.419 198.204C254.357 198.356 254.326 198.518 254.327 198.682C254.327 198.93 254.4 199.171 254.538 199.377C254.675 199.582 254.871 199.743 255.099 199.837C255.328 199.931 255.579 199.956 255.822 199.907C256.064 199.858 256.287 199.739 256.462 199.564C256.636 199.388 256.755 199.165 256.802 198.922C256.85 198.68 256.824 198.428 256.729 198.2C256.633 197.972 256.472 197.777 256.266 197.641C256.06 197.504 255.818 197.432 255.57 197.433Z"
          stroke="white"
          strokeWidth="13.094"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_4664_97538"
            x1="26.2369"
            y1="160.251"
            x2="14.2388"
            y2="106.337"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset={1} stopColor="#E1E4E5" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_4664_97538"
            x1="283.671"
            y1="142.434"
            x2="326.381"
            y2="93.8588"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset={1} stopColor="#F1F5F9" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_4664_97538"
            x1="45.7613"
            y1="71.7438"
            x2="9.36477"
            y2="5.72406"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset={1} stopColor="#A9B5BE" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default EmptySpace;
