export const photoPreview = (e, setFile, setImagePreviewUrl) => {
  e.preventDefault();
  const reader = new FileReader();
  const file = e.target.files[0];
  if (file) {
    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  }
};
