import TruncateNotes from "../../../components/common/truncate/TruncateNotes";
import TruncateAttachments from "./../../../components/common/truncate/TruncateAttachments";

import Dots from "../../../components/resources/icons/Dots";
import Forward from "./../../../components/resources/icons/Forward";
import KeyIcon from "../../../components/resources/icons/KeyIcon";
import TableSortIcon from "../../../components/resources/icons/TableSortIcon";
import CashAmountIcon from "../../../components/resources/icons/CashAmountIcon";
import UserAlt from "../../../components/resources/icons/UserAlt";
import CategoryIcon from "../../../components/resources/icons/CategoryIcon";
import DateIcon from "../../../components/resources/icons/DateIcon";
import Reply from "../../../components/resources/icons/Reply";
import Rejected from "../../../components/resources/icons/Rejected";
import Accepted from "../../../components/resources/icons/Accepted";
import Suspended from "../../../components/resources/icons/Suspended";

import { toolPin } from "../../../utils/toolPin";
import { formatDate } from "../../../utils/convertDate";
import TableMenu from "../../../components/common/TableMenu";
import ActiveDeactive from "../../../components/resources/icons/ActiveDeactive";

export const columnsHeaderReceived = (
  setActiveDeactiveId, 
  setShowDeactivateModal, 
  userData,
  hoverId,
  t,
  i18n
  ) => [
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <KeyIcon />
        </div>
        <div className="col-title">{t("transaction_code")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "id",
    key: "id",
    sorter: true,
    render: (text, row) => (
      <div className={`col-desc flex justify-start ${row.recent ? "font-bold" : ""}`} style={row.deactivated ? {opacity: 0.5} : {}}>
        {toolPin(String(text))}
      </div>
    ),
    width: 100,
    pinned: true,
    selected: true,
    customizeKey: t("transaction_code"),
  },
  //
  {
    title: () => (
      <div class="flex items-center">
        <div className="col-title">{t("transaction_name")}</div>
      </div>
    ),
    dataIndex: "name",
    key: "name",
    render: (text, row) => <div className="col-desc flex justify-center" style={row.deactivated ? {opacity: 0.5} : {}}>{toolPin(text)}</div>,
    width: 100,
    pinned: true,
    selected: true,
    customizeKey: t("transaction_name"),
  },
  //

  {
    title: () => (
      <div class="flex items-center">
        <div>
          <CashAmountIcon />
        </div>
        <div className="col-title">{t("amount")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "amount",
    key: "amount",
    sorter: true,
    render: (text, row) => (
      <div className="col-desc flex justify-center" style={row.deactivated ? {opacity: 0.5} : {}}>
        {toolPin(text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))} {t("egp")}
      </div>
    ),
    width: 100,
    pinned: true,
    selected: true,
    customizeKey: t("amount"),
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <UserAlt />
        </div>
        <div className="col-title">{t("sender")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "sender_name",
    key: "sender_name",
    sorter: true,
    render: (text, row) => <div className="col-desc flex justify-center" style={row.deactivated ? {opacity: 0.5} : {}}>{toolPin(text)}</div>,
    width: 125,
    selected: true,
    customizeKey: t("sender"),
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <CategoryIcon />
        </div>
        <div className="col-title">{t("category_name")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "category_name",
    key: "category_name",
    sorter: true,
    render: (text, row) => (
      <div className="flex justify-center text-xs text-body font-medium bg-sidebar-background p-2 rounded-xl flex justify-center items-center border border-transparent hover:border-secondary" style={row.deactivated ? {opacity: 0.5} : {}}>
        {toolPin(text)}
      </div>
    ),
    width: 100,
    selected: true,
    customizeKey: t("category"),
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <DateIcon />
        </div>
        <div className="col-title">{t("received_or_response_date")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "created_at",
    key: "created_at",
    sorter: true,
    render: (text, row) => (
      <div className="col-desc flex justify-center" style={row.deactivated ? {opacity: 0.5} : {}}>
        {/* {toolPin((row.response_time ? row.response_time : text).split("T")[0])} */}
        {toolPin(formatDate(row.response_time ? row.response_time : text))}
      </div>
    ),
    width: 100,
    selected: true,
    customizeKey: t("recieved_or_response_date"),
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <Reply />
        </div>
        <div className="col-title">{t("response_status")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "approved",
    key: "approved",
    sorter: true,
    render: (text, row) => (
      <div className="mr-7 flex justify-center" style={row.deactivated ? {opacity: 0.5} : {}}>
        {text === 1 ? (
          <div className="flex justify-between">
            <div className="tool-pin-container relative">
              <Accepted />
              <p className="tool-pin px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-1 right-6 z-10">
                {t("accepted")}
              </p>
            </div>
          </div>
        ) : text === 0 ? (
          <div className="flex justify-between">
            <div className="tool-pin-container relative">
              <Rejected />
              <p className="tool-pin px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-1 right-6 z-10">
                {t("rejected")}
              </p>
            </div>
          </div>
        ) : (
          <div className="flex justify-between">
            <div className="tool-pin-container relative">
              <Suspended />
              <p className="tool-pin px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-1 right-6 z-10">
                {t("transaction_pending")}
              </p>
            </div>
          </div>
        )}
      </div>
    ),
    width: 100,
    selected: true,
    customizeKey: t("response_status"),
  },

  //
  {
    title: () => (
      <div class="flex items-center">
        <div className="col-title">{t("required_response_time")}</div>
      </div>
    ),
    dataIndex: "request_date",
    key: "request_date",
    render: (text, row) => (
      <div className="col-desc flex justify-center" style={row.deactivated ? {opacity: 0.5} : {}}>
        {toolPin(text ? text.split("T")[0] : "---")}
      </div>
    ),
    width: 100,
    selected: false,
    customizeKey: t("required_response_time"),
  },
  {
    title: () => (
      <div class="flex items-center">
        <div className="col-title">{t("notes")}</div>
      </div>
    ),
    dataIndex: "notes",
    key: "notes",
    render: (text, row) => <div style={row.deactivated ? {opacity: 0.5} : {}}><TruncateNotes row={row} propertyName="notes" /></div>,
    width: 150,
    selected: false,
    customizeKey: t("notes"),
  },
  {
    title: () => (
      <div class="flex items-center">
        <div className="col-title">{t("attachments")}</div>
      </div>
    ),
    dataIndex: "original_attachments",
    key: "original_attachments",
    render: (text, row) => (
      <div style={row.deactivated ? {opacity: 0.5} : {}}>
        <TruncateAttachments row={row} propertyName="original_attachments" />
      </div>
    ),
    width: 125,
    selected: false,
    customizeKey: t("attachments"),
  },
  //
  {
    title: "",
    dataIndex: "",
    key: "",
    render: (text, row) => (
      <TableMenu 
        showButton={true}
        //showButton={hoverId == row.id}
        options={[
          {
            icon: <ActiveDeactive active={1} fill="#1E293B" />,
            text: t("suspend"),
            action: () => {
              setActiveDeactiveId(row.id);
              setShowDeactivateModal(true);
            },
            exists: row.deactivated != 1 && row.created_by == userData.id
          }
        ].filter(item => item.exists)} />
    ),
    width: 100,
    selected: true,
  },
];

export const columnsHeaderOutTable = (
  setShowPaymentLicenseModifyModal,
  setActiveRow,
  setActiveDeactiveId,
  setShowDeactivateModal, 
  userData,
  hoverId,
  t,
  i18n
) => [
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <KeyIcon />
        </div>
        <div className="col-title">{t("transaction_code")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "id",
    key: "id",
    sorter: true,
    render: (text, row) => (
      <div className={`col-desc flex justify-start ${row.recent ? "font-bold" : ""}`} style={row.deactivated ? {opacity: 0.5} : {}}>
        {toolPin(String(text))}
      </div>
    ),
    width: 100,
    pinned: true,
    selected: true,
    customizeKey: t("transaction_code"),
  },
  //
  {
    title: () => (
      <div class="flex items-center">
        <div className="col-title">{t("transaction_name")}</div>
      </div>
    ),
    dataIndex: "name",
    key: "name",
    render: (text, row) => <div className={`col-desc flex justify-start`} style={row.deactivated ? {opacity: 0.5} : {}}>{toolPin(text)}</div>,
    width: 100,
    pinned: true,
    selected: true,
    customizeKey: t("transaction_name"),
  },
  //
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <CashAmountIcon />
        </div>
        <div className="col-title">{t("amount")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "amount",
    key: "amount",
    sorter: true,
    render: (text, row) => (
      <div className="col-desc flex justify-center" style={row.deactivated ? {opacity: 0.5} : {}}>
        {toolPin(text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))} {t("egp")}
      </div>
    ),
    width: 100,
    pinned: true,
    selected: true,
    customizeKey: t("amount"),
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <UserAlt />
        </div>
        <div className={`col-title ${i18n.resolvedLanguage === "en" ? "flex justify-start" : ""}`}>{t("recipient")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "recipient_name",
    key: "recipient_name",
    sorter: true,
    render: (text, row) => <div className="col-desc" style={row.deactivated ? {opacity: 0.5} : {}}>{toolPin(text)}</div>,
    width: 125,
    selected: true,
    customizeKey: t("recipient"),
  },
  {
    title: () => (
      <div class="flex items-center justify-center">
        <div>
          <CategoryIcon />
        </div>
        <div className="col-title">{t("category_name")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "category_name",
    key: "category_name",
    sorter: true,
    render: (text, row) => (
      <div className=" flex justify-center text-xs text-body font-medium bg-sidebar-background p-2 rounded-xl flex justify-center items-center border border-transparent hover:border-secondary" style={row.deactivated ? {opacity: 0.5} : {}}>
        {toolPin(text)}
      </div>
    ),
    width: 100,
    selected: true,
    customizeKey: t("category"),
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <DateIcon />
        </div>
        <div className="col-title">{t("sent_or_response_date")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "created_at",
    key: "created_at",
    sorter: true,
    render: (text, row) => (
      <div className={`col-desc flex justify-center`} style={row.deactivated ? {opacity: 0.5} : {}}>
        {toolPin((row.response_time ? row.response_time : text).split("T")[0])}
      </div>
    ),
    width: 100,
    selected: true,
    customizeKey: t("sent_or_response_date"),
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <Reply />
        </div>
        <div className="col-title">{t("response_status")}</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "approved",
    key: "approved",
    sorter: true,
    render: (text, row) => {
      return (
        <div className="mr-7 flex justify-center" style={row.deactivated ? {opacity: 0.5} : {}}>
          {text === 1 ? (
            <div className="flex justify-between items-center">
              <div className="tool-pin-container relative">
                <Accepted />
                <p className="tool-pin px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-1 right-6 z-10">
                  {t("accepted")}
                </p>
              </div>
              {row.forwarded === 1 && (
                <div className="transform -rotate-90">
                  <Forward />
                </div>
              )}
              {row.approved === 1 && !row.supporting_attachments && (
                <button
                  className="tool-pin-container relative"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowPaymentLicenseModifyModal(true);
                    setActiveRow(row);
                  }}
                >
                  <div>
                    <Dots />
                  </div>
                  <p className="tool-pin px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute -top-2 left-6 z-10">
                    {t("add_attachments")} 
                  </p>
                </button>
              )}
            </div>
          ) : text === 0 ? (
            <div className="flex justify-between items-center">
              <div className="tool-pin-container relative">
                <Rejected />
                <p className="tool-pin px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-1 right-6 z-10">
                  {t("rejected")}
                </p>
              </div>
              {row.forwarded === 1 && (
                <div className="transform -rotate-90">
                  <Forward />
                </div>
              )}
            </div>
          ) : (
            <div className="flex justify-between items-center">
              <div className="tool-pin-container relative">
                <Suspended />
                <p className="tool-pin px-3 py-2 rounded text-white bg-gray-800 opacity-75 hidden absolute top-1 right-6 z-10">
                  {t("transaction_pending")}
                </p>
              </div>
              {row.forwarded === 1 && (
                <div className="transform -rotate-90">
                  <Forward />
                </div>
              )}
            </div>
          )}
        </div>
      );
    },
    width: 100,
    selected: true,
    customizeKey: t("response_status"),
  },
  //
  {
    title: () => (
      <div class="flex items-center">
        <div className="col-title">{t("required_response_time")}</div>
      </div>
    ),
    dataIndex: "request_date",
    key: "request_date",
    render: (text, row) => (
      <div className="col-desc" style={row.deactivated ? {opacity: 0.5} : {}}>
        {toolPin(text ? text.split("T")[0] : "---")}
      </div>
    ),
    width: 100,
    selected: false,
    customizeKey: t("required_response_time"),
  },
  {
    title: () => (
      <div class="flex items-center">
        <div className="col-title">{t("notes")}</div>
      </div>
    ),
    dataIndex: "notes",
    key: "notes",
    render: (text, row) => <div style={row.deactivated ? {opacity: 0.5} : {}}><TruncateNotes row={row} propertyName="notes" /></div>,
    width: 150,
    selected: false,
    customizeKey: t("notes"),
  },
  {
    title: () => (
      <div class="flex items-center">
        <div className="col-title">{t("attachments")}</div>
      </div>
    ),
    dataIndex: "original_attachments",
    key: "original_attachments",
    render: (text, row) => (
      <div style={row.deactivated ? {opacity: 0.5} : {}}>
        <TruncateAttachments row={row} propertyName="original_attachments" />
      </div>
    ),
    width: 125,
    selected: false,
    customizeKey: t("attachments"),
  },
  {
    title: "",
    dataIndex: "",
    key: "",
    render: (text, row) => (
     <TableMenu
        showButton={true} 
        //showButton={hoverId == row.id}
        options={[
          {
            icon: <ActiveDeactive active={1} fill="#1E293B" />,
            text: t("suspend"),
            action: () => {
              setActiveDeactiveId(row.id);
              setShowDeactivateModal(true);
            },
            exists: row.deactivated != 1 && row.created_by == userData.id
          }
        ].filter(item => item.exists)} 
      />
    ),
    width: 100,
    selected: true,
  },
  //
];
