import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useMutateAddNewRole(
  onClose,
  addNewRoleCheckbox,
  reset,
  setShowAlertConfirmModal,
  userData,
  setPerms,
  setAddNewRoleCheckbox
) {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      const formData = new FormData();

      formData.append("company_id", userData.activeCompanyId);
      formData.append("name", data.roleName);
      formData.append("en_name", data.en_name);
      formData.append("permissions", JSON.stringify(data.permissions));

      return apiClient.post(`role`, formData);
    },
    {
      onSuccess: () => {
        toast.info("تم إضافة الدور بنجاح.");
        queryClient.invalidateQueries("getAllRoles");
        setShowAlertConfirmModal(false);
        if (addNewRoleCheckbox) {
          reset();
          setPerms((prevPerms) =>
            prevPerms?.map((el) => ({
              ...el,
              permissions: el.permissions?.map((elem) => ({
                ...elem,
                selected: false,
              })),
            }))
          );
          setAddNewRoleCheckbox(false);
        } else {
          onClose();
        }
      },
      onError: (error) => {
        onClose();
        return error;
      },
    }
  );
}
