import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect, useMemo } from "react";

function Icon(props) {
  const { className, isButton, fillColor, ...restOfProps } = props;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <svg
      onMouseEnter={() => {
        if (isButton) {
          setIsHovered(true);
        }
      }}
      onMouseLeave={() => {
        if (isButton) {
          setIsHovered(false);
        }
      }}
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {isButton && isHovered ? (
        <rect width={32} height={32} rx={16} fill="#E7F0FF" />
      ) : null}
      <path
        d="M14.4093 5.25578L9.18721 7.31303C7.98372 7.78609 7 9.28227 7 10.6354V18.8095C7 20.1076 7.81629 21.8128 8.81048 22.5939L13.3105 26.1254C14.7861 27.2915 17.2139 27.2915 18.6895 26.1254L23.1896 22.5939C24.1837 21.8128 25 20.1076 25 18.8095V10.6354C25 9.28227 24.0163 7.78609 22.8128 7.31303L17.5907 5.25578C16.7012 4.91474 15.2779 4.91474 14.4093 5.25578Z"
        stroke={
          fillColor == null
            ? isButton && isHovered
              ? "#3B82F6"
              : "#1E293B"
            : fillColor
        }
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 16H19"
        stroke={
          fillColor == null
            ? isButton && isHovered
              ? "#3B82F6"
              : "#1E293B"
            : fillColor
        }
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 19V13"
        stroke={
          fillColor == null
            ? isButton && isHovered
              ? "#3B82F6"
              : "#1E293B"
            : fillColor
        }
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
};

export default Icon;
