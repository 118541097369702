const DocumentsBox = ({fill}) => {
  return (
    <div>
      <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="46" height="46" rx="16" fill="#FFD4D4"/>
      <path d="M14.2981 32.4015L14.3004 32.3997L16.01 31.12C16.01 31.12 16.0101 31.1199 16.0101 31.1199L16.0104 31.1197L16.31 31.52C16.71 31.22 17.27 31.26 17.63 31.62L14.2981 32.4015ZM14.2981 32.4015C13.9703 32.6498 13.5 32.4205 13.5 32V18V17C13.5 15.9602 13.7364 15.0967 14.2534 14.498C14.7586 13.9131 15.6024 13.5 17 13.5H18H27.0026M14.2981 32.4015L27.0026 13.5M27.0026 13.5C26.6873 13.9184 26.5 14.4382 26.5 15V32C26.5 32.4052 26.0316 32.6455 25.6987 32.399C25.6985 32.3988 25.6983 32.3987 25.698 32.3985L23.992 31.1215C23.3997 30.6681 22.5601 30.7328 22.0364 31.2564L20.3564 32.9364C20.1618 33.1311 19.8386 33.1312 19.6439 32.9368C19.6438 32.9366 19.6437 32.9365 19.6436 32.9364L27.0026 13.5ZM15.47 24.01C15.47 24.8361 16.1439 25.51 16.97 25.51C17.7961 25.51 18.47 24.8361 18.47 24.01C18.47 23.1839 17.7961 22.51 16.97 22.51C16.1439 22.51 15.47 23.1839 15.47 24.01ZM15.47 20.01C15.47 20.8361 16.1439 21.51 16.97 21.51C17.7961 21.51 18.47 20.8361 18.47 20.01C18.47 19.1839 17.7961 18.51 16.97 18.51C16.1439 18.51 15.47 19.1839 15.47 20.01ZM20 25.26H23C23.6861 25.26 24.25 24.6961 24.25 24.01C24.25 23.3239 23.6861 22.76 23 22.76H20C19.3139 22.76 18.75 23.3239 18.75 24.01C18.75 24.6961 19.3139 25.26 20 25.26ZM20 21.26H23C23.6861 21.26 24.25 20.6961 24.25 20.01C24.25 19.3239 23.6861 18.76 23 18.76H20C19.3139 18.76 18.75 19.3239 18.75 20.01C18.75 20.6961 19.3139 21.26 20 21.26Z" fill="#FF805C" stroke="#FF805C"/>
      <path d="M31.1172 14.8701L31.1173 14.87L31.1096 14.8626C30.6767 14.439 30.1169 14.1476 29.51 14.0429V13.5396C30.2709 13.6532 30.9499 14.0052 31.4764 14.5236C32.1083 15.1654 32.5 16.0404 32.5 17V19.42C32.5 20.103 32.286 20.6119 31.9489 20.9489C31.6119 21.286 31.103 21.5 30.42 21.5H27.5V15.01C27.5 14.3514 27.9218 13.7911 28.51 13.5848V14.133C28.2058 14.3074 28 14.6355 28 15.01V20.5V21H28.5H30.42C30.8596 21 31.2867 20.9014 31.5961 20.5898C31.9047 20.2789 32 19.8528 32 19.42V17C32 16.205 31.6858 15.4506 31.1172 14.8701Z" fill="#FF805C" stroke="#FF805C"/>
      </svg>
    </div>
  );
};

export default DocumentsBox;
