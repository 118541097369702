import React from 'react'
import EmptySpace from '../../resources/icons/EmptySpace'
import { useTranslation } from 'react-i18next'
const TableResult = ({ Icon, title, text, button, buttonText, action ,color}) => {
  const {t, i18n} = useTranslation();
  return (
    <div className='flex flex-col justify-center items-center'>
      {Icon ? <Icon /> : <EmptySpace />}
      <h2 className='mt-8 text-2xl font-bold'>{title}</h2>
      <p
        className='mt-4 font-medium text-base text-center text-side-icons'
        style={{color,width:"24rem"}}
      >
        {text}
      </p>
      {button && (
        <div className='flex  justify-center px-4  bg-white mt-4 text-right sm:px-6 w-full'>
          <button
            type='button'
            onClick={action}
            className={`bg-blue-500 hover:bg-blue-700 
                 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex justify-start`}
            style={{ width: '28rem' }}
          >
            {buttonText}
          </button>
        </div>
      )}
    </div>
  )
}

export default TableResult
