import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchDocsCount(finId) {
  return useQuery(["docsCount", finId], () => {
    return apiClient
      ?.get(`document/finlength`, {
        params: {
          id: finId,
        },
      })
      .then(({ data }) => data[0]?.docsCount)
      .catch((e) => e);
  });
}
