import PropTypes from "prop-types";

function Icon(props) {
  const { className, fillColor, ...restOfProps } = props;
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...restOfProps}
    >
      <path
        d="M14.1665 17.7077H5.83317C2.7915 17.7077 1.0415 15.9577 1.0415 12.916V7.08268C1.0415 4.04102 2.7915 2.29102 5.83317 2.29102H14.1665C17.2082 2.29102 18.9582 4.04102 18.9582 7.08268V12.916C18.9582 15.9577 17.2082 17.7077 14.1665 17.7077ZM5.83317 3.54102C3.44984 3.54102 2.2915 4.69935 2.2915 7.08268V12.916C2.2915 15.2993 3.44984 16.4577 5.83317 16.4577H14.1665C16.5498 16.4577 17.7082 15.2993 17.7082 12.916V7.08268C17.7082 4.69935 16.5498 3.54102 14.1665 3.54102H5.83317Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
      <path
        d="M10 13.125C8.275 13.125 6.875 11.725 6.875 10C6.875 8.275 8.275 6.875 10 6.875C11.725 6.875 13.125 8.275 13.125 10C13.125 11.725 11.725 13.125 10 13.125ZM10 8.125C8.96667 8.125 8.125 8.96667 8.125 10C8.125 11.0333 8.96667 11.875 10 11.875C11.0333 11.875 11.875 11.0333 11.875 10C11.875 8.96667 11.0333 8.125 10 8.125Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
      <path
        d="M3.75 8.95833C3.40833 8.95833 3.125 8.675 3.125 8.33333V7.08333C3.125 5.59167 4.34167 4.375 5.83333 4.375H7.08333C7.425 4.375 7.70833 4.65833 7.70833 5C7.70833 5.34167 7.425 5.625 7.08333 5.625H5.83333C5.03333 5.625 4.375 6.28333 4.375 7.08333V8.33333C4.375 8.675 4.09167 8.95833 3.75 8.95833Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
      <path
        d="M16.2498 8.95833C15.9082 8.95833 15.6248 8.675 15.6248 8.33333V7.08333C15.6248 6.28333 14.9665 5.625 14.1665 5.625H12.9165C12.5748 5.625 12.2915 5.34167 12.2915 5C12.2915 4.65833 12.5748 4.375 12.9165 4.375H14.1665C15.6582 4.375 16.8748 5.59167 16.8748 7.08333V8.33333C16.8748 8.675 16.5915 8.95833 16.2498 8.95833Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
      <path
        d="M7.08333 15.6243H5.83333C4.34167 15.6243 3.125 14.4077 3.125 12.916V11.666C3.125 11.3243 3.40833 11.041 3.75 11.041C4.09167 11.041 4.375 11.3243 4.375 11.666V12.916C4.375 13.716 5.03333 14.3743 5.83333 14.3743H7.08333C7.425 14.3743 7.70833 14.6577 7.70833 14.9993C7.70833 15.341 7.425 15.6243 7.08333 15.6243Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
      <path
        d="M14.1665 15.6243H12.9165C12.5748 15.6243 12.2915 15.341 12.2915 14.9993C12.2915 14.6577 12.5748 14.3743 12.9165 14.3743H14.1665C14.9665 14.3743 15.6248 13.716 15.6248 12.916V11.666C15.6248 11.3243 15.9082 11.041 16.2498 11.041C16.5915 11.041 16.8748 11.3243 16.8748 11.666V12.916C16.8748 14.4077 15.6582 15.6243 14.1665 15.6243Z"
        fill={fillColor ? fillColor : "#1E293B"}
      />
    </svg>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
};

export default Icon;
