import { useMutation } from "react-query";

import apiClient from "../../services/apiClient";

export default function useMutateInvitedUser(history) {
  return useMutation(
    ({ data, userData }) => {
      const formData = new FormData();

      formData.append("first_name", data.firstName);
      formData.append("last_name", data.lastName);
      formData.append("user_name", data.userName);
      formData.append("password", data.password);
      data.file && formData.append("photo", data.file);

      formData.append("email", userData.email);
      formData.append("role", userData.role);
      formData.append("first_time_owner", 0);
      formData.append("invitation_id", data.invitation_id);

      return apiClient.post("user/signup", formData);
    },
    {
      onSuccess: () => {
        history.push({
          pathname: "home",
          state: { userExists: true },
        });
      },
    }
  );
}
