import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchUserExistsForRFA({ userName }) {
  return useQuery(
    ["useFetchUserExistsForRFA", userName],
    () => {
      const params = {};
      params.username = userName;

      return apiClient
        .get("/request_for_access/checkifexists", {
          params,
        })
        .then(({ data }) => data)
        .catch((e) => e);
    },
    { keepPreviousData: false, retry: false }
  );
}
