const CashinBox = ({fill}) => {
  return (
    <div>
      <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="46" height="46" rx="16" fill="#C6EFDA"/>
        <path d="M30.97 19.75H28.03C26.76 19.75 26 18.99 26 17.72V14.78C26 13.51 26.76 12.75 28.03 12.75H30.97C32.24 12.75 33 13.51 33 14.78V17.72C33 18.99 32.24 19.75 30.97 19.75ZM31.19 16.44C31.07 16.32 30.91 16.26 30.75 16.26C30.59 16.26 30.43 16.32 30.31 16.44L30.13 16.62V14.38C30.13 14.03 29.85 13.75 29.5 13.75C29.15 13.75 28.87 14.03 28.87 14.38V16.62L28.69 16.44C28.45 16.2 28.05 16.2 27.81 16.44C27.57 16.68 27.57 17.08 27.81 17.32L29.06 18.57C29.11 18.62 29.18 18.66 29.25 18.69C29.27 18.7 29.29 18.7 29.31 18.71C29.36 18.73 29.41 18.74 29.47 18.74C29.49 18.74 29.51 18.74 29.53 18.74C29.6 18.74 29.66 18.73 29.73 18.7C29.74 18.7 29.74 18.7 29.75 18.7C29.82 18.67 29.88 18.63 29.93 18.58C29.94 18.57 29.94 18.57 29.95 18.57L31.2 17.32C31.44 17.08 31.44 16.68 31.19 16.44Z" fill="#42EBB3"/>
        <path d="M13 22.46V27.46C13 29.75 14.85 31.6 17.14 31.6H28.85C31.14 31.6 33 29.74 33 27.45V22.46C33 21.79 32.46 21.25 31.79 21.25H14.21C13.54 21.25 13 21.79 13 22.46ZM19 28.25H17C16.59 28.25 16.25 27.91 16.25 27.5C16.25 27.09 16.59 26.75 17 26.75H19C19.41 26.75 19.75 27.09 19.75 27.5C19.75 27.91 19.41 28.25 19 28.25ZM25.5 28.25H21.5C21.09 28.25 20.75 27.91 20.75 27.5C20.75 27.09 21.09 26.75 21.5 26.75H25.5C25.91 26.75 26.25 27.09 26.25 27.5C26.25 27.91 25.91 28.25 25.5 28.25Z" fill="#42EBB3"/>
        <path d="M24.5 15.61V18.54C24.5 19.21 23.96 19.75 23.29 19.75H14.21C13.53 19.75 13 19.19 13 18.52C13.01 17.39 13.46 16.36 14.21 15.61C14.96 14.86 16 14.4 17.14 14.4H23.29C23.96 14.4 24.5 14.94 24.5 15.61Z" fill="#42EBB3"/>
      </svg>
    </div>
  );
};

export default CashinBox;
