import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchLatestCategories() {
  return useQuery("lastcategories", () =>
    apiClient
      ?.get("spend_request/lastcategories")
      .then(({ data }) =>
        data.map((category) => {
          return {
            ...category,
            label: category.name,
            value: category.id,
          };
        })
      )
      .catch((e) => e)
  );
}
