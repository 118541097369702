import AcceptedSvg from "../../components/resources/accepted svg/AcceptedSvg";
import RejectedSvg from "../../components/resources/rejected svg/RejectedSvg";
import SuspendedSvg from "../../components/resources/suspended svg/SuspendedSvg";
import CurrentSvg from "../../components/resources/current svg/CurrentSvg";
import FinishedSvg from "../../components/resources/finished svg/FinishedSvg";
import { convertDate, formatDate } from "../../utils/convertDate";
import Health from "../../components/resources/icons/Health";
import TableSortIcon from "../../components/resources/icons/TableSortIcon";
import Key from "../../components/resources/icons/Key";
import { toolPin } from "../../utils/toolPin";
import DataBase from "../../components/resources/icons/Database";
import Cash from "../../components/resources/icons/Cash";
import SafeBox from "../../components/resources/icons/SafeBox";
import DateIcon from "../../components/resources/icons/DateIcon";
import UsersAvatars from "../../components/common/UsersAvatars.jsx/index.js";
import UserTag from "../../components/resources/icons/UserTag";

export const columnsHeaderArray = () => [
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <Key />
        </div>
        <div className="col-title">كود العملية</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "id",
    key: "id",
    width: 100,
    sorter: true,
    render: (text) => <div className="flex justify-center items-center">{text}</div>
  },
  {
    title: (
      <div class="flex items-center justify-center">
        <div>
          <Cash />
        </div>
        <div className="col-title">رصيد العهدة الحالي</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "remaining_amount",
    key: "remaining_amount",
    width: 100,
    sorter: true,
    render: (text) => <div className="flex justify-center items-center">
      {new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(text).toString().replace(/EGP/, "") + " "}
      ج.م
    </div>,
  },
  {
    title: (
      <div class="flex items-center justify-center">
        <div>
          <DataBase />
        </div>
        <div className="col-title">التصنيف</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "category_name",
    key: "category",
    width: 100,
    sorter: true,
    render: (text, row) => <div className="flex justify-center items-center">
      <div style={{
        backgroundColor: "#F8FAFC",
        borderRadius: "10px",
        padding: "7px"
      }}>{row.category.name}</div>
    </div>,
  },
  {
    title: (
      <div class="flex items-center justify-center">
        <div>
          <DataBase />
        </div>
        <div className="col-title">مصدر العملية</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "safe",
    key: "safe",
    width: 100,
    render: (text, row) => <div className="flex justify-center items-center">
      {
        row.type == "CASHOUT_WITHOUT_PERMISSION" ? <div style={{
          backgroundColor: "#F8FAFC",
          borderRadius: "10px",
          padding: "7px"
        }}>{row.safe.name}</div>
        : row.type == "CASHOUT_WITH_PERMISSION" ? <UsersAvatars usersArr={[row.recipient_user]} />
        : <div style={{
          backgroundColor: "#F8FAFC",
          borderRadius: "10px",
          padding: "7px"
        }}>{row.parent_money_request ? row.parent_money_request.name : row.safe.name}</div>
      }
    </div>,
  },
  {
    title: (
      <div class="flex items-center justify-center">
        <div>
          <DateIcon />
        </div>
        <div className="col-title">تاريخ الانشاء</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "creation_date",
    key: "creation_date",
    width: 250,
    sorter: true,
    sorter: (a, b) => {},
    render: (text, row, index) => {
      return (
        <div className="flex justify-center items-center">
          {convertDate(row.creation_date)}
        </div>
      );
    },
  },
  {
    title: () => (
      <div class="flex items-center">
        <div>
          <Health />
        </div>
        <div className="col-title">حالة العهدة</div>
        <div>
          <TableSortIcon />
        </div>
      </div>
    ),
    dataIndex: "approved",
    key: "approved opration",
    sorter: true,
    render: (text, row) => (
      <div className="col-desc">
        {text === 1 && row.status === 0 ? (
          <span className="text-xs font-medium text-confirmation py-1.5 px-2.5 bg-confirmation bg-opacity-20 rounded-xl">
            تمت التصفية
          </span>
        ) : text === 1 ? (
          <span className="text-xs font-medium text-secondary py-1.5 px-2.5 bg-secondary bg-opacity-20 rounded-xl">
            قيد التنفيذ
          </span>
        ) : text === 0 ? (
          <span className="text-xs font-medium text-danger py-1.5 px-2.5 bg-danger bg-opacity-20 rounded-xl">
            مرفوض
          </span>
        ) : (
          <div className="tool-pin-container relative">
            <span className="text-xs font-medium text-onhold py-1.5 px-2.5 bg-onhold bg-opacity-20 rounded-xl">
              معلق
            </span>
          </div>
        )}
      </div>
    ),
    width: 150,
  }
];
