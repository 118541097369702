import { Fragment, useEffect, useState } from "react";

import { Link, useHistory } from "react-router-dom";

import Modal from "../../components/common/Modal";
import HomeVideo from "../../components/resources/home video/HomeVideo";
import Login from "../../components/auth/Login";
import { useQuery } from "react-query";
import apiClient from "../../services/apiClient";
import ResetPassword from "../../components/auth/ResetPassword";
import { useTranslation } from "react-i18next";

const Home = ({ location }) => {
  const {t, i18n} = useTranslation();
  const history = useHistory();
  window.history.replaceState(null, "");

  let pass = location.search
    ? location.search.split("?")[1].split("=")[1]
    : null;

  useEffect(() => {
    if (pass) setShowResetModal(true);
  }, [pass]);
  useEffect(() => {
    if (history.location.state) setShowLoginModal(true);
  }, [history.location]);

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);

  const CSRFToken = useQuery(
    "CSRFToken",
    () => {
      return apiClient.get("/getCSRFToken");
    },
    {
      onSuccess(response) {
        const cookieString = `CSRFToken=${response.data.CSRFToken}`;
        document.cookie = cookieString;
      },
    }
  );

  return (
    <div className="bg-gray-50 h-screen">
      <div className="bg-gray-50 max-w-screen-2xl mx-auto">
        <div className="flex justify-between items-center p-4">
          <h1 className="text-lg text-green-300 font-bold">Trustbill</h1>
          <Fragment>
            <div className="flex justify-center items-center" style={{width: "auto"}}>
              <button className="change-language mx-2" onClick={() => {
                  i18n.changeLanguage(i18n.resolvedLanguage === "ar" ? "en" : "ar");
                  window.location.href = "/";
              }}>{i18n.resolvedLanguage === "ar" ? "English" : "العربية"}</button>
              <button
                type="button"
                className="border border-gray-800 px-4 py-1 rounded-3xl text-base font-bold hover:bg-black hover:text-white"
                onClick={() => setShowLoginModal(true)}
                style={{whiteSpace: "nowrap"}}
              >
                {t("login")}
              </button>
            </div>

            <Modal
              showModal={showLoginModal}
              onClose={() => setShowLoginModal(false)}
            >
              <Login onClose={() => setShowLoginModal(false)} />
            </Modal>

            <Modal
              showModal={showResetModal}
              onClose={() => setShowResetModal(false)}
            >
              <ResetPassword
                pass={pass}
                onClose={() => setShowResetModal(false)}
              />
            </Modal>
          </Fragment>
        </div>

        <hr />

        <div className="flex flex-wrap p-4">
          <div className="flex flex-col md:w-6/12 w-full">
            <h4 className="text-3xl font-bold text-center md:text-justify mt-10 md:mt-20">
              {t("home_text_1")}
            </h4>
            <p className="text-2xl text-gray-400 text-center md:text-justify mt-5 mb-10 md:mt-10 md:mb-0">
              {t("home_text_2")}
            </p>
            <Link
              className="order-first md:order-last w-full md:w-max border border-gray-800 px-4 py-1 rounded-3xl text-lg text-white bg-black font-bold hover:bg-white hover:text-black mt-5 md:mt-20"
              to="/register"
            >
              {t("join_us")}
            </Link>
          </div>
          <div className="md:w-6/12 w-full flex justify-center items-center">
            <HomeVideo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
