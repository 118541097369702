const BluePlay = () => {
  return (
    <div>
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="24" fill="#E7F0FF"/>
        <path d="M21.0996 24V22.52C21.0996 20.61 22.4496 19.84 24.0996 20.79L25.3796 21.53L26.6596 22.27C28.3096 23.22 28.3096 24.78 26.6596 25.73L25.3796 26.47L24.0996 27.21C22.4496 28.16 21.0996 27.38 21.0996 25.48V24Z" stroke="#3B82F6" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M24 34C29.5228 34 34 29.5228 34 24C34 18.4772 29.5228 14 24 14C18.4772 14 14 18.4772 14 24C14 29.5228 18.4772 34 24 34Z" stroke="#3B82F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  );
};

export default BluePlay;
