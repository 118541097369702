const CollapseArrow = ({ fillColor }) => {
  return (
    <div>
      <svg
        width={21}
        height={21}
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.9916 6.50549L10.9807 14.5L6.99158 6.49458L14.9916 6.50549Z"
          fill={fillColor || "#3B82F6"}
        />
      </svg>
    </div>
  );
};

export default CollapseArrow;
