const UserTag = () => {
  return (
    <div>
      <svg
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.5 14.1453H12.93C12.33 14.1453 11.76 14.3778 11.34 14.7978L10.0575 16.0653C9.47248 16.6428 8.52001 16.6428 7.93501 16.0653L6.6525 14.7978C6.2325 14.3778 5.655 14.1453 5.0625 14.1453H4.5C3.255 14.1453 2.25 13.1478 2.25 11.9178V3.73529C2.25 2.50529 3.255 1.50781 4.5 1.50781H13.5C14.745 1.50781 15.75 2.50529 15.75 3.73529V11.9178C15.75 13.1403 14.745 14.1453 13.5 14.1453Z"
          stroke="#1E293B"
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.00142 7.49889C9.96654 7.49889 10.7489 6.71649 10.7489 5.75137C10.7489 4.78626 9.96654 4.00391 9.00142 4.00391C8.0363 4.00391 7.25391 4.78626 7.25391 5.75137C7.25391 6.71649 8.0363 7.49889 9.00142 7.49889Z"
          stroke="#1E293B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 11.7457C12 10.3957 10.6575 9.30078 9 9.30078C7.3425 9.30078 6 10.3957 6 11.7457"
          stroke="#1E293B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default UserTag;
