const HomeVideo = ({}) => {
  return (
    <video autoPlay loop className="max-w-full">
      <source src="img/tarbeeta-landing.mp4" type="video/mp4" />
      Your browser is not supported!
    </video>
  );
};

export default HomeVideo;
