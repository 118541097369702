import { useState, useMemo, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Table from "../../components/common/Table";
import ExpandedRowWithPerm from "../../components/safe/Deposit/preview/ExpandedRowWithPerm/index";
import ExpandedRowWithoutPerm from "../../components/safe/Deposit/preview/ExpandedRowWithoutPerm/index";
import ExpandedRowFullControl from "../../components/safe/Deposit/preview/ExpandedRowFullControl/index";

import useFetchUserData from "../../hooks/reactQuery/useFetchUserData";
import useFetchSingleCashin from "../../hooks/reactQuery/useFetchSingleCashin";

import { columnsHeader } from "../SingleSafe/Tables/Deposit/DepositFullControl/tableColumnsHeader";
import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";

import { useTranslation } from "react-i18next";

const SingleCashin = () => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();

  const params = useParams();
  const safeId = params.safeId;
  const cashinId = params.id;

  const location = useLocation();

  const history = useHistory();

  const currentSafe = userData.safes.find(safe => safe.id == safeId && safe.active == 1);
  const [columnsHeaderState, setColumnsHeader] = useState(columnsHeader(t, i18n));
  const [selectedRows, setSelectedRows] = useState([]);

  const {
    isLoading: isTableLoading,
    data: tableData,
    error: tableError,
  } = useFetchSingleCashin(
    cashinId
  );

  const memoizedTableData = useMemo(
    () => (tableData ? tableData : []),
    [tableData]
  );


  const [expandedRows, setExpandedRows] = useState([]);

  const onTableRowExpand = (expanded, record) => {
    var keys = [];
    if (expanded) {
      keys.push(record.id);
    }
    setExpandedRows(keys);
  };

  useEffect(() => {
    if(tableData) setExpandedRows([tableData[0].id]);
  }, [tableData]);

  return (
    <>
    {location.state && <Breadcrumb items={[{title: location.state.comingFrom, action: () => history.push(location.state.fallback)}, {title: location.state.title, action: () => {}}]} />}
      
    <div className="mx-4 my-3">
      <Table
        rowKey="id"
        data={memoizedTableData}
        columns={columnsHeaderState}
        isLoading={isTableLoading}
        error={tableError}
        total={1}
        setSelectedRows={setSelectedRows}
        onTableRowExpand={onTableRowExpand}
        expandedRows={expandedRows}
        setExpandedRows={setExpandedRows}
        expandedRow={(row) => 
          currentSafe.permissions.includes("CashInWithPermission") ? 
          <ExpandedRowWithPerm row={row} safeId={safeId} />:
          currentSafe.permissions.includes("CashFullControl") ?
          <ExpandedRowFullControl row={row} safeId={safeId} /> :
          currentSafe.permissions.includes("CashInWithoutPermission") ?
          <ExpandedRowWithoutPerm row={row} safeId={safeId} />:
          <ExpandedRowWithPerm row={row} safeId={safeId} />
        }
        isPaginationDisabled={true}
        pageSize={1}
        currentPage={1}
        hasFooter={false}
        hasSelection={false}
      />
    </div>
    </>
  );
};

export default SingleCashin;
