import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useMutateEditSafe(onClose, userData) {
  const queryClient = useQueryClient();

  return useMutation(
    (data) => {
      const formData = new FormData();
      formData.append("users", JSON.stringify(data.users));
      formData.append("safe_id", data.safe_id);
      formData.append("manager_id", data.manager_id);
      formData.append("name", data.name);
      formData.append("code", data.code);
      formData.append("en_name", data.en_name);

      return apiClient.put(`safe?company_id=${localStorage.getItem("activeCompanyId")}&safe_id=${data.safe_id}`, formData);
    },
    {
      onSuccess: (response, data) => {
        toast.info("تم تعديل الخزينة بنجاح");
        queryClient.invalidateQueries("getAllSafes");
        queryClient.invalidateQueries("userData");
        queryClient.invalidateQueries("getLastUpdates");
        onClose();
      },
      onError: (error) => {
        onClose();
        return error;
      },
    }
  );
}
