const ArrowUp = ({fill}) => {
  return (
    <div>
      <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.981 1L7.82109 7.15986L5.47448 3.63994L0.78125 8.33317" stroke={fill ? fill : "#59A0FF"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.4883 1H14.4215V3.93327" stroke={fill ? fill : "#59A0FF"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  );
};

export default ArrowUp;
