import { useState, useEffect } from "react";

import Shapes from "../../../Shapes";
import Spinner from "../../../Spinner";
import Close from "../../../../resources/icons/Close";
import Search from "./../../../../resources/icons/Search";
import { usersSecondFormatOptionLabel } from "../../../../../utils/markUp";
import { useTranslation } from "react-i18next";

const UsersShapes = ({ isUsersLoading, users, setUsers }) => {
  let [searchField, setSearchField] = useState("");
  const {t, i18n} = useTranslation();


  const handleSearchFieldChange = (e) => {
    let value = e.target.value;
    setSearchField(value);
  };
  const handleClearSearchField = () => {
    setSearchField("");
  };

  useEffect(() => {
    setUsers((prevUsers) => {
      return prevUsers?.map((user) => {
        if (user.name.includes(searchField)) {
          user.isShown = true;
        } else {
          user.isShown = false;
        }
        return user;
      });
    });
  }, [searchField]);

  return isUsersLoading ? (
    <div className="flex justify-center items-center p-6">
      <div className="w-6 h-6">
        <Spinner />
      </div>
    </div>
  ) : (
    <div className="grid grid-cols-3 gap-y-7 py-5 px-6" dir={i18n.resolvedLanguage === "en" ? "ltr" : "rtl"}>
      <div className="relative col-span-1">
        <input
          type="text"
          name="searchField"
          value={searchField}
          onChange={(e) => handleSearchFieldChange(e)}
          placeholder={t("search_placeholder")}
          className="px-5 border-outline focus:border-secondary rounded-md w-full text-sm font-medium text-side-icons"
          autoComplete="off"
        />
        <div className="absolute top-2.5 right-3">
          <Search />
        </div>
        <button
          className={`absolute top-3 left-3 transform scale-75 ${
            !searchField ? "hidden" : ""
          }`}
          onClick={handleClearSearchField}
        >
          <Close />
        </button>
      </div>
      <div className="col-span-3">
        <Shapes
          shapes={users?.filter((user) => user.isShown)}
          setShapes={setUsers}
          formatShapesOption={usersSecondFormatOptionLabel}
          hasPlusMark={false}
        />
      </div>
    </div>
  );
};

export default UsersShapes;
