import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchCurrentMoneyRequests(safeId) {
    const params = {};
    if(safeId) params.safe_id = safeId;
    return useQuery(["currentMoneyRequests", safeId], () => {
    return apiClient.get("/company/runningamount", {params}).then((response) => {
      return response.data.total_remaining_amount;
    })
  });
}
