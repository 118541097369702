import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useMutateHoldSpendRequest(
  onClose
) {
  const queryClient = useQueryClient();
  return useMutation(
    (id) => {
      return apiClient.put(`spend_request/${id}/hold`);
    },
    {
      onSuccess: (res, sentData) => {
        toast.error("تم حجب العملية");
        queryClient.invalidateQueries("getPayments");
        queryClient.invalidateQueries("getSingleSpendRequest");
        queryClient.invalidateQueries("userData");
        onClose();
      },
      onError: (error) => {
        onClose();
        return error;
      },
    }
  );
}
