const ActiveDeactive = ({active, fill}) => {
  if(active) {
    return (
      <div>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.9707 22.75C6.0507 22.75 1.2207 17.93 1.2207 12C1.2207 6.07 6.0507 1.25 11.9707 1.25C17.8907 1.25 22.7207 6.07 22.7207 12C22.7207 17.93 17.9007 22.75 11.9707 22.75ZM11.9707 2.75C6.8707 2.75 2.7207 6.9 2.7207 12C2.7207 17.1 6.8707 21.25 11.9707 21.25C17.0707 21.25 21.2207 17.1 21.2207 12C21.2207 6.9 17.0707 2.75 11.9707 2.75Z" fill= {fill ? fill : "#DC2626" }/>
          <path d="M10.01 15.95H8.71C7.79 15.95 7.25 15.42 7.25 14.53V9.46999C7.25 8.57999 7.8 8.04999 8.71 8.04999H10C10.92 8.04999 11.46 8.57999 11.46 9.46999V14.53C11.47 15.42 10.92 15.95 10.01 15.95ZM8.75 14.45H9.97V9.54999H8.76L8.75 14.45Z" fill={fill ? fill : "#DC2626" }/>
          <path d="M15.2891 15.95H13.9991C13.0791 15.95 12.5391 15.42 12.5391 14.53V9.46999C12.5391 8.57999 13.0891 8.04999 13.9991 8.04999H15.2891C16.2091 8.04999 16.7491 8.57999 16.7491 9.46999V14.53C16.7491 15.42 16.1991 15.95 15.2891 15.95ZM14.0291 14.45H15.2491V9.54999H14.0391L14.0291 14.45Z" fill= {fill ? fill : "#DC2626" }/>
        </svg>
      </div>
    );
  }
  else {
    return (
      <div>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.09961 12V10.52C9.09961 8.60999 10.4496 7.83999 12.0996 8.78999L13.3796 9.52999L14.6596 10.27C16.3096 11.22 16.3096 12.78 14.6596 13.73L13.3796 14.47L12.0996 15.21C10.4496 16.16 9.09961 15.38 9.09961 13.48V12Z" stroke={fill ? fill : "#10B981"} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke={fill ? fill : "#10B981"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    );
  }
};

export default ActiveDeactive;
