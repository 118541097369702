import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchActiveMoneyRequests(
  safeId,
  pageno,
  pagesize,
  columnKey, 
  order,
  currentPage,
) {
  return useQuery(
    [
      "activeMoneyRequests",
      safeId,
      pageno,
      pagesize,
      columnKey, 
      order,
      currentPage
    ],
    () => {
      const params = {pageno, pagesize, currentPage};
      
      if (order && columnKey) {
        params.orderbycolumn = columnKey;
        params.isascending = order === "ascend" ? 1 : order === "descend" ? 0 : null;
      }
      if(safeId) params.safe_id = safeId;
      
      return apiClient
        .get("/money_request/custody/dashboard", {
          params,
        })
        .then(({data}) => {
          let response = [...data];
          response[0] = data[0].map(row => {
            return {
              ...row,
              profile_picture_url: (process.env.REACT_APP_UPLOAD_FILES_BASE_URL
                ? process.env.REACT_APP_UPLOAD_FILES_BASE_URL
                : "") +
              (process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
                ? process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
                : "") +
              `users/${row.recipient_id}/profilepicture` +
              `.${row.profile_picture_ext}`,
              creator_profile_picture_url: (process.env.REACT_APP_UPLOAD_FILES_BASE_URL
                ? process.env.REACT_APP_UPLOAD_FILES_BASE_URL
                : "") +
              (process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
                ? process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
                : "") +
              `users/${row.creator_id}/profilepicture` +
              `.${row.creator_profile_picture_ext}`
            }
          })
          return response;
        });
    }
  );
}
