import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchArchive({
  userId,
  activeCompanyId,
  activeTab,
  currentPage,
  pageSize,
  urgent,
  notUrgent,
  accepted,
  rejected,
  order,
  columnKey,
  searchType,
  searchTerms,
}) {
  return useQuery(
    [
      "getArchivedData",
      activeTab,
      currentPage,
      pageSize,
      urgent,
      notUrgent,
      accepted,
      rejected,
      order,
      columnKey,
      searchType,
      searchTerms,
    ],
    () => {
      const params = {};
      params.user_id = userId;
      params.company_id = activeCompanyId;
      params.pageno = currentPage;
      params.pagesize = pageSize;
      if (activeTab === 1) {
        params.received = 1;
      }
      if (activeTab === 0) {
        params.received = 0;
      }
      if (urgent && !notUrgent) {
        params.urgent = 1;
      }
      if (!urgent && notUrgent) {
        params.urgent = 0;
      }
      if (accepted && !rejected) {
        params.isprocessed = 3;
      }
      if (!accepted && rejected) {
        params.isprocessed = 2;
      }
      if (order && columnKey) {
        params.orderbycolumn = columnKey;
        params.isascending =
          order === "ascend" ? 1 : order === "descend" ? 0 : null;
      }
      if (searchType !== "" && searchTerms.length > 0) {
        params.search_type = searchType;
        params.search_terms = JSON.stringify(searchTerms);
      }

      return apiClient
        .get("spend_request/archived", {
          params,
        })
        .then(({ data }) => data)
        .catch((e) => e);
    },
    { keepPreviousData: true, retry: false }
  );
}
