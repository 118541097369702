import React from "react";

import { useForm } from "react-hook-form";

import Input from "../../common/inputs/Input";

import apiClient from "../../../services/apiClient";

import "./ResetPassword.scss";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";

import { useTranslation } from "react-i18next";

const ResetPassword = ({ history, pass, onClose }) => {
  const {t, i18n} = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    mode: "onSubmit",
  });

  const resetPasswordInfo = useQuery("resetPasswordInfo", () => {
    return apiClient.get("/auth/getresetpassworduserinfobytoken", {
      params: { token: pass },
    });
  });

  const resetPasswordMutation = useMutation(
    (body) =>
      apiClient.post(
        `/user/${resetPasswordInfo.data.data.id}/resetpassword`,
        body
      ),
    {
      onSuccess: () => {
        onClose();
        toast.info(t("password_reset_success"), {
          autoClose: 3000,
        });
        history.replace("/home");
      },
    }
  );

  const handleResetPassword = (data) => {
    let body = new FormData();
    body.append("password", data.password);
    resetPasswordMutation.mutate(body);
  };

  return (
    <div className="overlay" onClick={() => onClose()} dir={i18n.resolvedLanguage === "en" ? "ltr" : "rtl"}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <form
          class="bg-gray-50 flex flex-col justify-center py-6 rounded-lg forget-container"
          onSubmit={handleSubmit(handleResetPassword)}
        >
          <div>
            <div className="sm:mx-auto sm:w-full">
              <h2 className="mt-2 text-center text-lg font-extrabold text-gray-900">
              {t("reset_password")}
              </h2>
            </div>
            <div className="mt-8 sm:mx-auto sm:w-full">
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <div>
                  <Input
                    register={register}
                    type="password"
                    isPassword={true}
                    name="password"
                    fieldName={t("password")}
                    label={t("password")}
                    placeholder={t("password")}
                    required={true}
                    minLength="8"
                    errors={errors?.password}
                  />
                  <Input
                    register={register}
                    type="password"
                    isPassword={true}
                    name="confirmPassword"
                    fieldName={t("password_confirmation")}
                    label={t("password_confirmation")}
                    placeholder={t("password_confirmation")}
                    required={true}
                    validate={{
                      validateRepetition: (value) => {
                        return (
                          value === getValues("password") ||
                          t("password_mismatch")
                        );
                      },
                    }}
                    minLength="8"
                    errors={errors?.confirmPassword}
                  />
                </div>

                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 mt-6 bg-black border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white"
                  >
                  {t("reset_passowrd")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withRouter(ResetPassword);
