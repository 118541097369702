import React, { Fragment, useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";

import AddNewRoleIcon from "../../../components/resources/icons/AddNewRoleIcon";

import HeaderSection from "../../../components/common/HeaderSection";
import Table from "../../../components/common/Table";
import ExpandedRow from "../../../components/dashboard/ExpandedRow";

import apiClient from "../../../services/apiClient";
import useFetchUserData from "../../../hooks/reactQuery/useFetchUserData";
import useFetchRoles from "../../../hooks/reactQuery/useFetchRoles";

import { useDataLayerValue } from "../../../context/DataLayer";

import { columnsHeader } from "./tableColumnsHeader.js";

import { useQueryClient } from "react-query";

import Modal from "../../common/Modal";
import AddEditRole from "../Modals/AddEditRole";
import EditRole from "../../../components/settings/Modals/EditRole/EditRole";
import { useTranslation } from "react-i18next";

const RolesSettings = ({}) => {
  const { data: userData } = useFetchUserData();

  const {t, i18n} = useTranslation();

  const [{ activeTab, searchType, searchTerm }] = useDataLayerValue();

  const [showAddNewRoleModal, setShowAddNewRoleModal] = useState(false);
  // const [showEditRoleModal, setShowEditRoleModal] = useState(false);

  const [selectedRole, setSelectedRole] = useState(null);

  const pageSize = process.env.REACT_APP_PAGE_SIZE;
  const [currentPage, setCurrentPage] = useState(1);
  const [columnKey, setColumnKey] = useState("");
  const [order, setOrder] = useState("");

  const [columnsHeaderState, setColumnsHeaderState] = useState(
    columnsHeader({
      setSelectedRole,
      setShowAddNewRoleModal,
      canEditRole: userData.permission_tags.includes("EditRole") || userData.permission_tags.includes("DeactivateRole"),
      t,
      i18n
    })
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [order, columnKey]);

  const {
    isLoading: isTableDataLoading,
    data: tableData,
    error: tableDataError,
    isPreviousData,
  } = useFetchRoles({
    userId: userData.id,
    activeCompanyId: userData.activeCompanyId,
    currentPage,
    pageSize,
    columnKey,
    order,
  });

  const memoizedTableData = useMemo(() => tableData ? tableData[0] : [], [tableData]);

  const handleTableChange = (pagination, filters, sorter) => {
    setOrder(sorter?.order);
    setColumnKey(sorter?.columnKey);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const queryClient = useQueryClient();

  const [expandedRows, setExpandedRows] = useState([]);

  const CSRFToken = useQuery(
    "CSRFToken",
    () => {
      return apiClient.get("/getCSRFToken");
    },
    {
      onSuccess(response) {
        localStorage.setItem("CSRFToken", response.data.CSRFToken);
      },
    }
  );

  return (
    <div>
      {userData.permission_tags.includes("CreateRole") ? (
        <div className="flex justify-end items-center px-4 pt-3">
          <button
            onClick={() => {
              setShowAddNewRoleModal(true);
            }}
            className="border hover:border-secondary flex justify-between items-center gap-x-2 bg-secondary bg-opacity-20 rounded-md px-4 py-2"
          >
            <div>
              <AddNewRoleIcon fillColor="#3B82F6" />
            </div>
            <span className="text-sm font-bold text-secondary">
              {t("add_new_role")}
            </span>
          </button>
        </div>
      ) : null}

      <div className="bg-white">
        <div>
          <div className="mx-4 my-3">
            <Table
              rowKey="id"
              hasSelection={false}
              data={memoizedTableData}
              columns={columnsHeaderState}
              isLoading={isTableDataLoading}
              error={tableDataError}
              pageSize={pageSize}
              currentPage={currentPage}
              total={tableData && tableData.length > 0 ? tableData[1][0].count : 0}
              isPaginationDisabled={isPreviousData}
              onPageChange={onPageChange}
              onChange={handleTableChange}
              expandedRows={expandedRows}
              setExpandedRows={setExpandedRows}
            />
          </div>
        </div>
      </div>
      <Modal
        showModal={showAddNewRoleModal}
        onClose={() => {
          setShowAddNewRoleModal(false);
          setSelectedRole(() => ({}));
        }}
      >
        <AddEditRole
          onClose={() => {
            setShowAddNewRoleModal(false);
            setSelectedRole(() => null);
          }}
          selectedRole={selectedRole}
          edit={userData.permission_tags.includes("EditRole")  }
          deactive={userData.permission_tags.includes("DeactivateRole")}
        />
      </Modal>

      {/* <Modal
        showModal={showEditRoleModal}
        onClose={() => setShowEditRoleModal(false)}
      >
        <EditRole
          roleId={selectedRoleId}
          onClose={() => setShowEditRoleModal(false)}
        />
      </Modal> */}
    </div>
  );
};

export default RolesSettings;
