import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useMutateRejectMpneyRequest(
  onClose,
  userId,
  activeCompanyId
) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ data, activeRow }) => {
      const formData = new FormData();

      formData.append("id", activeRow.id);
      formData.append("approved", 0);
      formData.append("response_notes", data.response_notes);

      formData.append(
        "attachments",
        JSON.stringify(data.attachments ? data.attachments : [])
      );

      formData.append("sender_id", activeRow.creator);
      formData.append("user_id", userId);
      formData.append("company_id", activeCompanyId);

      return apiClient.post("money_request/reject", formData);
    },
    {
      onSuccess: () => {
        toast.info("تم رفض الطلب.", { autoClose: 3000 });
        queryClient.invalidateQueries("getMoneyRequestsData");
        queryClient.invalidateQueries("userData");
        queryClient.invalidateQueries("userBalance");
        onClose();
      },
      onError: (error) => {
        onClose();
        return error;
      },
    }
  );
}
