const CancelAddRole = () => {
  return (
    <div>
      <svg
        width={48}
        height={48}
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width={48} height={48} rx={24} fill="#FFD4D4" />
        <path
          d="M24 34.75C18.07 34.75 13.25 29.93 13.25 24C13.25 18.07 18.07 13.25 24 13.25C29.93 13.25 34.75 18.07 34.75 24C34.75 29.93 29.93 34.75 24 34.75ZM24 14.75C18.9 14.75 14.75 18.9 14.75 24C14.75 29.1 18.9 33.25 24 33.25C29.1 33.25 33.25 29.1 33.25 24C33.25 18.9 29.1 14.75 24 14.75Z"
          fill="#DC2626"
        />
        <path
          d="M24 25.75C23.59 25.75 23.25 25.41 23.25 25V20C23.25 19.59 23.59 19.25 24 19.25C24.41 19.25 24.75 19.59 24.75 20V25C24.75 25.41 24.41 25.75 24 25.75Z"
          fill="#DC2626"
        />
        <path
          d="M24 29C23.87 29 23.74 28.97 23.62 28.92C23.5 28.87 23.39 28.8 23.29 28.71C23.2 28.61 23.13 28.51 23.08 28.38C23.03 28.26 23 28.13 23 28C23 27.87 23.03 27.74 23.08 27.62C23.13 27.5 23.2 27.39 23.29 27.29C23.39 27.2 23.5 27.13 23.62 27.08C23.86 26.98 24.14 26.98 24.38 27.08C24.5 27.13 24.61 27.2 24.71 27.29C24.8 27.39 24.87 27.5 24.92 27.62C24.97 27.74 25 27.87 25 28C25 28.13 24.97 28.26 24.92 28.38C24.87 28.51 24.8 28.61 24.71 28.71C24.61 28.8 24.5 28.87 24.38 28.92C24.26 28.97 24.13 29 24 29Z"
          fill="#DC2626"
        />
      </svg>
    </div>
  );
};

export default CancelAddRole;
