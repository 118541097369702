import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useMutateAddSupportPaymentAttachments(onClose) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ data, activeRow }) => {
      const formData = new FormData();

      formData.append("id", activeRow.id);

      formData.append(
        "attachments",
        JSON.stringify(data.attachments ? data.attachments : [])
      );

      formData.append("recipient_id", activeRow.recipient_id);

      return apiClient.post("spend_request/addsupportingdocuments", formData);
    },
    {
      onSuccess: () => {
        toast.info("تم إضافة المرفقات بنجاح.", { autoClose: 3000 });
        queryClient.invalidateQueries("getPayments");
        onClose();
      },
      onError: (error) => {
        onClose();
        return error;
      },
    }
  );
}
