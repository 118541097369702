const Cash = ({}) => {
  return (
    <div>
      <svg
        width={23}
        height={18}
        viewBox="0 0 23 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.209 6.23197V4.15505C16.209 3.008 15.2856 2.07812 14.1465 2.07812H3.83398C2.6949 2.07812 1.77148 3.008 1.77148 4.15505V10.3858C1.77148 11.5329 2.6949 12.4627 3.83398 12.4627H5.89648M7.95898 16.6166H18.2715C19.4106 16.6166 20.334 15.6867 20.334 14.5397V8.30889C20.334 7.16184 19.4106 6.23197 18.2715 6.23197H7.95898C6.8199 6.23197 5.89648 7.16184 5.89648 8.30889V14.5397C5.89648 15.6867 6.8199 16.6166 7.95898 16.6166ZM15.1777 11.4243C15.1777 12.5713 14.2543 13.5012 13.1152 13.5012C11.9761 13.5012 11.0527 12.5713 11.0527 11.4243C11.0527 10.2772 11.9761 9.34736 13.1152 9.34736C14.2543 9.34736 15.1777 10.2772 15.1777 11.4243Z"
          stroke="#1E293B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Cash;
