import React, { useState, useEffect, Fragment } from "react";

import { useHistory, useLocation } from "react-router-dom";
import {
  useForm,
  useFieldArray,
  useFormContext,
  FormProvider,
} from "react-hook-form";

import Form from "../../../components/documentations/Form";
import Modal from "../../../components/common/Modal";
import Alert from "../../../components/common/Alert";
import Plus from "../../../components/resources/icons/Plus";

import useFetchUserData from "../../../hooks/reactQuery/useFetchUserData";
import useFetchFinancials from "../../../hooks/reactQuery/useFetchFinancials";
import useMutateDocumentForm from "../../../hooks/reactQuery/useMutateDocumentForm";

import { useDataLayerValue } from "../../../context/DataLayer";
import { useTranslation } from "react-i18next";

const DocumentForm = ({}) => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [{ isFileUploading }] = useDataLayerValue();

  const [showAlertCancelModal, setShowAlertCancelModal] = useState(false);
  const [showAlertConfirmModal, setShowAlertConfirmModal] = useState(false);

  const [trackAddField, setTrackAddField] = useState(true);

  const financials = useFetchFinancials(userData.id, userData.activeCompanyId);

  const methods = useForm({
    mode: "all",
    defaultValues: {
      documentForm: [
        {
          name: "",
          periodic: "",
          category_id: "",
          safe_id: "",
          payment_date: "",
          financial_id: location?.state ? location?.state : "",
          amount: "",
          notes: "",
          fromSafe: 0,
          documentFiles: [],
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "documentForm",
  });

  const addFieldSet = () => {
    append({
      name: "",
      periodic: "",
      category_id: "",
      safe_id: "",
      payment_date: "",
      financial_id: "",
      amount: "",
      notes: "",
      fromSafe: 0,
      documentFiles: [],
    });
  };

  const removeFieldSet = (fieldIndex) => {
    remove(fieldIndex);
  };

  const { isLoading, mutate, status } = useMutateDocumentForm(
    userData.id,
    userData.activeCompanyId,
    setShowAlertConfirmModal,
    history
  );

  const handleSubmitDocumentForm = (data) => {
    const formData = data.documentForm.map((field) => ({
      amount: field.amount,
      notes: field.notes,
      payment_date: field.payment_date,
      category_id: field.category_id.value,
      safe_id: field.safe_id.value,
      money_request_id: field.financial_id.value,
      periodic: field.periodic.value,
      fromSafe: field.fromSafe ? 1 : 0,
      attachments: field.attachments?.map((file) => ({
        guid: file.guid,
        name: file.name,
      })),
    }));

    mutate(formData);
  };

  const [totalValue, setTotalValue] = useState(0);

  const [activeFieldArr, setActiveFieldArr] = useState(fields[0]?.id);

  return (
    <FormProvider {...methods}>
      <form className="space-y-8 divide-y divide-gray-200 w-full bg-white relative">
        <div className="px-4 pay-forms-height">
          {fields.map((field, index) => {
            return (
              <div>
                <ExpandedFieldArray
                  key={field.id}
                  fields={fields}
                  financials={financials} // 3ohda Select Menu OPtions
                  index={index}
                  field={field}
                  removeFieldSet={removeFieldSet}
                  trackTotalValue={(totalValue) => setTotalValue(totalValue)}
                  financialIdDefaultValue={location?.state}
                  activeFieldArr={activeFieldArr}
                  setActiveFieldArr={setActiveFieldArr}
                  trackAddField={trackAddField}
                />
              </div>
            );
          })}
        </div>

        <div className="py-5 lg:sticky bottom-0 bg-white px-4 lg:pr-16">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-y-6 lg:gap-x-4">
            <div className="col-span-9 grid lg:grid-cols-9 grid-cols-1 gap-y-3 h-fit-content">
              <button
                type="button"
                className="lg:col-span-2 col-span-1 row-start-3 lg:row-start-1 lg:w-32 flex justify-center items-center gap-x-3 bg-white py-2 px-4 border border-outline rounded-md shadow-sm text-sm font-bold text-body"
                onClick={() => {
                  addFieldSet();
                  setTrackAddField((prevState) => !prevState);
                }}
              >
                <div>
                  <Plus />
                </div>
                <span>{t("add_another")}</span>
              </button>

              <div className="col-span-4 lg:col-start-6 grid grid-cols-1 lg:grid-cols-9 gap-y-6 gap-x-2">
                <Fragment>
                  <button
                    type="button"
                    className={`lg:col-span-4 lg:col-start-2 py-2 px-4 text-base leading-5 font-bold rounded-md shadow-sm border border-outline ${
                      !methods.formState?.isValid || isFileUploading
                        ? "cursor-not-allowed disabled bg-outline text-white"
                        : "bg-white text-hints"
                    }`}
                    onClick={() => setShowAlertConfirmModal(true)}
                    disabled={!methods.formState?.isValid || isFileUploading}
                  >
                  {t("send")}
                  </button>
                  <Modal
                    showModal={showAlertConfirmModal}
                    onClose={() => setShowAlertConfirmModal(false)}
                  >
                    <Alert
                      hasFooter={true}
                      alertType="confirm"
                      title={t("confirm")}
                      //body={`سيتم تسجيل عدد ${fields.length} مصروف بقيمة ${totalValue} جنيه مصري يمكنك الاطلاع على جميع مصروفاتك بقائمة التدوينات بواجهتك.`}
                      body={() => (
                        <div>
                          {t("alert_text_add_document", {count: fields.length, amount: totalValue})}
                        </div>
                      )}
                      confirmText={t("send")}
                      cancelText={t("cancel")}
                      handleConfirm={methods.handleSubmit(
                        handleSubmitDocumentForm
                      )}
                      handleCancel={() =>
                        !isLoading && setShowAlertConfirmModal(false)
                      }
                      isSubmitLoading={isLoading}
                      submitStatus={status}
                    />
                  </Modal>
                </Fragment>

                <Fragment>
                  <button
                    type="button"
                    className={`lg:col-span-4 py-2 px-4 text-base leading-5 font-bold rounded-md shadow-sm border border-outline ${
                      !methods.formState?.isDirty
                        ? "cursor-not-allowed disabled bg-outline text-white"
                        : "bg-white text-hints"
                    }`}
                    onClick={() => setShowAlertCancelModal(true)}
                    disabled={!methods.formState?.isDirty}
                  >
                  {t("cancel")}
                  </button>
                  <Modal
                    showModal={showAlertCancelModal}
                    onClose={() => setShowAlertCancelModal(false)}
                  >
                    <Alert
                      hasFooter={true}
                      alertType="cancel"
                      title={t("discard")}
                      //body="هل أنت متأكد؟ سيتم حذف جميع البيانات التي أدخلتها، هل أنت متأكد من الإلغاء؟"
                      body={() => (
                        <div>
                          {t("alert_text_cancel")}
                        </div>
                      )}
                      confirmText={t("discard")}
                      cancelText={t("cancel")}
                      handleConfirm={() => history.push("/")}
                      handleCancel={() => setShowAlertCancelModal(false)}
                    />
                  </Modal>
                </Fragment>
              </div>
            </div>

            <div className="lg:col-span-2 lg:col-start-11 bg-white py-2 px-4 rounded-md shadow-sm lg:text-left text-center">
              <span className="text-sm font-bold text-body">
              {t("total_amount")}
              </span>{" "}
              <span className="text-secondary text-sm font-bold">
                {isNaN(totalValue) ? 0 : totalValue}
              </span>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default DocumentForm;

function ExpandedFieldArray({
  fields,
  financials, // 3ohda Select Menu OPtions
  index,
  field,
  removeFieldSet,
  trackTotalValue,
  financialIdDefaultValue,
  activeFieldArr,
  setActiveFieldArr,
  trackAddField,
}) {
  const {
    formState: { errors },
  } = useFormContext();

  const [isExpanded, setExpanded] = useState(true);

  useEffect(() => {
    if (activeFieldArr !== field.id) {
      setExpanded(false);
    }
  }, [activeFieldArr]);

  useEffect(() => {
    setExpanded(true);
    setActiveFieldArr(fields[fields.length - 1].id);
  }, [trackAddField]);

  return (
    <div className="flex">
      <div className="flex flex-col items-center ml-4 relative top-4">
        <div
          className={`flex justify-center items-center py-2 px-3 text-white text-base leading-4 font-medium rounded-lg relative transition-all duration-300 ${
            errors?.documentForm && errors?.documentForm[index]
              ? "bg-error"
              : "bg-side-icons"
          }`}
        >
          <span className="w-2 flex justify-center items-center">
            {index + 1}
          </span>
        </div>
        <div className="flex justify-center h-full">
          <div
            className={`w-px bg-side-icons h-0 transition-all duration-300 ${
              index + 1 !== fields.length && "h-full"
            }`}
          ></div>
        </div>
      </div>
      <Form
        fields={fields}
        financials={financials} // 3ohda Select Menu OPtions
        index={index}
        field={field}
        removeFieldSet={removeFieldSet}
        trackTotalValue={trackTotalValue}
        financialIdDefaultValue={financialIdDefaultValue}
        activeFieldArr={activeFieldArr}
        setActiveFieldArr={setActiveFieldArr}
        isExpanded={isExpanded}
        setExpanded={setExpanded}
      />
    </div>
  );
}
