import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchPaymentsCount({ activeCompanyId }) {
  return useQuery("paymentsCount", () => {
    return apiClient
      ?.get(`spend_request/notificationscount`, {
        company_id: activeCompanyId,
      })
      .then(({ data }) => data)
      .catch((e) => e);
  });
}
