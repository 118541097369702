import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchLastUpdates(
  safe_id,
  pageno = 1,
  pagesize = 10
) {
  return useQuery(
    [
      "getLastUpdates",
      safe_id,
      pageno,
      pagesize
    ],
    () => {
      const params = {pageno, pagesize};

      if(safe_id) params.safe_id = safe_id;

      return apiClient
        .get("/admin/lastUpdates", {
          params,
        })
        .then(({data}) => {
          return data;
        });
    }
  );
}
