import { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

import Input from "../../components/common/inputs/Input";
import TextArea from "../../components/common/inputs/Textarea";
import SelectMenu from "../../components/common/inputs/Select";
import ReactTagInput from "@pathofdev/react-tag-input";

import "./style.scss";
import { useMutation, useQuery } from "react-query";
import apiClient from "../../services/apiClient";
import { toast } from "react-toastify";
import Reaptcha from "reaptcha";
import Spinner from "../../components/common/Spinner";
import Personal from "./personal";
import Steps from "./steps";
import Work from "./work";
import WorkTools from "./WorkTools";
import SuccessScreen from "./SuccessScreen";
import RequestForAccessImage from "./RequestForAccessImage";
import { useTranslation } from "react-i18next";

const RequestForAccess = ({ history }) => {
  const {t, i18n} = useTranslation();
  const [currentTab, setCurrentTab] = useState(1);
  const [completed, setCompleted] = useState(
    localStorage.getItem("request_access_completed") ? JSON.parse(localStorage.getItem("request_access_completed")) : []
  );
  const [steps, setSteps] = useState([
    {name: t("request_for_access_personal"), href: "#", status: "current", tab: 1, click: () => setCurrentTab(1)},
    {name: t("request_for_access_business"), href: "#", status: "upcoming", tab: 2, click: () => setCurrentTab(2)},
    {name: t("request_for_access_tools"), href: "#", status: "upcoming", tab: 3, click: () => setCurrentTab(3)}
  ]);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [firstName, setFirstName] = useState("");

  useEffect(() => {
    return () => {
      localStorage.removeItem("request_access_completed");
      localStorage.removeItem("request_access_personal");
      localStorage.removeItem("request_access_work");
      localStorage.removeItem("request_access_tools");
    }
  }, []);

  useEffect(() => {
    setSteps(stps => {
      let current = stps.find(one => one.tab === currentTab);
      current = {...current, status: "current"};
      return [...stps.filter(obj => obj.tab !== current.tab), current].sort((a,b) => a.tab - b.tab);
    });

    setCompleted(completed => completed.filter(tab => tab !== currentTab));
  }, [currentTab]);

  useEffect(() => {
    if(completed.length > 0) {
      setSteps(stps => {
        let allSteps = [...stps];
        const completedSteps = [];
        for(let tab of completed) {
          let currentCompleted = stps.find(one => one.tab === tab);
          currentCompleted = {...currentCompleted, status: "complete"};
          completedSteps.push(currentCompleted);
          allSteps = allSteps.filter(obj => obj.tab !== tab);
        }
        const newSteps = [...allSteps, ...completedSteps].sort((a,b) => a.tab - b.tab);
        return newSteps;
      });
      localStorage.setItem("request_access_completed", JSON.stringify(completed));
    }
  }, [completed]);

  const onCancel = () => {
    localStorage.removeItem("request_access_completed");
    localStorage.removeItem("request_access_personal");
    localStorage.removeItem("request_access_work");
    localStorage.removeItem("request_access_tools");
    setCompleted([]);
    history.push("/");
  }

  const registerationMutation = useMutation(
    (body) => apiClient.post("/request_for_access/add", body),
    {
      onSuccess: (response) => {
        localStorage.removeItem("request_access_completed");
        localStorage.removeItem("request_access_personal");
        localStorage.removeItem("request_access_work");
        localStorage.removeItem("request_access_tools");
        setCompleted([]);
        setIsSubmitted(true);
      },
      onError: (err) => {
        localStorage.removeItem("request_access_completed");
        localStorage.removeItem("request_access_personal");
        localStorage.removeItem("request_access_work");
        localStorage.removeItem("request_access_tools");
        setCompleted([]);
      }
    }
  );

  const submit = () => {
    if(!localStorage.getItem("request_access_completed") 
    || !localStorage.getItem("request_access_personal")
    || !localStorage.getItem("request_access_work")
    || !localStorage.getItem("request_access_tools")
    ) {
      toast.error(t("fill_all_date"));
      return;
    }

    const completed = JSON.parse(localStorage.getItem("request_access_completed"));
    if(!completed.includes(1) || !completed.includes(2)) {
      toast.error(t("fill_all_date"));
      return;
    }

    const personalData = JSON.parse(localStorage.getItem("request_access_personal"));
    setFirstName(personalData.name.split(" ")[0]);

    const workData = JSON.parse(localStorage.getItem("request_access_work"));
    const toolsData = JSON.parse(localStorage.getItem("request_access_tools"));

    let body = new FormData();
    let data = {};

    const companyCommunicationTools = [];
    const companyFinanceTools = [];

    toolsData.communicationTools.forEach((item) => {
      companyCommunicationTools.push(item.id);
      delete data[item.name];
    });
    toolsData.financialTools.forEach((item) => {
      companyFinanceTools.push(item.id);
      delete data[item.name];
    });

    data.username = personalData.name;
    data.firstName = personalData.name.split(" ")[0];
    data.lastName = personalData.name.split(" ")[1];
    data.email = personalData.email;
    data.phoneNumber = personalData.phone;
    data.password = personalData.password;
    data.companyName = workData.companyName;
    data.tarbeetaHelp = workData.tarbeetaHelp;
    data["companyCommunicationTools"] = companyCommunicationTools;
    data["companyFinanceTools"] = companyFinanceTools;
    data.companySpecialization = workData.companySpecialization.value;
    data.companyResponsibility = workData.companyResponsibility.value;
    data.companyMembers = workData.companyMembers.value;
    data.companyExtraCommunicationTools = toolsData.extraCommunicationTools;
    data.companyExtraFinanceTools = toolsData.extraFinancialTools;
    data.companyTransactions = workData.transactions;
    data.companyTransactionsAmount = workData.transactions_amount;

    body.append("request", JSON.stringify(data));
    setIsSubmitting(true);
    registerationMutation.mutate(body);
  }

  return (!isSubmitted ? 
    <div className="flex items-start" style={{height: "100vh", maxHeight: "100vh"}}>
      <div className="flex items-start" style={{width: "40%", height: "100vh", maxHeight: "100vh"}}>
        <RequestForAccessImage tab={currentTab} />
      </div>
      <div className="flex items-center justify-start" style={{flex: 1, height: "100%", flexDirection: "column"}}>
        <Steps steps={steps} />
        {currentTab === 1 ? <Personal 
        next={() => setCurrentTab(tab => tab + 1)} 
        completed={() => setCompleted(c => c.includes(1) ? c : [...c, 1])} 
        notCompleted={() => setCompleted(c => c.filter(tab => tab != 1))} 
        cancel={onCancel} 
        /> 
        : currentTab === 2 ? <Work 
          next={() => setCurrentTab(tab => tab + 1)} 
          previous={() => setCurrentTab(tab => tab - 1)} 
          completed={() => setCompleted(c => c.includes(2) ? c : [...c, 2])}
          notCompleted={() => setCompleted(c => c.filter(tab => tab != 2))}  
          cancel={onCancel}
        /> 
        : currentTab === 3 ? <WorkTools
        next={submit} 
        previous={() => setCurrentTab(tab => tab - 1)} 
        completed={() => setCompleted(c => c.includes(3) ? c : [...c, 3])} 
        notCompleted={() => setCompleted(c => c.filter(tab => tab != 3))} 
        cancel={onCancel}
        isSubmitting={isSubmitting}
        />
        : null}
      </div>
    </div>
    : <SuccessScreen firstName={firstName} />);
};

export default withRouter(RequestForAccess);
