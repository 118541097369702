import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchPendingMoneyRequestsPaymentLicenses({
  currentPage,
  pageSize,
  order,
  columnKey,
  searchType,
  searchTerm,
}) {
  return useQuery(
    [
      "getPendingMoneyRequestsPaymentLicenses",
      currentPage,
      pageSize,
      order,
      columnKey,
      searchType,
      searchTerm,
    ],
    () => {
      const params = {};
      params.pageno = currentPage;
      params.pagesize = pageSize;

      if (order && columnKey) {
        params.orderbycolumn = columnKey;
        params.isascending =
          order === "ascend" ? 1 : order === "descend" ? 0 : null;
      }
      if (searchType && searchTerm) {
        params.search_type = searchType;
        params.search_term = searchTerm;
      }

      return apiClient
        .get("/admin/pendingMoneyRequestsPaymentLicenses", {
          params,
        })
        .then((res) => {
          res.data.data.forEach((element) => {
            element.key = element.id + "_" + element.request_type;
          });
          return res?.data;
        })
        .catch((e) => e);
    },
    { keepPreviousData: true }
  );
}
