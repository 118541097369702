import React, { useEffect, useState } from "react";

import { Switch, Route, withRouter, Redirect } from "react-router-dom";

import mixpanel from "mixpanel-browser";
import "dotenv";
import withClearCache from "./ClearCache";

import { useScrollRestoration, useResetState } from "./hooks";

import DocumentForm from "./pages/documentations/Create";
import MoneyRequestDetails from "./pages/MoneyRequests/Details";
import PaymentLicense from "./pages/PaymentLicenses/Create";
import Register from "./pages/Registeration";
import WelcomeOwner from "./pages/WelcomeOwner";
import Settings from "./pages/Settings";
import ContactUs from "./pages/ContactUs";
import Documentations from "./pages/documentations/Preview";
import MoneyRequests from "./pages/MoneyRequests";
import Archive from "./pages/Archive";
import Helicopter from "./pages/Helicopter";
import Safe from "./pages/Safe/create/index";
import SingleSafe from "./pages/SingleSafe/index";
import SingleCashin from "./pages/SingleCashin/index";

import WithDraw from "./pages/Safe/preview/WithDraw/index";
import Deposit from "./pages/Safe/preview/Deposit/index";

import Home from "./pages/Home";
import WelcomeUser from "./pages/WelcomeUser";
import { QueryClient, useQuery, useQueryClient } from "react-query";
import ProtectedRoute from "./components/ProtectedRoute";
import PaymentLicenses from "./pages/PaymentLicenses/Preview";
import Dashboard from "./pages/Dashboard";
import Layout from "./components/layout";

import apiClient from "./services/apiClient";
import useFetchUserData from "./hooks/reactQuery/useFetchUserData";
import NotFound from "./pages/NotFound/index";
import { use } from "chai";
// import ContactUs from './components/resources/icons/ContactUs';
import { toast } from "react-toastify";
import Trash from "./components/resources/icons/Trash";
import SingleMoneyRequest from "./pages/SingleMoneyRequest";
import SingleDocument from "./pages/SingleDocument";
import SingleSpendRequest from "./pages/SingleSpendRequest";
import PendingRequests from "./pages/PendingRequests";
import BalanceDetails from "./pages/BalanceDetails";
import RequestForAccess from "./pages/RequestForAccess";

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const MainApp = ({}) => {
  useScrollRestoration();
  useResetState();

  // const condition =
  // document.cookie.includes("Bearer") && userData ? true : false;
  const { data: userData, isLoading: isUserDataLoading } = useFetchUserData();

  const condition = !!userData;

  const CSRFToken = useQuery(
    "CSRFToken",
    () => {
      return apiClient.get("/getCSRFToken");
    },
    {
      onSuccess(response) {
        localStorage.setItem("CSRFToken", response.data.CSRFToken);
      },
    }
  );

  // useEffect(() => {
  //   const handleStorage = () => {
  //     if (!localStorage.getItem("activeCompanyId") && userData)
  //       window.location.reload();
  //   };
  //   window.addEventListener("storage", handleStorage);
  //   return () => {
  //     window.removeEventListener("storage", handleStorage);
  //   };
  // }, []);

  return (
    <>
      {!isUserDataLoading && (
        <Layout>
          <Switch>
            <Route exact path="/">
              {condition ? (
                <Redirect to="/payment-licenses" />
              ) : (
                <Redirect to="/home" />
              )}
            </Route>

            <ProtectedRoute
              exact
              path="/dashboard"
              Component={Dashboard}
              condition={condition}
              permissions={[]}
              redirect="/home"
            ></ProtectedRoute>
            <ProtectedRoute
              exact
              path="/payment-licenses"
              Component={PaymentLicenses}
              condition={condition}
              permissions={[
                "SendPurchaseRequest",
                "AcceptOrRejectSpendRequest",
                "ForwardPurchaseRequest",
              ]}
              redirect="/settings"
            ></ProtectedRoute>
            <ProtectedRoute
              exact
              path="/payment-license"
              Component={PaymentLicense}
              condition={condition}
              permissions={["SendPurchaseRequest"]}
              redirect="/home"
            ></ProtectedRoute>

            <ProtectedRoute
              exact
              path="/documentations"
              Component={Documentations}
              condition={condition}
              permissions={["Document"]}
              redirect="/home"
            ></ProtectedRoute>
            <ProtectedRoute
              exact
              path="/document"
              Component={DocumentForm}
              condition={condition}
              permissions={["Document"]}
              redirect="/home"
            ></ProtectedRoute>

            <ProtectedRoute
              exact
              path="/money-requests"
              Component={MoneyRequests}
              condition={condition}
              permissions={[
                "SendMoneyRequest",
                "AcceptOrRejectMoneyRequest",
                "ForwardMoneyRequest",
                "CashOutWithPermission",
                "CashOutWithoutPermission",
              ]}
              redirect="/home"
            ></ProtectedRoute>
            <ProtectedRoute
              exact
              path="/moneyRequestDetails"
              Component={MoneyRequestDetails}
              condition={condition}
              permissions={[]}
              redirect="/home"
            ></ProtectedRoute>

            <ProtectedRoute
              exact
              path="/helicopter"
              Component={Helicopter}
              condition={condition}
              permissions={["HelicopterReadOnly", "HelicopterFullControl"]}
              redirect="/home"
            ></ProtectedRoute>

            <ProtectedRoute
              exact
              path="/safe"
              Component={Safe}
              condition={ userData?.safes.length > 0}
              permissions={[
                "CashFullControl",
                "AddSafe"
              ]}
              redirect="/home"
            ></ProtectedRoute>

            <ProtectedRoute
              exact
              path="/safe/:id"
              Component={SingleSafe}
              condition={condition}
              permissions={[]}
              redirect="/home"
            ></ProtectedRoute>

            <ProtectedRoute
              exact
              path="/safes/:safeId/cashin/:id"
              Component={SingleCashin}
              condition={condition}
              permissions={[]}
              redirect="/home"
            ></ProtectedRoute>

            <ProtectedRoute
              exact
              path="/safes/:safeId/money_request/:id"
              Component={SingleMoneyRequest}
              condition={condition}
              permissions={[]}
              redirect="/home"
            ></ProtectedRoute>
            
            <ProtectedRoute
              exact
              path="/safes/:safeId/spend_request/:id"
              Component={SingleSpendRequest}
              condition={condition}
              permissions={[]}
              redirect="/home"
            ></ProtectedRoute>

            <ProtectedRoute
              exact
              path="/safes/:safeId/document/:id"
              Component={SingleDocument}
              condition={condition}
              permissions={[]}
              redirect="/home"
            ></ProtectedRoute>

            <ProtectedRoute
              exact
              path="/safe-withdraw"
              Component={WithDraw}
              condition={condition}
              permissions={[]}
              redirect="/home"
            ></ProtectedRoute>

            <ProtectedRoute
              exact
              path="/safe-deposit"
              Component={Deposit}
              condition={condition}
              permissions={[]}
              redirect="/home"
            ></ProtectedRoute>

            <ProtectedRoute
              exact
              path="/archive"
              Component={Archive}
              condition={condition}
              permissions={[]}
              redirect="/home"
            ></ProtectedRoute>

            <ProtectedRoute
              exact
              path="/balance"
              Component={BalanceDetails}
              condition={condition}
              permissions={[]}
              redirect="/home"
            ></ProtectedRoute>

            <ProtectedRoute
              exact
              path="/settings"
              Component={Settings}
              condition={condition}
              permissions={[]}
              redirect="/home"
            ></ProtectedRoute>

            <ProtectedRoute
              exact
              path="/contact-us"
              Component={ContactUs}
              condition={condition}
              permissions={[]}
              redirect="/home"
            ></ProtectedRoute>

            {/* <ProtectedRoute
              exact
              path="/not-found"
              Component={NotFound}
              condition={condition}
              redirect="/home"
            ></ProtectedRoute> */}

            {/* <Route exact path="/home" component={Home}></Route> */}
            
            <ProtectedRoute
              path="/home"
              Component={Home}
              condition={!condition}
              permissions={[]}
              redirect="/payment-licenses"
            ></ProtectedRoute>
            <Route exact path="/request-access" component={Register}></Route>
            <Route exact path="/register" component={RequestForAccess}></Route>
            <Route exact path="/welcome-owner" component={WelcomeOwner}></Route>
            <Route exact path="/signup" component={WelcomeUser}></Route>
            <Route exact path="/pending-requests" component={PendingRequests}></Route>
            <Route path="/404" component={NotFound} />
            <Redirect from="*" to="/404" />
          </Switch>
        </Layout>
      )}
    </>
  );
};

const ClearCacheComponent = withClearCache((props) => MainApp(props));

const App = (props) => {
  return <ClearCacheComponent props={props} />;
};

export default withRouter(App);
