const ActiveMoneyRequestsIcon = ({ fillColor }) => {
  return(
    <div>
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="30" height="30" rx="6" fill="#E7F0FF"/>
        <path d="M17.6827 10.4583H9.81602C9.58268 10.4583 9.36602 10.4667 9.16602 10.475C7.19102 10.5917 6.66602 11.3167 6.66602 13.5667V14.05C6.66602 14.5083 7.04102 14.8833 7.49935 14.8833H19.9993C20.4577 14.8833 20.8327 14.5083 20.8327 14.05V13.5667C20.8327 11.0833 20.1993 10.4583 17.6827 10.4583Z" fill="#59A0FF"/>
        <path d="M7.49935 16.1333C7.04102 16.1333 6.66602 16.5083 6.66602 16.9667V19.3917C6.66602 21.875 7.29935 22.5 9.81602 22.5H17.6827C20.1577 22.5 20.8077 21.9 20.8327 19.525V16.9667C20.8327 16.5083 20.4577 16.1333 19.9993 16.1333H7.49935ZM10.7993 20.4667H9.37435C9.03268 20.4667 8.74935 20.1833 8.74935 19.8417C8.74935 19.5 9.03268 19.2167 9.37435 19.2167H10.8077C11.1493 19.2167 11.4327 19.5 11.4327 19.8417C11.4327 20.1833 11.1493 20.4667 10.7993 20.4667ZM15.4577 20.4667H12.591C12.2493 20.4667 11.966 20.1833 11.966 19.8417C11.966 19.5 12.2493 19.2167 12.591 19.2167H15.4577C15.7993 19.2167 16.0827 19.5 16.0827 19.8417C16.0827 20.1833 15.8077 20.4667 15.4577 20.4667Z" fill="#59A0FF"/>
        <path d="M23.3333 16.1083V11.7417C23.3333 9.13333 21.8417 8 19.5917 8H12.15C11.5167 8 10.95 8.09167 10.45 8.28333C10.0583 8.425 9.70834 8.63333 9.42501 8.90833C9.27501 9.05 9.39168 9.28333 9.60834 9.28333H18.6667C20.5417 9.28333 22.0583 10.8 22.0583 12.675V18.65C22.0583 18.8583 22.2833 18.975 22.4333 18.825C23.0083 18.2167 23.3333 17.325 23.3333 16.1083Z" fill="#59A0FF"/>
        <path d="M25.64 13.6078V9.24118C25.64 6.63285 24.1483 5.49951 21.8983 5.49951H14.4566C13.8233 5.49951 13.2566 5.59118 12.7566 5.78285C12.365 5.92451 12.015 6.13285 11.7316 6.40785C11.5816 6.54951 11.6983 6.78285 11.915 6.78285H20.9733C22.8483 6.78285 24.365 8.29951 24.365 10.1745V16.1495C24.365 16.3578 24.59 16.4745 24.74 16.3245C25.315 15.7162 25.64 14.8245 25.64 13.6078Z" fill="#59A0FF"/>
      </svg>
    </div>
  );
};

export default ActiveMoneyRequestsIcon;
