import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchUserBalance() {
  return useQuery("userBalance", () =>
    apiClient
      ?.get("user/getuserbalance")
      .then(({ data }) => data[0]?.balance)
      .catch((e) => e)
  );
}
