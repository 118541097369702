import { useState, useEffect, useReducer, useMemo } from "react";

import { useHistory } from "react-router-dom";

import HeaderSection from "../../../components/common/HeaderSection";
import Table from "../../../components/common/Table";
import ExpandedRow from "../../../components/documentations/ExpandedRow";
import Filters from "./../../../components/documentations/Filters/index";

import useFetchUserData from "./../../../hooks/reactQuery/useFetchUserData";
import useFetchDocumentations from "../../../hooks/reactQuery/useFetchDocumentations";
import useFetchParentMoneyRequest from "./../../../hooks/reactQuery/useFetchParentMoneyRequest";

import actionTypes from "../../../context/types";
import { useDataLayerValue } from "../../../context/DataLayer";

import { columnsHeaderArr } from "./tableColumnsHeader";
import useMutateHoldDocument from "../../../hooks/reactQuery/useMutateHoldDocument";
import Modal from "../../../components/common/Modal";
import Alert from "../../../components/common/Alert";
import { useTranslation } from "react-i18next";

const pageSize = process.env.REACT_APP_PAGE_SIZE;

const Documentations = ({}) => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();

  const [{ searchType, searchTerms }, dispatch] = useDataLayerValue();

  const history = useHistory();

  const [activeRow, setActiveRow] = useState();
  
  const [hoverId, setHoverId] = useState(null);

  const [activeDeactiveId, setActiveDeactiveId] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);

  const [columnsHeader, setColumnsHeader] = useState(
    columnsHeaderArr(setActiveRow, setActiveDeactiveId, setShowDeactivateModal, userData, hoverId, t, i18n)
  );

  /*useEffect(() => {
    setColumnsHeader(columnsHeaderArr(setActiveRow, setActiveDeactiveId, setShowDeactivateModal, userData, hoverId, t, i18n));
  }, [hoverId]);*/

  const [currentPage, setCurrentPage] = useState(1);
  const [columnKey, setColumnKey] = useState("");
  const [order, setOrder] = useState("");

  //
  //
  // handling filters
  const filtersInitState = {
    categories: [],
    periodic: [],
    fromAmount: null,
    toAmount: null,
    fromDate: null,
    toDate: null,
    fromPaymentDate: null,
    toPaymentDate: null,
  };
  function filtersReducer(prevState, { type, payload }) {
    return {
      ...prevState,
      ...payload,
    };
  }
  const [filtersState, dispatchFiltrers] = useReducer(
    filtersReducer,
    filtersInitState
  );
  //
  //

  useEffect(() => {
    setCurrentPage(1);
  }, [order, columnKey, searchType, searchTerms, filtersState]);

  const {
    isLoading: isTableDataLoading,
    data: tableData,
    error: tableDataError,
    isPreviousData,
  } = useFetchDocumentations({
    currentPage,
    pageSize,
    order,
    columnKey,
    searchType,
    searchTerms,
    categories: filtersState.categories,
    periodic: filtersState.periodic,
    fromAmount: filtersState.fromAmount,
    toAmount: filtersState.toAmount,
    fromdate: filtersState.fromDate,
    todate: filtersState.toDate,
    frompaymentdate: filtersState.fromPaymentDate,
    topaymentdate: filtersState.toPaymentDate,
  });

  const memoizedTableData = useMemo(
    () => (tableData ? tableData[0] : []),
    [tableData]
  );

  const handleTableChange = (pagination, filters, sorter) => {
    setOrder(sorter?.order);
    setColumnKey(sorter?.columnKey);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const [expandedRows, setExpandedRows] = useState([]);
  const onTableRowExpand = (expanded, record) => {
    var keys = [];
    if (expanded) {
      keys.push(record.id);
    }
    setExpandedRows(keys);
  };

  const [selectedRows, setSelectedRows] = useState([]);

  const { isLoading: isParentMonReqLoading, data: parentMonReq } =
    useFetchParentMoneyRequest(activeRow?.id);
  useEffect(() => {
    if (parentMonReq) {
      history.push({
        pathname: "/moneyRequestDetails",
        moneyRequestsActiveTab: parentMonReq.creator === userData.id ? 0 : 1,
        type: "moneyRequestDetails",
        docId: parentMonReq.money_request_id,
        activeRow: parentMonReq,
        showFooter: false,
        inValidteQueryKey: "getMoneyRequestsData",
      });
    }
  }, [parentMonReq]);

  const { isLoading: isMutateHoldLoading, mutate: mutateHold } =
  useMutateHoldDocument(() => setShowDeactivateModal(false));

  return (
    <div className="bg-white">
      <HeaderSection
        // activeTab={null}
        searchDropDownApiUrl={"document/dropdowndata"}
        setColumns={setColumnsHeader}
        originalColumnsHeader={columnsHeaderArr(setActiveRow, setActiveDeactiveId, setShowDeactivateModal, userData, hoverId, t, i18n)}
        selectedRows={selectedRows}
        excelFileName="مصاريفك"
        FiltersComponent={Filters}
        dispatchFiltrers={dispatchFiltrers}
        filtersState={filtersState}
      />

      <div className="mx-4 my-3">
        <Table
          rowKey="id"
          data={memoizedTableData}
          columns={columnsHeader.filter((el) => el.selected || el.pinned)}
          isLoading={isTableDataLoading}
          error={tableDataError}
          pageSize={pageSize}
          currentPage={currentPage}
          isPaginationDisabled={isPreviousData}
          total={tableData && tableData[1][0]?.overall_count}
          expandedRows={expandedRows}
          setExpandedRows={setExpandedRows}
          onTableRowExpand={onTableRowExpand}
          onPageChange={onPageChange}
          onChange={handleTableChange}
          setSelectedRows={setSelectedRows}
          buttonEmptyPage={true}
          // textEmptyPage="يبدو انه لا يوجد "
          actionEmptyPage={() => {
            history.replace("/document");
          }}
          textButtonEmptyPage={t("create_document")}
          expandedRow={(row) => <ExpandedRow row={row} />}
          setHoverId={setHoverId}
        />
      </div>
      <Modal
          showModal={showDeactivateModal}
          onClose={() => setShowDeactivateModal(false)}
        >
          <Alert
            hasFooter={true}
            alertType="cancel"
            title={t("block")}
            body={() => (
              <div>
              {t("alert_text_block")}
              </div>
            )}
            confirmText={t("block")}
            cancelText={t("cancel")}
            isSubmitLoading={isMutateHoldLoading}
            handleConfirm={() => mutateHold(activeDeactiveId)}
            handleCancel={() => !isMutateHoldLoading && setShowDeactivateModal(false)}
          />
      </Modal>
    </div>
  );
};

export default Documentations;
