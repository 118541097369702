import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useMutatePerson(userId, setFile) {
  const queryClint = useQueryClient();

  return useMutation(
    (data) => {
      const formData = new FormData();
      formData.append("first_name", data.firstName);
      formData.append("last_name", data.lastName);
      formData.append("user_name", data.userName);
      data.file && formData.append("photo", data.file);

      return apiClient.patch(`user/${userId}`, formData);
    },
    {
      onSuccess: () => {
        setFile("");
        toast.info("تم تعديل معلومات الحساب.", { autoClose: 3000 });
        queryClint.invalidateQueries("userData");
      },
      onError: (error) => {
        return error;
      },
    }
  );
}
