import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchInvitedUserData(token) {
  return useQuery(
    "invitedUserData",
    () =>
      apiClient
        ?.get("auth/getinviteduserinfobytoken", {
          params: {
            token,
          },
        })
        .then(({ data }) => data?.[0])
        .catch((e) => e),
    { enabled: token ? true : false }
  );
}
