import { Fragment, useState, useEffect } from "react";

import { Link, withRouter, useLocation } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";

import Modal from "../../common/Modal";
import MoneyRequest from "../../moneyRequest/Form";
import WorkSpace from "../../workspace/WorkSpace";
import Alert from "../../common/Alert";
import NotificationsPanel from "../../notifications/Panel";
import { useQuery, useQueryClient } from "react-query";
import apiClient from "../../../services/apiClient";
import AddSafeCashInIcon from "../../resources/icons/AddSafeCashInIcon";
import AddSafeCashOutIcon from "../../resources/icons/AddSafeCashOutIcon";
import AddDocumentationIcon from "../../resources/icons/AddDocumentationIcon";
import AddMoneyRequestIcon from "../../resources/icons/AddMoneyRequestIcon";
import AddPaymentLicenseIcon from "../../resources/icons/AddPaymentLicenseIcon";
import useKeyboardShortcut from "../../../hooks/useKeyboardShortcut";
import useFetchSafeIn from "../../../hooks/reactQuery/useFetchSafeIn";
import useFetchUserData from "../../../hooks/reactQuery/useFetchUserData";
import useAcceptForAccess from "../../../hooks/reactQuery/useAcceptForAceess";
import Spinner from "../../common/Spinner";
import useFetchSafeBalance from "../../../hooks/reactQuery/useFetchSafeBalance";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = ({ history }) => {
  const [showMoneyRequestModal, setShowMoneyRequestModal] = useState(false);
  const [showWorkSpaceModal, setShowWorkSpaceModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [currentBalance, setCurrentBalance] = useState("...");


  const location = useLocation();
  const {t, i18n} = useTranslation();

  const { data: userData } = useFetchUserData();

  useEffect(() => {
    if(userData) setCurrentBalance(userData.activeCompany.balance);
  }, [userData]);

  const safeWithCashInPermissions = userData.safes.filter(safe => safe.company_id === parseInt(localStorage.getItem("activeCompanyId"))).filter(safe => safe.active).find(safe => safe.permissions.includes("CashInWithPermission") || safe.permissions.includes("CashInWithoutPermission") || safe.permissions.includes("CashFullControl"));
  const safeWithCashOutPermissions = userData.safes.filter(safe => safe.company_id === parseInt(localStorage.getItem("activeCompanyId"))).filter(safe => safe.active).find(safe => safe.permissions.includes("CashOutWithPermission") || safe.permissions.includes("CashOutWithoutPermission") || safe.permissions.includes("CashFullControl"));

  const newOperationMenuActions = [
    {
      id: "NewOperationMenuAction1",
      name: t("transaction_type_deposit"),
      icon: <AddSafeCashInIcon />,
      shortcut: "Ctrl+i",
      redirectRoute: "/safe-deposit",
      action: () => {
        history.push("/safe-deposit");
      },
      hasSeperatorLineAfter: false,
      hasPermission: !safeWithCashInPermissions ? false : true
    },
    {
      id: "NewOperationMenuAction2",
      name: t("transaction_type_withdraw"),
      icon: <AddSafeCashOutIcon />,
      shortcut: "Ctrl+o",
      redirectRoute: "/safe-withdraw",
      action: () => {
        history.push("/safe-withdraw");
      },
      hasSeperatorLineAfter: true,
      hasPermission: !safeWithCashOutPermissions ? false : true
    },
    {
      id: "NewOperationMenuAction3",
      name: t("transaction_type_spend_request"),
      icon: <AddPaymentLicenseIcon />,
      shortcut: "Ctrl+p",
      redirectRoute: "/payment-license",
      action: () => {
        history.replace("/payment-license");
      },
      hasSeperatorLineAfter: false,
      hasPermission: userData.permission_tags.includes("SendPurchaseRequest"),
    },
    {
      id: "NewOperationMenuAction4",
      name: t("transaction_type_money_request"),
      icon: <AddMoneyRequestIcon />,
      shortcut: "Ctrl+r",
      redirectRoute: "",
      action: () => {
        setShowMoneyRequestModal(true);
      },
      hasSeperatorLineAfter: true,
      hasPermission: userData.permission_tags.includes("SendMoneyRequest"),
    },
    {
      id: "NewOperationMenuAction5",
      name: t("transaction_type_document"),
      icon: <AddDocumentationIcon />,
      shortcut: "Ctrl+e",
      redirectRoute: "/document",
      action: () => {
        history.replace("/document");
      },
      hasSeperatorLineAfter: false,
      hasPermission: userData.permission_tags.includes("Document"),
    },
  ];

  useKeyboardShortcut([
    {
      keys: ["Control", "p"],
      callback: () => {
        if (!userData.permission_tags.includes("SendPurchaseRequest")) return;

        setNewOperationMenuSelected(
          newOperationMenuActions.find(
            (item) => item.id === "NewOperationMenuAction3"
          )
        );
        history.replace("/payment-license");
      },
    },
    {
      keys: ["Control", "e"],
      callback: () => {
        if (!userData.permission_tags.includes("Document")) return;

        setNewOperationMenuSelected(
          newOperationMenuActions.find(
            (item) => item.id === "NewOperationMenuAction5"
          )
        );
        history.replace("/document");
      },
    },
    {
      keys: ["Control", "r"],
      callback: () => {
        if (!userData.permission_tags.includes("SendMoneyRequest")) return;
        setNewOperationMenuSelected(
          newOperationMenuActions.find(
            (item) => item.id === "NewOperationMenuAction4"
          )
        );
        setShowMoneyRequestModal(true);
      },
    },
    {
      keys: ["Control", "i"],
      callback: () => {
        if (
          !userData.permission_tags.includes("CashInWithPermission") ||
          !userData.permission_tags.includes("CashInWithoutPermission") ||
          !userData.permission_tags.includes("CashFullControl")
        )
          return;
        setNewOperationMenuSelected(
          newOperationMenuActions.find(
            (item) => item.id === "NewOperationMenuAction1"
          )
        );
        history.push("/safe-deposit");
      },
    },
    {
      keys: ["Control", "o"],
      callback: () => {
        if (
          !userData.permission_tags.includes("CashOutWithPermission") ||
          !userData.permission_tags.includes("CashOutWithoutPermission") ||
          !userData.permission_tags.includes("CashFullControl")
        )
          return;
        setNewOperationMenuSelected(
          newOperationMenuActions.find(
            (item) => item.id === "NewOperationMenuAction2"
          )
        );
        history.push("/safe-withdraw");
      },
    },
  ]);

  useEffect(() => {
    let currentOperation = newOperationMenuActions.find(
      (operation) => operation.redirectRoute === location.pathname
    );

    if (currentOperation) {
      setNewOperationMenuSelected(currentOperation);
    } else {
      setNewOperationMenuSelected(null);
    }
    // add option accept for access
    if (
      [
        "mostafa.saady@cloudabill.com",
        "f@chickinworx.com",
        "islamalsoultan@gmail.com",
      ].indexOf(userData.email) !== -1
    ) {
      newOperationMenuActions.push({
        id: "NewOperationMenuAction6",
        name: "قبول الطلبات",
        icon: <AddMoneyRequestIcon />,
        redirectRoute: "",
        action: () => {
          mutateAcceptForAccess();
        },
      });
    }
  }, [location]);

  const [newOperationMenuSelected, setNewOperationMenuSelected] =
    useState(null);

  const { isLoading: loadingAccess, mutate: mutateAcceptForAccess } =
    useAcceptForAccess();
  const companies = useQuery("companies", () => {
    return apiClient.get(`user/${userData.id}/companies`);
  });

  useEffect(() => {
    console.log("companies: ", companies);
  }, [companies]);

  const getCompanyPicture = (id, extension) => {
    return extension
      ? (process.env.REACT_APP_UPLOAD_FILES_BASE_URL
          ? process.env.REACT_APP_UPLOAD_FILES_BASE_URL
          : "") +
          (process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
            ? process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
            : "images/tarbeetapaymentrequests/") +
          `companies/${id}/profilepicture` +
          `.${extension}`
      : "../img/default-company.svg";
  };

  const getUserPicture = () => {
    return userData.profile_picture_ext
      ? (process.env.REACT_APP_UPLOAD_FILES_BASE_URL
          ? process.env.REACT_APP_UPLOAD_FILES_BASE_URL
          : "") +
          (process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
            ? process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
            : "images/tarbeetapaymentrequests/") +
          `users/${userData.id}/profilepicture` +
          `.${userData.profile_picture_ext}`
      : "../img/default-user.svg";
  };

  const switchCompany = (company) => {
    localStorage.setItem("activeCompanyId", JSON.stringify(company.id));
    window.location.reload();
  };

  const handleConfirmLogout = () => {
    localStorage.removeItem("activeCompanyId");
    document.cookie = "Bearer= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    window.location.href = "/home";
    // window.location.reload();
    // history.replace("/home");
  };

  const { data } = useFetchSafeIn({});
  const { data: totalBalance } = useFetchSafeBalance();
  return !companies.isFetched ? (
    ""
  ) : (
    <div className="relative bg-white w-full">
      <div className="flex justify-between gap-x-2 items-center px-4 py-6 sm:px-6">
        <div className="flex items-center">
          <div className="flex justify-start" style={{ width: "12rem" }}>
            <Menu
              as="div"
              className="relative inline-block text-left w-auto h-9"
            >
              {({ open }) => (
                <>
                  <div
                    style={
                      open && newOperationMenuSelected == null
                        ? {
                            boxShadow: "0px 0px 16px rgba(84, 115, 255, 0.5)",
                          }
                        : {}
                    }
                  >
                    <Menu.Button
                      className={`flex justify-center items-center w-full rounded-md px-4 py-2 ${
                        open || newOperationMenuSelected != null
                          ? `bg-white text-black ${
                              newOperationMenuSelected == null
                                ? "ring-2 ring-opacity-100 ring-blue-500 ring-offset-2"
                                : ""
                            } border-2 border-gray-200`
                          : "bg-blue-600 text-white"
                      } text-14 font-bold`}
                      dir="rtl"
                    >
                      {loadingAccess && console.log("loadingg")}
                      <svg
                        width={10}
                        height={6}
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0.292893 0.292893C0.683416 -0.097631 1.31658 -0.097631 1.7071 0.292893L4.99999 3.58579L8.29288 0.292893C8.6834 -0.0976311 9.31657 -0.0976311 9.70709 0.292893C10.0976 0.683417 10.0976 1.31658 9.70709 1.70711L5.7071 5.70711C5.31657 6.09763 4.68341 6.09763 4.29289 5.70711L0.292893 1.70711C-0.0976309 1.31658 -0.0976309 0.683417 0.292893 0.292893Z"
                          fill={`${
                            open || newOperationMenuSelected != null
                              ? "black"
                              : "white"
                          }`}
                        />
                      </svg>
                      <div className="flex mr-2">
                        <div class="flex-shrink">
                          <span>
                            {newOperationMenuSelected != null
                              ? newOperationMenuSelected.name
                              : t("new_transaction")}
                          </span>
                        </div>
                        <div class="flex-none mr-2">
                          {newOperationMenuSelected != null
                            ? newOperationMenuSelected.icon
                            : null}
                        </div>
                      </div>
                    </Menu.Button>
                    {loadingAccess && (
                      <div className="w-4 h-4">
                        <Spinner />
                      </div>
                    )}
                  </div>

                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className={`z-10	origin-top-right absolute ${i18n.resolvedLanguage === "ar" ? "right-0" : "left-0"} mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                    >
                      <div className="py-1">
                        {newOperationMenuActions?.map((operation) =>
                          operation.hasPermission ? (
                            <div>
                              <Menu.Item key={operation.id}>
                                {({ active }) => (
                                  <button
                                    className={classNames(
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700",
                                      `pr-4 pl-2 py-2 text-sm w-full flex justify-between`
                                    )}
                                    onClick={() => {
                                      setNewOperationMenuSelected(operation);
                                      operation.action();
                                    }}
                                  >
                                    <div class={`flex-none w-1/12 ${i18n.resolvedLanguage === "ar" ? "text-right" : "text-left"}`}>
                                      {operation.icon}
                                    </div>
                                    <div class={`flex-shrink w-8/12 ${i18n.resolvedLanguage === "ar" ? "text-right" : "text-left"} px-4`}>
                                      <span class="text-14 font-medium">
                                        {operation.name}
                                      </span>
                                    </div>
                                    <div class="flex-none w-3/12 text-left">
                                      <span class="text-12 font-normal text-gray-300">
                                        {operation.shortcut}
                                      </span>
                                    </div>
                                  </button>
                                )}
                              </Menu.Item>
                              {operation.hasSeperatorLineAfter ? (
                                <hr></hr>
                              ) : null}
                            </div>
                          ) : null
                        )}
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
          <div>
            <NotificationsPanel />
          </div>
        </div>
        
        <div className="flex items-center">
          <div className="flex justify-center items-center mx-3" dir={i18n.resolvedLanguage === "ar" ? "rtl" : "ltr"}><span style={{color: "#6B7280", fontSize: "12px"}}>
          {t("current_balance")}:
          </span>
          <Link to="/balance" style={{color: "#3F84F6", fontSize: "14px", fontWeight: "700", marginRight: "5px"}}>
            {new Intl.NumberFormat('en-EG', { style: 'currency', currency: 'EGP', maximumSignificantDigits: 12}).format(currentBalance).toString().replace(/EGP/, "") + " "}  
            {t("egp")}
          </Link>
          </div>
          <Menu as="div" className="relative inline-block text-left">
            {({ open }) => (
              <>
                <div className="flex items-center">
                  {userData.profile_picture_ext ? (
                    <img
                      className="w-8 h-8 rounded-full flex justify-center items-center"
                      src={getUserPicture()}
                    />
                  ) : (
                    <div className="w-8 h-8 bg-blue-200 rounded-full flex justify-center items-center">
                      {userData.name.charAt(0).toString().toUpperCase()}
                    </div>
                  )}
                  <div name="profileUserNameDiv" className="mx-4">{userData.name}</div>

                  <Menu.Button name="profileMenuButton" className="">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.0002 15.5C11.7442 15.5 11.4883 15.402 11.2933 15.207L7.29325 11.207C6.90225 10.816 6.90225 10.184 7.29325 9.793C7.68425 9.402 8.31625 9.402 8.70725 9.793L12.0122 13.098L15.3053 9.918C15.7043 9.535 16.3352 9.546 16.7192 9.943C17.1032 10.34 17.0922 10.974 16.6952 11.357L12.6953 15.219C12.5002 15.407 12.2502 15.5 12.0002 15.5Z"
                        fill="#343C44"
                      />
                    </svg>
                  </Menu.Button>
                </div>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className={`origin-top-right absolute ${i18n.resolvedLanguage === "ar" ? "left-2.5" : "right-0"} mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                    style={{ zIndex: 100 }}
                  >
                    <div className="py-1">
                      {companies?.data?.data?.map((company) => (
                        <Menu.Item onClick={() => switchCompany(company)}>
                          <button
                            className={classNames(
                              `block px-4 py-2 text-sm w-full flex ${i18n.resolvedLanguage === "ar" ? "justify-between" : "justify-start"} items-center`
                            )}
                          >
                            <div className="grid grid-cols-4 gap-x-1">
                              <div className={`col-span-1 ${i18n.resolvedLanguage === "en" ? "mr-2" : ""}`}>
                                <img
                                  src={getCompanyPicture(
                                    company?.id,
                                    company?.profile_picture_ext
                                  )}
                                  width={26}
                                  height={26}
                                />
                              </div>

                              <span className="col-span-3">{i18n.resolvedLanguage === "en" && company.en_name ? company.en_name : company.name}</span>
                            </div>
                            {company.id == userData.activeCompanyId ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19"
                                height="14"
                                viewBox="0 0 19 14"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M6.00234 11.2L1.80234 7.00001L0.402344 8.40001L6.00234 14L18.0023 2.00001L16.6023 0.600006L6.00234 11.2Z"
                                  fill="#1697FF"
                                />
                              </svg>
                            ) : (
                              ""
                            )}
                          </button>
                        </Menu.Item>
                      ))}
                      <hr />
                      {userData.permission_tags.includes("CreateWorkspace") ? (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm  w-full flex justify-between"
                              )}
                              onClick={() => setShowWorkSpaceModal(true)}
                            >
                              <span>{t("new_workspace")}</span>
                            </button>
                          )}
                        </Menu.Item>
                      ) : null}
                      <hr />
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            name="signOutButton"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm w-full flex justify-between"
                            )}
                            onClick={() => setShowLogoutModal(true)}
                          >
                            <span>{t("logout")}</span>
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </div>
      <hr />

      <Modal
        showModal={showMoneyRequestModal}
        onClose={() => setShowMoneyRequestModal(false)}
      >
        <MoneyRequest onClose={() => setShowMoneyRequestModal(false)} />
      </Modal>

      <Modal
        showModal={showWorkSpaceModal}
        onClose={() => setShowWorkSpaceModal(false)}
      >
        <WorkSpace onClose={() => setShowWorkSpaceModal(false)} />
      </Modal>

      <Modal
        showModal={showLogoutModal}
        onClose={() => setShowLogoutModal(false)}
      >
        <Alert
          hasFooter={true}
          alertType="cancel"
          title={t("confirm")}
          body={() => (
            <div>{t("alert_text_logout")}</div>
          )}
          confirmText={t("logout")}
          cancelText={t("cancel")}
          handleConfirm={handleConfirmLogout}
          handleCancel={() => setShowLogoutModal(false)}
        />
      </Modal>
    </div>
  );
};

export default withRouter(Header);
