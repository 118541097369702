const ThreeDots = ({}) => {
  return (
    <div>
      <svg
        width={10}
        height={3}
        viewBox="0 0 10 3"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.03143 2.09446C1.57333 2.09446 2.02575 1.65199 2.02575 1.10014C2.02575 0.558239 1.57333 0.110795 1.03143 0.110795C0.484553 0.110795 0.0371094 0.558239 0.0371094 1.10014C0.0371094 1.65199 0.484553 2.09446 1.03143 2.09446ZM4.99627 2.09446C5.53817 2.09446 5.99059 1.65199 5.99059 1.10014C5.99059 0.558239 5.53817 0.110795 4.99627 0.110795C4.4494 0.110795 4.00195 0.558239 4.00195 1.10014C4.00195 1.65199 4.4494 2.09446 4.99627 2.09446ZM8.96112 2.09446C9.50302 2.09446 9.95543 1.65199 9.95543 1.10014C9.95543 0.558239 9.50302 0.110795 8.96112 0.110795C8.41424 0.110795 7.9668 0.558239 7.9668 1.10014C7.9668 1.65199 8.41424 2.09446 8.96112 2.09446Z"
          fill="#CBD5E1"
        />
      </svg>
    </div>
  );
};

export default ThreeDots;
