import { useState } from "react";

import { getAttachments } from "../../../../utils/getAttachments";

const TruncateAttachments = ({ row, propertyName }) => {
  const [truncate, setTruncate] = useState(false);

  return (
    <div
      className={`mr-2 text-xs text-body font-medium flex flex-col gap-y-2 ${
        !truncate && "truncate w-full"
      }`}
    >
      {getAttachments({
        attachProperity: row[propertyName],
        attachUrl: "attachments",
        rowId: row.id,
        notAvailableMessage: "---",
        shownFiles: truncate ? row[propertyName]?.length : 1,
      })}
      {row[propertyName]?.length > 1 && (
        <span
          className="text-xs font-normal text-secondary"
          onClick={(e) => {
            e.stopPropagation();
            setTruncate((p) => !p);
          }}
        >
          {truncate ? "أقل" : "أكثر"}
        </span>
      )}
    </div>
  );
};

export default TruncateAttachments;
