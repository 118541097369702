import { fileTypeIconFromFileName } from "../utils/fileUtils";

const static_base_url =
  (process.env.REACT_APP_UPLOAD_FILES_BASE_URL
    ? process.env.REACT_APP_UPLOAD_FILES_BASE_URL
    : "") +
  (process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
    ? process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
    : "") +
  "/";

const getAttachmentIndex = (attachments, attachment, index) => {
  const attachmentName = attachment.name ? attachment.name : attachment;
  return attachments
    .slice(0, index)
    .filter((att) => att.name === attachmentName).length;
};

export const getAttachments = ({
  attachProperity,
  attachUrl,
  rowId,
  notAvailableMessage = "",
  shownFiles = attachProperity?.length,
}) => (
  <>
    <p className="flex flex-wrap gap-2">
      {attachProperity ? (
        attachProperity.slice(0, shownFiles).map((attachment, index) => (
          <a
            href={`${static_base_url}/${attachUrl}/${rowId}/${
              getAttachmentIndex(attachProperity, attachment, index) > 0
                ? getAttachmentIndex(attachProperity, attachment, index) + "/"
                : ""
            }${attachment}`}
            target="_blank"
            rel="noreferrer"
            key={index}
          >
            <div className="rounded border-2 border-gray-200 px-2 py-1">
              <div class="flex flex-row">
                <div>
                  <span>
                    {attachment.length > 18
                      ? "..." + attachment.substr(0, 15)
                      : attachment}
                  </span>
                </div>
                <div className="mr-2">
                  {fileTypeIconFromFileName(attachment)}
                </div>
              </div>
            </div>
          </a>
        ))
      ) : (
        <div>{notAvailableMessage}</div>
      )}
    </p>
  </>
);
