import React, { Fragment, useEffect, useState } from "react";

import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";

import ReactTagInput from "@pathofdev/react-tag-input";
import UploadInput from "../../components/common/inputs/Upload";
import Modal from "../../components/common/Modal";
import InviteMembers from "../../components/auth/InviteMembers";
import { useMutation, useQuery } from "react-query";
import apiClient from "../../services/apiClient";
import useFetchUserData from "./../../hooks/reactQuery/useFetchUserData";

const WelcomeOwner = ({ history }) => {
  const { data: userData } = useFetchUserData();
  const [showInviteMembersModal, setShowInviteMembersModal] = useState(false);
  const ownedCompanies = useQuery("ownedCompanies", () => {
    return apiClient.get(`/user/${userData.id}/ownedcompanies`);
  });

  useEffect(() => {
    if (userData.firsttimeowner === 0) {
      history.replace("/");
    }
  }, [userData]);

  const {
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
    control,
    trigger,
  } = useForm({
    mode: "onSubmit",
  });

  const welcomeOwnerMutation = useMutation(
    (body) =>
      apiClient.post(`/user/${userData.id}/updatefirsttime`, body, {
        headers: { "Content-Type": "multipart/form-data" },
      }),
    {
      onSuccess: () => {
        history.replace("/");
      },
    }
  );

  const sendInvitationsMutation = useMutation((body) =>
    apiClient.post(`user/${userData.id}/sendinvitations`, body)
  );

  const sendInvitations = (emails) => {
    let data = new FormData();
    // using Set(array) applies the known set-theory rules/operations (i.e. unique values)
    const filteredEmailsWithDefaultRoles = [...new Set(emails)]
      .filter((mail) => mail !== userData.email)
      .map((mail) => {
        return { email: mail, role: 3 };
      });
    data.append("emails", JSON.stringify(filteredEmailsWithDefaultRoles));
    data.append("user_id", userData.id);
    data.append("company_id", ownedCompanies?.data?.data[0].id);
    data.append("user_name", userData.first_name + " " + userData.last_name);
    data.append("company_name", ownedCompanies?.data?.data[0].name);

    sendInvitationsMutation.mutate(data);
  };

  const handleWelcomeOwnerForm = (data) => {
    let body = new FormData();
    body.append("company_id", ownedCompanies.data.data[0].id);
    data.personFile && body.append("userPhoto", data.personFile[0]);
    data.companyFile && body.append("companyPhoto", data.companyFile[0]);

    welcomeOwnerMutation.mutate(body);
    if (data.emails.length > 0) sendInvitations(data.emails);
  };

  const [filePerson, setFilePerson] = useState("");
  const [imagePreviewUrlPerson, setImagePreviewUrlPerson] = useState(
    "img/upload-profile.png"
  );

  const photoPreviewPerson = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        setFilePerson(file);
        setImagePreviewUrlPerson(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const [fileCompany, setFileCompany] = useState("");
  const [imagePreviewUrlCompany, setImagePreviewUrlCompany] = useState(
    "img/upload-profile.png"
  );

  const photoPreviewCompany = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        setFileCompany(file);
        setImagePreviewUrlCompany(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const [membersEmails, setMembersEmails] = useState([]);

  const hanleCotinue = () => {
    history.replace("/");
  };

  return (
    <form
      className="col-span-12  mx-auto"
      // onSubmit={handleSubmit(handleWelcomeOwnerForm)}
    >
      <div className="sm:rounded-md sm:overflow-hidden bg-gray-50">
        <div className="bg-gray-50 py-6 px-4 space-y-6 sm:p-6 mx-auto max-w-screen-lg w-full">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              أهلا بك
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              لقد تم قبولك معنا في مجتمع Trustbill. الآن إضافة أعضاء إلى شركتك
            </p>
          </div>
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6">
              <div className="flex flex-col justify-center items-center select-none">
                <div>
                  <label
                    className="flex flex-col justify-center items-start relative cursor-pointer"
                    htmlFor="personFile"
                  >
                    <div className="w-16 h-16">
                      <img
                        className="w-full h-full object-cover rounded-lg"
                        htmlFor="photo-upload"
                        alt=""
                        src={imagePreviewUrlPerson}
                      />
                    </div>
                  </label>
                </div>
                <UploadInput
                  multiple={false}
                  accept="image/*"
                  name="personFile"
                  id="personFile"
                  hint="اختر الصورة الشخصية"
                  showUplodedFilesNames={false}
                  getValues={getValues}
                  fileSize="50000"
                  errors={errors.personFile}
                  Controller={Controller}
                  control={control}
                  setValue={setValue}
                  trigger={trigger}
                  photoPreview={photoPreviewPerson}
                />
              </div>
            </div>
            <div className="col-span-6 sm:col-span-3">
              <div className="flex flex-col">
                <span className="block text-sm font-medium text-gray-700">
                  اسم المستخدم
                </span>
                <span className="bg-white text-gray-900 font-medium text-base px-2 py-2 rounded-lg select-none mt-2">
                  {userData.user_name}
                </span>
              </div>
            </div>
            <div className="col-span-6 sm:col-span-3">
              <div className="flex flex-col">
                <span className="block text-sm font-medium text-gray-700">
                  البريد الإلكتروني
                </span>
                <span className="bg-white text-gray-900 text-sm font-medium text-base px-2 py-2 rounded-lg mt-2">
                  {userData.email}
                </span>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6">
              <div className="flex flex-col justify-center items-center select-none">
                <div>
                  <label
                    className="flex flex-col justify-center items-start relative cursor-pointer"
                    htmlFor="companyFile"
                  >
                    <div className="w-16 h-16">
                      <img
                        className="w-full h-full object-cover rounded-lg"
                        htmlFor="photo-upload"
                        alt=""
                        src={imagePreviewUrlCompany}
                      />
                    </div>
                  </label>
                </div>
                <UploadInput
                  multiple={false}
                  accept="image/*"
                  name="companyFile"
                  id="companyFile"
                  hint="اختر صورة الشركة"
                  showUplodedFilesNames={false}
                  getValues={getValues}
                  fileSize="50000"
                  errors={errors.companyFile}
                  Controller={Controller}
                  control={control}
                  setValue={setValue}
                  trigger={trigger}
                  photoPreview={photoPreviewCompany}
                />
              </div>
            </div>
            <div className="col-span-6 sm:col-span-3">
              <div className="flex flex-col">
                <span className="block text-sm font-medium text-gray-700">
                  اسم شركتك
                </span>
                <span className="bg-white text-gray-900 text-sm font-medium text-base px-2 py-2 rounded-lg select-none mt-2">
                  {ownedCompanies?.data?.data[0].name}
                </span>
              </div>
            </div>
            <div className="col-span-6 sm:col-span-3">
              <div className="flex flex-col">
                <span className="block text-sm font-medium text-gray-700">
                  تخصص شركتك
                </span>
                <span className="bg-white text-gray-900 text-sm font-medium text-base px-2 py-2 rounded-lg mt-2">
                  {ownedCompanies?.data?.data[0].specialization_name}
                </span>
              </div>
            </div>
            <div className="col-span-6 sm:col-span-3">
              <div className="flex flex-col">
                <span className="block text-sm font-medium text-gray-700">
                  مسئوليتك بالشركة
                </span>
                <span className="bg-white text-gray-900 text-sm font-medium text-base px-2 py-2 rounded-lg mt-2">
                  {ownedCompanies?.data?.data[0].responsibility_name}
                </span>
              </div>
            </div>
            <div className="col-span-6 sm:col-span-3">
              <div className="flex flex-col">
                <span className="block text-sm font-medium text-gray-700">
                  عدد الموظفين
                </span>
                <span className="bg-white text-gray-900 text-sm font-medium text-base px-2 py-2 rounded-lg mt-2">
                  {ownedCompanies?.data?.data[0].members_name}
                </span>
              </div>
            </div>
          </div>

          <div className="mt-2 grid grid-cols-1 sm:grid-cols-6 gap-y-6 gap-x-4">
            <div className="sm:col-span-4 sm:col-start-2">
              <Controller
                name="emails"
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <ReactTagInput
                    // maxLength="20"
                    className="add-tools"
                    tags={membersEmails}
                    name="membersEmails"
                    placeholder="يمكنك اضافة عضو ثم Enter"
                    maxTags={15}
                    editable={true}
                    readOnly={false}
                    removeOnBackspace={true}
                    onChange={(newTags) => {
                      {
                        if (
                          newTags[newTags.length - 1]?.match(
                            /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
                          ) &&
                          newTags[newTags.length - 1]?.length <= 20
                        ) {
                          setMembersEmails([...new Set(newTags)]);
                          onChange(newTags);
                        } else {
                          if (membersEmails.length === 1) {
                            setMembersEmails([]);
                            onChange([]);
                          } else toast.error("بريد إلكتروني غير صالح");
                        }
                      }
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <hr />
      </div>
      <div className="grid sm:grid-cols-8 gap-5 px-4 py-3 bg-white text-right sm:px-6">
        <Fragment>
          <button
            type="button"
            className={`col-span-1 sm:col-start-4 bg-black py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white ${
              !getValues().personFile &&
              !getValues().companyFile &&
              !membersEmails.length > 0
                ? "cursor-not-allowed disabled bg-opacity-50"
                : ""
            }`}
            onClick={() => {
              setShowInviteMembersModal(true);
            }}
            disabled={
              !getValues().personFile &&
              !getValues().companyFile &&
              !membersEmails.length > 0
            }
          >
            تأكيد
          </button>
          <Modal
            showModal={showInviteMembersModal}
            onClose={() => setShowInviteMembersModal(false)}
          >
            <InviteMembers
              onClose={() => setShowInviteMembersModal(false)}
              emails={membersEmails}
              handleConfirm={handleSubmit(handleWelcomeOwnerForm)}
            />
          </Modal>
        </Fragment>

        <button
          type="button"
          className={`col-span-1 sm:col-start-5 bg-white py-2 px-4 border border-red-400 rounded-md shadow-sm text-sm font-medium text-red-500 hover:bg-gray-50 ${
            getValues().personFile ||
            getValues().companyFile ||
            membersEmails.length > 0
              ? "cursor-not-allowed disabled bg-opacity-50"
              : ""
          }`}
          disabled={
            getValues().personFile ||
            getValues().companyFile ||
            membersEmails.length > 0
          }
          onClick={hanleCotinue}
        >
          ليس الأن
        </button>
      </div>
    </form>
  );
};

export default WelcomeOwner;
