import { useState, useEffect, useReducer, useMemo, useRef } from "react";
import { useHistory, useLocation } from "react-router";

import HeaderSection from "../../components/common/HeaderSection";
import TableTabs from "../../components/moneyRequest/TableTabs";
import Table from "../../components/common/Table";
import ExpandedRow from "../../components/moneyRequest/ExpandedRow";
import ExpandedRowFullControl from "../../components/safe/Withdraw/preview/ExpandedRowFullControl/index";
import NoteSvg from "../../components/resources/note svg/NoteSvg";
import TotalRequests from "../../components/resources/total requests/TotalRequests";
import TotalValueSvg from "../../components/resources/total value svg/TotalValueSvg";
import CurrentBalanceSvg from "../../components/resources/current balance svg/CurrentBalanceSvg";
import { useDataLayerValue } from "../../context/DataLayer";
import useFetchUserBalance from "../../hooks/reactQuery/useFetchUserBalance";
import useFetchMoneyRequests from "../../hooks/reactQuery/useFetchMoneyRequests";
import { getColumnsHeader } from "./tableColumnsHeader";
import useMutateMoneyRequestUpdateChecked from "../../hooks/reactQuery/useMutateMoneyRequestUpdateChecked";
import { useQueryClient } from "react-query";
import actionTypes from "../../context/types";
import useFetchUserData from "./../../hooks/reactQuery/useFetchUserData";
import Filters from "../../components/paymentLicense/Filters";
import Modal from "../../components/common/Modal";
import MoneyRequest from "../../components/moneyRequest/Form";
import useMutateHoldMoneyRequest from "../../hooks/reactQuery/useMutateHoldMoneyRequest";
import Alert from "../../components/common/Alert";
import { useTranslation } from "react-i18next";

const MoneyRequests = ({}) => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();

  const [{ activeTab, searchType, searchTerms }, dispatch] = useDataLayerValue();

  if(activeTab == 0 && !userData.permission_tags.includes("SendMoneyRequest")) {
    dispatch({
      type: actionTypes.ACTIVE_TAB,
      payload: 1,
    });
  }

  const [showMoneyRequestModal, setShowMoneyRequestModal] = useState(false);

  const pageSize = process.env.REACT_APP_PAGE_SIZE;
  const [currentPage, setCurrentPage] = useState(1);
  const [columnKey, setColumnKey] = useState("");
  const [order, setOrder] = useState("");

  const location = useLocation();

  const moneyRequestsActiveTab = useRef(location.moneyRequestsActiveTab);
  const expandedDocId = useRef(location.expandedDocId);

  const [hoverId, setHoverId] = useState(null);

  const [activeDeactiveId, setActiveDeactiveId] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);

  const [columnsHeader, setColumnsHeader] = useState(() =>
    getColumnsHeader(
      activeTab, 
      true, 
      setActiveDeactiveId, 
      setShowDeactivateModal, 
      userData, 
      hoverId,
      t,
      i18n
    ).filter((el) => el.selected || el.pinned)
  );

  /*useEffect(() => {
    setColumnsHeader(getColumnsHeader(activeTab, true, setActiveDeactiveId, setShowDeactivateModal, userData, hoverId).filter((el) => el.selected || el.pinned));
  }, [hoverId]);*/

  //
  //
  // handling filters
  const filtersInitState = {
    users: [],
    categories: [],
    approved: [],
    fromAmount: null,
    toAmount: null,
  };
  function filtersReducer(prevState, { type, payload }) {
    return {
      ...prevState,
      ...payload,
    };
  }
  const [filtersState, dispatchFiltrers] = useReducer(
    filtersReducer,
    filtersInitState
  );
  useEffect(() => {
    dispatchFiltrers({
      // type: "CHANGESTATE",
      payload: {
        users: [],
        categories: [],
        approved: [],
        fromAmount: null,
        toAmount: null,
      },
    });
    setColumnsHeader(
      getColumnsHeader(
        activeTab, 
        true, 
        setActiveDeactiveId, 
        setShowDeactivateModal, 
        userData, 
        hoverId,
        t,
        i18n
      ).filter((el) => el.selected || el.pinned)
    );
  }, [activeTab]);

  const {
    isLoading: isTableDataLoading,
    data: tableData,
    error: tableDataError,
    isPreviousData,
  } = useFetchMoneyRequests({
    activeTab,
    currentPage,
    pageSize,
    order,
    columnKey,
    searchType,
    searchTerms,
    userId: userData.id
  });

  const memoizedTableData = useMemo(
    () => (tableData ? tableData[0] : []),
    [tableData]
  );

  const {
    isLoading: isUserBalanceLoading,
    data: userBalance,
    error: userBalanceError,
  } = useFetchUserBalance();

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTab, order, columnKey, searchType, searchTerms, filtersState]);

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTab]);

  const handleTableChange = (pagination, filters, sorter) => {
    setOrder(sorter?.order);
    setColumnKey(sorter?.columnKey);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const { mutate: mutateMoneyRequestUpdateChecked } =
    useMutateMoneyRequestUpdateChecked(userData.id);

  const queryClient = useQueryClient();

  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    if (moneyRequestsActiveTab.current != null) {
      dispatch({
        type: actionTypes.ACTIVE_TAB,
        payload: moneyRequestsActiveTab.current,
      });
      moneyRequestsActiveTab.current = null;
    }
  }, []);

  useEffect(() => {
    if (expandedDocId.current != null) {
      setExpandedRows([...expandedRows, expandedDocId.current]);
      let expandedDocRowInTable = document.querySelector(
        '[data-row-key="' + expandedDocId.current + '"]'
      );
      if (expandedDocRowInTable != null) {
        expandedDocRowInTable.scrollIntoView();
        expandedDocId.current = null;
      }
    }
  }, [activeTab, expandedRows]);

  const onTableRowExpand = (expanded, record) => {
    var keys = [];
    if (expanded) {
      keys.push(record.id);
    }
    setExpandedRows(keys);

    // remove unread effect if the user expanded the row
    if (expanded) {
      if (record.recent != null) {
        if (record.recent !== 0) {
          let expandedRecordData = tableData[0].find((datum) => {
            return datum.id === record.id;
          });
          expandedRecordData.recent = 0;

          queryClient.setQueryData(
            [
              "getMoneyRequestsData",
              activeTab,
              currentPage,
              pageSize,
              order,
              columnKey,
              searchType,
              searchTerms,
            ],
            tableData
          );

          // notify the server that the user expanded the row
          let request = expandedRecordData;

          mutateMoneyRequestUpdateChecked({ request });
        }
      }
    }
  };

  const [selectedRows, setSelectedRows] = useState([]);

  
  const { isLoading: isMutateHoldLoading, mutate: mutateHold } =
  useMutateHoldMoneyRequest(() => setShowDeactivateModal(false));

  useEffect(() => {
    console.log(expandedRows);
    console.log(activeTab);
  }, [expandedRows]);

  return (
    <>
      <div>
        {/* <HeaderSection
          PageLogo={NoteSvg}
          title="نظام العهد"
          description="لعهد الصادرة منك و الواردة إليك. العهد التي تم الرد عليها و التي تنتظر الموافقة. العهد التي تم تصفيتها, و التي لم يتم تصفيتها بعد. يمكنك متابعتها كلها من هذة الصفحة."
          title1="إجمالى الطلبات"
          value1={tableData && tableData[1][0]?.overall_count}
          state1={{ isLoading: isTableDataLoading, error: tableDataError }}
          title2="إجمالى القيمة"
          value2={tableData && tableData[1][0]?.overall_total}
          state2={{ isLoading: isTableDataLoading, error: tableDataError }}
          title3="رصيدك الحالي"
          value3={userBalance}
          state3={{
            isLoading: isUserBalanceLoading,
            error: userBalanceError,
          }}
          TotalRequests={TotalRequests}
          TotalValueSvg={TotalValueSvg}
          CurrentBalanceSvg={CurrentBalanceSvg}
        /> */}

        {/* <TableTabs
          subActiveTab={subActiveTab}
          setSubActiveTab={setSubActiveTab}
        /> */}
        <Modal
          showModal={showMoneyRequestModal}
          onClose={() => setShowMoneyRequestModal(false)}
        >
          <MoneyRequest onClose={() => setShowMoneyRequestModal(false)} />
        </Modal>
        <HeaderSection
          searchDropDownApiUrl={"money_request/dropdowndata"}
          activeTab={activeTab}
          setColumns={setColumnsHeader}
          originalColumnsHeader={columnsHeader}
          selectedRows={selectedRows}
          excelFileName="العهد"
          FiltersComponent={Filters}
          dispatchFiltrers={dispatchFiltrers}
          filtersState={filtersState}
        />

        <div className="mx-4 my-3">
          <Table
            rowKey="id"
            data={memoizedTableData}
            columns={columnsHeader}
            isLoading={isTableDataLoading}
            error={tableDataError}
            buttonEmptyPage={activeTab == 0 ? true : false}
            textButtonEmptyPage={
              activeTab == 0 ? "إرسال طلب عهدة مالية" : false
            }
            textEmptyPage={
              activeTab == 0
                ? t("sent_money_requests_empty_page")
                : activeTab == 1
                ? t("received_money_requests_empty_page")
                : null /*مسحوبات الخزنه */
            }
            actionEmptyPage={() => {
              setShowMoneyRequestModal(true);
            }}
            pageSize={pageSize}
            currentPage={currentPage}
            isPaginationDisabled={isPreviousData}
            total={tableData && tableData[1] && tableData[1][0]?.overall_count}
            onChange={handleTableChange}
            onPageChange={onPageChange}
            setSelectedRows={setSelectedRows}
            expandedRows={expandedRows}
            setExpandedRows={setExpandedRows}
            onTableRowExpand={onTableRowExpand}
            expandedRow={(row) => (
              activeTab === 2 ? <ExpandedRowFullControl row={row} safeId={userData.safes[0].id} isOwner={true} /> : <ExpandedRow row={row} activeTab={activeTab} />
            )}
            setHoverId={setHoverId}
          />
        </div>
        <Modal
          showModal={showDeactivateModal}
          onClose={() => setShowDeactivateModal(false)}
        >
          <Alert
            hasFooter={true}
            alertType="cancel"
            title="حجب العملية"
            body={() => (
              <div>
                {t("alert_text_block")}
              </div>
            )}
            confirmText="حجب العملية"
            cancelText="الغاء"
            isSubmitLoading={isMutateHoldLoading}
            handleConfirm={() => mutateHold(activeDeactiveId)}
            handleCancel={() => !isMutateHoldLoading && setShowDeactivateModal(false)}
          />
        </Modal>
      </div>
    </>
  );
};

export default MoneyRequests;
