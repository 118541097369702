import { useState, useMemo, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Table from "../../components/common/Table";

import ExpandedRow from "../../components/dashboard/ExpandedRow";
import useFetchUserData from "../../hooks/reactQuery/useFetchUserData";

import { columnsHeaderReceived } from "../PaymentLicenses/Preview/tableColumnsHeader";
import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
import useFetchSingleSpendRequest from "../../hooks/reactQuery/useFetchSingleSpendRequest";
import useMutateHoldSpendRequest from "../../hooks/reactQuery/useMutateHoldSpendRequest";
import Alert from "../../components/common/Alert";
import Modal from "../../components/common/Modal";
import { useTranslation } from "react-i18next";

const SingleSpendRequest = () => {
  const { data: userData } = useFetchUserData();
  const {t, i18n} = useTranslation();

  const params = useParams();
  const safeId = params.safeId;
  const spendRequestId = params.id;

  const location = useLocation();

  const history = useHistory();

  const currentSafe = userData.safes.find(safe => safe.id == safeId && safe.active == 1);

  const [hoverId, setHoverId] = useState(null);

  const [activeDeactiveId, setActiveDeactiveId] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);

  const [columnsHeaderState, setColumnsHeader] = useState(() => columnsHeaderReceived(
    setActiveDeactiveId, 
    setShowDeactivateModal, 
    userData,
    hoverId,
    t, 
    i18n
  ).filter(el => el.selected));

  /*useEffect(() => {
    setColumnsHeader(columnsHeaderReceived(
      setActiveDeactiveId, 
      setShowDeactivateModal, 
      userData,
      hoverId
    ).filter(el => el.selected));
  }, [hoverId]);*/

  const [selectedRows, setSelectedRows] = useState([]);

  const {
    isLoading: isTableLoading,
    data: tableData,
    error: tableError,
  } = useFetchSingleSpendRequest(
    spendRequestId
  );

  const memoizedTableData = useMemo(
    () => (tableData ? tableData : []),
    [tableData]
  );

  console.log(memoizedTableData);


  const [expandedRows, setExpandedRows] = useState([]);

  const onTableRowExpand = (expanded, record) => {
    var keys = [];
    if (expanded) {
      keys.push(record.id);
    }
    setExpandedRows(keys);
  };

  useEffect(() => {
    if(tableData) setExpandedRows([tableData[0].id]);
  }, [tableData]);

  const { isLoading: isMutateHoldLoading, mutate: mutateHold } =
  useMutateHoldSpendRequest(() => setShowDeactivateModal(false));

  return (
    <>
    {location.state && <Breadcrumb items={[{title: location.state.comingFrom ? location.state.comingFrom : "home", action: () => history.push(location.state.fallback ? location.state.fallback : "/home")}, {title: location.state.title, action: () => {}}]} />}
      
    <div className="mx-4 my-3">
      <Table
        rowKey="id"
        data={memoizedTableData}
        columns={columnsHeaderState}
        isLoading={isTableLoading}
        error={tableError}
        total={1}
        setSelectedRows={setSelectedRows}
        onTableRowExpand={onTableRowExpand}
        expandedRows={expandedRows}
        setExpandedRows={setExpandedRows}
        expandedRow={(row) => <ExpandedRow row={row} activeTab={row.created_by === userData.id ? 0 : 1} /> }
        isPaginationDisabled={true}
        pageSize={1}
        currentPage={1}
        hasFooter={false}
        hasSelection={false}
        setHoverId={setHoverId}
      />
    </div>

    <Modal
        showModal={showDeactivateModal}
        onClose={() => setShowDeactivateModal(false)}
      >
      <Alert
        hasFooter={true}
        alertType="cancel"
        title="حجب العملية"
        body={() => (
          <div>
            {t("alert_text_block")}
          </div>
        )}
        confirmText="حجب العملية"
        cancelText="الغاء"
        isSubmitLoading={isMutateHoldLoading}
        handleConfirm={() => mutateHold(activeDeactiveId)}
        handleCancel={() => !isMutateHoldLoading && setShowDeactivateModal(false)}
      />
    </Modal>
    </>
  );
};

export default SingleSpendRequest;
