import PropTypes from "prop-types";

function Icon(props) {
  const { className, ...restOfProps } = props;
  return (
    <svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      className={`${className}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restOfProps}
    >
      <path
        d="M1.87499 0H9.84043L14.0625 4.20564V14.0625C14.0625 14.5805 13.6425 15 13.125 15H1.87499C1.35748 15 0.9375 14.5805 0.9375 14.0625V0.937491C0.9375 0.41954 1.35753 0 1.87499 0Z"
        fill="#FF897E"
      />
      <path
        d="M14.0489 4.21718H10.7812C10.2637 4.21718 9.84375 3.79721 9.84375 3.27969V0.0078125L14.0489 4.21718Z"
        fill="#DC2626"
      />
      <path
        d="M10.5471 7.10594C10.7042 7.10594 10.781 6.96907 10.781 6.83642C10.781 6.69906 10.7009 6.56641 10.5471 6.56641H9.65274C9.47789 6.56641 9.3804 6.71123 9.3804 6.87109V9.06905C9.3804 9.265 9.49195 9.37374 9.64289 9.37374C9.79291 9.37374 9.90494 9.265 9.90494 9.06905V8.46579H10.4459C10.6137 8.46579 10.6976 8.32843 10.6976 8.19204C10.6976 8.05847 10.6137 7.92577 10.4459 7.92577H9.90494V7.10594H10.5471ZM7.52414 6.56641H6.86975C6.69209 6.56641 6.56598 6.68829 6.56598 6.8692V9.07094C6.56598 9.29546 6.74738 9.36578 6.87722 9.36578H7.56396C8.37675 9.36578 8.91347 8.83096 8.91347 8.00545C8.91303 7.13266 8.40774 6.56641 7.52414 6.56641ZM7.55557 8.823H7.15664V7.10924H7.51618C8.06042 7.10924 8.29711 7.47441 8.29711 7.97829C8.29711 8.44988 8.06459 8.823 7.55557 8.823ZM5.15837 6.56641H4.51009C4.3268 6.56641 4.22461 6.68732 4.22461 6.87109V9.06905C4.22461 9.265 4.34179 9.37374 4.49927 9.37374C4.65676 9.37374 4.77394 9.265 4.77394 9.06905V8.42732H5.18034C5.68189 8.42732 6.09581 8.072 6.09581 7.5006C6.09586 6.94142 5.69649 6.56641 5.15837 6.56641ZM5.1476 7.91175H4.77399V7.08251H5.1476C5.37822 7.08251 5.52494 7.2625 5.52494 7.49735C5.52446 7.73176 5.37822 7.91175 5.1476 7.91175Z"
        fill="white"
      />
    </svg>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
};

export default Icon;
