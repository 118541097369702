import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchBalanceDetails({
  user_id,
  currentPage,
  pageSize,
  order,
  columnKey,
}) {
  return useQuery(
    [
      "getBalanceDetails",
      user_id,
      currentPage,
      pageSize,
      order,
      columnKey,
    ],
    () => {
      const params = {};
      params.pageno = currentPage;
      params.pagesize = pageSize;
      params.user_id = user_id;

      if (order && columnKey) {
        params.orderbycolumn = columnKey;
        params.isascending =
          order === "ascend" ? 1 : order === "descend" ? 0 : null;
      }
      return apiClient
        .get(
          `/user/balanceDetails`,
          {
            params,
          }
        )
        .then(({ data }) => {
          console.log(data);
          let newData = [...data];
          newData[0] = data[0].map(obj => {
            return {
              ...obj,
              recipient_user: obj.recipient_user ? {
                ...obj.recipient_user,
                name: `${obj.recipient_user.first_name} ${obj.recipient_user.last_name}`,
                profile_picture_url: (process.env.REACT_APP_UPLOAD_FILES_BASE_URL
                  ? process.env.REACT_APP_UPLOAD_FILES_BASE_URL
                  : "") +
                (process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
                  ? process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
                  : "") +
                `users/${obj.recipient_user.id}/profilepicture` +
                `.${obj.recipient_user.profile_picture_ext}`
              } : null,
              safe: obj.safe ? {
                ...obj.safe,
                manager_user: {
                  ...obj.safe.manager_user,
                  profile_picture_url: (process.env.REACT_APP_UPLOAD_FILES_BASE_URL
                    ? process.env.REACT_APP_UPLOAD_FILES_BASE_URL
                    : "") +
                  (process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
                    ? process.env.REACT_APP_UPLOAD_FILES_RELATIVE_APP_PATH
                    : "") +
                  `users/${obj.safe.manager_user.id}/profilepicture` +
                  `.${obj.safe.manager_user.profile_picture_ext}`
                }
              } : null
            }
          })
          return newData;
        }).catch(error => console.log(error));
    }
  );
}
