import { convertDate } from "../../../../../utils/convertDate";
import { getAttachments } from "../../../../../utils/getAttachments";
import folders from "../../../../../constants/static_folders";
import { useTranslation } from "react-i18next";

const ExpandedRowSafeDocumentations = ({ row }) => {
  const {t, i18n} = useTranslation();

  return (
    <div className={`grid grid-cols-6 gap-4 ${i18n.resolvedLanguage === "en" ? "text-left ml-10" : "text-right mr-10"}`}>
      <div className="col-span-1">
        <p className="text-xs text-body font-bold">{t("document_type")}</p>
        <p className="text-sm text-hints font-medium mt-1">
          {row.periodic ? t("periodic") : t("rare")}
        </p>
      </div>

      <div className="col-span-1">
        <p className="text-xs text-body font-bold">{t("spend_date")}</p>
        <p className="text-sm text-hints font-medium mt-1">
          {convertDate(row.payment_date)}
        </p>
      </div>

      {row.deactivated && row.deactivation_date ? (
        <div className="col-span-1">
          <p className="text-xs text-body font-bold">{t("block_date")}</p>
          <p className="text-sm text-hints font-medium mt-1">
            {convertDate(row.deactivation_date)}
          </p>
        </div>
      ) : null}


      <div className="col-span-5 row-start-2">
        <p className="text-xs text-body font-bold">
          {t("notes_dynamic", {user_name: row.creator_name})}
        </p>
        <p className="text-sm text-hints font-medium mt-1">
          {row.description ? row.description : t("no_notes")}
        </p>
      </div>

      {row.attachments && (
        <div className="col-span-5 row-start-3">
          {getAttachments({
            title: t("attachments"),
            notFoundMessage: t("no_attachments"),
            attachProperity: row.attachments,
            attachUrl: folders.withdrawals,
            rowId: row.id,
          })}
        </div>
      )}
    </div>
  );
};

export default ExpandedRowSafeDocumentations;
