import { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import Modal from "../../common/Modal";
import Spinner from "../../common/Spinner";
import LockSvg from "../../resources/lock svg/LockSvg";
import BlueDotSvg from "../../resources/blue dot svg/BlueDotSvg";
import DocumentExpenseSvg from "../../resources/document expense svg/DocumentExpenseSvg";

import useFetchUserData from "../../../hooks/reactQuery/useFetchUserData";
import useFetchFinLength from "../../../hooks/reactQuery/useFetchFinLength";
import useFetchDocsCount from "../../../hooks/reactQuery/useFetchDocsCount";
import useFetchUserBalance from "../../../hooks/reactQuery/useFetchUserBalance";
import useFetchFinancials from "../../../hooks/reactQuery/useFetchFinancials";

import { convertDate, formatDate } from "../../../utils/convertDate";
import { getAttachments } from "../../../utils/getAttachments";

import folders from "../../../constants/static_folders";
import Alert from "../../common/Alert";
import SelectMenu from "./../../common/inputs/Select/index";
import { useForm, Controller } from "react-hook-form";
import AcceptMoneyReq from "./../../resources/icons/AcceptMoneyReq";
import useMutatePendingRequestAccept from "../../../hooks/reactQuery/useMutatePendingRequestAccept";
import { categoriesLatestOptionsMarkup } from "../../../utils/markUp";

import apiClient from "../../../services/apiClient";
import Safe from "../../resources/icons/Safe";

import { useTranslation } from "react-i18next";

const ExpandedRow = ({ row, activeTab }) => {
  const {
    data: userData,
    isLoading: isUserDataLoading,
    isError: isUserDataError,
  } = useFetchUserData();

  const {t, i18n} = useTranslation();

  const { mutate: mutatePendingRequestAccept } =
    useMutatePendingRequestAccept();

  return (
    <>
      <div className={`grid grid-cols-6 gap-4 ${i18n.resolvedLanguage === "en" ? "text-left ml-10" : "text-right mr-10"}`}>
        <div className="col-span-1 row-start-1">
          <p>{t("company_name")}</p>
          <p>{row.company_name ? row.company_name : null}</p>
        </div>
        <div className="col-span-5 row-start-4 grid grid-cols-5 gap-4">
          <button
            type="button"
            className="col-span-1 inline-flex justify-center items-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none sm:w-auto sm:text-sm bg-black"
            onClick={() => {
              let request = {
                id: row.id,
              };
              mutatePendingRequestAccept({ request });
            }}
          >
          {t("accept_member")} 
          </button>
          {/* <button
            type="button"
            className="col-span-1 inline-flex justify-center items-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none sm:w-auto sm:text-sm bg-red-600"
            onClick={() => {}}
          >
            رفض العضو
          </button> */}
        </div>
      </div>
    </>
  );
};

export default ExpandedRow;
