import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchMembersCount() {
  return useQuery("membersCount", () =>
    apiClient
      ?.get("request_for_access/loadmembers")
      .then(({ data }) =>
        data.map((membersCount) => {
          if (membersCount.max_number_of_members === null) {
            membersCount.max_number_of_members = "Above";
          }
          return {
            ...membersCount,
            label: `${membersCount.min_number_of_members} -
            ${membersCount.max_number_of_members}`,
            value: membersCount.id,
          };
        })
      )
      .catch((e) => e)
  );
}
