const AllOptions = () => {
  return (
    <div>
      <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.79164 1.66667H10.2083C10.825 1.66667 11.3333 2.17501 11.3333 2.79168V4.02499C11.3333 4.47499 11.05 5.03334 10.775 5.31668L8.35835 7.45001C8.02501 7.73334 7.79998 8.29166 7.79998 8.74166V11.1584C7.79998 11.4917 7.57499 11.9417 7.29166 12.1167L6.50832 12.625C5.77499 13.075 4.76663 12.5667 4.76663 11.6667V8.69167C4.76663 8.3 4.54165 7.79168 4.31665 7.50835L2.18332 5.25834C1.89999 4.975 1.675 4.475 1.675 4.13333V2.84167C1.66666 2.17501 2.17497 1.66667 2.79164 1.66667Z"
          stroke="#94A3B8"
          strokeWidth="1.5"
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.66666 9.99999V12.5C1.66666 16.6667 3.33332 18.3333 7.49999 18.3333H12.5C16.6667 18.3333 18.3333 16.6667 18.3333 12.5V7.49999C18.3333 4.89999 17.6833 3.26666 16.175 2.41666C15.75 2.17499 14.9 1.99165 14.125 1.86665"
          stroke="#94A3B8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.8333 10.8333H15"
          stroke="#94A3B8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.16666 14.1667H15"
          stroke="#94A3B8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default AllOptions;
