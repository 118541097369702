import { useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";

import apiClient from "../../services/apiClient";

export default function useChangUserRole(setChangeRoleModal, row) {
  const queryClint = useQueryClient();

  return useMutation(
    (newRole) => {
      const params = {};
      params.id = row.user_id;
      params.role = newRole.id;
      return apiClient.post(
        !row.pending ? "user/updateuserrole" : "user/invitation/updateuserrole",
        params
      );
    },
    {
      onSuccess: () => {
        toast.info("تم تعديل دور هذا العضو بنجاح.");
        queryClint.invalidateQueries("team");
        setChangeRoleModal(false);
      },
      onError: (error) => {
        toast.error(error.response.data.message);
        setChangeRoleModal(false);
        return error;
      },
    }
  );
}
