import { useMutation } from "react-query";
import { toast } from "react-toastify";
import apiClient from "../../services/apiClient";

export default function useAcceptForAccess() {
  return useMutation("acceptForAccess", () =>
    apiClient
      ?.post(`request_for_access/acceptlatest`),{
          onError:(err)=>{
                toast.error("حدث خطأ ما يمكن المحاولة لاحقا")
          },
          onSuccess:(res)=>{
                toast.success("تم قبول كل الطلبات")
          }
      }
      
  );
}
