import { useQuery } from "react-query";

import apiClient from "../../services/apiClient";

export default function useFetchMoneyRequests({
  activeTab,
  currentPage,
  pageSize,
  order,
  columnKey,
  searchType,
  searchTerms,
  userId
}) {
  return useQuery(
    [
      "getMoneyRequestsData",
      activeTab,
      currentPage,
      pageSize,
      order,
      columnKey,
      searchType,
      searchTerms,
    ],
    () => {
      const params = {};
      params.pageno = currentPage;
      params.pagesize = pageSize;
      params.withdrawn = 0;

      if (order && columnKey) {
        params.orderbycolumn = columnKey;
        params.isascending =
          order === "ascend" ? 1 : order === "descend" ? 0 : null;
      }
      if (searchType !== "" && searchTerms.length > 0) {
        params.search_type = searchType;
        params.search_terms = JSON.stringify(searchTerms);
      }
      return apiClient
        .get(
          `${activeTab === 2 ? `/money_request/cashout/${userId}` : `money_request/all/${activeTab === 0 ? "sent" : activeTab === 1 ? "received" : ""}`}`,
          {
            params,
          }
        )
        .then(({ data }) => data);
    }
  );
}
