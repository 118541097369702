const UserRole = ({ fillColor }) => {
  return (
    <div>
      <svg
        width={32}
        height={32}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 16C18.7614 16 21 13.7614 21 11C21 8.23858 18.7614 6 16 6C13.2386 6 11 8.23858 11 11C11 13.7614 13.2386 16 16 16Z"
          stroke={fillColor ? fillColor : "#1E293B"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.2101 19.74L19.67 23.2801C19.53 23.4201 19.4 23.68 19.37 23.87L19.18 25.22C19.11 25.71 19.45 26.05 19.94 25.98L21.29 25.79C21.48 25.76 21.75 25.63 21.88 25.49L25.42 21.95C26.03 21.34 26.32 20.63 25.42 19.73C24.53 18.84 23.8201 19.13 23.2101 19.74Z"
          stroke={fillColor ? fillColor : "#1E293B"}
          strokeWidth="1.5"
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.7002 20.25C23.0002 21.33 23.8402 22.17 24.9202 22.47"
          stroke={fillColor ? fillColor : "#1E293B"}
          strokeWidth="1.5"
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.40991 26C7.40991 22.13 11.2599 19 15.9999 19C17.0399 19 18.0399 19.15 18.9699 19.43"
          stroke={fillColor ? fillColor : "#1E293B"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default UserRole;
