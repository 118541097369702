const BlueDotSvg = ({}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
    >
      <circle cx={8} cy={8} r={8} fill="#A9B5BE" />
      <circle cx={8} cy={8} r={8} fill="#A9B5BE" />
      <circle cx={8} cy={8} r={8} fill="#A9B5BE" />
      <circle cx={8} cy={8} r={8} fill="#D6DDFF" />
      <circle cx={8} cy={8} r={5} fill="#A9B5BE" />
      <circle cx={8} cy={8} r={5} fill="#A9B5BE" />
      <circle cx={8} cy={8} r={5} fill="#A9B5BE" />
      <circle cx={8} cy={8} r={5} fill="#5473FF" />
    </svg>
  );
};

export default BlueDotSvg;
